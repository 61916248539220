import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { Grid } from "@mui/material";
import { SectionLabel } from "../../../../../components/SectionLabel";
import intl from "react-intl-universal";
import { Constants } from "../event_options";
import * as React from "react";
import MySelect from "../../../../../components/select/MySelect";

export function EventEditSingleSelectField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  options: any[];
  required?: boolean;
  onChange?: any;
}) {
  return (
    <Grid item container direction="column">
      <SectionLabel required={props.required ?? false}>{intl.get(`event_form.label.${props.field}`)}</SectionLabel>
      <MySelect
        options={props.options}
        isDisabled={props.formState.readonly}
        isClearable
        isSearchable
        placeholder={intl.get(`event_form.label.${props.field}`)}
        value={Constants.toSingleSelectOption(
          Constants.fetchedValueFromPropPath(props.formState, props.field),
          `event_constants.${props.field}`,
        )}
        onChange={(value) => {
          props.formActions.updateEventFormField({
            prop: `event.${props.field}`,
            value: Constants.fromSingleSelectOption(value),
          });
          if (props.onChange) {
            props.onChange(value);
          }
        }}
      />
    </Grid>
  );
}
