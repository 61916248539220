import { Box, IconButton, Stack } from "@mui/material";
import CloseIcon from "../../assets/icons/Close";
import { useDialogContext } from "../dialog/DialogContext";

export interface BookingDrawerLayoutProps {
  children?: any;
  infoSection?: any;
}

const columnSx = {
  width: { xs: "100%", md: "50%" },
  maxWidth: { xs: "100vw", md: "383px" },
  minHeight: { xs: "50vh", md: "100vh" },
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingTop: "30px",
  paddingBottom: "30px",
};

function CloseButton({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) {
  return (
    <Box display={"flex"} flexDirection={"row"} sx={{ marginBottom: "44px" }}>
      <IconButton onClick={onClick} sx={{ marginLeft: "auto" }} disabled={disabled}>
        <CloseIcon sx={{ width: "12px", height: "12px", margin: "10px" }} />
      </IconButton>
    </Box>
  );
}

export default function BookingDrawerLayout({ children, infoSection }: BookingDrawerLayoutProps) {
  const { handler } = useDialogContext();

  if (!infoSection) {
    return (
      <Stack
        sx={{
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingTop: "30px",
          paddingBottom: "30px",
          height: "100%",
          width: "100%",
        }}
      >
        <Box>
          <CloseButton onClick={handler.close} disabled={!handler.canClose} />
          {children}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction={{ xs: "column-reverse", sm: "row" }} sx={{ height: "100%" }}>
      <Box
        sx={(theme) => ({
          ...columnSx,
          background: theme.palette.primary.light,
        })}
      >
        <Stack sx={{ marginTop: "33px" }}>{infoSection}</Stack>
      </Box>
      <Box sx={columnSx}>
        <CloseButton onClick={handler.close} disabled={!handler.canClose} />
        {children}
      </Box>
    </Stack>
  );
}
