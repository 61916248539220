import { Navigate } from "react-router-dom";
import { useLocation } from "react-router";

interface Props {
  to: string;
  state?: any;
}

export default function NavigateWithSearchAndState({ to, state }: Props) {
  const location = useLocation();
  return <Navigate to={{ pathname: to, search: location.search }} state={state || location.state} />;
}
