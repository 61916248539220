import { PickersDay, PickersDayProps } from "@mui/x-date-pickers-pro";
import { Box, IconButton, Stack, Theme, Typography } from "@mui/material";
import CloseIconSmall from "../../assets/icons/CloseSmall";
import AddIconSmall from "../../assets/icons/AddSmall";
import { isInSameWeek } from "./useCalendarWeekSelection";
import FeatureCheckmarkIcon from "../../assets/icons/FeatureCheckmark";

export default function CalendarWeekSelectionDay(
  props: PickersDayProps<Date> & {
    isSelected: boolean;
    readOnly: boolean;
    hoveredDay?: Date | null;
    selectable?: boolean;
    price: string;
  },
) {
  const {
    day,
    isSelected,
    hoveredDay,
    onPointerEnter,
    onPointerLeave,
    onClick,
    disabled,
    selectable,
    price,
    readOnly,
  } = props;

  const isMonday = day.getDay() === 1;
  const isSunday = day.getDay() === 0;

  const isHovered = isInSameWeek(day, hoveredDay);

  return (
    <Stack
      onClick={onClick as any}
      onPointerEnter={onPointerEnter as any}
      onPointerLeave={onPointerLeave as any}
      direction={"row"}
      sx={(theme: Theme) => ({
        flexGrow: isSunday ? 1 : undefined,
        ...(isHovered && {
          backgroundColor: theme.palette.primary[theme.palette.mode],
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary[theme.palette.mode],
          },
        }),
        ...(isSelected &&
          !readOnly && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover, &:focus": {
              backgroundColor: theme.palette.primary.main,
            },
          }),
        ...(isMonday && {
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }),
        ...(isSunday && {
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        }),
        ...(readOnly && {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
        }),
      })}
    >
      <PickersDay
        disabled={disabled || !selectable}
        onDaySelect={() => {}}
        isFirstVisibleCell={false}
        isLastVisibleCell={false}
        outsideCurrentMonth={false}
        day={day}
        sx={{
          paddingLeft: isMonday ? 2.5 : 2,
          paddingRight: 2,
          color: "inherit",
          backgroundColor: "transparent !important",
          width: "20px",
          font: "normal normal normal 14px/18px Manrope",
        }}
        disableMargin
        selected={false}
      />
      {isSunday && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginLeft: "7px",
            paddingRight: 1.25,
            flexGrow: 1,
          }}
        >
          {(isHovered || isSelected) && !readOnly && (
            <Typography
              sx={{
                font: "normal normal bold 14px/18px Manrope",
                color: "inherit",
              }}
            >
              {price}
            </Typography>
          )}
          {isSelected && !readOnly && (
            <IconButton
              color={"primary"}
              sx={{ width: "18px", height: "18px", marginLeft: 0.75, backgroundColor: "white !important" }}
            >
              <CloseIconSmall sx={{ width: "8px", height: "8px" }} />
            </IconButton>
          )}
          {isSelected && readOnly && (
            <FeatureCheckmarkIcon
              sx={{
                width: "12px",
                height: "12px",
                marginLeft: 0.75,
              }}
            />
          )}
          {isHovered && !isSelected && (
            <IconButton
              color={"primary"}
              sx={(theme) => ({
                width: "18px",
                height: "18px",
                marginLeft: 0.75,
                backgroundColor: theme.palette.primary.main + " !important",
              })}
            >
              <AddIconSmall sx={{ width: "11.36px", height: "11.36px" }} />
            </IconButton>
          )}
        </Box>
      )}
    </Stack>
  );
}
