import { Box, Grid } from "@mui/material";
import * as React from "react";

const classes = {
  planPromotionBanner: {
    marginLeft: 2,
    marginRight: 2,
    paddingLeft: "32px",
    paddingRight: "32px",
    marginTop: 2,
    marginBottom: 2,
    height: "110px",
    backgroundImage: 'url("/plan_banner_balken.png")',
    backgroundSize: "100% 100%",
    resize: "both",
  },
};

export default function PlanPromotionBanner() {
  return (
    <Box sx={classes.planPromotionBanner}>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"space-between"}
        style={{ height: "100%" }}
      >
        <Grid
          item
          direction={"row"}
          alignItems={"center"}
          alignContent={"center"}
          style={{ height: "100%", display: "flex" }}
        >
          <Grid item>
            <img src={"/plan_banner_plus.png"} />
          </Grid>
          <Grid item style={{ color: "#FFFFFF", marginLeft: "32px" }}>
            <b>bis zu 40x mehr Besuche</b> auf deiner exklusiven Detailseite
            <br />
            <b>bis zu 20x mehr Klicks</b> auf Deiner Website
          </Grid>
        </Grid>
        <Grid item style={{ color: "#FFFFFF" }}>
          <b>Jetzt eines unserer Pakete buchen</b>
        </Grid>
      </Grid>
    </Box>
  );
}
