import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabGeneralIcon = (props) => (
  <SvgIcon width="21" height="21" viewBox="0 0 21 21" {...props}>
    <path
      id="Pfad_155"
      data-name="Pfad 155"
      d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.512,10.512,0,0,0,10.5,0m-.122,4.17a1.365,1.365,0,1,1-.4.97,1.375,1.375,0,0,1,.4-.97m2.27,11.8L10.3,16.991a1.02,1.02,0,0,1-.41.085A1.027,1.027,0,0,1,8.877,15.85l1.029-5.223-.187.106A1.027,1.027,0,1,1,8.71,8.944l2.13-1.2a1.027,1.027,0,0,1,1.512,1.093l-1.081,5.487.556-.242a1.027,1.027,0,1,1,.821,1.883"
    />
  </SvgIcon>
);

export default TabGeneralIcon;
