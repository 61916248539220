import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const CloseIconSmall = (props) => (
  <SvgIcon width="8" height="8" viewBox="0 0 8 8" {...props}>
    <path
      id="Pfad_29"
      data-name="Pfad 29"
      d="M7.8,6.818a.692.692,0,0,1-.979.979L4,4.979,1.182,7.8A.692.692,0,0,1,.2,6.818L3.021,4,.2,1.182A.692.692,0,0,1,1.182.2L4,3.021,6.818.2a.692.692,0,0,1,.979.979L4.979,4Z"
      transform="translate(0 0)"
      fill="#3b3d3b"
    />
  </SvgIcon>
);

export default CloseIconSmall;
