import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { Grid } from "@mui/material";
import { SectionLabel } from "../../../../../components/SectionLabel";
import intl from "react-intl-universal";
import { Constants } from "../event_options";
import * as React from "react";
import MySelect from "../../../../../components/select/MySelect";

export function EventEditMultiSelectField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  options: any[];
  onChange?: any;
  maxCount?: number;
}) {
  return (
    <Grid item container direction="column">
      <SectionLabel required={false}>{intl.get(`event_form.label.${props.field}`)}</SectionLabel>
      <MySelect
        options={props.options}
        closeMenuOnSelect={false}
        isMulti
        isDisabled={props.formState.readonly}
        isClearable
        isSearchable
        isOptionDisabled={() => {
          if (props.maxCount) {
            return (
              (Constants.toMultiSelectOption(props.formState.event[`${props.field}`], props.options) ?? []).length ==
              props.maxCount
            );
          }
          return false;
        }}
        placeholder={intl.get(`event_form.label.${props.field}`)}
        value={Constants.toMultiSelectOption(props.formState.event[`${props.field}`], props.options)}
        onChange={(value) => {
          props.formActions.updateEventFormField({
            prop: `event.${props.field}`,
            value: Constants.fromMultiSelectOption(value),
          });
          if (props.onChange) {
            props.onChange();
          }
        }}
      />
    </Grid>
  );
}
