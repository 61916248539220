import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import TextFieldWithLabel from "../../../../../components/TextFieldWithLabel";
import intl from "react-intl-universal";
import * as React from "react";
import { Constants } from "../event_options";

export function EventEditMultilineTextInputField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  maxLength?: number;
  rows: number;
  required?: boolean;
  disabled?: boolean;
}) {
  return (
    <TextFieldWithLabel
      maxLength={props.maxLength}
      required={props.required ?? false}
      disabled={props.formState.readonly || props.disabled}
      multiline
      rows={props.rows}
      label={intl.get(`event_form.label.${props.field}`)}
      id={props.field}
      margin="normal"
      onChange={(event) => {
        props.formActions.updateEventFormField({
          prop: `event.${props.field}`,
          value: event.target.value,
        });
      }}
      value={Constants.fetchedValueFromPropPath(props.formState, props.field)}
    />
  );
}
