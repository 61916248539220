import useAvailablePaymentProducts from "./useAvailablePaymentProducts";

export default function usePaymentProduct(productSku: string) {
  const { loading, availableProducts, error, refresh } = useAvailablePaymentProducts();

  const selectedProduct = availableProducts?.find((product) => product.product_sku === productSku);

  return {
    loading,
    selectedProduct,
    error,
    refresh,
  };
}
