import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import UpdatePaymentMethodDialog from "../pages/company/payment/UpdatePaymentMethodDialog";
import * as React from "react";
import useDialogHandler from "./dialog/useDialogHandler";
import { Stack, SxProps, Typography } from "@mui/material";
import intl from "react-intl-universal";
import { BookingPermissionStatus } from "./drawer/BookingPermissionStatus";
import useBackendLoader from "../hooks/useBackendLoader";
import useSelectedCompanyId from "../hooks/useSelectedCompanyId";

interface BookingInitCallResponse {
  status: BookingPermissionStatus;
}

interface Props extends Pick<LoadingButtonProps, "fullWidth" | "loading" | "disabled" | "variant"> {
  onPerformBooking: () => void;
  doesNotRequirePurchase?: boolean;
  buttonLabel: string;
  sx?: SxProps;
}

export default function BookingButtonAndHint({
  onPerformBooking,
  doesNotRequirePurchase,
  disabled,
  buttonLabel,
  sx,
  loading,
  ...props
}: Props) {
  const companyId = useSelectedCompanyId();

  const loader = useBackendLoader<BookingInitCallResponse, BookingPermissionStatus>(
    `/v2/api/b2b/private/companies/${companyId}/booking_permission_status`,
    (result) => result.status,
    "BookingButton",
  );

  const handler = useDialogHandler();

  const handleDialogFinish = () => {
    onPerformBooking();
  };

  const handleButtonClicked = () => {
    if (doesNotRequirePurchase) {
      onPerformBooking();
      return;
    }

    switch (loader.data) {
      case "OK":
        onPerformBooking();
        break;
      case "REQUIRES_PAYMENT_METHOD":
        handler.open();
        break;
      default:
        // do nothing, button should be disabled in all these cases anyways
        break;
    }
  };

  const buttonDisabled =
    (!doesNotRequirePurchase && loader.data !== "OK" && loader.data !== "REQUIRES_PAYMENT_METHOD") || disabled;

  let hint = loader.error ? intl.get("error.generic") : undefined;

  switch (loader.data) {
    case "MISSING_PERMISSION":
      hint = intl.get("booking_drawer.permission_hints.hint_missing_permission");
      break;
    case "MISSING_PERMISSION_SALESPARTNER":
      hint = intl.get("booking_drawer.permission_hints.hint_missing_permission_salespartner");
      break;
    case "NO_COMPANY_COUNTRY":
      hint = intl.get("booking_drawer.permission_hints.hint_no_company_country");
      break;
    case "NO_SALESPARTNER_COMPANY_COUNTRY":
      hint = intl.get("booking_drawer.permission_hints.hint_no_salespartner_company_country");
      break;
  }

  return (
    <Stack sx={sx}>
      <LoadingButton
        {...props}
        disabled={buttonDisabled}
        onClick={handleButtonClicked}
        loading={loading || loader.loading}
      >
        {buttonLabel}
      </LoadingButton>
      {!doesNotRequirePurchase && hint && (
        <Typography
          variant={"body2"}
          color={"error"}
          sx={{ marginBottom: "22px", marginTop: "25px", font: "normal normal bold 14px/18px Manrope" }}
        >
          {hint}
        </Typography>
      )}
      {handler.isOpen && (
        <UpdatePaymentMethodDialog handler={handler} onCancel={handler.close} onSuccess={handleDialogFinish} />
      )}
    </Stack>
  );
}
