import { Company } from "../company";
import intl from "react-intl-universal";

export interface PaymentProduct {
  product_id: string;
  country: string;
  name: string;
  price_in_cents: number;
  currency: string;
  tax_rate_percentage: number;
  type: string;
  product_sku_group: string;
  product_sku: string;
  premium_upgrade_slots: number;
  premium_plus_upgrade_slots: number;
  granted_credits: string;
  billing_type: string;
  billing_interval_value: number;
  billing_interval_unit: string;
  hero_credits?: number;
  social_credits?: number;
  fai_credits?: number;
  display_order: number;
  companyInfo?: Pick<Company, "name">;
  company_id?: string;
  reduced_price_in_cents: number;
  number_of_reduced_price_periods: number;
  trial_interval: number;
  trial_unit?: string;
  single_use_only: boolean;
  is_used: boolean;
}

export const mapGrantedCredits = (granted_credits: string) => {
  const grantTypes = ["hero", "social", "fai_newsletter"];

  const map = new Map<string, number>();
  const creditsToGrant = granted_credits.split(",");
  for (const credit of creditsToGrant) {
    const creditGrant = credit.split("=");
    if (creditGrant.length == 2) {
      if (grantTypes.indexOf(creditGrant[0]) !== -1) {
        try {
          const creditCount = Number(creditGrant[1]);
          map.set(creditGrant[0], creditCount);
        } catch (e) {}
      }
    }
  }

  return map;
};

export const formattedProductFeaturesNew = (product: PaymentProduct) => {
  const entries: string[] = [];

  if (product.premium_upgrade_slots + product.premium_plus_upgrade_slots === 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_priority"));
  } else if (product.premium_upgrade_slots + product.premium_plus_upgrade_slots > 1) {
    entries.push(
      intl.getHTML("company_plan_info.plans.feature_priority_multi", {
        count: product.premium_upgrade_slots + product.premium_plus_upgrade_slots,
      }),
    );
  }
  entries.push(intl.getHTML("company_plan_info.plans.feature_seo"));
  entries.push(intl.getHTML("company_plan_info.plans.feature_statistics"));

  const grantedCredits = mapGrantedCredits(product.granted_credits);
  const heroCredits = grantedCredits.get("hero");
  const socialCredits = grantedCredits.get("social");
  const newsletterCredits = grantedCredits.get("fai_newsletter");

  if (newsletterCredits && newsletterCredits === 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_fai_newsletter"));
  } else if (newsletterCredits && newsletterCredits > 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_fai_newsletter_multi", { count: newsletterCredits }));
  }
  if (socialCredits && socialCredits === 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_social_media_package"));
  } else if (socialCredits && socialCredits > 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_social_media_package_multi", { count: socialCredits }));
  }
  if (heroCredits && heroCredits === 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_hero"));
  } else if (heroCredits && heroCredits > 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_hero_multi", { count: heroCredits }));
  }

  if (product.premium_plus_upgrade_slots === 1) {
    entries.push(intl.getHTML("company_plan_info.plans.feature_premium_plus_priority"));
  } else if (product.premium_plus_upgrade_slots > 1) {
    entries.push(
      intl.getHTML("company_plan_info.plans.feature_premium_plus_priority_multi", {
        count: product.premium_plus_upgrade_slots,
      }),
    );
  }

  return entries;
};

export const formattedAddonInfo = (product_sku_group: string) => {
  switch (product_sku_group) {
    case "hero":
      return intl.get("add_on_info.hero_section.info");
    case "social":
      return intl.get("add_on_info.social_media_package.info");
    case "fai_newsletter":
      return intl.get("add_on_info.newsletter.info");
    default:
      return "";
  }
};

const addEnumerator = (text: string) => {
  return "<b>+</b>&nbsp;&nbsp;&nbsp;&nbsp;" + text;
};

export const formattedAddonFeatures = (product_sku_group: string) => {
  switch (product_sku_group) {
    case "hero":
      return [
        addEnumerator(intl.get("add_on_info.hero_section.feature_image")),
        addEnumerator(intl.get("add_on_info.hero_section.feature_detail_page")),
        addEnumerator(intl.get("add_on_info.hero_section.feature_location")),
        addEnumerator(intl.get("add_on_info.hero_section.feature_exclusive_slot")),
      ];
    case "social":
      return [
        addEnumerator(intl.get("add_on_info.social_media_package.feature_promotion")),
        addEnumerator(intl.get("add_on_info.social_media_package.feature_instagram")),
        addEnumerator(intl.get("add_on_info.social_media_package.feature_facebook")),
        addEnumerator(intl.get("add_on_info.social_media_package.feature_blog")),
      ];
    case "fai_newsletter":
      return [addEnumerator(intl.get("add_on_info.newsletter.feature_blog_post"))];
    default:
      return [];
  }
};

export const formattedNoProductEntries = (entries: number) => {
  return entries === 1
    ? intl.get("company_plan_info.plans.feature_entries_1")
    : intl.get("company_plan_info.plans.feature_entries_multi", {
        count: entries,
      });
};

export const formattedProductFeatures = (
  premium_slots: number,
  premium_plus_slots: number,
  granted_credits: string,
) => {
  const entries: string[] = [];

  if (premium_slots === 1) {
    entries.push("<b>1</b> Premium Freizeitangebot");
  } else if (premium_slots > 1) {
    entries.push(`<b>${premium_slots}</b> Premium Freizeitangebote`);
  }
  if (premium_plus_slots === 1) {
    entries.push("<b>1</b> Premium Plus Freizeitangebot");
  } else if (premium_plus_slots > 1) {
    entries.push(`<b>${premium_plus_slots}</b> Premium Plus Freizeitangebote`);
  }
  const grantedCredits = mapGrantedCredits(granted_credits);
  const heroCredits = grantedCredits.get("hero");
  if (heroCredits && heroCredits === 1) {
    entries.push(`<b>1</b> Credit für Hero-Section`);
  } else if (heroCredits && heroCredits > 1) {
    entries.push(`<b>${heroCredits}</b> Credits für Hero-Section`);
  }
  const socialCredits = grantedCredits.get("social");
  if (socialCredits && socialCredits === 1) {
    entries.push(`<b>1</b> Credit für Social-Media Package`);
  } else if (socialCredits && socialCredits > 1) {
    entries.push(`<b>${socialCredits}</b> Credits für Social-Media Packages`);
  }
  const faiCredits = grantedCredits.get("fai_newsletter");
  if (faiCredits && faiCredits === 1) {
    entries.push(`<b>1</b> Credit für Newsletter`);
  } else if (faiCredits && faiCredits > 1) {
    entries.push(`<b>${faiCredits}</b> Credits für Newsletter`);
  }

  return entries;
};

export const shortFormattedFeatures = (product: PaymentProduct) => {
  const entries: string[] = [];
  entries.push(`${product.premium_upgrade_slots} Premium`);
  entries.push(`${product.premium_plus_upgrade_slots} Premium Plus`);
  const grantedCredits = mapGrantedCredits(product.granted_credits);

  entries.push(
    shortFormattedCredits(
      " / ",
      grantedCredits.get("hero"),
      grantedCredits.get("social"),
      grantedCredits.get("fai_newsletter"),
    ),
  );

  return entries.join(" / ");
};

export const shortFormattedCredits = (separator: string, hero?: number, social?: number, newsletter?: number) => {
  const entries: string[] = [];

  if (hero && hero > 0) {
    entries.push(`${hero} Hero`);
  }
  if (social && social > 0) {
    entries.push(`${social} Social`);
  }
  if (newsletter && newsletter > 0) {
    entries.push(`${newsletter} Newsletter`);
  }

  return entries.join(separator);
};

export const formattedCreditType = (type: string) => {
  if (type === "hero") {
    return "Hero-Section";
  } else if (type === "social") {
    return "Social-Media Package";
  } else if (type === "fai_newsletter") {
    return "Newsletter";
  }
  return "";
};
