import { useDispatch } from "react-redux";
import { useState } from "react";
import useSelectedCompanyId from "../../hooks/useSelectedCompanyId";
import * as EventActions from "../../actions/event";
import intl from "react-intl-universal";
import TopPlacementActiveIcon from "../../assets/icons/TopPlacementActive";
import TopPlacementIcon from "../../assets/icons/TopPlacement";
import SquareButton from "../SquareButton";
import { BCEvent } from "../../model/event";
import { SxProps } from "@mui/material";
import useEventUpgradeSlotsInfo from "../../hooks/useEventUpgradeSlotsInfo";

type ForwardProps = any;

interface Props extends ForwardProps {
  event: BCEvent;
  onTopPlacementClicked?: () => void;
  sx?: SxProps;
  component?: (props: EventUpgradeButtonComponentProps) => any;
  disabled?: boolean;
}
export interface EventUpgradeButtonComponentProps extends ForwardProps {
  active: boolean;
  loading: boolean;
  onClick: () => void;
  disabled?: boolean;
  canUpgradeImmediately: boolean;
  event: BCEvent;
}

export default function EventUpgradeButton({
  event,
  onTopPlacementClicked,
  sx,
  component,
  disabled,
  ...otherProps
}: Props) {
  const dispatch = useDispatch();

  const [topPlacementLoading, setTopPlacementLoading] = useState(false);

  const eventUpgradeSlotsInfo = useEventUpgradeSlotsInfo();

  const companyId = useSelectedCompanyId();

  const canUpgradeImmediately = Boolean(
    (eventUpgradeSlotsInfo?.premium_plus_available && !eventUpgradeSlotsInfo?.premium_available) ||
      (!eventUpgradeSlotsInfo?.premium_plus_available && eventUpgradeSlotsInfo?.premium_available),
  );

  const handleTopPlacementClicked = async () => {
    if (event.event_upgrade === "none" && !canUpgradeImmediately) {
      // let other components handle the upgrade
      onTopPlacementClicked();
      return;
    }

    setTopPlacementLoading(true);

    let upgradeVariant = "none";

    if (event.event_upgrade === "none") {
      upgradeVariant = eventUpgradeSlotsInfo.premium_plus_available ? "premium_plus" : "premium";
    }

    await dispatch<any>(
      EventActions.changeEventUpgrade(companyId, event.event_id, upgradeVariant, () => {
        // TODO: this shouldn't happen, could display an error to make sure?
      }),
    );

    setTopPlacementLoading(false);
  };

  const componentProps = {
    ...otherProps,
    active: event.event_upgrade && event.event_upgrade !== "none",
    loading: topPlacementLoading,
    onClick: handleTopPlacementClicked,
    disabled,
    event,
    canUpgradeImmediately,
  };

  if (component) {
    return component(componentProps);
  }

  return (
    <SquareButton
      onClick={async (e) => {
        e.stopPropagation();
        await handleTopPlacementClicked();
      }}
      loading={topPlacementLoading}
      sx={sx}
      text={
        event.event_upgrade_suspended
          ? intl.get("company_events.event_card.button_top_suspended")
          : intl.get("company_events.event_card.button_top")
      }
      additionalLineCount={event.event_upgrade_suspended ? 1 : 0}
      showPrimaryLineGradient={event.event_upgrade_suspended}
      active={componentProps.active}
      renderIcon={(iconProps, active) =>
        active ? <TopPlacementActiveIcon {...iconProps} /> : <TopPlacementIcon {...iconProps} />
      }
    />
  );
}
