import { Grid } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import SaveIcon from "@mui/icons-material/Check";
import ResellerProfileEditForm from "./components/forms/ResellerProfileEditForm";
import { ResellerProfileFormState } from "../model/resellerProfileFormState";
import { RootState } from "../reducers";
import * as ResellerProfileActions from "../actions/resellerProfile";
import { BackButton, DefaultButton } from "../components/Buttons";
import { AuthenticationState } from "../model/authenticationState";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AppStyles from "../styles/appStyles";

export interface Props {
  resellerProfileFormState: ResellerProfileFormState;
  authState: AuthenticationState;
  actions: typeof ResellerProfileActions;
}

export interface State {}

function ResellerProfileEditPage(props: Props) {
  const [state, setState] = useState({
    supportBackAction: false,
    closing: false,
  });

  const navigate = useNavigate();
  const params = useParams();

  function closePage(): void {
    navigate(-1);
  }

  useEffect(() => {
    if (params == null) {
      props.actions.createNewResellerProfile(); //TODO: should never happen
      setState({ ...state, supportBackAction: true });
    } else {
      const userId = params.user_id;

      setState({ ...state, supportBackAction: true }); // for implementing readonly: props.location.pathname.endsWith("/edit")
      props.actions.editResellerProfileForUser(userId);
    }
  }, []);

  function saveChanges(): void {
    props.authState.user.reseller_profile = props.resellerProfileFormState.profile;
    props.actions.saveResellerProfile(
      props.resellerProfileFormState.profile,
      props.resellerProfileFormState.user_id,
      props.resellerProfileFormState.profile_id,
    );
  }

  if (state.closing) {
    return <div />;
  }
  if (props.resellerProfileFormState.closePage && state.supportBackAction) {
    props.actions.resetResellerProfileForm();
    props.actions.closeResellerProfile();
    closePage();
    setState({ ...state, closing: true });
    return <div />;
  }

  const showReadonly = false;

  const disableSave = showReadonly || !props.resellerProfileFormState.valid;

  return (
    <>
      <PageTracker />
      <ResellerProfileEditForm readonly={showReadonly}>
        <Grid container direction="row">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.actionHeaderLeft}>
              <BackButton
                title={
                  props.resellerProfileFormState.profile_id
                    ? intl.get("ResellerProfile_form.title_edit")
                    : intl.get("ResellerProfile_form.title_create")
                }
                onClick={() => {
                  closePage();
                }}
              />
            </Grid>

            <Grid item sx={AppStyles.headerRight}>
              <DefaultButton
                style={{ marginLeft: 0 }}
                disabled={disableSave}
                onClick={() => {
                  saveChanges();
                }}
              >
                <SaveIcon sx={AppStyles.buttonLeftIcon} />
                {props.resellerProfileFormState.profile_id
                  ? intl.get("ResellerProfile_form.button_save")
                  : intl.get("ResellerProfile_form.button_create")}
              </DefaultButton>
            </Grid>
          </Grid>
        </Grid>
      </ResellerProfileEditForm>
    </>
  );
}

function mapStateToProps(state: RootState) {
  state.resellerProfileFormState.hasManagePermission =
    state.authenticationState.user.permissions.includes("MANAGE_RESELLER_PROFILES");
  return {
    resellerProfileFormState: state.resellerProfileFormState,
    authState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ResellerProfileActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ResellerProfileEditPage);
