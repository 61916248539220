import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import * as React from "react";
import * as intl from "react-intl-universal";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import LinkIcon from "@mui/icons-material/Link";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReactTooltip from "react-tooltip";
import { PagingInformation } from "../../model/pagingInformation";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import WithCompanyPermission from "../../components/WithCompanyPermission";
import WithPermission from "../../components/WithPermission";
import { Pagination } from "../../components/TablePagination";
import { BCEvent } from "../../model/event";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import "moment/locale/de";
import AppStyles from "../../styles/appStyles";
import PlanPromotionBannerLegacy from "../../components/company/PlanPromotionBannerLegacy";

const classes = {
  rejectedTableCell: (theme: Theme) => ({
    cursor: "pointer",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    color: theme.palette.error.main,
    textTransform: "uppercase",
  }),
  statusCell: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    alignContent: "stretch",
  },
};

export interface BaseProps {
  title?: string;
  paging: PagingInformation;
  events: BCEvent[];
  showAddEvent: boolean;
  inboxMode: boolean;

  eventIdForUpdatingStatus?: string;

  companyId?: string;
  showPlanPromotionBanner?: boolean;

  addEvent(): void;

  openEvent(event_id: string, event: BCEvent): void;

  deleteEvent?(event_id: string, event: BCEvent): void;

  transferEvent?(event_id: string, event: BCEvent): void;

  fetchEvents(page: number, pageSize: number): void;

  updateEventStatus(event_id: string, event: BCEvent, status: string): void;

  changeEventUpgrade(event: BCEvent): void;

  manageHeroSlots(event: BCEvent): void;
}

export interface Props extends BaseProps {}

export interface State {
  showShareSuccess: boolean;
}

export interface Props {}

class EventsListLegacy extends React.Component<Props> {
  state = {
    showShareSuccess: false,
  };

  componentDidMount(): void {
    this.props.fetchEvents(this.props.paging.current_page, this.props.paging.page_size);
  }

  handleChangePage = (event: any, page: any) => {
    this.props.paging.current_page = page + 1;
    this.props.fetchEvents(this.props.paging.current_page, this.props.paging.page_size);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.props.paging.page_size = event.target.value;
    this.props.fetchEvents(this.props.paging.current_page, this.props.paging.page_size);
  };

  updateEventStatus(event: BCEvent, status: string) {
    this.props.updateEventStatus(event.event_id, event, status);
  }

  renderSubmitToReviewAction(row: BCEvent) {
    if (row.is_ready_to_review && row.status === "REJECTED") {
      return (
        <LinkButton
          disabled={this.props.eventIdForUpdatingStatus !== null}
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.updateEventStatus(row.event_id, row, "READY_FOR_REVIEW");
          }}
          title={intl.get("company_events.button_submit_to_review")}
        />
      );
    } else if (row.is_ready_to_review === false) {
      return (
        <LinkButton
          disabled={this.props.eventIdForUpdatingStatus !== null}
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.openEvent(row.event_id, row);
          }}
          title={intl.get("company_events.hint_not_ready_for_review")}
        />
      );
    }
  }

  renderStopReviewAction(row: BCEvent) {
    if (row.status !== "READY_FOR_REVIEW") {
      return null;
    }

    return (
      <LinkButton
        disabled={this.props.eventIdForUpdatingStatus !== null}
        onClick={(event: any) => {
          event.stopPropagation();
          this.props.updateEventStatus(row.event_id, row, "DRAFT");
        }}
        title={intl.get("company_events.button_draft")}
      />
    );
  }

  renderUnpublishAction(row: BCEvent) {
    if (row.status !== "PUBLISHED") {
      return null;
    }
    return (
      <LinkButton
        disabled={this.props.eventIdForUpdatingStatus !== null}
        onClick={(event: any) => {
          event.stopPropagation();
          this.props.updateEventStatus(row.event_id, row, "DRAFT");
        }}
        title={intl.get("company_events.button_unpublish")}
      />
    );
  }

  renderPublishAction(row: BCEvent) {
    if (row.status === "DRAFT") {
      return (
        <LinkButton
          disabled={this.props.eventIdForUpdatingStatus !== null}
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.updateEventStatus(row.event_id, row, "PUBLISHED");
          }}
          title={intl.get("company_events.button_publish")}
        />
      );
    }
    return null;
  }

  renderUpgradeAction(row: BCEvent) {
    if (this.props.inboxMode) {
      return null;
    }

    let upgradeTitle = "Aktivieren";
    if (row.event_upgrade !== "none") {
      if (row.event_upgrade === "premium") {
        upgradeTitle = "Premium";
      } else if (row.event_upgrade === "premium_plus") {
        upgradeTitle = "Premium Plus";
      }
      if (row.event_upgrade_suspended) {
        upgradeTitle = `${upgradeTitle} (pausiert - Paket prüfen)`;
      }
    }
    return (
      <LinkButton
        onClick={(event: any) => {
          event.stopPropagation();
          this.props.changeEventUpgrade(row);
        }}
        title={upgradeTitle}
      />
    );
  }

  renderHeroAction(row: BCEvent) {
    if (this.props.inboxMode) {
      if (row.booked_hero_slots?.length === 0) {
        return null;
      } else if (row.booked_hero_slots?.length === 1) {
        return "1 gebuchter Hero-Slot";
      } else {
        return `${row.booked_hero_slots} gebuchte Hero-Slots`;
      }
    }

    let bookedTitle = "";
    if (row.booked_hero_slots?.length === 1) {
      bookedTitle = "1 gebuchter Hero-Slot";
    } else if (row.booked_hero_slots?.length > 1) {
      bookedTitle = `${row.booked_hero_slots?.length} gebuchte Hero-Slots`;
    }

    return (
      <div>
        {bookedTitle}
        <LinkButton
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.manageHeroSlots(row);
          }}
          title={"Hero Slots verwalten"}
        />
      </div>
    );
  }

  renderAppropriateEventAction(row: BCEvent) {
    if (this.props.inboxMode) {
      return (
        <TableCell padding="none" sx={AppStyles.tableCell} align="right" style={{ width: "200px !important" }}>
          <WithPermission hasAnyPermission={["REVIEW_COMPANY_EVENTS"]}>
            <Button
              disabled={this.props.eventIdForUpdatingStatus !== null}
              variant="outlined"
              style={{ marginRight: 10 }}
              onClick={(event) => {
                event.stopPropagation();
                this.props.openEvent(row.event_id, row);
              }}
            >
              {intl.get("event_inbox.button_perform_review")}
            </Button>
          </WithPermission>
        </TableCell>
      );
    }

    return (
      <TableCell padding="none" sx={AppStyles.tableCell} align="right">
        <WithCompanyPermission
          ignoreShowCompanyDetailMenuFlag
          hasAnyPermission={["EDIT_COMPANY_EVENTS", "CREATE_COMPANY_EVENTS"]}
          renderExit={false}
        >
          {this.renderSubmitToReviewAction(row)}
          {this.renderStopReviewAction(row)}

          {this.renderUnpublishAction(row)}
          {this.renderPublishAction(row)}

          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              if (this.props.deleteEvent) {
                this.props.deleteEvent(row.event_id, row);
              }
            }}
            size="large"
          >
            <ClearIcon />
          </IconButton>

          <CopyToClipboard
            text={`${process.env.REACT_APP_SHARING_BASE_URL}/events/${row.company_slug}/${row.normalized_title}`}
            onCopy={() => {
              this.setState({ showShareSuccess: true });
            }}
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
              }}
              size="large"
            >
              <LinkIcon />
            </IconButton>
          </CopyToClipboard>

          <WithPermission hasAnyPermission={["TRANSFER_EVENTS"]} showForCompanyPageAsWell={true}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                if (this.props.transferEvent) {
                  this.props.transferEvent(row.event_id, row);
                }
              }}
              size="large"
            >
              <LocalShippingIcon />
            </IconButton>
          </WithPermission>
        </WithCompanyPermission>
      </TableCell>
    );
  }

  render() {
    return (
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={this.state.showShareSuccess}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({ showShareSuccess: false });
          }}
        >
          <Alert severity="success">{intl.get("company_events.url_copied_to_clipboard")}</Alert>
        </Snackbar>

        {this.props.title && this.props.showAddEvent && (
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.headerLeft}>
              <HeaderLabel>{this.props.title}</HeaderLabel>
            </Grid>
            <Grid item sx={AppStyles.headerRight}>
              {this.props.showAddEvent && (
                <DefaultButton
                  size={"large"}
                  onClick={() => {
                    this.props.addEvent();
                  }}
                >
                  <AddIcon />
                  {intl.get("company_events.button_add")}
                </DefaultButton>
              )}
              {!this.props.showAddEvent && <DefaultButton sx={AppStyles.buttonPlaceholder} />}
            </Grid>
          </Grid>
        )}
        {this.props.companyId && this.props.showPlanPromotionBanner && (
          <PlanPromotionBannerLegacy companyId={this.props.companyId} />
        )}
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  {intl.get("company_events.list_header_title")}
                </TableCell>
                {this.props.inboxMode && (
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                    {intl.get("company_events.list_header_company_name")}
                  </TableCell>
                )}
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  {intl.get("company_events.list_header_type")}
                </TableCell>
                {!this.props.inboxMode && (
                  <WithCompanyPermission hasAnyPermission={["EDIT_COMPANY_EVENTS"]} renderExit={false}>
                    <TableCell padding="none" sx={AppStyles.tableHeaderCell} align={"center"}>
                      {"TOP Platzierung"}
                    </TableCell>
                  </WithCompanyPermission>
                )}
                <WithCompanyPermission hasAnyPermission={["EDIT_COMPANY_EVENTS"]} renderExit={false}>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell} align={"center"}>
                    {"Hero Slots"}
                  </TableCell>
                </WithCompanyPermission>
                {!this.props.inboxMode && (
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell} align="right">
                    {intl.get("company_events.list_header_status")}
                  </TableCell>
                )}
                {this.props.inboxMode && (
                  <>
                    <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                      {intl.get("company_events.list_header_submission_time")}
                    </TableCell>
                    <TableCell padding="none" align="right" />
                  </>
                )}
                {!this.props.inboxMode && (
                  <TableCell padding="none" align="right" sx={AppStyles.tableHeaderCell} style={{ width: "350px" }} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.events.map((row: BCEvent) => {
                const classForStatusText = row.status !== "REJECTED" ? AppStyles.tableCell : classes.rejectedTableCell;
                return (
                  <TableRow
                    key={row.event_id}
                    onClick={() => {
                      this.props.openEvent(row.event_id, row);
                    }}
                    hover
                  >
                    {this.props.inboxMode === false && (
                      <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
                        {row.title}
                      </TableCell>
                    )}
                    {this.props.inboxMode && row.has_special_category === true && (
                      <TableCell scope="row" padding="none" sx={AppStyles.tableCellWarning}>
                        <b>{row.title} (Spezialkategorie)</b>
                      </TableCell>
                    )}
                    {this.props.inboxMode &&
                      (row.has_special_category === undefined || row.has_special_category === false) && (
                        <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
                          {row.title}
                        </TableCell>
                      )}
                    {this.props.inboxMode && (
                      <TableCell padding="none" sx={AppStyles.tableCell}>
                        {row.company.name}
                      </TableCell>
                    )}

                    <TableCell padding="none" sx={AppStyles.tableCell}>
                      {intl.get(`event_type.${row.type}`)}
                    </TableCell>
                    {!this.props.inboxMode && (
                      <WithCompanyPermission hasAnyPermission={["EDIT_COMPANY_EVENTS"]} renderExit={false}>
                        <TableCell padding="none" sx={AppStyles.tableCell} align={"center"}>
                          {this.renderUpgradeAction(row)}
                        </TableCell>
                      </WithCompanyPermission>
                    )}
                    <WithCompanyPermission hasAnyPermission={["EDIT_COMPANY_EVENTS"]} renderExit={false}>
                      <TableCell padding="none" sx={AppStyles.tableCell} align={"center"}>
                        {this.renderHeroAction(row)}
                      </TableCell>
                    </WithCompanyPermission>
                    {!this.props.inboxMode && (
                      <TableCell padding="none" sx={classForStatusText} align="right">
                        <Box sx={classes.statusCell}>
                          {this.props.eventIdForUpdatingStatus === row.event_id && <CircularProgress size={30} />}

                          {this.props.eventIdForUpdatingStatus !== row.event_id && (
                            <div style={{ textAlign: "center" }}>{intl.get(`event_status.${row.status}`)}</div>
                          )}
                          {row.status !== "PUBLISHED" &&
                            row.has_a_published_version &&
                            this.props.eventIdForUpdatingStatus !== row.event_id && (
                              <>
                                <a style={{ marginLeft: 5 }} data-tip data-for="global">
                                  <InfoIcon />
                                </a>

                                <ReactTooltip id="global" aria-haspopup="true" role="example" place="bottom">
                                  <div>{intl.get("company_events.another_event_version_is_published_hint")}</div>
                                </ReactTooltip>
                              </>
                            )}
                        </Box>
                      </TableCell>
                    )}

                    {this.props.inboxMode && (
                      <TableCell padding="none" sx={AppStyles.tableCell}>
                        {moment(new Date()).diff(moment(row.review_submission_date)) > 1000 * 60 * 60 * 8
                          ? moment(row.review_submission_date).locale("de").format("LL LT")
                          : moment(row.review_submission_date).fromNow()}
                      </TableCell>
                    )}
                    {this.renderAppropriateEventAction(row)}
                  </TableRow>
                );
              })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <Pagination
                  paging={this.props.paging}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Box>
    );
  }
}

export default EventsListLegacy;
