import { useEffect, useRef, useState } from "react";

interface Props {
  handleGoogleResponse: (response: CredentialResponse) => void;
}

export default function GoogleLoginButton({ handleGoogleResponse }: Props) {
  const buttonRef = useRef();

  const [google, setGoogle] = useState<Google>();
  const [googleIsLoading, setGoogleIsLoading] = useState(true);

  useEffect(() => {
    const timer = setInterval(
      () => {
        if (typeof window !== "undefined" && window.google) {
          setGoogle(window.google);
          setGoogleIsLoading(false);
        }
      },
      googleIsLoading ? 100 : null,
    );
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (google) {
      google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleGoogleResponse,
      });

      google.accounts.id.renderButton(buttonRef.current, {
        theme: "outline",
        size: "large",
        type: "standard",
        shape: "rectangular",
        text: "signin_with",
        logo_alignment: "left",
      });
    }
  }, [google]);

  return <div ref={buttonRef} />;
}
