import * as React from "react";
import { Box } from "@mui/material";

const aHeaderLabel = function (props: { children?: any }) {
  const {} = props;
  return (
    <Box
      sx={(theme) => ({
        color: "#3c3c3b",
        fontWeight: 800,
        fontFamily: theme.headerFontFamily,
        fontSize: theme.fontSizes.largerFont,
      })}
    >
      {props.children}
    </Box>
  );
};

export default aHeaderLabel;
export const HeaderLabel = aHeaderLabel;
