import { Box, Grid, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import * as ResellerProfileActions from "../actions/resellerProfile";
import { RootState } from "../reducers";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { PageState, PageStateType } from "../model/pageState";
import { Pagination } from "../components/TablePagination";
import { ResellerProfileState } from "../model/resellerProfileState";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import AppStyles from "../styles/appStyles";

export interface Props {
  rpState: ResellerProfileState;
  pageState: PageState;
  actions: typeof ResellerProfileActions;
}

export interface State {}

function ResellerProfilesPage(props: Props) {
  const navigate = useNavigate();
  const handleChangePage = (event: any, page: any) => {
    props.rpState.paging.current_page = page + 1;
    props.actions.fetchAllProfiles(props.rpState.paging.current_page, props.rpState.paging.page_size);
  };

  const handleChangeRowsPerPage = (event: any) => {
    props.rpState.paging.page_size = event.target.value;
    props.actions.fetchAllProfiles(props.rpState.paging.current_page, props.rpState.paging.page_size);
  };

  useEffect(() => {
    props.actions.fetchAllProfiles(props.rpState.paging.current_page, props.rpState.paging.page_size);
  }, []);

  function editResellerProfile(user_id?: string) {
    navigate(`/commission_management/${user_id}`); // + '/edit');
  }

  if (props.pageState.state === PageStateType.Loading) {
    return <></>;
  }

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("resellerprofiles.title")}</HeaderLabel>
          </Grid>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("resellerprofiles.list_header_name")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("resellerprofiles.list_header_email")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("resellerprofiles.list_header_continuous_commission_percentage")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("resellerprofiles.list_header_continuous_commission_months")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rpState.allResellers != null &&
                props.rpState.allResellers.map((row) => {
                  return (
                    <TableRow
                      key={row.user_id}
                      onClick={() => {
                        editResellerProfile(row.user_id);
                      }}
                      hover
                    >
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {row.first_name + " " + row.last_name}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {row.email}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={AppStyles.tableCell}
                        style={
                          !row.reseller_profile || !row.reseller_profile.continuous_commission_percentage
                            ? { color: "red" }
                            : {}
                        }
                      >
                        {row.reseller_profile && row.reseller_profile.continuous_commission_percentage
                          ? row.reseller_profile.continuous_commission_percentage + "%"
                          : intl.get("resellerprofiles.list_entry_not_set")}
                      </TableCell>
                      <TableCell
                        sx={AppStyles.tableCell}
                        component="th"
                        scope="row"
                        style={
                          !row.reseller_profile || !row.reseller_profile.continuous_commission_months
                            ? { color: "red" }
                            : {}
                        }
                      >
                        {row.reseller_profile && row.reseller_profile.continuous_commission_months
                          ? intl
                              .get("resellerprofiles.list_entry_continuous_commission_months")
                              .replace("{months}", row.reseller_profile.continuous_commission_months.toString())
                          : intl.get("resellerprofiles.list_entry_not_set")}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <Pagination
                  paging={props.rpState.paging}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    rpState: state.resellerProfileState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ResellerProfileActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ResellerProfilesPage);
