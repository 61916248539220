import BookingDrawerLayout from "../BookingDrawerLayout";
import { Button, Typography } from "@mui/material";
import intl from "react-intl-universal";
import { BCEvent } from "../../../model/event";
import BookingDrawerHeader from "./BookingDrawerHeader";

interface Props {
  infoSection: any;
  event?: BCEvent;
  onClose?: () => void;
}

export default function BookingDrawerSuccessPage({ infoSection, onClose, event }: Props) {
  return (
    <BookingDrawerLayout infoSection={infoSection}>
      <BookingDrawerHeader event={event} />
      <Typography sx={{ font: "normal normal bold 18px/21px Manrope" }}>
        {intl.get("booking_drawer.success.title")}
      </Typography>
      <Typography
        variant={"body2"}
        sx={(theme) => ({ marginBottom: "26px", marginTop: "15px", "& strong": { color: theme.palette.primary.main } })}
      >
        {intl.getHTML("booking_drawer.success.message")}
      </Typography>

      <Button onClick={onClose}>{intl.get("generic.btn_close")}</Button>
    </BookingDrawerLayout>
  );
}
