import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabImagesIcon = (props) => (
  <SvgIcon width="23" height="18.619" viewBox="0 0 23 18.619" {...props}>
    <g id="Gruppe_546" data-name="Gruppe 546">
      <path
        id="Pfad_150"
        data-name="Pfad 150"
        d="M21.476,4.637H17.725L16.677.738A.991.991,0,0,0,15.734,0H6.719a.991.991,0,0,0-.942.738l-1.049,3.9H.976A.948.948,0,0,0,0,5.579V18.06a.976.976,0,0,0,.976.976h20.5a.976.976,0,0,0,.976-.976V5.579a.948.948,0,0,0-.976-.943M11.226,16.226a5.672,5.672,0,1,1,5.6-5.672,5.642,5.642,0,0,1-5.6,5.672"
      />
      <path
        id="Pfad_151"
        data-name="Pfad 151"
        d="M34.709,28a3.72,3.72,0,1,0,3.646,3.72A3.687,3.687,0,0,0,34.709,28"
        transform="translate(-23.482 -21.17)"
      />
    </g>
  </SvgIcon>
);

export default TabImagesIcon;
