import { AppBar, Box, IconButton, Menu, Theme, Toolbar, Typography } from "@mui/material";
import { useLocation } from "react-router";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import * as intl from "react-intl-universal";
import MoreIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import { AccountCircleRounded } from "@mui/icons-material";
import { CompanyState } from "../model/companyState";
import { getDisplayName, getRoleForUser } from "../model/user";
import { AuthenticationState } from "../model/authenticationState";
import { RootState } from "../reducers";
import * as ErrorDialogActions from "../actions/errorDialog";
import * as SearchActions from "../actions/search";
import * as CompanyActions from "../actions/company";
import * as PageStateActions from "../actions/pageState";
import * as AuthActions from "../actions/auth";
import { EventState } from "../model/eventState";
import { PageState } from "../model/pageState";
import { SearchState } from "../model/searchState";
import HeaderSearch from "./header/HeaderSearch";
import ErrorDialog from "./ErrorDialog";
import HeaderLogoAndName from "./menu/HeaderLogoAndName";
import MyDrawer from "./menu/MyDrawer";
import MyMenuItem from "./menu/MyMenuItem";
import HeaderCompanySelection from "./header/HeaderCompanySelection";
import UserConfirmationBar from "./topbar/userConfirmationBar";

const classes = {
  menuButton: (theme: Theme) => ({
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  }),
  headerTitle: (theme: Theme) => ({
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: theme.fontSizes.largerFont,
  }),
  toolbar: {
    height: "120px",
  },
  toolbarWithoutDrawer: {
    paddingLeft: 0,
    height: "120px",
  },
  sectionDesktop: (theme: Theme) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }),
  sectionMobile: (theme: Theme) => ({
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }),
  header_name: (theme: Theme) => ({
    fontSize: theme.fontSizes.smallFont,
  }),
  header_role: (theme: Theme) => ({
    fontSize: theme.fontSizes.verySmallFont,
  }),
  header_icon: {
    height: "35px",
    width: "35px",
  },
};

export interface Props {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  eventState: EventState;
  pageState: PageState;
  actions: typeof AuthActions;
  companyActions: typeof CompanyActions;
  searchState: SearchState;
  searchActions: typeof SearchActions;
  errorDialogActions: typeof ErrorDialogActions;
}

function Root(props: Props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const location = useLocation();

  const headerTitle = () => {
    if (props.companyState.company != null) {
      return `${props.companyState.company.name}`;
    }
    return "";
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // close Company If Not On DetailPage
  useEffect(() => {
    if (!location || !location.pathname) {
      return;
    }
    if (
      location.pathname.includes("/companies") === false ||
      location.pathname.length - location.pathname.indexOf("/companies") - 10 <= 1
    ) {
      if (location.pathname.includes("/user/") && props.companyState.selected_company_id) {
        return;
      }
      if (props.companyState.show_company_detail_menu || props.companyState.selected_company_id) {
        props.companyActions.closeCompany();
      }
    }
  }, [location?.pathname]);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { company } = props.companyState;
  const renderUserComponent = () => {
    if (!props.authenticationState.authenticated || !props.authenticationState.user) {
      return null;
    }
    return (
      <>
        <Grid container justifyContent="center" direction="row" alignItems="center" flexWrap={"nowrap"}>
          <Grid item>
            <Grid container justifyContent="center" direction="column" alignItems="flex-end" flexWrap={"nowrap"}>
              <Grid item sx={classes.header_name} textAlign={"end"}>
                {`${getDisplayName(props.authenticationState.user)}`}
              </Grid>
              <Grid item sx={classes.header_role}>
                {intl.get(`user_role.${getRoleForUser(props.authenticationState.user, company)}`)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: 4 }} sx={classes.header_icon}>
            <AccountCircleRounded sx={classes.header_icon} />
          </Grid>
        </Grid>
        <ErrorDialog title={intl.get("event_rejected_hint_dialog.title")} />
      </>
    );
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MyMenuItem key={"menu_user"} disabled>
        {renderUserComponent()}
      </MyMenuItem>
    </Menu>
  );

  const showReducedContent =
    !props.authenticationState.authenticated || !props.authenticationState.user.b2b_profile.completed;

  if (showReducedContent) {
    return (
      <AppBar
        position="fixed"
        elevation={0}
        sx={(theme) => ({
          paddingLeft: { xs: "0px !important", sm: "300px !important" },
          backgroundColor: "#f2f2f2",
          color: theme.palette.primary.main,
          borderBottom: "none",
        })}
      >
        <Toolbar sx={classes.toolbarWithoutDrawer}>
          <HeaderLogoAndName />
          <Typography sx={classes.headerTitle} noWrap>
            {headerTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={(theme) => ({
          paddingLeft: { xs: "0px !important", sm: "300px !important" },
          backgroundColor: "#f2f2f2",
          color: theme.palette.primary.main,
          borderBottom: "none",
        })}
      >
        <UserConfirmationBar />

        <Toolbar sx={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            sx={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>

          <HeaderCompanySelection />

          <Box sx={{ flexGrow: 1 }} />

          <HeaderSearch />

          <Box sx={classes.sectionDesktop}>{renderUserComponent()}</Box>

          <Box sx={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit" size="large">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <MyDrawer mobileOpen={mobileOpen} onClose={handleDrawerToggle} />
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyState: state.companyState,
    eventState: state.eventState,
    pageState: state.pageState,
    searchState: state.searchState,
    errorDialogState: state.errorDialogState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
    pageStateActions: bindActionCreators(PageStateActions as any, dispatch),
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
    searchActions: bindActionCreators(SearchActions as any, dispatch),
    errorDialogActions: bindActionCreators(ErrorDialogActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
