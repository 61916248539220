import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { Company } from "../model/company";
import { Error, mapErrorFromException } from "../model/error";
import { CompanySearchCriteria } from "../model/companyState";
import { BCEventCategory } from "../model/event";

export const fetchAllCompanies =
  (page: number, size: number, searchCriteria: CompanySearchCriteria) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res = await axios.get(
        `/v2/api/b2b/private/companies?page=${page}&size=${size}&includeDeleted=${searchCriteria.includeDeleted}&onlyCompaniesWithSuspendedUpgrades=${searchCriteria.onlyCompaniesWithSuspendedUpgrades}&onlyCompaniesWithActiveSubscriptions=${searchCriteria.onlyCompaniesWithActiveSubscriptions}&onlyCompaniesWithUnusedCredits=${searchCriteria.onlyCompaniesWithUnusedCredits}&onlyCompaniesWithUnusedPremiumSlots=${searchCriteria.onlyCompaniesWithUnusedPremiumSlots}&onlyCompaniesWithUnusedPremiumPlusSlots=${searchCriteria.onlyCompaniesWithUnusedPremiumPlusSlots}&text=${searchCriteria.text}`,
      );

      dispatch({
        type: ActionType.FETCH_COMPANIES,
        payload: {
          data: true,
          companies: res.data.companies,
          paging: res.data.paging,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const fetchSalesPartners = () => async (dispatch: Dispatch) => {
  try {
    const res = await axios.get("/v2/api/b2b/private/salespartners");

    dispatch({
      type: ActionType.FETCH_SALESPARTNERS,
      payload: { salesPartners: res.data.companies },
    });
  } catch (error) {}
};

export const fetchCompanyEvents = (company_id: string, page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/events?page=${page}&size=${size}`);
    dispatch({
      type: ActionType.FETCH_COMPANY_EVENTS,
      payload: {
        data: true,
        events: res.data.events,
        paging: res.data.paging,
        eventUpgradeSlots: res.data.event_upgrade_slots,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const fetchUpgradeSlotsInfo = (company_id: string) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/events?page=${0}&size=${1}`);
    dispatch({
      type: ActionType.FETCH_COMPANY_UPGRADE_SLOT_INFO,
      payload: {
        data: true,
        eventUpgradeSlots: res.data.event_upgrade_slots,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const fetchEventInbox = (page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/event_inbox?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_INBOX_EVENTS,
      payload: {
        data: true,
        events: res.data.events,
        paging: res.data.paging,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const selectCompany =
  (selected_company_id: string, show_company_detail_menu: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SELECT_COMPANY,
      payload: { selected_company_id, show_company_detail_menu },
    });
  };

export const fetchCompanyInformation = (company_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}`);

    dispatch({
      type: ActionType.FETCH_COMPANY,
      payload: { data: true, company: res.data },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const closeCompany = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CLOSE_COMPANY });
};

export const editCompany = (company_id: string, loadResellers: boolean) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_COMPANY_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}`);
    let resellers = [];
    if (loadResellers) {
      resellers = (await axios.get("/v2/api/b2b/private/users/commission_recipients")).data.resellers;
    }

    dispatch({
      type: ActionType.EDIT_COMPANY,
      payload: { data: true, company: res.data, company_id, resellers },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const createNewCompany = (loadResellers: boolean) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_COMPANY_FORM });

  let resellers = [];
  if (loadResellers) {
    resellers = (await axios.get("/v2/api/b2b/private/users/commission_recipients")).data.resellers;
  }

  dispatch({ type: ActionType.CREATE_COMPANY, payload: { resellers } });
};

// @ts-ignore
export const updateCompanyFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_COMPANY_FORM_FIELD,
    payload: { prop, value },
  };
};

export const removeCompanyCustomCategory = (category: BCEventCategory) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.REMOVE_COMPANY_CUSTOM_CATEGORY, payload: category });
};

export const addCompanyCustomCategory = (category: BCEventCategory) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_COMPANY_CUSTOM_CATEGORY, payload: category });
};

export const resetCompanyForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_COMPANY_FORM });
};

export const saveCompany =
  (company_id: string | null, company: Company, completion: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      if (company.website_url != null && company.website_url.isEmpty()) {
        company.website_url = null;
      }

      if (company_id) {
        const res = await axios.put(`/v2/api/b2b/private/companies/${company_id}`, company);

        dispatch({
          type: ActionType.SAVE_COMPANY,
          payload: { company: res.data },
        });
      } else {
        const res = await axios.post("/v2/api/b2b/private/companies", company);

        dispatch({
          type: ActionType.SAVE_COMPANY,
          payload: { company: res.data },
        });
      }
      dispatch({ type: ActionType.PAGE_SAVED });

      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const deleteCompany = (company_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.DELETE_COMPANY, payload: { loading: true } });
  try {
    await axios.delete(`/v2/api/b2b/private/companies/${company_id}`);

    dispatch({ type: ActionType.DELETE_COMPANY, payload: { data: true } });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const deletePaymentInformation = (company_id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ActionType.PAGE_SAVING });

    await axios.delete(`/v2/api/b2b/private/companies/${company_id}/payment_info`);

    const activeProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/active_products`);
    const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);
    const availableProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/available_products`);

    dispatch({
      type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
      payload: {
        data: true,
        activeProducts: activeProductsResponse.data.active_products,
        credits: creditsResponse.data.credits,
        availableProducts: availableProductsResponse.data.products,
      },
    });

    dispatch({
      type: ActionType.FETCH_COMPANY_PAYMENT_METHOD,
      payload: { data: true, payment_info: null },
    });

    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const loadPaymentInformation = (company_id: string) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/payment_info`);

    dispatch({
      type: ActionType.FETCH_COMPANY_PAYMENT_METHOD,
      payload: { data: true, payment_info: res.data },
    });
  } catch (error) {}
};

export const createPaymentMethod =
  (heidePayPaymentMethod: any, company_id: string, payment_type: string, callBack: (PaymentInfo) => void) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      // @ts-ignore
      const result = await heidePayPaymentMethod.createResource();
      if (result && result.id) {
        const updatePaymentInfoResult = await axios.put(`/v2/api/b2b/private/companies/${company_id}/payment_info`, {
          payment_type,
          payment_type_id: result.id,
          return_url: window.location.href,
        });

        dispatch({
          type: ActionType.UPDATE_COMPANY_PAYMENT_METHOD,
          payload: updatePaymentInfoResult.data,
        });
        dispatch({ type: ActionType.PAGE_SAVED });
        window.setTimeout(() => {
          if (callBack) {
            callBack(updatePaymentInfoResult.data);
          }
        }, 100);
      }
    } catch (error: any) {
      const { message } = error;
      if (message) {
        const mappedError: Error = { errorMessage: message };
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: mappedError },
        });
      } else {
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: mapErrorFromException(error) },
        });
      }
    }
  };

export const setupPaymentByTransaction =
  (company_id: string, callBack: (PaymentInfo) => void) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      const updatePaymentInfoResult = await axios.put(
        `/v2/api/b2b/private/companies/${company_id}/payment_info_by_transaction`,
        {},
      );

      dispatch({
        type: ActionType.UPDATE_COMPANY_PAYMENT_METHOD,
        payload: updatePaymentInfoResult.data,
      });
      dispatch({ type: ActionType.PAGE_SAVED });
      if (callBack) {
        callBack(updatePaymentInfoResult.data);
      }
    } catch (error: any) {
      const { message } = error;
      if (message) {
        const mappedError: Error = { errorMessage: message };
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: mappedError },
        });
      } else {
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: mapErrorFromException(error) },
        });
      }
    }
  };

export const loadInvoices = (company_id: string, page: number, size: number) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/invoices?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_COMPANY_INVOICES,
      payload: {
        data: true,
        invoices: res.data.invoices,
        paging: res.data.paging,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const chargeOverdueInvoices = (company_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.put(`/v2/api/b2b/private/companies/${company_id}/invoices/overdue/charge`);

    dispatch({
      type: ActionType.CHARGE_COMPANY_OVERDUE_INVOICES,
      payload: {
        data: true,
        invoices: res.data.invoices,
        paging: res.data.paging,
      },
    });

    const companyInformationRes = await axios.get(`/v2/api/b2b/private/companies/${company_id}`);

    dispatch({
      type: ActionType.FETCH_COMPANY,
      payload: { data: true, company: companyInformationRes.data },
    });

    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

// @ts-ignore
export const updateCompanySearchCriteriaFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_COMPANIES_SEARCH_CRITERIA,
    payload: { prop, value },
  };
};
