import { EventUpgradeSlotsInfo } from "../../model/eventState";
import { Button } from "@mui/material";
import TopPlacementIcon from "../../assets/icons/TopPlacement";
import * as React from "react";
import { BCEventUpgradeType } from "../../model/event";

interface Props {
  upgradeSlotsInfo: EventUpgradeSlotsInfo;
  upgradeType?: BCEventUpgradeType;
  onClick?: () => void;
}

export default function AvailableEventUpgradeSlotsButton({ upgradeSlotsInfo, upgradeType, onClick }: Props) {
  const heightSx = { maxHeight: "34px", minHeight: "34px" };

  let usedSlots = upgradeSlotsInfo.premium_plus_used + upgradeSlotsInfo.premium_used;
  let availableSlots = upgradeSlotsInfo.premium_plus_total + upgradeSlotsInfo.premium_total;

  if (upgradeType) {
    usedSlots = upgradeType === "premium_plus" ? upgradeSlotsInfo.premium_plus_used : upgradeSlotsInfo.premium_used;
    availableSlots =
      upgradeType === "premium_plus" ? upgradeSlotsInfo.premium_plus_total : upgradeSlotsInfo.premium_total;
  }

  return (
    <Button
      sx={{
        ...heightSx,
        minWidth: "50px",
        paddingLeft: "6px",
        paddingRight: "6px",
      }}
      color={"primaryInverse"}
      onClick={onClick}
    >
      <TopPlacementIcon sx={{ maxHeight: "16px", marginRight: "6px" }} /> {usedSlots}/{availableSlots}
    </Button>
  );
}
