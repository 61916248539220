import * as React from "react";
import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { AuthenticationState } from "../../../../../model/authenticationState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { FormGridItem } from "../FormGridItem";
import { EventEditTextInputField } from "../fields/EventEditTextInputField";
import { EventEditCheckboxField } from "../fields/EventEditCheckboxField";
import { EventEditMultilineTextInputField } from "../fields/EventEditMultilineTextInputField";
import { CompanyState } from "../../../../../model/companyState";

export interface Props {
  eventFormState: EventFormState;
  authenticationState: AuthenticationState;
  eventFormActions: typeof EventFormActions;
  companyState: CompanyState;
}

class EventDiscountInfoEdit extends React.Component<Props> {
  state = {};

  renderIsDiscountActive() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_discount_active"}
      />
    );
  }

  renderDiscountInfoShort() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"discount_description_short"}
      />
    );
  }

  renderDiscountInfoLong() {
    return (
      <EventEditMultilineTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"discount_description_long"}
        maxLength={1000}
        rows={5}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    const disableControls = this.props.eventFormState.readonly;

    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={4}>{this.renderIsDiscountActive()}</FormGridItem>
          <FormGridItem md={8}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={8}>{this.renderDiscountInfoShort()}</FormGridItem>
          <FormGridItem>{this.renderDiscountInfoLong()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventDiscountInfoEdit;
