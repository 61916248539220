import { Grid, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import * as React from "react";
import * as intl from "react-intl-universal";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { formattedPaymentInfo, PaymentInfo, PaymentInfoStatus } from "../../model/payment_info";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import { theme } from "../../theme";
import AppStyles from "../../styles/appStyles";

export interface Props {
  paymentInfo: PaymentInfo;
  supportsEditing: boolean;
  onChangePaymentMethod: any;
  onDeletePaymentMethod: any;
}

class CompanyPaymentInfoComponent extends React.Component<Props> {
  render() {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" style={{ marginTop: 30 }}>
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("company_plan_info.title_payment_method")}</HeaderLabel>
          </Grid>
          {this.props.supportsEditing && (
            <Grid item sx={AppStyles.headerRight}>
              <DefaultButton
                color={this.props.paymentInfo ? "primary" : "warningLight"}
                onClick={() => {
                  this.props.onChangePaymentMethod();
                }}
              >
                {this.props.paymentInfo && <EditIcon sx={AppStyles.buttonLeftIcon} />}
                {!this.props.paymentInfo && <AddIcon sx={AppStyles.buttonLeftIcon} />}
                {this.props.paymentInfo
                  ? intl.get("company_plan_info.payment_method.button_change")
                  : intl.get("company_plan_info.payment_method.button_add")}
              </DefaultButton>
            </Grid>
          )}
          {this.props.supportsEditing === false && (
            <Grid item sx={AppStyles.headerRight}>
              <DefaultButton sx={AppStyles.buttonPlaceholder} />
            </Grid>
          )}
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableBody>
              {this.props.paymentInfo && (
                <TableRow>
                  {this.props.paymentInfo.status === PaymentInfoStatus.VALID ? (
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                      {formattedPaymentInfo(this.props.paymentInfo)}
                    </TableCell>
                  ) : (
                    <TableCell
                      padding="none"
                      sx={AppStyles.tableCellNotSelectable}
                      style={{
                        color: theme.palette.error.main,
                      }}
                    >
                      {formattedPaymentInfo(this.props.paymentInfo)}
                    </TableCell>
                  )}

                  <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                    &nbsp;
                  </TableCell>
                  <TableCell
                    padding="none"
                    sx={AppStyles.tableCellNotSelectable}
                    style={{
                      textAlign: "right",
                      paddingRight: 10,
                      color: theme.palette.error.main,
                    }}
                  >
                    {this.props.paymentInfo &&
                    this.props.paymentInfo.status === PaymentInfoStatus.PENDING_AUTHORIZATION &&
                    this.props.paymentInfo.authorizationRedirectUrl != null ? (
                      <LinkButton
                        target="_blank"
                        color={"error"}
                        to={this.props.paymentInfo.authorizationRedirectUrl}
                        title={intl.get("company_plan_info.payment_method_authorizing_link")}
                      />
                    ) : (
                      <LinkButton
                        color={"error"}
                        onClick={(event: any) => {
                          event.stopPropagation();
                          this.props.onDeletePaymentMethod();
                        }}
                        title={intl.get("company_plan_info.payment_method.button_delete")}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )}
              {!this.props.paymentInfo && (
                <TableRow>
                  <TableCell scope="row" colSpan={2} sx={AppStyles.tableCellNotSelectable}>
                    {intl.get("company_plan_info.payment_method.no_data")}
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}

export default CompanyPaymentInfoComponent;
