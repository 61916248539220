import { DefaultButton } from "../../../components/Buttons";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AppStyles from "../../../styles/appStyles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { EventFormState } from "../../../model/eventFormState";
import useSelectedCompany from "../../../hooks/useSelectedCompany";

interface Props {
  disabled?: boolean;
}

export default function EventEditTransferAction({ disabled }: Props) {
  const eventFormState = useSelector<RootState, EventFormState>((rootState) => {
    return rootState.eventFormState;
  });

  const company = useSelectedCompany();

  const navigate = useNavigate();

  function transferEvent(): void {
    navigate(`/companies/${company.company_id}/company_events/${eventFormState.event_id}/transfer`);
  }

  return (
    <DefaultButton
      disabled={disabled}
      onClick={() => {
        transferEvent();
      }}
    >
      <LocalShippingIcon sx={AppStyles.buttonLeftIcon} />
      {"Event transferieren"}
    </DefaultButton>
  );
}
