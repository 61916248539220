import { Button, Checkbox, Stack, Typography } from "@mui/material";
import * as React from "react";
import useSelectedCompanyId from "../../../hooks/useSelectedCompanyId";
import { useEffect, useState } from "react";
import { BCEvent, BCEventUpgradeType } from "../../../model/event";
import useBackendLoader from "../../../hooks/useBackendLoader";
import useSelectionHandler from "../../../hooks/useSelectionHandler";
import intl from "react-intl-universal";
import EventCoverImage from "../../events/EventCoverImage";
import TextClamper from "../../textClamper";
import SlotSelectionSummaryBox from "../../calendarWeekSelection/SlotSelectionSummaryBox";
import { useDispatch } from "react-redux";
import * as EventActions from "../../../actions/event";
import { LoadingButton } from "@mui/lab";
import { ActionType } from "../../../model/actions";
import { mapErrorFromException } from "../../../model/error";
import BookingDrawerLoaderWrapper from "../components/BookingDrawerLoaderWrapper";

interface Props {
  onSkip: () => void;
  onSuccess: () => void;
  originatingEvent?: BCEvent;
  numberOfSlots: number;
  upgradeType: BCEventUpgradeType;
  title: string;
  message: string;
  hint?: string;
}

export default function BulkEventUpgradeDrawerPage({
  onSkip,
  onSuccess,
  originatingEvent,
  numberOfSlots,
  upgradeType,
  title,
  message,
  hint,
}: Props) {
  const dispatch = useDispatch();
  const companyId = useSelectedCompanyId();

  const loader = useBackendLoader<any, BCEvent[]>(
    `/v2/api/b2b/private/companies/${companyId}/events`,
    (result) => result.events,
    "BulkEventUpgrade",
  );

  const events = loader.data;

  const [saving, setSaving] = useState(false);

  const selection = useSelectionHandler(events?.map((event) => event.event_id) || [], numberOfSlots);

  useEffect(() => {
    if (originatingEvent) {
      const idSet = new Set<string>();
      idSet.add(originatingEvent.event_id);
      selection.setSelectedItemsIds(idSet);
    }
  }, []);

  const saveSelection = async () => {
    setSaving(true);
    const status = await dispatch<any>(
      EventActions.changeEventUpgradeBulk(companyId, Array.from(selection.selectedItemIds.values()), upgradeType),
    );

    if (status.success) {
      onSuccess();
    } else {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(status.error) },
      });
    }
    setSaving(false);
  };

  const singleSelectMode = numberOfSlots === 1;

  return (
    <BookingDrawerLoaderWrapper loader={loader} onError={onSkip}>
      <Stack>
        <Typography sx={{ font: "normal normal bold 18px/21px Manrope", marginBottom: "25px" }}>{title}</Typography>
        <Typography
          variant={"body2"}
          sx={(theme) => ({ marginTop: "15px", "& b": { color: theme.palette.primary.main } })}
        >
          {message}
        </Typography>
        <Stack gap={"6px"} sx={{ marginTop: "21px" }}>
          {events &&
            events.map((event) => (
              <EventRow
                event={event}
                selected={selection.isSelected(event.event_id) === true || event.event_upgrade !== "none"}
                disabled={
                  !singleSelectMode &&
                  ((!selection.canAddMoreItems && selection.isSelected(event.event_id) !== true) ||
                    event.event_upgrade !== "none")
                }
                onSelectionChange={(selected) => {
                  if (singleSelectMode) {
                    selection.deselectAll();
                  }
                  selection.setSelected(event.event_id, selected);
                }}
                key={event.event_id}
              />
            ))}
        </Stack>
        <SlotSelectionSummaryBox
          sx={{ marginTop: "22px" }}
          availableSlots={numberOfSlots}
          selectedSlots={selection.selectedItemCount}
        />
        <Stack direction={"row"} sx={{ marginRight: "auto", marginTop: "21px" }} gap={"6px"}>
          <LoadingButton
            disabled={selection.selectedItemCount <= 0 || saving}
            onClick={saveSelection}
            loading={saving}
            variant={"contained"}
          >
            {intl.get("generic.btn_save")}
          </LoadingButton>
          <Button variant={"outlined"} onClick={onSkip} color={"defaultTextColor"} disabled={saving}>
            {intl.get("generic.btn_later")}
          </Button>
        </Stack>
        {hint && (
          <Typography
            variant={"body2"}
            sx={{ marginBottom: "22px", marginTop: "25px", font: "normal normal bold 14px/18px Manrope" }}
          >
            {hint}
          </Typography>
        )}
      </Stack>
    </BookingDrawerLoaderWrapper>
  );
}

interface EventRowProps {
  event: BCEvent;
  selected: boolean;
  disabled?: boolean;
  onSelectionChange: (selected: boolean) => void;
}

function EventRow({ event, selected, onSelectionChange, disabled }: EventRowProps) {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
      <EventCoverImage
        event={event}
        sx={{ height: "33px", width: "44px", minWidth: "44px", borderRadius: "3px" }}
        placeHolderSize={24}
      />
      <Typography variant={"body2"}>
        <TextClamper numberOfLines={2} text={event.title} />
      </Typography>
      <Checkbox
        sx={{ marginLeft: "auto" }}
        checked={selected}
        onClick={() => onSelectionChange(!selected)}
        disabled={disabled}
      />
    </Stack>
  );
}
