import { Tab, Tabs, useTheme } from "@mui/material";
import GridViewIcon from "../../assets/icons/GridView";
import ListViewIcon from "../../assets/icons/ListView";
import * as React from "react";

export type CompanyEventsViewType = "grid" | "list";

export default function CompanyEventsViewTypeTabs({
  value,
  onChange,
}: {
  value: CompanyEventsViewType;
  onChange: (newValue: CompanyEventsViewType) => void;
}) {
  const theme = useTheme();

  const heightSx = { maxHeight: "34px", minHeight: "34px" };
  const tabSx = {
    ...heightSx,
    minWidth: "50px",
    maxWidth: "50px",
    color: (theme.palette.defaultTextColor as any).main,
  };

  return (
    <Tabs
      sx={heightSx}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      aria-label="Ansicht auswählen"
      color={"primary"}
      indicatorColor={"primary"}
      textColor={"primary"}
    >
      <Tab sx={tabSx} icon={<GridViewIcon sx={{ maxHeight: "14px" }} />} value={"grid"} aria-label="Raster" />
      <Tab sx={tabSx} icon={<ListViewIcon sx={{ maxHeight: "12px" }} />} value={"list"} aria-label="Liste" />
    </Tabs>
  );
}
