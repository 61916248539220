import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Grid } from "@mui/material";
import { BCEvent } from "../../model/event";
import EventCard, { EventCardActionEvent } from "../events/EventCard";

interface Props {
  sx?: any;
  onActionClicked: EventCardActionEvent;
}
export default function CompanyEventsGrid({ sx, onActionClicked }: Props) {
  const events = useSelector<RootState, BCEvent[]>((state) => state.eventState.events);

  return (
    <Grid sx={sx} container rowSpacing={6} columnSpacing={2} justifyContent={"stretch"}>
      {events.map((event) => (
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2.4} key={event.event_id}>
          <EventCard variant={"grid"} event={event} onActionClicked={onActionClicked} sx={{ height: "100%" }} />
        </Grid>
      ))}
    </Grid>
  );
}
