import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack, PeopleOutline } from "@mui/icons-material";
import intl from "react-intl-universal";
import WithPermission from "../WithPermission";
import BusinessIcon from "@mui/icons-material/Business";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import UsersIcon from "@mui/icons-material/People";
import { userFilledResellerProfile } from "../../model/resellerProfile";
import InboxIcon from "@mui/icons-material/Inbox";
import SearchIcon from "@mui/icons-material/Search";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PaymentIcon from "@mui/icons-material/Payment";
import ReportingIcon from "@mui/icons-material/TrendingUp";
import WithCompanyPermission from "../WithCompanyPermission";
import EventIcon from "@mui/icons-material/Event";
import LocationIcon from "@mui/icons-material/LocationOn";
import TimelineIcon from "@mui/icons-material/Timeline";
import RedeemIcon from "@mui/icons-material/Redeem";
import MediaIcon from "@mui/icons-material/PermMedia";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";
import * as React from "react";
import HeaderLogoAndName from "./HeaderLogoAndName";
import MyMenuItem from "./MyMenuItem";
import { AuthenticationState } from "../../model/authenticationState";
import { CompanyState } from "../../model/companyState";
import { EventState } from "../../model/eventState";
import * as AuthActions from "../../actions/auth";
import * as CompanyActions from "../../actions/company";
import { RootState } from "../../reducers";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const classes = {
  menuItemContent: (theme: Theme) => ({
    color: "inherit",
    fontSize: theme.fontSizes.mediumFont,
  }),

  menuItemIcon: {
    color: "#3c3c3b",
    width: "30px",
    height: "100%",
    minWidth: "30px",
    minHeight: "100%",
    marginRight: "18px",
  },

  menuItemIconContent: {
    width: "30px",
    height: "100%",
    minWidth: "30ox",
    minHeight: "100%",
  },

  menuItemDivider: {
    marginLeft: "30px",
  },
};

const ExternalLink = ({ children, href }: { children?: any; href: string }) => {
  const theme = useTheme();

  return (
    <a
      rel="noopener noreferrer"
      href={href}
      target="_blank"
      style={{
        color: theme.palette.secondary.main,
        marginLeft: 15,
        fontSize: "0.75em",
      }}
    >
      {children}
    </a>
  );
};

const InboxIndicator = ({ children }: { children?: any }) => {
  return (
    <Box
      sx={(theme) => ({
        height: "25px",
        width: "25px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        textAlign: "center",
        color: "white",
        fontSize: theme.fontSizes.smallFont,
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none",
      })}
    >
      {children}
    </Box>
  );
};

export interface Props {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  inboxState: EventState;
  actions: typeof AuthActions;
  companyActions: typeof CompanyActions;

  mobileOpen: boolean;
  onClose: () => void;
}

function MyDrawer(props: Props) {
  const { mobileOpen, onClose } = props;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      component={"nav"}
      sx={{
        [theme.breakpoints.up("sm")]: {
          flexShrink: 0,
        },
      }}
    >
      <Drawer
        variant={isDesktop ? "permanent" : "temporary"}
        anchor={"left"}
        open={isDesktop ? true : mobileOpen}
        onClose={isDesktop ? undefined : onClose}
        PaperProps={{
          sx: {
            backgroundColor: "#f2f2f2",
            borderRight: "none",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <MyDrawerContent {...props} />
      </Drawer>
    </Box>
  );
}

function MyDrawerContent(props: Props) {
  const navigate = useNavigate();

  const closeCompanyLink =
    props.companyState.selected_company_id &&
    props.companyState.show_company_detail_menu &&
    props.authenticationState.user.permissions &&
    props.authenticationState.user.permissions.indexOf("LIST_MY_COMPANIES") !== -1 ? (
      <MyMenuItem
        key="sidebar.external.close"
        onClick={() => {
          navigate("/companies");
        }}
      >
        <ListItemIcon sx={classes.menuItemIcon}>
          <ArrowBack sx={classes.menuItemIconContent} />
        </ListItemIcon>
        {intl.get("sidebar.external.close")}
      </MyMenuItem>
    ) : null;

  const logout = () => {
    props.actions.logout(true, props.authenticationState.user);
  };

  const isSelected = (path: any, exactMatch = false) => {
    const currentPath = location.pathname;
    if (currentPath == null) {
      return false;
    }
    if (exactMatch) {
      return currentPath === path;
    }
    return currentPath.startsWith(path) || currentPath.endsWith(path);
  };

  const inboxSize = props.inboxState.paging != null ? props.inboxState.paging.total_size : null;

  return (
    <div>
      <div
        role="button"
        style={{ height: 120, cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <HeaderLogoAndName />
      </div>
      <List>
        {closeCompanyLink}
        {closeCompanyLink != null && <Divider sx={classes.menuItemDivider} />}
        <WithPermission hasAnyPermission={["MANAGE_COMPANIES", "LIST_MY_COMPANIES"]}>
          <MyMenuItem
            selected={isSelected("/companies") && isSelected("/review") === false}
            key="sidebar.internal.companies"
            link={"/companies"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <BusinessIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.companies")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["MANAGE_PAYMENT_PLANS"]}>
          <MyMenuItem selected={isSelected("/paymentplans")} key="sidebar.internal.paymentplans" link={"/paymentplans"}>
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.paymentplans")}
          </MyMenuItem>
        </WithPermission>
        <WithPermission hasAnyPermission={["MANAGE_USERS"]}>
          <MyMenuItem selected={isSelected("/users")} key="sidebar.internal.users" link={"/users"}>
            <ListItemIcon sx={classes.menuItemIcon}>
              <UsersIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.users")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_RESELLER_PROFILE"]}>
          <MyMenuItem
            selected={
              props.authenticationState.user
                ? isSelected(`/resellers/${props.authenticationState.user.user_id}`, true)
                : false
            }
            key="sidebar.internal.resellerprofile"
            link={`/resellers/${props.authenticationState.user.user_id}`}
          >
            <ListItemIcon
              sx={classes.menuItemIcon}
              style={
                props.authenticationState.user &&
                props.authenticationState.user.reseller_profile &&
                userFilledResellerProfile(props.authenticationState.user.reseller_profile)
                  ? {}
                  : { color: "red" }
              }
            >
              <PeopleOutline sx={classes.menuItemIconContent} />
            </ListItemIcon>
            <ListItemText
              sx={(theme) => ({
                "& .Mui-primary": classes.menuItemContent(theme),
              })}
              primary={intl.get("sidebar.internal.resellerprofile")}
              style={
                props.authenticationState.user &&
                props.authenticationState.user.reseller_profile &&
                userFilledResellerProfile(props.authenticationState.user.reseller_profile)
                  ? {}
                  : { color: "red" }
              }
            />
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_RESELLER_EARNINGS"]}>
          <MyMenuItem
            selected={
              props.authenticationState.user
                ? isSelected(`/resellers/${props.authenticationState.user.user_id}/earnings`, true)
                : false
            }
            key="sidebar.internal.commissionearnings"
            link={`/resellers/${props.authenticationState.user.user_id}/earnings`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.commissionearnings")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_RESELLER_CREDIT_NOTES"]}>
          <MyMenuItem
            selected={
              props.authenticationState.user
                ? isSelected(`/resellers/${props.authenticationState.user.user_id}/credit-notes`, true)
                : false
            }
            key="sidebar.internal.creditnotes"
            link={`/resellers/${props.authenticationState.user.user_id}/credit-notes`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.creditnotes")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["REVIEW_COMPANY_EVENTS"]}>
          <MyMenuItem
            selected={isSelected("/inbox") || isSelected("/review")}
            key="sidebar.internal.inbox"
            link={"/inbox"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <InboxIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.inbox")}

            {inboxSize != null && inboxSize > 0 && (
              <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
                <InboxIndicator>{inboxSize}</InboxIndicator>
              </ListItemSecondaryAction>
            )}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["MANAGE_SEO_PAGES"]}>
          <MyMenuItem selected={isSelected("/seopages")} key="sidebar.internal.seopages" link={"/seopages"}>
            <ListItemIcon sx={classes.menuItemIcon}>
              <SearchIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.seopages")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["MANAGE_PLUGIN_API_KEYS"]}>
          <MyMenuItem
            selected={isSelected("/plugin_api_keys")}
            key="sidebar.internal.seopages"
            link={"/plugin_api_keys"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <VpnKeyIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.plugin_api_keys")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_GLOBAL_INVOICES"]}>
          <MyMenuItem
            selected={isSelected("/global_invoices")}
            key="sidebar.internal.invoices_payments"
            link={"/global_invoices"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <PaymentIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.invoices_payments")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["MANAGE_RESELLER_PROFILES"]}>
          <Divider sx={classes.menuItemDivider} />
          <MyMenuItem
            selected={isSelected("/commission_management")}
            key="sidebar.internal.commissionmanagement"
            link={"/commission_management"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <PeopleOutline sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.commissionmanagement")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_GLOBAL_RESELLER_EARNINGS"]}>
          <MyMenuItem
            selected={isSelected("/reseller-earnings")}
            key="sidebar.internal.globalcommissionearnings"
            link={"/reseller-earnings"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <ReportingIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.globalcommissionearnings")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_GLOBAL_RESELLER_CREDIT_NOTES"]}>
          <MyMenuItem
            selected={isSelected("/reseller-creditnotes")}
            key="sidebar.internal.globalcommissioncredits"
            link={"/reseller-creditnotes"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.globalcommissioncredits")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_GLOBAL_SALESPARTNER_EARNINGS"]}>
          <MyMenuItem
            selected={isSelected("/salespartner-earnings")}
            key="sidebar.internal.globalsalespartnerearnings"
            link={"/salespartner-earnings"}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <ReportingIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.globalsalespartnerearnings")}
          </MyMenuItem>
        </WithPermission>

        <WithPermission hasAnyPermission={["SHOW_GLOBAL_REPORT"]}>
          <MyMenuItem selected={isSelected("/reporting")} key="sidebar.internal.reporting" link={"/reporting"}>
            <ListItemIcon sx={classes.menuItemIcon}>
              <ReportingIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.internal.reporting")}
          </MyMenuItem>
        </WithPermission>

        <WithCompanyPermission
          hasAnyPermission={[
            "SHOW_COMPANY_EVENTS",
            "EDIT_COMPANY_EVENTS",
            "CREATE_COMPANY_EVENTS",
            "REVIEW_COMPANY_EVENTS",
          ]}
          renderExit={false}
        >
          <MyMenuItem
            selected={isSelected("/company_events") || isSelected("/review")}
            key="sidebar.external.events"
            link={`/companies/${props.companyState.selected_company_id}/company_events`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <EventIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.events")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["EDIT_COMPANY_EVENTS", "CREATE_COMPANY_EVENTS"]} renderExit={false}>
          <MyMenuItem
            selected={isSelected("/company_shared_locations")}
            key="sidebar.external.shared_locations"
            link={`/companies/${props.companyState.selected_company_id}/company_shared_locations`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <LocationIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.shared_locations")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["SHOW_COMPANY_INFO", "EDIT_COMPANY_INFO"]} renderExit={false}>
          <MyMenuItem
            selected={isSelected("/company_info")}
            key="sidebar.external.company"
            link={`/companies/${props.companyState.selected_company_id}/company_info`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <BusinessIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.company")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["MANAGE_COMPANIES"]} renderExit={false}>
          <MyMenuItem
            selected={isSelected("/activity_logs")}
            key="sidebar.external.activity_logs"
            link={`/companies/${props.companyState.selected_company_id}/activity_logs`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <TimelineIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.activity_logs")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["SHOW_COMPANY_USERS", "MANAGE_COMPANY_USERS"]} renderExit={false}>
          <MyMenuItem
            selected={isSelected("/users")}
            key="sidebar.external.users"
            link={`/companies/${props.companyState.selected_company_id}/users`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <UsersIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.users")}
          </MyMenuItem>
        </WithCompanyPermission>

        {props.companyState.selected_company_id &&
          props.companyState.company &&
          props.companyState.company.status === "ACTIVE" && (
            <WithCompanyPermission hasAnyPermission={["MANAGE_PAYMENT_PLANS"]} renderExit={false}>
              <MyMenuItem
                selected={isSelected("/admin_credits")}
                key="sidebar.external.admin_credits"
                link={`/companies/${props.companyState.selected_company_id}/admin_credits`}
              >
                <ListItemIcon sx={classes.menuItemIcon}>
                  <RedeemIcon sx={classes.menuItemIconContent} />
                </ListItemIcon>
                {"Credits"}
              </MyMenuItem>
            </WithCompanyPermission>
          )}

        <WithCompanyPermission
          hasAnyPermission={["SHOW_COMPANY_PAYMENT_DATA", "EDIT_COMPANY_PAYMENT_DATA"]}
          renderExit={false}
        >
          <MyMenuItem
            selected={isSelected("/plan")}
            key="sidebar.external.payment"
            link={`/companies/${props.companyState.selected_company_id}/plan`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.payment")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission
          hasAnyPermission={["SHOW_COMPANY_PAYMENT_DATA", "EDIT_COMPANY_PAYMENT_DATA"]}
          renderExit={false}
        >
          <MyMenuItem
            selected={isSelected("/invoices")}
            key="sidebar.external.invoices"
            link={`/companies/${props.companyState.selected_company_id}/invoices`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.invoices")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["SHOW_SALESPARTNER_EARNINGS"]} renderExit={false}>
          <MyMenuItem
            key={"sidebar.external.salespartner_earnings"}
            link={`/companies/${props.companyState.selected_company_id}/salespartner-earnings`}
            selected={isSelected("/salespartner-earnings")}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MonetizationOnIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.salespartner_earnings")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["SHOW_COMPANY_EVENTS"]} renderExit={false}>
          <MyMenuItem
            selected={isSelected("/media")}
            key="sidebar.external.media"
            link={`/companies/${props.companyState.selected_company_id}/media`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <MediaIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.media")}
          </MyMenuItem>
        </WithCompanyPermission>

        <WithCompanyPermission hasAnyPermission={["EDIT_COMPANY_EVENTS", "SHOW_COMPANY_EVENTS"]} renderExit={false}>
          <MyMenuItem
            selected={isSelected("/reporting")}
            key="sidebar.external.reporting"
            link={`/companies/${props.companyState.selected_company_id}/reporting`}
          >
            <ListItemIcon sx={classes.menuItemIcon}>
              <ReportingIcon sx={classes.menuItemIconContent} />
            </ListItemIcon>
            {intl.get("sidebar.external.reporting")}
          </MyMenuItem>
        </WithCompanyPermission>
      </List>

      <Divider sx={classes.menuItemDivider} />

      <List>
        <MyMenuItem
          selected={isSelected("/user/")}
          key="sidebar.external.settings"
          link={`/user/${props.authenticationState.user.user_id}`}
        >
          <ListItemIcon sx={classes.menuItemIcon}>
            <SettingsIcon sx={classes.menuItemIconContent} />
          </ListItemIcon>
          {intl.get("sidebar.common.settings")}
        </MyMenuItem>

        <MyMenuItem key="sidebar.common.logout" onClick={logout}>
          <ListItemIcon sx={classes.menuItemIcon}>
            <LogoutIcon sx={classes.menuItemIconContent} />
          </ListItemIcon>
          {intl.get("sidebar.common.logout")}
        </MyMenuItem>
      </List>

      <Box
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          color: theme.palette.primary.main,
          marginBottom: "5px",
        })}
      >
        <ExternalLink href={process.env.REACT_APP_IMPRESSUM}>{intl.get("impressum")}</ExternalLink>
        <ExternalLink href="https://family-cherries.com/impressum">{intl.get("signup.agbs_title")}</ExternalLink>
        <ExternalLink href="https://family-cherries.com/datenschutz">
          {intl.get("signup.privacy_statement_title")}
        </ExternalLink>
      </Box>
    </div>
  );
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyState: state.companyState,
    inboxState: state.inboxState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDrawer);
