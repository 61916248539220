import { Box, IconButton, TableCell, ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as React from "react";
import * as intl from "react-intl-universal";
import ClearIcon from "@mui/icons-material/Clear";
import { BCEventRangeOpeningHours } from "../../../../model/event";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import moment from "moment";
import "moment/locale/de";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  openingHour: BCEventRangeOpeningHours;
  onRemove: any;
  disabled: boolean;
  onChange: (newValue: BCEventRangeOpeningHours) => void;
}

function EventOpeningHourEdit({ openingHour, disabled, onRemove, onChange }: Props) {
  function onChangeTime(range: DateRange) {
    const newValue = { ...openingHour };
    newValue.time_start = moment(range[0]).locale("de").format("HH:mm");
    newValue.time_end = moment(range[1]).locale("de").format("HH:mm");
    if (moment(range[0]).isAfter(moment(range[1]))) {
      newValue.time_end = newValue.time_start;
    }
    onChange(newValue);
  }

  function selectedDays() {
    if (openingHour.day_selection) {
      return openingHour.day_selection.split(",");
    }
    return [];
  }

  function changeSelectedDays(days: string[]) {
    onChange({ ...openingHour, day_selection: days.join(",") });
  }

  function getCurrentValue(): DateRange {
    if (openingHour.time_start && openingHour.time_end) {
      const date = moment(new Date()).locale("de").format("YYYY-MM-DD");
      const start = openingHour.time_start ? moment(`${date}T${openingHour.time_start}`).toDate() : null;
      const end = openingHour.time_end ? moment(`${date}T${openingHour.time_end}`).toDate() : null;
      return [start, end];
    }
    return null;
  }

  return (
    <>
      <TableCell sx={{ padding: 0 }} padding="none" align="left">
        <Box sx={AppStyles.toggleContainer}>
          <ToggleButtonGroup
            value={selectedDays()}
            onChange={(event, value) => {
              changeSelectedDays(value);
            }}
          >
            {["mon", "tue", "wed", "thu", "fri", "sat", "sun", "hol"].map((day) => {
              return (
                <ToggleButton disabled={disabled} key={day} value={day}>
                  {intl.get(`days.long.${day}`)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <DateRangePicker
            isoWeek={true}
            readOnly={disabled}
            style={{ paddingLeft: "16px" }}
            cleanable={false}
            format="HH:mm"
            ranges={[]}
            onOk={(range) => {
              onChangeTime(range);
            }}
            onChange={(range) => {
              if (range) {
                onChangeTime(range);
              }
            }}
            value={getCurrentValue()}
          />
        </Box>
      </TableCell>

      {disabled === false && (
        <TableCell align="right">
          <IconButton
            onClick={() => {
              onRemove();
            }}
            color="secondary"
            size="large"
          >
            <ClearIcon />
          </IconButton>
        </TableCell>
      )}
    </>
  );
}

export default EventOpeningHourEdit;
