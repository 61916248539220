import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { RootState } from "../../reducers";
import * as CompanyActions from "../../actions/company";
import * as CompanyProductActions from "../../actions/company.product.actions";
import { CompanyState } from "../../model/companyState";
import { PaymentState } from "../../model/paymentState";
import { AuthenticationState } from "../../model/authenticationState";
import { formattedCreditType } from "../../model/payment/payment.product";
import { CompanyCreditBalance } from "../../model/payment/company.credit.balance";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import { PageTracker } from "../../util/pageTracker";
import AppStyles from "../../styles/appStyles";

export interface Props {
  companyState: CompanyState;
  paymentState: PaymentState;
  authenticationState: AuthenticationState;
  actions: typeof CompanyActions;
  productActions: typeof CompanyProductActions;
}

export interface State {
  showRedeemConfirmationDialog: boolean;
  showGrantConfirmationDialog: boolean;

  showRedeemSuccessConfirmation: boolean;
  showGrantSuccessConfirmation: boolean;

  creditToHandle: string | null;
}

class AdminCompanyCreditsPage extends React.Component<Props> {
  state = {
    showRedeemConfirmationDialog: false,
    showGrantConfirmationDialog: false,
    showRedeemSuccessConfirmation: false,
    showGrantSuccessConfirmation: false,
    creditToHandle: null,
  };

  componentDidMount(): void {
    this.props.productActions.loadCompanyCredits(this.props.companyState.selected_company_id);
  }

  confirmRedeem() {
    this.props.productActions.redeemCredit(
      this.props.companyState.selected_company_id,
      this.state.creditToHandle,
      () => {
        this.setState({ showRedeemSuccessConfirmation: true });
      },
    );
    this.setState({
      creditToHandle_: null,
      showRedeemConfirmationDialog: false,
    });
  }

  rejectRedeem() {
    this.setState({
      creditToHandle_: null,
      showRedeemConfirmationDialog: false,
    });
  }

  confirmGrant() {
    this.props.productActions.grantCredit(
      this.props.companyState.selected_company_id,
      this.state.creditToHandle,
      () => {
        this.setState({ showGrantSuccessConfirmation: true });
      },
    );
    this.setState({
      creditToHandle_: null,
      showGrantConfirmationDialog: false,
    });
  }

  rejectGrant() {
    this.setState({
      creditToHandle_: null,
      showGrantConfirmationDialog: false,
    });
  }

  renderCredit(credit: CompanyCreditBalance) {
    const formatted = formattedCreditType(credit.credit_type);
    return (
      <TableRow>
        <TableCell scope="row" sx={AppStyles.tableCellNotSelectable}>
          <b>{credit.count}x</b> {formatted}
        </TableCell>
        <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable} style={{ textAlign: "end" }}>
          {credit.count > 0 && (
            <LinkButton
              color={"error"}
              onClick={(event: any) => {
                event.stopPropagation();
                this.setState({
                  creditToHandle: credit.credit_type,
                  showRedeemConfirmationDialog: true,
                });
              }}
              title={"1 Credit abziehen"}
            />
          )}
          <LinkButton
            onClick={(event: any) => {
              event.stopPropagation();
              this.setState({
                creditToHandle: credit.credit_type,
                showGrantConfirmationDialog: true,
              });
            }}
            title={"1 Credit gutschreiben"}
          />
        </TableCell>
      </TableRow>
    );
  }

  renderCredits() {
    if (!this.props.paymentState.credits || this.props.paymentState.credits.length === 0) {
      return undefined;
    }

    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>Credits</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton sx={AppStyles.buttonPlaceholder} />
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableBody>{this.props.paymentState.credits.map((value) => this.renderCredit(value))}</TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  render() {
    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          {this.state.showRedeemConfirmationDialog && (
            <Dialog
              open={this.state.showRedeemConfirmationDialog}
              onClose={() => this.rejectRedeem()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Credit abziehen</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Möchten Sie wirklich einen Credit abziehen?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.rejectRedeem()} color="primary">
                  Abbrechen
                </Button>
                <Button onClick={() => this.confirmRedeem()} color="error" autoFocus>
                  Ja, abziehen
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {this.state.showGrantConfirmationDialog && (
            <Dialog
              open={this.state.showGrantConfirmationDialog}
              onClose={() => this.rejectGrant()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Credit gutschreiben</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Möchten Sie wirklich einen Credit gutschreiben?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.rejectGrant()} color="primary">
                  Abbrechen
                </Button>
                <Button onClick={() => this.confirmGrant()} color="primary" autoFocus>
                  Ja, gutschreiben
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {this.state.showRedeemSuccessConfirmation && (
            <Dialog
              open={this.state.showRedeemSuccessConfirmation}
              onClose={() => {
                this.setState({ showRedeemSuccessConfirmation: false });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Credit abezogen</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">Credit wurde erfolgreich abgezogen</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ showRedeemSuccessConfirmation: false })}
                  color="primary"
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {this.state.showGrantSuccessConfirmation && (
            <Dialog
              open={this.state.showGrantSuccessConfirmation}
              onClose={() => {
                this.setState({ showGrantSuccessConfirmation: false });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Credit gutgeschrieben</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Credit wurde erfolgreich gutgeschrieben
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ showGrantSuccessConfirmation: false })}
                  color="primary"
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {this.renderCredits()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    paymentState: state.paymentState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
    productActions: bindActionCreators(CompanyProductActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminCompanyCreditsPage);
