import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import * as intl from "react-intl-universal";
import { CompanyState } from "../model/companyState";
import * as CompanyActions from "../actions/company";
import { DefaultButton } from "../components/Buttons";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { RootState } from "../reducers";
import { PageState, PageStateType } from "../model/pageState";
import { Pagination } from "../components/TablePagination";
import { shortFormattedCredits } from "../model/payment/payment.product";
import { AuthenticationState } from "../model/authenticationState";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import { Company } from "../model/company";
import { formattedShortInfoForCompanyProduct } from "../model/payment_info";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import AppStyles from "../styles/appStyles";

const classes = {
  tableCellDelete: (theme: Theme) => ({
    color: theme.colors.red,
  }),
};

export interface Props {
  companyState: CompanyState;
  pageState: PageState;
  actions: typeof CompanyActions;
  authenticationState: AuthenticationState;
}

export interface State {}

function CompaniesPage(props: Props) {
  const theme = useTheme();
  const handleChangePage = (event: any, page: any) => {
    props.companyState.paging.current_page = page + 1;
    props.actions.fetchAllCompanies(
      props.companyState.paging.current_page,
      props.companyState.paging.page_size,
      props.companyState.searchCriteria,
    );
  };

  const handleChangeRowsPerPage = (event: any) => {
    props.companyState.paging.page_size = event.target.value;
    props.actions.fetchAllCompanies(
      props.companyState.paging.current_page,
      props.companyState.paging.page_size,
      props.companyState.searchCriteria,
    );
  };

  const resetSearch = () => {
    props.companyState.paging.current_page = 1;
    props.actions.fetchAllCompanies(
      props.companyState.paging.current_page,
      props.companyState.paging.page_size,
      props.companyState.searchCriteria,
    );
  };

  useEffect(() => {
    props.actions.fetchAllCompanies(
      props.companyState.paging.current_page,
      props.companyState.paging.page_size,
      props.companyState.searchCriteria,
    );
  }, []);

  const navigate = useNavigate();

  function selectCompany(company_id: string) {
    navigate(`/companies/${company_id}/company_events`);
  }

  function editCompany(company_id: string) {
    navigate(`/companies/${company_id}/edit?showCompanyDetailMenu=false`);
  }

  function createNewCompany() {
    navigate("/companies/new");
  }

  function renderCompanyTitle() {
    return (
      <TextFieldWithLabel
        id={"company_name"}
        margin="normal"
        onChange={(event) => {
          props.actions.updateCompanySearchCriteriaFormField({
            prop: "searchCriteria.text",
            value: event.target.value,
          });
        }}
        onReturnEnabled={true}
        onReturnPressed={() => {
          resetSearch();
        }}
        placeholder={"Suche nach Firmenname..."}
        value={props.companyState.searchCriteria.text}
      />
    );
  }

  function renderSearchButton() {
    return (
      <DefaultButton
        size={"large"}
        onClick={() => {
          resetSearch();
        }}
      >
        <AddIcon sx={AppStyles.buttonLeftIcon} />
        Suchen
      </DefaultButton>
    );
  }

  function renderIncludeDeleted() {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={props.companyState.searchCriteria.includeDeleted}
            onChange={(event: any, checked: boolean) => {
              props.actions.updateCompanySearchCriteriaFormField({
                prop: "searchCriteria.includeDeleted",
                value: checked,
              });
              resetSearch();
            }}
          />
        }
        label={"Gelöschte anzeigen"}
      />
    );
  }

  function renderOnlySuspended() {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={props.companyState.searchCriteria.onlyCompaniesWithSuspendedUpgrades}
            onChange={(event: any, checked: boolean) => {
              props.actions.updateCompanySearchCriteriaFormField({
                prop: "searchCriteria.onlyCompaniesWithSuspendedUpgrades",
                value: checked,
              });
              resetSearch();
            }}
          />
        }
        label={"Pausierte Upgrades"}
      />
    );
  }

  function renderOnlyActiveSubscriptions() {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={props.companyState.searchCriteria.onlyCompaniesWithActiveSubscriptions}
            onChange={(event: any, checked: boolean) => {
              props.actions.updateCompanySearchCriteriaFormField({
                prop: "searchCriteria.onlyCompaniesWithActiveSubscriptions",
                value: checked,
              });
              resetSearch();
            }}
          />
        }
        label={"Aktive Pakete"}
      />
    );
  }

  function renderOnlyUnusedCredits() {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={props.companyState.searchCriteria.onlyCompaniesWithUnusedCredits}
            onChange={(event: any, checked: boolean) => {
              props.actions.updateCompanySearchCriteriaFormField({
                prop: "searchCriteria.onlyCompaniesWithUnusedCredits",
                value: checked,
              });
              resetSearch();
            }}
          />
        }
        label={"Ungenutze Credits"}
      />
    );
  }

  function renderOnlyUnusedPremium() {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={props.companyState.searchCriteria.onlyCompaniesWithUnusedPremiumSlots}
            onChange={(event: any, checked: boolean) => {
              props.actions.updateCompanySearchCriteriaFormField({
                prop: "searchCriteria.onlyCompaniesWithUnusedPremiumSlots",
                value: checked,
              });
              if (checked) {
                props.actions.updateCompanySearchCriteriaFormField({
                  prop: "searchCriteria.onlyCompaniesWithActiveSubscriptions",
                  value: true,
                });
              }
              resetSearch();
            }}
          />
        }
        label={"Ungenutze Premium"}
      />
    );
  }

  function renderOnlyUnusedPremiumPlus() {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={props.companyState.searchCriteria.onlyCompaniesWithUnusedPremiumPlusSlots}
            onChange={(event: any, checked: boolean) => {
              props.actions.updateCompanySearchCriteriaFormField({
                prop: "searchCriteria.onlyCompaniesWithUnusedPremiumPlusSlots",
                value: checked,
              });
              if (checked) {
                props.actions.updateCompanySearchCriteriaFormField({
                  prop: "searchCriteria.onlyCompaniesWithActiveSubscriptions",
                  value: true,
                });
              }
              resetSearch();
            }}
          />
        }
        label={"Ungenutzte Premium Plus"}
      />
    );
  }

  function renderActiveProductsForCompany(row: Company) {
    if (row.active_products !== null && row.active_products !== undefined && row.active_products.length > 0) {
      return (
        <TableCell sx={AppStyles.tableCell}>
          <Tooltip
            style={{
              backgroundColor: "#f5f5f9",
              color: "rgba(0, 0, 0, 0.87)",
              maxWidth: "none",
              fontSize: theme.typography.pxToRem(12),
              border: "1px solid #dadde9",
              padding: "12px",
            }}
            title={
              <React.Fragment>
                <Typography color="primary" variant={"h6"}>
                  Aktive Pakete für {row.name}
                </Typography>
                {row.active_products.map((value) => {
                  return (
                    <div key={value.company_product_id}>
                      <Typography color="inherit" variant={"h6"}>
                        {value.name} {value.status === "ACTIVE" ? "" : "(keine Verlängerung)"}
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formattedShortInfoForCompanyProduct(value),
                        }}
                      />
                    </div>
                  );
                })}
              </React.Fragment>
            }
          >
            <div>{row.number_of_active_subscriptions !== 0 ? row.number_of_active_subscriptions : "-"}</div>
          </Tooltip>
        </TableCell>
      );
    } else {
      return (
        <TableCell sx={AppStyles.tableCell}>
          {row.number_of_active_subscriptions !== 0 ? row.number_of_active_subscriptions : "-"}
        </TableCell>
      );
    }
  }

  if (props.pageState.state === PageStateType.Loading) {
    return <></>;
  }

  let hasPermissionToShowCompanyPaymentInformation = false;
  if (props.authenticationState.user && props.authenticationState.user.permissions) {
    if (props.authenticationState.user.permissions.indexOf("MANAGE_COMPANIES") !== -1) {
      hasPermissionToShowCompanyPaymentInformation = true;
    }
  }

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("companies.title")}</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton
              size={"large"}
              onClick={() => {
                createNewCompany();
              }}
            >
              <AddIcon sx={AppStyles.buttonLeftIcon} />
              {intl.get("companies.button_add")}
            </DefaultButton>
          </Grid>
        </Grid>

        {hasPermissionToShowCompanyPaymentInformation && (
          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={{ ...AppStyles.detailForm, marginTop: 0 }}>
              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                {renderCompanyTitle()}
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent={"center"}
                direction={"column"}
                sx={AppStyles.detailFormRowNoBorder}
              >
                {renderSearchButton()}
              </Grid>
              <Grid item container direction={"row"} sx={AppStyles.detailFormRowNoBorder}>
                <Grid
                  item
                  xs={12}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  sx={AppStyles.detailFormRowNoBorder}
                >
                  {renderIncludeDeleted()}
                  {renderOnlyActiveSubscriptions()}
                  {renderOnlyUnusedCredits()}
                  {renderOnlyUnusedPremium()}
                  {renderOnlyUnusedPremiumPlus()}
                  {renderOnlySuspended()}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("companies.list_header_name")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("companies.list_header_email")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("companies.list_header_phone")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("companies.list_header_address")}
                </TableCell>
                {hasPermissionToShowCompanyPaymentInformation && (
                  <TableCell padding="none" sx={AppStyles.tableCell}>
                    Aktive Pakete
                  </TableCell>
                )}
                {hasPermissionToShowCompanyPaymentInformation && (
                  <TableCell padding="none" sx={AppStyles.tableCell}>
                    Premium
                  </TableCell>
                )}
                {hasPermissionToShowCompanyPaymentInformation && (
                  <TableCell padding="none" sx={AppStyles.tableCell}>
                    Premium Plus
                  </TableCell>
                )}
                {hasPermissionToShowCompanyPaymentInformation && (
                  <TableCell padding="none" sx={AppStyles.tableCell}>
                    Credits
                  </TableCell>
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {props.companyState.allCompanies != null &&
                props.companyState.allCompanies.map((row) => {
                  return (
                    <TableRow
                      key={row.company_id}
                      onClick={() => {
                        selectCompany(row.company_id);
                      }}
                      hover
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={row.status === "DELETED" ? classes.tableCellDelete : AppStyles.tableCell}
                      >
                        {row.name} {row.editorial ? intl.get("company.editorial_hint") : ""}
                        {row.status === "DELETED" ? " (GELÖSCHT)" : ""}
                      </TableCell>
                      <TableCell sx={AppStyles.tableCell}>{row.email}</TableCell>
                      <TableCell sx={AppStyles.tableCell}>{row.phone}</TableCell>
                      <TableCell sx={AppStyles.tableCell}>{row.address}</TableCell>
                      {hasPermissionToShowCompanyPaymentInformation && renderActiveProductsForCompany(row)}
                      {hasPermissionToShowCompanyPaymentInformation && (
                        <TableCell
                          sx={
                            row.has_suspended_event_upgrades
                              ? { ...AppStyles.tableCell, ...classes.tableCellDelete }
                              : AppStyles.tableCell
                          }
                        >
                          {row.premium_slots !== 0 ? `${row.premium_slots_used} / ${row.premium_slots}` : "-"}
                        </TableCell>
                      )}
                      {hasPermissionToShowCompanyPaymentInformation && (
                        <TableCell
                          sx={
                            row.has_suspended_event_upgrades
                              ? { ...AppStyles.tableCell, ...classes.tableCellDelete }
                              : AppStyles.tableCell
                          }
                        >
                          {row.premium_plus_slots !== 0
                            ? `${row.premium_plus_slots_used} / ${row.premium_plus_slots}`
                            : "-"}
                        </TableCell>
                      )}
                      {hasPermissionToShowCompanyPaymentInformation && (
                        <TableCell
                          sx={AppStyles.tableCell}
                          dangerouslySetInnerHTML={{
                            __html: shortFormattedCredits(
                              "<br/>",
                              row.hero_credits,
                              row.social_credits,
                              row.newsletter_credits,
                            ),
                          }}
                        />
                      )}
                      <TableCell align="right" sx={AppStyles.tableCell}>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            editCompany(row.company_id);
                          }}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <Pagination
                  paging={props.companyState.paging}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    pageState: state.pageState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompaniesPage);
