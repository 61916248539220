import { Box, IconButton, TablePagination, Theme, useTheme } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import * as React from "react";
import * as intl from "react-intl-universal";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { PagingInformation } from "../model/pagingInformation";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";

const classes = {
  root: (theme: Theme) => ({
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  }),
};

export interface BaseProps {
  count: number;
  page: number;
  rowsPerPage: number;
}

const aPagination = function (props: {
  paging: PagingInformation;
  handleChangePage: (event: any, page: number) => void;
  handleChangeRowsPerPage: (event: any) => void;
}) {
  return (
    <TablePagination
      labelDisplayedRows={(paginationInfo) => {
        return intl.get("pagination.show_results", {
          from: paginationInfo.from,
          to: paginationInfo.to,
          count: paginationInfo.count,
        });
      }}
      labelRowsPerPage={intl.get("pagination.rows_per_page")}
      rowsPerPageOptions={[10, 25, 50]}
      colSpan={4}
      count={props.paging?.total_size || 0}
      rowsPerPage={props.paging?.page_size || 0}
      page={props.paging?.current_page ? props.paging.current_page - 1 : 0}
      SelectProps={{
        native: true,
      }}
      onPageChange={props.handleChangePage}
      onRowsPerPageChange={props.handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActionsWrapped}
    />
  );
};

export interface ActionsProps extends BaseProps, Omit<TablePaginationActionsProps, "classes"> {} // TODO: have a look at incompatible classes

function TablePaginationActions(props: ActionsProps) {
  const handleFirstPageButtonClick = (event: any) => {
    props.onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    props.onPageChange(event, props.page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    props.onPageChange(event, props.page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    props.onPageChange(event, Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1));
  };

  const theme = useTheme();

  const { count, page, rowsPerPage } = props;

  return (
    <Box sx={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={intl.get("pagination.first_page")}
        size="large"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={intl.get("pagination.previous_page")}
        size="large"
      >
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={intl.get("pagination.next_page")}
        size="large"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={intl.get("pagination.last_page")}
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const TablePaginationActionsWrapped = TablePaginationActions;

export const Pagination = aPagination;
