import BookingDrawerInfoSection from "../components/BookingDrawerInfoSection";
import NewsletterIcon from "../../../assets/icons/Newsletter";
import intl from "react-intl-universal";
import { Stack, Typography } from "@mui/material";
import BookingDrawerInfoSectionFeature from "../components/BookingDrawerInfoSectionFeature";
import { formattedProductFeaturesNew, PaymentProduct } from "../../../model/payment/payment.product";

export default function PremiumDrawerInfoSection({ product }: { product?: PaymentProduct }) {
  const features = product
    ? formattedProductFeaturesNew(product)
    : [
        intl.getHTML("booking_drawer_info.premium_upgrade.feature1"),
        intl.getHTML("booking_drawer_info.premium_upgrade.feature2"),
        intl.getHTML("booking_drawer_info.premium_upgrade.feature3"),
      ];

  return (
    <BookingDrawerInfoSection
      icon={<NewsletterIcon sx={{ width: "33px", height: "27px" }} />}
      title={intl.get("booking_drawer_info.premium_upgrade.title")}
      text1={intl.get("booking_drawer_info.premium_upgrade.text1")}
      features={features}
    >
      <Typography sx={{ font: "normal normal bold 18px/21px Manrope", marginTop: "26px" }}>
        {intl.get("booking_drawer_info.premium_upgrade.header_premium_plus")}
      </Typography>
      <Stack gap={"7px"} sx={{ marginTop: "22px" }}>
        <BookingDrawerInfoSectionFeature
          title={intl.get("booking_drawer_info.premium_upgrade.feature_plus_1")}
          bold
          color={"primary"}
        />
        <BookingDrawerInfoSectionFeature
          title={intl.get("booking_drawer_info.premium_upgrade.feature_plus_2")}
          bold
          color={"primary"}
        />
        <BookingDrawerInfoSectionFeature
          title={intl.get("booking_drawer_info.premium_upgrade.feature_plus_3")}
          bold
          color={"primary"}
        />
      </Stack>
    </BookingDrawerInfoSection>
  );
}
