import * as React from "react";
import { AuthenticationState } from "../../model/authenticationState";
import { RootState } from "../../reducers";
import { connect } from "react-redux";
import { AuthUtils } from "./AuthUtils";
import NavigateWithSearchAndState from "./NavigateWithSearchAndState";

interface Props {
  authenticationState: AuthenticationState;
  children?: any;
  ignoreSetupNeeded?: boolean;
}

function PrivateGuard({ children, authenticationState, ignoreSetupNeeded }: Props) {
  if (AuthUtils.setupNeeded(authenticationState) && !ignoreSetupNeeded) {
    return <NavigateWithSearchAndState to={"/setupProfile"} />;
  } else if (AuthUtils.isAuthenticated(authenticationState)) {
    return children;
  } else {
    return <NavigateWithSearchAndState to={"/login"} />;
  }
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateGuard);
