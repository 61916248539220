import { Box, FormControl, Grid, Paper, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import Select from "@mui/material/Select";
import SaveIcon from "@mui/icons-material/Check";
import { UserFormState } from "../../../model/userFormState";
import { RootState } from "../../../reducers";
import * as UserFormActions from "../../../actions/addUserForm";
import { BackButton, DefaultButton } from "../../../components/Buttons";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import { SectionLabel } from "../../../components/SectionLabel";
import AppStyles from "../../../styles/appStyles";

export interface BaseProps {
  createUser(): void;

  closePage(): void;
}

export interface Props extends BaseProps {
  userFormState: UserFormState;
  actions: typeof UserFormActions;
  children?: any;

  createUser(): void;
}

export interface State {}

class AddUserForm extends React.Component<Props> {
  handleChange = (propName: any) => (event: any) => {
    this.props.actions.updateUserFormField({
      prop: propName,
      value: event.target.value,
    });
  };

  componentDidMount(): void {
    this.props.actions.createUser();
  }

  closePage(): void {
    this.props.closePage();
  }

  render() {
    if (this.props.userFormState.closePage) {
      this.props.actions.resetUserForm();
      this.closePage();
      return <div />;
    }

    const disableSave = !this.props.userFormState.valid;

    return (
      <Box sx={AppStyles.innerContentRoot}>
        <Grid container direction="row">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.actionHeaderLeft}>
              <BackButton
                title={intl.get("user_form.title_create")}
                onClick={() => {
                  this.closePage();
                }}
              />
            </Grid>

            <Grid item sx={AppStyles.headerRight}>
              <DefaultButton disabled={disableSave} onClick={this.props.createUser}>
                <SaveIcon sx={AppStyles.buttonLeftIcon} />
                {intl.get("user_form.button_add")}
              </DefaultButton>
            </Grid>
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Tabs value={0} indicatorColor="primary" textColor="primary">
            <Tab label={intl.get("user_form.tab_info")} />
          </Tabs>

          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <Grid container>
              <Grid item xs={12} sm={4} container direction="column">
                <TextFieldWithLabel
                  required
                  label={intl.get("user_form.label.email")}
                  id="email"
                  onChange={this.handleChange("user.email")}
                  value={this.props.userFormState.user.email}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 25 }}>
              <Grid item xs={12} sm={4} container direction="column">
                <FormControl>
                  <SectionLabel required>{intl.get("user_form.label.role")}</SectionLabel>
                  <Select
                    displayEmpty
                    onChange={this.handleChange("user.role")}
                    value={this.props.userFormState.user.role}
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "7px",
                      marginTop: "16px",
                    }}
                    inputProps={{
                      name: "role",
                      id: "role",
                    }}
                  >
                    {this.props.children}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    userFormState: state.userFormState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(UserFormActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddUserForm);
