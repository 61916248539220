import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { BackendLoader } from "../../../hooks/useBackendLoader";
import intl from "react-intl-universal";
import { useEffect } from "react";

interface Props {
  children?: any;
  loader: BackendLoader<any>;
  onDone?: () => void;
  onError?: (error: any) => void;
}
export default function BookingDrawerLoaderWrapper({ children, loader, onDone, onError }: Props) {
  useEffect(() => {
    if (loader.error && onError) {
      onError(loader.error);
    }
  }, [loader.error]);

  if (loader.loading) {
    return (
      <Box sx={{ display: "flex", marginTop: "40px" }}>
        <CircularProgress sx={{ marginLeft: "auto", marginRight: "auto" }} size={"23px"} />
      </Box>
    );
  }

  if (!onError && loader.error) {
    return (
      <Stack>
        <Typography variant={"body2"} color={"error"}>
          {intl.get("error.generic")}
        </Typography>
        {onDone && (
          <Button fullWidth={false} onClick={onDone} sx={{ marginRight: "auto", marginTop: "21px" }}>
            {intl.get("generic.btn_close")}
          </Button>
        )}
      </Stack>
    );
  }

  if (onError && loader.error) {
    return null;
  }

  return children;
}
