import { formattedProductFeaturesNew, PaymentProduct } from "../../../model/payment/payment.product";
import intl from "react-intl-universal";
import BookingDrawerInfoSection from "../components/BookingDrawerInfoSection";
import NewsletterIcon from "../../../assets/icons/Newsletter";

export default function PremiumPlusDrawerInfoSection({ product }: { product?: PaymentProduct }) {
  const features = product
    ? formattedProductFeaturesNew(product)
    : [
        intl.getHTML("booking_drawer_info.premium_upgrade.feature1"),
        intl.getHTML("booking_drawer_info.premium_upgrade.feature2"),
        intl.getHTML("booking_drawer_info.premium_upgrade.feature3"),
      ];

  return (
    <BookingDrawerInfoSection
      icon={<NewsletterIcon sx={{ width: "33px", height: "27px" }} />}
      title={intl.get("booking_drawer_info.premium_plus_upgrade.title")}
      text1={intl.get("booking_drawer_info.premium_plus_upgrade.text1")}
      features={features}
    ></BookingDrawerInfoSection>
  );
}
