import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { CompanyState } from "../../../../../model/companyState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { AuthenticationState } from "../../../../../model/authenticationState";
import * as React from "react";
import { FormGridItem } from "../FormGridItem";
import { EventEditCheckboxField } from "../fields/EventEditCheckboxField";
import { EventEditMultiSelectField } from "../fields/EventEditMultiSelectField";
import { Constants } from "../event_options";
import { EventEditNumberInputField } from "../fields/EventEditNumberInputField";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventGeneralInfoLocationEdit extends React.Component<Props> {
  renderIsIndoor() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_indoor_event"}
      />
    );
  }

  renderIsOutdoor() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_outdoor_event"}
      />
    );
  }

  renderIsOnlineEvent() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_online_event"}
      />
    );
  }

  renderReachabilityOptions() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.reachabilityOptions()}
        field={"reachability_options"}
      />
    );
  }

  renderIsParkingAvailable() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_parking_available"}
        withLabel={true}
      />
    );
  }

  renderNumberOfParkingSpots() {
    return (
      <EventEditNumberInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"number_of_parking_spots"}
      />
    );
  }

  renderDistancePublicTransport() {
    return (
      <EventEditNumberInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"distance_public_transport"}
      />
    );
  }

  renderSurroundingsType() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.surroundingsTypeOptions()}
        field={"surroundings_type"}
      />
    );
  }

  renderSchadeOptions() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.shadeTypeOptions()}
        field={"shade_type"}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderIsIndoor()}
          </FormGridItem>
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderIsOutdoor()}
          </FormGridItem>
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderIsOnlineEvent()}
          </FormGridItem>
          <FormGridItem md={3}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem>{this.renderReachabilityOptions()}</FormGridItem>

          <FormGridItem md={3}>{this.renderIsParkingAvailable()}</FormGridItem>
          <FormGridItem md={3}>{this.renderNumberOfParkingSpots()}</FormGridItem>
          <FormGridItem md={6}>{this.renderDistancePublicTransport()}</FormGridItem>

          <FormGridItem>{this.renderSurroundingsType()}</FormGridItem>

          <FormGridItem md={6}>{this.renderSchadeOptions()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventGeneralInfoLocationEdit;
