import { PagingInformation } from "./pagingInformation";
import {
  ResellerSalespartnerAccountingEntry,
  ResellerSalespartnerEarningsReport,
} from "./resellerSalespartnerAccounting";

export interface ResellerSalespartnerEarningsState {
  singleEntries: ResellerSalespartnerAccountingEntry[];
  globalResellerEarnings: ResellerSalespartnerEarningsReport[];
  globalSalespartnerEarnings: ResellerSalespartnerEarningsReport[];
  globalCreditNotes: ResellerSalespartnerEarningsReport[];
  singleCreditNotes: ResellerSalespartnerEarningsReport[];
  selected_entry_id: string;
  paging: PagingInformation;

  total_amount_in_cents: number;
  current_year_amount_in_cents: number;
  current_filter_amount_in_cents: number;

  startDate: Date;
  endDate: Date;
}

export enum EarningsLayoutType {
  Single_Reseller,
  Single_Salespartner,
  List_Resellers,
  List_Salespartners,
  List_Credit_Notes,
  Single_Credit_Notes,
}
