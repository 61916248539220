export interface Media {
  media_id: string;
  public_id: string;
  full_image_url: string;
  large_preview_url: string;
  thumbnail_url: string;
  mini_thumbnail_url: string;
  credits: string;
  original_width: number;
  original_height: number;
  uploaded_in_event_creation: boolean;
  type: string;
  is_external: boolean;
}

export class Photo {
  src: string;

  srcSet: any;

  sizes: any;

  width: number;

  height: number;

  credits: string;

  media: Media;

  constructor(media: Media) {
    this.src = media.mini_thumbnail_url;
    this.srcSet = [
      `${media.mini_thumbnail_url} 500w`,
      `${media.thumbnail_url} 800w`,
      `${media.large_preview_url} 1024w`,
    ];
    this.sizes = ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"];
    this.width = media.original_width || 480;
    this.height = media.original_height || 480;
    this.credits = media.credits;

    this.media = media;
  }
}

export class UploadInfo {
  uploadSignature: null;

  cloudName: null;

  apiKey: null;

  uploadSignatureTimestamp: null;

  folder: null;

  uploadPreset: null;
}
