import { combineReducers } from "redux";
import { ActionType } from "../model/actions";
import { AuthenticationState } from "../model/authenticationState";

import * as authReducer from "./auth";
import * as companyReducer from "./company";
import * as paymentPlanReducer from "./paymentPlan";
import * as paymentPlanFormReducer from "./PaymentPlanForm";
import * as forgotPasswordReducer from "./forgotPassword";
import * as resetPasswordReducer from "./resetPassword";
import * as companyFormReducer from "./companyForm";
import * as adminUsersReducer from "./adminUsers";
import * as companyUsersReducer from "./companyUsers";
import * as userFormReducer from "./userForm";
import * as eventsReducer from "./events";
import * as inboxReducer from "./inbox";
import * as eventFormReducer from "./eventForm";
import * as mediaReducer from "./media";
import * as pageStateReducer from "./pageState";
import * as searchReducer from "./search";
import * as paymentReducer from "./payment";
import * as seoPagesReducer from "./seoPage";
import * as seoPageFormReducer from "./seoPageForm";
import * as errorDialogReducer from "./errorDialog";
import * as resellerProfileFormReducer from "./ResellerProfileForm";
import * as resellerProfileReducer from "./resellerProfile";
import * as resellerSalespartnerEarningsReducer from "./resellerSalespartnerEarnings";
import * as heroSlotFormReducer from "./event.hero.slot.form";
import * as companySharedLocationsReducer from "./company.shared.locations.reducer";
import * as activityLogsReducer from "./activity.logs.reducer";
import * as pluginApiKeyReducer from "./plugin.api.key.reducer";
import * as companyReportingResultsReducer from "./company.reporting.results.reducer";
import * as globalInvoicesReducer from "./global.invoices.reducer";
import { CompanyState } from "../model/companyState";
import { PaymentPlanState } from "../model/payment/payment.plan.state";
import { ForgotPasswordState, ResetPasswordState } from "../model/user";
import { CompanyFormState } from "../model/companyFormState";
import { AdminUsersState } from "../model/adminUsersState";
import { CompanyUsersState } from "../model/companyUsersState";
import { UserFormState } from "../model/userFormState";
import { EventState } from "../model/eventState";
import { EventFormState } from "../model/eventFormState";
import { MediaState } from "../model/mediaState";
import { ErrorDialogState } from "../model/errorDialogState";
import { PageState } from "../model/pageState";
import { SearchState } from "../model/searchState";
import { PaymentState } from "../model/paymentState";
import { PaymentPlanFormState } from "../model/paymentPlanFormState";
import { SeoPageFormState } from "../model/seoPageFormState";
import { SeoPageState } from "../model/seoPageState";
import { ResellerProfileFormState } from "../model/resellerProfileFormState";
import { ResellerProfileState } from "../model/resellerProfileState";
import { ResellerSalespartnerEarningsState } from "../model/resellerSalespartnerEarningsState";
import { EventHeroSlotState } from "../model/event.hero.slot.state";
import { CompanySharedLocationsState } from "../model/company.shared.locations.state";
import { ActivityLogsState } from "../model/activity.logs.state";
import { PluginApiKeyState } from "../model/api_keys/plugin.api.key.state";
import { CompanyReportingState } from "../model/company.reporting.state";
import { GlobalInvoicesState } from "../model/global_invoices/global.invoices.state";

export interface RootState {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  paymentPlanState: PaymentPlanState;
  resellerProfileState: ResellerProfileState;
  resellerSalespartnerEarningsState: ResellerSalespartnerEarningsState;
  adminUsersState: AdminUsersState;
  companyUsersState: CompanyUsersState;
  forgotPasswordState: ForgotPasswordState;
  resetPasswordState: ResetPasswordState;
  companyFormState: CompanyFormState;
  userFormState: UserFormState;
  eventState: EventState;
  eventFormState: EventFormState;
  mediaState: MediaState;
  errorDialogState: ErrorDialogState;
  pageState: PageState;
  searchState: SearchState;
  inboxState: EventState;
  paymentState: PaymentState;
  paymentPlanFormState: PaymentPlanFormState;
  resellerProfileFormState: ResellerProfileFormState;
  seoPageState: SeoPageState;
  seoPageFormState: SeoPageFormState;
  heroSlotState: EventHeroSlotState;
  companySharedLocationsState: CompanySharedLocationsState;
  activityLogsState: ActivityLogsState;
  pluginApiKeyState: PluginApiKeyState;
  companyReportingState: CompanyReportingState;
  globalInvoicesState: GlobalInvoicesState;
}

const appReducer = combineReducers<RootState>({
  ...authReducer,
  ...companyReducer,
  ...paymentPlanReducer,
  ...resellerProfileReducer,
  ...resellerSalespartnerEarningsReducer,
  ...adminUsersReducer,
  ...companyUsersReducer,
  ...forgotPasswordReducer,
  ...resetPasswordReducer,
  ...companyFormReducer,
  ...userFormReducer,
  ...eventsReducer,
  ...eventFormReducer,
  ...mediaReducer,
  ...errorDialogReducer,
  ...pageStateReducer,
  ...searchReducer,
  ...inboxReducer,
  ...paymentReducer,
  ...paymentPlanFormReducer,
  ...resellerProfileFormReducer,
  ...seoPagesReducer,
  ...seoPageFormReducer,
  ...heroSlotFormReducer,
  ...companySharedLocationsReducer,
  ...activityLogsReducer,
  ...pluginApiKeyReducer,
  ...companyReportingResultsReducer,
  ...globalInvoicesReducer,
});

export default (state: any, action: any) => {
  if (action.type === ActionType.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
