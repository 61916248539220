import BookingDrawerInfoSection from "../components/BookingDrawerInfoSection";
import NewsletterIcon from "../../../assets/icons/Newsletter";
import intl from "react-intl-universal";

export default function NewsletterInfoSection() {
  return (
    <BookingDrawerInfoSection
      icon={<NewsletterIcon sx={{ width: "33px", height: "27px" }} />}
      title={intl.get("booking_drawer_info.newsletter.title")}
      text1={intl.get("booking_drawer_info.newsletter.text1")}
      features={[intl.getHTML("booking_drawer_info.newsletter.feature1")]}
    />
  );
}
