import { Drawer, DrawerProps } from "@mui/material";
import { DialogHandler } from "../../dialog/useDialogHandler";
import { DialogContext } from "../../dialog/DialogContext";

interface Props extends Omit<DrawerProps, "open" | "onClose"> {
  handler: DialogHandler<any>;
}

export default function BCSideDrawer({ handler, ...props }: Props) {
  return (
    <DialogContext handler={handler}>
      <Drawer
        open={handler.isOpen}
        anchor={"right"}
        onClose={handler.close}
        PaperProps={{ sx: { width: "766px", maxWidth: "100vw" } }}
        {...props}
      />
    </DialogContext>
  );
}
