import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";

export const fetchSingleResellerEarnings =
  (user_id: string, page: number, size: number, startDate?: Date, endDate?: Date) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res =
        startDate && endDate
          ? await axios.get(
              `/v2/api/b2b/private/resellers/${user_id}/earnings?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}`,
            )
          : await axios.get(`/v2/api/b2b/private/resellers/${user_id}/earnings?page=${page}&size=${size}`);

      dispatch({
        type: ActionType.FETCH_EARNINGS,
        payload: {
          data: true,
          accounting_entries: res.data.accounting_entries,
          unpaid_invoices: res.data.unpaid_invoices,
          paging: res.data.paging,
          calculated_earnings: res.data.calculated_earnings,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };

export const fetchGlobalResellerEarnings =
  (page: number, size: number, startDate?: Date, endDate?: Date) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res =
        startDate && endDate
          ? await axios.get(
              `/v2/api/b2b/private/resellers/earnings?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}`,
            )
          : await axios.get(`/v2/api/b2b/private/resellers/earnings?page=${page}&size=${size}`);

      dispatch({
        type: ActionType.FETCH_EARNINGS,
        payload: {
          data: true,
          reseller_earnings: res.data.reseller_earnings,
          unpaid_invoices: res.data.unpaid_invoices,
          paging: res.data.paging,
          calculated_earnings: res.data.calculated_earnings,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };

export const fetchGlobalCreditNotes =
  (page: number, size: number, quarter: number, year: number) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res = await axios.get(`/v2/api/b2b/private/resellers/quarter-reports/${year}/${quarter}`);

      dispatch({
        type: ActionType.FETCH_EARNINGS,
        payload: {
          data: true,
          credit_notes: res.data.credit_notes,
          paging: res.data.paging,
          calculated_earnings: res.data.calculated_earnings,
          unpaid_invoices: res.data.unpaid_invoices,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };

export const fetchSingleCreditNotes =
  (reseller_id: string, page: number, size: number, startDate?: Date, endDate?: Date) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res =
        startDate && endDate
          ? await axios.get(
              `/v2/api/b2b/private/resellers/${reseller_id}/credit-notes?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}`,
            )
          : await axios.get(`/v2/api/b2b/private/resellers/${reseller_id}/credit-notes?page=${page}&size=${size}`);

      dispatch({
        type: ActionType.FETCH_EARNINGS,
        payload: {
          data: true,
          single_credit_notes: res.data.credit_notes,
          paging: res.data.paging,
          calculated_earnings: res.data.calculated_earnings,
          unpaid_invoices: res.data.unpaid_invoices,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };

export const fetchSingleSalespartnerEarnings =
  (salespartner_company_id: string, page: number, size: number, startDate?: Date, endDate?: Date) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res =
        startDate && endDate
          ? await axios.get(
              `/v2/api/b2b/private/companies/${salespartner_company_id}/salespartner-earnings?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}`,
            )
          : await axios.get(
              `/v2/api/b2b/private/companies/${salespartner_company_id}/salespartner-earnings?page=${page}&size=${size}`,
            );

      dispatch({
        type: ActionType.FETCH_EARNINGS,
        payload: {
          data: true,
          accounting_entries: res.data.accounting_entries,
          unpaid_invoices: res.data.unpaid_invoices,
          paging: res.data.paging,
          calculated_earnings: res.data.calculated_earnings,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };

export const fetchGlobalSalespartnerEarnings =
  (page: number, size: number, startDate?: Date, endDate?: Date) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res =
        startDate && endDate
          ? await axios.get(
              `/v2/api/b2b/private/salespartner-earnings?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}`,
            )
          : await axios.get(`/v2/api/b2b/private/salespartner-earnings?page=${page}&size=${size}`);

      dispatch({
        type: ActionType.FETCH_EARNINGS,
        payload: {
          data: true,
          salespartner_earnings: res.data.accounting_entries,
          paging: res.data.paging,
          calculated_earnings: res.data.summary,
          unpaid_invoices: res.data.unpaid_invoices,
        },
      });
      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };

export const closeResellerEarnings = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CLOSE_EARNINGS });
};
