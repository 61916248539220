import { PageState, PageStateType } from "../model/pageState";
import { Action, ActionType } from "../model/actions";
import createReducer from "./createReducer";

export const pageState = createReducer(new PageState(), {
  [ActionType.PAGE_LOADING]() {
    return { state: PageStateType.Loading };
  },
  [ActionType.PAGE_LOADED]() {
    return { state: PageStateType.Loaded };
  },
  [ActionType.PAGE_LOADING_ERROR](state: PageState, action: Action<any>) {
    return { state: PageStateType.FailedLoading, error: action.payload.error };
  },
  [ActionType.PAGE_SAVING_ERROR](state: PageState, action: Action<any>) {
    return { state: PageStateType.FailedSaving, error: action.payload.error };
  },
  [ActionType.PAGE_SAVING]() {
    return { state: PageStateType.Saving };
  },
  [ActionType.PAGE_SAVED]() {
    return { state: PageStateType.Saved };
  },
  [ActionType.RESET_PAGE_STATE]() {
    return { state: PageStateType.Unknown, error: null };
  },
});
