import { useCallback, useEffect, useState } from "react";
import { addDays, addMonths, compareAsc, compareDesc, endOfWeek, isSameWeek, startOfWeek } from "date-fns";
import { CalendarBookingSlot } from "../drawer/hero/HeroSlotsDrawerBackendResponse";
import CalendarSlotBooking from "./CalendarSlotBooking";

export const isInSameWeek = (dayA: Date, dayB: Date | null | undefined) => {
  if (dayB == null) {
    return false;
  }
  return isSameWeek(dayA, dayB, { weekStartsOn: 1 });
};

export interface CalendarWeekSelectionHandler {
  selectWeek: (week: Date) => void;
  deselectWeek: (week: Date) => void;
  clear: () => void;
  toggleWeek: (week: Date) => void;
  getBookingForWeekIfExists: (week: Date) => CalendarSlotBooking | undefined;
  bookings: CalendarSlotBooking[];
  newBookings: CalendarSlotBooking[];
  isWeekSelectable: (week: Date) => any;
  minDate: Date;
  maxDate: Date;
  nextBookingIsFree: boolean;
}

const defaultMinDate = addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 7);
const defaultMaxDate = addMonths(startOfWeek(new Date(), { weekStartsOn: 1 }), 2);

export function useCalendarWeekSelection(
  selectableWeeks: CalendarBookingSlot[],
  defaultSelection: CalendarBookingSlot[],
  availableFreeSlots = 0,
): CalendarWeekSelectionHandler {
  const [bookings, setBookings] = useState<CalendarSlotBooking[]>(
    defaultSelection.map((slot) => new CalendarSlotBooking(slot, true)),
  );

  const newBookings = bookings.filter((booking) => !booking.previouslyBooked);

  const minDate = [defaultMinDate, ...selectableWeeks.map((slot) => slot.slot.parsedSlotStartDate)].sort(compareAsc)[0];
  const maxDate = endOfWeek(
    [defaultMaxDate, ...selectableWeeks.map((slot) => slot.slot.parsedSlotStartDate)].sort(compareDesc)[0],
  );

  const reset = () => {
    setBookings(defaultSelection.map((slot) => new CalendarSlotBooking(slot, true)));
  };

  const defaultSelectionIds = defaultSelection.map((slot) => slot.slot.slot_id).join(",");

  useEffect(() => {
    setBookings(bookings.map((booking, index) => ({ ...booking, free: index <= availableFreeSlots })));
  }, [availableFreeSlots]);

  // make sure to place this hook AFTER the availableFreeSlots one
  // as both call setBookings (and only the last one called will get through)
  useEffect(() => {
    reset();
  }, [defaultSelectionIds]);

  const getBookingForWeek = useCallback(
    (week: Date) => {
      let booking = undefined;
      bookings.forEach((item) => {
        if (isInSameWeek(week, item.startDate)) {
          booking = item;
        }
      });

      return booking;
    },
    [bookings],
  );

  const nextBookingIsFree = newBookings.length < availableFreeSlots;

  const selectWeek = (week: Date) => {
    const slot = selectableWeeks.find((item) => isInSameWeek(item.slot.parsedSlotStartDate, week));
    setBookings([...bookings, new CalendarSlotBooking(slot, nextBookingIsFree)]);
  };

  const deselectWeek = (week: Date) => {
    const filtered = bookings.filter((item) => !isInSameWeek(item.startDate, week) || item.previouslyBooked);
    setBookings(
      filtered.map((booking, index) => ({
        ...booking,
        free: booking.previouslyBooked ? booking.free : index < availableFreeSlots,
      })),
    );
  };

  const toggleWeek = (week: Date) => {
    if (getBookingForWeek(week)) {
      deselectWeek(week);
    } else {
      selectWeek(week);
    }
  };

  const isWeekSelectable = useCallback(
    (date: Date) => selectableWeeks.find((x) => isInSameWeek(x.slot.parsedSlotStartDate, date)),
    [selectableWeeks],
  );

  return {
    selectWeek,
    deselectWeek,
    clear: reset,
    toggleWeek,
    getBookingForWeekIfExists: getBookingForWeek,
    bookings,
    newBookings,
    isWeekSelectable,
    minDate,
    maxDate,
    nextBookingIsFree,
  };
}
