import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { DialogHandler } from "../dialog/useDialogHandler";
import { BCEvent } from "../../model/event";
import HandlerDialog from "../dialog/handlerDialog";

export default function DialogDeleteCompanyEventConfirmation({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<BCEvent>;
  onConfirm: (event: BCEvent) => void;
}) {
  return (
    <HandlerDialog handler={handler}>
      <Content
        onConfirm={() => onConfirm(handler.payload)}
        onCancel={handler.close}
        eventTitle={handler.payload?.title}
      />
    </HandlerDialog>
  );
}

export function DialogDeleteCompanyEventConfirmationLegacy({
  open,
  onConfirm,
  onCancel,
  eventTitle,
}: {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  eventTitle: string;
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Content onConfirm={onConfirm} onCancel={onCancel} eventTitle={eventTitle} />
    </Dialog>
  );
}

function Content({
  onConfirm,
  onCancel,
  eventTitle,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  eventTitle: string;
}) {
  return (
    <>
      <DialogTitle id="alert-dialog-title">{intl.get("company_events.delete_confirmation.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.get("company_events.delete_confirmation.message", {
            event_title: eventTitle,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {intl.get("company_events.delete_confirmation.button_cancel")}
        </Button>
        <Button onClick={onConfirm} color="error" autoFocus>
          {intl.get("company_events.delete_confirmation.button_confirm")}
        </Button>
      </DialogActions>
    </>
  );
}
