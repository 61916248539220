import { connect } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { RootState } from "../reducers";
import { CompanyState } from "../model/companyState";
import { AuthenticationState } from "../model/authenticationState";
import WithCompanyPermission from "./WithCompanyPermission";
import * as CompanyActions from "../actions/company";
import { useLocation, useParams } from "react-router";

export interface Props {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  hasAnyPermission: string[];
  children: any;
  companyActions: typeof CompanyActions;
}

function CheckOrLoadCompanyPermission(props: Props) {
  const companyId = useParams().company_id;
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    // this flag indicates if the menu should change - if the company-specific menu should be shown. Most of the time that should happen but in the e.g. company list (of administrators) there is a edit button and when editing a company this way the menu should not change
    const showCompanyDetailMenu = urlParams.get("showCompanyDetailMenu");
    if (props.companyState.selected_company_id !== companyId) {
      props.companyActions.selectCompany(companyId, showCompanyDetailMenu == null || showCompanyDetailMenu === "true");
      props.companyActions.fetchCompanyInformation(companyId);
    }
  }, [companyId, location.search]);

  if (props.companyState.selected_company_id && props.companyState.company) {
    return (
      <WithCompanyPermission
        ignoreShowCompanyDetailMenuFlag
        hasAnyPermission={props.hasAnyPermission}
        renderExit={true}
      >
        {props.children}
      </WithCompanyPermission>
    );
  }

  return null;
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyState: state.companyState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOrLoadCompanyPermission);
