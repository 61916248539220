import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { CompanyState } from "../../../../../model/companyState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { AuthenticationState } from "../../../../../model/authenticationState";
import * as React from "react";
import { FormGridItem } from "../FormGridItem";
import { EventEditCheckboxField } from "../fields/EventEditCheckboxField";
import { EventEditNumberInputField } from "../fields/EventEditNumberInputField";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventGeneralInfoInfrastructureEdit extends React.Component<Props> {
  renderRestaurant() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_restaurant_available"}
      />
    );
  }

  renderSnackbar() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_snackbar_available"}
      />
    );
  }

  renderDistanceSupply() {
    return (
      <EventEditNumberInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"distance_supply"}
      />
    );
  }

  renderToilet() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_toilet_available"}
      />
    );
  }

  renderDiaperChanging() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_diaper_changing_available"}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderRestaurant()}
          </FormGridItem>
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderSnackbar()}
          </FormGridItem>
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderToilet()}
          </FormGridItem>
          <FormGridItem md={3} noTopPadding={true}>
            {this.renderDiaperChanging()}
          </FormGridItem>
          <FormGridItem md={6}>{this.renderDistanceSupply()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventGeneralInfoInfrastructureEdit;
