export interface CompanySharedLocation {
  location_id?: string;
  internal_name?: string;
  name?: string;
  directions?: string;
  description?: string;
  address?: string;
  postal_code?: string;
  city?: string;
  state?: string;
  region?: string;
  country?: string;
  country_code?: string;
  additional_info?: string;
  district?: string;
  longitude?: number;
  latitude?: number;
}

export function fullEditingAddressStringFrom(location: CompanySharedLocation): string {
  const values: string[] = [];
  values.push(`${location.address}, ${location.postal_code} ${location.city}`);
  if (location.district && !location.district.isEmpty()) {
    values.push(location.district);
  }
  values.push(location.country);
  if (location.additional_info && !location.additional_info.isEmpty()) {
    values.push(`(${location.additional_info})`);
  }
  return values.join(", ");
}

export function fullAddressStringFrom(location: CompanySharedLocation): string {
  if (location == null || Object.keys(location).length === 0) {
    return "";
  }
  return fullAddressString(
    location.address,
    location.postal_code,
    location.city,
    location.country,
    location.region,
    location.state,
    location.district,
  );
}

export function fullAddressString(
  address: string,
  postal_code: string,
  city: string,
  country: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  region: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  district: string,
): string {
  let string = "";
  if (address != null && !address.isEmpty()) {
    string = string.concat(address);
    string = string.concat(", ");
  }
  if (postal_code != null && !postal_code.isEmpty()) {
    string = string.concat(postal_code);
    string = string.concat(" ");
  }
  if (city != null && !city.isEmpty()) {
    string = string.concat(city);
    string = string.concat(", ");
  }
  if (country != null && !country.isEmpty()) {
    string = string.concat(country);
    string = string.concat(" ");
  }
  return string.trim();
}
