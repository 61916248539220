import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { CompanyUsersState } from "../model/companyUsersState";

export const companyUsersState = createReducer(
  {
    users: null,
    loading: false,
    error: null,
    failed: false,
    selectedCompanyId: null,
    closePage: false,
  },
  {
    [ActionType.FETCH_COMPANY_USERS](state: CompanyUsersState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          selectedCompanyId: action.payload.selectedCompanyId,
          users: action.payload.users,
        };
      }
      return { ...state };
    },
    [ActionType.REMOVE_USER_FROM_COMPANY](state: CompanyUsersState, action: Action<any>) {
      if (action.payload.data === true) {
        const newState = { ...state };
        const eventIndex = newState.users.findIndex((value) => value.user_id === action.payload.user_id);
        newState.users.splice(eventIndex, 1);
        return newState;
      }
      return state;
    },
    [ActionType.CHANGE_COMPANY_USER_ROLE](state: CompanyUsersState, action: Action<any>) {
      if (action.payload.data === true && action.payload.user != null) {
        const newState = { ...state, closePage: true };
        const index = newState.users.findIndex((value) => value.user_id === action.payload.user.user_id);
        newState.users[index] = action.payload.user;
        return newState;
      }
      return { ...state, closePage: true };
    },
  },
);
