export class SelectionOptions {
  static federalStatesByCountryOptions() {
    return {
      DE: [
        "Bayern",
        "Baden-Württemberg",
        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Thüringen",
        "Hessen",
        "Niedersachsen",
        "Mecklenburg-Vorpommern",
        "Sachsen",
        "Schleswig-Holstein",
        "Berlin",
        "Brandenburg",
        "Saarland",
        "Sachsen-Anhalt",
        "Hamburg",
        "Bremen",
      ],
      AT: [
        "Oberösterreich",
        "Niederösterreich",
        "Salzburg",
        "Steiermark",
        "Tirol",
        "Wien",
        "Kärnten",
        "Burgenland",
        "Vorarlberg",
      ],
      IT: [
        "Trentino-Südtirol",
        "Venetien",
        "Lombardei",
        "Emilia Romagna",
        "Friaul-Julisch Venetien",
        "Marken",
        "Toskana",
        "Kampanien",
      ],
      CH: [
        "Graubünden",
        "St. Gallen",
        "Bern",
        "Zürich",
        "Freiburg",
        "Aargau",
        "Tessin",
        "Wallis",
        "Thurgau",
        "Basel-Stadt",
        "Waadt",
        "Appenzell Ausserrhoden",
        "Zug",
        "Jura",
        "Luzern",
        "Solothurn",
        "Schwyz",
        "Schaffhausen",
        "Genf",
        "Uri",
        "Basel-Landschaft",
        "Glarus",
        "Obwalden",
        "Nidwalden",
        "Appenzell Innerrhoden",
        "Neuenburg",
      ],
      HR: ["Istrien", "Kvarner"],
      NL: ["Nordholland"],
      HU: ["Westtransdanubien"],
    };
  }

  static regionsByFederalStateOptions() {
    return {
      Bayern: [
        "Franken",
        "Oberbayern",
        "Ostbayern",
        "Münchner Umland",
        "Oberpfalz",
        "Bayerisch-Schwaben",
        "Bayerischer Wald",
        "Allgäu",
        "Region Chiemsee",
        "Rhön",
        "Region Spessart",
        "Region Augsburg",
        "Chiemgau",
        "Berchtesgadener Land",
        "Zugspitz Region",
        "Ammersee",
        "Bäderdreieck",
        "Region Tegernsee",
        "Starnberger See",
        "Königssee",
      ],
      "Trentino-Südtirol": [
        "Südtirol",
        "Dolomiten",
        "Pustertal",
        "Meran und Umgebung",
        "Bozen und Umgebung",
        "Eisacktal",
        "Vinschgau",
        "Kronplatz",
        "Seiser Alm",
        "Trentino",
        "Passeiertal",
      ],
      "Baden-Württemberg": [
        "Schwarzwald",
        "Schwäbische Alb",
        "Region Bodensee",
        "Region Stuttgart",
        "Region Schwaben",
        "Kurpfalz",
        "Kraichgau-Stromberg",
        "Odenwald",
        "Heilbronner Land",
        "Taubertal",
        "Hohenlohe",
      ],
      "St. Gallen": ["Ostschweiz"],
      Niederösterreich: [
        "Donauraum",
        "Waldviertel",
        "Mostviertel",
        "Weinviertel",
        "Wienerwald",
        "Wiener Alpen",
        "Region Wachau",
      ],
      Oberösterreich: [
        "Mühlviertel",
        "Salzkammergut",
        "Donau Oberösterreich",
        "Innviertel",
        "Region Hausruck",
        "Attersee-Attergau",
        "Steyr und Nationalpark Region",
        "Dachstein Salzkammergut",
        "Böhmerwald",
        "Region Mondsee",
        "Pyhrn-Priel",
        "Traunsee",
        "Hallstätter See",
        "Irrsee",
        "Vitalwelt Bad Schallerbach",
        "Almtal",
      ],
      Salzburg: [
        "Pinzgau",
        "Flachgau",
        "Salzburg und Umgebung",
        "Pongau",
        "Salzburger Seenland",
        "Tennengau",
        "Wallersee",
        "Lungau",
        "Wolfgangsee",
        "Salzburger Sportwelt",
        "Großarltal",
        "Raurisertal",
        "Zell am See-Kaprun",
        "Gasteinertal",
        "Wildkogel-Arena",
        "Region Obertauern",
        "Fuschlsee",
        "Hochkönig",
        "Saalachtal",
        "Großglockner",
      ],
      Tirol: [
        "Tiroler Unterland",
        "Tiroler Oberland",
        "Serfaus-Fiss-Ladis",
        "Zillertal",
        "Stubaital",
        "Alpbachtal",
        "Region Innsbruck",
        "Achensee",
        "Kitzbüheler Alpen",
        "Lechtal",
        "Oberinntal",
        "Außerfern",
        "Osttirol",
        "Ötztal",
        "Zugspitze",
        "Wipptal",
        "Silberregion Karwendel",
        "Wilder Kaiser",
        "Kaiserwinkl",
        "Kufsteinerland",
        "Region Seefeld",
        "Arlberg",
        "Pitztal",
        "Region Kitzbühel",
        "Ferienregion Imst",
        "Paznaun - Ischgl",
        "Region Kaunertal",
        "Region Walchsee",
        "Tannheimertal",
      ],
      "Schleswig-Holstein": [
        "Ostsee",
        "Hamburg-Umland",
        "Nordsee",
        "Insel Fehmarn",
        "Holsteinische Schweiz",
        "Flusslandschaft Elbe",
        "Plöner See",
        "Binnenland",
        "Herzogtum Lauenburg",
      ],
      "Nordrhein-Westfalen": [
        "Rhein",
        "Sauerland",
        "Ruhrgebiet",
        "Region Köln-Bonn",
        "Teutoburger Wald",
        "Bergisches Land",
        "Münsterland",
        "Niederrhein",
        "Region Düsseldorf",
        "Die bergischen Drei",
        "Siegen-Wittgenstein",
      ],
      "Rheinland-Pfalz": ["Eifel", "Mosel", "Pfalz", "Hunsrück", "Rheinhessen", "Westerwald", "Naheland", "Ahrtal"],
      Hessen: [
        "Frankfurt Rhein-Main",
        "Bergstrasse-Odenwald",
        "Region Lahntal",
        "Nordhessen",
        "Taunus",
        "Region Vogelsberg",
        "Rheingau",
      ],
      Brandenburg: [
        "Berlin-Umland",
        "Brandenburg Nord",
        "Brandenburg Süd",
        "Fläming",
        "Barnimer Land",
        "Ruppiner Seenland",
        "Seenland Oder-Spree",
        "Spreewald",
        "Havelland",
        "Uckermark",
        "Lausitzer Seenland",
        "Dahme-Seenland",
        "Elbe-Elster-Land",
        "Prignitz",
      ],
      Thüringen: [
        "Thüringer Wald",
        "Ostthüringen",
        "Nordthüringen",
        "Wartburg Hainich",
        "Saaleland",
        "Eichsfeld",
        "Kyffhäuser",
      ],
      Lombardei: ["Sondrio", "Gardasee", "Mailand", "Lago di Como", "Iseosee"],
      Kärnten: [
        "Hohe Tauern",
        "Region Villach",
        "Südkärnten",
        "Nockberge",
        "Millstättersee",
        "Ossiachersee",
        "Klopeiner See",
        "Wörthersee",
        "Region Klagenfurt am Wörthersee",
        "Gailtal",
        "Nassfeld-Pressegger See",
        "Region Bad Kleinkirchheim",
        "Region Rosental",
        "Lieser-/Maltatal",
        "Mittelkärnten",
        "Lavanttal",
        "Katschberg-Rennweg",
        "Turnersee",
        "Weissensee",
        "Faakersee",
        "Lesachtal",
      ],
      Steiermark: [
        "Schladming-Dachstein",
        "Oststeiermark",
        "Hochsteiermark",
        "Graz und Umgebung",
        "Murtal",
        "Süd & West Steiermark",
        "Thermenland Steiermark",
        "Gesäuse",
        "Ausseerland - Salzkammergut",
      ],
      Vorarlberg: [
        "Bodensee-Vorarlberg",
        "Montafon",
        "Alpenregion Bludenz",
        "Bregenzerwald",
        "Brandnertal",
        "Kleinwalsertal",
        "Region Klostertal",
        "Großes Walsertal",
      ],
      Burgenland: ["Nordburgenland", "Südburgenland", "Neusiedler See", "Mittelburgenland", "Rosalia-Neufelder See"],
      "Mecklenburg-Vorpommern": [
        "Vorpommern",
        "Mecklenburgische Seenplatte",
        "Region Schwerin",
        "Mecklenburgische Ostseeküste",
        "Rügen",
        "Müritz",
        "Vorpommersche Boddenlandschaft",
        "Fischland-Darß-Zingst",
        "Insel Usedom",
        "Mecklenburgische Schweiz",
        "Plauer See",
        "Schweriner See",
        "Kummerower See",
      ],
      Sachsen: ["Vogtland", "Elbeland", "Region Leipzig", "Erzgebirge", "Oberlausitz", "Sächsische Schweiz"],
      Niedersachsen: [
        "Harz",
        "Bremen-Umland",
        "Lüneburger Heide",
        "Weserbergland",
        "Nordseeküste",
        "Region Nordsee-Elbe-Weser",
        "Braunschweiger Land",
        "Region Hannover",
        "Ostfriesland",
        "Emsland",
        "Mittelweser",
        "Oldenburger Münsterland",
        "Osnabrücker Land",
        "Grafschaft Bentheim",
        "Elbe und Wendland",
      ],
      Lothringen: ["Moselle"],
      "Sachsen-Anhalt": ["Halle-Saale", "Magdeburg-Elbe-Börde-Heide", "Anhalt-Dessau-Wittenberg", "Altmark"],
      "Rhône-Alpes": ["Haut-Savoie", "Ain"],
      Elsass: ["Haut Rhin", "Bas Rhin"],
      "Pomurje / Pohorjegebirge & Umgebung / Savinjska": ["Pohorje z okolico", "Savinjska", "Pomurje"],
      "Franche-Comté": ["Doubs", "Territoire de Belfort", "Region Jura"],
      Venetien: ["Venedig", "Cavallino", "Belluno"],
      Wallonien: ["Lüttich"],
      "Carniola / Julische Alpen / Laibach / Zasavje": ["Krain", "Julische Alpen"],
      Piemont: ["Lago Maggiore"],
      "Friaul-Julisch Venetien": ["Udine", "Lignano"],
      Westtransdanubien: ["Vas", "Győr-Moson-Sopron"],
      "Emilia Romagna": ["Forli-Cesena", "Rimini", "Ravenna", "Emilia", "Modena"],
      Marken: ["Pesaro Urbino"],
      Istrien: ["Poreč", "Pula", "Rovinj", "Novigrad", "Rabac"],
      Gelderland: ["Achterhoek"],
      Toskana: ["Lucca - Pisa", "Livorno"],
      "Mosel / Müllerthal / Grevenmacher": ["Mullerthal", "La Moselle"],
      Kampanien: ["Caserta"],
      Kvarner: ["Krk"],
      Dalmatien: ["Zadar - Šibenik"],
      "Slowakei West": ["Pressburg"],
    };
  }

  static citiesByFederalStateOptions() {
    return {
      Wien: ["Wien-Stadt"],
      Bayern: [
        "München",
        "Nürnberg",
        "Augsburg",
        "Regensburg",
        "Bayreuth",
        "Dachau",
        "Würzburg",
        "Bamberg (Bamberg)",
        "Aschaffenburg",
        "Coburg",
        "Furth im Wald",
        "Ingolstadt",
        "Oberschleißheim",
        "Erlangen",
        "Landshut (Kreisfreie Stadt Landshut)",
        "Kulmbach",
        "Eichstätt",
        "Ansbach",
        "Fürth (Fürth)",
        "Kaufbeuren",
        "Marktoberdorf",
        "Memmingen",
        "Mindelheim",
        "Ochsenfurt",
        "Passau (Passau)",
        "Rosenheim (Rosenheim)",
        "Waldkraiburg",
        "Altötting",
        "Burghausen (Landkreis Altötting)",
        "Cham (Cham)",
        "Donauwörth",
        "Forchheim (Landkreis Forchheim)",
        "Garmisch-Partenkirchen",
        "Kelheim",
        "Kempten",
        "Königsbrunn",
        "Sulzbach-Rosenberg",
        "Aichach (Landkreis Aichach-Friedberg)",
        "Altomünster",
        "Bad Kissingen",
        "Bad Reichenhall",
        "Berchtesgaden",
        "Dinkelsbühl",
        "Grafenau (Freyung-Grafenau)",
        "Illertissen",
        "Nabburg",
        "Neu-Ulm",
        "Pottenstein (Landkreis Bayreuth)",
        "Riedenburg",
        "Ruhpolding",
        "Schweinfurt",
        "Weiden (Weiden i.d.OPf.)",
        "Wertingen",
        "Zirndorf",
        "Amerang",
        "Bad Bocklet",
        "Bad Staffelstein",
        "Bad Windsheim",
        "Bayerisch Eisenstein",
        "Deggendorf",
        "Ebersberg (Landkreis Ebersberg)",
        "Erding",
        "Feucht",
        "Feuchtwangen",
        "Fladungen",
        "Freising",
        "Freyung",
        "Gerolzhofen",
        "Gessertshausen",
        "Günzburg",
        "Gunzenhausen",
        "Ichenhausen",
        "Kiefersfelden",
        "Kitzingen",
        "Lichtenfels (Landkreis Lichtenfels)",
        "Massing (Landkreis Rottal-Inn)",
        "Nesselwang",
        "Neureichenau",
        "Nördlingen",
        "Ohlstadt",
        "Ottobeuren",
        "Polling (Landkreis Weilheim-Schongau)",
        "Raisting",
        "Rattenberg (Landkreis Straubing-Bogen)",
        "Schauenstein",
        "Schillingsfürst",
        "Schwabach",
        "Tiefenbach (Cham)",
        "Traunstein (Landkreis Traunstein)",
        "Treuchtlingen",
        "Unterreit",
        "Warmensteinach",
        "Weidenberg",
        "Wolfratshausen",
        "Abenberg",
        "Altmannstein",
        "Amberg (Amberg)",
        "Arrach",
        "Arzberg (Wunsiedel i.Fichtelgebirge)",
        "Bad Brückenau",
        "Bad Füssing",
        "Bad Tölz",
        "Bärnau",
        "Bechhofen (Landkreis Ansbach)",
        "Bernau am Chiemsee",
        "Bernried (Landkreis Weilheim-Schongau)",
        "Bodenmais",
        "Breitenberg (Landkreis Passau)",
        "Bruckmühl (Landkreis Rosenheim)",
        "Burgthann",
        "Dettelbach",
        "Diedorf (Landkreis Augsburg)",
        "Dingolfing",
        "Eichendorf",
        "Ellingen",
        "Essing",
        "Falkenberg (Landkreis Tirschenreuth)",
        "Flossenbürg",
        "Freilassing (Berchtesgadener Land)",
        "Fürstenfeldbruck",
        "Geisenhausen",
        "Georgensgmünd",
        "Glonn",
        "Goldkronach",
        "Gößweinstein",
        "Grünwald (Landkreis München)",
        "Haar (Landkreis München)",
        "Hausham (Miesbach)",
        "Hauzenberg (Landkreis Passau)",
        "Heroldsbach",
        "Hersbruck",
        "Hirschaid",
        "Hof (Hof)",
        "Inzell (Landkreis Traunstein)",
        "Iphofen",
        "Irsee",
        "Ismaning",
        "Karlstadt",
        "Kronach",
        "Krumbach (Landkreis Günzburg)",
        "Leipheim",
        "Lenggries",
        "Leutershausen",
        "Ludwigsstadt",
        "Maihingen",
        "Markt Schwaben",
        "Mellrichstadt",
        "Mertingen",
        "Neuendettelsau",
        "Neukirchen (Landkreis Straubing-Bogen)",
        "Neuschönau",
        "Oerlenbach",
        "Ortenburg",
        "Pappenheim",
        "Peiting",
        "Pocking",
        "Pommelsbrunn",
        "Postbauer-Heng",
        "Rain (Landkreis Donau-Ries)",
        "Regen",
        "Rödental",
        "Roding (Cham)",
        "Rohrdorf (Landkreis Rosenheim)",
        "Roth (Landkreis Roth)",
        "Scheidegg",
        "Schliersee",
        "Schrobenhausen",
        "Schwandorf",
        "Starnberg (Starnberg)",
        "Steinwiesen",
        "Taufkirchen (Landkreis Mühldorf am Inn)",
        "Tegernsee",
        "Thalmässing",
        "Thierhaupten",
        "Thurnau",
        "Tittmoning",
        "Treffelstein",
        "Veitshöchheim",
        "Vohenstrauß",
        "Volkach",
        "Waldkirchen (Freyung-Grafenau)",
        "Waldmünchen",
        "Weiler-Simmerberg",
        "Weißenhorn",
        "Wemding",
        "Wolnzach",
        "Wonsees",
        "Ziemetshausen",
        "Abensberg",
        "Adelschlag",
        "Adelsdorf",
        "Ahorn (Landkreis Coburg)",
        "Ainring",
        "Aldersbach",
        "Allersberg",
        "Altdorf (Landshut)",
        "Altdorf (Nürnberger Land)",
        "Altenbuch",
        "Altenkunstadt",
        "Altenthann",
        "Amorbach",
        "Andechs",
        "Anger (Berchtesgadener Land)",
        "Apfeltrach",
        "Arnstorf",
        "Aschheim",
        "Aub",
        "Aufseß",
        "Aurach",
        "Aying",
        "Babenhausen (Landkreis Unterallgäu)",
        "Bad Abbach",
        "Bad Aibling",
        "Bad Bayersoien",
        "Bad Heilbrunn",
        "Bad Rodach",
        "Bad Steben",
        "Bad Wörishofen",
        "Baierbrunn",
        "Bastheim",
        "Baunach",
        "Bayerisch Gmain",
        "Beilngries",
        "Benediktbeuern",
        "Benningen",
        "Berching",
        "Bergen (Landkreis Traunstein)",
        "Bernbeuren",
        "Bernried (Landkreis Deggendorf)",
        "Biessenhofen (Landkreis Ostallgäu)",
        "Bischberg",
        "Blindheim",
        "Bogen",
        "Brand (Landkreis Tirschenreuth)",
        "Bubenreuth",
        "Buchbrunn (Landkreis Kitzingen)",
        "Buchenberg (Landkreis Oberallgäu)",
        "Buchloe",
        "Burgau (Landkreis Günzburg)",
        "Burgheim",
        "Burgkunstadt",
        "Burglengenfeld",
        "Bürgstadt",
        "Buttenheim",
        "Buxheim (Landkreis Unterallgäu)",
        "Cadolzburg",
        "Castell",
        "Creußen",
        "Dietenhofen",
        "Dinkelscherben",
        "Dirlewang",
        "Dittelbrunn",
        "Dollnstein",
        "Donaustauf",
        "Dorfprozelten",
        "Durach",
        "Ebelsbach",
        "Ebermannstadt",
        "Ebern",
        "Ebrach",
        "Eckersdorf (Landkreis Bayreuth)",
        "Egenhofen",
        "Ehingen (Landkreis Ansbach)",
        "Eichenau",
        "Elsenfeld",
        "Eltmann",
        "Emskirchen",
        "Erbendorf",
        "Erdweg",
        "Ergoldsbach",
        "Erkheim",
        "Eschlkam",
        "Essenbach",
        "Ettal",
        "Euerdorf",
        "Falkenstein (Cham)",
        "Fichtelberg",
        "Flachslanden",
        "Flintsbach",
        "Floß",
        "Forstern (Erding)",
        "Frammersbach",
        "Frasdorf",
        "Frauenau",
        "Frensdorf (Bamberg)",
        "Freudenberg (Amberg-Sulzbach)",
        "Friedberg (Landkreis Aichach-Friedberg)",
        "Füssen",
        "Gablingen",
        "Gaimersheim",
        "Gauting",
        "Geiersthal",
        "Geiselhöring",
        "Geiselwind",
        "Geisenfeld",
        "Geldersheim",
        "Geretsried",
        "Germering",
        "Gersthofen",
        "Gestratz",
        "Gilching",
        "Glattbach",
        "Gnotzheim",
        "Gochsheim",
        "Grabenstätt",
        "Grafenrheinfeld",
        "Grafenwiesen",
        "Grafenwöhr",
        "Grassau (Landkreis Traunstein)",
        "Greding",
        "Gröbenzell",
        "Großeibstadt",
        "Großostheim",
        "Großwallstadt",
        "Großweil",
        "Gundremmingen",
        "Guteneck",
        "Gutenstetten",
        "Habach (Landkreis Weilheim-Schongau)",
        "Haimhausen",
        "Halblech",
        "Hammelburg",
        "Harburg",
        "Hasloch",
        "Hausen (Landkreis Forchheim)",
        "Heideck",
        "Heilsbronn",
        "Helmbrechts",
        "Hendungen",
        "Hengersberg",
        "Hergensweiler",
        "Heroldsberg",
        "Herzogenaurach",
        "Hilpoltstein",
        "Himmelkron",
        "Hitzhofen",
        "Höchberg",
        "Hofstetten (Landkreis Landsberg am Lech)",
        "Hohenlinden",
        "Holzheim (Landkreis Donau-Ries)",
        "Hummeltal",
        "Hurlach",
        "Iffeldorf",
        "Illesheim",
        "Illschwang",
        "Immenstadt im Allgäu",
        "Inchenhofen",
        "Isen",
        "Jettingen-Scheppach",
        "Karlshuld",
        "Kastl (Amberg-Sulzbach)",
        "Kellmünz",
        "Kemnath",
        "Kienberg (Landkreis Traunstein)",
        "Kinding",
        "Kipfenberg",
        "Kirchberg (Erding)",
        "Kirchheim bei München",
        "Kleinheubach",
        "Klingenberg am Main",
        "Knetzgau",
        "Kochel am See",
        "Köditz",
        "Ködnitz (Landkreis Kulmbach)",
        "Kolbermoor",
        "Kolitzheim",
        "Königsdorf (Landkreis Bad Tölz-Wolfratshausen)",
        "Kösching",
        "Kößlarn",
        "Kronburg",
        "Kümmersbruck",
        "Künzing",
        "Kupferberg",
        "Laufen (Berchtesgadener Land)",
        "Lechbruck",
        "Legau",
        "Lindberg",
        "Lindenberg im Allgäu",
        "Mähring",
        "Mainburg (Landkreis Kelheim)",
        "Mallersdorf-Pfaffenberg",
        "Manching",
        "Markt Erlbach",
        "Markt Nordheim",
        "Marktbergel",
        "Marktbreit",
        "Marktl (Landkreis Altötting)",
        "Marktredwitz",
        "Marktrodach",
        "Marktschellenberg",
        "Marktsteft",
        "Maßbach",
        "Mauth",
        "Meeder",
        "Memmelsdorf",
        "Merkendorf (Landkreis Ansbach)",
        "Mespelbrunn",
        "Miesbach",
        "Miltach",
        "Miltenberg",
        "Missen-Wilhams",
        "Mittenwald",
        "Mitterfels",
        "Mitterteich",
        "Mitwitz",
        "Mömlingen",
        "Mönchberg",
        "Moosach",
        "Moosburg (Landkreis Freising)",
        "Motten (Landkreis Bad Kissingen)",
        "Mühlhausen (Landkreis Neumarkt in der Oberpfalz)",
        "Münnerstadt",
        "Murnau am Staffelsee",
        "Naila",
        "Nassenfels",
        "Nersingen",
        "Neualbenreuth",
        "Neubeuern",
        "Neuburg am Inn",
        "Neuenmarkt",
        "Neumarkt-Sankt Veit",
        "Neuötting",
        "Neutraubling",
        "Niederaichbach",
        "Nittenau",
        "Nordhalben",
        "Oberammergau",
        "Oberaudorf",
        "Oberbergkirchen",
        "Oberelsbach",
        "Obergünzburg",
        "Oberhaid (Bamberg)",
        "Obernzell",
        "Obernzenn",
        "Oberostendorf",
        "Oberschwarzach",
        "Oberstaufen",
        "Oberstdorf",
        "Oberviechtach",
        "Ottensoos",
        "Ottobrunn",
        "Pähl",
        "Parkstein",
        "Parsberg",
        "Partenstein (Main-Spessart)",
        "Peißenberg",
        "Penzberg",
        "Pfaffenhausen",
        "Pfarrkirchen",
        "Philippsreut",
        "Planegg",
        "Plattling",
        "Pleinfeld",
        "Pleystein",
        "Plößberg",
        "Pöcking",
        "Poing (Landkreis Ebersberg)",
        "Pommersfelden",
        "Prichsenstadt",
        "Prien am Chiemsee",
        "Prittriching",
        "Randersacker",
        "Raubling",
        "Reckendorf",
        "Rehau",
        "Reisbach",
        "Riedbach",
        "Rimbach (Landkreis Rottal-Inn)",
        "Rimpar",
        "Ringelai",
        "Roggenburg (Neu-Ulm)",
        "Roßhaupten",
        "Roßtal",
        "Rothenburg ob der Tauber",
        "Rott (Landkreis Landsberg am Lech)",
        "Rottach-Egern",
        "Rottendorf (Landkreis Würzburg)",
        "Röttingen",
        "Rötz (Cham)",
        "Runding",
        "Sankt Englmar",
        "Sauerlach",
        "Schernfeld",
        "Schirnding",
        "Schlüsselfeld",
        "Schnaittach",
        "Schneizlreuth",
        "Schongau (Landkreis Weilheim-Schongau)",
        "Schöngeising",
        "Schonungen",
        "Schwabmünchen",
        "Schwabsoien",
        "Schwanfeld",
        "Schwangau",
        "Schwarzenfeld",
        "Seeg",
        "Seehausen am Staffelsee",
        "Segnitz",
        "Selb",
        "Seßlach",
        "Siegsdorf",
        "Simbach (Dingolfing-Landau)",
        "Solnhofen",
        "Sonthofen",
        "Spalt",
        "Speinshart",
        "Stadtlauringen",
        "Stadtsteinach",
        "Steingaden",
        "Straßlach-Dingharting",
        "Straubing",
        "Sugenheim",
        "Sulzheim (Landkreis Schweinfurt)",
        "Sünching",
        "Teisendorf",
        "Thannhausen",
        "Thyrnau",
        "Tirschenreuth",
        "Todtenweis",
        "Töpen",
        "Traunreut",
        "Triefenstein",
        "Trostberg",
        "Türkheim",
        "Tüßling",
        "Tutzing",
        "Übersee",
        "Uffenheim",
        "Unterdießen",
        "Unterföhring",
        "Untergriesbach (Landkreis Passau)",
        "Unterhaching",
        "Untermerzbach",
        "Unterschleißheim",
        "Unterschwaningen",
        "Ursberg",
        "Urspringen",
        "Valley",
        "Viechtach",
        "Vilsbiburg",
        "Vilseck",
        "Vilshofen",
        "Wackersdorf",
        "Walderbach",
        "Waldsassen",
        "Waldthurn",
        "Wasserlosen",
        "Wassertrüdingen",
        "Weibersbrunn",
        "Weilbach (Landkreis Miltenberg)",
        "Weiltingen",
        "Weisendorf",
        "Weismain",
        "Weißenbrunn (Landkreis Kronach)",
        "Weißenburg in Bayern",
        "Weißensberg",
        "Weißenstadt",
        "Welden",
        "Wellheim",
        "Wertach",
        "Wiesenfelden",
        "Wiesent",
        "Wiesenttal",
        "Wiggensbach",
        "Wildpoldsried",
        "Wilhelmsdorf (Landkreis Neustadt an der Aisch-Bad Windsheim)",
        "Windischeschenbach",
        "Winzer",
        "Wipfeld",
        "Wittelshofen",
        "Wolframs-Eschenbach",
        "Wolfsegg (Landkreis Regensburg)",
        "Wörthsee",
        "Wunsiedel",
        "Zachenberg",
        "Zell (Landkreis Hof)",
        "Ziertheim",
        "Zusmarshausen",
        "Zwiesel",
      ],
      Salzburg: [
        "Salzburg-Stadt (Salzburg)",
        "Hinterglemm",
        "Mattsee",
        "Rauris",
        "Saalbach",
        "Großarl",
        "Seekirchen am Wallersee",
        "Neumarkt am Wallersee",
        "Obertrum am See",
        "Bad Hofgastein",
        "Seeham",
        "Abtenau",
        "Leogang",
        "Mittersill",
        "Strobl",
        "Zell am See",
        "Flachau",
        "Hallein",
        "Saalfelden am Steinernen Meer",
        "Werfen",
        "Bramberg am Wildkogel",
        "Golling an der Salzach",
        "Grödig",
        "Kaprun",
        "Lofer",
        "Mariapfarr",
        "Mauterndorf (Mauterndorf)",
        "Niedernsill",
        "Obertauern",
        "Uttendorf (Uttendorf)",
        "Wagrain",
        "Anif",
        "Bad Dürrnberg",
        "Bad Gastein",
        "Bergheim (Bergheim)",
        "Ebenau",
        "Großgmain",
        "Neukirchen am Großvenediger",
        "Nußdorf am Haunsberg",
        "Radstadt",
        "Schwarzach im Pongau",
        "Tamsweg",
        "Thalgau",
        "Untertauern (Untertauern)",
        "Adnet",
        "Anthering",
        "Berndorf bei Salzburg",
        "Dorfgastein",
        "Elixhausen",
        "Elsbethen",
        "Filzmoos (Filzmoos)",
        "Fuschl am See",
        "Henndorf am Wallersee",
        "Hintersee (Hintersee)",
        "Hüttschlag",
        "Lamprechtshausen",
        "Lessach (Lessach)",
        "Maishofen",
        "Mühlbach am Hochkönig",
        "Straßwalchen",
        "Bischofshofen",
        "Eben im Pongau",
        "Eugendorf",
        "Faistenau",
        "Hollersbach im Pinzgau",
        "Hüttau",
        "Köstendorf (Köstendorf)",
        "Krispl",
        "Kuchl",
        "Mitterberg (Ramingstein)",
        "Oberndorf bei Salzburg",
        "Piesendorf",
        "Sankt Johann im Pongau",
        "Sankt Martin bei Lofer",
        "Scheffau am Tennengebirge",
        "Schleedorf",
        "Seewalchen",
        "Sonnberg (Bramberg am Wildkogel)",
        "Stuhlfelden",
        "Taxenbach",
        "Thomatal",
        "Tiefbrunnau",
        "Unken",
        "Viehhofen",
        "Wals",
        "Walserberg",
        "Zederhaus",
      ],
      Berlin: ["Berlin-Stadt"],
      Oberösterreich: [
        "Linz (Linz)",
        "Braunau am Inn",
        "Gosau",
        "Wels (Wels)",
        "Mondsee",
        "Obertraun",
        "Bad Ischl",
        "Bad Leonfelden",
        "Feldkirchen an der Donau",
        "Oberneukirchen (Oberneukirchen)",
        "Ulrichsberg (Ulrichsberg)",
        "Asten (Asten)",
        "Bad Schallerbach",
        "Hallstatt",
        "Pasching (Pasching)",
        "Perg",
        "Steinbach am Attersee",
        "Weyregg am Attersee",
        "Grein",
        "Kremsmünster",
        "Unterweißenbach (Unterweißenbach)",
        "Bad Kreuzen",
        "Enns",
        "Gmunden",
        "Kirchschlag bei Linz",
        "Kronstorf",
        "Neustift im Mühlkreis",
        "Seewalchen am Attersee",
        "Spital am Pyhrn",
        "Wilhering",
        "Windischgarsten",
        "Engelhartszell",
        "Freistadt",
        "Geretsberg",
        "Haslach an der Mühl",
        "Hellmonsödt",
        "Klaffer am Hochficht",
        "Nußdorf am Attersee",
        "Sandl",
        "Sankt Georgen im Attergau",
        "Scharnstein",
        "Schlüßlberg",
        "Schwanenstadt",
        "Ternberg",
        "Tragwein",
        "Vöcklabruck",
        "Wallern an der Trattnach",
        "Windhaag bei Freistadt",
        "Aspach (Aspach)",
        "Bad Hall",
        "Bad Zell",
        "Eggelsberg",
        "Garsten",
        "Großraming",
        "Gutau",
        "Hinterstoder",
        "Krenglbach",
        "Lasberg",
        "Lengau (Lengau)",
        "Mauthausen",
        "Molln",
        "Neukirchen an der Vöckla",
        "Peilstein im Mühlviertel",
        "Pregarten",
        "Ried im Innkreis",
        "Roßleithen",
        "Sankt Agatha (Sankt Agatha)",
        "Sankt Georgen am Walde",
        "Sankt Pantaleon",
        "Schlierbach (Schlierbach)",
        "Schönau im Mühlkreis",
        "Steinerkirchen an der Traun",
        "Taufkirchen an der Pram",
        "Andorf",
        "Ebensee",
        "Frankenmarkt",
        "Franking",
        "Gaflenz",
        "Geinberg",
        "Haag am Hausruck",
        "Hartkirchen",
        "Hirschbach im Mühlkreis",
        "Kirchdorf am Inn (Kirchdorf am Inn)",
        "Kirchdorf an der Krems",
        "Königswiesen (Königswiesen)",
        "Laakirchen",
        "Lambach (Lambach)",
        "Laussa",
        "Leonding",
        "Losenstein",
        "Luftenberg",
        "Maria Neustift",
        "Mining",
        "Moosdorf (Moosdorf, Kirchberg bei Mattighofen)",
        "Munderfing",
        "Obernberg am Inn",
        "Oberndorf (Gurten)",
        "Pfarrkirchen im Mühlkreis",
        "Puchenau",
        "Rainbach im Mühlkreis",
        "Reichraming",
        "Sankt Florian (Sankt Florian)",
        "Sankt Johann am Wimberg",
        "Sankt Martin im Mühlkreis",
        "Schalchen (Schalchen)",
        "St. Wolfgang im Salzkammergut",
        "Steyr",
        "Vichtenstein (Vichtenstein, Sankt Roman)",
        "Vorderstoder",
        "Waldhausen im Strudengau",
        "Weißenkirchen im Attergau",
        "Weyer (Weyer)",
        "Windhaag bei Perg",
        "Alberndorf in der Riedmark",
        "Altheim (Altheim)",
        "Ansfelden",
        "Eberschwang",
        "Eberstalzell",
        "Eferding",
        "Gaspoltshofen",
        "Grünau im Almtal",
        "Gunskirchen",
        "Hagenberg im Mühlkreis",
        "Haibach ob der Donau",
        "Höhnhart",
        "Klaus (Edt bei Lambach)",
        "Lenzing (Lenzing)",
        "Leonstein",
        "Lichtenberg (Ulrichsberg)",
        "Liebenau (Liebenau)",
        "Maria Schmolln",
        "Mattighofen",
        "Mauerkirchen",
        "Natternbach",
        "Neukirchen (Altmünster)",
        "Niederneukirchen",
        "Oberhofen am Irrsee",
        "Ottensheim",
        "Pettenbach (Pettenbach)",
        "Pramet",
        "Rechberg (Sankt Thomas am Blasenstein, Rechberg)",
        "Reichenthal",
        "Rußbach",
        "Sankt Georgen an der Gusen",
        "Sankt Peter am Wimberg",
        "Sankt Stefan am Walde",
        "Sarleinsbach",
        "Schiedlberg",
        "Schwertberg",
        "Sierning (Sierning)",
        "Steyregg",
        "Tiefgraben",
        "Traun (Traun)",
        "Traunkirchen",
        "Vorchdorf",
        "Vorderweißenbach",
        "Waldburg (Waldburg)",
        "Walding (Walding)",
        "Waldneukirchen",
        "Wartberg an der Krems",
        "Waxenberg",
        "Zell an der Pram",
        "Adlwang",
        "Allerheiligen im Mühlkreis",
        "Altenberg bei Linz",
        "Altenfelden",
        "Altmünster",
        "Aschach an der Steyr",
        "Atzbach",
        "Bachmanning",
        "Brunnenthal (Brunnenthal)",
        "Diersbach",
        "Edlbach",
        "Eggerding (Eggerding)",
        "Eidenberg",
        "Eitzing (Höhnhart)",
        "Esternberg",
        "Feldkirchen bei Mattighofen",
        "Frankenburg am Hausruck",
        "Gallneukirchen",
        "Gallspach",
        "Gampern",
        "Geboltskirchen",
        "Geiersberg",
        "Gramastetten",
        "Grieskirchen",
        "Grünbach (Grünbach)",
        "Hargelsberg",
        "Helfenberg (Ahorn, Helfenberg)",
        "Hofkirchen im Mühlkreis",
        "Hofkirchen im Traunkreis",
        "Hörsching",
        "Inzersdorf im Kremstal",
        "Katsdorf",
        "Kefermarkt",
        "Kematen an der Krems",
        "Klam",
        "Kleinzell im Mühlkreis",
        "Kollerschlag",
        "Lambrechten",
        "Lembach im Mühlkreis",
        "Lochen",
        "Meggenhofen",
        "Micheldorf (Micheldorf in Oberösterreich)",
        "Münzkirchen",
        "Nebelberg",
        "Neufelden",
        "Neukirchen an der Enknach",
        "Neumarkt im Mühlkreis",
        "Niederkappel",
        "Niederwaldkirchen",
        "Nußbach (Nußbach)",
        "Oberschlierbach",
        "Oberwang",
        "Oepping",
        "Pabneukirchen",
        "Palting",
        "Peuerbach",
        "Pfaffing (Pfaffing)",
        "Pfarrkirchen bei Bad Hall",
        "Pierbach (Pierbach, Sankt Thomas am Blasenstein)",
        "Pinsdorf",
        "Pischelsdorf am Engelbach",
        "Pupping",
        "Putzleinsdorf",
        "Raab",
        "Rading (Bad Leonfelden)",
        "Reichersberg (Reichersberg)",
        "Riedau",
        "Rosenau am Hengstpaß",
        "Rüstorf",
        "Sankt Aegidi",
        "Sankt Georgen bei Obernberg am Inn",
        "Sankt Gotthard im Mühlkreis",
        "Sankt Johann am Walde",
        "Sankt Konrad",
        "Sankt Leonhard bei Freistadt",
        "Sankt Marienkirchen an der Polsenz",
        "Sankt Oswald bei Freistadt",
        "Sankt Pankraz",
        "Sankt Ulrich im Mühlkreis",
        "Sankt Veit im Mühlkreis",
        "Saxen",
        "Schenkenfelden",
        "Schöneben (Ulrichsberg)",
        "Schörfling",
        "Schwarzenberg am Böhmerwald",
        "Steinbach an der Steyr",
        "Straß (Timelkam)",
        "Suben",
        "Taiskirchen im Innkreis",
        "Tarsdorf",
        "Timelkam",
        "Tollet",
        "Unterlaussa (Weyer)",
        "Untermühl",
        "Unterweitersdorf",
        "Waizenkirchen",
        "Weilbach (Weilbach)",
        "Wernstein am Inn",
        "Wolfern",
        "Wolfsegg am Hausruck",
        "Zell am Moos",
        "Zwettl an der Rodl",
        "Schärding Innere Stadt",
        "Schärding Vorstadt",
      ],
      Hamburg: ["Hamburg-Stadt (Hamburg, Freie und Hansestadt)"],
      "Baden-Württemberg": [
        "Freiburg im Breisgau",
        "Tübingen",
        "Karlsruhe",
        "Calw",
        "Baden-Baden",
        "Esslingen am Neckar",
        "Ulm",
        "Mannheim",
        "Stuttgart",
        "Eppingen",
        "Höchenschwand",
        "Schramberg",
        "Sinsheim",
        "Überlingen",
        "Waldshut-Tiengen",
        "Albstadt",
        "Hayingen",
        "Reutlingen",
        "Bad Wimpfen",
        "Badenweiler",
        "Balingen",
        "Löffingen",
        "Weil am Rhein",
        "Bad Herrenalb",
        "Bad Liebenzell",
        "Bad Urach",
        "Göppingen",
        "Konstanz",
        "Schwäbisch Hall",
        "Singen",
        "Münsingen (Reutlingen)",
        "Titisee-Neustadt",
        "Abtsgmünd",
        "Emmendingen",
        "Freudenstadt",
        "Grafenhausen",
        "Hechingen",
        "Pfullendorf",
        "Radolfzell am Bodensee",
        "Rottweil",
        "Schiltach",
        "Tuttlingen",
        "Waldkirch (Emmendingen)",
        "Weinheim",
        "Zell im Wiesental",
        "Aalen",
        "Bad Rappenau",
        "Burladingen",
        "Gaggenau",
        "Reichenau (Konstanz)",
        "Schluchsee",
        "Wolfach",
        "Bad Bellingen",
        "Bad Wurzach",
        "Baiersbronn",
        "Bühl (Rastatt)",
        "Ettenheim",
        "Friedrichshafen",
        "Gernsbach",
        "Gomadingen",
        "Haigerloch",
        "Haslach im Kinzigtal",
        "Heidelberg",
        "Kirchheim unter Teck",
        "Lichtenstein (Reutlingen)",
        "Ludwigsburg",
        "Meersburg",
        "Meßkirch",
        "Mössingen",
        "Nagold",
        "Offenburg (Ortenaukreis)",
        "Rastatt",
        "Villingen-Schwenningen",
        "Weilheim",
        "Achern",
        "Bad Krozingen",
        "Bad Mergentheim",
        "Bad Säckingen",
        "Blaubeuren",
        "Donaueschingen",
        "Durbach",
        "Ehingen an der Donau",
        "Feldberg",
        "Forbach",
        "Gerstetten",
        "Herbolzheim",
        "Herrischried",
        "Hornberg (Ortenaukreis)",
        "Hüfingen",
        "Kornwestheim",
        "Meßstetten",
        "Neckargemünd",
        "Neuenburg am Rhein",
        "Oppenau",
        "Rosenfeld (Zollernalbkreis)",
        "Rust (Ortenaukreis)",
        "Schömberg (Zollernalbkreis)",
        "Sigmaringen",
        "Uhldingen-Mühlhofen",
        "Waldachtal",
        "Wertheim (Main-Tauber-Kreis)",
        "Bad Ditzenbach",
        "Bruchsal",
        "Cleebronn",
        "Eislingen/Fils",
        "Ellwangen (Jagst)",
        "Endingen am Kaiserstuhl",
        "Freiamt",
        "Gammertingen",
        "Geislingen",
        "Geislingen an der Steige",
        "Gutach (Schwarzwaldbahn)",
        "Hausach",
        "Heilbronn",
        "Horb am Neckar",
        "Kehl",
        "Kraichtal",
        "Lautenbach",
        "Meckesheim",
        "Metzingen",
        "Neuenbürg",
        "Oberkirch",
        "Pforzheim",
        "Rangendingen",
        "Römerstein",
        "Rottenburg am Neckar",
        "Salem (Bodenseekreis)",
        "Sasbachwalden",
        "Schliengen",
        "Schömberg (Calw)",
        "Schorndorf (Rems-Murr-Kreis)",
        "Schriesheim",
        "Schutterwald",
        "Schwaigern (Landkreis Heilbronn)",
        "Schwetzingen",
        "Seebach (Ortenaukreis)",
        "Sonnenbühl",
        "Staufen im Breisgau",
        "Tauberbischofsheim",
        "Tengen",
        "Trochtelfingen",
        "Trossingen",
        "Ühlingen-Birkendorf",
        "Unterkirnach",
        "Wangen im Allgäu",
        "Westerheim (Alb-Donau-Kreis)",
        "Zuzenhausen",
        "Abstatt",
        "Adelberg",
        "Alpirsbach",
        "Altensteig",
        "Angelbachtal",
        "Aulendorf",
        "Bad Dürrheim",
        "Bad Peterstal-Griesbach",
        "Bad Rippoldsau-Schapbach",
        "Bad Schönborn",
        "Bad Teinach-Zavelstein",
        "Bad Überkingen",
        "Bad Wildbad im Schwarzwald",
        "Berghülen",
        "Beuren (Esslingen)",
        "Beuron",
        "Biberach",
        "Bietigheim-Bissingen",
        "Blaustein",
        "Bodman-Ludwigshafen",
        "Bollschweil",
        "Bopfingen",
        "Brackenheim",
        "Breisach am Rhein",
        "Denzlingen",
        "Dischingen",
        "Dobel",
        "Donzdorf",
        "Dormettingen",
        "Dornstetten",
        "Dotternhausen",
        "Eichstetten am Kaiserstuhl",
        "Elzach",
        "Engstingen",
        "Ettlingen",
        "Filderstadt",
        "Friedenweiler",
        "Gärtringen",
        "Geisingen",
        "Gengenbach",
        "Grenzach-Wyhlen",
        "Heidenheim an der Brenz",
        "Heiningen (Göppingen)",
        "Helmstadt-Bargen",
        "Herbertingen",
        "Herbrechtingen",
        "Hettingen",
        "Heubach (Ostalbkreis)",
        "Hohenstein (Reutlingen)",
        "Holzmaden",
        "Illmensee",
        "Immenstaad am Bodensee",
        "Inzigkofen",
        "Jagsthausen",
        "Kaisersbach",
        "Kappel-Grafenhausen",
        "Kappelrodeck",
        "Kißlegg",
        "Kressbronn am Bodensee",
        "Künzelsau",
        "Kuppenheim",
        "Laichingen",
        "Langenau",
        "Langenburg",
        "Lauchheim",
        "Leibertingen",
        "Lenzkirch",
        "Leonberg (Böblingen)",
        "Lorch (Ostalbkreis)",
        "Lörrach",
        "Löwenstein",
        "Marbach am Neckar",
        "Maulbronn",
        "Meckenbeuren",
        "Mengen",
        "Möckmühl",
        "Mosbach",
        "Mühlacker",
        "Müllheim",
        "Munderkingen",
        "Münstertal",
        "Mutlangen",
        "Neckarbischofsheim",
        "Neckarwestheim",
        "Neubulach",
        "Neuffen",
        "Niederstotzingen",
        "Nordrach",
        "Oberharmersbach",
        "Obermarchtal",
        "Oberried (Landkreis Breisgau-Hochschwarzwald)",
        "Oberwolfach",
        "Oedheim",
        "Oppenweiler",
        "Ottenhöfen im Schwarzwald",
        "Owen",
        "Rainau",
        "Ravensburg",
        "Renchen",
        "Rheinhausen",
        "Rickenbach",
        "Riesbürg",
        "Sachsenheim",
        "Salach (Göppingen)",
        "Sankt Georgen im Schwarzwald",
        "Seelbach (Ortenaukreis)",
        "Sontheim an der Brenz",
        "Steinheim am Albuch",
        "Stödtlen",
        "Straßberg (Zollernalbkreis)",
        "Sulzburg",
        "Tettnang",
        "Todtnau",
        "Veringenstadt",
        "Vogtsburg im Kaiserstuhl",
        "Waghäusel",
        "Waldbronn",
        "Wäschenbeuren",
        "Weikersheim",
        "Weinsberg",
        "Werbach",
        "Westhausen (Ostalbkreis)",
        "Wieden (Landkreis Lörrach)",
        "Wiesensteig",
        "Winden im Elztal",
        "Wurmlingen",
        "Zell am Harmersbach",
      ],
      "Trentino-Südtirol": [
        "Bozen",
        "Meran",
        "Schenna",
        "Bruneck",
        "Toblach",
        "Gsieser Tal",
        "Villnöss",
        "Ratschings",
        "St. Leonhard in Passeier",
        "Brixen",
        "Eppan",
        "Völs am Schlern",
        "Lana (Trentino-Südtirol)",
        "Sand in Taufers",
        "Schnals",
        "Innichen",
        "Sterzing",
        "Kaltern",
        "Mals",
        "Partschins",
        "Prad am Stilfserjoch",
        "Schlanders",
        "St. Ulrich - Gröden",
        "St. Vigil in Enneberg",
        "Steinegg (Trentino-Südtirol)",
        "Aldein",
        "Algund",
        "Jenesien",
        "Kastelbell-Tschars",
        "Latsch (Trentino-Südtirol)",
        "Naturns",
        "Obereggen (Trentino-Südtirol)",
        "Terenten",
        "Dorf Tirol",
        "Freienfeld",
        "Glurns",
        "Kastelruth",
        "Klausen (Trentino-Südtirol)",
        "Mühlbach (Trentino-Südtirol)",
        "Ritten",
        "Seis am Schlern",
        "Sexten",
        "Sulden",
        "Altrei",
        "Corvara",
        "Feldthurns",
        "Mühlwald (Trentino-Südtirol)",
        "Natz-Schabs",
        "Neumarkt",
        "Oberolang",
        "St. Christina - Gröden",
        "Steinhaus/ Ahrntal",
        "Tisens-Prissian",
        "Tscherms",
        "Welschnofen",
        "Wolkenstein - Gröden",
        "Ahrntal",
        "Antholz Mittertal",
        "Colfosco",
        "Deutschnofen",
        "Freiberg (Trentino-Südtirol)",
        "Gais (Trentino-Südtirol)",
        "Gargazon",
        "Graun im Vinschgau",
        "Hafling",
        "Karneid",
        "Lüsen",
        "Luttach",
        "Martell",
        "Moos in Passeier",
        "Nals",
        "Naturns, Südtirol",
        "Niederdorf (Trentino-Südtirol)",
        "Niederolang",
        "Prettau",
        "Salurn",
        "Schluderns",
        "St. Lorenzen (Trentino-Südtirol)",
        "St. Walburg",
        "St.Ulrich - Gröden",
        "Steinhaus/ Ahrntal (Südtirol)",
        "Stilfs",
        "Taufers im Münstertal",
        "Terlan",
        "Tiers",
        "Tirol",
        "Tramin",
        "Trento",
        "Villanders",
        "Vintl",
        "Wanderdorf La Val",
        "Welsberg-Taisten",
        "Wolfsgruben - Ritten",
        "39042 Brixen",
        "39100 Bozen",
        "Abtei (Trentino-Südtirol)",
        "Arco",
        "Auer",
        "Badia",
        "Barbian",
        "Burgeis",
        "Deutschnfoen",
        "Enneberg",
        "Eppan/Berg",
        "Franzensfeste",
        "Gemeinde Karneid",
        "Girlan | Eppan",
        "Gossensass",
        "Graun",
        "Gummer",
        "Kaltern an der Weinstrasse",
        "Kurtatsch",
        "Laas (Trentino-Südtirol)",
        "Lajen",
        "Mals im Vinschgau",
        "Mareit, Kirchdorf 25, Ratschings",
        "Margreid an der Weinstraße",
        "Marling",
        "Moelten",
        "Oberbozen - Ritten",
        "Olang",
        "Partschins/Rabland",
        "Passeier",
        "Pfalzen/Issing",
        "Pichl (Trentino-Südtirol)",
        "Polsa di Bretonico",
        "Prad am Stilferjoch",
        "Prags",
        "Prämajur - Mals",
        "Predazzo",
        "Raas (Trentino-Südtirol)",
        "Rabland",
        "Radein",
        "Rasen-Antholz",
        "Reischach (Trentino-Südtirol)",
        "Reschen",
        "Riva del Garda",
        "Rodeneck",
        "San Cassiano",
        "San Martin Dolomites",
        "Sankt Lorenzen (Trentino-Südtirol)",
        "Sarntal",
        "Sarnthein",
        "Sarnthein Bozen Südtirol",
        "St. Andrä/Brixen",
        "St. Gertraud im Ultental",
        "St. Kassian",
        "St. Martin in Passeier",
        "St. Martin in Thurn",
        "St. Michael | Eppan",
        "St. Peter im Ahrntal",
        "St. Ulrich (Trentino-Südtirol)",
        "St. Valentin auf der Haide",
        "St. Vigil",
        "St.Martin in Passeier",
        "St.Vigil in Enneberg",
        "Steinhaus (Trentino-Südtirol)",
        "Steinhaus/ Ahrntal (BZ)",
        "Tartsch",
        "Teis",
        "Tenno",
        "Tiers am Rosengarten",
        "Töll - Partschins",
        "Torbole sul Garda",
        "Truden",
        "Tschars",
        "Tschengls",
        "Unsere Liebe Frau im Walde-Sankt Felix",
        "Vahrn",
        "Vilpian",
        "Waidbruck",
        "Welsberg",
        "Welsberg - Taisten",
        "Welsberg, Taisten oder Gsieser Tal",
        "Wiesen/Pfitsch",
      ],
      Steiermark: [
        "Graz",
        "Schladming",
        "Admont (Admont)",
        "Gröbming",
        "Altaussee",
        "Eisenerz",
        "Fürstenfeld",
        "Passail",
        "Ramsau am Dachstein",
        "Birkfeld",
        "Burgau (Burgau)",
        "Mariazell",
        "Murau (Murau)",
        "Palfau",
        "Spielberg (Spielberg)",
        "Stainz",
        "Bad Gleichenberg",
        "Bad Mitterndorf",
        "Bruck an der Mur",
        "Deutschlandsberg",
        "Gleisdorf",
        "Großsteinbach",
        "Hartberg (Hartberg)",
        "Hohentauern",
        "Judenburg",
        "Mürzzuschlag",
        "Oberzeiring",
        "Pöllau (Pöllau)",
        "Riegersburg (Riegersburg)",
        "Sebersdorf",
        "Semriach",
        "Stubenberg am See",
        "Anger (Anger)",
        "Bad Aussee",
        "Bad Gams",
        "Feldbach (Feldbach)",
        "Frohnleiten",
        "Gleinstätten",
        "Haus (Haus)",
        "Krieglach",
        "Laßnitzhöhe",
        "Liezen",
        "Oberweg (Judenburg)",
        "Puch bei Weiz",
        "Rettenegg",
        "Schönberg-Lachtal",
        "Vorau (Vorau)",
        "Weiz",
        "Wundschuh",
        "Aigen im Ennstal",
        "Altenmarkt bei Fürstenfeld",
        "Archkogl",
        "Bad Radkersburg",
        "Bad Waltersdorf",
        "Bärnbach (Bärnbach)",
        "Baumgarten (Pinggau)",
        "Deutschfeistritz",
        "Ebersdorf (Ebersdorf)",
        "Edelsbach bei Feldbach",
        "Edelschrott",
        "Eibiswald",
        "Fehring",
        "Fischbach (Fischbach)",
        "Fladnitz an der Teichalm",
        "Fohnsdorf",
        "Gaishorn am See",
        "Gamlitz",
        "Gössendorf",
        "Großklein",
        "Hart bei Graz",
        "Heimschuh",
        "Hirschegg (Hirschegg-Pack)",
        "Hub (Stattegg)",
        "Ilz",
        "Jagerberg",
        "Kalsdorf bei Graz",
        "Kapfenberg",
        "Kapfenstein",
        "Kirchberg an der Raab",
        "Knittelfeld",
        "Kobenz",
        "Köflach",
        "Krottendorf (Weiz)",
        "Kumberg",
        "Leibnitz (Leibnitz)",
        "Leoben (Leoben)",
        "Lieboch",
        "Maria Lankowitz",
        "Mariahof",
        "Michaelerberg (Michaelerberg-Pruggern)",
        "Mooslandl",
        "Mühldorf bei Feldbach",
        "Mureck",
        "Neumarkt in Steiermark",
        "Oberaich (Bruck an der Mur)",
        "Öblarn",
        "Peggau",
        "Pinggau",
        "Pöls",
        "Pößnitz",
        "Präbichl",
        "Pusterwald",
        "Ratsch an der Weinstraße",
        "Remschnigg",
        "Rohrmoos",
        "Röthelstein",
        "Sallaberg",
        "Sankt Johann im Saggautal",
        "Sankt Johann in der Haide",
        "Sankt Peter ob Judenburg",
        "Scheifling (Scheifling)",
        "Seiersberg",
        "Sinabelkirchen",
        "Spital am Semmering",
        "St. Margarethen an der Raab",
        "Straß in Steiermark",
        "Thörl (Thörl)",
        "Trieben",
        "Turnau",
        "Turrach",
        "Unterlamm (Unterlamm)",
        "Voitsberg",
        "Vordernberg",
        "Wald am Schoberpaß",
        "Wettmannstätten",
        "Wildalpen",
        "Wildon",
        "Wörschach",
        "Zeltweg",
      ],
      Graubünden: [
        "Chur",
        "Scuol",
        "Savognin",
        "St. Moritz",
        "Arosa",
        "Sedrun",
        "Davos Platz",
        "Maloja",
        "Pontresina",
        "Samedan",
        "Zernez",
        "Grüsch",
        "Rueun",
        "Tschierv",
        "Zuoz",
        "Bergün/Bravuogn",
        "Bonaduz",
        "Churwalden",
        "Davos Dorf",
        "Flims Dorf",
        "Poschiavo",
        "Andeer",
        "Guarda",
        "Maienfeld",
        "Samnaun-Compatsch",
        "Silvaplana",
        "Splügen",
        "St. Antönien",
        "Thusis",
        "Valendas",
        "Vals (Vals)",
        "Versam",
        "Wergenstein",
        "Curaglia",
        "Domat/Ems",
        "Ftan",
        "Fuldera",
        "Klosters",
        "Madulain",
        "Müstair",
        "Samnaun Dorf",
        "Sent",
        "Stampa",
        "Sufers",
        "Susch",
        "Tartar",
        "Vicosoprano",
        "Ardez",
        "Arezen",
        "Augio",
        "Bivio",
        "Cama",
        "Castasegna",
        "Cauco",
        "Celerina/Schlarigna",
        "Champfèr",
        "Cinuos-chel",
        "Davos Frauenkirch",
        "Davos Glaris",
        "Disentis/Mustér",
        "Fideris",
        "Filisur",
        "Flims Waldhaus",
        "Flond",
        "Fürstenau (Fürstenau)",
        "Haldenstein",
        "Hinterrhein",
        "Ilanz",
        "Innerferrera",
        "Jenaz",
        "Lantsch/Lenz",
        "Lumbrein",
        "Malix",
        "Mesocco",
        "Mutten",
        "Obermutten",
        "Pany",
        "Parpan",
        "Riom",
        "Rueras",
        "Sarn",
        "Seewis Dorf",
        "Sils/Segl Baselgia",
        "Stierva",
        "Strada",
        "Sur",
        "Surcuolm",
        "Tarasp",
        "Tenna",
        "Thalkirch",
        "Trin",
        "Tschappina",
        "Tschiertschen",
        "Valchava",
        "Vnà",
        "Vulpera",
        "Zillis",
      ],
      "Basel-Stadt": ["Basel (Basel)", "Riehen"],
      Bremen: ["Bremen-Stadt", "Bremerhaven"],
      Vorarlberg: [
        "Dornbirn",
        "Feldkirch",
        "Bludenz",
        "Bregenz",
        "Hohenems",
        "Brand (Brand)",
        "Vandans",
        "Hard",
        "Götzis",
        "Nüziders",
        "Schruns",
        "Lustenau",
        "Rankweil",
        "Warth (Warth)",
        "Dalaas",
        "Egg (Egg)",
        "Frastanz",
        "Gaschurn",
        "Koblach",
        "Schröcken",
        "Schwarzenberg (Schwarzenberg)",
        "Wolfurt",
        "Alberschwende",
        "Altach",
        "Bartholomäberg",
        "Bezau",
        "Bürs",
        "Bürserberg",
        "Damüls",
        "Hirschegg (Mittelberg)",
        "Meiningen (Meiningen)",
        "Mellau",
        "Nenzing",
        "Riezlern",
        "Schnifis",
        "Schwarzach (Schwarzach)",
        "Silbertal",
        "St. Gerold",
        "Sulz (Sulz)",
        "Tschagguns",
        "Blons (Blons)",
        "Doren",
        "Dünserberg",
        "Faschina",
        "Fontanella",
        "Göfis",
        "Hittisau",
        "Höchst",
        "Lauterach (Lauterach)",
        "Lech",
        "Lingenau",
        "Lochau",
        "Mäder",
        "Muntlix",
        "Riefensberg",
        "Schlins",
        "Schnepfau",
        "Schoppernau",
        "Stein (Sonntag)",
        "Thüringen (Thüringen)",
        "Thüringerberg",
        "Wald am Arlberg",
      ],
      Sachsen: [
        "Dresden",
        "Leipzig",
        "Altenberg (Landkreis Sächsische Schweiz)",
        "Bad Muskau",
        "Chemnitz",
        "Eibenstock",
        "Görlitz",
        "Hohnstein",
        "Kriebstein",
        "Neißeaue",
        "Rathen (Landkreis Sächsische Schweiz)",
        "Stadt Wehlen",
        "Stolpen",
        "Weißwasser",
        "Augustusburg",
        "Bautzen",
        "Bischofswerda",
        "Delitzsch",
        "Erlbach (Vogtlandkreis)",
        "Freiberg (Landkreis Mittelsachsen)",
        "Geising",
        "Grimma",
        "Hirschfeld (Zwickau)",
        "Jöhstadt",
        "Leisnig",
        "Lengenfeld (Vogtlandkreis)",
        "Lichtenau (Landkreis Mittelsachsen)",
        "Meißen",
        "Moritzburg",
        "Mügeln",
        "Müglitztal",
        "Pirna",
        "Pöhl",
        "Radebeul",
        "Rechenberg-Bienenmühle",
        "Rodewisch",
        "Schkeuditz",
        "Schöneck/Vogtland",
        "Schönheide",
        "Torgau",
        "Zittau",
      ],
      Burgenland: [
        "Eisenstadt",
        "Deutschkreutz",
        "Rechnitz",
        "Donnerskirchen",
        "Forchtenstein",
        "Frauenkirchen",
        "Gols",
        "Illmitz",
        "Jennersdorf",
        "Königsdorf (Königsdorf)",
        "Apetlon",
        "Bad Sauerbrunn",
        "Bad Tatzmannsdorf",
        "Güssing",
        "Lutzmannsburg",
        "Mattersburg",
        "Mischendorf",
        "Mörbisch am See",
        "Neusiedl am See",
        "Podersdorf am See",
        "Andau",
        "Bernstein",
        "Draßmarkt",
        "Gerersdorf bei Güssing",
        "Großhöflein",
        "Großpetersdorf",
        "Halbturn",
        "Lackenbach",
        "Lockenhaus",
        "Marz",
        "Neuhaus am Klausenbach",
        "Oberschützen",
        "Pinkafeld",
        "Purbach am Neusiedler See",
        "Ritzing (Ritzing)",
        "Rohr im Burgenland",
        "Rotenturm an der Pinka",
        "Rudersdorf (Rudersdorf)",
        "Rust (Rust)",
        "Schattendorf",
        "Stadtschlaining",
        "Badersdorf",
        "Breitenbrunn (Breitenbrunn am Neusiedler See)",
        "Burgauberg",
        "Deutsch Jahrndorf",
        "Eberau",
        "Edelstal",
        "Eisenberg an der Pinka",
        "Hannersdorf",
        "Heiligenkreuz im Lafnitztal",
        "Heugraben (Heugraben)",
        "Hirm (Hirm)",
        "Kaisersteinbruch",
        "Kemeten",
        "Kittsee",
        "Kleinmürbisch",
        "Kobersdorf",
        "Lindgraben",
        "Litzelsdorf",
        "Mogersdorf",
        "Mönchhof",
        "Moschendorf",
        "Neckenmarkt",
        "Neudauberg",
        "Neudörfl (Neudörfl)",
        "Neufeld an der Leitha",
        "Neumarkt an der Raab",
        "Neutal",
        "Nickelsdorf",
        "Oberdorf im Burgenland",
        "Oberkohlstätten",
        "Oberloisdorf",
        "Oberrabnitz",
        "Pamhagen",
        "Piringsdorf",
        "Potzneusiedl",
        "Rauchwart",
        "Riedlingsdorf",
        "Sigleß",
        "Stegersbach",
        "Steinfurt (Strem)",
        "Strebersdorf",
        "Strem",
        "Tschanigraben",
        "Unterkohlstätten",
        "Unterschützen",
        "Weiden am See",
        "Welgersdorf",
        "Wiesen (Wiesen)",
        "Wolfau (Wolfau)",
        "Wörterberg",
        "Hornstein / Vorištan",
      ],
      Tirol: [
        "Serfaus",
        "Lienz (Lienz)",
        "Fiss",
        "Innsbruck",
        "Tux",
        "Neustift im Stubaital",
        "Fieberbrunn",
        "Fügen",
        "Ladis",
        "Maurach",
        "Schwaz",
        "Waidring (Waidring)",
        "Mayrhofen (Mayrhofen)",
        "Reutte",
        "Wörgl",
        "Zell am Ziller",
        "Kitzbühel",
        "Jerzens",
        "Kirchberg in Tirol",
        "Kössen",
        "Kufstein",
        "Reith im Alpbachtal",
        "Sölden (Sölden)",
        "Walchsee",
        "Achenkirch",
        "Alpbach",
        "Ebbs",
        "Ellmau",
        "Gschnitz",
        "Imst",
        "Ischgl",
        "Jenbach",
        "Kaltenbach (Kaltenbach)",
        "Münster (Münster)",
        "Mutters",
        "Oetz",
        "Reith bei Seefeld",
        "Sillian",
        "St. Johann in Tirol",
        "Zams",
        "Ehrwald",
        "Elbigenalp",
        "Fügenberg",
        "Galtür",
        "Haiming (Haiming)",
        "Hall in Tirol",
        "Hart im Zillertal",
        "Kundl",
        "Matrei in Osttirol",
        "Natters",
        "Rattenberg (Rattenberg)",
        "Ried im Oberinntal",
        "Söll",
        "St. Anton am Arlberg",
        "Tristach",
        "Wattens",
        "Ainet",
        "Anras",
        "Aschau (Brandenberg)",
        "Bad Häring",
        "Berwang",
        "Biberwier",
        "Bichlbach",
        "Brandenberg",
        "Brixen im Thale",
        "Erpfendorf",
        "Fendels",
        "Finkenberg",
        "Fließ",
        "Fulpmes",
        "Gerlos",
        "Grän",
        "Gries im Sellrain",
        "Grins",
        "Heiterwang",
        "Höfen (Höfen)",
        "Innervillgraten",
        "Itter",
        "Kappl (Kappl)",
        "Kaunertal",
        "Kirchbichl",
        "Längenfeld",
        "Lans",
        "Lavant",
        "Mariatal",
        "Mieders",
        "Mitteregg (Berwang)",
        "Oberndorf in Tirol",
        "Obertilliach",
        "Ochsengarten",
        "Ötztal-Bahnhof",
        "Pertisau",
        "Ramsau im Zillertal",
        "Rohrberg (Rohrberg)",
        "Sautens",
        "Schlitters",
        "Schnann",
        "Schönberg im Stubaital",
        "See (Kappl, See)",
        "Seefeld in Tirol",
        "St. Jakob in Haus",
        "St. Ulrich am Pillersee",
        "Steinach am Brenner",
        "Tannheim (Tannheim)",
        "Terfens",
        "Thurn (Thurn)",
        "Trins",
        "Tulfes",
        "Umhausen",
        "Wenns (Wenns)",
        "Westendorf (Westendorf)",
        "Wildermieming",
      ],
      Niederösterreich: [
        "Schiltern (Langenlois)",
        "Krems an der Donau",
        "Gmünd (Gmünd)",
        "St. Pölten",
        "Tulln an der Donau",
        "Retz",
        "St. Corona am Wechsel",
        "Artstetten",
        "Baden (Baden)",
        "Bärnkopf",
        "Hainfeld (Hainfeld)",
        "Neuhofen an der Ybbs",
        "Schrems (Schrems)",
        "Ernstbrunn",
        "Gaming (Gaming)",
        "Gars am Kamp",
        "Guntramsdorf",
        "Heidenreichstein",
        "Klosterneuburg",
        "Seitenstetten",
        "Semmering-Kurort",
        "Wiener Neudorf",
        "Zwettl-Niederösterreich",
        "Arbesbach",
        "Eggenburg",
        "Felixdorf",
        "Frankenfels",
        "Geras",
        "Göstling an der Ybbs",
        "Herrnbaumgarten",
        "Herzogenburg",
        "Hollabrunn",
        "Laa an der Thaya",
        "Leobendorf",
        "Leobersdorf",
        "Lichtenegg (Lichtenegg)",
        "Lunz am See",
        "Marchegg",
        "Orth an der Donau",
        "Pöggstall",
        "Poysdorf",
        "Purkersdorf (Purkersdorf)",
        "Stein an der Donau",
        "Tattendorf",
        "Türnitz",
        "Wolkersdorf im Weinviertel",
        "Absdorf (Absdorf)",
        "Alland",
        "Asparn an der Zaya",
        "Bad Vöslau",
        "Berndorf (Berndorf)",
        "Bruck an der Leitha",
        "Dürnstein",
        "Eichgraben",
        "Furth bei Göttweig",
        "Grafenschlag (Grafenschlag)",
        "Großschönau (Großschönau)",
        "Gutenstein",
        "Hadersdorf am Kamp",
        "Hainburg an der Donau",
        "Hohenau an der March",
        "Hölles",
        "Karlstein an der Thaya",
        "Katzelsdorf (Katzelsdorf)",
        "Kleinwetzdorf",
        "Krumbach (Krumbach)",
        "Lilienfeld",
        "Litschau",
        "Maiersdorf",
        "Maissau",
        "Maria Gugging",
        "Mauerbach",
        "Mistelbach (Mistelbach)",
        "Mitterbach-Seerotte",
        "Mödling",
        "Mönichkirchen",
        "Mühldorf (Mühldorf)",
        "Ober-Grafendorf",
        "Oberndorf an der Melk",
        "Platt",
        "Puchberg am Schneeberg",
        "Raabs an der Thaya",
        "Rabenstein an der Pielach",
        "Rohrau",
        "Rohrbach (Alland)",
        "Rohrbach an der Gölsen",
        "Scheibbs",
        "Schönbach (Schönbach)",
        "Spitz",
        "Waidhofen an der Ybbs",
        "Weitersfeld",
        "Wiener Neustadt",
        "Wilfersdorf (Wilfersdorf)",
        "Yspertal",
        "Aderklaa",
        "Aggsbach Markt",
        "Alt-Dietmanns",
        "Altenburg (Altenburg)",
        "Alt-Nagelberg",
        "Amaliendorf",
        "Amstetten (Amstetten)",
        "Ardagger Stift",
        "Armschlag",
        "Aspang Markt",
        "Asperhofen",
        "Atzenbrugg",
        "Bad Deutsch-Altenburg",
        "Bad Fischau",
        "Bad Großpertholz",
        "Bad Pirawarth",
        "Bergland",
        "Bernhardsthal",
        "Böhlerwerk",
        "Breitenwaida",
        "Brunn an der Wild",
        "Deutsch-Wagram",
        "Dobersberg (Dobersberg)",
        "Ebenthal (Ebenthal)",
        "Eckartsau",
        "Ehrendorf (Großdietmanns)",
        "Eichenbrunn",
        "Elsarn im Straßertal",
        "Engelhartstetten",
        "Erlauf",
        "Euratsfeld",
        "Falkenstein (Falkenstein)",
        "Felling (Hardegg)",
        "Fels am Wagram",
        "Franzen",
        "Friedersbach",
        "Fromberg",
        "Frühwärts",
        "Gablitz",
        "Gänserndorf",
        "Gassen (Zelking-Matzleinsdorf)",
        "Glinzendorf",
        "Gloggnitz",
        "Göttlesbrunn",
        "Grafenegg",
        "Grafenwörth",
        "Grametschlag",
        "Grimmenstein",
        "Groß-Enzersdorf",
        "Großharras",
        "Großhaselbach",
        "Grünbach am Schneeberg",
        "Grund (Wullersdorf)",
        "Gumpoldskirchen",
        "Guntersdorf",
        "Haag (Haag)",
        "Hagenbrunn",
        "Hardegg (Hardegg)",
        "Harland (St. Pölten)",
        "Haugsdorf",
        "Hausleiten (Hausleiten)",
        "Hinterbrühl",
        "Hirschenwies",
        "Hof am Leithaberge",
        "Hofamt Priel",
        "Hoheneich",
        "Hollenstein an der Ybbs",
        "Horn (Horn)",
        "Kaltenleutgeben",
        "Kapelln (Kapelln)",
        "Karlstetten",
        "Kernhof",
        "Kirchberg am Wechsel",
        "Kirchberg an der Pielach",
        "Kirchschlag in der Buckligen Welt",
        "Kirchstetten (Kirchstetten)",
        "Klein-Neusiedl",
        "Königsbrunn am Wagram",
        "Königstetten",
        "Krumau am Kamp",
        "Lackenhof",
        "Langenlois",
        "Langenzersdorf",
        "Lassee",
        "Laxenburg",
        "Leiben",
        "Lengenfeld (Lengenfeld)",
        "Leopoldsdorf (Leopoldsdorf)",
        "Lichtenwörth",
        "Maißen",
        "Mank",
        "Maria Enzersdorf",
        "Maria Laach am Jauerling",
        "Maria Raisenmarkt",
        "Maria Taferl",
        "Markt Piesting",
        "Mauer bei Melk",
        "Mautern an der Donau",
        "Melk (Melk)",
        "Michelstetten",
        "Miesenbach (Miesenbach)",
        "Mödring (Horn)",
        "Mollands",
        "Muggendorf (Muggendorf)",
        "Münichreith (Münichreith-Laimbach)",
        "Nappersdorf",
        "Neulengbach",
        "Neumarkt an der Ybbs",
        "Neunkirchen (Neunkirchen)",
        "Neusiedl (Hernstein, Waidmannsfeld)",
        "Neusiedl am Steinfeld",
        "Neustift (Scheibbs)",
        "Niedersulz",
        "Nöchling",
        "Nußdorf ob der Traisen",
        "Oberhöflein (Weitersfeld)",
        "Oberndorf (Artstetten-Pöbring)",
        "Oberthern",
        "Oberweiden",
        "Obritzberg",
        "Ottenschlag (Ottenschlag)",
        "Otterthal",
        "Pachfurth",
        "Perchtoldsdorf",
        "Pernegg (Pernegg)",
        "Perschling (Perschling)",
        "Petronell-Carnuntum",
        "Petzenkirchen",
        "Pfaffstätten",
        "Pichl (Zöbern)",
        "Pillichsdorf",
        "Pitten",
        "Plank am Kamp",
        "Pölla (Raxendorf)",
        "Pömling",
        "Pottenstein (Pottenstein)",
        "Poysbrunn",
        "Prellenkirchen",
        "Prottes",
        "Puch (Hollabrunn)",
        "Pyhra (Gnadendorf)",
        "Rappottenstein",
        "Rauchenwarth",
        "Reichenau an der Rax",
        "Reingers",
        "Reinsberg (Reinsberg)",
        "Röhrenbach (Röhrenbach)",
        "Röschitz",
        "Rosenau Schloss",
        "Rosenburg",
        "Ruppersthal",
        "Schallaburg",
        "Schloßhof",
        "Schönberg (Schönberg am Kamp)",
        "Schönkirchen-Reyersdorf",
        "Schrattenberg",
        "Schwadorf (Schwadorf)",
        "Schwarzenau",
        "Schwarzenbach (Schwarzenbach)",
        "Schwechat",
        "Schweiggers",
        "Seebenstein",
        "Seisenegg",
        "Sieghartskirchen",
        "Sigmundsherberg",
        "Sitzendorf (Irnfritz-Messern)",
        "Sonntagberg",
        "Sparbach (Hinterbrühl)",
        "Sprögnitz",
        "St. Bernhard",
        "St. Leonhard am Forst",
        "St. Oswald (St. Oswald)",
        "Stephanshart",
        "Stetten (Stetten)",
        "Stockerau",
        "Straß im Straßertale",
        "Strasshof an der Nordbahn",
        "Stratzing",
        "Thaya",
        "Traiskirchen",
        "Trattenbach",
        "Tribuswinkel",
        "Vitis",
        "Waidhofen an der Thaya",
        "Waitzendorf (Schrattenthal)",
        "Wartmannstetten",
        "Wasenbruck",
        "Weidlingbach",
        "Weinburg",
        "Weißenkirchen in der Wachau",
        "Weiten",
        "Weitenegg",
        "Weitra",
        "Wieselburg",
        "Wildendürnbach",
        "Wilhelmsburg (Wilhelmsburg)",
        "Windigsteig",
        "Wittau",
        "Wolfpassing (Wolfpassing)",
        "Wulzeshofen",
        "Ybbs an der Donau",
        "Ybbsitz",
        "Zeillern",
        "Zellerndorf",
        "Zöbern",
        "Zöbing (Langenlois)",
        "Zweiersdorf",
      ],
      Thüringen: [
        "Erfurt",
        "Altenburg (Altenburger Land)",
        "Greiz",
        "Großbreitenbach",
        "Oberhof (Landkreis Schmalkalden-Meiningen)",
        "Weimar (Weimar, Stadt)",
        "Gotha",
        "Jena",
        "Meiningen (Landkreis Schmalkalden-Meiningen)",
        "Sonneberg",
        "Steinach (Landkreis Sonneberg)",
        "Bad Langensalza",
        "Georgenthal",
        "Mühlhausen (Unstrut-Hainich-Kreis)",
        "Suhl",
        "Zella-Mehlis",
        "Bad Liebenstein",
        "Blankenhain",
        "Gehren",
        "Ilmenau",
        "Ronneburg (Landkreis Greiz)",
        "Schmalkalden",
        "Schönstedt",
        "Seitenroda",
        "Sondershausen",
        "Tambach-Dietharz",
        "Wurzbach",
        "Arnstadt",
        "Bad Klosterlausnitz",
        "Breitungen (Landkreis Schmalkalden-Meiningen)",
        "Eisenach (Eisenach, Stadt)",
        "Eisenberg (Saale-Holzland-Kreis)",
        "Eisfeld",
        "Emleben",
        "Fockendorf",
        "Frauenwald",
        "Geisa",
        "Gera",
        "Gerstungen",
        "Goldisthal",
        "Heilbad Heiligenstadt",
        "Hohenfelden",
        "Ilfeld",
        "Kaltennordheim",
        "Kaltensundheim",
        "Katharinenberg",
        "Kranichfeld",
        "Mackenrode",
        "Meura",
        "Neuhaus am Rennweg",
        "Neustadt an der Orla",
        "Nordhausen",
        "Pößneck",
        "Posterstein",
        "Ruhla",
        "Schalkau",
        "Schleiz",
        "Schleusegrund",
        "Uhlstädt-Kirchhasel",
        "Walldorf (Landkreis Schmalkalden-Meiningen)",
        "Worbis",
        "Wünschendorf (Landkreis Greiz)",
        "Wutha-Farnroda",
      ],
      "St. Gallen": [
        "St. Gallen-Stadt",
        "Bad Ragaz (Pfäfers)",
        "Flumserberg Tannenbodenalp",
        "Pfäfers",
        "Vättis",
        "Flawil",
        "Lömmenschwil",
        "Mogelsberg",
        "Murg (Quarten)",
        "Niederbüren",
        "Rorschach",
        "Walenstadt",
        "Weisstannen",
        "Altenrhein",
        "Diepoldsau",
        "Eichberg (Eichberg)",
        "Flums",
        "Flumserberg Tannenheim",
        "Mels",
        "Mörschwil",
        "Rorschacherberg",
        "Sargans",
        "St. Margrethenberg",
        "Weesen",
      ],
      Zürich: [
        "Zürich-Stadt",
        "Rümlang",
        "Winterthur",
        "Dachsen",
        "Dietikon",
        "Dübendorf",
        "Fällanden",
        "Greifensee",
        "Horgen",
        "Höri",
        "Kloten",
        "Schlieren",
        "Volketswil",
      ],
      Zug: ["Zug-Stadt", "Baar (Baar)", "Oberägeri", "Hagendorn", "Unterägeri"],
      "Rheinland-Pfalz": [
        "Koblenz (Koblenz, kreisfreie Stadt)",
        "Speyer",
        "Mayen",
        "Mainz",
        "Trier",
        "Andernach",
        "Ettringen (Landkreis Mayen-Koblenz)",
        "Boppard",
        "Brodenbach",
        "Cochem",
        "Dahn",
        "Ernzen",
        "Nürburg",
        "Plaidt",
        "Saarburg",
        "Gerolstein",
        "Gondorf",
        "Grimburg",
        "Hermeskeil",
        "Landau in der Pfalz",
        "Manderscheid (Landkreis Bernkastel-Wittlich)",
        "Mendig",
        "Adenau",
        "Altenahr",
        "Bad Breisig",
        "Beilstein (Landkreis Cochem-Zell)",
        "Bendorf (Landkreis Mayen-Koblenz)",
        "Berlingen (Vulkaneifel)",
        "Biersdorf",
        "Bollendorf",
        "Bornich",
        "Daun",
        "Gackenbach",
        "Hamm (Eifelkreis Bitburg-Prüm)",
        "Haßloch",
        "Hilscheid",
        "Idar-Oberstein",
        "Imsbach",
        "Kaiserslautern (Landkreis Kaiserslautern, Kaiserslautern, kreisfreie Stadt)",
        "Kandel",
        "Katzweiler",
        "Kempenich",
        "Klingenmünster",
        "Klotten",
        "Kottenheim",
        "Kretz",
        "Lahnstein (Rhein-Lahn-Kreis)",
        "Langenfeld (Landkreis Mayen-Koblenz)",
        "Lünebach",
        "Malberg (Eifelkreis Bitburg-Prüm)",
        "Montabaur",
        "Mörsdorf (Rhein-Hunsrück-Kreis)",
        "Mürlenbach",
        "Neuwied",
        "Nickenich",
        "Niederdürenbach",
        "Nothweiler",
        "Patersberg",
        "Pirmasens",
        "Pohl",
        "Pommern (Landkreis Cochem-Zell)",
        "Prüm",
        "Reichenberg (Rhein-Lahn-Kreis)",
        "Rittersdorf (Eifelkreis Bitburg-Prüm)",
        "Roes",
        "Sankt Goarshausen",
        "Schuld",
        "Trechtingshausen",
        "Wierschem",
      ],
      "Nordrhein-Westfalen": [
        "Köln",
        "Bielefeld",
        "Aachen",
        "Königswinter",
        "Mechernich",
        "Essen",
        "Soest",
        "Arnsberg",
        "Brühl (Rhein-Erft-Kreis)",
        "Düsseldorf",
        "Oberhausen (Oberhausen, Stadt)",
        "Olsberg (Hochsauerlandkreis)",
        "Duisburg",
        "Hellenthal",
        "Lennestadt",
        "Münster (Münster, Stadt)",
        "Schmallenberg",
        "Warstein",
        "Wuppertal",
        "Attendorn",
        "Blankenheim (Euskirchen)",
        "Bottrop",
        "Engelskirchen",
        "Gevelsberg",
        "Hagen (Hagen, Stadt)",
        "Hilden",
        "Isselburg",
        "Kerpen (Rhein-Erft-Kreis)",
        "Krefeld",
        "Nettersheim",
        "Odenthal",
        "Porta Westfalica",
        "Rheine",
        "Simmerath",
        "Sundern",
        "Winterberg",
        "Zülpich",
        "Altena",
        "Bad Laasphe",
        "Bestwig",
        "Bocholt",
        "Bochum",
        "Brilon",
        "Büren",
        "Castrop-Rauxel",
        "Delbrück",
        "Detmold",
        "Dorsten",
        "Dortmund",
        "Düren",
        "Euskirchen",
        "Gelsenkirchen",
        "Greven (Steinfurt)",
        "Grevenbroich",
        "Gummersbach",
        "Halle (Gütersloh)",
        "Haltern am See",
        "Hamm (Hamm, Stadt)",
        "Hattingen",
        "Havixbeck",
        "Heimbach (Düren)",
        "Herford",
        "Herne",
        "Horn-Bad Meinberg",
        "Hückeswagen",
        "Hürtgenwald",
        "Ibbenbüren",
        "Iserlohn",
        "Kaarst",
        "Kalkar",
        "Kevelaer",
        "Kirchhundem",
        "Kleve (Kleve)",
        "Leverkusen",
        "Lindlar",
        "Lüdinghausen",
        "Marienheide",
        "Meschede",
        "Minden (Minden-Lübbecke)",
        "Möhnesee",
        "Monschau",
        "Nideggen",
        "Nörvenich",
        "Nümbrecht",
        "Plettenberg",
        "Rees",
        "Reken",
        "Rheinbach",
        "Rheinberg",
        "Roetgen",
        "Rösrath",
        "Saerbeck",
        "Solingen",
        "Stadtlohn",
        "Velbert",
        "Waldbröl",
        "Weeze",
        "Werther (Gütersloh)",
        "Xanten",
      ],
      "Schleswig-Holstein": [
        "Lübeck",
        "Fehmarn",
        "Eutin",
        "Eckernförde",
        "Glücksburg",
        "Damp",
        "Grömitz",
        "Heikendorf",
        "Blekendorf",
        "Gettorf",
        "Lensahn",
        "Scharbeutz",
        "Wangels",
        "Altenhof (Kreis Rendsburg-Eckernförde)",
        "Bad Schwartau",
        "Daldorf",
        "Großenaspe",
        "Harmsdorf (Kreis Ostholstein)",
        "Heiligenhafen",
        "Hohenfelde (Kreis Plön)",
        "Husum (Kreis Nordfriesland)",
        "Kiel (Kreisfreie Stadt Kiel, Kreis Rendsburg-Eckernförde)",
        "Laboe",
        "Molfsee",
        "Neustadt in Holstein",
        "Norderstedt",
        "Oldenburg in Holstein",
        "Probsteierhagen",
        "Sierksdorf",
        "Timmendorfer Strand",
        "Warder",
      ],
      Bern: [
        "Bern-Stadt",
        "Burgdorf (Burgdorf)",
        "Biel/Bienne",
        "Rohrbach (Rohrbach)",
        "Affoltern im Emmental",
        "Attiswil",
        "Belp",
        "Crémines",
        "Frutigen",
        "Grindelwald",
        "Ittigen",
        "Mamishaus",
        "Matten b. Interlaken",
        "Meiringen",
        "Rüschegg Heubach",
        "Wabern (Köniz)",
        "Walkringen",
      ],
      Hessen: [
        "Frankfurt am Main",
        "Hanau (Main-Kinzig-Kreis)",
        "Lorsch",
        "Limburg an der Lahn",
        "Weilburg",
        "Michelstadt",
        "Schotten",
        "Edertal",
        "Fulda",
        "Steinau an der Straße",
        "Wiesbaden",
        "Witzenhausen",
        "Bad Homburg vor der Höhe",
        "Braunfels",
        "Darmstadt",
        "Eppstein",
        "Erbach (Odenwaldkreis)",
        "Gersfeld",
        "Hofgeismar",
        "Kassel",
        "Knüllwald",
        "Schmitten (Hochtaunuskreis)",
        "Waldeck (Landkreis Waldeck-Frankenberg)",
        "Weinbach (Limburg-Weilburg)",
        "Alsbach-Hähnlein",
        "Bad Hersfeld",
        "Beerfelden",
        "Biedenkopf",
        "Breidenbach",
        "Breuberg",
        "Buseck",
        "Gelnhausen",
        "Gießen",
        "Glashütten (Hochtaunuskreis)",
        "Greifenstein (Lahn-Dill-Kreis)",
        "Groß-Zimmern",
        "Hohenstein (Rheingau-Taunus-Kreis)",
        "Idstein",
        "Kronberg im Taunus",
        "Lich",
        "Liederbach am Taunus",
        "Marburg",
        "Messel",
        "Mühltal (Darmstadt-Dieburg)",
        "Münzenberg",
        "Nentershausen (Hersfeld-Rotenburg)",
        "Neu-Anspach",
        "Offenbach",
        "Ronneburg (Main-Kinzig-Kreis)",
        "Runkel",
        "Schaafheim",
        "Schlangenbad",
        "Schwalmstadt",
        "Seligenstadt",
        "Sinn",
        "Solms",
        "Taunusstein",
        "Wald-Michelbach",
        "Wehrheim",
        "Weilrod",
        "Wetzlar",
        "Willingen (Upland)",
      ],
      Luzern: [
        "Luzern-Stadt (Luzern, Kriens)",
        "Kriens (Kriens)",
        "Sörenberg",
        "Adligenswil",
        "Emmen",
        "Hämikon",
        "Root",
        "Schüpfheim",
      ],
      Niedersachsen: [
        "Hannover",
        "Bispingen",
        "Bassum",
        "Braunschweig",
        "Soltau",
        "Wilhelmshaven",
        "Hameln",
        "Haren",
        "Herzberg am Harz",
        "Wildeshausen",
        "Wolfsburg",
        "Bad Bentheim",
        "Braunlage",
        "Bruchhausen-Vilsen",
        "Cloppenburg",
        "Dötlingen",
        "Goslar",
        "Lüneburg",
        "Norden",
        "Syke",
        "Twistringen",
        "Adelebsen",
        "Aurich",
        "Bad Harzburg",
        "Bad Sachsa",
        "Barnstorf",
        "Bohmte",
        "Borkum",
        "Bovenden",
        "Bramsche",
        "Bückeburg",
        "Buxtehude",
        "Clausthal-Zellerfeld",
        "Dahlenburg",
        "Egestorf",
        "Einbeck",
        "Embsen",
        "Emsbüren",
        "Freistatt",
        "Friesoythe",
        "Gifhorn",
        "Goldenstedt",
        "Hanstedt",
        "Harpstedt",
        "Hatten",
        "Hildesheim",
        "Hodenhagen",
        "Jever",
        "Krummhörn",
        "Lütetsburg",
        "Munster (Landkreis Heidekreis)",
        "Northeim",
        "Osnabrück",
        "Osten",
        "Pattensen",
        "Rastede",
        "Rieste",
        "Rinteln",
        "Rosengarten (Landkreis Harburg)",
        "Salzhemmendorf",
        "Scharnebeck",
        "Stadthagen",
        "Uetze",
        "Walsrode",
        "Wardenburg",
        "Westerstede",
        "Worpswede",
      ],
      Schaffhausen: [
        "Schaffhausen-Stadt",
        "Neuhausen am Rheinfall",
        "Buchberg (Buchberg)",
        "Ramsen (Ramsen)",
        "Stein am Rhein",
      ],
      Kärnten: [
        "St. Kanzian am Klopeiner See",
        "Ossiach",
        "Millstatt",
        "Winklern (Winklern)",
        "Althofen (Althofen)",
        "Bad Bleiberg",
        "Bad Eisenkappel",
        "Ferlach",
        "Flattach",
        "Innerkrems",
        "Katschberghöhe",
        "Lendorf (Lendorf)",
        "Malta (Malta)",
        "Radenthein",
        "Spittal an der Drau",
        "Völkermarkt",
        "Wolfsberg (Wolfsberg)",
        "Arnoldstein",
        "Feistritz im Rosental",
        "Feld am See",
        "Friesach (Friesach)",
        "Gmünd (Gmünd in Kärnten)",
        "Innerteuchen",
        "Ledenitzen",
        "Moosburg (Moosburg)",
        "Patergassen",
        "Reauz",
        "Rosegg",
        "Seeboden",
        "St. Gertraud (Frantschach-St. Gertraud)",
        "St. Primus",
        "Turracherhöhe",
        "Altenmarkt (Lurnfeld)",
        "Bach (Bad Kleinkirchheim)",
        "Baldramsdorf",
        "Ebene Reichenau",
        "Faak am See",
        "Feistritz (St. Jakob im Rosental)",
        "Fleiß",
        "Fresach",
        "Frög",
        "Gallizien",
        "Greifenburg",
        "Griffen",
        "Gurk (Gurk)",
        "Haimburg",
        "Hermagor",
        "Himmelberg",
        "Hochrindl (Deutsch-Griffen, Albeck)",
        "Hof (Heiligenblut am Großglockner)",
        "Hüttenberg (Hüttenberg)",
        "Kirchbach (Kirchbach)",
        "Klagenfurt",
        "Kleinedling (St. Andrä, Wolfsberg)",
        "Kleinkirchheim",
        "Knappenberg",
        "Magdalensberg (Magdalensberg)",
        "Mallnitz",
        "Maria Rain",
        "Maria Saal",
        "Maria Wörth",
        "Mauthen",
        "Metnitz (Metnitz)",
        "Molzbichl",
        "Neuhaus (Neuhaus)",
        "Nötsch",
        "Oberdrauburg",
        "Obervellach (Obervellach)",
        "Polan (Reißeck)",
        "Presseggersee",
        "Raufen (Obervellach)",
        "Reichenfels",
        "Riegersdorf (Arnoldstein)",
        "Seidendorf",
        "Sittersdorf",
        "St. Jakob im Rosental",
        "St. Oswald (Bad Kleinkirchheim)",
        "St. Veit an der Glan",
        "Steindorf (Weitensfeld im Gurktal)",
        "Steinfeld (Steinfeld)",
        "Stockenboi",
        "Straßburg-Stadt",
        "Strau",
        "Techendorf",
        "Tratten (Reißeck)",
        "Unterbergen (Ferlach)",
        "Unterhaus (Seeboden am Millstätter See, Baldramsdorf)",
        "Vorderberg",
        "Weißbriach",
        "Wernberg",
        "Winklern (Treffen am Ossiacher See)",
      ],
      "Sachsen-Anhalt": [
        "Eckartsberga",
        "Halle (Kreisfreie Stadt Halle)",
        "Thale",
        "Bernburg",
        "Petersberg (Saalekreis)",
        "Dessau-Roßlau",
        "Magdeburg",
        "Naumburg (Burgenlandkreis)",
        "Bad Kösen",
        "Egeln",
        "Flechtingen",
        "Leuna",
        "Lutherstadt Eisleben",
        "Lützen",
        "Merseburg",
        "Oschersleben",
        "Querfurt",
        "Schönburg",
        "Stößen",
      ],
      "Appenzell Innerrhoden": ["Jakobsbad", "Appenzell", "Brülisau"],
      Saarland: [
        "Merzig",
        "Saarbrücken",
        "Blieskastel",
        "Homburg (Saarpfalz-Kreis)",
        "Gersheim",
        "Mandelbachtal",
        "Nohfelden",
        "Tholey",
        "Weiskirchen",
        "Bexbach",
        "Bous",
        "Ensdorf (Saarlouis)",
        "Eppelborn",
        "Freisen",
        "Losheim am See",
        "Merchweiler",
        "Mettlach",
        "Neunkirchen/Saar",
        "Nonnweiler",
        "Ottweiler",
        "Perl",
        "Püttlingen",
        "Saarlouis",
        "Sankt Ingbert",
        "Schiffweiler",
        "Völklingen",
        "Wadern",
        "Wadgassen",
      ],
      Brandenburg: [
        "Potsdam",
        "Bad Saarow",
        "Alt Ruppin",
        "Angermünde",
        "Baruth/Mark",
        "Beelitz (Landkreis Potsdam-Mittelmark)",
        "Chorin",
        "Cottbus",
        "Dahlwitz-Hoppegarten",
        "Eberswalde",
        "Elstal",
        "Falkensee",
        "Kremmen",
        "Liebenthal (Landkreis Oberhavel)",
        "Löwenberger Land",
        "Luckau (Landkreis Dahme-Spreewald)",
        "Oderberg",
        "Oranienburg",
        "Rheinsberg",
        "Schenkenberg (Landkreis Uckermark)",
        "Strausberg",
        "Teltow",
        "Templin",
        "Vetschau",
        "Wendisch Rietz",
        "Zehdenick",
        "Zossen",
      ],
      Obwalden: ["Engelberg (Engelberg)", "Sarnen", "Alpnachstad", "Kerns"],
      "Appenzell Ausserrhoden": [
        "Herisau",
        "Gais (Gais)",
        "Urnäsch",
        "Rehetobel",
        "Speicher (Speicher)",
        "Stein AR",
        "Trogen (Trogen)",
      ],
      "Mecklenburg-Vorpommern": [
        "Neustrelitz",
        "Ribnitz-Damgarten",
        "Rostock (Kreisfreie Stadt Rostock)",
        "Schwerin (Schwerin)",
        "Stralsund",
        "Greifswald",
        "Wismar",
        "Anklam",
        "Binz (Vorpommern-Rügen)",
        "Neubrandenburg",
        "Wolgast",
        "Alt Schwerin",
        "Ankershagen",
        "Bad Doberan",
        "Bad Sülze",
        "Born a. Darß",
        "Breest",
        "Dargun",
        "Feldberger Seenlandschaft",
        "Gingst",
        "Glowe",
        "Göhren (Vorpommern-Rügen)",
        "Göhren-Lebbin",
        "Grevesmühlen",
        "Groß Mohrdorf",
        "Güstrow",
        "Kalkhorst",
        "Klocksin",
        "Klütz",
        "Koserow",
        "Kühlungsborn",
        "Lübz",
        "Malchow",
        "Marlow",
        "Mirow",
        "Neustadt-Glewe",
        "Pasewalk",
        "Peenemünde",
        "Penzlin",
        "Plau am See",
        "Putbus",
        "Rechlin",
        "Röbel/Müritz",
        "Rövershagen",
        "Sassnitz",
        "Stuer",
        "Ückeritz",
        "Ueckermünde",
        "Waren (Müritz)",
        "Wittenburg",
        "Zingst",
        "Zinnowitz",
        "Zirkow",
      ],
      Solothurn: [
        "Solothurn-Stadt",
        "Balsthal",
        "Gänsbrunnen",
        "Balmberg",
        "Bellach",
        "Flumenthal",
        "Oberdorf SO",
        "Wolfwil",
      ],
      Aargau: [
        "Baden (Baden)",
        "Aarau",
        "Bad Zurzach",
        "Endingen",
        "Fisibach",
        "Frick",
        "Gränichen",
        "Habsburg",
        "Hunzenschwil",
        "Lenzburg",
        "Niederlenz",
        "Reitnau",
        "Remigen",
        "Rheinfelden (Rheinfelden)",
        "Seengen",
        "Spreitenbach",
        "Strengelbach",
        "Wildegg",
      ],
      Freiburg: [
        "Bulle",
        "Fribourg",
        "Granges-Paccot",
        "Schwarzsee",
        "Bösingen (Bösingen)",
        "Broc",
        "Estavayer-le-Lac",
        "Givisiez",
        "Kerzers",
        "Pringy",
      ],
      Schwyz: [
        "Einsiedeln",
        "Morschach",
        "Goldau",
        "Ibach (Schwyz)",
        "Küssnacht am Rigi",
        "Reichenburg",
        "Sattel (Sattel)",
        "Schwyz-Stadt",
      ],
      Glarus: ["Elm", "Filzbach", "Näfels", "Braunwald", "Engi"],
      Nidwalden: ["Beckenried", "Stans (Stans)", "Dallenwil", "Emmetten", "Oberdorf NW", "Oberrickenbach"],
      "Basel-Landschaft": ["Langenbruck", "Pratteln", "Frenkendorf", "Liestal", "Münchenstein", "Reigoldswil"],
      Tessin: [
        "Lugano",
        "Bellinzona",
        "Locarno",
        "Ascona",
        "Balerna",
        "Bedretto",
        "Capolago (Mendrisio)",
        "Davesco-Soragno",
        "Giubiasco",
        "Gordola",
        "Magliaso",
        "Melide",
        "Mendrisio",
        "Ruvigliana",
        "Sessa",
      ],
      Thurgau: [
        "Romanshorn",
        "Arbon",
        "Frauenfeld",
        "Güttingen",
        "Kreuzlingen",
        "Amriswil",
        "Berlingen (Berlingen)",
        "Bischofszell",
        "Lipperswil",
        "Neukirch an der Thur",
        "Salenstein",
        "Warth (Warth-Weiningen)",
        "Weinfelden",
      ],
      "Friaul-Julisch Venetien": ["Caorle", "Tarvis"],
      Jura: [
        "Delémont",
        "Saignelégier",
        "Bassecourt",
        "Boncourt",
        "Courroux",
        "Courtételle",
        "Le Noirmont",
        "Les Bois",
        "Les Breuleux",
        "Montenol",
        "Porrentruy",
        "Rebeuvelier",
        "Réclère",
        "St-Ursanne (Saint-Brais)",
        "Vendlincourt",
        "Vicques",
      ],
      Lombardei: [
        "Gardone Riviera",
        "Antegnate",
        "Campione Del Garda",
        "Corte Franca",
        "Desenzano del Garda",
        "Limone sul Garda",
        "Lonato",
        "Lonato del Garda",
        "Milano",
        "San Gervasio Bresciano",
        "Toscolano-Maderno",
        "Valbrembo",
      ],
      Waadt: [
        "Lausanne",
        "Vallorbe",
        "Vevey",
        "Aigle",
        "Bex",
        "Bougy-Villars",
        "Chavornay",
        "Corsier-sur-Vevey",
        "Echallens",
        "Echandens",
        "Le Mont-sur-Lausanne",
        "Le Vaud",
        "Leysin",
        "Payerne",
        "Servion",
        "Tolochenaz",
        "Veytaux",
        "Vuiteboeuf",
        "Yverdon-les-Bains",
      ],
      Venetien: [
        "Lido di Jesolo",
        "Savio",
        "Castelnuevo del Garda",
        "Castelnuovo del Garda",
        "Cavaion Veronese",
        "Cisano di Bardolino",
        "Custoza",
        "Garda",
        "Lazise sul Garda",
        "Lugagnano di Sona",
        "Malcesine",
        "San Zeno di Montagna",
        "Valeggio sul Mincio",
        "Verona-Chievo",
      ],
      Wallis: [
        "Martigny",
        "Saas-Fee",
        "Sion",
        "Vercorin",
        "Brigerbad",
        "Chalais",
        "Champéry",
        "Champex-Lac",
        "Conthey",
        "Crans-Montana",
        "Evionnaz",
        "Les Marécottes",
        "Leukerbad",
        "Monthey",
        "Reckingen VS",
        "Vétroz",
      ],
      Nordholland: ["Amsterdam"],
      Uri: ["Andermatt", "Bristen", "Flüelen", "Göschenen", "Intschi", "Seelisberg", "Urnerboden"],
      Genf: ["Bellevue", "Les Acacias", "Meyrin", "Onex", "Satigny"],
      Neuenburg: ["Buttes", "Chaumont", "La Chaux-de-Fonds", "La Vue-des-Alpes", "Le Locle", "Neuchâtel"],
      Toskana: ["Cecina Mare", "Collodi"],
      Istrien: ["Funtana", "Glavani", "Nova Vas", "Svetvincenat"],
      Kvarner: ["Lopar", "Omišalj"],
      Kampanien: ["Mondragone"],
      "Emilia Romagna": ["Viserba di Rimini"],
    };
  }

  static districtByCitiesOptions() {
    return {
      "Wien-Stadt": [
        "Wien-Stadt Donaustadt",
        "Wien-Stadt Innere Stadt",
        "Wien-Stadt Leopoldstadt",
        "Wien-Stadt Hietzing",
        "Wien-Stadt Favoriten",
        "Wien-Stadt Landstraße",
        "Wien-Stadt Liesing",
        "Wien-Stadt Döbling",
        "Wien-Stadt Neubau",
        "Wien-Stadt Floridsdorf",
        "Wien-Stadt Penzing",
        "Wien-Stadt Wieden",
        "Wien-Stadt Ottakring",
        "Wien-Stadt Simmering",
        "Wien-Stadt Währing",
        "Wien-Stadt Alsergrund",
        "Wien-Stadt Margareten",
        "Wien-Stadt Mariahilf",
        "Wien-Stadt Hernals",
        "Wien-Stadt 1.Bezirk",
        "Wien-Stadt Brigittenau",
        "Wien-Stadt Flughafen",
        "Wien-Stadt Innenstadt",
        "Wien-Stadt Josefsdorf",
        "Wien-Stadt Kahlenberg",
        "Wien-Stadt Leopoldsberg",
        "Wien-Stadt Rudolfsheim-Fünfhaus",
        "Wien-Stadt Seestadt Aspern",
        "Wien-Stadt Wien",
      ],
      Dachau: ["Dachau Altstadt"],
      "Berlin-Stadt": [
        "Berlin-Stadt Mitte",
        "Berlin-Stadt Dahlem",
        "Berlin-Stadt Reinickendorf",
        "Berlin-Stadt Friedrichshain",
        "Berlin-Stadt Köpenick",
        "Berlin-Stadt Kreuzberg",
        "Berlin-Stadt Marzahn",
        "Berlin-Stadt Oberschöneweide",
        "Berlin-Stadt Schöneberg",
        "Berlin-Stadt Tiergarten",
        "Berlin-Stadt Treptow",
        "Berlin-Stadt Charlottenburg",
        "Berlin-Stadt Friedrichsfelde",
        "Berlin-Stadt Spandau",
        "Berlin-Stadt Wedding",
        "Berlin-Stadt Zehlendorf",
        "Berlin-Stadt Buckow",
        "Berlin-Stadt Falkenberg",
        "Berlin-Stadt Gatow / Kladow",
        "Berlin-Stadt Grunewald",
        "Berlin-Stadt Hellersdorf",
        "Berlin-Stadt Kladow / Gatow",
        "Berlin-Stadt Malchow",
        "Berlin-Stadt Marienfelde",
        "Berlin-Stadt Pankow",
        "Berlin-Stadt Prezlauer Berg",
        "Berlin-Stadt Staaken",
        "Berlin-Stadt Tegel",
        "Berlin-Stadt Tempelhof",
        "Berlin-Stadt Wilmersdorf",
      ],
      "Gsieser Tal": ["Gsieser Tal St. Magdalena", "Gsieser Tal Pichl", "Gsieser Tal St. Martin"],
      Serfaus: ["Serfaus serfaus", "Serfaus Fiss", "Serfaus Ladis"],
      Dornbirn: ["Dornbirn Gütle"],
      Mayen: ["Mayen Mayen", "Mayen Alzheim", "Mayen Betzing", "Mayen Hausen", "Mayen Kürrenberg", "Mayen Nitztal"],
      "Waldshut-Tiengen": ["Waldshut-Tiengen Waldshut", "Waldshut-Tiengen Tiengen"],
      Speyer: ["Speyer Zentrum", "Speyer Altstadt", "Speyer Speyer West"],
      "Kirchschlag bei Linz": ["Kirchschlag bei Linz Breitenstein"],
      "Neuhofen an der Ybbs": ["Neuhofen an der Ybbs -", "Neuhofen an der Ybbs Neuhofen"],
      Großarl: ["Großarl Au", "Großarl Unterberg"],
      "Bad Dürrnberg": ["Bad Dürrnberg Bad Dürrnberg", "Bad Dürrnberg Hallein", "Bad Dürrnberg Salzburg"],
      Fiss: ["Fiss Fiss"],
      Calw: ["Calw -Hirsau", "Calw Hirsau", "Calw Stammheim", "Calw Wimberg"],
      Augsburg: ["Augsburg Innenstadt"],
      "Kastelbell-Tschars": ["Kastelbell-Tschars Kastelbell"],
      Ladis: ["Ladis Ladis"],
      Maurach: [
        "Maurach Maurach",
        "Maurach Achenkirch",
        "Maurach Pertisau",
        "Maurach Steinberg am Rofan",
        "Maurach Wiesing",
      ],
      Eppan: ["Eppan Missian", "Eppan Montiggl", "Eppan St. Michael"],
      "Bramberg am Wildkogel": [
        "Bramberg am Wildkogel Ortsmitte",
        "Bramberg am Wildkogel Bahnstation Steinach",
        "Bramberg am Wildkogel Habach",
        "Bramberg am Wildkogel Weichseldorf",
      ],
      "Sand in Taufers": [
        "Sand in Taufers Sand in Taufers",
        "Sand in Taufers Ahornach",
        "Sand in Taufers Kematen",
        "Sand in Taufers Lappach",
        "Sand in Taufers Mühlen in Taufers",
        "Sand in Taufers Mühlwald",
        "Sand in Taufers Rein in Taufers",
      ],
      Sinsheim: ["Sinsheim Sinsheim", "Sinsheim Stadt Sinsheim"],
      Villnöss: ["Villnöss Zans", "Villnöss St. Magdalena", "Villnöss Südtirol Italien"],
      Tübingen: ["Tübingen Altstadt", "Tübingen Bebenhausen"],
      Walchsee: [
        "Walchsee Durchholzen",
        "Walchsee Kössen",
        "Walchsee Rettenschöss",
        "Walchsee Schwendt",
        "Walchsee Walchsee",
      ],
      Oerlenbach: ["Oerlenbach Ebenhausen"],
      Emmendingen: ["Emmendingen Emmendingen"],
      Erfurt: ["Erfurt Erfurt", "Erfurt Herrenberg"],
      Balingen: ["Balingen Frommern", "Balingen Weilstetten"],
      Guarda: ["Guarda Guarda Dorf", "Guarda Unterengadin"],
      Hallstatt: ["Hallstatt Hallstatt", "Hallstatt Salzkammergut", "Hallstatt Echerntal"],
      "Hart im Zillertal": ["Hart im Zillertal Haselbach"],
      Passail: ["Passail Hohenau"],
      Ritten: ["Ritten Klobenstein"],
      Kössen: ["Kössen Kössen"],
      "Krems an der Donau": ["Krems an der Donau Krems - Altstadt", "Krems an der Donau Zentrum"],
      "Saalfelden am Steinernen Meer": [
        "Saalfelden am Steinernen Meer Leogang",
        "Saalfelden am Steinernen Meer Saalfelden",
      ],
      Altaussee: ["Altaussee Lichtersberg", "Altaussee Altaussee"],
      Mariazell: ["Mariazell Mariazell"],
      Meßkirch: ["Meßkirch Meßkirch (Ort)"],
      Mogelsberg: ["Mogelsberg Mogelsberg", "Mogelsberg Ostschweiz", "Mogelsberg St. Gallen"],
      "Neukirchen am Großvenediger": ["Neukirchen am Großvenediger Ortsmitte", "Neukirchen am Großvenediger Sulzau"],
      "Spital am Pyhrn": ["Spital am Pyhrn Ortszentrum", "Spital am Pyhrn Grünau"],
      München: ["München Pasing", "München Maxvorstadt", "München München Haidhausen", "München München-Solln"],
      "Tisens-Prissian": ["Tisens-Prissian Prissian", "Tisens-Prissian Gfrill"],
      "Natz-Schabs": ["Natz-Schabs Raas", "Natz-Schabs Natz", "Natz-Schabs Viums"],
      Schladming: [
        "Schladming Rohrmoos Untertal und Obertal",
        "Schladming Fastenberg",
        "Schladming Preunegg",
        "Schladming Rohrmoos Obertal",
        "Schladming schladming",
        "Schladming Untertal",
      ],
      Grafenhausen: ["Grafenhausen Rothaus"],
      "Samnaun Dorf": ["Samnaun Dorf samnaun", "Samnaun Dorf unterengadin"],
      Scuol: ["Scuol S-charl", "Scuol Scuol"],
      Saarburg: ["Saarburg Staden"],
      "Welsberg-Taisten": ["Welsberg-Taisten Taisten"],
      Welschnofen: ["Welschnofen Welschnofen", "Welschnofen Carezza"],
      Bärnkopf: [
        "Bärnkopf 1 km südlich vomm Ort.",
        "Bärnkopf 500m von der Kirche",
        "Bärnkopf Beim Schlesingerteich",
        "Bärnkopf südlich vom Ort 1 km",
        "Bärnkopf südlicher Ortsrand",
      ],
      Bassum: ["Bassum 52.81920631169559", "Bassum 8.714711941913016"],
      Abtenau: [
        "Abtenau Abtenau",
        "Abtenau Austria",
        "Abtenau Salzburg",
        "Abtenau Salzkammergut",
        "Abtenau St Wolfgang",
        "Abtenau St. Gilgen",
        "Abtenau Strobl",
      ],
      Achenkirch: ["Achenkirch Achenkirch", "Achenkirch Achenseehof Leuchtturm"],
      Frutigen: [
        "Frutigen Adelboden",
        "Frutigen Bern",
        "Frutigen Interlaken",
        "Frutigen Kandersteg",
        "Frutigen Spiez",
        "Frutigen Thun",
      ],
      Stierva: ["Stierva Albulatal", "Stierva Mittelbünden"],
      Algund: [
        "Algund Algund Dorf",
        "Algund Aschbach",
        "Algund Forst",
        "Algund Mitterplars",
        "Algund Mühlbach",
        "Algund Oberplars",
        "Algund Vellau",
      ],
      Altenrhein: ["Altenrhein Altenrhein"],
      "Taiskirchen im Innkreis": ["Taiskirchen im Innkreis Altmannsdorf"],
      Bozen: ["Bozen Altstadt", "Bozen Sill", "Bozen St. Anton", "Bozen Zentrum"],
      Hallein: ["Hallein Altstadt"],
      Kaufbeuren: ["Kaufbeuren Altstadt", "Kaufbeuren Neugablonz"],
      Chur: ["Chur Altstadt"],
      Weinheim: ["Weinheim Altstadt"],
      Ochsenfurt: ["Ochsenfurt Altstadt", "Ochsenfurt Kleinochsenfurt"],
      Erlangen: ["Erlangen Altstadt"],
      Meran: ["Meran Altstadt"],
      "Bern-Stadt": ["Bern-Stadt Altstadt Bern"],
      "Weil am Rhein": ["Weil am Rhein Altweil"],
      Gröbming: ["Gröbming Am Fuße der Stoderzinkenstraße", "Gröbming am Fuße des Stoderzinkens"],
      Ankershagen: ["Ankershagen Ankershagen", "Ankershagen Friedrichsfelde", "Ankershagen Wenddorf"],
      Lamprechtshausen: ["Lamprechtshausen Arnsdorf"],
      Schmalkalden: ["Schmalkalden Asbach"],
      "Reith bei Seefeld": [
        "Reith bei Seefeld Auland",
        "Reith bei Seefeld Reith",
        "Reith bei Seefeld Reith und Leithen",
      ],
      Stuttgart: ["Stuttgart Bad Cannstatt", "Stuttgart Stadtmitte"],
      "Bad Radkersburg": [
        "Bad Radkersburg Bad Radkersburg",
        "Bad Radkersburg Gornja Radgona",
        "Bad Radkersburg Klöch",
        "Bad Radkersburg St. Anna am Aigen",
        "Bad Radkersburg Tieschen",
      ],
      Versam: ["Versam Bahnhof Versam-Safien"],
      Baiersbronn: ["Baiersbronn Baiersbronn-Obertal", "Baiersbronn Buhlbach"],
      Berwang: ["Berwang Bärenbad"],
      Hüfingen: [
        "Hüfingen Behla",
        "Hüfingen Fürstenberg",
        "Hüfingen Hausen vor Wald",
        "Hüfingen Mundelfingen und Sumpfohren",
      ],
      "Bergün/Bravuogn": ["Bergün/Bravuogn Beim Bahnhof Bergün"],
      "Bad Liebenzell": ["Bad Liebenzell Beinberg"],
      Bellach: ["Bellach Bellach", "Bellach Mittelland", "Bellach Solothurn"],
      Rheine: ["Rheine Bentlage"],
      Windischeschenbach: ["Windischeschenbach Bernstein"],
      "Ühlingen-Birkendorf": ["Ühlingen-Birkendorf Birkendorf"],
      Sasbachwalden: ["Sasbachwalden Bischenberg"],
      Maloja: ["Maloja Bitabergh", "Maloja Maloja", "Maloja Orden"],
      "Frankfurt am Main": ["Frankfurt am Main Bockenheim", "Frankfurt am Main Bornheim", "Frankfurt am Main Westend"],
      Bottrop: ["Bottrop Bottrop", "Bottrop Feldhausen", "Bottrop Kirchhellen"],
      "Braunau am Inn": ["Braunau am Inn Braunauer Au"],
      Nonnweiler: ["Nonnweiler Braunshausen"],
      Rankweil: ["Rankweil Brederis"],
      "Schaffhausen-Stadt": [
        "Schaffhausen-Stadt Breite",
        "Schaffhausen-Stadt Halbinsel Höri",
        "Schaffhausen-Stadt Insel Reichenau",
        "Schaffhausen-Stadt Konstanz",
        "Schaffhausen-Stadt Kreuzlingen",
        "Schaffhausen-Stadt Steckborn",
        "Schaffhausen-Stadt Stein am Rhein",
      ],
      "Bremen-Stadt": ["Bremen-Stadt Bremen Neustadt", "Bremen-Stadt Überseestadt Walle"],
      "St. Andrä/Brixen": ["St. Andrä/Brixen Brixen", "St. Andrä/Brixen St. Andrä"],
      "Bruchhausen-Vilsen": ["Bruchhausen-Vilsen Bruchhausen-Vilsen"],
      Nörvenich: ["Nörvenich Bubenheim"],
      Rauris: [
        "Rauris Bucheben",
        "Rauris Hüttwinkltal",
        "Rauris Kolm Saigurn",
        "Rauris Ortsteil Wörth",
        "Rauris Wörth",
      ],
      Hannersdorf: ["Hannersdorf Burg"],
      Mals: ["Mals Burgeis"],
      Wuppertal: ["Wuppertal Cronenberg", "Wuppertal Kohlfurth", "Wuppertal Wuppertal Lüntenbeck"],
      "Furth im Wald": [
        "Furth im Wald Daberg",
        "Furth im Wald in der Nähe vom Drachensee in Furth im Wald",
        "Furth im Wald Sengenbühl",
      ],
      Frankenmarkt: ["Frankenmarkt Danzenreith"],
      Deutschnfoen: ["Deutschnfoen Deutschnofen"],
      Tauberbischofsheim: [
        "Tauberbischofsheim Dienstadt",
        "Tauberbischofsheim Distelhausen",
        "Tauberbischofsheim Dittigheim",
        "Tauberbischofsheim Dittwar",
        "Tauberbischofsheim Hochhausen",
        "Tauberbischofsheim Impfingen",
      ],
      Eppelborn: ["Eppelborn Dirmingen"],
      "Neustift im Stubaital": [
        "Neustift im Stubaital Dorf",
        "Neustift im Stubaital Falbeson",
        "Neustift im Stubaital Grawa",
        "Neustift im Stubaital Kampl",
        "Neustift im Stubaital Stubaier Gletscher",
      ],
      Herisau: ["Herisau Dorfkern"],
      Ernstbrunn: ["Ernstbrunn Dörfles"],
      "Maria Rain": [
        "Maria Rain Dullach",
        "Maria Rain Feistritz/Drau",
        "Maria Rain Ferlach",
        "Maria Rain Greifenburg",
        "Maria Rain Lienz",
        "Maria Rain Oberdrauburg",
        "Maria Rain Rosegg/Mühlbach",
        "Maria Rain Sachsenburg",
        "Maria Rain Seidendorf",
        "Maria Rain Spittal/Drau",
        "Maria Rain Villach",
      ],
      Harpstedt: ["Harpstedt Dünsen", "Harpstedt Groß Ippener", "Harpstedt Harpstedt", "Harpstedt Kirchseelte"],
      Düsseldorf: ["Düsseldorf Düsseldorf-Stockum"],
      "Fuschl am See": [
        "Fuschl am See Ebenau",
        "Fuschl am See Faistenau",
        "Fuschl am See Hintersee",
        "Fuschl am See Hof bei Salzburg",
        "Fuschl am See Koppl",
      ],
      Birkfeld: [
        "Birkfeld einem Ortsteil von Koglhof und nicht in der Stadt Birkfeld !",
        "Birkfeld Ortsteil Koglhof",
        "Birkfeld Wir befinden uns in Rossegg",
      ],
      Engelhartszell: ["Engelhartszell Engelszell"],
      Spitz: ["Spitz Erlahof"],
      Friedrichshafen: ["Friedrichshafen Ettenkirch"],
      Frastanz: ["Frastanz Fellengatter"],
      Tux: [
        "Tux Finkenberger Almbahnen Bergstation",
        "Tux Hintertux - Gefrorene Wand 3.250m",
        "Tux Hintertux - Sommerbergalm",
        "Tux Tux-Lanersbach",
        "Tux Vorderlanersbach",
      ],
      Mauth: ["Mauth Finsterau"],
      Lüsen: ["Lüsen Flitt", "Lüsen Herol", "Lüsen Lüsen Berg", "Lüsen Lüsner Alm"],
      Stilfs: ["Stilfs Fraktion Trafoi"],
      Vendlincourt: ["Vendlincourt Fribourg", "Vendlincourt Jura"],
      Fulda: ["Fulda Fulda-Neuenberg"],
      Fuldera: ["Fuldera Fuldera", "Fuldera Fuldera d'Aint", "Fuldera Val Müstair", "Fuldera Valchava"],
      Grödig: ["Grödig Fürstenbrunn"],
      Ischgl: ["Ischgl Galtür", "Ischgl Ischgl", "Ischgl Kappl", "Ischgl Mittelstation Silvrettabahn A1", "Ischgl See"],
      Galtür: ["Galtür Galtür", "Galtür Gampele", "Galtür TVB Paznaun - Ischgl"],
      Werbach: ["Werbach Gamburg"],
      Gänserndorf: ["Gänserndorf Gänserndorf Süd"],
      Oranienburg: ["Oranienburg Germendorf"],
      "Girlan | Eppan": ["Girlan | Eppan Girlan"],
      Kleinwetzdorf: [
        "Kleinwetzdorf Glaubendorf",
        "Kleinwetzdorf Großweikersdorf",
        "Kleinwetzdorf Großwetzdorf",
        "Kleinwetzdorf Heldenberg",
        "Kleinwetzdorf Hollabrunn",
        "Kleinwetzdorf Stockerauch",
        "Kleinwetzdorf Wien",
        "Kleinwetzdorf Ziersdorf",
      ],
      "Schönberg im Stubaital": ["Schönberg im Stubaital Gleins"],
      Schiedlberg: ["Schiedlberg Goldberg"],
      Gosau: ["Gosau Gosausee"],
      Freudenstadt: ["Freudenstadt Grüntal"],
      "Gemeinde Karneid": ["Gemeinde Karneid Gummer"],
      Gummer: ["Gummer Gummer"],
      Gutau: ["Gutau Gutau"],
      Oberostendorf: ["Oberostendorf Gutenberg"],
      Niederkappel: ["Niederkappel Haar", "Niederkappel Niederkappel", "Niederkappel Obermühl"],
      Ansfelden: ["Ansfelden Haid"],
      Grän: ["Grän Haldensee"],
      Enns: ["Enns Hauptplatz"],
      Schramberg: ["Schramberg Heiligenbronn"],
      Mauthausen: ["Mauthausen Heindlkai"],
      "Bad Urach": ["Bad Urach Hengen", "Bad Urach Seeburg", "Bad Urach Sirchingen", "Bad Urach Wittlingen"],
      Neufelden: ["Neufelden Hintere Zeile"],
      "Ribnitz-Damgarten": ["Ribnitz-Damgarten Hirschburg"],
      Monschau: ["Monschau Höfen"],
      Innsbruck: ["Innsbruck Hofgarten"],
      Hitzhofen: ["Hitzhofen Hofstetten"],
      Selb: ["Selb Hohenberg an der Eger", "Selb Selb", "Selb Selb-Plößberg"],
      Eggelsberg: ["Eggelsberg Ibm"],
      Eggenburg: ["Eggenburg Im Norden von Eggenburg"],
      Braunschweig: ["Braunschweig Innenstadt"],
      Rottweil: ["Rottweil Innenstadt"],
      Reutlingen: ["Reutlingen Innenstadt"],
      Graz: ["Graz Jakomini"],
      Lenzkirch: ["Lenzkirch Kappel"],
      Thyrnau: ["Thyrnau Kellberg"],
      Zellerndorf: [
        "Zellerndorf Kellergasse",
        "Zellerndorf Kirche und Karner",
        "Zellerndorf Ortstsraße",
        "Zellerndorf Rehbirlstott",
      ],
      Klagenfurt: ["Klagenfurt Klagenfurt"],
      Kaltern: ["Kaltern Klughammer", "Kaltern St. Josef am See"],
      Mechernich: ["Mechernich Kommern"],
      Sessa: ["Sessa La Costa di Sessa"],
      "Schönberg-Lachtal": ["Schönberg-Lachtal Lachtal"],
      Palfau: ["Palfau Landl"],
      Gleisdorf: ["Gleisdorf Laßnitzthal"],
      "Puchberg am Schneeberg": ["Puchberg am Schneeberg Losenheim"],
      Waldachtal: ["Waldachtal Lützenhardt"],
      Mining: ["Mining Mamling"],
      "Windhaag bei Freistadt": ["Windhaag bei Freistadt Markt"],
      Schleedorf: ["Schleedorf Mauern"],
      Zehdenick: ["Zehdenick Mildenberg"],
      Philippsreut: ["Philippsreut Mitterfirmiansreut"],
      "Radolfzell am Bodensee": ["Radolfzell am Bodensee Möggingen"],
      Traiskirchen: ["Traiskirchen Möllersdorf"],
      "St. Christina - Gröden": ["St. Christina - Gröden Monte Pana"],
      Ahrntal: ["Ahrntal Mühlegg"],
      Hayingen: ["Hayingen Münzdorf", "Hayingen Wimsen"],
      Adnet: ["Adnet nahe der Kirche"],
      Naturns: ["Naturns Naturnser Sonnenberg"],
      Mannheim: ["Mannheim Neckarstadt-West"],
      Blankenhain: ["Blankenhain Neckeroda"],
      Großbreitenbach: ["Großbreitenbach Neustadt"],
      Forchtenstein: ["Forchtenstein Neustift an der Rosalia"],
      Winterberg: ["Winterberg Niedersfeld"],
      Tiers: ["Tiers Nigerpass"],
      Hanstedt: ["Hanstedt Nindorf"],
      Mattsee: ["Mattsee Nord", "Mattsee Zellhof"],
      Solms: ["Solms Oberbiel"],
      "Oberbozen - Ritten": ["Oberbozen - Ritten Oberbozen"],
      Villanders: ["Villanders Oberland", "Villanders Villanders"],
      Gessertshausen: ["Gessertshausen Oberschönenfeld"],
      Präbichl: ["Präbichl Obersteiermak"],
      "Idar-Oberstein": ["Idar-Oberstein Oberstein"],
      Obertraun: ["Obertraun Obertraun"],
      Perg: ["Perg Obervormarkt", "Perg Stadtzentrum"],
      Winterthur: ["Winterthur Oberwinterthur", "Winterthur Winterthur Töss"],
      "Sankt Martin bei Lofer": ["Sankt Martin bei Lofer Obsthurn"],
      Zernez: ["Zernez Ofenpass", "Zernez Schweizerischer Nationalpark"],
      Schenna: ["Schenna Ortszentrum", "Schenna Schennaberg-Gsteier", "Schenna St. Georgen", "Schenna Verdins"],
      Neißeaue: ["Neißeaue OT Zentendorf"],
      Umhausen: ["Umhausen Parkplatz Bischoffsplatz - direkt an der Niederthaier Straße"],
      Partschins: ["Partschins Partschins", "Partschins Rabland"],
      "Bad Saarow": ["Bad Saarow Petersdorf"],
      Gossensass: ["Gossensass Pflersch"],
      "Welsberg, Taisten oder Gsieser Tal": [
        "Welsberg, Taisten oder Gsieser Tal Pichl",
        "Welsberg, Taisten oder Gsieser Tal St. Magdalena",
        "Welsberg, Taisten oder Gsieser Tal St. Martin",
        "Welsberg, Taisten oder Gsieser Tal Taisten",
        "Welsberg, Taisten oder Gsieser Tal Welsberg",
      ],
      Maßbach: ["Maßbach Poppenlauer"],
      Pöcking: ["Pöcking Possenhofen"],
      "Mals im Vinschgau": ["Mals im Vinschgau Prämajur"],
      Predazzo: ["Predazzo Predazzo"],
      "Prien am Chiemsee": ["Prien am Chiemsee Prien/Stock"],
      Lübeck: ["Lübeck Priwall", "Lübeck Travemünde"],
      "Partschins/Rabland": ["Partschins/Rabland Rabland"],
      Rheinbach: ["Rheinbach Ramershoven"],
      Laßnitzhöhe: ["Laßnitzhöhe Region Graz"],
      Duisburg: ["Duisburg Rheinhausen"],
      Lübz: ["Lübz Riederfelde"],
      Zederhaus: ["Zederhaus Riedingtal"],
      Andeer: ["Andeer Rofflaschlucht"],
      "Zell am Ziller": ["Zell am Ziller Rohr", "Zell am Ziller Rohrberg"],
      "Sankt Peter ob Judenburg": ["Sankt Peter ob Judenburg Rothenthurm"],
      "Bad Kösen": ["Bad Kösen Saaleck"],
      "Samnaun-Compatsch": ["Samnaun-Compatsch samnaun", "Samnaun-Compatsch Unterengadin"],
      Schlieren: ["Schlieren Schlieren"],
      Schlins: ["Schlins Schlins"],
      "Wutha-Farnroda": ["Wutha-Farnroda Schönau"],
      Schleusegrund: ["Schleusegrund Schönbrunn"],
      "Zell im Wiesental": ["Zell im Wiesental Schwarznau"],
      Sent: ["Sent Scuol", "Sent Sent", "Sent Unterengadin"],
      "Sils/Segl Baselgia": ["Sils/Segl Baselgia Sils/Segl Baselgia"],
      "Steinheim am Albuch": ["Steinheim am Albuch Sontheim im Stubental"],
      Saarbrücken: ["Saarbrücken St. Arnual"],
      "Feistritz im Rosental": ["Feistritz im Rosental St. Johann"],
      Nürnberg: ["Nürnberg St. Leonhard", "Nürnberg Sündersbühl"],
      "Neumarkt in Steiermark": ["Neumarkt in Steiermark St. Marein bei Neumarkt"],
      "St. Michael | Eppan": ["St. Michael | Eppan St. Michael"],
      Karlsruhe: ["Karlsruhe Stadt"],
      Wolfsburg: ["Wolfsburg Stadtmitte"],
      Michelstadt: ["Michelstadt Steinbach"],
      Niederstotzingen: ["Niederstotzingen Stetten ob Lontal"],
      "Podersdorf am See": ["Podersdorf am See Strandbad"],
      Sondershausen: ["Sondershausen Straußberg"],
      Höchenschwand: ["Höchenschwand Strittberg"],
      "Maria Neustift": ["Maria Neustift Sulzbach"],
      Mössingen: ["Mössingen Talheim"],
      Tarasp: ["Tarasp Tarasp", "Tarasp Unterengadin"],
      Peißenberg: ["Peißenberg Tiefstollen"],
      Ternberg: ["Ternberg Trattenbach"],
      "Aggsbach Markt": ["Aggsbach Markt Ungefähr 3 Km außerhalb des Ortsgebietes Aggsbach Markt"],
      "Margreid an der Weinstraße": ["Margreid an der Weinstraße Unterfennberg"],
      Abtsgmünd: ["Abtsgmünd Untergröningen"],
      "St. Corona am Wechsel": ["St. Corona am Wechsel Unternberg"],
      "Uhldingen-Mühlhofen": ["Uhldingen-Mühlhofen Unteruhldingen"],
      Oberzeiring: ["Oberzeiring Unterzeiring"],
      Rodeneck: ["Rodeneck Vill"],
      Ruhpolding: ["Ruhpolding Vorderbrand"],
      Eisfeld: ["Eisfeld Waffenrod / Hinterrod"],
      Wolfratshausen: ["Wolfratshausen Waldram"],
      Potsdam: ["Potsdam Waldstadt"],
      "Bad Langensalza": ["Bad Langensalza Wanderparkplatz Rüspelsweg", "Bad Langensalza Wanderparkplatz Zollgarten"],
      Donnerskirchen: ["Donnerskirchen Weide zwischen Donnerskirchen und Purbach"],
      "Welsberg - Taisten": ["Welsberg - Taisten Welsberg"],
      Kelheim: ["Kelheim Weltenburg"],
      "Wolfsgruben - Ritten": ["Wolfsgruben - Ritten Wolfsgruben"],
      "Bad Schallerbach": ["Bad Schallerbach Zentrum"],
      Tramin: ["Tramin Zentrum"],
    };
  }
}
