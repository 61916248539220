import BookingDrawerInfoSection from "../components/BookingDrawerInfoSection";
import NewsletterIcon from "../../../assets/icons/Newsletter";
import intl from "react-intl-universal";

export default function HeroDrawerInfoSection() {
  return (
    <BookingDrawerInfoSection
      icon={<NewsletterIcon sx={{ width: "33px", height: "27px" }} />}
      title={intl.get("booking_drawer_info.hero_slots.title")}
      text1={intl.get("booking_drawer_info.hero_slots.text1")}
      features={[
        intl.getHTML("booking_drawer_info.hero_slots.feature1"),
        intl.getHTML("booking_drawer_info.hero_slots.feature2"),
        intl.getHTML("booking_drawer_info.hero_slots.feature3"),
        intl.getHTML("booking_drawer_info.hero_slots.feature4"),
      ]}
    />
  );
}
