import { Box, Grid, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { PageState, PageStateType } from "../model/pageState";
import * as PluginApiKeyActions from "../actions/plugin.api.key.actions";
import * as React from "react";
import { PluginApiKeyState } from "../model/api_keys/plugin.api.key.state";
import { RootState } from "../reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { PluginApiKey } from "../model/api_keys/plugin.api.key";
import { HeaderLabel } from "../components/header/HeaderLabel";
import intl from "react-intl-universal";
import { LinkButton } from "../components/Buttons";
import { Pagination } from "../components/TablePagination";
import { PageTracker } from "../util/pageTracker";
import AppStyles from "../styles/appStyles";

export interface Props {
  pluginApiKeyState: PluginApiKeyState;
  pageState: PageState;
  pluginApiKeyActions: typeof PluginApiKeyActions;
}

export interface State {}

class PluginApiKeysPage extends React.Component<Props> {
  handleChangePage = (event: any, page: any) => {
    this.props.pluginApiKeyState.paging.current_page = page + 1;
    this.props.pluginApiKeyActions.fetchAllPluginApiKeys(
      this.props.pluginApiKeyState.paging.current_page,
      this.props.pluginApiKeyState.paging.page_size,
    );
  };

  handleChangeRowsPerPage = (event: any) => {
    this.props.pluginApiKeyState.paging.page_size = event.target.value;
    this.props.pluginApiKeyActions.fetchAllPluginApiKeys(
      this.props.pluginApiKeyState.paging.current_page,
      this.props.pluginApiKeyState.paging.page_size,
    );
  };

  componentDidMount(): void {
    this.props.pluginApiKeyActions.fetchAllPluginApiKeys(
      this.props.pluginApiKeyState.paging.current_page,
      this.props.pluginApiKeyState.paging.page_size,
    );
  }

  lockPlugin(key: PluginApiKey) {
    this.props.pluginApiKeyActions.lockPlugin(key.api_key_id);
  }

  unlockPlugin(key: PluginApiKey) {
    this.props.pluginApiKeyActions.unlockPlugin(key.api_key_id);
  }

  approvePlugin(key: PluginApiKey) {
    this.props.pluginApiKeyActions.approvePlugin(key.api_key_id);
  }

  renderPluginApiKey(key: PluginApiKey) {
    const cellClassName =
      key.status === "LOCKED"
        ? AppStyles.tableCellRed
        : key.is_reviewed === false
          ? AppStyles.tableCellWarning
          : AppStyles.tableCell;

    return (
      <TableRow key={key.api_key_id} hover>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {key.name}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          <LinkButton
            onClick={(event: any) => {
              event.stopPropagation();
              window.open(key.url, "_blank");
            }}
            title={key.url}
          />
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {key.email}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {key.status === "ACTIVE"
            ? intl.get("plugin_api_keys.status_active")
            : intl.get("plugin_api_keys.status_locked")}{" "}
          {key.is_reviewed === false ? "(Noch nicht überprüft)" : ""}
        </TableCell>
        <TableCell padding="none" sx={AppStyles.tableCell} align={"center"}>
          {key.status === "ACTIVE" && key.is_reviewed === false && (
            <LinkButton
              onClick={(event: any) => {
                event.stopPropagation();
                this.approvePlugin(key);
              }}
              title={intl.get("plugin_api_keys.action_approve")}
            />
          )}
          {key.status === "ACTIVE" && (
            <LinkButton
              onClick={(event: any) => {
                event.stopPropagation();
                this.lockPlugin(key);
              }}
              title={intl.get("plugin_api_keys.action_lock")}
            />
          )}
          {key.status === "LOCKED" && (
            <LinkButton
              onClick={(event: any) => {
                event.stopPropagation();
                this.unlockPlugin(key);
              }}
              title={intl.get("plugin_api_keys.action_unlock")}
            />
          )}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    if (this.props.pageState.state === PageStateType.Loading) {
      return <></>;
    }

    const mapped = this.props.pluginApiKeyState.allKeys.map((value) => this.renderPluginApiKey(value));

    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.headerLeft}>
              <HeaderLabel>{intl.get("plugin_api_keys.title")}</HeaderLabel>
            </Grid>
            <Grid item sx={AppStyles.headerRight}></Grid>
          </Grid>
          <Paper sx={AppStyles.innerContentPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                    {intl.get("plugin_api_keys.list_header_name")}
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                    {intl.get("plugin_api_keys.list_header_url")}
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                    {intl.get("plugin_api_keys.list_header_email")}
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                    {intl.get("plugin_api_keys.list_header_status")}
                  </TableCell>
                  <TableCell padding="none" align="right" sx={AppStyles.tableHeaderCell} />
                </TableRow>
              </TableHead>
              <TableBody>{mapped}</TableBody>
              <TableFooter>
                <TableRow>
                  <Pagination
                    paging={this.props.pluginApiKeyState.paging}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    pluginApiKeyState: state.pluginApiKeyState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    pluginApiKeyActions: bindActionCreators(PluginApiKeyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PluginApiKeysPage);
