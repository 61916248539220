import { IconButton, TableCell } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { BCEventDedicatedTime } from "../../../../model/event";
import { DatePicker, DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import moment from "moment";
import "moment/locale/de";
import * as EventFormActions from "../../../../actions/eventForm";

const classes = {
  cell: {
    padding: 0,
  },
};

export interface Props {
  dedicatedTime: BCEventDedicatedTime;
  onRemove: any;
  disabled: boolean;
  eventFormActions: typeof EventFormActions;
}

function EventDedicatedTimeEdit(props: Props) {
  const [state, setState] = useState({
    startDate: props.dedicatedTime.start_date ? props.dedicatedTime.start_date : null,
    endDate: props.dedicatedTime.end_date ? props.dedicatedTime.end_date : null,
  });

  const onChangeDate = (date: Date) => {
    const formattedDate = moment(date).locale("de").format("YYYY-MM-DD");
    const formattedStartTime = props.dedicatedTime.start_date
      ? moment(props.dedicatedTime.start_date).format("HH:mm")
      : "00:00";
    const formattedEndTime = props.dedicatedTime.end_date
      ? moment(props.dedicatedTime.end_date).format("HH:mm")
      : "00:00";

    props.dedicatedTime.start_date = moment(`${formattedDate}T${formattedStartTime}`).toDate();
    props.dedicatedTime.end_date = moment(`${formattedDate}T${formattedEndTime}`).toDate();

    setState({
      startDate: props.dedicatedTime.start_date,
      endDate: props.dedicatedTime.end_date,
    });
  };

  function getCurrentValue(): DateRange {
    if (props.dedicatedTime.start_date && props.dedicatedTime.end_date) {
      return [moment(props.dedicatedTime.start_date).toDate(), moment(props.dedicatedTime.end_date).toDate()];
    }
    return null;
  }

  function onChangeTime(range: DateRange) {
    const timeStart = moment(range[0]).locale("de").format("HH:mm");
    let timeEnd = moment(range[1]).locale("de").format("HH:mm");
    if (moment(range[0]).isAfter(moment(range[1]))) {
      timeEnd = timeStart;
    }
    const formattedStartDate = moment(props.dedicatedTime.start_date ? props.dedicatedTime.start_date : new Date())
      .locale("de")
      .format("YYYY-MM-DD");

    const formattedEndDate = moment(props.dedicatedTime.end_date ? props.dedicatedTime.end_date : new Date())
      .locale("de")
      .format("YYYY-MM-DD");
    props.dedicatedTime.start_date = moment(`${formattedStartDate}T${timeStart}`).toDate();
    props.dedicatedTime.end_date = moment(`${formattedEndDate}T${timeEnd}`).toDate();
    setState({
      startDate: props.dedicatedTime.start_date ? props.dedicatedTime.start_date : state.startDate,
      endDate: props.dedicatedTime.end_date ? props.dedicatedTime.end_date : state.startDate,
    });

    props.eventFormActions.validateEventForm();
  }

  const startDate = state.startDate !== null ? moment(state.startDate).toDate() : null;
  return (
    <>
      <TableCell sx={classes.cell} padding="none" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <DatePicker
          isoWeek={true}
          ranges={[]}
          cleanable={false}
          format={"dd.MM.yyyy"}
          oneTap={true}
          onSelect={(date) => {
            onChangeDate(date);
          }}
          value={startDate}
        />
      </TableCell>
      <TableCell sx={classes.cell} padding="none" align="left">
        <DateRangePicker
          isoWeek={true}
          cleanable={false}
          format="HH:mm"
          ranges={[]}
          onOk={(range) => {
            onChangeTime(range);
          }}
          onChange={(range) => {
            if (range) {
              onChangeTime(range);
            }
          }}
          value={getCurrentValue()}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() => {
            props.onRemove();
          }}
          color="secondary"
          size="large"
        >
          <ClearIcon />
        </IconButton>
      </TableCell>
    </>
  );
}

export default EventDedicatedTimeEdit;
