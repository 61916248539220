import { useState } from "react";
import useLogger from "../../hooks/useLogger";

export interface DialogHandler<PayloadType> {
  isOpen: boolean;
  close: () => void;
  handleDialogClose: (event: any, reason: any) => void;
  setOpen: (open: boolean) => void;
  open: (payload?: PayloadType) => void;
  payload?: PayloadType;
  setPayload: (payload: PayloadType) => void;
  canClose: boolean;
  setCanClose: (canClose: boolean) => void;
}

export default function useDialogHandler<PayloadType>(
  logTag = `dialogHandler`,
  closeOnClickOutside = false,
  defaultOpen = false,
): DialogHandler<PayloadType> {
  const [open, setOpen] = useState(defaultOpen);
  const [canClose, setCanClose] = useState(true);
  const logger = useLogger(logTag);
  const [payload, setPayload] = useState<PayloadType | undefined>();

  return {
    isOpen: open,
    close: () => {
      if (canClose) {
        setOpen(false);
      }
    },
    handleDialogClose: (event: any, reason: any) => {
      if (!closeOnClickOutside && reason && reason === `backdropClick`) {
        return;
      }
      setOpen(false);
    },
    setOpen,
    open: (openPayload?: PayloadType) => {
      logger.log(`open dialog w payload`, openPayload);
      setPayload(openPayload);
      setOpen(true);
    },
    payload,
    setPayload,
    canClose,
    setCanClose,
  };
}
