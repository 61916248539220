import { Alert, Snackbar } from "@mui/material";
import * as React from "react";
import useUser from "../../hooks/useUser";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as AuthActions from "../../actions/auth";

export default function UserConfirmationBar() {
  const dispatch = useDispatch();

  const user = useUser();

  const [showConfirmationResendSuccess, setShowConfirmationResendSuccess] = useState(false);

  const resendMyConfirmationMail = () => {
    dispatch<any>(AuthActions.resendMyConfirmationMail());
    setShowConfirmationResendSuccess(true);
  };

  return (
    <>
      {user && user.status === "UNCONFIRMED" && (
        <div
          style={{
            background: "#FF9800",
            color: "#FFFFFF",
            textAlign: "center",
            padding: 16,
            fontWeight: "bold",
          }}
        >
          Ihr Konto ist noch nicht bestätigt. Bitte prüfen Sie Ihren Posteingang.&nbsp;
          <a
            style={{ color: "#FFFFFF", textDecoration: "underline", cursor: "pointer" }}
            onClick={resendMyConfirmationMail}
          >
            Erneut senden
          </a>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showConfirmationResendSuccess}
        autoHideDuration={3000}
        onClose={() => {
          setShowConfirmationResendSuccess(false);
        }}
      >
        <Alert severity="success">Bestätigungsmail wurde erneut gesendet.</Alert>
      </Snackbar>
    </>
  );
}
