import ReactSelect from "react-select";
import * as React from "react";

/**
 * This is a wrapper for react-select, since we have type clashes
 * @param props
 * @constructor
 */
export default function MyReactSelect(props: any) {
  // @ts-ignore
  return <ReactSelect {...props} />;
}
