import AppStyles from "../../styles/appStyles";
import { Grid, Paper, Typography } from "@mui/material";
import ProductInformationCard from "./ProductInfoCard";
import intl from "react-intl-universal";
import TopPlacementIcon from "../../assets/icons/TopPlacement";
import HeroIcon from "../../assets/icons/Hero";
import SocialMediaIcon from "../../assets/icons/SocialMedia";
import NewsletterIcon from "../../assets/icons/Newsletter";
import useHeightGroup from "../../hooks/useHeightGroup";

export default function ProductUpgradesInformationBanner({
  onTopPlacementInfo,
  onHeroInfo,
  onSocialMediaPackageInfo,
  onNewsletterInfo,
}: {
  onTopPlacementInfo?: () => void;
  onHeroInfo?: () => void;
  onSocialMediaPackageInfo?: () => void;
  onNewsletterInfo?: () => void;
}) {
  const heightGroup = useHeightGroup();

  return (
    <Paper
      sx={{
        ...AppStyles.centerPaper,
        ...AppStyles.innerContentPaper,
        boxShadow: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "34px",
        paddingBottom: "34px",
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container justifyContent={"stretch"} gap={4}>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontSize: theme.fontSizes.biggerFont,
              fontWeight: 700,
            })}
          >
            {intl.get("product_information.benefits_title")}
          </Typography>
        </Grid>
        <Grid item xs={12} md>
          <ProductInformationCard
            title={intl.get("product_information.top_placement.title")}
            text={intl.get("product_information.top_placement.text")}
            icon={<TopPlacementIcon sx={{ width: "100%", height: "26px" }} />}
            onButtonClick={onTopPlacementInfo}
            titleHeightGroup={heightGroup}
          />
        </Grid>
        <Grid item xs={12} md>
          <ProductInformationCard
            title={intl.get("product_information.hero.title")}
            text={intl.get("product_information.hero.text")}
            icon={<HeroIcon sx={{ width: "100%", height: "35px" }} />}
            onButtonClick={onHeroInfo}
            titleHeightGroup={heightGroup}
          />
        </Grid>
        <Grid item xs={12} md>
          <ProductInformationCard
            title={intl.get("product_information.social_media_package.title")}
            text={intl.get("product_information.social_media_package.text")}
            icon={<SocialMediaIcon sx={{ width: "100%", height: "33px" }} />}
            onButtonClick={onSocialMediaPackageInfo}
            titleHeightGroup={heightGroup}
          />
        </Grid>
        <Grid item xs={12} md>
          <ProductInformationCard
            title={intl.get("product_information.newsletter.title")}
            text={intl.get("product_information.newsletter.text")}
            icon={<NewsletterIcon sx={{ width: "100%", height: "27px" }} />}
            onButtonClick={onNewsletterInfo}
            titleHeightGroup={heightGroup}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
