import { Box, Theme } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { bindActionCreators } from "redux";
import { RootState } from "../reducers";
import { PageState, PageStateType } from "../model/pageState";
import { Progress } from "./Progress";
import { ErrorView } from "./ErrorView";
import * as PageStateActions from "../actions/pageState";

const classes = {
  loading: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f2f2f2",
    zIndex: 1000,
  },
  innerContent: (theme: Theme) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 1000,
    overflow: "scroll",
    flexGrow: 1,
    paddingTop: "65px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "56px",
    },
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f2f2f2",
  }),
  innerContentHide: {
    animation: "0.25s linear 0s forwards animateHide",
  },
  innerContentError: (theme: Theme) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "#f2f2f2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.colors.darkGrey,
  }),
  fullscreen: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2000,
    background: "#000000",
    animation: "0.15s linear 0s forwards fadeIn",
    opacity: 0.7,
  },
  error: {
    width: "100%",
    height: "64px",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10000,
  },
  errorShow: {
    animation: "0.25s linear 0s forwards animateShow",
  },
  errorHide: {
    animation: "0.25s linear 0s forwards animateHide",
  },
};

export interface BaseProps {}

export interface Props extends BaseProps {
  pageState: PageState;
  pageStateActions: typeof PageStateActions;
}

class PageStateView extends React.Component<Props> {
  state = {
    animateHideError: false,
  };

  animateHideError() {
    this.setState({ animateHideError: true });

    setTimeout(() => {
      this.setState({ animateHideError: false });
      this.props.pageStateActions.resetPageState();
    }, 250);
  }

  render() {
    const state = this.props.pageState != null ? this.props.pageState.state : PageStateType.Unknown;
    const error = this.props.pageState != null ? this.props.pageState.error : null;

    if (state === PageStateType.Unknown) {
      return <div />;
    }

    if (state === PageStateType.Loading) {
      return (
        <Box sx={classes.loading}>
          <Progress />
        </Box>
      );
    }

    if (state === PageStateType.FailedLoading) {
      return (
        <Box sx={classes.innerContentError}>
          <ErrorIcon />
          <div>{intl.get("loading_failed")}</div>
        </Box>
      );
    }

    if (state === PageStateType.Loaded) {
      setTimeout(() => {
        this.props.pageStateActions.resetPageState();
      }, 250);
      return (
        <Box sx={classes.loading}>
          <Progress />
        </Box>
      );
    }

    if (state === PageStateType.FailedSaving) {
      return (
        <>
          <Box
            sx={
              this.state.animateHideError
                ? { ...classes.errorHide, ...classes.error }
                : { ...classes.errorShow, ...classes.error }
            }
          >
            <ErrorView
              error={error}
              defaultErrorMessage={intl.get("saving_failed")}
              showClearIcon={true}
              onClearButton={(event: any) => {
                event.stopPropagation();
                this.animateHideError();
              }}
            />
          </Box>
        </>
      );
    }

    if (state === PageStateType.Saving) {
      return (
        <>
          <Box sx={classes.fullscreen}>
            <Progress />
          </Box>
        </>
      );
    }
    return <></>;
  }
}

function mapStateToProps(state: RootState) {
  return {
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    pageStateActions: bindActionCreators(PageStateActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageStateView);
