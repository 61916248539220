import { Stack, SxProps, Typography } from "@mui/material";
import intl from "react-intl-universal";
import { PaymentProduct } from "../../../model/payment/payment.product";
import {
  formattedPaymentProductBillingInfo,
  formattedPlanBillingInterval,
  formattedPlanReducedPlanBillingInterval,
  formattedReducedPricingInfo,
  formattedTrialPhase,
} from "../../../model/payment_info";
import * as React from "react";

interface Props {
  product: PaymentProduct;
  sx?: SxProps;
  productCount?: number;
}

export default function BookingDrawerSumRow({ sx, ...props }: Props) {
  const { product, productCount } = props;

  const price =
    product.number_of_reduced_price_periods > 0
      ? formattedReducedPricingInfo(product, productCount)
      : formattedPaymentProductBillingInfo(product, undefined, undefined, productCount);

  return (
    <Stack alignItems={"center"} sx={sx}>
      <Stack direction={"row"} alignItems={"center"} style={{ width: "100%" }}>
        <Typography sx={{ font: "normal normal bold 18px/21px Manrope" }}>
          {intl.get("booking_drawer.general.label_sum")}
        </Typography>
        <Typography color={"primary"} sx={{ font: "normal normal bold 30px/41px Manrope", marginLeft: "auto" }}>
          {price}
        </Typography>
      </Stack>
      <AdditionalPricingInfo product={product} productCount={productCount} />
    </Stack>
  );
}

function AdditionalPricingInfo({ product, productCount }: { product: PaymentProduct; productCount: number }) {
  return (
    <>
      {product.number_of_reduced_price_periods === 0 && (
        <Typography variant="body2" color={"primary"} sx={{ marginLeft: "auto" }}>
          {formattedPlanBillingInterval(product.billing_interval_unit, product.billing_interval_value)}
        </Typography>
      )}
      {product.number_of_reduced_price_periods > 0 && (
        <Typography variant="body2" color={"primary"} sx={{ marginLeft: "auto" }}>
          {formattedPlanReducedPlanBillingInterval(
            product.billing_interval_unit,
            product.number_of_reduced_price_periods,
          )}
        </Typography>
      )}
      {product.number_of_reduced_price_periods > 0 && (
        <Typography variant="body2" color={"primary"} sx={{ marginLeft: "auto" }}>
          danach {formattedPaymentProductBillingInfo(product, undefined, undefined, productCount)}{" "}
          {formattedPlanBillingInterval(product.billing_interval_unit, product.billing_interval_value)}
        </Typography>
      )}
      {product.trial_unit && product.trial_interval > 0 && (
        <Typography variant="body2" color={"primary"} sx={{ marginLeft: "auto" }}>
          {formattedTrialPhase(product.trial_unit, product.trial_interval)}
        </Typography>
      )}
    </>
  );
}
