import { ResetPasswordState } from "../model/user";
import { Action, ActionType } from "../model/actions";
import createReducer from "./createReducer";

export const resetPasswordState = createReducer(new ResetPasswordState(), {
  [ActionType.RESET_PASSWORD_SUCCESS](state: ResetPasswordState) {
    return { ...state, performing: false, triggered: true };
  },
  [ActionType.RESET_PASSWORD_PROGRESS](state: ResetPasswordState) {
    return { ...state, performing: true, triggered: false };
  },
  [ActionType.RESET_PASSWORD_ERROR](state: ResetPasswordState, action: Action<any>) {
    const error = action.payload;
    if (error.error) {
      return { ...state, loading: false, triggered: false, error: error.error };
    }
    return { ...state, loading: false, triggered: false, error: null };
  },
});
