import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { PluginApiKeyState } from "../model/api_keys/plugin.api.key.state";
import { PluginApiKey } from "../model/api_keys/plugin.api.key";

export const pluginApiKeyState = createReducer(
  {
    allKeys: [],
    paging: {
      page_size: 50,
      current_page: 1,
    },
  },
  {
    [ActionType.FETCH_PLUGIN_API_KEYS](state: PluginApiKeyState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          allKeys: action.payload.plugin_api_keys,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
    [ActionType.UPDATE_PLUGIN_API_KEY](state: PluginApiKeyState, action: Action<any>) {
      const newState = { ...state };
      const pluginApiKey: PluginApiKey = action.payload.data;
      const index = newState.allKeys.findIndex((value) => value.api_key_id === pluginApiKey.api_key_id);
      if (index !== -1) {
        newState.allKeys[index] = pluginApiKey;
      }
      return newState;
    },
  },
);
