import { Grid } from "@mui/material";
import { SectionLabel } from "../../../../../components/SectionLabel";
import intl from "react-intl-universal";
import { Constants } from "../event_options";
import * as React from "react";
import { CompanyFormState } from "../../../../../model/companyFormState";
import * as CompanyActions from "../../../../../actions/company";
import MySelect from "../../../../../components/select/MySelect";

export function CompanyEditSingleSelectField(props: {
  formState: CompanyFormState;
  formActions: typeof CompanyActions;
  field: string;
  options: any[];
  required?: boolean;
  onChange?: any;
  disabled?: boolean;
}) {
  return (
    <Grid item container direction="column">
      <SectionLabel required={props.required ?? false}>{intl.get(`company_form.label.${props.field}`)}</SectionLabel>
      <MySelect
        options={props.options}
        isDisabled={props.disabled}
        isClearable
        isSearchable
        placeholder={intl.get(`company_form.label.${props.field}`)}
        value={Constants.toSingleSelectOption(
          Constants.fetchedValueFromCompanyPropPath(props.formState, props.field),
          `event_constants.country_code`,
        )}
        onChange={(value) => {
          props.formActions.updateCompanyFormField({
            prop: `company.${props.field}`,
            value: Constants.fromSingleSelectOption(value),
          });
          if (props.onChange) {
            props.onChange(value);
          }
        }}
      />
    </Grid>
  );
}
