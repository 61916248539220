import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const CheckmarkIcon = (props) => (
  <SvgIcon width="10.867" height="8.127" viewBox="0 0 10.867 8.127" {...props}>
    <path
      id="Pfad_161"
      data-name="Pfad 161"
      d="M10.553.342A1.113,1.113,0,0,0,9.766,0a.994.994,0,0,0-.8.321L3.855,5.432,1.9,3.477A1.059,1.059,0,0,0,1.1,3.158,1.117,1.117,0,0,0,.333,5.071L3.066,7.8a1.115,1.115,0,0,0,.789.326l-.04-.508c.014,0,.026.008.04.008h0v.5a1.112,1.112,0,0,0,.79-.327l5.9-5.9a1.124,1.124,0,0,0,0-1.56"
    />
  </SvgIcon>
);

export default CheckmarkIcon;
