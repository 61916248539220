import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import axios from "axios";
import { mapErrorFromException } from "../model/error";

export const fetchAllPluginApiKeys = (page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/plugin_keys?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_PLUGIN_API_KEYS,
      payload: {
        data: true,
        plugin_api_keys: res.data.plugin_api_keys,
        paging: res.data.paging,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const lockPlugin = (api_key_id: string) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.put(`/v2/api/b2b/private/plugin_keys/${api_key_id}/lock`);

    dispatch({
      type: ActionType.UPDATE_PLUGIN_API_KEY,
      payload: {
        data: res.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const unlockPlugin = (api_key_id: string) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.put(`/v2/api/b2b/private/plugin_keys/${api_key_id}/unlock`);

    dispatch({
      type: ActionType.UPDATE_PLUGIN_API_KEY,
      payload: {
        data: res.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const approvePlugin = (api_key_id: string) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.put(`/v2/api/b2b/private/plugin_keys/${api_key_id}/approve`);

    dispatch({
      type: ActionType.UPDATE_PLUGIN_API_KEY,
      payload: {
        data: res.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};
