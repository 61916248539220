import { MenuItem } from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { UserFormState } from "../model/userFormState";
import { RootState } from "../reducers";
import * as AdminUserActions from "../actions/adminUser";
import AddUserForm from "./components/forms/AddUserForm";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";

export interface Props {
  adminUserFormState: UserFormState;
  actions: typeof AdminUserActions;
}

export interface State {}

function AddAdminUserPage(props: Props) {
  const navigate = useNavigate();
  const createUser = () => {
    props.actions.saveNewAdminUser(props.adminUserFormState.user.email, props.adminUserFormState.user.role);
  };

  const closePage = () => {
    navigate(-1);
  };

  return (
    <>
      <PageTracker />
      <AddUserForm
        createUser={() => {
          createUser();
        }}
        closePage={() => {
          closePage();
        }}
      >
        <MenuItem value="ADMIN">{intl.get("user_role.ADMIN")}</MenuItem>
        <MenuItem value="REVIEWER">{intl.get("user_role.REVIEWER")}</MenuItem>
        <MenuItem value="GROUP_MANAGER">{intl.get("user_role.GROUP_MANAGER")}</MenuItem>
        <MenuItem value="RESELLER">{intl.get("user_role.RESELLER")}</MenuItem>
        <MenuItem value="INTERNAL_SALES_MANAGER">{intl.get("user_role.INTERNAL_SALES_MANAGER")}</MenuItem>
      </AddUserForm>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    adminUserFormState: state.userFormState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AdminUserActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddAdminUserPage);
