import * as intl from "react-intl-universal";
import { BCEvent, BCEventCategory, BCEventTag } from "../../../../model/event";
import { EventFormState } from "../../../../model/eventFormState";
import { CompanyFormState } from "../../../../model/companyFormState";
import { ResellerProfileFormState } from "../../../../model/resellerProfileFormState";
import { SelectionOptions } from "../../../../util/selection_options";

export class Constants {
  static destinationTypeOptions() {
    return [
      "aussichtspunkt",
      "bad",
      "bauernhof",
      "escape_room",
      "familienevent",
      "freizeitpark",
      "indoorspielplatz",
      "kinderfest",
      "kletterpark",
      "museum",
      "naturerlebnis",
      "restaurant",
      "schaubetrieb",
      "sehenswerter_ort",
      "skigebiet",
      "spielplatz",
      "streichelzoo",
      "tierpark",
      "wahrzeichen",
      "wandergebiet",
      "weg",
      "zoo",
      "bahn",
      "kulturelle_einrichtung",
      "sehenswuerdigkeit",
      "shifffahrt",
      "sommerrodelbahn",
      "sportanlage",
      "sternwarte",
      "veranstaltung",
      "wanderung",
    ].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_type.${value}`),
      };
    });
  }

  static eventTags(from: BCEventTag[]) {
    if (from) {
      return from
        .map((eventTag) => {
          return {
            value: eventTag.tag_id,
            label: eventTag.name,
            tag: eventTag,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
  }

  static toSelectedTags(event: BCEvent) {
    if (event && event.tags) {
      return event.tags.map((eventTag) => {
        return {
          value: eventTag.tag_id,
          label: eventTag.name,
          tag: eventTag,
        };
      });
    }
    return [];
  }

  static eventCategories(from: BCEventCategory[]) {
    if (from) {
      return from.map((eventCategory) => {
        return {
          value: eventCategory.category_id,
          label: `${intl.get(`event_constants.category.${eventCategory.identifier}`)}${
            eventCategory.is_exclusive ? intl.get("event_constants.category.exclusivity_suffix") : ""
          }`,
          category: eventCategory,
        };
      });
    }
    return [];
  }

  static toSelectedCategories(event: BCEvent) {
    if (event && event.categories) {
      return event.categories.map((eventCategory) => {
        return {
          value: eventCategory.category_id,
          label: intl.get(`event_constants.category.${eventCategory.identifier}`),
          category: eventCategory,
        };
      });
    }
    return [];
  }

  static destinationSubtypeSwimOptions() {
    return ["badesee", "familienbad", "freibad", "hallenbad", "naturbad", "schwimmbad", "strandbad", "therme"].map(
      (value) => {
        return {
          value: value,
          label: intl.get(`event_constants.destination_subtype_bad.${value}`),
        };
      },
    );
  }

  static destinationSubtypeAmusementParkOptions() {
    return ["erlebnispark", "vergnuegungspark", "maerchenpark", "wasserpark", "kletterpark"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_subtype_freizeitpark.${value}`),
      };
    });
  }

  static destinationSubtypeRestaurantOptions() {
    return ["kinderfreundliches_restaurant", "familien_alm", "kindercafe"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_subtype_restaurant.${value}`),
      };
    });
  }

  static destinationSubtypeCulturalFacilityOptions() {
    return ["galerie", "kino", "konzert", "oper", "puppentheater", "theater"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_subtype_kulturelle_einrichtung.${value}`),
      };
    });
  }

  static destinationSubtypeDesinationWorthSeeingOptions() {
    return ["bergwerk", "burg", "festung", "garten", "kirche", "ruine", "schloss", "turm", "wahrzeichen"].map(
      (value) => {
        return {
          value: value,
          label: intl.get(`event_constants.destination_subtype_sehenswerter_ort.${value}`),
        };
      },
    );
  }

  static destinationSubtypeSportsFacilityOptions() {
    return [
      "bogenparcour",
      "eislaufplatz",
      "fussballplatz",
      "golfplatz",
      "kletterhalle",
      "minigolfplatz",
      "pumptrack",
      "rodelbahn",
      "skaterpark",
      "tennisplatz",
    ].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_subtype_sportanlage.${value}`),
      };
    });
  }

  static destinationSubtypePathOptions() {
    return ["erlebnisweg", "naturweg", "lernweg", "moorweg"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_subtype_weg.${value}`),
      };
    });
  }

  static weatherConditionOptions() {
    return ["schoenwetter", "regenwetter", "wind", "schnee", "kaelte", "hitze", "wechselhaft", "bewoelkt"].map(
      (value) => {
        return {
          value: value,
          label: intl.get(`event_constants.weather_conditions.${value}`),
        };
      },
    );
  }

  static strollerConditionOptions() {
    return ["nicht_geeignet", "wenig_geeignet", "halb_geeignet", "grossteils_geeignet", "vollstaendig_geeignet"].map(
      (value) => {
        return {
          value: value,
          label: intl.get(`event_constants.stroller_condition.${value}`),
        };
      },
    );
  }

  static dogsAllowedOptions() {
    return ["nicht_erlaubt", "willkommen", "erlaubt", "teilweise_erlaubt", "nur_im_aussenbereich"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.is_dogs_allowed.${value}`),
      };
    });
  }

  static mainThemeOptions() {
    return [
      "abenteuer",
      "action",
      "bewegung",
      "dinosaurier",
      "eislaufen",
      "entdecken",
      "experiment",
      "geschichte",
      "kinderschminken",
      "klettern",
      "kultur",
      "kunst",
      "lernen",
      "maerchen",
      "magie",
      "musik",
      "pferde",
      "schwimmen",
      "show",
      "skifahren",
      "spielen",
      "sport",
      "tiere",
      "wandern",
      "wasser",
    ].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.main_theme.${value}`),
      };
    });
  }

  static activityDurationOptions() {
    return ["unter_einer_h", "ein_bis_zwei_h", "zwei_bis_drei_h", "halbtags", "ganztags", "mehrtaegig"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.activity_duration.${value}`),
      };
    });
  }

  static reachabilityOptions() {
    return ["auto", "bahn", "bus", "fahrrad", "schiff", "seilbahn"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.reachability_options.${value}`),
      };
    });
  }

  static addressCountryCodeOptions() {
    return [
      "AL",
      "AD",
      "BY",
      "BE",
      "BA",
      "BG",
      "DK",
      "DE",
      "EE",
      "FI",
      "FR",
      "GR",
      "IE",
      "IS",
      "IT",
      "KZ",
      "XK",
      "HR",
      "LV",
      "LI",
      "LT",
      "LU",
      "MT",
      "MD",
      "MC",
      "ME",
      "NL",
      "MK",
      "NO",
      "AT",
      "PL",
      "PT",
      "RO",
      "RU",
      "SM",
      "SE",
      "CH",
      "RS",
      "SK",
      "SI",
      "ES",
      "CZ",
      "TR",
      "UA",
      "HU",
      "VA",
      "GB",
    ].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.country_code.${value}`),
      };
    });
  }

  static surroundingsTypeOptions() {
    return ["stadt", "land", "see", "fluss", "berg", "wald"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.surroundings_type.${value}`),
      };
    });
  }

  static shadeTypeOptions() {
    return ["nicht_schattig", "wenig_schattig", "halb_schattig", "ueberwiegend_schattig", "vollstaendig_schattig"].map(
      (value) => {
        return {
          value: value,
          label: intl.get(`event_constants.shade_type.${value}`),
        };
      },
    );
  }

  static priceLevelOptions() {
    return ["kostenlos", "guenstig", "moderat", "exklusiv", "keine_angabe"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.price_level.${value}`),
      };
    });
  }

  static priceCurrencyOptions() {
    return ["EUR", "USD", "GBP", "CHF"].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.price_currency.${value}`),
      };
    });
  }

  static destinationSubtypeEventOptions() {
    return [
      "ausstellung",
      "festival",
      "fuehrung",
      "kinderfasching",
      "kinderfest",
      "konzert",
      "kurz",
      "praesentation",
      "ritterfest",
      "schnitzeljagd",
      "showevent",
      "sportveranstaltung",
      "tagderoffenentuer",
      "thementage",
      "vortrag",
      "wanderung",
      "workshop",
      "zirkus",
      "weihnachtsmarkt",
      "sonstiges",
    ].map((value) => {
      return {
        value: value,
        label: intl.get(`event_constants.destination_subtype_event.${value}`),
      };
    });
  }

  static getSubtypeOptionsForDestinationType(type: string) {
    const options = [];
    type.split(";").forEach((value) => {
      if (value === "bad") {
        options.push(...this.destinationSubtypeSwimOptions());
      } else if (value === "freizeitpark") {
        options.push(...this.destinationSubtypeAmusementParkOptions());
      } else if (value === "restaurant") {
        options.push(...this.destinationSubtypeRestaurantOptions());
      } else if (value === "kulturelle_einrichtung") {
        options.push(...this.destinationSubtypeCulturalFacilityOptions());
      } else if (value === "sehenswerter_ort") {
        options.push(...this.destinationSubtypeDesinationWorthSeeingOptions());
      } else if (value === "sportanlage") {
        options.push(...this.destinationSubtypeSportsFacilityOptions());
      } else if (value === "weg") {
        options.push(...this.destinationSubtypePathOptions());
      } else if (
        value === "familienevent" ||
        value === "kinderfest" ||
        value === "veranstaltung" ||
        value === "showevent"
      ) {
        options.push(...this.destinationSubtypeEventOptions());
      }
    });
    const uniqueOptions = [];
    const addedValues: string[] = [];
    for (const option of options) {
      if (!addedValues.includes(option.value)) {
        addedValues.push(option.value);
        uniqueOptions.push(option);
      }
    }
    return uniqueOptions;
  }

  static toSingleSelectOption(value: string, key: string) {
    if (value === undefined || value === null || value.length === 0) {
      return null;
    } else {
      return {
        value: value,
        label: intl.get(`${key}.${value}`),
      };
    }
  }

  static fromSingleSelectOption(value) {
    if (value) {
      return value.value;
    } else {
      return "";
    }
  }

  static toMultiSelectOption(value: string, options: any) {
    if (value.length === 0) {
      return [];
    } else {
      const mappedValues = [];
      const splitValues = value.split(";");
      for (const optionValue of splitValues) {
        for (const option of options) {
          if (option.value === optionValue) {
            mappedValues.push(option);
          }
        }
      }
      return mappedValues;
    }
  }

  static fromMultiSelectOption(value) {
    if (value) {
      return (
        (value as any[]).map((value1) => {
          return value1.value;
        }) as string[]
      ).join(";");
    } else {
      return "";
    }
  }

  static fetchedValueFromPropPath(state: EventFormState, propPath: string) {
    let value: any;
    if (propPath.indexOf(".") !== -1) {
      value = state.event;
      for (const part of propPath.split(".")) {
        value = value[`${part}`];
      }
    } else {
      value = state.event[`${propPath}`];
    }
    return value;
  }

  static fetchedValueFromCompanyPropPath(state: CompanyFormState, propPath: string) {
    if (state.company === undefined) {
      return null;
    }
    let value: any;
    if (propPath.indexOf(".") !== -1) {
      value = state.company;
      for (const part of propPath.split(".")) {
        value = value[`${part}`];
      }
    } else {
      value = state.company[`${propPath}`];
    }
    return value;
  }

  static fetchedValueFromResellerProfilePropPath(state: ResellerProfileFormState, propPath: string) {
    if (state.profile === undefined) {
      return null;
    }
    let value: any;
    if (propPath.indexOf(".") !== -1) {
      value = state.profile;
      for (const part of propPath.split(".")) {
        value = value[`${part}`];
      }
    } else {
      value = state.profile[`${propPath}`];
    }
    return value;
  }

  static activityLogTypeOptions() {
    return [
      {
        value: "note",
        label: intl.get(`activity_log_types.note`),
      },
      {
        value: "contact_email",
        label: intl.get(`activity_log_types.contact_email`),
      },
      {
        value: "contact_phone",
        label: intl.get(`activity_log_types.contact_phone`),
      },
      {
        value: "contact_generic",
        label: intl.get(`activity_log_types.contact_generic`),
      },
    ];
  }

  static federalStateOptionsForCountry(country_code?: string, currentValue?: string) {
    if (!country_code || country_code === "") {
      return [{ value: "", label: "Land auswählen" }];
    }

    const federalStatesByCountryMap = SelectionOptions.federalStatesByCountryOptions();

    return this.mappedLocationOptions(federalStatesByCountryMap, true, true, country_code, currentValue);
  }

  static regionOptionsForFederalState(state?: string, currentValue?: string) {
    if (!state || state === "") {
      return [{ value: "", label: "Bundesland auswählen" }];
    }
    const regionsByFederalStateMap = SelectionOptions.regionsByFederalStateOptions();

    return this.mappedMultiselectLocationOptions(regionsByFederalStateMap, true, true, state, currentValue);
  }

  static mappedLocationOptions(
    mappingOptions: any,
    addCurrentValue: boolean,
    sort: boolean,
    key?: string,
    currentValue?: string,
  ) {
    let allValues = [];
    if (key) {
      const value: string[] = mappingOptions[key];
      if (value) {
        allValues = value.map((value1) => {
          return { value: value1, label: value1 };
        });
      }
    }
    if (currentValue && currentValue !== "" && addCurrentValue) {
      if (
        allValues.findIndex((value) => {
          return value.label === currentValue;
        }) === -1
      ) {
        allValues.push({ value: currentValue, label: currentValue });
      }
    }

    if (sort) {
      allValues.sort((a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });
    }

    allValues.unshift({ value: "", label: "---" });

    return allValues;
  }

  static mappedMultiselectLocationOptions(
    mappingOptions: any,
    addCurrentValue: boolean,
    sort: boolean,
    key?: string,
    currentValue?: string,
  ) {
    let allValues = [];
    if (key) {
      const value: string[] = mappingOptions[key];
      if (value) {
        allValues = value.map((value1) => {
          return { value: value1, label: value1 };
        });
      }
    }
    if (currentValue && currentValue !== "" && addCurrentValue) {
      const values = currentValue.split(";");
      for (const currentVal of values) {
        if (
          allValues.findIndex((value) => {
            return value.label === currentVal;
          }) === -1
        ) {
          allValues.push({ value: currentVal, label: currentVal });
        }
      }
    }

    if (sort) {
      allValues.sort((a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });
    }

    allValues.unshift({ value: "", label: "---" });

    return allValues;
  }

  static cityOptionsForFederalState(state?: string, currentValue?: string) {
    const citiesByFederalStateMap = SelectionOptions.citiesByFederalStateOptions();

    return this.mappedLocationOptions(citiesByFederalStateMap, true, true, state, currentValue);
  }

  static districtOptionsForCity(city?: string, currentValue?: string) {
    const districtsByCityMap = SelectionOptions.districtByCitiesOptions();

    return this.mappedLocationOptions(districtsByCityMap, true, true, city, currentValue);
  }

  static selectedDependentLocationValue(fallback: string, hint: string, value?: string, dependentValue?: string) {
    if (dependentValue && dependentValue !== "") {
      if (value) {
        return { value: value, label: value };
      } else {
        return { value: "", label: hint };
      }
    }
    if (value && value !== "") {
      return { value, label: value };
    }
    return { value: "", label: fallback };
  }

  static selectedMultiDependentLocationValue(fallback: string, hint: string, value?: string, dependentValue?: string) {
    if (dependentValue && dependentValue !== "") {
      if (value) {
        const splitValues = value.split(";");
        return splitValues.map((value1) => {
          return { value: value1, label: value1 };
        });
      } else {
        return { value: "", label: hint };
      }
    }
    if (value && value !== "") {
      const splitValues = value.split(";");
      return splitValues.map((value1) => {
        return { value: value1, label: value1 };
      });
    }
    return { value: "", label: fallback };
  }

  static hasSelectedPredefinedValueFor(selectionOptionType: SelectionOptionType, key?: string, value?: string) {
    let options: any = null;
    if (selectionOptionType == SelectionOptionType.FederalStateForCountry) {
      options = SelectionOptions.federalStatesByCountryOptions();
    } else if (selectionOptionType == SelectionOptionType.RegionForFederalState) {
      options = SelectionOptions.regionsByFederalStateOptions();
    } else if (selectionOptionType == SelectionOptionType.CityForFederalState) {
      options = SelectionOptions.citiesByFederalStateOptions();
    } else if (selectionOptionType == SelectionOptionType.DistrictForCity) {
      options = SelectionOptions.districtByCitiesOptions();
    }
    if (options === null) {
      return false;
    }
    if (key && key !== "") {
      const optionsForKey: string[] = options[key];
      if (optionsForKey) {
        if (value && value !== "") {
          if (optionsForKey.indexOf(value) !== -1) {
            return true;
          }
        }
      }
    }
    return false;
  }
}

export enum SelectionOptionType {
  FederalStateForCountry,
  RegionForFederalState,
  CityForFederalState,
  DistrictForCity,
}
