import { Grid, Theme } from "@mui/material";
import * as React from "react";

const classes = {
  container: (theme: Theme) => ({
    color: "white",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    padding: 2,
    height: "100%",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "18px",
    marginRight: "12px",
  }),
  subContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warningText: (theme: Theme) => ({
    color: "#FFFFFF",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }),
};

export default function WarningMessageBanner({ text }: { text: string }) {
  return (
    <Grid sx={classes.container}>
      <Grid sx={classes.subContainer}>
        <Grid item container direction={"column"} sx={classes.warningText}>
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
