import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import Select from "@mui/material/Select";
import { AsYouType } from "libphonenumber-js/core";
import { NumericFormat } from "react-number-format";
import * as CompanyActions from "../../../actions/company";
import { RootState } from "../../../reducers";
import { CompanyFormState } from "../../../model/companyFormState";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import { SectionLabel } from "../../../components/SectionLabel";
import { PageState, PageStateType } from "../../../model/pageState";
import { getDisplayName, User } from "../../../model/user";
import { AuthenticationState } from "../../../model/authenticationState";
import { Company } from "../../../model/company";
import { CompanyState } from "../../../model/companyState";
import * as EmailValidator from "email-validator";
import { Constants } from "./event_edit/event_options";
import { CompanyEditTextInputField } from "./event_edit/fields/CompanyEditTextInputField";
import { CompanyEditSingleSelectField } from "./event_edit/fields/CompanyEditSingleSelectField";
import { BCEventCategory } from "../../../model/event";
import MyReactSelect from "../../../components/select/MyReactSelect";
import AppStyles from "../../../styles/appStyles";

const metadata = require("libphonenumber-js/metadata.min.json");

const classes = {
  select: {
    paddingTop: "6px",
    paddingBottom: "7px",
    marginTop: "16px",
  },
};

export interface Props {
  companyFormState: CompanyFormState;
  companyState: CompanyState;
  pageState: PageState;
  actions: typeof CompanyActions;
  readonly?: boolean;
  showResellerSelection?: boolean;
  authenticationState: AuthenticationState;
  children?: any;
}

export interface State {
  tab: number;
}

class CompanyEditForm extends React.Component<Props> {
  state = {
    tab: 0,
    reseller_id: null,
  };

  handleChange = (propName: any) => (event: any) => {
    this.props.actions.updateCompanyFormField({
      prop: propName,
      value: event.target.value,
    });
  };

  onChangePhoneNumber(event: any) {
    event.target.value = new AsYouType(undefined, metadata).input(event.target.value);
    this.handleChange("company.phone")(event);
  }

  onChangePhoneNumberTwo(event: any) {
    event.target.value = new AsYouType(undefined, metadata).input(event.target.value);
    this.handleChange("company.phone_2")(event);
  }

  onChangeFaxNumber(event: any) {
    event.target.value = new AsYouType(undefined, metadata).input(event.target.value);
    this.handleChange("company.fax")(event);
  }

  changeTab = (event: any, tab: any) => {
    this.setState({ tab });
  };

  renderSalesPartnerCompanies() {
    if (this.props.companyFormState.company.is_salespartner) {
      return null;
    }

    if (this.props.authenticationState.user.role === "ADMIN") {
      if (this.props.companyState.allSalesPartners) {
        return (
          <Grid item xs={12} sm={6} container direction="column">
            <SectionLabel required={false}>{intl.get("company_form.label.link_salespartner")}</SectionLabel>
            <MyReactSelect
              getOptionLabel={(option) => {
                return option.name;
              }}
              getOptionValue={(option) => option.company_id}
              options={this.props.companyState.allSalesPartners}
              value={this.props.companyState.allSalesPartners.find((value) => {
                return this.props.companyFormState.company.salespartner_company_id === value.company_id;
              })}
              onChange={(value) => {
                this.props.actions.updateCompanyFormField({
                  prop: "company.salespartner_company_id",
                  value: value ? (value as Company).company_id : null,
                });
              }}
              placeholder={intl.get("company_form.label.salespartner_hint")}
              isClearable
              isSearchable={true}
            />
          </Grid>
        );
      }
    } else if (this.props.companyFormState.company_id && this.props.companyFormState.company.salespartner_company_id) {
      const salesPartnerCompanies: Company[] = this.props.authenticationState.user.companies.filter((company) => {
        return company.is_salespartner && this.props.companyFormState.company.salespartner_company_id;
      });
      if (salesPartnerCompanies.length > 0) {
        return (
          <Grid item xs={12} sm={6} container direction="column">
            <TextFieldWithLabel
              required={false}
              disabled={true}
              label={intl.get("company_form.label.link_salespartner")}
              id="link_salespartner"
              value={salesPartnerCompanies[0].name}
              margin="normal"
            />
          </Grid>
        );
      }
    } else if (!this.props.companyFormState.company_id) {
      const salesPartnerCompanies: Company[] = this.props.authenticationState.user.companies.filter((company) => {
        return company.is_salespartner;
      });

      if (salesPartnerCompanies.length > 0) {
        if (!this.props.companyFormState.company.salespartner_company_id) {
          this.props.companyFormState.company.salespartner_company_id = salesPartnerCompanies[0].company_id;
        }
        return (
          <Grid item xs={12} sm={6} container direction="column">
            <TextFieldWithLabel
              required={false}
              disabled={true}
              label={intl.get("company_form.label.link_salespartner")}
              id="link_salespartner"
              value={
                salesPartnerCompanies.find(
                  (value) => value.company_id === this.props.companyFormState.company.salespartner_company_id,
                ).name
              }
              margin="normal"
            />
          </Grid>
        );
      }
    }
  }

  renderPhoneNumber(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required={false}
        disabled={this.props.readonly}
        label={intl.get("company_form.label.phone")}
        id="phone"
        onChange={(event: any) => {
          this.onChangePhoneNumber(event);
        }}
        value={this.props.companyFormState.company.phone}
        margin="normal"
        error={invalidFields.includes("phone")}
      />
    );
  }

  renderPhoneNumberTwo(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required={false}
        disabled={this.props.readonly}
        label={intl.get("company_form.label.phone_2")}
        id="phone_2"
        onChange={(event: any) => {
          this.onChangePhoneNumberTwo(event);
        }}
        value={this.props.companyFormState.company.phone_2}
        margin="normal"
        error={invalidFields.includes("phone_2")}
      />
    );
  }

  renderFaxNumber(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required={false}
        disabled={this.props.readonly}
        label={intl.get("company_form.label.fax")}
        id="fax"
        onChange={(event: any) => {
          this.onChangeFaxNumber(event);
        }}
        value={this.props.companyFormState.company.fax}
        margin="normal"
        error={invalidFields.includes("fax")}
      />
    );
  }

  renderAddress() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        required={true}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"address"}
      />
    );
  }

  renderPostalCode() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        required={true}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"postal_code"}
        hideClearIcon={true}
      />
    );
  }

  renderCity() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        required={true}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"city"}
      />
    );
  }

  renderSalesContactTitle() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"sales_contact_title"}
      />
    );
  }

  renderSalesContactFirstname() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"sales_contact_firstname"}
      />
    );
  }

  renderSalesContactLastname() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"sales_contact_lastname"}
      />
    );
  }

  renderSalesContactEmail() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"sales_contact_email"}
        type={"email"}
      />
    );
  }

  renderSalesContactPhone() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"sales_contact_phone"}
      />
    );
  }

  renderCountry() {
    return (
      <CompanyEditSingleSelectField
        required={true}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        options={Constants.addressCountryCodeOptions()}
        field={"country_code"}
        onChange={(value) => {
          if (value) {
            this.props.actions.updateCompanyFormField({
              prop: "company.country",
              value: value.label,
            });
          } else {
            this.props.actions.updateCompanyFormField({
              prop: "company.country",
              value: null,
            });
          }
        }}
      />
    );
  }

  renderNewsletterUnsubscribed() {
    return (
      <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
        <FormGroup>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={this.props.companyFormState.company.newsletter_unsubscribed}
                onChange={(event: any, checked: boolean) => {
                  this.props.actions.updateCompanyFormField({
                    prop: "company.newsletter_unsubscribed",
                    value: checked,
                  });
                }}
              />
            }
            label={intl.get("company_form.label.newsletter_unsubscribed")}
          />
        </FormGroup>
      </Grid>
    );
  }

  renderSalesContactInfo() {
    return (
      <>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get("company_form.tab_sales_contact_info")}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm} spacing={5}>
            <Grid item xs={12} md={2} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderSalesContactTitle()}
            </Grid>
            <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderSalesContactFirstname()}
            </Grid>
            <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderSalesContactLastname()}
            </Grid>
            <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderSalesContactEmail()}
            </Grid>
            <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderSalesContactPhone()}
            </Grid>
            {(this.props.authenticationState.user.role === "ADMIN" ||
              this.props.authenticationState.user.role === "INTERNAL_SALES_MANAGER") && (
              <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                {this.renderNewsletterUnsubscribed()}
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }

  renderCustomCategory(category: BCEventCategory) {
    return (
      <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
        <FormGroup>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={
                  this.props.companyFormState.company.custom_categories.findIndex(
                    (value) => value.category_id === category.category_id,
                  ) !== -1
                }
                onChange={(event: any, checked: boolean) => {
                  if (checked) {
                    this.props.actions.addCompanyCustomCategory(category);
                  } else {
                    this.props.actions.removeCompanyCustomCategory(category);
                  }
                }}
              />
            }
            label={intl.get(`event_constants.category.${category.identifier}`)}
          />
        </FormGroup>
      </Grid>
    );
  }

  renderCustomCategoryInfo() {
    if (
      this.props.authenticationState.user.role !== "ADMIN" &&
      this.props.authenticationState.user.role !== "INTERNAL_SALES_MANAGER"
    ) {
      return null;
    }

    if (!this.props.companyFormState.company.all_custom_categories) {
      return null;
    }

    const mappedCustomCategories = this.props.companyFormState.company.all_custom_categories.map((value) =>
      this.renderCustomCategory(value),
    );

    return (
      <>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get("company_form.custom_categories")}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm} spacing={5}>
            {mappedCustomCategories}
          </Grid>
        </Paper>
      </>
    );
  }

  renderInvoiceInfo() {
    return (
      <>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get("company_form.invoice_info")}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm} spacing={5}>
            <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderInvoiceAttentionOf()}
            </Grid>
            <Grid item xs={12} md={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              {this.renderInvoiceAddendum()}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  renderInvoiceAttentionOf() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"invoice_attention_of"}
      />
    );
  }

  renderInvoiceAddendum() {
    return (
      <CompanyEditTextInputField
        disabled={this.props.readonly}
        formState={this.props.companyFormState}
        formActions={this.props.actions}
        field={"invoice_addendum"}
        placeholder={intl.get(`company_form.label.invoice_addendum_placeholder`)}
      />
    );
  }

  render() {
    if (this.props.pageState.state === PageStateType.Loading) {
      return <></>;
    }

    let invalidFields: string[] = [];
    if (this.props.companyFormState.error && this.props.companyFormState.error.validationErrors) {
      invalidFields = this.props.companyFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }

    return (
      <>
        <Box sx={AppStyles.innerContentRootWithoutLimit} style={{ paddingBottom: 32 }}>
          {this.props.children}

          <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
            <Typography>
              <b>{intl.get("company_form.tab_info")}</b>
            </Typography>
          </Grid>

          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm} spacing={5}>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                <TextFieldWithLabel
                  required
                  disabled={this.props.readonly}
                  label={intl.get("company_form.label.name")}
                  id="name"
                  onChange={this.handleChange("company.name")}
                  value={this.props.companyFormState.company.name}
                  margin="normal"
                  error={invalidFields.includes("name")}
                />
              </Grid>

              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  <TextFieldWithLabel
                    required
                    disabled={this.props.readonly || this.props.companyFormState.company_id === null}
                    label={intl.get("company_form.label.slug")}
                    id="slug"
                    onChange={this.handleChange("company.slug")}
                    value={
                      this.props.companyFormState.company_id === null
                        ? intl.get("company_form.label.slug_placeholder")
                        : this.props.companyFormState.company.slug
                    }
                    margin="normal"
                    error={invalidFields.includes("slug")}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} container direction="column">
                <TextFieldWithLabel
                  label={intl.get("company_form.label.uid")}
                  id="uid"
                  disabled={this.props.readonly}
                  onChange={this.handleChange("company.uid")}
                  value={this.props.companyFormState.company.uid}
                  margin="normal"
                  required={false}
                  error={invalidFields.includes("uid")}
                />
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                <FormControl>
                  <SectionLabel>{intl.get("company_form.label.default_currency")}</SectionLabel>
                  <Select
                    displayEmpty
                    disabled={this.props.readonly}
                    onChange={this.handleChange("company.default_currency")}
                    value={this.props.companyFormState.company.default_currency}
                    sx={classes.select}
                    inputProps={{
                      name: "default_currency",
                      id: "default_currency",
                    }}
                  >
                    <MenuItem value="EUR">{intl.get("currencies.EUR")}</MenuItem>
                    <MenuItem value="USD">{intl.get("currencies.USD")}</MenuItem>
                    <MenuItem value="GBP">{intl.get("currencies.GBP")}</MenuItem>
                    <MenuItem value="CHF">{intl.get("currencies.CHF")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} container direction="column">
                <TextFieldWithLabel
                  required={false}
                  disabled={this.props.readonly}
                  label={intl.get("company_form.label.email")}
                  id="email"
                  onChange={this.handleChange("company.email")}
                  value={this.props.companyFormState.company.email}
                  margin="normal"
                  type="email"
                  error={invalidFields.includes("email")}
                />
              </Grid>

              {!this.props.companyFormState.company_id && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction="column"
                  sx={AppStyles.detailFormRow}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                />
              )}
              {!this.props.companyFormState.company_id && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction="column"
                  sx={AppStyles.detailFormRow}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        color="primary"
                        disabled={
                          !this.props.companyFormState.company.email ||
                          !EmailValidator.validate(this.props.companyFormState.company.email)
                        }
                        checked={this.props.companyFormState.company.email_add_as_admin}
                        onChange={(event: any, checked: boolean) => {
                          this.props.actions.updateCompanyFormField({
                            prop: "company.email_add_as_admin",
                            value: checked,
                          });
                        }}
                      />
                    }
                    label={intl.get("company_form.label.email_add_as_admin")}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderPhoneNumber(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderPhoneNumberTwo(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderFaxNumber(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column">
                <TextFieldWithLabel
                  required={false}
                  disabled={this.props.readonly}
                  label={intl.get("company_form.label.website")}
                  id="website_url"
                  onChange={this.handleChange("company.website_url")}
                  value={this.props.companyFormState.company.website_url}
                  margin="normal"
                  type="url"
                  placeholder={intl.get("web_site_placeholder")}
                />
              </Grid>

              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <Divider />
              </Grid>
              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderAddress()}
              </Grid>
              <Grid item xs={12} md={3} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderPostalCode()}
              </Grid>
              <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderCity()}
              </Grid>
              <Grid item xs={12} md={5} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                {this.renderCountry()}
              </Grid>
              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <Divider />
              </Grid>

              {this.props.showResellerSelection && (
                <Grid item xs={12} sm={6} container direction="column">
                  <SectionLabel required={false}>{intl.get("company_form.label.reseller")}</SectionLabel>
                  <MyReactSelect
                    getOptionLabel={(option) => {
                      return getDisplayName(option);
                    }}
                    getOptionValue={(option) => option.user_id}
                    options={this.props.companyFormState.resellers}
                    value={this.props.companyFormState.resellers.find(
                      (value) => value.user_id === this.props.companyFormState.company.reseller_user_id,
                    )}
                    onChange={(value) => {
                      this.props.actions.updateCompanyFormField({
                        prop: "company.reseller_user_id",
                        value: value ? (value as User).user_id : null,
                      });
                    }}
                    placeholder={intl.get("company_form.label.reseller_hint")}
                    isClearable
                    isSearchable={true}
                  />
                </Grid>
              )}

              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  <FormGroup>
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.props.companyFormState.company.is_salespartner}
                          onChange={(event: any, checked: boolean) => {
                            this.props.actions.updateCompanyFormField({
                              prop: "company.is_salespartner",
                              value: checked,
                            });
                          }}
                        />
                      }
                      label={intl.get("company_form.label.is_salespartner")}
                    />
                  </FormGroup>
                </Grid>
              )}

              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} sm={6} container direction="column">
                  <SectionLabel required={false}>
                    {intl.get("company_form.label.salespartner_discount_percentage")}
                  </SectionLabel>
                  <NumericFormat
                    value={
                      this.props.companyFormState.company.salespartner_discount_percentage
                        ? this.props.companyFormState.company.salespartner_discount_percentage
                        : undefined
                    }
                    decimalScale={0}
                    customInput={TextField}
                    disabled={this.props.companyFormState.company.is_salespartner === false}
                    onValueChange={(values) => {
                      const { value } = values;

                      if (value && value.length > 0) {
                        this.props.actions.updateCompanyFormField({
                          prop: "company.salespartner_discount_percentage",
                          value: Number(value),
                        });
                      } else {
                        this.props.actions.updateCompanyFormField({
                          prop: "company.salespartner_discount_percentage",
                          value: undefined,
                        });
                      }
                    }}
                  />
                </Grid>
              )}
              {this.renderSalesPartnerCompanies()}

              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                  <FormGroup>
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.props.companyFormState.company.editorial}
                          onChange={(event: any, checked: boolean) => {
                            this.props.actions.updateCompanyFormField({
                              prop: "company.editorial",
                              value: checked,
                            });
                          }}
                        />
                      }
                      label={intl.get("company_form.label.editorial")}
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Paper>

          {this.renderSalesContactInfo()}

          {this.renderInvoiceInfo()}

          {this.renderCustomCategoryInfo()}
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    companyFormState: state.companyFormState,
    companyState: state.companyState,
    pageState: state.pageState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyEditForm);
