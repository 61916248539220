import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { ForgotPasswordState } from "../model/user";
import * as AuthActions from "../actions/auth";
import { RootState } from "../reducers";
import { ErrorView } from "../components";
import { DefaultButton, LinkButton } from "../components/Buttons";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import AppStyles from "../styles/appStyles";

const classes = {
  subTitle: (theme: Theme) => ({
    fontWeight: "bold",
    fontSize: theme.fontSizes.largeFont,
    marginBottom: 10,
  }),
  section: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
};

export interface Props {
  forgotPasswordState: ForgotPasswordState;
  actions: typeof AuthActions;
}

export interface State {
  email: string;
}

function ForgotPasswordPage(props: Props) {
  const [state, setState] = useState({
    email: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    props.forgotPasswordState.triggered = false;
    return () => {
      props.forgotPasswordState.triggered = false;
    };
  }, []);

  const handleChange = (name: any) => (event: any) => {
    props.forgotPasswordState.error = null;
    // @ts-ignore
    setState({
      [name]: event.target.value,
    });
  };

  const forgotPassword = () => {
    props.actions.forgotPassword(state.email);
  };

  const error = props.forgotPasswordState != null ? props.forgotPasswordState.error : null;
  if (error == null && props.forgotPasswordState.triggered) {
    return (
      <Box component={"main"} sx={AppStyles.centerCardLayout}>
        <PageTracker />
        <Paper sx={AppStyles.centerPaper}>
          <Grid style={{ width: "100%" }}>
            <HeaderLabel>{intl.get("forgotPassword.title")}</HeaderLabel>
            <Typography sx={classes.subTitle}>{intl.get("forgotPassword.email_sent")}</Typography>
          </Grid>

          <Grid container sx={classes.section} direction="row" justifyContent="flex-end" alignItems={"flex-end"}>
            <DefaultButton
              style={{ paddingLeft: 50, paddingRight: 50 }}
              sx={{ flexGrow: 1 }}
              onClick={() => {
                navigate("/login");
              }}
              title={intl.get("signup.jump_to_login")}
            />
          </Grid>
        </Paper>
      </Box>
    );
  }
  return (
    <Box component={"main"} sx={AppStyles.centerCardLayout}>
      <PageTracker />
      <Paper sx={AppStyles.centerPaper}>
        <Grid style={{ width: "100%" }}>
          <HeaderLabel>{intl.get("forgotPassword.title")}</HeaderLabel>
          <Typography sx={classes.subTitle}>{intl.get("forgotPassword.subTitle")}</Typography>
        </Grid>
        <Box sx={AppStyles.appForm}>
          <ErrorView error={error} defaultErrorMessage={intl.get("error.generic")} />

          <Grid container justifyContent="center" direction="column">
            <Grid item container sx={classes.section} direction="column">
              <TextFieldWithLabel
                label={intl.get("label.email")}
                placeholder={intl.get("label.email")}
                error={error != null}
                id="email"
                value={state.email}
                onChange={handleChange("email")}
                margin="normal"
              />
            </Grid>
            <Grid container sx={classes.section} direction="row" justifyContent="space-between" alignItems={"flex-end"}>
              <LinkButton title={intl.get("signup.jump_to_login")} to="/login" search={location.search} />
              <DefaultButton
                style={{ paddingLeft: 50, paddingRight: 50 }}
                onClick={forgotPassword}
                title={intl.get("forgotPassword.button")}
                disabled={state.email.length === 0}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

function authMapStateToProps(state: RootState) {
  return {
    forgotPasswordState: state.forgotPasswordState,
  };
}

function authMapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default connect(authMapStateToProps, authMapDispatchToProps)(ForgotPasswordPage);
