import { connect } from "react-redux";
import * as React from "react";
import { compose } from "redux";
import { CompanyState } from "../model/companyState";
import { AuthenticationState } from "../model/authenticationState";
import { RootState } from "../reducers";

export interface BaseProps {
  hasAnyPermission: string[];
  ignoreShowCompanyDetailMenuFlag?: boolean;
  renderExit: boolean;
}

export interface Props extends BaseProps {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  children: any;
}

class WithCompanyPermission extends React.Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (
      this.props.ignoreShowCompanyDetailMenuFlag !== true &&
      this.props.companyState.show_company_detail_menu === false
    ) {
      return null;
    }

    if (
      this.props.companyState &&
      this.props.companyState.company &&
      this.props.authenticationState &&
      this.props.authenticationState.user &&
      this.props.authenticationState.user.permissions
    ) {
      const userPermissions = this.props.authenticationState.user.permissions;
      let hasPermission = false;
      for (const permission of this.props.hasAnyPermission) {
        if (userPermissions.indexOf(permission) !== -1) {
          hasPermission = true;
        }
      }
      if (hasPermission) {
        return <>{this.props.children}</>;
      }
    }
    if (this.props.companyState && this.props.companyState.company && this.props.companyState.company.permissions) {
      const companyPermissions = this.props.companyState.company.permissions;
      let hasPermission = false;
      for (const permission of this.props.hasAnyPermission) {
        if (companyPermissions.indexOf(permission) !== -1) {
          hasPermission = true;
        }
      }
      if (hasPermission) {
        return <>{this.props.children}</>;
      }
    }
    if (this.props.renderExit) {
      return (
        <div>
          Die Seite wurde nicht gefunden oder Sie haben nicht die notwendigen Berechtigungen - bitte gehen Sie zurück
          zur Startseite.
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyState: state.companyState,
  };
}

export default compose(connect(mapStateToProps, null))(WithCompanyPermission);
