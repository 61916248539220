import intl from "react-intl-universal";
import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import BookingDrawerLayout from "../BookingDrawerLayout";
import { useDialogContext } from "../../dialog/DialogContext";

interface Props {
  error?: any;
  infoSection?: any;
}
export default function BookingDrawerErrorContent({ infoSection, error }: Props) {
  const { handler } = useDialogContext<any>();

  const errorMessage =
    error?.response?.data?.status === "ERROR_NO_COMPANY_COUNTRY" ||
    error?.response?.data?.status === "NO_COMPANY_COUNTRY"
      ? intl.getHTML("booking_drawer.general.error_no_company_country")
      : intl.get("error.generic");

  return (
    <BookingDrawerLayout infoSection={infoSection}>
      <Stack>
        <Typography sx={{ font: "normal normal bold 18px/21px Manrope" }}>
          {intl.get("booking_drawer.general.error_title")}
        </Typography>
        <Typography variant={"body2"} sx={{ marginTop: "21px" }}>
          {errorMessage}
        </Typography>
        <Button
          onClick={handler.close}
          variant={"outlined"}
          color={"defaultTextColor"}
          sx={{ marginTop: "21px", marginRight: "auto" }}
        >
          {intl.get("generic.btn_close")}
        </Button>
      </Stack>
    </BookingDrawerLayout>
  );
}
