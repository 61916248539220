import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { asNonEmptyString, cloneDefaultState, updateStateFieldByProp } from "../util/util_extensions";
import { SeoPageFormState } from "../model/seoPageFormState";
import { BCEventCategory } from "../model/event";

const defaultState = {
  error: null,
  seopage_id: null,
  valid: false,
  seopage: {
    title: "",
    headline: "",
    url: "",
    description: "",
    bottom_description: "",
    meta_description: "",
    internal_notes: "",
    active: false,
    filter_city: "",
    filter_text: "",
    filter_free_only: false,
    filter_categories: [],
    filter_tags: [],
  },
  seopages: [],
  categories: [],
  tags: [],
  cities: [],
  closePage: false,
};

function validateSeoPageFormData(newState: SeoPageFormState): boolean {
  if (
    asNonEmptyString(newState.seopage.title) == null ||
    asNonEmptyString(newState.seopage.headline) == null ||
    asNonEmptyString(newState.seopage.url) == null
  ) {
    return false;
  }
  return true;
}

export const seoPageFormState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_SEOPAGE_FORM]() {
    return cloneDefaultState(defaultState);
  },
  [ActionType.EDIT_SEOPAGE](state: SeoPageFormState, action: Action<any>) {
    if (action.payload.data === true) {
      return {
        seopage_id: action.payload.seopage_id,
        seopage: action.payload.seopage,
        cities: action.payload.cities,
        tags: action.payload.tags,
        categories: action.payload.categories,
        valid: true,
      };
    }
    return { ...state };
  },
  [ActionType.CREATE_SEOPAGE](state: SeoPageFormState, action: Action<any>) {
    return {
      ...cloneDefaultState(defaultState),
      cities: action.payload.cities,
      categories: action.payload.categories,
      tags: action.payload.tags,
    };
  },
  [ActionType.UPDATE_SEOPAGE_FORM_FIELD](state: SeoPageFormState, action: Action<any>) {
    let newState = updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);

    if (validateSeoPageFormData(newState)) {
      newState = { ...newState, valid: true };
    } else {
      newState = { ...newState, valid: false };
    }

    return newState;
  },
  [ActionType.DELETE_SEOPAGE](state: SeoPageFormState) {
    return { ...state, closePage: true };
  },
  [ActionType.SAVE_SEOPAGE](state: SeoPageFormState, action: Action<any>) {
    return { ...state, seopage: action.payload.seopage, seopage_id: action.payload.seopage.seopage_id };
  },
  [ActionType.PAGE_SAVING_ERROR](state: SeoPageFormState, action: Action<any>) {
    return { ...state, error: action.payload.error };
  },
  [ActionType.TOGGLE_SEOPAGE_CATEGORY](state: SeoPageFormState, action: Action<any>) {
    let newState = { ...state };

    const category: BCEventCategory = action.payload;
    const index = newState.seopage.filter_categories.findIndex((cat) => cat.category_id === category.category_id);
    if (index === -1) {
      newState.seopage.filter_categories.push(category);
    } else {
      newState.seopage.filter_categories.splice(index, 1);
    }

    if (validateSeoPageFormData(newState)) {
      newState = { ...newState, valid: true };
    } else {
      newState = { ...newState, valid: false };
    }

    return newState;
  },
});
