import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { GlobalInvoicesState } from "../model/global_invoices/global.invoices.state";

export const globalInvoicesState = createReducer(
  {
    allInvoices: [],
    paging: {
      page_size: 50,
      current_page: 1,
    },
  },
  {
    [ActionType.FETCH_GLOBAL_INVOICES](state: GlobalInvoicesState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          allInvoices: action.payload.invoices,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
  },
);
