import { Dispatch } from "redux";
import { ActionType } from "../model/actions";

export const createUser = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_USER_FORM });

  dispatch({ type: ActionType.CREATE_USER });
};

// @ts-ignore
export const updateUserFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_USER_FORM_FIELD,
    payload: { prop, value },
  };
};

export const resetUserForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_USER_FORM });
};
