import { Action, ActionType } from "../model/actions";
import createReducer from "./createReducer";
import { ForgotPasswordState } from "../model/user";

export const forgotPasswordState = createReducer(new ForgotPasswordState(), {
  [ActionType.FORGOT_PASSWORD_SUCCESS](state: ForgotPasswordState) {
    return { ...state, performing: false, triggered: true };
  },
  [ActionType.FORGOT_PASSWORD_PROGRESS](state: ForgotPasswordState) {
    return { ...state, performing: true, triggered: false };
  },
  [ActionType.FORGOT_PASSWORD_ERROR](state: ForgotPasswordState, action: Action<any>) {
    const error = action.payload;
    if (error.error) {
      return { ...state, loading: false, triggered: false, error: error.error };
    }
    return { ...state, loading: false, triggered: false, error: null };
  },
});
