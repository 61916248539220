import intl from "react-intl-universal";
import { Stack, Typography } from "@mui/material";
import ContainedTabs from "../../tabs/ContainedTabs";
import ContainedTab from "../../tabs/ContainedTab";
import BookingDrawerSumRow from "../components/BookingDrawerSumRow";
import BookingButtonAndHint from "../../BookingButtonAndHint";
import useAvailablePaymentProducts from "../../../hooks/useAvailablePaymentProducts";

interface Props {
  value: string;
  onChange: (newProductId: string) => void;
  onBookingButtonClicked: () => void;
}

const getBillingIntervalLabel = (billingIntervalUnit: string): string => {
  switch (billingIntervalUnit) {
    case "year":
      return intl.get("booking_drawer.premium_upgrade.plan_selection.option_yearly");
    case "month":
      return intl.get("booking_drawer.premium_upgrade.plan_selection.option_monthly");
    default:
      return "Fehler";
  }
};

export default function PremiumUpgradeDrawerPlanSelectionPage({ value, onChange, onBookingButtonClicked }: Props) {
  const { availableProducts } = useAvailablePaymentProducts();

  const subscriptions = availableProducts.filter((product) => product.type === "subscription");
  const selectedProduct = availableProducts.find((product) => product.product_sku === value);

  // TODO: we should find a better way to group products other than by name
  const availableNames = [...new Set(subscriptions.map((product) => product.name))];

  // TODO: what about billing_interval_value???
  const availableBillingIntervals = [
    ...new Set(
      subscriptions
        .filter((product) => product.name === selectedProduct.name)
        .map((product) => product.billing_interval_unit),
    ),
  ];

  const handleProductNameSelected = (event: any, newProductName: string) => {
    const productsWithName = subscriptions.filter((product) => product.name === newProductName);

    // invalid product
    if (!productsWithName || productsWithName.length === 0) {
      return;
    }

    const productWithSameBillingInterval = productsWithName.find(
      (product) =>
        product.billing_interval_unit === selectedProduct.billing_interval_unit &&
        product.billing_interval_value === selectedProduct.billing_interval_value,
    );

    if (productWithSameBillingInterval) {
      onChange(productWithSameBillingInterval.product_sku);
    } else {
      onChange(productsWithName[0].product_sku);
    }
  };

  const handleBillingIntervalChanged = (event: any, newInterval: string) => {
    const productsWithName = subscriptions.filter((product) => product.name === selectedProduct.name);
    const newProduct = productsWithName.find((product) => product.billing_interval_unit === newInterval);
    if (newProduct) {
      onChange(newProduct.product_sku);
    }
  };

  if (!selectedProduct) {
    return (
      <Typography variant={"body2"} color={"error"}>
        {intl.get("error.generic")}
      </Typography>
    );
  }

  return (
    <Stack>
      <Stack direction={"row"} alignItems={"baseline"} gap={"7px"} sx={{ marginBottom: "15px" }}>
        <Typography sx={{ font: "normal normal bold 18px/21px Manrope" }}>
          {intl.get("booking_drawer.premium_upgrade.plan_selection.label_select_plan")}
        </Typography>

        {selectedProduct.product_sku_group === "premium_yearly" && (
          <Typography color={"secondary"} sx={{ font: "normal normal bold 14px/18px Manrope" }}>
            {intl.get("booking_drawer.premium_upgrade.plan_selection.badge_optimal_plan")}
          </Typography>
        )}
      </Stack>

      <ContainedTabs
        variant={"fullWidth"}
        value={selectedProduct.name}
        onChange={handleProductNameSelected}
        color={"primary"}
        indicatorColor={"primary"}
        textColor={"primary"}
      >
        {availableNames.map((productName) => (
          <ContainedTab value={productName} label={productName} key={productName} />
        ))}
      </ContainedTabs>

      <Typography sx={{ marginBottom: "15px", marginTop: "25px", font: "normal normal bold 18px/21px Manrope" }}>
        {intl.get("booking_drawer.premium_upgrade.plan_selection.label_select_payment_plan")}
      </Typography>

      <ContainedTabs
        variant={"fullWidth"}
        value={selectedProduct.billing_interval_unit}
        onChange={handleBillingIntervalChanged}
        color={"primary"}
        indicatorColor={"primary"}
        textColor={"primary"}
      >
        {availableBillingIntervals.map((billingInterval) => (
          <ContainedTab
            value={billingInterval}
            label={getBillingIntervalLabel(billingInterval)}
            key={billingInterval}
          />
        ))}
      </ContainedTabs>

      <BookingDrawerSumRow product={selectedProduct} sx={{ marginTop: "25px", marginBottom: "21px" }} />

      <BookingButtonAndHint
        onPerformBooking={onBookingButtonClicked}
        buttonLabel={intl.get("booking_drawer.premium_upgrade.plan_selection.button_book_now")}
        variant={"contained"}
      />

      <Typography sx={{ marginTop: "20px", font: "normal normal normal 14px/18px Manrope" }}>
        {intl.get("booking_drawer.premium_upgrade.plan_selection.hint_cancel_any_time")}
      </Typography>
    </Stack>
  );
}
