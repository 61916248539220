import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Check";
import PaymentPlanEditForm from "./components/forms/PaymentPlanEditForm";
import { PaymentPlanFormState } from "../model/paymentPlanFormState";
import { RootState } from "../reducers";
import * as PaymentProductActions from "../actions/payment.product.actions";
import { BackButton, DefaultButton, DeleteButton } from "../components/Buttons";
import { AuthenticationState } from "../model/authenticationState";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AppStyles from "../styles/appStyles";

export interface Props {
  paymentPlanFormState: PaymentPlanFormState;
  authState: AuthenticationState;
  actions: typeof PaymentProductActions;
}

export interface State {
  deletePaymentPlanConfirmationVisible: boolean;
}

function PaymentPlanEditPage(props: Props) {
  const [state, setState] = useState({
    supportBackAction: false,
    deletePaymentPlanConfirmationVisible: false,
    closing: false,
  });

  const navigate = useNavigate();
  const params = useParams();

  function closePage(): void {
    navigate(-1);
  }

  function deletePaymentPlan(): void {
    if (props.paymentPlanFormState.product_id != null) {
      props.actions.deletePaymentProduct(props.paymentPlanFormState.product_id);
      setState({
        ...state,
        deletePaymentPlanConfirmationVisible: false,
      });
    }
  }

  function confirmPaymentPlanDeletion() {
    setState({
      ...state,
      deletePaymentPlanConfirmationVisible: true,
    });
  }

  function cancelPaymentPlanDeletion() {
    setState({
      ...state,
      deletePaymentPlanConfirmationVisible: false,
    });
  }

  useEffect(() => {
    if (params == null) {
      props.actions.createNewPaymentProduct();
      setState({ ...state, supportBackAction: true });
    } else {
      const paymentplanId = params.product_id;

      if (paymentplanId) {
        setState({ ...state, supportBackAction: true }); // for implementing readonly: props.location.pathname.endsWith("/edit")
        props.actions.editPaymentProduct(paymentplanId);
      } else {
        setState({ ...state, supportBackAction: true });
        props.actions.createNewPaymentProduct();
      }
    }
  }, []);

  function saveChanges(): void {
    props.actions.savePaymentProduct(props.paymentPlanFormState.product, props.paymentPlanFormState.product_id);
  }

  if (state.closing) {
    return <div />;
  }
  if (props.paymentPlanFormState.closePage && state.supportBackAction) {
    props.actions.resetPaymentProductForm();
    props.actions.closePaymentProduct();
    closePage();
    setState({ ...state, closing: true });
    return <div />;
  }

  const showReadonly = false;
  const canDeletePaymentPlan = true;

  const disableSave = showReadonly || !props.paymentPlanFormState.valid;

  return (
    <>
      <PageTracker />
      <PaymentPlanEditForm
        readonly={showReadonly}
        showResellerSelection={
          props.authState.user.role &&
          props.authState.user.role !== "RESELLER" &&
          props.authState.user.role !== "INTERNAL_SALES_MANAGER"
        }
      >
        <Grid container direction="row">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.actionHeaderLeft}>
              <BackButton
                title={
                  props.paymentPlanFormState.product_id
                    ? intl.get("paymentplan_form.title_edit")
                    : intl.get("paymentplan_form.title_create")
                }
                onClick={() => {
                  closePage();
                }}
              />
            </Grid>

            <Grid item sx={AppStyles.headerRight}>
              {props.paymentPlanFormState.product_id && canDeletePaymentPlan && (
                <DeleteButton
                  disabled={showReadonly}
                  onClick={() => {
                    confirmPaymentPlanDeletion();
                  }}
                >
                  <ClearIcon sx={AppStyles.buttonLeftIcon} />
                  {intl.get("paymentplan_form.button_delete")}
                </DeleteButton>
              )}

              <DefaultButton
                style={{
                  marginLeft: props.paymentPlanFormState.product_id && canDeletePaymentPlan ? 10 : 0,
                }}
                disabled={disableSave}
                onClick={() => {
                  saveChanges();
                }}
              >
                <SaveIcon sx={AppStyles.buttonLeftIcon} />
                {props.paymentPlanFormState.product_id
                  ? intl.get("paymentplan_form.button_save")
                  : intl.get("paymentplan_form.button_create")}
              </DefaultButton>
            </Grid>
          </Grid>
        </Grid>
      </PaymentPlanEditForm>
      {state.deletePaymentPlanConfirmationVisible && (
        <Dialog
          open={state.deletePaymentPlanConfirmationVisible}
          onClose={() => cancelPaymentPlanDeletion()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.get("paymentplan_form.delete_confirmation.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.get("paymentplan_form.delete_confirmation.message", {
                paymentplan_title: props.paymentPlanFormState.product.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelPaymentPlanDeletion()} color="primary">
              {intl.get("paymentplan_form.delete_confirmation.button_cancel")}
            </Button>
            <Button onClick={() => deletePaymentPlan()} color="error" autoFocus>
              {intl.get("paymentplan_form.delete_confirmation.button_confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    paymentPlanFormState: state.paymentPlanFormState,
    authState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(PaymentProductActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentPlanEditPage);
