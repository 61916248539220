import { TableCell, TableHead, TableRow } from "@mui/material";
import AppStyles from "../../../styles/appStyles";
import intl from "react-intl-universal";
import * as React from "react";

export default function CompanyInvoicesTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={AppStyles.tableCellNotSelectable}>
          {intl.get("company_plan_info.invoices_list.header_date")}
        </TableCell>
        <TableCell sx={AppStyles.tableCellNotSelectable}>
          {intl.get("company_plan_info.invoices_list.header_number")}
        </TableCell>
        <TableCell sx={AppStyles.tableCellNotSelectable}>
          {intl.get("company_plan_info.invoices_list.header_status")}
        </TableCell>
        <TableCell sx={AppStyles.tableCellNotSelectable} align="right">
          {intl.get("company_plan_info.invoices_list.header_amount")}
        </TableCell>
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
}
