import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
export interface HeightGroup {
  height?: number;
  register: (newItem: MutableRefObject<any>) => void;
  unregister: (removedItem: MutableRefObject<any>) => void;
}

export function HeightGroupContainer({ children, heightGroup }: { children?: any; heightGroup?: HeightGroup }) {
  const ref = useRef();

  useEffect(() => {
    if (heightGroup) {
      heightGroup.register(ref);
      return () => heightGroup.unregister(ref);
    }
    return () => {};
  }, [ref.current]);

  return (
    <Box style={{ minHeight: heightGroup?.height }}>
      <Box ref={ref}>{children}</Box>
    </Box>
  );
}

export default function useHeightGroup(): HeightGroup {
  const [height, setHeight] = useState<number | undefined>();

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      const maxHeight = Math.max(...entries.map((entry) => entry.contentRect.height || 0));
      setHeight(maxHeight > 0 ? Math.round(maxHeight * 10) / 10 : undefined); // round for better performance
    }),
  );

  const register = (newItem: MutableRefObject<any>) => {
    if (newItem.current) {
      resizeObserver.current.observe(newItem.current);
    }
  };
  const unregister = (removedItem: MutableRefObject<any>) => {
    if (removedItem.current) {
      resizeObserver.current.unobserve(removedItem.current);
    }
  };

  return {
    register,
    unregister,
    height,
  };
}
