import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const HeroIcon = (props) => (
  <SvgIcon id="Ebene_1" width="22" height="20.9" viewBox="0 0 22 20.9" {...props}>
    <path
      id="Pfad_2"
      data-name="Pfad 2"
      d="M17.1,20.9a.993.993,0,0,1-.541-.16l-5.61-3.635L5.338,20.74a1,1,0,0,1-1.5-1.105L5.6,13.41.353,8.978A1,1,0,0,1,.924,7.225l6.288-.452L10.042.581a1,1,0,0,1,1.81,0l2.83,6.192,6.289.452a1,1,0,0,1,.571,1.753L16.3,13.41l1.756,6.225A1,1,0,0,1,17.1,20.9"
      transform="translate(0)"
      fill="#707cda"
    />
  </SvgIcon>
);

export default HeroIcon;
