interface Props {
  text?: string;
  center?: boolean;
  numberOfLines: number;
  breakWords?: boolean;
  fixedHeight?: boolean;
}

const inheritStyle = {
  fontSize: "inherit",
  fontFamily: "inherit",
  fontWeight: "inherit",
};

export default function TextClamper({ text, center, numberOfLines, breakWords, fixedHeight }: Props) {
  const content = (
    <span
      style={{
        ...inheritStyle,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: numberOfLines,
        WebkitBoxOrient: "vertical",
        maxWidth: "100%",
        wordBreak: breakWords ? "break-all" : "break-word",
        paddingBottom: "0.2em",
        marginBottom: "-0.2em",
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );

  if (fixedHeight) {
    return (
      <span
        style={{
          ...inheritStyle,
          minHeight: numberOfLines + "lh",
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          justifyContent: center ? "center" : undefined,
        }}
      >
        {content}
      </span>
    );
  }

  return content;
}
