import { InvoiceStatus } from "../../../model/invoice";
import { ErrorView } from "../../../components/ErrorView";
import intl from "react-intl-universal";
import { Button, Theme } from "@mui/material";
import { PaymentInfoStatus } from "../../../model/payment_info";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { PaymentState } from "../../../model/paymentState";
import { AuthenticationState } from "../../../model/authenticationState";
import { CompanyState } from "../../../model/companyState";

const classes = {
  errorButtonOutline: (theme: Theme) => ({
    boxShadow: "none",
    background: "white",
    color: theme.palette.error.main,
    borderRadius: "5px",
    border: "2px solid",
    borderColor: "white",
    "&:hover": {
      background: theme.palette.primary.light,
      borderColor: theme.palette.error.dark,
      boxShadow: "none",
    },
    textTransform: "uppercase",
    fontWeight: 600,
    "&:disabled": {
      color: theme.palette.secondary.main,
    },
  }),
};

export default function InvalidPaymentMethodOrOverdueInvoiceInfo({
  onChargeInvoicesClicked,
}: {
  onChargeInvoicesClicked: () => void;
}) {
  const paymentState = useSelector<RootState, PaymentState>((rootState: RootState) => {
    return rootState.paymentState;
  });

  const authenticationState = useSelector<RootState, AuthenticationState>((rootState: RootState) => {
    return rootState.authenticationState;
  });

  const companyState = useSelector<RootState, CompanyState>((rootState: RootState) => {
    return rootState.companyState;
  });

  if (
    paymentState.allInvoices &&
    paymentState.allInvoices.filter((value) => value.status === InvoiceStatus.Overdue).length > 0
  ) {
    const { supportEmail } = authenticationState.user;
    return (
      <>
        <ErrorView
          message={intl.getHTML("company_plan_info.overdue_invoices", {
            supportEmail,
          })}
        >
          <Button onClick={onChargeInvoicesClicked} sx={classes.errorButtonOutline}>
            {intl.get("company_plan_info.charge_overdue_invoices_button_title")}
          </Button>
        </ErrorView>
      </>
    );
  }
  if (companyState.company.salespartner_company_id) {
    // Do not show hint because payment information is managed for sales partner
    return null;
  }
  if (paymentState.paymentInfo && paymentState.paymentInfo.status === PaymentInfoStatus.INVALID) {
    return <ErrorView message={intl.get("company_plan_info.payment_method_invalid")} />;
  }
  if (paymentState.paymentInfo && paymentState.paymentInfo.status === PaymentInfoStatus.PENDING_AUTHORIZATION) {
    return <ErrorView message={intl.get("company_plan_info.payment_method_pending")} />;
  }
}
