import * as React from "react";
import CreatableSelect from "react-select/creatable";

/**
 * This is a wrapper for react-select, since we have type clashes
 * @param props
 * @constructor
 */
export default function MyCreatableSelect(props: any) {
  // @ts-ignore
  return <CreatableSelect {...props} />;
}
