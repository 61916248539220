import React from "react";
import { Grid } from "@mui/material";
import { GridSize } from "@mui/material/Grid/Grid";
import AppStyles from "../../../../styles/appStyles";

export function FormGridItem({ md, children, noTopPadding }: { md?: GridSize; children: any; noTopPadding?: boolean }) {
  return (
    <Grid
      item
      xs={12}
      md={md ?? 12}
      container
      direction={"column"}
      sx={noTopPadding === true ? AppStyles.detailFormRowNoBorderNoTopPadding : AppStyles.detailFormRowNoBorder}
    >
      {children}
    </Grid>
  );
}
