import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabLocationsIcon = (props) => (
  <SvgIcon width="15" height="21" viewBox="0 0 15 21" {...props}>
    <path
      id="Pfad_162"
      data-name="Pfad 162"
      d="M12.657,1.812A7.651,7.651,0,0,0,7.533,0,7.651,7.651,0,0,0,2.408,1.812,7.972,7.972,0,0,0,0,7.985C0,13.723,6.6,20.447,6.884,20.73a.913.913,0,0,0,1.3,0c.281-.283,6.884-7.007,6.884-12.745a7.972,7.972,0,0,0-2.408-6.173m-5.124,9.51A3.692,3.692,0,1,1,11.167,7.63a3.667,3.667,0,0,1-3.634,3.692"
    />
  </SvgIcon>
);

export default TabLocationsIcon;
