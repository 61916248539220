import { BCEvent } from "../../model/event";
import { Box, Card, Divider, Stack, SxProps, Typography } from "@mui/material";
import EventCardActionButtons from "./EventCardActionButtons";
import EventCoverImage from "./EventCoverImage";
import EventCardTypeAndStatus from "./EventCardTypeAndStatus";
import React from "react";
import EventCardBookingButtons from "./EventCardBookingButtons";
import TextClamper from "../textClamper";

interface Props {
  sx?: SxProps;
  variant: "list" | "grid";
  event: BCEvent;
  onActionClicked: EventCardActionEvent;
}

export type EventCardActionEvent = (
  event: BCEvent,
  action: "view_event" | "top_placement" | "hero" | "social_media" | "newsletter" | "transfer" | "delete",
) => void;

export default function EventCard({ sx, variant, ...contentProps }: Props) {
  return (
    <Card sx={sx}>
      {variant === "grid" && <GridEventCardContent {...contentProps} />}
      {variant === "list" && <ListEventCardContent {...contentProps} />}
    </Card>
  );
}

function GridEventCardContent({ event, onActionClicked }: Omit<Props, "onClick" | "variant" | "sx">) {
  return (
    <Stack sx={{ maxHeight: "100%", maxWidth: "100%", height: "100%" }}>
      <EventCoverImage
        event={event}
        sx={{ aspectRatio: "260 / 146" }}
        onClick={() => onActionClicked(event, "view_event")}
      />
      <Typography
        onClick={() => onActionClicked(event, "view_event")}
        sx={{
          margin: 2,
          cursor: "pointer",
          flexGrow: 1,
          font: "normal normal bold 18px/21px Manrope",
        }}
      >
        <TextClamper numberOfLines={2} text={event.title} />
      </Typography>
      <EventCardBookingButtons
        event={event}
        sx={{ paddingLeft: 2, paddingRight: 2, width: "100%" }}
        onHeroClicked={() => onActionClicked(event, "hero")}
        onTopPlacementClicked={() => onActionClicked(event, "top_placement")}
        onNewsletterClicked={() => onActionClicked(event, "newsletter")}
        onSocialMediaClicked={() => onActionClicked(event, "social_media")}
      />
      <EventCardTypeAndStatus sx={{ marginLeft: 2, marginRight: 2, marginBottom: 2, marginTop: 3 }} event={event} />
      <Divider />
      <EventCardActionButtons
        event={event}
        direction={"row"}
        sx={{ padding: 1 }}
        onDeleteEvent={() => onActionClicked(event, "delete")}
        onTransferEvent={() => onActionClicked(event, "transfer")}
      />
    </Stack>
  );
}

function ListEventCardContent({ event, onActionClicked }: Omit<Props, "onClick" | "variant" | "sx">) {
  const itemHeight = "122px";

  return (
    <Stack direction={"row"} sx={{ width: "100%" }} alignItems={"stretch"}>
      <EventCoverImage
        onClick={() => onActionClicked(event, "view_event")}
        event={event}
        sx={{ aspectRatio: "217 / 122", minWidth: "217px", minHeight: "100%", maxHeight: itemHeight }}
      />
      <Typography
        onClick={() => onActionClicked(event, "view_event")}
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
          flexGrow: 1,
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontWeight: 800,
          font: "normal normal bold 18px/21px Manrope",
        }}
      >
        <TextClamper numberOfLines={2} text={event.title} />
      </Typography>

      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <EventCardTypeAndStatus event={event} sx={{ padding: 2, minWidth: 260 }} />
      </Box>

      <EventCardBookingButtons
        event={event}
        sx={{ marginLeft: 2, marginRight: 2, maxWidth: "228px", paddingTop: 2, paddingBottom: 2 }}
        onHeroClicked={() => onActionClicked(event, "hero")}
        onTopPlacementClicked={() => onActionClicked(event, "top_placement")}
        onNewsletterClicked={() => onActionClicked(event, "newsletter")}
        onSocialMediaClicked={() => onActionClicked(event, "social_media")}
      />
      <Divider orientation={"vertical"} flexItem />
      <EventCardActionButtons
        event={event}
        size={"small"}
        direction={"column"}
        onDeleteEvent={() => onActionClicked(event, "delete")}
        onTransferEvent={() => onActionClicked(event, "transfer")}
        sx={{ height: "100%", padding: 1 }}
      />
    </Stack>
  );
}
