import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";

const moment = require("moment");

export const loadHeroBookingData = (company_id: string, event_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_HERO_FORM });

  try {
    const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);
    const bookedSlotsResponse = await axios.get(
      `/v2/api/b2b/private/companies/${company_id}/events/${event_id}/hero_slots`,
    );
    const heroBalance = (creditsResponse.data.credits as any[]).find((value) => value.credit_type === "hero");

    dispatch({
      type: ActionType.LOAD_HERO_FORM,
      payload: {
        booked_slots: bookedSlotsResponse.data,
        available_credits: heroBalance.count,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const loadAvailableHeroSlots = (from: Date, to: Date) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_AVAILABLE_HERO_SLOTS });

  try {
    const availableSlotsResponse = await axios.get(
      `/v2/api/b2b/private/hero_slots?from=${moment(from).toISOString()}&to=${moment(to).toISOString()}`,
    );

    dispatch({
      type: ActionType.LOAD_AVAILABLE_HERO_SLOTS,
      payload: {
        available_slots: availableSlotsResponse.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const bookSlots = (company_id: string, event_id: string, slot_ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const bookedSlotsResponse = await axios.post(
      `/v2/api/b2b/private/companies/${company_id}/events/${event_id}/hero_slots`,
      { slot_ids },
    );
    const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);
    const availableSlotsResponse = await axios.get(`/v2/api/b2b/private/hero_slots`);
    const heroBalance = (creditsResponse.data.credits as any[]).find((value) => value.credit_type === "hero");

    dispatch({
      type: ActionType.LOAD_HERO_FORM,
      payload: {
        available_slots: availableSlotsResponse.data,
        booked_slots: bookedSlotsResponse.data,
        available_credits: heroBalance.count,
      },
    });
    return true;
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
    return false;
  }
};
