import * as intl from "react-intl-universal";

export interface Error {
  originalError?: string | any | null;
  statusCode?: number | null;
  response?: any | null;
  error_code?: string | null;
  validationErrors?: ValidationError[] | null;
  errorMessage: string | null;
}

export function errorMessageFromError(error?: Error | null, defaultErrorMessage?: string | null): string | null {
  if (error != null && error.error_code) {
    const errorMessage = intl.get(`error.code.${error.error_code}`);
    if (errorMessage == null || errorMessage.isEmpty()) {
      if (defaultErrorMessage != null) {
        return `${defaultErrorMessage} (${error.error_code})`;
      }
      return error.error_code;
    }
    return errorMessage;
  }
  if (error != null && error.errorMessage != null) {
    return error.errorMessage;
  }
  if (error != null && defaultErrorMessage) {
    return defaultErrorMessage;
  }
  if (error != null) {
    return intl.get("error.generic");
  }
  return null;
}

export class ValidationError {
  field: string;

  expectedFormat?: string | null;

  keyword?: string | null;
}

export function mapErrorFromException(exception: any): Error {
  let statusCode = null;
  if (exception.response) {
    statusCode = exception.response.status;
  }
  let errorCode = null;
  if (exception.response && exception.response.data) {
    errorCode = exception.response.data.error_code;
  }
  let serverError = null;
  let validationErrors = null;
  if (exception.response && exception.response.data) {
    serverError = exception.response.data.error;

    // e.g. {"status":"ERROR_INVALID_INPUT","validations":[{"field":"website_url","expectedFormat":"url
    if (exception.response.data.validations) {
      for (const v of exception.response.data.validations) {
        const validationError = {
          field: v.field,
          expectedFormat: v.expectedFormat,
          keyword: v.keyword,
        };
        if (validationErrors == null) {
          validationErrors = [];
        }
        validationErrors.push(validationError);
      }
    }
  }

  return {
    originalError: serverError,
    statusCode,
    error_code: errorCode,
    response: exception.response,
    validationErrors,
    errorMessage: null,
  };
}
