import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";

export default function DialogMarkAsPaidConfirmation({
  open,
  onClose,
  invoice_number,
  onMarkAsPaid,
}: {
  open: boolean;
  onClose: () => void;
  invoice_number: string;
  onMarkAsPaid: () => void;
}) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.get("global_invoices.mark_as_paid_confirmation_dialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.get(`global_invoices.mark_as_paid_confirmation_dialog.message`, { invoice_number })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.get("global_invoices.mark_as_paid_confirmation_dialog.cancel_button")}
        </Button>
        <Button onClick={onMarkAsPaid} color="primary" autoFocus>
          {intl.get("global_invoices.mark_as_paid_confirmation_dialog.mark_as_paid_button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
