import { TableCell } from "@mui/material";
import AppStyles from "../../styles/appStyles";

export default function TableHeaderCell({
  text,
  align,
}: {
  text: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}) {
  return (
    <TableCell align={align} padding="none" sx={AppStyles.tableHeaderCell}>
      {text}
    </TableCell>
  );
}
