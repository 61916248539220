import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { ResetPasswordState } from "../model/user";
import * as AuthActions from "../actions/auth";
import { RootState } from "../reducers";
import { ErrorView } from "../components";
import { DefaultButton } from "../components/Buttons";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { PageTracker } from "../util/pageTracker";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AppStyles from "../styles/appStyles";
import StringUtils from "../util/StringUtils";

const classes = {
  subTitle: (theme: Theme) => ({
    fontWeight: "bold",
    fontSize: theme.fontSizes.largeFont,
    marginBottom: "10px",
  }),
};

export interface Props {
  resetPasswordState: ResetPasswordState;
  actions: typeof AuthActions;
}

export interface State {
  password: string;
  passwordRepeat: string;
  resetCode: string;
}

function ResetPasswordPage(props: Props) {
  const [state, setState] = useState({
    password: "",
    passwordRepeat: "",
    resetCode: "",
  });

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setState({ ...state, resetCode: params.resetCode });
    props.resetPasswordState.triggered = false;
    return () => {
      props.resetPasswordState.triggered = false;
    };
  }, []);

  const handleChange = (name: string) => (event: any) => {
    props.resetPasswordState.error = null;
    setState({ ...state, [name]: event.target.value });
  };

  const resetPassword = () => {
    props.actions.resetPassword(state.password, state.resetCode);
  };

  const passwordsInvalid = () => {
    return (
      !StringUtils.isNullOrEmpty(state.password) &&
      !StringUtils.isNullOrEmpty(state.passwordRepeat) &&
      state.passwordRepeat !== state.password
    );
  };

  const resetPossible = () => {
    return (
      !StringUtils.isNullOrEmpty(state.password) &&
      !StringUtils.isNullOrEmpty(state.passwordRepeat) &&
      state.passwordRepeat === state.password
    );
  };

  const error = props.resetPasswordState != null ? props.resetPasswordState.error : null;
  if (error == null && props.resetPasswordState.triggered) {
    return (
      <Box component={"main"} sx={AppStyles.centerCardLayout}>
        <PageTracker />
        <Paper sx={AppStyles.centerPaper}>
          <Grid style={{ width: "100%" }}>
            <HeaderLabel>{intl.get("resetPassword.title")}</HeaderLabel>
            <Typography sx={classes.subTitle}>{intl.get("resetPassword.done")}</Typography>
          </Grid>

          <Grid container sx={AppStyles.section} direction="row" justifyContent="flex-end" alignItems={"flex-end"}>
            <DefaultButton
              style={{ paddingLeft: 50, paddingRight: 50 }}
              sx={{ flexGrow: 1 }}
              onClick={() => {
                navigate("/login");
              }}
              title={intl.get("signup.jump_to_login")}
            />
          </Grid>
        </Paper>
      </Box>
    );
  }
  return (
    <Box component={"main"} sx={AppStyles.centerCardLayout}>
      <PageTracker />
      <Paper sx={AppStyles.centerPaper}>
        <Grid style={{ width: "100%" }}>
          <HeaderLabel>{intl.get("resetPassword.title")}</HeaderLabel>
          <Typography sx={classes.subTitle}>{intl.get("resetPassword.subTitle")}</Typography>
        </Grid>

        <Box sx={AppStyles.appForm}>
          <ErrorView error={error} defaultErrorMessage={intl.get("error.generic")} />

          <Grid item sx={AppStyles.section} container direction="row">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Grid item container sx={AppStyles.section} direction="column">
                  <TextFieldWithLabel
                    label={intl.get("label.password")}
                    placeholder={intl.get("label.password")}
                    id="password-input"
                    type="password"
                    autoComplete="current-password"
                    onChange={handleChange("password")}
                    margin="normal"
                    error={passwordsInvalid()}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item container sx={AppStyles.section} direction="column">
                  <TextFieldWithLabel
                    label={intl.get("label.password_repeat")}
                    placeholder={intl.get("label.password_repeat")}
                    id="password-repeat-input"
                    type="password"
                    autoComplete="current-password"
                    onChange={handleChange("passwordRepeat")}
                    margin="normal"
                    error={passwordsInvalid()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={AppStyles.section} direction="row" justifyContent="flex-end" alignItems={"flex-end"}>
            <DefaultButton
              style={{ paddingLeft: 50, paddingRight: 50 }}
              sx={{ flexGrow: 1 }}
              onClick={resetPassword}
              title={intl.get("resetPassword.button")}
              disabled={!resetPossible()}
            />
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

function authMapStateToProps(state: RootState) {
  return {
    resetPasswordState: state.resetPasswordState,
  };
}

function authMapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default connect(authMapStateToProps, authMapDispatchToProps)(ResetPasswordPage);
