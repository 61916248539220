import isValid from "date-fns/isValid";
import startOfQuarter from "date-fns/startOfQuarter";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { IconButton, InputAdornment } from "@mui/material";
import {
  addMonths,
  addQuarters,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getQuarter,
  getYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import { addYears } from "date-fns/esm";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DesktopDatePickerSlotsComponentsProps } from "@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker.types";

function enforceDateBoundaries(dateToCheck: Date, minDate: Date, maxDate: Date) {
  if (dateToCheck.getTime() > maxDate.getTime()) {
    return new Date(maxDate);
  }
  if (dateToCheck.getTime() < minDate.getTime()) {
    return new Date(minDate);
  }
  return dateToCheck;
}

function isDateAllowed(dateToCheck: Date, minDate: Date, maxDate: Date) {
  if (dateToCheck.getTime() > maxDate.getTime()) {
    return false;
  } else if (dateToCheck.getTime() < minDate.getTime()) {
    return false;
  }
  return true;
}

export const MonthDatePicker = (props) => {
  const minSelectableDate = new Date(2017, 0, 1);
  const maxSelectableDate = startOfMonth(new Date());

  const [date, setDate] = React.useState(
    enforceDateBoundaries(props.startDate ? props.startDate : new Date(), minSelectableDate, maxSelectableDate),
  );

  const handleSelectedMonthChange = (newDate: any) => {
    if (isDateAllowed(newDate, minSelectableDate, maxSelectableDate)) {
      setDate(newDate);
      props.onChange({ startDate: startOfMonth(newDate), endDate: endOfMonth(newDate) });
    } else {
      setDate(date);
    }
  };

  const handleMonthIncrement = () => {
    handleSelectedMonthChange(addMonths(new Date(date), 1));
  };

  const handleMonthDecrement = () => {
    handleSelectedMonthChange(subMonths(new Date(date), 1));
  };

  const formatMonthLabel = (aDate, invalidLabel) => {
    const dateClone = new Date(aDate);

    return dateClone && isValid(dateClone) ? format(dateClone, "MMMM yyyy") : invalidLabel;
  };

  return (
    <DesktopDatePicker
      value={date}
      sx={{
        "& .MuiInput-input": { textAlign: "center" },
      }}
      onChange={handleSelectedMonthChange}
      views={["year", "month"]}
      openTo="month"
      disableFuture
      minDate={minSelectableDate}
      maxDate={maxSelectableDate}
      slotProps={getSlotProps(handleMonthIncrement, handleMonthDecrement, formatMonthLabel(date, "error"))}
    />
  );
};

export const QuarterDatePicker = (props) => {
  const minSelectableDate = new Date(2017, 0, 1);
  const maxSelectableDate = subQuarters(endOfQuarter(new Date()), 1);

  const [date, setDate] = React.useState(
    enforceDateBoundaries(props.startDate ? props.startDate : new Date(), minSelectableDate, maxSelectableDate),
  );

  const handleSelectedQuarterChange = (newDate: any) => {
    if (isDateAllowed(newDate, minSelectableDate, maxSelectableDate)) {
      setDate(newDate);
      props.onChange({ startDate: startOfQuarter(newDate), endDate: endOfQuarter(newDate) });
    } else {
      setDate(date);
    }
  };

  const handleQuarterIncrement = () => {
    if (date) {
      handleSelectedQuarterChange(addQuarters(date, 1));
    }
  };

  const handleQuarterDecrement = () => {
    if (date) {
      handleSelectedQuarterChange(subQuarters(date, 1));
    }
  };

  const formatQuarterSelectLabel = (aDate, invalidLabel) => {
    const dateClone = new Date(aDate);

    return dateClone && isValid(dateClone) ? `Q ${getQuarter(dateClone)} ${getYear(dateClone)}` : invalidLabel;
  };

  return (
    <DesktopDatePicker
      value={date}
      onChange={handleSelectedQuarterChange}
      sx={{
        "& .MuiInput-input": { textAlign: "center" },
      }}
      views={["year"]}
      openTo="month"
      minDate={minSelectableDate}
      maxDate={maxSelectableDate}
      slotProps={getSlotProps(handleQuarterIncrement, handleQuarterDecrement, formatQuarterSelectLabel(date, "error"))}
    />
  );
};

export const YearDatePicker = (props) => {
  const minSelectableDate = new Date(2017, 0, 1);
  const maxSelectableDate = endOfYear(new Date());

  const [date, setDate] = React.useState(
    enforceDateBoundaries(props.startDate ? props.startDate : new Date(), minSelectableDate, maxSelectableDate),
  );

  const handleSelectedYearChange = (newDate: any) => {
    if (isDateAllowed(newDate, minSelectableDate, maxSelectableDate)) {
      setDate(newDate);
      props.onChange({ startDate: startOfYear(newDate), endDate: endOfYear(newDate) });
    } else {
      setDate(date);
    }
  };

  const handleIncrement = () => {
    if (date) {
      handleSelectedYearChange(addYears(date, 1));
    }
  };

  const handleDecrement = () => {
    if (date) {
      handleSelectedYearChange(subYears(date, 1));
    }
  };

  const formatYearLabel = (aDate, invalidLabel) => {
    const dateClone = new Date(aDate);

    return dateClone && isValid(dateClone) ? getYear(dateClone) : invalidLabel;
  };

  return (
    <DesktopDatePicker
      value={date}
      onChange={handleSelectedYearChange}
      sx={{
        "& .MuiInput-input": { textAlign: "center" },
      }}
      views={["year"]}
      openTo="year"
      minDate={minSelectableDate}
      maxDate={maxSelectableDate}
      slotProps={getSlotProps(handleIncrement, handleDecrement, formatYearLabel(date, "error"))}
    />
  );
};

function getSlotProps(
  handleIncrement: () => void,
  handleDecrement: () => void,
  overrideValue?: string,
): DesktopDatePickerSlotsComponentsProps<any> {
  return {
    textField: {
      variant: "standard",
      InputProps: {
        value: overrideValue,
        disableUnderline: true,
        fullWidth: true,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleDecrement} size="large">
              <ArrowBackIosIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIncrement} size="large">
              <ArrowForwardIosIcon />
            </IconButton>
          </InputAdornment>
        ),
      },
    },
  };
}
