import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabTopPlacementIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g id="Gruppe_550" data-name="Gruppe 550">
      <path
        id="Pfad_153"
        data-name="Pfad 153"
        d="M11.249,59.8c-1.1,1.1-1.142,3.3-1.14,3.724a.968.968,0,0,0,.951.966h.031c.492,0,2.606-.08,3.681-1.154a2.552,2.552,0,0,0,.821-1.824,2.5,2.5,0,0,0-.77-1.76,2.427,2.427,0,0,0-3.574.047"
        transform="translate(-7.691 -44.882)"
      />
      <path
        id="Pfad_154"
        data-name="Pfad 154"
        d="M21.94.923A.956.956,0,0,0,21.061.05,14.326,14.326,0,0,0,7.984,6.194,5.574,5.574,0,0,0,3.451,6.7,8.363,8.363,0,0,0,.091,10.443.956.956,0,0,0,1.4,11.7a3.637,3.637,0,0,1,4.291.379L9.91,16.288c.008.008.013.018.021.026A3.624,3.624,0,0,1,10.31,20.6a.957.957,0,0,0,1.253,1.313,8.369,8.369,0,0,0,3.747-3.356,5.557,5.557,0,0,0,.5-4.557,16.288,16.288,0,0,0,2.871-2.608A13.736,13.736,0,0,0,21.94.923M17.023,7.184a1.556,1.556,0,1,1,.454-1.1,1.569,1.569,0,0,1-.454,1.1"
      />
    </g>
  </SvgIcon>
);

export default TabTopPlacementIcon;
