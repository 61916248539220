import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { SearchState } from "../model/searchState";

export const searchState = createReducer(
  {
    search_query: "",
    companies: null,
    deleted_companies: null,
    events: null,
    performing_search: false,
    error: null,
    additional_company_count: null,
    additional_deleted_company_count: null,
    additional_event_count: null,
  },
  {
    [ActionType.UPDATE_SEARCH_QUERY](state: SearchState, action: Action<any>) {
      return {
        ...state,
        search_query: action.payload,
        performing_search: false,
        additional_company_count: null,
        additional_deleted_company_count: null,
        additional_event_count: null,
      };
    },
    [ActionType.SEARCH_START](state: SearchState) {
      return {
        ...state,
        performing_search: true,
        error: null,
        companies: null,
        deleted_companies: null,
        events: null,
        additional_company_count: null,
        additional_deleted_company_count: null,
        additional_event_count: null,
      };
    },
    [ActionType.SEARCH_FAILED](state: SearchState, action: Action<any>) {
      if (action.payload.query === state.search_query) {
        return {
          ...state,
          performing_search: false,
          error: action.payload.error,
          companies: null,
          deleted_companies: null,
          events: null,
          additional_company_count: null,
          additional_deleted_company_count: null,
          additional_event_count: null,
        };
      }
      return state;
    },
    [ActionType.SEARCH_FINISHED](state: SearchState, action: Action<any>) {
      if (action.payload.query === state.search_query) {
        return {
          ...state,
          performing_search: false,
          error: null,
          companies: action.payload.response.companies,
          deleted_companies: action.payload.response.deleted_companies,
          events: action.payload.response.events,
          additional_company_count: action.payload.response.additional_company_count,
          additional_deleted_company_count: action.payload.response.additional_deleted_company_count,
          additional_event_count: action.payload.response.additional_event_count,
        };
      }
      return state;
    },
  },
);
