import { Box, Grid, Link } from "@mui/material";
import intl from "react-intl-universal";
import AppStyles from "../../styles/appStyles";
import TextClamper from "../textClamper";
import { HeightGroupContainer, HeightGroup } from "../../hooks/useHeightGroup";
import { LoadingButton } from "@mui/lab";

export default function ProductInformationCard({
  title,
  text,
  icon,
  onButtonClick,
  titleHeightGroup,
  buttonText,
  disabled,
  loading,
  buttonVariant,
  highlight,
  active,
  showPrimaryLineGradient,
}: {
  title: string;
  text: string;
  icon: any;
  onButtonClick?: () => void;
  titleHeightGroup?: HeightGroup;
  buttonText?: string;
  disabled?: boolean;
  loading?: boolean;
  buttonVariant?: "contained" | "outlined" | "text";
  highlight?: boolean;
  active?: boolean;
  showPrimaryLineGradient?: boolean;
}) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: active ? theme.palette.primary.main : theme.palette.primary.light,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: "40px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRadius: "5px",
        color: active ? "white" : undefined,
        border: highlight ? `3px solid ${theme.palette.secondary.main}` : undefined,
        margin: highlight ? "-3px" : undefined,
        background: !showPrimaryLineGradient
          ? undefined
          : "repeating-linear-gradient(45deg, #6E7CE0,#6E7CE0 10px, #8b96e6 10px, #8b96e6 20px)",
      })}
    >
      <Grid item container sx={{ height: "35px" }} justifyContent={"center"} alignItems={"center"}>
        {icon}
      </Grid>
      <Grid
        item
        sx={(theme) => ({
          ...AppStyles.productInfoCardTitle(theme),
          color: active ? "white" : undefined,
        })}
      >
        <HeightGroupContainer heightGroup={titleHeightGroup}>
          <TextClamper numberOfLines={2} text={title} />
        </HeightGroupContainer>
      </Grid>
      <Grid
        item
        sx={(theme) => ({
          ...AppStyles.productInfoCardText(theme),
          flexGrow: 1,
          minHeight: "50px",
        })}
      >
        {text}
      </Grid>
      <ConditionalLinkWrapper condition={!onButtonClick} href={"https://blue-cherries.com/pakete-preise"}>
        <LoadingButton
          variant={buttonVariant || "contained"}
          onClick={onButtonClick}
          disabled={disabled}
          loading={loading}
          color={active ? "primaryInverse" : "primary"}
          size={"large"}
          sx={{ width: "100%", wordBreak: "break-word", marginTop: "32px" }}
        >
          {buttonText || intl.get("product_information.more_info_button")}
        </LoadingButton>
      </ConditionalLinkWrapper>
    </Box>
  );
}

function ConditionalLinkWrapper({ condition, children, href }: { condition: boolean; children?: any; href: string }) {
  if (condition) {
    return (
      <Link href={href} target={"_blank"}>
        {children}
      </Link>
    );
  }
  return children;
}
