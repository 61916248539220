import { useEffect } from "react";
import { DialogHandler } from "./useDialogHandler";

export default function useDialogReset(handler: DialogHandler<any>, resetFunction: () => void) {
  useEffect(() => {
    if (handler.isOpen) {
      resetFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler.isOpen]);
}
