import intl from "react-intl-universal";
import { Button, Stack, Typography } from "@mui/material";
import { formattedProductConfirmationMessage } from "../../model/payment_info";
import * as React from "react";
import BookingDrawerLoadingIndicator from "./components/BookingDrawerLoadingIndicator";
import usePaymentProduct from "../../hooks/usePaymentProduct";
import BookingButtonAndHint from "../BookingButtonAndHint";

interface Props {
  productSku: string;
  amount?: number;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
}

export default function PurchaseConfirmationDrawerPage({ productSku, amount, onConfirm, onCancel, loading }: Props) {
  const { selectedProduct, loading: productsLoading } = usePaymentProduct(productSku);

  const isSubscription = selectedProduct?.type === "subscription";

  if (!selectedProduct && productsLoading) {
    return <BookingDrawerLoadingIndicator />;
  }

  if (!selectedProduct) {
    return (
      <Stack>
        <Typography variant={"body2"} color={"error"}>
          {intl.get("error.generic")}
        </Typography>
        <Button
          onClick={onCancel}
          variant={"outlined"}
          color={"defaultTextColor"}
          disabled={loading}
          sx={{ marginTop: "21px", marginRight: "auto" }}
        >
          {intl.get("generic.btn_back")}
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack direction={"row"} alignItems={"baseline"} gap={"7px"}>
        <Typography sx={{ font: "normal normal bold 18px/21px Manrope" }}>
          {intl.get("booking_drawer.purchase_confirmation.title", { productName: selectedProduct.name })}
        </Typography>
      </Stack>

      <Typography
        variant={"body2"}
        sx={(theme) => ({ marginBottom: "26px", marginTop: "15px", "& b": { color: theme.palette.primary.main } })}
        dangerouslySetInnerHTML={{
          __html: formattedProductConfirmationMessage(selectedProduct, amount),
        }}
      ></Typography>

      <Stack direction={"row"} gap={"6px"}>
        <BookingButtonAndHint
          onPerformBooking={onConfirm}
          buttonLabel={intl.get(
            isSubscription
              ? "booking_drawer.purchase_confirmation.button_confirm_subscription"
              : "booking_drawer.purchase_confirmation.button_confirm",
          )}
          loading={loading}
          variant={"contained"}
        />

        <Button onClick={onCancel} variant={"outlined"} color={"defaultTextColor"} disabled={loading}>
          {intl.get("booking_drawer.purchase_confirmation.button_cancel")}
        </Button>
      </Stack>
    </Stack>
  );
}
