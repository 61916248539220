import createReducer from "./createReducer";
import { cloneDefaultState } from "../util/util_extensions";
import { Action, ActionType } from "../model/actions";
import { EventHeroSlotState } from "../model/event.hero.slot.state";

const defaultState = {
  available_slots: null,
  booked_slots: null,
  available_credits: null,
  loaded: false,
  loaded_available_slots: false,
};
export const heroSlotState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_HERO_FORM]() {
    return cloneDefaultState(defaultState);
  },
  [ActionType.LOAD_HERO_FORM](state: EventHeroSlotState, action: Action<any>) {
    return {
      ...state,
      loaded: true,
      available_credits: action.payload.available_credits,
      booked_slots: action.payload.booked_slots,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionType.RESET_AVAILABLE_HERO_SLOTS](state: EventHeroSlotState, action: Action<any>) {
    return {
      ...state,
      loaded_available_slots: false,
      available_slots: [],
    };
  },
  [ActionType.LOAD_AVAILABLE_HERO_SLOTS](state: EventHeroSlotState, action: Action<any>) {
    return {
      ...state,
      loaded_available_slots: true,
      available_slots: action.payload.available_slots,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionType.BOOK_HERO](state: EventHeroSlotState, action: Action<any>) {
    return state;
  },
});
