import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Check";
import CompanyEditForm from "../components/forms/CompanyEditForm";
import { CompanyFormState } from "../../model/companyFormState";
import { RootState } from "../../reducers";
import * as CompanyActions from "../../actions/company";
import { BackButton, DefaultButton, DeleteButton } from "../../components/Buttons";
import { AuthenticationState } from "../../model/authenticationState";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import AppStyles from "../../styles/appStyles";

export interface Props {
  companyFormState: CompanyFormState;
  authState: AuthenticationState;
  actions: typeof CompanyActions;
}

export interface State {
  deleteCompanyConfirmationVisible: boolean;
}

function CompanyEditPage(props: Props) {
  const [state, setState] = useState({
    supportBackAction: false,
    deleteCompanyConfirmationVisible: false,
    closing: false,
  });
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);

  const navigate = useNavigate();

  function closePage(): void {
    navigate(-1);
  }

  function deleteCompany(): void {
    if (props.companyFormState.company_id != null) {
      props.actions.deleteCompany(props.companyFormState.company_id);
      setState({ ...state, deleteCompanyConfirmationVisible: false });
    }
  }

  function confirmCompanyDeletion() {
    setState({ ...state, deleteCompanyConfirmationVisible: true });
  }

  function cancelCompanyDeletion() {
    setState({ ...state, deleteCompanyConfirmationVisible: false });
  }

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (params == null) {
      props.actions.createNewCompany(props.authState.user.role !== null && props.authState.user.role !== "RESELLER");
      setState({ ...state, supportBackAction: true });
    } else {
      const companyId = params.company_id;

      if (companyId) {
        setState({
          ...state,
          supportBackAction: location.pathname.endsWith("/edit"),
        });
        props.actions.editCompany(
          companyId,
          props.authState.user.role !== null && props.authState.user.role !== "RESELLER",
        );
      } else {
        setState({ ...state, supportBackAction: true });
        props.actions.createNewCompany(props.authState.user.role !== null && props.authState.user.role !== "RESELLER");
      }
    }

    if (props.authState.user.role === "ADMIN") {
      props.actions.fetchSalesPartners();
    }
  }, []);

  function saveChanges(): void {
    props.actions.saveCompany(props.companyFormState.company_id, props.companyFormState.company, () => {
      setShowSaveSuccess(true);
    });
  }

  if (state.closing === true) {
    return <div />;
  }
  if (props.companyFormState.closePage && state.supportBackAction) {
    props.actions.resetCompanyForm();
    props.actions.closeCompany();
    closePage();
    setState({ ...state, closing: true });
    return <div />;
  }

  const { company } = props.companyFormState;
  let showReadonly = false;
  const canDeleteCompany = props.authState.user.permissions.indexOf("MANAGE_COMPANIES") !== -1;
  if (company != null && company.permissions != null) {
    showReadonly = company.permissions.indexOf("EDIT_COMPANY_INFO") === -1;
  }
  const disableSave = showReadonly || !props.companyFormState.valid;

  return (
    <>
      <PageTracker />

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showSaveSuccess}
        autoHideDuration={2000}
        onClose={() => {
          setShowSaveSuccess(false);
        }}
      >
        <Alert severity="success">{intl.get("company.company_saved_confirmation")}</Alert>
      </Snackbar>

      <CompanyEditForm
        readonly={showReadonly}
        showResellerSelection={
          props.authState.user.role &&
          props.authState.user.role !== "RESELLER" &&
          props.authState.user.role !== "INTERNAL_SALES_MANAGER"
        }
      >
        <Grid container direction="row">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.actionHeaderLeft}>
              <BackButton
                title={
                  props.companyFormState.company_id
                    ? intl.get("company_form.title_edit")
                    : intl.get("company_form.title_create")
                }
                onClick={() => {
                  closePage();
                }}
              />
            </Grid>

            <Grid item sx={AppStyles.headerRight}>
              {props.companyFormState.company_id && canDeleteCompany && (
                <DeleteButton
                  disabled={showReadonly}
                  onClick={() => {
                    confirmCompanyDeletion();
                  }}
                >
                  <ClearIcon sx={AppStyles.buttonLeftIcon} />
                  {intl.get("company_form.button_delete")}
                </DeleteButton>
              )}

              <DefaultButton
                style={{
                  marginLeft: props.companyFormState.company_id && canDeleteCompany ? 10 : 0,
                  height: 40,
                }}
                disabled={disableSave}
                onClick={() => {
                  saveChanges();
                }}
              >
                <SaveIcon sx={AppStyles.buttonLeftIcon} />
                {props.companyFormState.company_id
                  ? intl.get("company_form.button_save")
                  : intl.get("company_form.button_create")}
              </DefaultButton>
            </Grid>
          </Grid>
        </Grid>
      </CompanyEditForm>
      {state.deleteCompanyConfirmationVisible && (
        <Dialog
          open={state.deleteCompanyConfirmationVisible}
          onClose={() => cancelCompanyDeletion()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.get("company.delete_confirmation.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.get("company.delete_confirmation.message", {
                company_title: props.companyFormState.company.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelCompanyDeletion()} color="primary">
              {intl.get("company.delete_confirmation.button_cancel")}
            </Button>
            <Button onClick={() => deleteCompany()} color="error" autoFocus>
              {intl.get("company.delete_confirmation.button_confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyFormState: state.companyFormState,
    authState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyEditPage);
