import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import * as intl from "react-intl-universal";
import * as React from "react";
import { EventFormState } from "../../../../model/eventFormState";
import EventDedicatedTimeEdit from "./EventDedicatedTimeEdit";
import { LinkButton } from "../../../../components/Buttons";
import { theme } from "../../../../theme";
import AppStyles from "../../../../styles/appStyles";
import * as EventFormActions from "../../../../actions/eventForm";

const classes = {
  dateCell: {
    padding: 0,
    width: "135px",
  },
  timeCell: {
    padding: 0,
    width: "100px",
  },
};

export interface BaseProps {
  eventFormState: EventFormState;
  eventFormActions: typeof EventFormActions;
  removeDedicatedTime: any;
  addDedicatedTime: any;
}

export interface Props extends BaseProps {}

class EventDedicatedTimes extends React.Component<Props> {
  render() {
    const disableControls = this.props.eventFormState.readonly;

    return (
      <Grid item container direction="row" sx={AppStyles.detailContent}>
        <Grid item xs={12} sx={AppStyles.detailContentWithPadding}>
          <Typography component="h5" variant="h5" color="secondary" style={{ fontSize: theme.fontSizes.smallFont }}>
            {intl.get("event_form.dedicated_event.description")}
          </Typography>
        </Grid>

        <Table style={{ marginTop: 10 }}>
          <TableHead>
            <TableRow sx={AppStyles.smallTableHeader}>
              <TableCell sx={classes.dateCell} padding="none" align="left" style={{ paddingLeft: 25 }}>
                <Typography
                  component="h5"
                  variant="h5"
                  color="secondary"
                  style={{ fontSize: theme.fontSizes.largeFont }}
                >
                  {intl.get("event_form.dedicated_event.dedicated_date")}
                </Typography>
              </TableCell>
              <TableCell sx={classes.timeCell} padding="none" align="left">
                <Typography
                  component="h5"
                  variant="h5"
                  color="secondary"
                  style={{ fontSize: theme.fontSizes.largeFont }}
                >
                  {intl.get("event_form.dedicated_event.dedicated_timerange")}
                </Typography>
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.eventFormState.event.dedicated_times != null &&
              this.props.eventFormState.event.dedicated_times.map((dedicated_time) => {
                return (
                  <TableRow key={dedicated_time.event_dedicated_time_id}>
                    <EventDedicatedTimeEdit
                      eventFormActions={this.props.eventFormActions}
                      disabled={disableControls}
                      dedicatedTime={dedicated_time}
                      onRemove={() => {
                        this.props.removeDedicatedTime(dedicated_time);
                      }}
                    />
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {disableControls === false && (
          <Grid item container xs={12} sx={AppStyles.detailContentWithPadding}>
            <LinkButton
              onClick={(event: any) => {
                event.stopPropagation();
                this.props.addDedicatedTime();
              }}
              title={intl.get("event_form.button_add_dedicated_time")}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default EventDedicatedTimes;
