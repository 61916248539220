import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";
import { BillingTypeUtil } from "../model/payment_info";
import { mapGrantedCredits, PaymentProduct } from "../model/payment/payment.product";

export const fetchAllPaymentProducts = (page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/payment_products?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_PAYMENTPLANS,
      payload: {
        data: true,
        products: res.data.products,
        paging: res.data.paging,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const deletePaymentProduct = (payment_product_id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.DELETE_PAYMENTPLAN,
    payload: { loading: true },
  });
  try {
    await axios.delete(`/v2/api/b2b/private/payment_products/${payment_product_id}`);

    dispatch({
      type: ActionType.DELETE_PAYMENTPLAN,
      payload: { data: true },
    });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const createNewPaymentProduct = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_PAYMENTPLAN_FORM });

  const { companies } = (await axios.get("/v2/api/b2b/private/companies?minimized=true")).data;

  const res = await axios.get(`/v2/api/b2b/private/payment_products/sku_groups`);

  dispatch({
    type: ActionType.CREATE_PAYMENTPLAN,
    payload: { companies, skuGroups: res.data.skuGroups },
  });
};

export const editPaymentProduct = (product_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_PAYMENTPLAN_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/payment_products/${product_id}`);
    const { companies } = (await axios.get("/v2/api/b2b/private/companies?minimized=true&includeDeleted=true")).data;

    const product = res.data.product;
    const credits = mapGrantedCredits(product.granted_credits);
    const heroCredits = credits.get("hero");
    if (heroCredits) {
      product.hero_credits = heroCredits;
    } else {
      product.hero_credits = 0;
    }
    const socialCredits = credits.get("social");
    if (socialCredits) {
      product.social_credits = socialCredits;
    } else {
      product.social_credits = 0;
    }
    const faiCredits = credits.get("fai_newsletter");
    if (faiCredits) {
      product.fai_credits = faiCredits;
    } else {
      product.fai_credits = 0;
    }

    dispatch({
      type: ActionType.EDIT_PAYMENTPLAN,
      payload: {
        data: true,
        product: product,
        skuGroups: res.data.skuGroups,
        product_id: product.product_id,
        companies,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const savePaymentProduct =
  (product: PaymentProduct, product_id?: string | null) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    const dataToSend = { ...product };
    if (dataToSend.company_id != null) {
      delete dataToSend.country; // is set by backend
    }

    // set billing interval unit to match billing type
    dataToSend.billing_interval_unit = BillingTypeUtil.toBillingIntervalUnit(
      BillingTypeUtil.fromString(dataToSend.billing_type),
      dataToSend.type,
    );
    if (dataToSend.type === "credit") {
      dataToSend.billing_interval_value = 1;
    }

    const creditEntries: string[] = [];
    if (dataToSend.hero_credits && dataToSend.hero_credits > 0) {
      creditEntries.push(`hero=${dataToSend.hero_credits}`);
    }
    if (dataToSend.social_credits && dataToSend.social_credits > 0) {
      creditEntries.push(`social=${dataToSend.social_credits}`);
    }
    if (dataToSend.fai_credits && dataToSend.fai_credits > 0) {
      creditEntries.push(`fai_newsletter=${dataToSend.fai_credits}`);
    }
    dataToSend.granted_credits = creditEntries.join(",");
    delete dataToSend.hero_credits;
    delete dataToSend.social_credits;
    delete dataToSend.fai_credits;

    try {
      if (product_id) {
        const res = await axios.put(`/v2/api/b2b/private/payment_products/${product_id}`, dataToSend);
        dispatch({
          type: ActionType.SAVE_PAYMENTPLAN,
          payload: { product: res.data },
        });
      } else {
        const res = await axios.post("/v2/api/b2b/private/payment_products", dataToSend);
        dispatch({
          type: ActionType.SAVE_PAYMENTPLAN,
          payload: { company: res.data },
        });
      }
      dispatch({ type: ActionType.PAGE_SAVED });
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const resetPaymentProductForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_PAYMENTPLAN_FORM });
};

export const closePaymentProduct = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CLOSE_PAYMENTPLANS });
};

// @ts-ignore
export const updatePaymentProductFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_PAYMENTPLAN_FORM_FIELD,
    payload: { prop, value },
  };
};
