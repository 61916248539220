import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const SelectArrowDownIcon = (props) => (
  <SvgIcon width="10" height="5.882" viewBox="0 0 10 5.882" {...props}>
    <path
      id="Pfad_158"
      data-name="Pfad 158"
      d="M4.882,5.724A.673.673,0,0,1,4.4,5.517L.188,1.14A.673.673,0,1,1,1.158.207L4.882,4.079,8.6.207a.673.673,0,1,1,.971.933L5.367,5.517a.673.673,0,0,1-.485.207"
      transform="translate(0 0)"
    />
  </SvgIcon>
);

export default SelectArrowDownIcon;
