import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import { RootState } from "../reducers";
import EventsListLegacy from "./components/EventsListLegacy";
import { EventState } from "../model/eventState";
import * as CompanyActions from "../actions/company";
import { PageState } from "../model/pageState";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";

export interface Props {
  inboxState: EventState;
  actions: typeof CompanyActions;
  pageState: PageState;
}

export interface State {}

function InboxPage(props: Props) {
  const navigate = useNavigate();

  function openEvent(event_id: string, company_id: string) {
    navigate(`/companies/${company_id}/company_events/${event_id}/review`);
  }

  return (
    <>
      <PageTracker />
      <EventsListLegacy
        eventIdForUpdatingStatus={null}
        inboxMode={true}
        paging={props.inboxState.paging}
        title={intl.get("event_inbox.title")}
        showAddEvent={false}
        events={props.inboxState.events}
        addEvent={() => {
          // TODO
        }}
        fetchEvents={(page, size) => {
          props.actions.fetchEventInbox(page, size);
        }}
        openEvent={(event_id, event) => {
          openEvent(event_id, event.company.company_id);
        }}
        updateEventStatus={() => {
          // TODO
        }}
        changeEventUpgrade={() => {}}
        manageHeroSlots={() => {}}
      />
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    inboxState: state.inboxState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(InboxPage);
