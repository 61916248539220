import * as React from "react";
import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { AuthenticationState } from "../../../../../model/authenticationState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { FormGridItem } from "../FormGridItem";
import { EventEditRadioField } from "../fields/EventEditRadioField";
import { BCEventBookingUrlType } from "../../../../../model/event";
import { EventEditTextInputField } from "../fields/EventEditTextInputField";

export interface Props {
  eventFormState: EventFormState;
  authenticationState: AuthenticationState;
  eventFormActions: typeof EventFormActions;
}

class EventBookingInfoEdit extends React.Component<Props> {
  state = {};

  renderBookingType() {
    return (
      <EventEditRadioField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"booking_url_type"}
        options={[BCEventBookingUrlType.Link, BCEventBookingUrlType.Email]}
      />
    );
  }

  renderBookingURL() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"booking_url"}
        type={this.props.eventFormState.event.booking_url_type === BCEventBookingUrlType.Email ? "email" : undefined}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    const disableControls = this.props.eventFormState.readonly;

    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={4}>{this.renderBookingType()}</FormGridItem>
          <FormGridItem md={8}>{this.renderBookingURL()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventBookingInfoEdit;
