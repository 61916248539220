import { Alert, Box, Divider, Grid, Paper, Snackbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as EmailValidator from "email-validator";
import * as intl from "react-intl-universal";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { ErrorView } from "../components/ErrorView";
import * as AuthActions from "../actions/auth";
import { AuthenticationState } from "../model/authenticationState";
import { RootState } from "../reducers";
import { CompanyState } from "../model/companyState";
import { DefaultButton, LinkButton } from "../components/Buttons";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import { getParamValue } from "../util/util_extensions";
import HeaderLabel from "../components/header/HeaderLabel";
import { PageTracker } from "../util/pageTracker";
import GoogleLoginButton from "./components/google/googleLoginButton";
import { Navigate, useNavigate } from "react-router-dom";
import AppStyles from "../styles/appStyles";

const classes = {
  socialLoginButton: {
    height: "50px",
    padding: "0 30px",
    borderRadius: "5px",
    color: "white",
    fontWeight: 600,
    fontSize: "0.9375em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fbButton: {
    backgroundColor: "#4c69ba",
    "&:hover": {
      background: "#3c5494",
      boxShadow: "none",
    },
    marginRight: "16px",
  },
  socialLoginButtonIcon: {
    paddingRight: "10px",
  },
};

export interface Props {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  actions: typeof AuthActions;
}

export interface State {
  email: string;
  password: string;
  validLoginInput: boolean;
  emailValid: boolean;
  showActivationSuccess: boolean;
}

function LoginPage(props: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validLoginInput, setValidLoginInput] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [showActivationSuccess, setShowActivationSuccess] = useState(false);

  const location = useLocation();

  const handleChange = (name: string) => (event: any) => {
    props.authenticationState.error = null;

    let hasValidLoginData = false;
    if (name === "email") {
      setEmail(event.target.value);
      const valid = validateEmail(event.target.value);
      hasValidLoginData = validatePassword(password) && valid;
      setEmailValid(valid);
    }
    if (name === "password") {
      setPassword(event.target.value);
      hasValidLoginData = validatePassword(event.target.value) && validateEmail(email);
    }

    setValidLoginInput(hasValidLoginData);
  };

  const validateEmail = (mEmail: string) => {
    if (mEmail.length > 0) {
      return EmailValidator.validate(mEmail);
    }
    return mEmail.length > 0;
  };

  const validatePassword = (mPassword: string) => {
    return mPassword.length > 0;
  };

  const login = () => {
    props.actions.login(email, password);
  };

  const facebookResponse = (response: any) => {
    const fbToken = response.accessToken;
    if (fbToken) {
      props.actions.signInWithFacebook(fbToken);
    }
  };

  const handleCustomGoogleResponse = (response: CredentialResponse) => {
    const { credential } = response;
    if (credential) {
      props.actions.signInWithGoogle(credential);
    }
  };

  useEffect(() => {
    if (getParamValue("activation_success")) {
      setShowActivationSuccess(true);
    }
  }, []);

  if (props.authenticationState.authenticated && props.authenticationState.user !== null) {
    // if last action was loggedOut then ignore the location state and show /companies
    if (location.state != null && !props.authenticationState.loggedOut) {
      const { from } = location.state as any;
      return <Navigate to={{ pathname: from.pathname, search: location.search }} />;
    }
    if (
      props.authenticationState.user &&
      props.authenticationState.user.companies &&
      props.authenticationState.user.companies.length === 1 &&
      props.authenticationState.user.role == null
    ) {
      // user has only one company and she has no global role -> let's select this company
      return (
        <Navigate
          to={{
            pathname: `/companies/${props.authenticationState.user.companies[0].company_id}/company_events`,
            search: location.search,
          }}
        />
      );
    }
    return <Navigate to={{ pathname: "/companies", search: location.search }} />;
  }

  let { error } = props.authenticationState;

  const passedErrorMessage = getParamValue("error");
  if (!error && email === "" && password === "" && passedErrorMessage) {
    if (intl.get(`error.code.${passedErrorMessage}`)) {
      error = { errorMessage: intl.get(`error.code.${passedErrorMessage}`) };
    }
  }

  return (
    <Box component={"main"} sx={AppStyles.centerCardLayout}>
      <PageTracker />
      <Paper sx={AppStyles.centerPaper}>
        <Grid style={{ width: "100%" }}>
          <HeaderLabel>{intl.get("login.title")}</HeaderLabel>
        </Grid>

        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={showActivationSuccess}
          autoHideDuration={3000}
          onClose={() => {
            setShowActivationSuccess(false);
          }}
        >
          <Alert severity="success">{intl.get("login.finished_activation")}</Alert>
        </Snackbar>
        <Box sx={AppStyles.appForm}>
          <ErrorView error={error} defaultErrorMessage={intl.get("login.error")} />
          <Grid container sx={AppStyles.section} direction="column">
            <TextFieldWithLabel
              placeholder={"E-Mail"}
              error={error != null || !emailValid}
              id="email"
              label={intl.get("label.email")}
              value={email}
              onChange={handleChange("email")}
              margin="normal"
              onReturnPressed={() => {
                login();
              }}
              onReturnEnabled={validLoginInput}
            />
          </Grid>
          <Grid container sx={AppStyles.section} direction="column">
            <TextFieldWithLabel
              placeholder={"Passwort"}
              error={error != null}
              id="password"
              label={intl.get("label.password")}
              type="password"
              autoComplete="current-password"
              onChange={handleChange("password")}
              margin="normal"
              onReturnPressed={() => {
                login();
              }}
              onReturnEnabled={validLoginInput}
            />
            <LinkButton to="/forgotPassword" title={intl.get("login.forgot_password")} style={{ marginTop: 8 }} />
          </Grid>

          <Grid container sx={AppStyles.section} direction="row" justifyContent="flex-end" alignItems={"flex-end"}>
            <DefaultButton
              style={{ paddingLeft: 50, paddingRight: 50 }}
              sx={{ flexGrow: 1 }}
              onClick={login}
              disabled={!validLoginInput}
              title={intl.get("login.button")}
            />
          </Grid>

          <Grid item xs={12} container direction={"row"} alignItems={"center"}>
            <Divider sx={{ flexGrow: 1 }} />
            <Typography style={{ margin: 16, color: "#969695" }}>oder fahre fort mit</Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Grid>

          <Grid container sx={AppStyles.section} direction="row" justifyContent="center" alignItems={"center"}>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
              autoLoad={false}
              language={"de_DE"}
              fields="name,email"
              onSuccess={(response) => {
                facebookResponse(response);
              }}
              onFail={() => {}}
              onProfileSuccess={() => {}}
              render={({ onClick }) => (
                <Box sx={{ ...classes.socialLoginButton, ...classes.fbButton }} onClick={onClick}>
                  <Box sx={classes.socialLoginButtonIcon} className={"fab fa-facebook-f"} />
                  <div>{intl.get("login.with_fb")}</div>
                </Box>
              )}
            />

            <GoogleLoginButton handleGoogleResponse={handleCustomGoogleResponse} />
          </Grid>
        </Box>

        <SignupComponent />
      </Paper>
    </Box>
  );
}

function SignupComponent() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} container direction={"row"} alignItems={"center"} style={{ marginTop: 16 }}>
        <Divider sx={{ flexGrow: 1 }} />
        <Typography style={{ margin: 16, color: "#3c3c3b" }}>{intl.get("login.no_account_yet")}</Typography>
        <Divider sx={{ flexGrow: 1 }} />
      </Grid>

      <Box sx={AppStyles.appForm}>
        <Grid container sx={AppStyles.section} direction="row" justifyContent="space-between">
          <DefaultButton
            variant={"outlined"}
            sx={{ flexGrow: 1 }}
            onClick={() => navigate(`/signup${location.search}`)}
            title={intl.get("login.jump_to_signup")}
          />
        </Grid>
      </Box>
    </>
  );
}

function authMapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyState: state.companyState,
  };
}

function authMapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default connect(authMapStateToProps, authMapDispatchToProps)(LoginPage);
