import { Grid } from "@mui/material";
import AppStyles from "../styles/appStyles";
import { HeaderLabel } from "./header/HeaderLabel";
import { DefaultButton } from "./Buttons";
import * as React from "react";

export default function EmptyPageSectionHeader({ text, marginTop }: { text: string; marginTop: number | undefined }) {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" style={{ marginTop }}>
      <Grid item sx={AppStyles.headerLeft}>
        <HeaderLabel>{text}</HeaderLabel>
      </Grid>
      <Grid item sx={AppStyles.headerRight}>
        <DefaultButton sx={AppStyles.buttonPlaceholder} />
      </Grid>
    </Grid>
  );
}
