import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { User } from "../model/user";
import { mapErrorFromException } from "../model/error";

export const fetchAllAdminUsers = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get("/v2/api/b2b/private/users");

    dispatch({
      type: ActionType.FETCH_ADMIN_USERS,
      payload: { data: true, users: res.data.users },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const changeAdminUserRole = (user_id: string, role: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    const res = await axios.put("/v2/api/b2b/private/users", {
      user_id,
      role,
    });

    dispatch({
      type: ActionType.CHANGE_ADMIN_USER_ROLE,
      payload: { data: true, user: res.data },
    });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const removeRoles = (user: User) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    await axios.put("/v2/api/b2b/private/users", {
      user_id: user.user_id,
      role: "none",
    });

    dispatch({
      type: ActionType.DELETE_ADMIN_USER,
      payload: { data: true, user_id: user.user_id },
    });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const saveNewAdminUser = (email: string, role: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    await axios.put("/v2/api/b2b/private/users", {
      email,
      role,
    });

    dispatch({ type: ActionType.SAVE_USER, payload: {} });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};
