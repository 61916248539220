import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { AdminUsersState } from "../model/adminUsersState";

export const adminUsersState = createReducer(
  {
    users: null,
  },
  {
    [ActionType.FETCH_ADMIN_USERS](state: AdminUsersState, action: Action<any>) {
      if (action.payload.data === true) {
        return { ...state, users: action.payload.users };
      }
      return { ...state };
    },
    [ActionType.DELETE_ADMIN_USER](state: AdminUsersState, action: Action<any>) {
      if (action.payload.data === true) {
        const newState = { ...state };
        const eventIndex = newState.users.findIndex((value) => value.user_id === action.payload.user_id);
        newState.users.splice(eventIndex, 1);
        return newState;
      }
      return state;
    },
    [ActionType.CHANGE_ADMIN_USER_ROLE](state: AdminUsersState, action: Action<any>) {
      if (action.payload.data === true && action.payload.user != null) {
        const newState = { ...state, closePage: true };
        const index = newState.users.findIndex((value) => value.user_id === action.payload.user.user_id);
        newState.users[index] = action.payload.user;
        return newState;
      }
      return { ...state, closePage: true };
    },
  },
);
