import { MenuItem } from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { UserFormState } from "../../model/userFormState";
import { RootState } from "../../reducers";
import * as CompanyUserActions from "../../actions/companyUser";
import AddUserForm from "../components/forms/AddUserForm";
import { CompanyState } from "../../model/companyState";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";

export interface Props {
  companyUserFormState: UserFormState;
  companyState: CompanyState;
  actions: typeof CompanyUserActions;
}

export interface State {}

function AddCompanyUserPage(props: Props) {
  const navigate = useNavigate();
  const createUser = () => {
    props.actions.saveNewCompanyUser(
      props.companyState.selected_company_id,
      props.companyUserFormState.user.email,
      props.companyUserFormState.user.role,
    );
  };

  const closePage = () => {
    navigate(-1);
  };

  if (props.companyState.company.is_salespartner) {
    return (
      <>
        <PageTracker />
        <AddUserForm
          createUser={() => {
            createUser();
          }}
          closePage={() => {
            closePage();
          }}
        >
          <MenuItem value="COMPANY_ADMIN">{intl.get("user_role.COMPANY_ADMIN")}</MenuItem>
          <MenuItem value="COMPANY_MANAGER">{intl.get("user_role.COMPANY_MANAGER")}</MenuItem>
          <MenuItem value="COMPANY_EDITOR">{intl.get("user_role.COMPANY_EDITOR")}</MenuItem>
          <MenuItem value="SALESPARTNER_SALESPERSON">{intl.get("user_role.SALESPARTNER_SALESPERSON")}</MenuItem>
        </AddUserForm>
      </>
    );
  }
  if (props.companyState.company.salespartner_company_id) {
    if (props.companyState.company.role === "COMPANY_SALESPARTNER_ADMIN") {
      return (
        <>
          <PageTracker />
          <AddUserForm
            createUser={() => {
              createUser();
            }}
            closePage={() => {
              closePage();
            }}
          >
            <MenuItem value="COMPANY_SALESPARTNER_ADMIN">{intl.get("user_role.COMPANY_SALESPARTNER_ADMIN")}</MenuItem>
            <MenuItem value="COMPANY_LIGHT_ADMIN">{intl.get("user_role.COMPANY_LIGHT_ADMIN")}</MenuItem>
            <MenuItem value="COMPANY_EDITOR">{intl.get("user_role.COMPANY_EDITOR")}</MenuItem>
          </AddUserForm>
        </>
      );
    }
    return (
      <>
        <PageTracker />
        <AddUserForm
          createUser={() => {
            createUser();
          }}
          closePage={() => {
            closePage();
          }}
        >
          <MenuItem value="COMPANY_LIGHT_ADMIN">{intl.get("user_role.COMPANY_LIGHT_ADMIN")}</MenuItem>
          <MenuItem value="COMPANY_EDITOR">{intl.get("user_role.COMPANY_EDITOR")}</MenuItem>
        </AddUserForm>
      </>
    );
  }
  return (
    <>
      <PageTracker />
      <AddUserForm
        createUser={() => {
          createUser();
        }}
        closePage={() => {
          closePage();
        }}
      >
        <MenuItem value="COMPANY_ADMIN">{intl.get("user_role.COMPANY_ADMIN")}</MenuItem>
        <MenuItem value="COMPANY_MANAGER">{intl.get("user_role.COMPANY_MANAGER")}</MenuItem>
        <MenuItem value="COMPANY_EDITOR">{intl.get("user_role.COMPANY_EDITOR")}</MenuItem>
      </AddUserForm>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyUserFormState: state.userFormState,
    companyState: state.companyState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyUserActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddCompanyUserPage);
