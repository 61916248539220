import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const ContactSupportIcon = (props) => (
  <SvgIcon width="28" height="30" viewBox="0 0 28 30" {...props}>
    <path
      id="Pfad_125"
      data-name="Pfad 125"
      d="M27.986,14.657a1.09,1.09,0,0,0-.01-.145V14.5a3.991,3.991,0,0,0-2.554-3.069v-.106a11.193,11.193,0,0,0-3.314-8A11.323,11.323,0,0,0,14.079,0h-.028A11.361,11.361,0,0,0,2.682,11.287v.1A4,4,0,0,0,.024,14.5a1.407,1.407,0,0,0-.011.167L0,20.35a.762.762,0,0,0,.013.15C.168,21.728,1.432,24,5.16,24A1.5,1.5,0,0,0,6.7,22.518l.025-10.081a1.47,1.47,0,0,0-.448-1.057,1.355,1.355,0,0,0-.268-.209,8.029,8.029,0,0,1,8.042-7.845h.021a8.017,8.017,0,0,1,5.684,2.357,7.907,7.907,0,0,1,2.337,5.443,1.434,1.434,0,0,0-.371.268,1.516,1.516,0,0,0-.448,1.079l.025,10.05A1.5,1.5,0,0,0,22.837,24c.069,0,.135,0,.2,0a4.071,4.071,0,0,1-4.373,3.337H18A1.333,1.333,0,0,0,16.667,26H11.333A1.333,1.333,0,0,0,10,27.333v1.333A1.333,1.333,0,0,0,11.333,30h5.333A1.333,1.333,0,0,0,18,28.667h.667A5.616,5.616,0,0,0,22.8,27.138a6.335,6.335,0,0,0,1.629-3.3,4.2,4.2,0,0,0,3.556-3.37l0-.014A1.021,1.021,0,0,0,28,20.318Z"
      fill="#707cda"
    />
  </SvgIcon>
);

export default ContactSupportIcon;
