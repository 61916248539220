import * as React from "react";
import { Box } from "@mui/material";

export interface Props {}

export interface State {}

class DashboardPage extends React.Component<Props> {
  render() {
    return (
      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Dashboard
      </Box>
    );
  }
}

export default DashboardPage;
