import { Box, Grid, Paper, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { RootState } from "../reducers";
import { AuthenticationState } from "../model/authenticationState";
import * as AuthActions from "../actions/auth";
import * as UserActions from "../actions/user";
import { ErrorView } from "../components/ErrorView";
import { DefaultButton, LinkButton } from "../components/Buttons";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import { SectionLabel } from "../components";
import { Progress } from "../components/Progress";
import { theme } from "../theme";
import { LoginMethod } from "../model/user";
import EditPasswordDialog from "./components/EditPasswordDialog";
import { PageTracker } from "../util/pageTracker";
import AppStyles from "../styles/appStyles";

const classes = {
  section: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
};

export interface Props {
  authenticationState: AuthenticationState;
  authActions: typeof AuthActions;
  userActions: typeof UserActions;
}

export interface State {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  showEditPasswordDialog: boolean;
}

function UserEditPage(props: Props) {
  const [state, setState] = useState({
    email: props.authenticationState.user.email,
    first_name: props.authenticationState.user.first_name,
    last_name: props.authenticationState.user.last_name,
    role: props.authenticationState.user.role,
  });

  const [showEditPasswordDialog, setShowEditPasswordDialog] = useState(false);

  const changePassword = () => {
    setShowEditPasswordDialog(true);
  };

  const closeEditPasswordDialog = () => {
    setShowEditPasswordDialog(false);
  };

  const handleChange = (name: any) => (event: any) => {
    // @ts-ignore
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const save = () => {
    let { user } = props.authenticationState;
    user = {
      ...user,
      first_name: state.first_name,
      last_name: state.last_name,
    };
    props.userActions.updateUser(user);
  };

  function renderEditPasswordDialog() {
    return <EditPasswordDialog showDialog={showEditPasswordDialog} onClose={closeEditPasswordDialog} />;
  }

  const { error } = props.authenticationState;

  if (props.authenticationState.loading) {
    return <Progress />;
  }

  const { user } = props.authenticationState;
  const loginMethodIsEmail = user.login_method === LoginMethod.Email;

  return (
    <Box>
      <PageTracker />

      {renderEditPasswordDialog()}

      <Box component={"main"} sx={AppStyles.centerCardLayout}>
        <Paper sx={AppStyles.centerPaper}>
          <Typography component="h1" variant="h5">
            {intl.get("editProfile.title")}
          </Typography>
          <Box sx={AppStyles.appForm}>
            <ErrorView error={error} />
            <Grid container sx={classes.section} direction="column">
              <SectionLabel>{intl.get("label.email")}</SectionLabel>
              <Typography component="h5" variant="h5" style={{ fontSize: theme.fontSizes.mediumFont }}>
                {state.email}
              </Typography>
            </Grid>
            <Grid container sx={classes.section} direction="column">
              <SectionLabel>{intl.get("label.role")}</SectionLabel>
              <Typography component="h5" variant="h5" style={{ fontSize: theme.fontSizes.mediumFont }}>
                {intl.get(`user_role.${state.role}`)}
              </Typography>
            </Grid>
            <Grid container alignItems="stretch" justifyContent="center" direction="column">
              <Grid item sx={classes.section} container direction="row">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Grid item container sx={classes.section} direction="column">
                      <TextFieldWithLabel
                        label={intl.get("label.first_name")}
                        id="first_name"
                        onChange={handleChange("first_name")}
                        margin="normal"
                        value={state.first_name}
                        disabled={!loginMethodIsEmail}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid item container sx={classes.section} direction="column">
                      <TextFieldWithLabel
                        label={intl.get("label.last_name")}
                        id="last_name"
                        onChange={handleChange("last_name")}
                        margin="normal"
                        value={state.last_name}
                        disabled={!loginMethodIsEmail}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={classes.section} direction="row" justifyContent="space-between" alignItems={"center"}>
              {loginMethodIsEmail && <DefaultButton onClick={save} title={intl.get("editProfile.save_button")} />}
              <Grid item>
                {loginMethodIsEmail && (
                  <>
                    <LinkButton onClick={changePassword} title={intl.get("editProfile.change_password_button")} />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

function authMapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
  };
}

function authMapDispatchToProps(dispatch: any) {
  return {
    authActions: bindActionCreators(AuthActions as any, dispatch),
    userActions: bindActionCreators(UserActions as any, dispatch),
  };
}

export default connect(authMapStateToProps, authMapDispatchToProps)(UserEditPage);
