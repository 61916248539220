import { useEffect, useRef, useState } from "react";
import useLogger from "../../../hooks/useLogger";

export default function useUnzerPaymentScripts() {
  const cardRef = useRef();
  const sepaRef = useRef();

  const logger = useLogger("unzer_scripts");

  const [doneLoading, setDoneLoading] = useState(false);

  const hasMounted = useRef(false);

  useEffect(() => {}, [doneLoading]);

  function handleScriptLoaded() {
    if (!doneLoading) {
      logger.info("Done loading unzer scripts");

      const unzerPublicKey = process.env.REACT_APP_UNZER_PUB_KEY;
      // @ts-ignore
      const unzerInstance = new unzer(unzerPublicKey);
      cardRef.current = unzerInstance.Card();
      sepaRef.current = unzerInstance.SepaDirectDebit();

      // @ts-ignore
      cardRef.current.create("number", {
        containerId: "card-element-id-number",
        onlyIframe: false,
      });
      // @ts-ignore
      cardRef.current.create("expiry", {
        containerId: "card-element-id-expiry",
        onlyIframe: false,
      });
      // @ts-ignore
      cardRef.current.create("cvc", {
        containerId: "card-element-id-cvc",
        onlyIframe: false,
      });

      // @ts-ignore
      sepaRef.current.create("sepa-direct-debit", {
        containerId: "sepa-IBAN",
      });

      setDoneLoading(true);
    }
  }

  useEffect(() => {
    if (hasMounted.current) {
      return;
    }

    const script = document.createElement("script");

    script.src = "https://static.unzer.com/v1/unzer.js";
    script.async = true;
    script.onload = handleScriptLoaded;
    script.onerror = () => logger.warn("error loading unzer scripts");

    document.body.appendChild(script);

    hasMounted.current = true;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const hideSandboxNotification = () => {
    if (process.env.NODE_ENV === "production") {
      // no need for this in PR
      return;
    }
    const elements = document.querySelectorAll(".unzerSandboxNotify");
    elements.forEach((element) => {
      document.body.removeChild(element);
    });
  };

  return {
    doneLoading,
    hideSandboxNotification,
    sepaPaymentMethod: sepaRef.current,
    cardPaymentMethod: cardRef.current,
  };
}
