import { asNonEmptyString } from "../util/util_extensions";

export interface ResellerProfile {
  profile_id: string;
  name: string;
  should_calc_vat: boolean;
  vat_id: string;
  country: string;
  account_name: string;
  account_iban: string;
  address_line_1: string;
  address_line_2: string;
  continuous_commission_months: number;
  continuous_commission_percentage: number;
}

export function userFilledResellerProfile(profile: ResellerProfile) {
  return (
    profile != null &&
    asNonEmptyString(profile.name) &&
    asNonEmptyString(profile.account_name) &&
    asNonEmptyString(profile.account_iban) &&
    asNonEmptyString(profile.address_line_1) &&
    asNonEmptyString(profile.address_line_2) &&
    asNonEmptyString(profile.country)
  );
}
