import { Tabs, TabsProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const styledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  minHeight: "40px",
  "&. MuiTabs-indicator": {
    display: "default",
    height: "100%",
  },
}));
export default styledTabs;
