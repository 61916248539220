import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import TextFieldWithLabel from "../../../../../components/TextFieldWithLabel";
import intl from "react-intl-universal";
import { isNonEmptyNumber } from "../../../../../util/util_extensions";
import * as React from "react";

export function EventEditNumberInputField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  onChange?: any;
  error?: boolean;
  required?: boolean;
}) {
  let isEmpty = false;
  let isZero = false;
  if (props.formState.event[`${props.field}`] === undefined || props.formState.event[`${props.field}`] === null) {
    isEmpty = true;
  }
  if (props.formState.event[`${props.field}`] === 0) {
    isZero = true;
  }
  return (
    <TextFieldWithLabel
      type="number"
      required={props.required ?? false}
      label={intl.get(`event_form.label.${props.field}`)}
      id={props.field}
      margin="normal"
      disabled={props.formState.readonly}
      onChange={(event) => {
        props.formActions.updateEventFormField({
          prop: `event.${props.field}`,
          value: isNonEmptyNumber(+event.target.value) ? Number(event.target.value) : null,
        });
        if (props.onChange) {
          props.onChange(event);
        }
      }}
      value={isEmpty ? "" : isZero ? "0" : props.formState.event[`${props.field}`]}
      error={props.error}
    />
  );
}
