import { Grid, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import * as React from "react";
import * as intl from "react-intl-universal";
import {
  formattedCompanyProductBillingInfo,
  formattedExpiryDateForCompanyProduct,
  formattedNextInvoiceDateForCompanyProduct,
} from "../../model/payment_info";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import { CompanyPaymentProduct } from "../../model/payment/company.payment.product";
import { CompanyCreditBalance } from "../../model/payment/company.credit.balance";
import { formattedCreditType, formattedProductFeatures } from "../../model/payment/payment.product";
import AppStyles from "../../styles/appStyles";

export interface Props {
  activeProducts: CompanyPaymentProduct[];
  credits: CompanyCreditBalance[];
  companyCountryCode: string;
  canCancelProducts: boolean;
  cancelProduct: any;
}

class CompanyPlanInformation extends React.Component<Props> {
  renderActiveProducts() {
    if (this.props.activeProducts === null || this.props.activeProducts === undefined) {
      return null;
    }
    if (this.props.activeProducts.length === 0) {
      return null;
    }

    return this.props.activeProducts.map((product) => {
      return (
        <React.Fragment key={product.company_product_id}>
          <Paper sx={AppStyles.innerContentPaper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable}>
                    {product.status === "ACTIVE" && <b>{intl.get("company_plan_info.plans.active_plan_title")}</b>}
                    {product.status === "CANCELLED" && (
                      <b>{intl.get("company_plan_info.plans.cancelled_plan_title")}</b>
                    )}
                  </TableCell>
                  <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable}>
                    {product.name}
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={AppStyles.tableCellNotSelectable}
                    dangerouslySetInnerHTML={{ __html: formattedCompanyProductBillingInfo(product) }}
                  ></TableCell>
                  <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable}>
                    {this.props.canCancelProducts && !product.active_until_date && (
                      <LinkButton
                        onClick={(event: any) => {
                          event.stopPropagation();
                          this.props.cancelProduct(product);
                        }}
                        title={intl.get("company_plan_info.cancellation.button_cancel")}
                      />
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable} />
                  <TableCell scope="row" sx={AppStyles.tableCellNotSelectable}>
                    {product.active_until_date && formattedExpiryDateForCompanyProduct(product)}
                    {product.next_invoice_date && formattedNextInvoiceDateForCompanyProduct(product)}
                  </TableCell>
                  <TableCell scope="row" colSpan={2} padding="none" sx={AppStyles.tableCellNotSelectable} />
                </TableRow>

                <TableRow>
                  <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable} />
                  <TableCell colSpan={2} scope="row" sx={AppStyles.tableCellNotSelectable}>
                    <ul>
                      {formattedProductFeatures(
                        product.premium_upgrade_slots,
                        product.premium_plus_upgrade_slots,
                        product.granted_credits,
                      ).map((feature: string) => {
                        return <li dangerouslySetInnerHTML={{ __html: feature }} key={feature} />;
                      })}
                    </ul>
                  </TableCell>
                  <TableCell scope="row" padding="none" sx={AppStyles.tableCellNotSelectable} />
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </React.Fragment>
      );
    });
  }

  renderCredit(credit: CompanyCreditBalance) {
    const formatted = formattedCreditType(credit.credit_type);
    return (
      <TableRow key={credit.credit_type + credit.count}>
        <TableCell scope="row" sx={AppStyles.tableCellNotSelectable}>
          <b>{credit.count}x</b> {formatted}
        </TableCell>
      </TableRow>
    );
  }

  renderCredits() {
    if (this.props.credits.length === 0) {
      return undefined;
    }

    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>Credits</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton sx={AppStyles.buttonPlaceholder} />
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableBody>{this.props.credits.map((value) => this.renderCredit(value))}</TableBody>
          </Table>
        </Paper>
      </>
    );
  }

  render() {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("company_plan_info.title_plan")}</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton sx={AppStyles.buttonPlaceholder} />
          </Grid>
        </Grid>

        {this.renderActiveProducts()}

        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableBody>
              {this.props.activeProducts && this.props.activeProducts.length === 0 && this.props.companyCountryCode && (
                <TableRow>
                  <TableCell scope="row" colSpan={2} sx={AppStyles.tableCellNotSelectable}>
                    {intl.get("company_plan_info.plans.select_plan_hint")}
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                    &nbsp;
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              )}
              {this.props.activeProducts &&
                this.props.activeProducts.length === 0 &&
                !this.props.companyCountryCode && (
                  <TableRow>
                    <TableCell scope="row" colSpan={2} padding="none" sx={AppStyles.tableCellNotSelectable}>
                      {intl.get("company_plan_info.plans.set_company_address_hint")}
                    </TableCell>
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                      &nbsp;
                    </TableCell>
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </Paper>

        {this.renderCredits()}
      </>
    );
  }
}

export default CompanyPlanInformation;
