import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";

export const updateProfile = (data: any) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.put("/v2/api/b2b/private/users/profile", data);
    dispatch({ type: ActionType.UPDATE_PROFILE, payload: res.data });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};
