import { Tab, TabsProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const styledTab = styled(Tab)<TabsProps>(({ theme }) => ({
  zIndex: 99,
  minHeight: "40px",
  color: theme.palette.primary.main,
  "& .Mui-selected": {
    color: "#fff",
  },
}));
export default styledTab;
