import createReducer from "./createReducer";
import { cloneDefaultState, updateStateFieldByProp } from "../util/util_extensions";
import { Action, ActionType } from "../model/actions";
import { CompanySharedLocationsState } from "../model/company.shared.locations.state";

const defaultState = {
  shared_locations: [],

  location_id: null,
  editing_shared_location: {
    internal_name: "",
    name: "",
    directions: "",
    description: "",
    address: "",
    postal_code: "",
    city: "",
    state: "",
    region: "",
    country: "",
    country_code: "",
    main_location: true,
    additional_info: "",
    longitude: null,
    latitude: null,
  },
};

export const companySharedLocationsState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_SHARED_LOCATION_FORM](state: CompanySharedLocationsState) {
    return {
      ...state,
      location_id: null,
      editing_shared_location: {
        internal_name: "",
        name: "",
        directions: "",
        description: "",
        address: "",
        postal_code: "",
        city: "",
        state: "",
        region: "",
        country: "",
        country_code: "",
        main_location: true,
        additional_info: "",
        longitude: null,
        latitude: null,
      },
    };
  },
  [ActionType.FETCH_SHARED_LOCATIONS](state: CompanySharedLocationsState, action: Action<any>) {
    if (action.payload.data === true) {
      return {
        ...state,
        shared_locations: action.payload.locations,
      };
    }
    return { ...state };
  },
  [ActionType.EDIT_SHARED_LOCATION](state: CompanySharedLocationsState, action: Action<any>) {
    if (action.payload.data) {
      return {
        ...state,
        editing_shared_location: action.payload.location,
        location_id: action.payload.location_id,
      };
    }
    return { ...state };
  },
  [ActionType.UPDATE_SHARED_LOCATION_FORM_FIELD](state: CompanySharedLocationsState, action: Action<any>) {
    return updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);
  },
});
