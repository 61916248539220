import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";
import { Media } from "../model/media";

export const fetchMediaForCompany = (company_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/media`);

    dispatch({
      type: ActionType.FETCH_COMPANY_MEDIA_ITEMS,
      payload: { data: true, media: res.data.media, paging: res.data.paging },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const deleteMediaItemForCompany = (company_id: string, media_item_id: string) => async (dispatch: Dispatch) => {
  try {
    await axios.delete(`/v2/api/b2b/private/companies/${company_id}/media/${media_item_id}`);

    dispatch({
      type: ActionType.DELETE_COMPANY_MEDIA_ITEM,
      payload: { media_item_id },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const uploadCloudinaryResult = (company_id: string, cloudinary_info: any) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.post(`/v2/api/b2b/private/companies/${company_id}/media`, cloudinary_info);
    dispatch({
      type: ActionType.UPLOAD_COMPANY_MEDIA_ITEM,
      payload: { data: true, media_item: res.data },
    });
    dispatch({
      type: ActionType.ADD_EVENT_MEDIA_ITEM,
      payload: { ...res.data, uploaded_in_event_creation: true },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const updateMediaItem =
  (company_id: string, media_item_id: string, credits: string) => async (dispatch: Dispatch) => {
    try {
      const res = await axios.put(`/v2/api/b2b/private/companies/${company_id}/media/${media_item_id}`, { credits });

      dispatch({
        type: ActionType.UPDATE_COMPANY_MEDIA_ITEM,
        payload: { data: true, media_item: res.data },
      });
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const addMediaItemToEvent = (mediaItem: Media) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_EVENT_MEDIA_ITEM, payload: mediaItem });
};

export const removeMediaItemFromEvent = (mediaItem: Media) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.REMOVE_EVENT_MEDIA_ITEM, payload: mediaItem });
};
