import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import intl from "react-intl-universal";
import EmptyPageSectionHeader from "../../components/EmptyPageSectionHeader";
import * as React from "react";
import AppStyles from "../../styles/appStyles";
import PdfRegenerationSuccessSnackbar from "../../components/company/invoices/PdfRegenerationSuccessSnackbar";
import { Paper, Table, TableBody } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CompanyInvoicesTableHeader from "../../components/company/invoices/CompanyInvoicesTableHeader";
import { Invoice } from "../../model/invoice";
import { PagingInformation } from "../../model/pagingInformation";
import TablePaginationFooter from "../../components/TablePaginationFooter";
import * as InvoiceActions from "../../actions/company";
import PlaceholderTextTableRow from "../../components/PlaceholderTextTableRow";
import CompanyInvoiceCell from "../../components/company/invoices/CompanyInvoiceCell";
import axios from "axios";

export default function CompanyInvoicesPage() {
  const selectedCompanyId = useSelector<RootState, string>((state) => state.companyState.selected_company_id);

  const allInvoices = useSelector<RootState, Invoice[]>((state) => state.paymentState.allInvoices);
  const pagingInformation = useSelector<RootState, PagingInformation>((state) => state.paymentState.invoice_paging);

  const [showPdfRegenerationSuccess, setShowPdfRegenerationSuccess] = useState<boolean>(false);

  const dispatch = useDispatch();
  const onChangePage = (page) => {
    pagingInformation.current_page = page;
    dispatch<any>(
      InvoiceActions.loadInvoices(selectedCompanyId, pagingInformation.current_page, pagingInformation.page_size),
    );
  };

  const onChangePageSize = (pageSize) => {
    pagingInformation.page_size = pageSize;
    pagingInformation.current_page = 1;
    dispatch<any>(
      InvoiceActions.loadInvoices(selectedCompanyId, pagingInformation.current_page, pagingInformation.page_size),
    );
  };

  const regenerateInvoicePdf = async (invoice: Invoice) => {
    await axios.delete(`/v2/api/b2b/private/companies/${selectedCompanyId}/invoices/${invoice.invoice_id}/pdf`);
    setShowPdfRegenerationSuccess(true);
  };

  const downloadInvoicePdf = async (invoice: Invoice) => {
    try {
      const res = await axios.get(
        `/v2/api/b2b/private/companies/${selectedCompanyId}/invoices/${invoice.invoice_id}/pdf`,
        { responseType: "blob" },
      );

      const dispositionHeader: string = res.headers["Content-Disposition"];
      let filename: string;
      if (dispositionHeader != null) {
        const fileIndex = dispositionHeader.indexOf("filename=");
        filename = dispositionHeader.substring(fileIndex + 9);
      } else {
        filename = `blue-cherries-${invoice.invoice_number}`;
      }

      const newBlob = new Blob([res.data], { type: "application/pdf" });

      const nav = window.navigator as any;

      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob, filename);
        return;
      }

      const data = URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = data;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      if (link.parentNode != null) {
        link.parentNode.removeChild(link);
      }
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (error) {}
  };

  const performInitialLoad = useCallback(() => {
    pagingInformation.current_page = 1;
    dispatch<any>(
      InvoiceActions.loadInvoices(selectedCompanyId, pagingInformation.current_page, pagingInformation.page_size),
    );
  }, [pagingInformation, selectedCompanyId]);

  useEffect(() => {
    performInitialLoad();
  }, []);

  return (
    <>
      <EmptyPageSectionHeader text={intl.get("company_plan_info.title_invoices")} marginTop={30} />

      <Paper sx={AppStyles.innerContentPaper}>
        <PdfRegenerationSuccessSnackbar
          open={showPdfRegenerationSuccess}
          onClose={() => setShowPdfRegenerationSuccess(false)}
        />

        <Table>
          <CompanyInvoicesTableHeader />

          <TableBody>
            {allInvoices.length === 0 && (
              <PlaceholderTextTableRow text={intl.get("company_plan_info.invoices_list.no_data")} span={5} />
            )}

            {allInvoices.map((invoice) => {
              return (
                <CompanyInvoiceCell
                  key={invoice.invoice_id}
                  invoice={invoice}
                  onDownload={() => {
                    downloadInvoicePdf(invoice);
                  }}
                  onRegeneratePdf={() => {
                    regenerateInvoicePdf(invoice);
                  }}
                />
              );
            })}
          </TableBody>

          <TablePaginationFooter
            count={allInvoices.length}
            paging={pagingInformation}
            onChangePage={(number) => {
              onChangePage(number);
            }}
            onChangeRowsPerPage={(number) => {
              onChangePageSize(number);
            }}
          />
        </Table>
      </Paper>
    </>
  );
}
