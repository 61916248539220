import { useEffect } from "react";
import * as PageStateActions from "../actions/pageState";
import { useDispatch } from "react-redux";

export default function usePageStateSetup() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(PageStateActions.resetPageState());
  }, [location]);
}
