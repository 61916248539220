import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import * as intl from "react-intl-universal";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as UserActions from "../../actions/user";
import { RootState } from "../../reducers";
import { ErrorView, SectionLabel } from "../../components";
import { AuthenticationState } from "../../model/authenticationState";
import { Error } from "../../model/error";

const PREFIX = "EditPasswordDialog";

const classes = {
  dialogBackdrop: `${PREFIX}-dialogBackdrop`,
  dialogContent: `${PREFIX}-dialogContent`,
  section: `${PREFIX}-section`,
  textField: `${PREFIX}-textField`,
  optionalTopPaddingSection: `${PREFIX}-optionalTopPaddingSection`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogBackdrop}`]: {
    background: "rgba(255,255,255,0.8)",
  },

  [`& .${classes.dialogContent}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.section}`]: {
    paddingTop: "20px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
    },
  },

  [`& .${classes.textField}`]: {
    margin: 0,
  },

  [`& .${classes.optionalTopPaddingSection}`]: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
  },
}));

export interface Props {
  authenticationState: AuthenticationState;
  userActions: typeof UserActions;
  onClose: any;
  showDialog: boolean;
}

export interface State {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
  validChange: boolean;
  error: Error | null;
}

class EditPasswordDialog extends React.Component<Props, State> {
  state = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    validChange: false,
    error: null,
  };

  resetState() {
    this.setState({
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      validChange: false,
    });
  }

  componentDidUpdate() {
    if (
      this.props.authenticationState.error != null &&
      this.state.error == null &&
      this.state.oldPassword.length === 0
    ) {
      this.setState({
        ...this.state,
        error: this.props.authenticationState.error,
      });
    }
  }

  handleChange = (name: any) => (event: any) => {
    // @ts-ignore
    let newState = { ...this.state, error: null, [name]: event.target.value };

    if (
      newState.oldPassword &&
      newState.oldPassword.length > 0 &&
      newState.newPassword &&
      newState.newPassword.length > 0 &&
      newState.newPassword === newState.newPasswordRepeat
    ) {
      newState = { ...newState, validChange: true };
    } else {
      newState = { ...newState, validChange: false };
    }

    this.setState(newState);
  };

  handleCommit = () => {
    const oldPwd = this.state.oldPassword;
    const newPwd = this.state.newPassword;
    this.resetState();
    this.props.userActions.changePassword(oldPwd, newPwd);
  };

  render() {
    const {} = this.props;

    return (
      <StyledDialog
        BackdropProps={{ className: classes.dialogBackdrop }}
        scroll="paper"
        open={this.props.showDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">{intl.get("editProfile.change_password_dialog.title")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ErrorView error={this.state.error} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={classes.section}>
                <Grid item xs={12} sm={6} container direction="column">
                  <SectionLabel>{intl.get("editProfile.change_password_dialog.header_old_password")}</SectionLabel>
                  <TextField
                    id="old"
                    className={classes.textField}
                    value={this.state.oldPassword}
                    type="password"
                    autoComplete="current-password"
                    onChange={this.handleChange("oldPassword")}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.section} spacing={1}>
                <Grid item sm={6} xs={12} container direction="column">
                  <SectionLabel>{intl.get("editProfile.change_password_dialog.header_new_password")}</SectionLabel>

                  <TextField
                    id="old"
                    className={classes.textField}
                    value={this.state.newPassword}
                    type="password"
                    onChange={this.handleChange("newPassword")}
                    margin="normal"
                  />
                </Grid>

                <Grid item sm={6} xs={12} container direction="column" className={classes.optionalTopPaddingSection}>
                  <SectionLabel>
                    {intl.get("editProfile.change_password_dialog.header_new_password_repeat")}
                  </SectionLabel>

                  <TextField
                    id="old"
                    className={classes.textField}
                    value={this.state.newPasswordRepeat}
                    type="password"
                    onChange={this.handleChange("newPasswordRepeat")}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.validChange || this.props.authenticationState.loading}
            onClick={this.handleCommit}
          >
            {intl.get("editProfile.change_password_dialog.change")}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              this.resetState();
              this.props.onClose();
            }}
            disabled={this.props.authenticationState.loading}
          >
            {intl.get("editProfile.change_password_dialog.cancel")}
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditPasswordDialog);
