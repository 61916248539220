import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";
import { ResellerProfile } from "../model/resellerProfile";

export const fetchAllProfiles = (page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/commission_recipients?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_RESELLER_PROFILES,
      payload: { data: true, resellers: res.data.resellers, paging: res.data.paging },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
  }
};

export const resetResellerProfileForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_RESELLER_PROFILE_FORM });
};

export const closeResellerProfile = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CLOSE_RESELLER_PROFILES });
};

// @ts-ignore
export const updateResellerProfileFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_RESELLER_PROFILE_FORM_FIELD,
    payload: { prop, value },
  };
};

export const createNewResellerProfile = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_PAYMENTPLAN_FORM });
  //const { companies } = (await axios.get('/v2/api/b2b/private/companies')).data;

  dispatch({ type: ActionType.CREATE_RESELLER_PROFILE, payload: {} });
};

export const editResellerProfileForUser = (user_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_PAYMENTPLAN_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/resellerprofileByUser/${user_id}`);
    //const { companies } = (await axios.get('/v2/api/b2b/private/companies')).data;
    //TODO: update data????
    dispatch({
      type: ActionType.EDIT_RESELLER_PROFILE,
      payload: { data: true, profile: res.data, profile_id: res.data.profile_id, user_id: res.data.user_id },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({ type: ActionType.PAGE_LOADING_ERROR, payload: { error: mapErrorFromException(error) } });
  }
};

export const saveResellerProfile =
  (profile: ResellerProfile, user_id: string, profile_id?: string | null) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      if (profile_id) {
        const res = await axios.put(`/v2/api/b2b/private/resellerprofile/${profile_id}`, { ...profile, user_id });
        dispatch({ type: ActionType.SAVE_RESELLER_PROFILE, payload: { profile: res.data } });
      } else {
        const res = await axios.post("/v2/api/b2b/private/resellerprofile", { ...profile, user_id });
        dispatch({ type: ActionType.SAVE_RESELLER_PROFILE, payload: { profile: res.data } });
      }
      dispatch({ type: ActionType.PAGE_SAVED });
    } catch (error) {
      dispatch({ type: ActionType.PAGE_SAVING_ERROR, payload: { error: mapErrorFromException(error) } });
    }
  };
