import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import TextFieldWithLabel from "../../../../../components/TextFieldWithLabel";
import intl from "react-intl-universal";
import * as React from "react";
import { Constants } from "../event_options";

export function EventEditTextInputField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  maxLength?: number;
  required?: boolean;
  onChange?: any;
  disabled?: boolean;
  type?: string;
}) {
  return (
    <TextFieldWithLabel
      type={props.type}
      required={props.required ?? false}
      maxLength={props.maxLength}
      label={intl.get(`event_form.label.${props.field}`)}
      id={props.field}
      margin="normal"
      disabled={props.formState.readonly || props.disabled}
      onChange={(event) => {
        props.formActions.updateEventFormField({
          prop: `event.${props.field}`,
          value: event.target.value,
        });
        if (props.onChange) {
          props.onChange(event);
        }
      }}
      value={Constants.fetchedValueFromPropPath(props.formState, props.field)}
    />
  );
}
