import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { EventUpgradeSlotsInfo } from "../model/eventState";
import * as CompanyActions from "../actions/company";
import { useEffect } from "react";
import useSelectedCompanyId from "./useSelectedCompanyId";

export default function useEventUpgradeSlotsInfo() {
  const data = useSelector<RootState, EventUpgradeSlotsInfo>((state) => state.eventState.eventUpgradeSlots);
  const dispatch = useDispatch();

  const selectedCompanyId = useSelectedCompanyId();

  useEffect(() => {
    if (!data) {
      dispatch<any>(CompanyActions.fetchUpgradeSlotsInfo(selectedCompanyId));
    }
  }, [selectedCompanyId]);

  return data;
}
