import BookingDrawerLayout, { BookingDrawerLayoutProps } from "./BookingDrawerLayout";
import { PaymentProduct } from "../../model/payment/payment.product";
import { DialogHandler } from "../dialog/useDialogHandler";
import BCSideDrawer from "./components/BCSideDrawer";
import * as React from "react";
import PurchaseConfirmationFlow from "./PurchaseConfirmationFlow";
import { BCEvent } from "../../model/event";
import { useState } from "react";
import BookingDrawerContactSuccessPage from "./components/BookingDrawerContactSuccessPage";
import ProductInfoSection from "./ProductInfoSection";
import useDialogReset from "../dialog/useDialogReset";
import BookingDrawerSuccessPage from "./components/BookingDrawerSuccessPage";
import BulkEventUpgradeForProductDrawerPage from "./bulkEventUpgrade/BulkEventUpgradeForProductDrawerPage";
import BookingDrawerLoadingIndicator from "./components/BookingDrawerLoadingIndicator";

interface Props extends BookingDrawerLayoutProps {
  handler: DialogHandler<PaymentProduct>;
  onSuccess?: () => void;
  event?: BCEvent;
}

type ViewState = "booking" | "success";

export default function PurchaseConfirmationDrawer({ handler, onSuccess, event }: Props) {
  const [viewState, setViewState] = useState<ViewState>("booking");

  useDialogReset(handler, () => {
    setViewState("booking");
  });

  let successPage = null;
  if (["social", "fai_newsletter"].indexOf(handler.payload?.product_sku_group) >= 0) {
    successPage = (
      <BookingDrawerContactSuccessPage
        event={event}
        infoSection={<ProductInfoSection product={handler.payload} />}
        onClose={handler.close}
      />
    );
  } else if (handler.payload?.product_sku_group?.indexOf("premium") >= 0) {
    successPage = (
      <BookingDrawerLayout infoSection={<ProductInfoSection product={handler.payload} />}>
        <BulkEventUpgradeForProductDrawerPage
          product={handler.payload}
          onSkip={handler.close}
          onSuccess={handler.close}
        />
      </BookingDrawerLayout>
    );
  } else {
    successPage = (
      <BookingDrawerSuccessPage
        infoSection={<ProductInfoSection product={handler.payload} />}
        onClose={handler.close}
      />
    );
  }

  const handlePurchaseSuccess = () => {
    if (!successPage) {
      onSuccess();
    } else {
      setViewState("success");
    }
  };

  return (
    <BCSideDrawer handler={handler}>
      {!handler.payload && <BookingDrawerLoadingIndicator />}
      {handler.payload && viewState === "booking" && (
        <PurchaseConfirmationFlow onSuccess={handlePurchaseSuccess} selectedProduct={handler.payload} event={event} />
      )}
      {handler.payload && viewState === "success" && successPage}
    </BCSideDrawer>
  );
}
