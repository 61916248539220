import { connect } from "react-redux";
import * as React from "react";
import { compose } from "redux";
import { AuthenticationState } from "../model/authenticationState";
import { RootState } from "../reducers";
import { CompanyState } from "../model/companyState";

export interface BaseProps {
  hasAnyPermission: string[];
  showForCompanyPageAsWell?: boolean;
}

export interface Props extends BaseProps {
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  children?: any;
}

function WithPermission({
  children,
  companyState,
  authenticationState,
  showForCompanyPageAsWell,
  hasAnyPermission,
}: Props) {
  if (companyState.show_company_detail_menu === true && showForCompanyPageAsWell !== true) {
    return null;
  }
  if (authenticationState.user && authenticationState.user.permissions) {
    const userPermissions = authenticationState.user.permissions;
    let hasPermission = false;
    for (const permission of hasAnyPermission) {
      if (userPermissions.indexOf(permission) !== -1) {
        hasPermission = true;
      }
    }
    if (hasPermission) {
      return <>{children}</>;
    }
  }
  return null;
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyState: state.companyState,
  };
}

export default compose(connect(mapStateToProps, null))(WithPermission);
