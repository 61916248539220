import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { AuthenticationState } from "../../../../../model/authenticationState";
import { Constants } from "../event_options";
import * as EventFormActions from "../../../../../actions/eventForm";
import { EventEditCheckboxField } from "../fields/EventEditCheckboxField";
import { EventEditSingleSelectField } from "../fields/EventEditSingleSelectField";
import { FormGridItem } from "../FormGridItem";
import { SectionLabel } from "../../../../../components/SectionLabel";
import intl from "react-intl-universal";
import { NumericFormat } from "react-number-format";
import { EventEditMultilineTextInputField } from "../fields/EventEditMultilineTextInputField";
import AppStyles from "../../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  authenticationState: AuthenticationState;
  eventFormActions: typeof EventFormActions;
}

class EventPricingInfoEdit extends React.Component<Props> {
  state = {};

  renderPriceLevel() {
    return (
      <EventEditSingleSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.priceLevelOptions()}
        field={"price_level"}
      />
    );
  }

  renderPrice() {
    return (
      <Grid item container direction="column" sx={AppStyles.noRightPadding}>
        <SectionLabel required={false}>{intl.get("event_form.label.price_in_cents")}</SectionLabel>
        <Grid item container direction="row">
          <NumericFormat
            value={this.props.eventFormState.event.price_in_cents}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            disabled={this.props.eventFormState.readonly}
            variant="outlined"
            style={{ width: "100%" }}
            onValueChange={(values) => {
              const { value } = values;

              if (value && value.length > 0) {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents",
                  value: Number(value),
                });
              } else {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents",
                  value: undefined,
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  renderPrice_3yr() {
    return (
      <Grid item container direction="column" sx={AppStyles.noRightPadding}>
        <SectionLabel required={false}>{intl.get("event_form.label.price_in_cents_3yr")}</SectionLabel>
        <Grid item container direction="row">
          <NumericFormat
            value={this.props.eventFormState.event.price_in_cents_3yr}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            disabled={this.props.eventFormState.readonly}
            variant="outlined"
            style={{ width: "100%" }}
            onValueChange={(values) => {
              const { value } = values;

              if (value && value.length > 0) {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents_3yr",
                  value: Number(value),
                });
              } else {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents_3yr",
                  value: undefined,
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  renderPrice_8yr() {
    return (
      <Grid item container direction="column" sx={AppStyles.noRightPadding}>
        <SectionLabel required={false}>{intl.get("event_form.label.price_in_cents_8yr")}</SectionLabel>
        <Grid item container direction="row">
          <NumericFormat
            value={this.props.eventFormState.event.price_in_cents_8yr}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            disabled={this.props.eventFormState.readonly}
            variant="outlined"
            style={{ width: "100%" }}
            onValueChange={(values) => {
              const { value } = values;

              if (value && value.length > 0) {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents_8yr",
                  value: Number(value),
                });
              } else {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents_8yr",
                  value: undefined,
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  renderPrice_Adult() {
    return (
      <Grid item container direction="column" sx={AppStyles.noRightPadding}>
        <SectionLabel required={false}>{intl.get("event_form.label.price_in_cents_adult")}</SectionLabel>
        <Grid item container direction="row">
          <NumericFormat
            value={this.props.eventFormState.event.price_in_cents_adult}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            disabled={this.props.eventFormState.readonly}
            variant="outlined"
            style={{ width: "100%" }}
            onValueChange={(values) => {
              const { value } = values;

              if (value && value.length > 0) {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents_adult",
                  value: Number(value),
                });
              } else {
                this.props.eventFormActions.updateEventFormField({
                  prop: "event.price_in_cents_adult",
                  value: undefined,
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  renderCurrency() {
    return (
      <EventEditSingleSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.priceCurrencyOptions()}
        field={"price_currency"}
      />
    );
  }

  renderIsStartingPrice() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_starting_price"}
      />
    );
  }

  renderPriceOnRequest() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"price_on_request"}
      />
    );
  }

  renderPricingNotes() {
    return (
      <EventEditMultilineTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"notes_pricing"}
        maxLength={1000}
        rows={5}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    const disableControls = this.props.eventFormState.readonly;

    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={8}>{this.renderPriceLevel()}</FormGridItem>
          <FormGridItem md={4}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={4}>{this.renderPrice()}</FormGridItem>
          <FormGridItem md={4}>{this.renderCurrency()}</FormGridItem>
          <FormGridItem md={4}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={4}>{this.renderIsStartingPrice()}</FormGridItem>
          <FormGridItem md={4}>{this.renderPriceOnRequest()}</FormGridItem>
          <FormGridItem md={4}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={4}>{this.renderPrice_3yr()}</FormGridItem>
          <FormGridItem md={4}>{this.renderPrice_8yr()}</FormGridItem>
          <FormGridItem md={4}>{this.renderPrice_Adult()}</FormGridItem>
          <FormGridItem>{this.renderPricingNotes()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventPricingInfoEdit;
