import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";

export default function DialogShowInvoiceCharges({
  open,
  onClose,
  formattedCharges,
}: {
  open: boolean;
  onClose: () => void;
  formattedCharges: string;
}) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{intl.get("global_invoices.charges_title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <pre>
            <code>{formattedCharges}</code>
          </pre>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {intl.get("global_invoices.charges_close_button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
