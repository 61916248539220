import AppStyles from "../../styles/appStyles";
import { LinearProgress, Paper, Stack, Typography } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import useEventFormValidation from "./useEventFormValidation";

export default function EventFormProgress() {
  const { progress, color } = useEventFormValidation();

  return (
    <Paper sx={[AppStyles.innerContentPaper, { flexGrow: 1, px: "20px", py: "12px" }]}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={"20px"}>
        <Typography sx={{ font: "normal normal bold 11px/15px Manrope" }}>
          {intl.get("event_form.progress").toUpperCase()}
        </Typography>
        <LinearProgress
          sx={{
            flexGrow: 1,
            height: "8px",
            borderRadius: "4px",
            color: `rgb(${color})`,
          }}
          variant={"determinate"}
          value={progress}
          color={"inherit"}
        />
      </Stack>
    </Paper>
  );
}
