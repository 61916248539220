import BookingDrawerLayout from "../BookingDrawerLayout";
import BookingDrawerEventHeader from "../components/BookingDrawerEventHeader";
import useDialogReset from "../../dialog/useDialogReset";
import { useEffect } from "react";
import { useCalendarWeekSelection } from "../../calendarWeekSelection/useCalendarWeekSelection";
import HeroSlotsDrawerBackendResponse from "./HeroSlotsDrawerBackendResponse";
import { parse } from "date-fns";
import DrawerContentHeroBooking from "./DrawerContentHeroBooking";
import HeroDrawerInfoSection from "./HeroDrawerInfoSection";
import { BookingDrawerHandlerPayload } from "../BookingDrawer";
import useBackendLoader from "../../../hooks/useBackendLoader";
import BookingDrawerLoaderWrapper from "../components/BookingDrawerLoaderWrapper";
import useCompanyEvents from "../../../hooks/useCompanyEvents";
import { useDialogContext } from "../../dialog/DialogContext";
import BookingDrawerErrorContent from "../components/BookingDrawerErrorContent";

const mapResponse = (result: HeroSlotsDrawerBackendResponse) => {
  return {
    ...result,
    slots:
      result?.slots?.map(
        (slot) =>
          ({
            ...slot,
            slot: {
              ...slot.slot,
              parsedSlotStartDate: parse(slot.slot.slot_start_date, "yyyyMMdd", new Date()),
              parsedSloEndDate: parse(slot.slot.slot_end_date, "yyyyMMdd", new Date()),
            },
          }) as any,
      ) || [],
  };
};

export default function HeroSlotsDrawerContent() {
  const { handler } = useDialogContext<BookingDrawerHandlerPayload>();
  const event = handler.payload?.event;
  const companyId = handler.payload?.companyId;

  const loader = useBackendLoader<HeroSlotsDrawerBackendResponse>(
    `/v2/api/b2b/private/companies/${companyId}/events/${event.event_id}/hero`,
    mapResponse,
    "HeroSlots",
  );

  const { loadEvents: refreshEvents } = useCompanyEvents(false);

  const availableSlots = loader.data?.available_slots || 0;

  const calendarHandler = useCalendarWeekSelection(
    loader.data?.slots?.filter((slot) => slot.available) || [],
    loader.data?.slots?.filter((slot) => slot.booked) || [],
    availableSlots,
  );

  useDialogReset(handler, () => {
    calendarHandler.clear();
    loader.reload();
  });

  useEffect(() => {
    calendarHandler.clear();
  }, [handler.payload]);

  const handleSuccess = () => {
    handler.close();
    refreshEvents();
  };

  if (loader.error) {
    return <BookingDrawerErrorContent error={loader.error} infoSection={<HeroDrawerInfoSection />} />;
  }

  return (
    <BookingDrawerLayout infoSection={<HeroDrawerInfoSection />}>
      <BookingDrawerEventHeader event={event} sx={{ marginBottom: "41px" }} />
      <BookingDrawerLoaderWrapper loader={loader}>
        {!loader.loading && loader.data && (
          <DrawerContentHeroBooking
            data={loader.data}
            handler={calendarHandler}
            onSuccess={handleSuccess}
            eventId={event.event_id}
            companyId={companyId}
          />
        )}
      </BookingDrawerLoaderWrapper>
    </BookingDrawerLayout>
  );
}
