import BookingDrawerLayout, { BookingDrawerLayoutProps } from "./BookingDrawerLayout";
import { useDispatch } from "react-redux";
import * as ProductActions from "../../actions/company.product.actions";
import PurchaseConfirmationDrawerPage from "./PurchaseConfirmationDrawerPage";
import { PaymentProduct } from "../../model/payment/payment.product";
import useSelectedCompanyId from "../../hooks/useSelectedCompanyId";
import ProductInfoSection from "./ProductInfoSection";
import { useDialogContext } from "../dialog/DialogContext";
import { BCEvent } from "../../model/event";
import BookingDrawerHeader from "./components/BookingDrawerHeader";

interface Props extends BookingDrawerLayoutProps {
  onSuccess?: () => void;
  onCancel?: () => void;
  selectedProduct: PaymentProduct;
  event?: BCEvent;
}

export default function PurchaseConfirmationFlow({ onSuccess, selectedProduct, onCancel, event }: Props) {
  const { handler } = useDialogContext<PaymentProduct>();
  const selectedCompanyId = useSelectedCompanyId();

  const dispatch = useDispatch();

  const activateProduct = async () => {
    const success = await dispatch<any>(
      ProductActions.activateProduct(selectedCompanyId, selectedProduct.product_id, event),
    );
    if (success === true) {
      if (onSuccess) {
        onSuccess();
      } else {
        handler.close();
      }
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      handler.close();
    }
  };

  if (!selectedProduct) {
    return null;
  }

  return (
    <BookingDrawerLayout infoSection={<ProductInfoSection product={selectedProduct} />}>
      <BookingDrawerHeader event={event} />
      <PurchaseConfirmationDrawerPage
        productSku={selectedProduct.product_sku}
        onCancel={handleCancel}
        onConfirm={activateProduct}
      />
    </BookingDrawerLayout>
  );
}
