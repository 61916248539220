import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import axios from "axios";
import { mapErrorFromException } from "../model/error";
import intl from "react-intl-universal";

export const fetchAllGlobalInvoices = (page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/invoices?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_GLOBAL_INVOICES,
      payload: {
        data: true,
        invoices: res.data.invoices,
        paging: res.data.paging,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const markInvoiceAsPaid = (invoice_id: string, page: number, size: number) => async (dispatch: Dispatch) => {
  try {
    await axios.put(`/v2/api/b2b/private/invoices/${invoice_id}/status`, { status: "PAID" });

    const res = await axios.get(`/v2/api/b2b/private/invoices?page=${page}&size=${size}`);
    dispatch({
      type: ActionType.FETCH_GLOBAL_INVOICES,
      payload: {
        data: true,
        invoices: res.data.invoices,
        paging: res.data.paging,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: {
        error: { errorMessage: intl.get("global_invoices.mark_as_paid_confirmation_dialog.error") },
      },
    });
  }
};
