import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { PaymentProduct } from "../model/payment/payment.product";
import { useEffect, useState } from "react";
import * as CompanyProductActions from "../actions/company.product.actions";
import useSelectedCompanyId from "./useSelectedCompanyId";

export default function useAvailablePaymentProducts() {
  const dispatch = useDispatch();
  const companyId = useSelectedCompanyId();

  const availableProducts = useSelector<RootState, PaymentProduct[]>((rootState) => {
    return rootState.paymentState.availableProducts;
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadProducts = async () => {
    setLoading(true);
    const responseError = await dispatch<any>(CompanyProductActions.fetchAvailablePaymentProducts(companyId));
    setLoading(false);
    setError(responseError);
  };

  useEffect(() => {
    if (!availableProducts || availableProducts.length === 0) {
      loadProducts();
    }
  }, []);

  return {
    loading,
    availableProducts: availableProducts || [],
    error,
    refresh: loadProducts,
  };
}
