import { useState } from "react";
import { PagingInformation } from "../model/pagingInformation";

export interface PaginationHandler {
  currentPage: number;
  pageSize: number;
  setCurrentPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  pagingState: PagingInformation;
}

export default function usePagination(pagingState: PagingInformation, defaultPageSize: number = 25): PaginationHandler {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pagingState,
  };
}
