import { useEffect, useState } from "react";
import { ItemSelectionHandler } from "./useItemSelectionHandler";

export interface SelectionHandler<KeyType> {
  selectAll: () => void;
  deselectAll: () => void;
  setSelectedItemsIds: (selectedIds: Set<KeyType>) => void;
  forItem: (itemId: KeyType) => ItemSelectionHandler;
  isSelected: (itemId: KeyType) => boolean;
  setSelected: (itemId: KeyType, selected: boolean) => void;
  toggleSelected: (itemId: KeyType) => void;
  selectedItemIds: Set<KeyType> | undefined;
  selectedItemCount: number | undefined;
  enableSelectAll: boolean;
  enableDeselectAll: boolean;
  canAddMoreItems: boolean;
}

export default function useSelectionHandler<KeyType>(
  itemIds: KeyType[],
  maxSelectedItems?: number,
  onChangeListener?: (selectedItemIds: Set<KeyType>, deselectedItemIds: Set<KeyType>) => void,
  defaultValue: Set<KeyType> = new Set<KeyType>(itemIds),
): SelectionHandler<KeyType> {
  const [selectedItemIds, setSelectedItemsIds] = useState<Set<KeyType>>(new Set<KeyType>(defaultValue));

  const canAddMoreItems = maxSelectedItems === undefined || selectedItemIds.size < maxSelectedItems;

  useEffect(() => {
    if (onChangeListener) {
      onChangeListener(selectedItemIds, new Set(itemIds.filter((id) => !selectedItemIds.has(id))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemIds]);

  const toggleSelected = (itemId: KeyType) => {
    setSelectedItemsIds((previous) => {
      const newSet = new Set(previous);
      if (previous.has(itemId)) {
        newSet.delete(itemId);
      } else if (maxSelectedItems === undefined || newSet.size < maxSelectedItems) {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  const isSelected = (itemId: KeyType): boolean => selectedItemIds.has(itemId);

  const selectAll = () => {
    setSelectedItemsIds(new Set(itemIds));
  };

  const deselectAll = () => {
    setSelectedItemsIds(new Set<KeyType>());
  };

  const setSelected = (itemId: KeyType, selected: boolean) => {
    if ((isSelected(itemId) && !selected) || (!isSelected(itemId) && selected)) {
      toggleSelected(itemId);
    }
  };

  const forItem = (itemId: KeyType): ItemSelectionHandler => ({
    isSelected: isSelected(itemId),
    toggleSelected: () => toggleSelected(itemId),
    setSelected: (selected: boolean) => setSelected(itemId, selected),
  });

  return {
    selectAll,
    deselectAll,
    toggleSelected,
    isSelected,
    setSelected,
    forItem,
    setSelectedItemsIds,
    selectedItemIds,
    selectedItemCount: selectedItemIds.size,
    enableDeselectAll: selectedItemIds.size > 0,
    enableSelectAll:
      (maxSelectedItems === undefined || maxSelectedItems >= itemIds.length) && selectedItemIds.size < itemIds.length,
    canAddMoreItems,
  };
}
