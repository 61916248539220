import { PagingInformation } from "../model/pagingInformation";
import { TableFooter, TableRow } from "@mui/material";
import { Pagination } from "./TablePagination";
import * as React from "react";

export default function TablePaginationFooter({
  count,
  paging,
  onChangePage,
  onChangeRowsPerPage,
}: {
  count: number;
  paging: PagingInformation;
  onChangePage: (number) => void;
  onChangeRowsPerPage: (number) => void;
}) {
  if (count === 0) {
    return null;
  }
  return (
    <TableFooter>
      <TableRow>
        <Pagination
          paging={paging}
          handleChangePage={(event, page) => {
            onChangePage(page + 1);
          }}
          handleChangeRowsPerPage={(event) => {
            onChangeRowsPerPage(event.target.value);
          }}
        />
      </TableRow>
    </TableFooter>
  );
}
