import { Grid, Paper, Stack, Typography } from "@mui/material";
import AppStyles from "../../../../styles/appStyles";
import intl from "react-intl-universal";
import * as React from "react";
import useHeightGroup from "../../../../hooks/useHeightGroup";
import ProductInformationCard from "../../../../components/booking/ProductInfoCard";
import TopPlacementIcon from "../../../../assets/icons/TopPlacement";
import HeroIcon from "../../../../assets/icons/Hero";
import SocialMediaIcon from "../../../../assets/icons/SocialMedia";
import NewsletterIcon from "../../../../assets/icons/Newsletter";
import useDialogHandler from "../../../../components/dialog/useDialogHandler";
import EventUpgradeButton, { EventUpgradeButtonComponentProps } from "../../../../components/events/EventUpgradeButton";
import BookingDrawer, { BookingDrawerHandlerPayload } from "../../../../components/drawer/BookingDrawer";
import NewsletterDrawer from "../../../../components/drawer/newsletter/NewsletterDrawer";
import SocialMediaDrawer from "../../../../components/drawer/social/SocialMediaDrawer";
import { BCEvent } from "../../../../model/event";
import useSelectedCompanyId from "../../../../hooks/useSelectedCompanyId";
import TopPlacementActiveIcon from "../../../../assets/icons/TopPlacementActive";
import HeroActive from "../../../../assets/icons/HeroActive";

export default function EventEditTopPlacementTab({ event }: { event?: BCEvent }) {
  const newsletterHandler = useDialogHandler<BCEvent>();
  const socialHandler = useDialogHandler<BCEvent>();
  const bookingHandler = useDialogHandler<BookingDrawerHandlerPayload>();

  const companyId = useSelectedCompanyId();

  return (
    <Stack sx={AppStyles.detailFormWithoutBottomPadding}>
      {!event?.event_id && <MessageCardSaveFirst />}
      <Paper
        sx={{
          ...AppStyles.centerPaper,
          ...AppStyles.innerContentPaper,
          boxShadow: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
          paddingTop: "34px",
          paddingBottom: "34px",
          marginLeft: 0,
          marginRight: 0,
          backgroundColor: "#FFFFFF",
        }}
      >
        <Stack width={"100%"}>
          <Typography sx={{ font: "normal normal 800 30px/35px Manrope" }}>
            {intl.get(`event_form.top_placement.title`)}
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontSize: theme.fontSizes.biggerFont,
              fontWeight: 700,
              marginTop: "11px",
              marginBottom: "26px",
            })}
          >
            {intl.get("product_information.benefits_title")}
          </Typography>

          <ProductBanner
            event={event}
            disabled={!event?.event_id}
            onTopPlacementInfo={() => {
              bookingHandler.open({
                event,
                intent: "top_placement",
                companyId: event.company?.company_id || companyId,
              });
            }}
            onHeroInfo={() => {
              bookingHandler.open({ event, intent: "hero_slots", companyId: event.company?.company_id || companyId });
            }}
            onSocialMediaPackageInfo={() => {
              socialHandler.open(event);
            }}
            onNewsletterInfo={() => {
              newsletterHandler.open(event);
            }}
          />
        </Stack>
      </Paper>
      <NewsletterDrawer handler={newsletterHandler} />
      <SocialMediaDrawer handler={socialHandler} />
      <BookingDrawer handler={bookingHandler} />
    </Stack>
  );
}

function ProductBanner({
  onTopPlacementInfo,
  onHeroInfo,
  onSocialMediaPackageInfo,
  onNewsletterInfo,
  loading,
  disabled,
  event,
}: {
  onTopPlacementInfo: () => void;
  onHeroInfo: () => void;
  onSocialMediaPackageInfo: () => void;
  onNewsletterInfo: () => void;
  loading?: boolean;
  disabled?: boolean;
  event?: BCEvent;
}) {
  const heightGroup = useHeightGroup();

  const hasPremium = Boolean(event?.event_upgrade && event?.event_upgrade !== "none");

  const hasHeroBooking = Boolean(event?.booked_hero_slots && event?.booked_hero_slots?.length > 0);
  const heroText = hasHeroBooking
    ? `${intl.get("event_form.top_placement.hero_weeks_prefix")} ${event.booked_hero_slots
        .map((slot) => slot.slot_week)
        .sort((a, b) => a - b)
        .join(", ")}`
    : intl.get("product_information.hero.text");

  return (
    <Grid container justifyContent={"stretch"} gap={4}>
      <Grid item xs={12} md>
        <EventUpgradeButton
          event={event || ({} as any)}
          heightGroup={heightGroup}
          onTopPlacementClicked={onTopPlacementInfo}
          component={UpgradeButtonComponent}
          loading={loading}
          disabled={disabled}
          highlight={!hasPremium}
        />
      </Grid>
      <Grid item xs={12} md>
        <ProductInformationCard
          title={intl.get("product_information.hero.title")}
          text={heroText}
          active={hasHeroBooking}
          buttonText={intl.get(
            hasHeroBooking ? "event_form.top_placement.button_book_more" : "event_form.top_placement.button_book_now",
          )}
          icon={
            hasHeroBooking ? (
              <HeroActive sx={{ width: "100%", height: "35px" }} />
            ) : (
              <HeroIcon sx={{ width: "100%", height: "35px" }} />
            )
          }
          onButtonClick={onHeroInfo}
          titleHeightGroup={heightGroup}
          loading={loading}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md>
        <ProductInformationCard
          title={intl.get("product_information.social_media_package.title")}
          text={intl.get("product_information.social_media_package.text")}
          buttonText={intl.get("event_form.top_placement.button_book_now")}
          icon={<SocialMediaIcon sx={{ width: "100%", height: "33px" }} />}
          onButtonClick={onSocialMediaPackageInfo}
          titleHeightGroup={heightGroup}
          loading={loading}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md>
        <ProductInformationCard
          title={intl.get("product_information.newsletter.title")}
          text={intl.get("product_information.newsletter.text")}
          buttonText={intl.get("event_form.top_placement.button_book_now")}
          icon={<NewsletterIcon sx={{ width: "100%", height: "27px" }} />}
          onButtonClick={onNewsletterInfo}
          titleHeightGroup={heightGroup}
          loading={loading}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

function UpgradeButtonComponent({
  onClick,
  loading,
  active,
  disabled,
  event,
  canUpgradeImmediately,
  heightGroup,
  highlight,
}: EventUpgradeButtonComponentProps) {
  let buttonText: string;
  let text: string;

  switch (event?.event_upgrade) {
    case "premium":
      text =
        event?.event_upgrade_suspended === true
          ? intl.get("event_form.top_placement.premium_suspended")
          : intl.get("event_form.top_placement.premium_active");
      buttonText = intl.get("event_form.top_placement.button_deactivate_now");
      break;
    case "premium_plus":
      text =
        event?.event_upgrade_suspended === true
          ? intl.get("event_form.top_placement.premium_plus_suspended")
          : intl.get("event_form.top_placement.premium_plus_active");
      buttonText = intl.get("event_form.top_placement.button_deactivate_now");
      break;
    default:
      text = intl.get("product_information.top_placement.text");
      buttonText = intl.get(
        canUpgradeImmediately
          ? "event_form.top_placement.button_activate_now"
          : "event_form.top_placement.button_book_now",
      );
      break;
  }

  return (
    <ProductInformationCard
      showPrimaryLineGradient={event?.event_upgrade_suspended === true}
      title={intl.get("product_information.top_placement.title")}
      text={text}
      buttonText={buttonText}
      icon={
        active ? (
          <TopPlacementActiveIcon sx={{ width: "100%", height: "26px" }} />
        ) : (
          <TopPlacementIcon sx={{ width: "100%", height: "26px" }} />
        )
      }
      onButtonClick={onClick}
      titleHeightGroup={heightGroup}
      loading={loading}
      disabled={disabled || !event}
      highlight={highlight}
      active={active}
    />
  );
}

function MessageCardSaveFirst() {
  return (
    <Paper
      sx={{
        ...AppStyles.centerPaper,
        ...AppStyles.innerContentPaper,
        boxShadow: "none",
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "34px",
        paddingBottom: "34px",
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Typography color={"error"}>{intl.get("event_form.top_placement.message_save_first")}</Typography>
    </Paper>
  );
}
