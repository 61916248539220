import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { AuthenticationState } from "../model/authenticationState";

export default function useUser() {
  const authenticationState = useSelector<RootState, AuthenticationState>((rootState) => {
    return rootState.authenticationState;
  });

  const user = authenticationState.user;
  const permissions = user?.permissions || [];

  function hasPermission(permission: string): boolean {
    return permissions.indexOf(permission) !== -1;
  }

  function hasRole(role: string): boolean {
    return user?.role === role;
  }

  function hasOneOfRoles(roles: string[]): boolean {
    let found = false;

    roles.forEach((role) => {
      if (hasRole(role)) {
        found = true;
      }
    });
    return found;
  }

  return {
    ...user,
    hasPermission,
    hasRole,
    hasOneOfRoles,
  };
}
