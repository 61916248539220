import {
  Box,
  Divider,
  Grow,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Theme,
} from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "throttle-debounce";
import * as intl from "react-intl-universal";
import { errorMessageFromError } from "../../model/error";
import { RootState } from "../../reducers";
import * as SearchActions from "../../actions/search";
import { SearchState } from "../../model/searchState";
import { BCEvent } from "../../model/event";
import * as CompanyActions from "../../actions/company";
import { AuthenticationState } from "../../model/authenticationState";
import { useNavigate } from "react-router-dom";

const classes = {
  search: (theme: Theme) => ({
    color: theme.palette.primary.main,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.7),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 1,
      width: "auto",
    },
    marginRight: "24px",
    paddingLeft: 2,
  }),

  searchIcon: {
    width: 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    color: "inherit",
    width: "100%",
  },

  inputInput: (theme: Theme) => ({
    paddingTop: 1,
    paddingRight: 1,
    paddingBottom: 1,
    paddingLeft: 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "120px",
      "&:focus": {
        width: "200px",
      },
    },
  }),

  searchResultContent: (theme: Theme) => ({
    width: "100%",
    maxWidth: "600px",
    minWidth: "300px",
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  }),

  searchResultSectionHeader: (theme: Theme) => ({
    fontWeight: 600,
    fontSize: theme.fontSizes.smallFont,
  }),

  searchResultError: (theme: Theme) => ({
    color: theme.palette.error.main,
  }),

  deletedEntry: (theme: Theme) => ({
    color: theme.colors.red,
  }),
};

export interface Props {
  searchState: SearchState;
  searchActions: typeof SearchActions;
  companyActions: typeof CompanyActions;
  authenticationState: AuthenticationState;
}

export interface State {
  query: string;
  show_result_dialog: boolean;
  anchorElement: any;
  cancelSearch: boolean;
}

function HeaderSearch(props: Props) {
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [query, setQuery] = useState("");
  const [cancelSearch, setCancelSearch] = useState(false);

  const navigate = useNavigate();
  const autocompleteSearch = (mQuery: string) => {
    if (cancelSearch === false) {
      searchForResults(mQuery);
    }
  };

  const autocompleteSearchDebounced = useRef(debounce(250, autocompleteSearch));

  const inputRef = useRef();

  const searchForResults = (mQuery: string) => {
    props.searchActions.search(mQuery);
    setShowResultDialog(true);
  };

  const clearSearch = () => {
    props.searchActions.updateSearchQuery("");
    setQuery("");
    setShowResultDialog(false);
    setCancelSearch(true);
  };

  const changeQuery = (event: any) => {
    props.searchActions.updateSearchQuery(event.target.value);
    setCancelSearch(false);
    setQuery(event.target.value);
  };

  // replaces setState callback
  useEffect(() => {
    autocompleteSearchDebounced.current(query);
  }, [query]);

  const openCompany = (company_id: string) => {
    // BC-250: just pushing the new path doesn't work.
    // Because when the user is already on say /1/company_events the page will not be reloaded to show the new page /2/company_events
    // So we have first show another page and then navigate to the /2/company_events page
    navigate("/", { replace: true });
    setTimeout(() => {
      navigate(`/companies/${company_id}/company_events`);
    }, 1);
  };

  return (
    <Box sx={classes.search}>
      <InputBase
        startAdornment={
          <Box sx={classes.searchIcon}>
            <SearchIcon />
          </Box>
        }
        ref={inputRef}
        placeholder={intl.get("global_search.placeholder")}
        sx={classes.inputRoot}
        inputProps={{ sx: classes.inputInput }}
        value={query}
        onChange={changeQuery}
        onBlur={clearSearch}
      />

      <Popper open={showResultDialog} anchorEl={inputRef.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper sx={classes.searchResultContent}>
              {props.searchState.performing_search && <LinearProgress />}
              {props.searchState.error && props.searchState.search_query.length > 0 && (
                <ListItem>
                  <ListItemText
                    sx={classes.searchResultError}
                    primary={errorMessageFromError(props.searchState.error, intl.get("global_search.failed"))}
                    disableTypography={true}
                  />
                </ListItem>
              )}
              {!props.searchState.error && props.searchState.performing_search === false && (
                <List component="nav" sx={classes.searchResultContent}>
                  <CompanySearchResultsSection {...props} onCompanyClicked={openCompany} />

                  <>
                    <ListSubheader sx={classes.searchResultSectionHeader}>
                      {intl.get("global_search.results.events")}
                    </ListSubheader>
                    <Divider />
                  </>

                  <EventResults {...props} />

                  <ListItem>
                    <ListItemText primary="" />
                  </ListItem>

                  <DeletedCompaniesSearchResultsSection {...props} onCompanyClicked={openCompany} />
                </List>
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

interface CompanyComponentProps extends Props {
  onCompanyClicked: (company_id: string) => void;
}

function DeletedCompanyResults({ onCompanyClicked, ...props }: CompanyComponentProps) {
  return (
    <>
      {props.searchState.deleted_companies.map((value) => {
        return (
          <ListItem
            button
            key={`search_${value.company_id}`}
            onClick={() => {
              onCompanyClicked(value.company_id);
            }}
          >
            <ListItemText sx={classes.deletedEntry} primary={value.name} />
          </ListItem>
        );
      })}
      {props.searchState.additional_deleted_company_count === 1 && (
        <ListItem>
          <ListItemText secondary={intl.get("global_search.results.one_additional_result")} />
        </ListItem>
      )}
      {props.searchState.additional_deleted_company_count > 1 && (
        <ListItem>
          <ListItemText
            secondary={intl.get("global_search.results.multiple_additional_results", {
              result_count: props.searchState.additional_deleted_company_count,
            })}
          />
        </ListItem>
      )}
    </>
  );
}

function CompanyResults({ onCompanyClicked, ...props }: CompanyComponentProps) {
  if (!props.searchState.performing_search) {
    if (!props.searchState.companies || props.searchState.companies.length === 0) {
      return (
        <ListItem>
          <ListItemText secondary={intl.get("global_search.results.no_results_found")} />
        </ListItem>
      );
    }
    const { companies } = props.searchState;
    return (
      <>
        {companies.map((value) => {
          return (
            <ListItem
              button
              key={`search_${value.company_id}`}
              onClick={() => {
                onCompanyClicked(value.company_id);
              }}
            >
              <ListItemText primary={value.name} />
            </ListItem>
          );
        })}
        {props.searchState.additional_company_count === 1 && (
          <ListItem>
            <ListItemText secondary={intl.get("global_search.results.one_additional_result")} />
          </ListItem>
        )}
        {props.searchState.additional_company_count > 1 && (
          <ListItem>
            <ListItemText
              secondary={intl.get("global_search.results.multiple_additional_results", {
                result_count: props.searchState.additional_company_count,
              })}
            />
          </ListItem>
        )}
      </>
    );
  }
  return null;
}

function EventResults(props: Props) {
  const navigate = useNavigate();
  const openEvent = (event: BCEvent, company_id: string) => {
    // see comment above -> BC-250
    navigate("/", { replace: true });
    setTimeout(() => {
      if (props.authenticationState.user.role === "REVIEWER") {
        props.companyActions.selectCompany(company_id, true);
        props.companyActions.fetchCompanyInformation(company_id);
        navigate(`/companies/${company_id}/company_events/${event.event_id}/review`);
      } else {
        navigate(`/companies/${company_id}/company_events/${event.event_id}/edit`);
      }
    }, 1);
  };

  if (!props.searchState.performing_search) {
    if (!props.searchState.events || props.searchState.events.length === 0) {
      return (
        <ListItem>
          <ListItemText secondary={intl.get("global_search.results.no_results_found")} />
        </ListItem>
      );
    }
    const { events } = props.searchState;
    return (
      <>
        {events.map((value) => {
          return (
            <ListItem
              button
              key={`search_${value.event_id}`}
              onClick={() => {
                openEvent(value, value.company.company_id);
              }}
            >
              <ListItemText primary={value.title} secondary={value.company.name} />
            </ListItem>
          );
        })}
        {props.searchState.additional_event_count === 1 && (
          <ListItem>
            <ListItemText secondary={intl.get("global_search.results.one_additional_result")} />
          </ListItem>
        )}
        {props.searchState.additional_event_count > 1 && (
          <ListItem>
            <ListItemText
              secondary={intl.get("global_search.results.multiple_additional_results", {
                result_count: props.searchState.additional_event_count,
              })}
            />
          </ListItem>
        )}
      </>
    );
  }
  return null;
}

function CompanySearchResultsSection(props: CompanyComponentProps) {
  return (
    <>
      <ListSubheader sx={classes.searchResultSectionHeader}>
        {intl.get("global_search.results.companies")}
      </ListSubheader>
      <Divider />

      <CompanyResults {...props} />

      <ListItem>
        <ListItemText primary="" />
      </ListItem>
    </>
  );
}

function DeletedCompaniesSearchResultsSection(props: CompanyComponentProps) {
  if (!props.searchState.performing_search) {
    if (props.searchState.deleted_companies && props.searchState.deleted_companies.length > 0) {
      return (
        <>
          <ListSubheader sx={classes.searchResultSectionHeader}>{"Gelöschte Firmen"}</ListSubheader>
          <Divider />

          <DeletedCompanyResults {...props} />

          <ListItem>
            <ListItemText primary="" />
          </ListItem>
        </>
      );
    }
  }
  return null;
}

function mapStateToProps(state: RootState) {
  return {
    searchState: state.searchState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    searchActions: bindActionCreators(SearchActions as any, dispatch),
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
