import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Check";
import SeoPageEditForm from "./components/forms/SeoPageEditForm";
import { RootState } from "../reducers";
import * as SeoPageActions from "../actions/seoPage";
import { BackButton, DefaultButton, DeleteButton } from "../components/Buttons";
import { AuthenticationState } from "../model/authenticationState";
import { SeoPageFormState } from "../model/seoPageFormState";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AppStyles from "../styles/appStyles";

export interface Props {
  seoPageFormState: SeoPageFormState;
  authState: AuthenticationState;
  actions: typeof SeoPageActions;
}

export interface State {
  deleteSeoPageConfirmationVisible: boolean;
}

function SeoPageEditPage(props: Props) {
  const [supportBackAction, setSupportBackAction] = useState(false);
  const [deleteSeoPageConfirmationVisible, setDeleteSeoPageConfirmationVisible] = useState(false);
  const [closing, setClosing] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const closePage = () => {
    navigate(-1);
  };

  const deleteSeoPage = () => {
    if (props.seoPageFormState.seopage_id != null) {
      props.actions.deleteSeoPage(props.seoPageFormState.seopage_id);
      setDeleteSeoPageConfirmationVisible(false);
    }
  };

  const confirmSeoPageDeletion = () => {
    setDeleteSeoPageConfirmationVisible(true);
  };

  const cancelSeoPageDeletion = () => {
    setDeleteSeoPageConfirmationVisible(false);
  };

  useEffect(() => {
    if (params == null) {
      props.actions.createNewSeoPage();
      setSupportBackAction(true);
    } else {
      const seopageId = params.seopage_id;

      if (seopageId) {
        setSupportBackAction(true); // for implementing readonly: this.props.location.pathname.endsWith("/edit")
        props.actions.editSeoPage(seopageId);
      } else {
        setSupportBackAction(true);
        props.actions.createNewSeoPage();
      }
    }
  }, []);

  const saveChanges = () => {
    props.actions.saveSeoPage(props.seoPageFormState.seopage, props.seoPageFormState.seopage_id);
  };

  if (closing) {
    return <div />;
  }
  if (props.seoPageFormState.closePage && supportBackAction) {
    props.actions.resetSeoPageForm();
    props.actions.closeSeoPage();
    closePage();
    setClosing(true);
    return <div />;
  }

  const showReadonly = false;
  const canDeleteSeoPage = true;

  const disableSave = showReadonly || !props.seoPageFormState.valid;

  return (
    <>
      <PageTracker />
      <SeoPageEditForm
        readonly={showReadonly}
        showResellerSelection={
          props.authState.user.role &&
          props.authState.user.role !== "RESELLER" &&
          props.authState.user.role !== "INTERNAL_SALES_MANAGER"
        }
      >
        <Grid container direction="row">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.actionHeaderLeft}>
              <BackButton
                title={
                  props.seoPageFormState.seopage_id
                    ? intl.get("seopage_form.title_edit")
                    : intl.get("seopage_form.title_create")
                }
                onClick={() => {
                  closePage();
                }}
              />
            </Grid>

            <Grid item sx={AppStyles.headerRight}>
              {props.seoPageFormState.seopage_id && canDeleteSeoPage && (
                <DeleteButton
                  disabled={showReadonly}
                  onClick={() => {
                    confirmSeoPageDeletion();
                  }}
                >
                  <ClearIcon sx={AppStyles.buttonLeftIcon} />
                  {intl.get("seopage_form.button_delete")}
                </DeleteButton>
              )}

              <DefaultButton
                style={{
                  marginLeft: props.seoPageFormState.seopage_id && canDeleteSeoPage ? 10 : 0,
                }}
                disabled={disableSave}
                onClick={() => {
                  saveChanges();
                }}
              >
                <SaveIcon sx={AppStyles.buttonLeftIcon} />
                {props.seoPageFormState.seopage_id
                  ? intl.get("seopage_form.button_save")
                  : intl.get("seopage_form.button_create")}
              </DefaultButton>
            </Grid>
          </Grid>
        </Grid>
      </SeoPageEditForm>
      {deleteSeoPageConfirmationVisible && (
        <Dialog
          open={deleteSeoPageConfirmationVisible}
          onClose={() => cancelSeoPageDeletion()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.get("seopage_form.delete_confirmation.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.get("seopage_form.delete_confirmation.message", {
                seopage_title: props.seoPageFormState.seopage.title,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelSeoPageDeletion()} color="primary">
              {intl.get("seopage_form.delete_confirmation.button_cancel")}
            </Button>
            <Button onClick={() => deleteSeoPage()} color="error" autoFocus>
              {intl.get("seopage_form.delete_confirmation.button_confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    seoPageFormState: state.seoPageFormState,
    authState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(SeoPageActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SeoPageEditPage);
