import { Box, Paper, SxProps } from "@mui/material";
import * as React from "react";
import { Pagination } from "./TablePagination";
import { PaginationHandler } from "../hooks/usePagination";

interface Props {
  handler: PaginationHandler;
  sx?: SxProps;
}

export default function TablePaginationFloating({ sx, handler }: Props) {
  return (
    <Box
      sx={[
        (theme) => ({
          position: "fixed",
          bottom: 0,
          left: { xs: 0, sm: theme.dimensions.drawerWidth, xl: 0 },
          right: 0,
          padding: 3,
          display: "flex",
        }),
        sx as any,
      ]}
    >
      <Paper sx={{ marginLeft: "auto", marginRight: "auto" }} elevation={1}>
        <Pagination
          paging={handler.pagingState}
          handleChangePage={(event, page) => {
            handler.setCurrentPage(page + 1);
          }}
          handleChangeRowsPerPage={(event) => {
            handler.setPageSize(event.target.value);
          }}
        />
      </Paper>
    </Box>
  );
}
