import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const FeatureCheckmarkIcon = (props) => (
  <SvgIcon width="10" height="7" viewBox="0 0 10 7" {...props}>
    <path
      id="Pfad_4"
      data-name="Pfad 4"
      d="M3.35,7.128a.616.616,0,0,1-.436-.18L.181,4.219a.617.617,0,1,1,.872-.873L3.35,5.64,8.817.18a.617.617,0,0,1,.872.873l-5.9,5.894a.616.616,0,0,1-.436.18"
      transform="translate(0 0)"
      fill="#707cda"
    />
  </SvgIcon>
);

export default FeatureCheckmarkIcon;
