import * as React from "react";
import { EventFormState } from "../../../../model/eventFormState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import { EventEditTextInputField } from "./fields/EventEditTextInputField";
import { FormGridItem } from "./FormGridItem";

export interface Props {
  eventFormState: EventFormState;
  authenticationState: AuthenticationState;
  eventFormActions: typeof EventFormActions;
}

class EventContactPersonEdit extends React.Component<Props> {
  state = {};

  renderContactTitle() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"contact_title"}
      />
    );
  }

  renderContactFirstname() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"contact_firstname"}
      />
    );
  }

  renderContactLastname() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"contact_lastname"}
      />
    );
  }

  renderContactEmail() {
    return (
      <EventEditTextInputField
        type={"email"}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"contact_email"}
      />
    );
  }

  renderResponsibleOrganisation() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"responsible_organisation"}
      />
    );
  }

  render() {
    /*
    const disableControls = this.props.eventFormState.readonly;

    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <FormGridItem md={2}>{this.renderContactTitle()}</FormGridItem>
        <FormGridItem md={5}>{this.renderContactFirstname()}</FormGridItem>
        <FormGridItem md={5}>{this.renderContactLastname()}</FormGridItem>
        <FormGridItem md={6}>{this.renderContactEmail()}</FormGridItem>
        <FormGridItem md={6}>{this.renderResponsibleOrganisation()}</FormGridItem>
      </>
    );
  }
}

export default EventContactPersonEdit;
