import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as intl from "react-intl-universal";
import * as ResellerSalespartnerEarningActions from "../actions/resellerSalespartnerEarnings";
import { RootState } from "../reducers";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { PageState, PageStateType } from "../model/pageState";
import { Pagination } from "../components/TablePagination";
import { EarningsLayoutType, ResellerSalespartnerEarningsState } from "../model/resellerSalespartnerEarningsState";
import { AuthenticationState } from "../model/authenticationState";
import {
  formattedEarningsAmount,
  ResellerSalespartnerAccountingEntry,
  ResellerSalespartnerEarningsReport,
} from "../model/resellerSalespartnerAccounting";
import { MonthDatePicker, QuarterDatePicker, YearDatePicker } from "../components/DatePickers";
import { User } from "../model/user";
import { endOfMonth, endOfYear, getQuarter, getYear, startOfMonth, subQuarters } from "date-fns";
import axios from "axios";
import { DefaultButton } from "../components/Buttons";
import { endOfQuarter, startOfQuarter, startOfYear } from "date-fns/esm";
import { PageTracker } from "../util/pageTracker";
import { useLocation } from "react-router";
import AppStyles from "../styles/appStyles";
import { CompanyState } from "../model/companyState";

export interface Props {
  earningsState: ResellerSalespartnerEarningsState;
  authState: AuthenticationState;
  companyState: CompanyState;
  pageState: PageState;
  actions: typeof ResellerSalespartnerEarningActions;
}

export interface State {}

const ResellerSalespartnerEarningsPage = (props: Props) => {
  const location = useLocation();

  function fetchData() {
    if (getLayoutType() === EarningsLayoutType.Single_Salespartner) {
      props.actions.fetchSingleSalespartnerEarnings(
        props.companyState.company.company_id,
        props.earningsState.paging.current_page,
        props.earningsState.paging.page_size,
        props.earningsState.startDate,
        props.earningsState.endDate,
      );
    } else if (getLayoutType() === EarningsLayoutType.List_Salespartners) {
      props.actions.fetchGlobalSalespartnerEarnings(
        props.earningsState.paging.current_page,
        props.earningsState.paging.page_size,
        props.earningsState.startDate,
        props.earningsState.endDate,
      );
    } else if (getLayoutType() === EarningsLayoutType.Single_Reseller) {
      props.actions.fetchSingleResellerEarnings(
        props.authState.user.user_id,
        props.earningsState.paging.current_page,
        props.earningsState.paging.page_size,
        props.earningsState.startDate,
        props.earningsState.endDate,
      );
    } else if (getLayoutType() === EarningsLayoutType.List_Resellers) {
      props.actions.fetchGlobalResellerEarnings(
        props.earningsState.paging.current_page,
        props.earningsState.paging.page_size,
        props.earningsState.startDate,
        props.earningsState.endDate,
      );
    } else if (getLayoutType() === EarningsLayoutType.List_Credit_Notes) {
      props.actions.fetchGlobalCreditNotes(
        props.earningsState.paging.current_page,
        props.earningsState.paging.page_size,
        getQuarter(props.earningsState.startDate),
        getYear(props.earningsState.startDate),
      );
    } else if (getLayoutType() === EarningsLayoutType.Single_Credit_Notes) {
      props.actions.fetchSingleCreditNotes(
        props.authState.user.user_id,
        props.earningsState.paging.current_page,
        props.earningsState.paging.page_size,
        props.earningsState.startDate,
        props.earningsState.endDate,
      );
    }
  }

  const handleChangePage = (event: any, page: any) => {
    props.earningsState.paging.current_page = page + 1;
    fetchData();
  };

  const handleChangeRowsPerPage = (event: any) => {
    props.earningsState.paging.page_size = event.target.value;
    fetchData();
  };

  const handleSelectedDateFilterChange = (event: any) => {
    props.earningsState.startDate = event.startDate;
    props.earningsState.endDate = event.endDate;
    fetchData();
  };

  useEffect(() => {
    switch (getLayoutType()) {
      case EarningsLayoutType.Single_Credit_Notes:
        props.earningsState.startDate = startOfYear(Date.now());
        props.earningsState.endDate = endOfYear(Date.now());
        break;
      case EarningsLayoutType.List_Credit_Notes: {
        props.earningsState.startDate = startOfQuarter(subQuarters(Date.now(), 1));
        props.earningsState.endDate = endOfQuarter(subQuarters(Date.now(), 1));
        break;
      }
      default: {
        props.earningsState.startDate = startOfMonth(Date.now());
        props.earningsState.endDate = endOfMonth(Date.now());
        break;
      }
    }
    fetchData();
  }, []);

  function getLayoutType(): EarningsLayoutType {
    if (location.pathname === "/reseller-earnings") {
      return EarningsLayoutType.List_Resellers;
    } else if (location.pathname === "/salespartner-earnings") {
      return EarningsLayoutType.List_Salespartners;
    } else if (location.pathname.endsWith("salespartner-earnings")) {
      return EarningsLayoutType.Single_Salespartner;
    } else if (location.pathname.endsWith("earnings")) {
      return EarningsLayoutType.Single_Reseller;
    } else if (location.pathname.endsWith("reseller-creditnotes")) {
      return EarningsLayoutType.List_Credit_Notes;
    } else if (location.pathname.endsWith("credit-notes")) {
      return EarningsLayoutType.Single_Credit_Notes;
    }
    return null;
  }

  function getTitleResource() {
    switch (getLayoutType()) {
      case EarningsLayoutType.Single_Salespartner:
      case EarningsLayoutType.List_Salespartners: {
        return "resellerearnings.salespartner.title";
      }
      case EarningsLayoutType.List_Resellers:
      case EarningsLayoutType.Single_Reseller: {
        return "resellerearnings.reseller.title";
      }
      case EarningsLayoutType.Single_Credit_Notes:
      case EarningsLayoutType.List_Credit_Notes: {
        return "resellerearnings.reseller_credit_notes.title";
      }
    }
  }

  function renderTable() {
    switch (getLayoutType()) {
      case EarningsLayoutType.Single_Salespartner: {
        return renderSingleSalespartnerTable(props.earningsState.singleEntries);
      }
      case EarningsLayoutType.List_Salespartners: {
        return RenderGlobalSalespartnerTable(props.earningsState.globalSalespartnerEarnings);
      }
      case EarningsLayoutType.Single_Reseller: {
        return renderSingleResellerTable(props.earningsState.singleEntries);
      }
      case EarningsLayoutType.List_Resellers: {
        return RenderGlobalResellerTable(props.earningsState.globalResellerEarnings);
      }
      case EarningsLayoutType.List_Credit_Notes: {
        return RenderGlobalResellerCreditNotesTable(props.earningsState.globalCreditNotes);
      }
      case EarningsLayoutType.Single_Credit_Notes: {
        return RenderSingleResellerCreditNotesTable(props.earningsState.singleCreditNotes);
      }
    }
  }

  function csvDownloadButtonClicked() {
    downloadFile(
      `/v2/api/b2b/private/resellers/quarter-reports/${props.earningsState.startDate.getFullYear()}/${getQuarter(
        props.earningsState.startDate,
      )}/csv`,
      "text/csv",
      "bc-ueberweisungen.csv",
    );
  }

  function renderSingleResellerTable(allEntries: ResellerSalespartnerAccountingEntry[]) {
    return <ResellerAccountingTable accounting_entries={allEntries} />;
  }

  function renderSingleSalespartnerTable(allEntries: ResellerSalespartnerAccountingEntry[]) {
    return <SalespartnerAccountingTable accounting_entries={allEntries} />;
  }

  function RenderGlobalResellerTable(allEntries: ResellerSalespartnerEarningsReport[]) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_global.list_header_name")}
            </TableCell>
            <TableCell align="right" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_global.list_header_amount_total")}
            </TableCell>
            <TableCell align="right" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_global.list_header_amount_year")}
            </TableCell>
            <TableCell sx={AppStyles.tableCell} align="right">
              {intl.get("resellerearnings.reseller_global.list_header_amount_month")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEntries != null && allEntries.map((subrow) => <ResellerCollapsibleRow row={subrow} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              paging={props.earningsState.paging}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }

  function RenderGlobalResellerCreditNotesTable(entries: ResellerSalespartnerEarningsReport[]) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_name")}
            </TableCell>
            <TableCell align="left" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_date")}
            </TableCell>
            <TableCell align="left" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_number")}
            </TableCell>
            <TableCell align="left" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_pdf")}
            </TableCell>
            <TableCell align="right">
              {intl.get("resellerearnings.reseller_credit_notes.list_header_amount_month")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{entries != null && entries.map((subrow) => <CreditNoteCollapsibleRow row={subrow} />)}</TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              paging={props.earningsState.paging}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }

  function RenderSingleResellerCreditNotesTable(entries: ResellerSalespartnerEarningsReport[]) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_subject")}
            </TableCell>
            <TableCell align="left" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_date")}
            </TableCell>
            <TableCell align="left" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_number")}
            </TableCell>
            <TableCell align="left" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.reseller_credit_notes.list_header_pdf")}
            </TableCell>
            <TableCell align="right">
              {intl.get("resellerearnings.reseller_credit_notes.list_header_amount_month")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries != null &&
            entries.map((subrow) => <CreditNoteCollapsibleRow row={{ ...subrow, showSubject: true }} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              paging={props.earningsState.paging}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }

  function RenderGlobalSalespartnerTable(allEntries: ResellerSalespartnerEarningsReport[]) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.salespartner_global.list_header_name")}
            </TableCell>
            <TableCell align="right" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.salespartner_global.list_header_amount_total")}
            </TableCell>
            <TableCell align="right" padding="none" sx={AppStyles.tableCell}>
              {intl.get("resellerearnings.salespartner_global.list_header_amount_year")}
            </TableCell>
            <TableCell sx={AppStyles.tableCell} align="right">
              {intl.get("resellerearnings.salespartner_global.list_header_amount_month")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEntries != null && allEntries.map((subrow) => <SalespartnerCollapsibleRow row={subrow} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              paging={props.earningsState.paging}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }

  if (props.pageState.state === PageStateType.Loading) {
    return <></>;
  }

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item sx={AppStyles.headerLeft}>
              <HeaderLabel>{intl.get(getTitleResource())}</HeaderLabel>
            </Grid>
          </Grid>
          <Grid item sx={AppStyles.headerLeft} style={{ marginTop: "24pt", marginBottom: "6pt" }}>
            {getLayoutType() !== EarningsLayoutType.List_Credit_Notes &&
              getLayoutType() !== EarningsLayoutType.Single_Credit_Notes && (
                <Typography variant="h5">
                  {intl.get("resellerearnings.page_header_amount_total_label")}{" "}
                  {formattedEarningsAmount(props.earningsState.total_amount_in_cents, "EUR")}
                </Typography>
              )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item sx={AppStyles.headerLeft} style={{ marginTop: 0 }}>
            {getLayoutType() !== EarningsLayoutType.List_Credit_Notes &&
              getLayoutType() !== EarningsLayoutType.Single_Credit_Notes && (
                <Typography variant="h6">
                  {intl.get("resellerearnings.page_header_amount_year_label")}{" "}
                  {formattedEarningsAmount(props.earningsState.current_year_amount_in_cents, "EUR")}
                </Typography>
              )}
            {getLayoutType() === EarningsLayoutType.List_Credit_Notes &&
              props.earningsState.globalCreditNotes &&
              props.earningsState.globalCreditNotes.length > 0 && (
                <DefaultButton
                  style={{ marginLeft: -8 }}
                  disabled={false}
                  onClick={() => {
                    csvDownloadButtonClicked();
                  }}
                >
                  <CloudDownloadIcon sx={AppStyles.buttonLeftIcon} />
                  {intl.get("resellerearnings.reseller_credit_notes.button_csv_download")}
                </DefaultButton>
              )}
          </Grid>
          <Grid item>
            {getLayoutType() === EarningsLayoutType.List_Credit_Notes ? (
              <QuarterDatePicker startDate={props.earningsState.startDate} onChange={handleSelectedDateFilterChange} />
            ) : getLayoutType() === EarningsLayoutType.Single_Credit_Notes ? (
              <YearDatePicker startDate={props.earningsState.startDate} onChange={handleSelectedDateFilterChange} />
            ) : (
              <MonthDatePicker startDate={props.earningsState.startDate} onChange={handleSelectedDateFilterChange} />
            )}
          </Grid>
          <Grid item sx={AppStyles.headerRight} alignContent="flex-end" style={{ marginTop: 0 }}>
            <Typography variant="h6">
              {intl.get("resellerearnings.page_header_amount_month_label")}{" "}
              {formattedEarningsAmount(props.earningsState.current_filter_amount_in_cents, "EUR")}
            </Typography>
          </Grid>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>{renderTable()}</Paper>
      </Box>
    </>
  );
};

const dateFormat = new Intl.DateTimeFormat("de", {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
});

const ResellerAccountingTable = (props) => {
  return (
    <Table size={props.size ? props.size : "medium"}>
      <TableHead>
        <TableRow>
          <TableCell>{intl.get("resellerearnings.reseller.list_header_date")}</TableCell>
          <TableCell>{intl.get("resellerearnings.reseller.list_header_company")}</TableCell>
          <TableCell>{intl.get("resellerearnings.reseller.list_header_type")}</TableCell>
          {props.showInvoiceNumber && (
            <TableCell>{intl.get("resellerearnings.reseller.list_header_invoice_number")}</TableCell>
          )}
          <TableCell align="right">{intl.get("resellerearnings.reseller.list_header_amount")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.accounting_entries &&
          props.accounting_entries.map((entryRow) => (
            <TableRow key={entryRow.created_at}>
              <TableCell component="th" scope="row">
                {dateFormat.format(new Date(entryRow.created_at))}
              </TableCell>
              <TableCell>{entryRow.company.name}</TableCell>
              <TableCell>
                {entryRow.type === "INITIAL"
                  ? intl.get("resellerearnings.reseller.list_entry_type_initial")
                  : intl.get("resellerearnings.reseller.list_entry_type_continuous")}
              </TableCell>
              {props.showInvoiceNumber && (
                <TableCell>{entryRow.type === "INITIAL" ? "-" : entryRow.invoice_number}</TableCell>
              )}
              {entryRow.invoice_paid === false && (
                <TableCell align="right" style={{ color: "#ff9800" }}>
                  {formattedEarningsAmount(entryRow.amount_in_cents, "EUR")} (noch nicht bezahlt)
                </TableCell>
              )}
              {(entryRow.invoice_paid === true || entryRow.invoice_paid === undefined) && (
                <TableCell align="right">{formattedEarningsAmount(entryRow.amount_in_cents, "EUR")}</TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const SalespartnerAccountingTable = (props) => {
  return (
    <Table size={props.size ? props.size : "medium"}>
      <TableHead>
        <TableRow>
          <TableCell>{intl.get("resellerearnings.salespartner.list_header_date")}</TableCell>
          <TableCell>{intl.get("resellerearnings.salespartner.list_header_company")}</TableCell>
          {props.showInvoiceNumber && (
            <TableCell>{intl.get("resellerearnings.salespartner.list_header_invoice_number")}</TableCell>
          )}
          <TableCell align="right">{intl.get("resellerearnings.salespartner.list_header_amount")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.accounting_entries &&
          props.accounting_entries.map((entryRow) => (
            <TableRow key={entryRow.created_at}>
              <TableCell component="th" scope="row">
                {dateFormat.format(new Date(entryRow.created_at))}
              </TableCell>
              <TableCell>{entryRow.company.name}</TableCell>
              {props.showInvoiceNumber && <TableCell>{entryRow.invoice_number}</TableCell>}
              {entryRow.invoice_paid === false && (
                <TableCell align="right" style={{ color: "#ff9800" }}>
                  {formattedEarningsAmount(entryRow.discount_value, "EUR")} (noch nicht bezahlt)
                </TableCell>
              )}
              {(entryRow.invoice_paid === true || entryRow.invoice_paid === undefined) && (
                <TableCell align="right">{formattedEarningsAmount(entryRow.discount_value, "EUR")}</TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const ResellerRow = (props) => {
  const handleArrowClicked = (newVal: any) => {
    setOpen(newVal);
    props.onOpenChanged(newVal);
  };

  const [open, setOpen] = React.useState(false);
  return (
    <TableRow>
      {props.collapsible && (
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => handleArrowClicked(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      )}
      <TableCell component="th" scope="row">
        {getResellerDisplayName(props.reseller)}
      </TableCell>
      <TableCell align="right">{formattedEarningsAmount(props.summary.total_amount_in_cents, "EUR")}</TableCell>
      <TableCell align="right">{formattedEarningsAmount(props.summary.current_year_amount_in_cents, "EUR")}</TableCell>
      {props.unpaid_invoices && (
        <TableCell align="right" style={{ color: "#ff9800" }}>
          {formattedEarningsAmount(props.summary.current_filter_amount_in_cents, "EUR")} (enthält nicht bezahlte
          Rechnungen)
        </TableCell>
      )}
      {!props.unpaid_invoices && (
        <TableCell align="right">
          {formattedEarningsAmount(props.summary.current_filter_amount_in_cents, "EUR")}
        </TableCell>
      )}
    </TableRow>
  );
};

const SalespartnerRow = (props) => {
  const handleArrowClicked = (newVal: any) => {
    setOpen(newVal);
    props.onOpenChanged(newVal);
  };

  const [open, setOpen] = React.useState(false);
  return (
    <TableRow>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => handleArrowClicked(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row">
        {props.salespartner.name}
      </TableCell>
      <TableCell align="right">{formattedEarningsAmount(props.summary.total_amount_in_cents, "EUR")}</TableCell>
      <TableCell align="right">{formattedEarningsAmount(props.summary.current_year_amount_in_cents, "EUR")}</TableCell>
      {props.unpaid_invoices && (
        <TableCell align="right" style={{ color: "#ff9800" }}>
          {formattedEarningsAmount(props.summary.current_filter_amount_in_cents, "EUR")} (enthält nicht bezahlte
          Rechnungen)
        </TableCell>
      )}
      {!props.unpaid_invoices && (
        <TableCell align="right">
          {formattedEarningsAmount(props.summary.current_filter_amount_in_cents, "EUR")}
        </TableCell>
      )}
    </TableRow>
  );
};

const CreditNoteRow = (props) => {
  const handleArrowClicked = (newVal: any) => {
    setOpen(newVal);
    props.onOpenChanged(newVal);
  };

  async function downloadCreditNote(creditnote: any) {
    downloadFile(
      `/v2/api/b2b/private/resellers/${props.creditnote.reseller_id}/credit-notes/${creditnote.note_id}/pdf`,
      "application/pdf",
      `blue-cherries-${creditnote.note_number}`,
    );
  }

  const [open, setOpen] = React.useState(false);
  return (
    <TableRow>
      {props.collapsible && (
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => handleArrowClicked(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      )}
      <TableCell component="th" scope="row">
        {props.showSubject ? props.creditnote.subject : getResellerDisplayName(props.reseller)}
      </TableCell>
      <TableCell>{dateFormat.format(new Date(props.creditnote.normalized_invoice_date))}</TableCell>
      <TableCell>{props.creditnote.note_number}</TableCell>
      <TableCell>
        {
          <IconButton aria-label="expand row" size="small" onClick={() => downloadCreditNote(props.creditnote)}>
            <PictureAsPdfIcon />
          </IconButton>
        }
      </TableCell>
      {props.unpaid_invoices && (
        <TableCell align="right" style={{ color: "#ff9800" }}>
          {formattedEarningsAmount(props.creditnote.amount_in_cents, props.creditnote.currency)} (enthält nicht bezahlte
          Rechnungen)
        </TableCell>
      )}
      {!props.unpaid_invoices && (
        <TableCell align="right">
          {formattedEarningsAmount(props.creditnote.amount_in_cents, props.creditnote.currency)}
        </TableCell>
      )}
    </TableRow>
  );
};

const ResellerCollapsibleRow = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ResellerRow
        unpaid_invoices={row.unpaid_invoices}
        reseller={row.reseller}
        summary={row.summary}
        collapsible
        onOpenChanged={(newVal) => setOpen(newVal)}
      />
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {!row.accounting_entries || row.accounting_entries.length === 0 ? (
                <Typography variant="body2" gutterBottom component="div" align="center">
                  {intl.get("resellerearnings.reseller.sublist_empty")}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    {intl.get("resellerearnings.reseller.sublist_header")}
                  </Typography>
                  <ResellerAccountingTable showInvoiceNumber accounting_entries={row.accounting_entries} size="small" />
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const SalespartnerCollapsibleRow = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <SalespartnerRow
        salespartner={row.salespartner}
        unpaid_invoices={row.unpaid_invoices}
        summary={row.summary}
        collapsible
        onOpenChanged={(newVal) => setOpen(newVal)}
      />
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {!row.accounting_entries || row.accounting_entries.length === 0 ? (
                <Typography variant="body2" gutterBottom component="div" align="center">
                  {intl.get("resellerearnings.salespartner.sublist_empty")}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    {intl.get("resellerearnings.salespartner.sublist_header")}
                  </Typography>
                  <SalespartnerAccountingTable
                    showInvoiceNumber
                    accounting_entries={row.accounting_entries}
                    size="small"
                  />
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CreditNoteCollapsibleRow = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <CreditNoteRow
        unpaid_invoices={row.unpaid_invoices}
        reseller={row.reseller}
        summary={row.summary}
        creditnote={row.creditnote}
        collapsible
        showSubject={row.showSubject}
        onOpenChanged={(newVal) => setOpen(newVal)}
      />
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {!row.accounting_entries || row.accounting_entries.length === 0 ? (
                <Typography variant="body2" gutterBottom component="div" align="center">
                  {intl.get("resellerearnings.reseller.sublist_empty")}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    {intl.get("resellerearnings.reseller.sublist_header")}
                  </Typography>
                  <ResellerAccountingTable accounting_entries={row.accounting_entries} size="small" />
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function getResellerDisplayName(reseller: User) {
  if (reseller.first_name) {
    return reseller.first_name + " " + reseller.last_name;
  }

  if (reseller.last_name) {
    return reseller.last_name;
  }

  if (reseller.reseller_profile) {
    return reseller.reseller_profile.name;
  }

  return reseller.email;
}

async function downloadFile(url: string, mimeType: string, fallbackFilename: string) {
  try {
    const res = await axios.get(url, { responseType: "blob" });

    const dispositionHeader: string = res.headers["content-disposition"];
    let filename = "";
    if (dispositionHeader != null) {
      const fileIndex = dispositionHeader.indexOf("filename=");
      filename = dispositionHeader.substring(fileIndex + 9);
    } else {
      filename = fallbackFilename;
    }

    const newBlob = new Blob([res.data], { type: mimeType });

    //@ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      window.navigator.msSaveOrOpenBlob(newBlob, filename);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    if (link.parentNode != null) {
      link.parentNode.removeChild(link);
    }
    setTimeout(function () {
      window.URL.revokeObjectURL(data);
    }, 100);
  } catch (error) {}
}

function mapStateToProps(state: RootState) {
  return {
    earningsState: state.resellerSalespartnerEarningsState,
    authState: state.authenticationState,
    companyState: state.companyState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ResellerSalespartnerEarningActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerSalespartnerEarningsPage);
