import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import * as intl from "react-intl-universal";
import axios from "axios";
import Lightbox from "yet-another-react-lightbox";
import * as CompanyActions from "../../actions/company";
import * as MediaActions from "../../actions/media";
import { CompanyState } from "../../model/companyState";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton } from "../../components/Buttons";
import { MediaState } from "../../model/mediaState";
import { RootState } from "../../reducers";
import { Media, Photo, UploadInfo } from "../../model/media";
import { cloudinaryUploader } from "../../util/cloudinaryUploader";
import { PageTracker } from "../../util/pageTracker";
import AppStyles from "../../styles/appStyles";
import PhotoAlbum from "react-photo-album";
import MediaImageWrapper from "../components/media/MediaImageWrapper";

export interface Props {
  companyState: CompanyState;
  mediaState: MediaState;
  companyActions: typeof CompanyActions;
  mediaActions: typeof MediaActions;
}

export interface State {
  currentImage: number;
  lightboxIsOpen: boolean;
  activeMediaItem: Media;
  deleteConfirmationVisible: boolean;
  activeMediaItemEventUsageText: string[];
}

class CompanyMediaPage extends React.Component<Props> {
  state = {
    currentImage: 0,
    lightboxIsOpen: false,
    activeMediaItem: null,
    deleteConfirmationVisible: false,
    activeMediaItemEventUsageText: [],
    uploadInfo: new UploadInfo(),
  };

  openLightbox(event: any, obj: any) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  confirmMediaItemDeletion() {
    const mediaItem: any = this.state.activeMediaItem;
    const mediaItemId = mediaItem.media_id;

    this.props.mediaActions.deleteMediaItemForCompany(this.props.companyState.selected_company_id, mediaItemId);
    this.setState({ activeMediaItem: null, deleteConfirmationVisible: false });
  }

  async checkForUsage(event: any, obj: any) {
    const mediaItem = this.props.mediaState.media[obj.index];

    try {
      const res = await axios.get(
        `/v2/api/b2b/private/companies/${this.props.companyState.selected_company_id}/media/${mediaItem.media_id}/usages`,
      );

      if (res.data.events.length > 0) {
        const titles = res.data.events.map((e: any) => e.title);

        this.setState({
          activeMediaItem: mediaItem,
          activeMediaItemEventUsageText: titles,
          deleteConfirmationVisible: true,
        });
      } else {
        this.setState({
          activeMediaItem: mediaItem,
          activeMediaItemEventUsageText: [],
          deleteConfirmationVisible: true,
        });
      }
    } catch (err) {
      // TODO
    }
  }

  cancelDeletion() {
    this.setState({ activeMediaItem: null, deleteConfirmationVisible: false });
  }

  componentDidMount(): void {
    this.props.mediaActions.fetchMediaForCompany(this.props.companyState.selected_company_id);
  }

  uploadCloudinaryResult(info: any) {
    this.props.mediaActions.uploadCloudinaryResult(this.props.companyState.selected_company_id, info);
    this.setState({});
  }

  columns(containerWidth: number) {
    let columns = 1;
    if (containerWidth >= 500) {
      columns = 2;
    }
    if (containerWidth >= 900) {
      columns = 3;
    }
    if (containerWidth >= 1500) {
      columns = 4;
    }
    return columns;
  }

  render() {
    const photos = this.props.mediaState.media.map((mediaItem) => {
      return new Photo(mediaItem);
    });

    let eventConfirmationItems: any[] = [];
    if (this.state.activeMediaItemEventUsageText && this.state.activeMediaItemEventUsageText.length > 0) {
      eventConfirmationItems = this.state.activeMediaItemEventUsageText.map((item) => {
        return (
          <li key={item}>
            <Typography color="textSecondary" variant="subtitle1">
              {item}
            </Typography>
          </li>
        );
      });
    }

    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.headerLeft}>
              <HeaderLabel>{intl.get("company_media.title")}</HeaderLabel>
            </Grid>
            <Grid item sx={AppStyles.headerRight}>
              <DefaultButton
                onClick={() => {
                  cloudinaryUploader(
                    this.state.uploadInfo,
                    this.props.companyState.selected_company_id,
                    (uploadInfo: any) => {
                      this.setState({ ...this.state, uploadInfo });
                    },
                    (info: any) => {
                      this.props.mediaActions.uploadCloudinaryResult(this.props.companyState.selected_company_id, info);
                    },
                  );
                }}
              >
                <AddIcon />
                {intl.get("company_media.button_upload")}
              </DefaultButton>
            </Grid>
          </Grid>
          <Paper sx={AppStyles.innerContentPaper}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                padding: 2,
              }}
            >
              <PhotoAlbum
                layout={"rows"}
                photos={photos}
                targetRowHeight={240}
                rowConstraints={{ singleRowMaxHeight: 300 }}
                renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                  <MediaImageWrapper
                    style={wrapperStyle}
                    photo={photo}
                    photoIndex={photos.indexOf(photo)}
                    onClick={(event) => this.openLightbox(event, { index: photos.indexOf(photo) })}
                    managementView={true}
                    key={photo.media.media_id}
                    disableControls={false}
                    onSaveCreditsInfo={(credits: any, object: any) => {
                      this.updateMediaItem(credits, object);
                    }}
                    onDelete={(event: any, object: any) => {
                      this.checkForUsage(event, object);
                    }}
                    direction={"row"}
                  >
                    {renderDefaultPhoto({ wrapped: true })}
                  </MediaImageWrapper>
                )}
              />
            </Box>
          </Paper>

          <Lightbox
            open={this.state.lightboxIsOpen}
            close={() => this.closeLightbox()}
            slides={this.props.mediaState.media.map((mediaItem) => ({
              src: mediaItem.large_preview_url,
              width: mediaItem.original_width,
              height: mediaItem.original_height,
            }))}
            index={this.state.currentImage}
          />

          <Dialog
            open={this.state.deleteConfirmationVisible}
            onClose={() => this.cancelDeletion()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{intl.get("company_media.delete_confirmation.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {eventConfirmationItems.length > 0
                  ? intl.get("company_media.delete_confirmation.message_with_usages_in_events")
                  : intl.get("company_media.delete_confirmation.message")}
              </DialogContentText>
              {eventConfirmationItems.length > 0 && <ul>{eventConfirmationItems}</ul>}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.cancelDeletion()} color="primary">
                {intl.get("company_media.delete_confirmation.button_cancel")}
              </Button>
              <Button onClick={() => this.confirmMediaItemDeletion()} color="error" autoFocus>
                {intl.get("company_media.delete_confirmation.button_confirm")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </>
    );
  }

  updateMediaItem(credits: string, obj: any) {
    const mediaItem = obj.photo;
    const mediaItemId = mediaItem.media.media_id;

    this.props.mediaActions.updateMediaItem(this.props.companyState.selected_company_id, mediaItemId, credits);
    this.setState({ activeMediaItem: null, mediaItemCopyright: null });
  }
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    mediaState: state.mediaState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
    mediaActions: bindActionCreators(MediaActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyMediaPage);
