import * as React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../../../model/eventFormState";
import { SectionLabel } from "../../../../components/SectionLabel";
import { CompanyState } from "../../../../model/companyState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import { Constants } from "./event_options";
import { BCLocation } from "../../../../model/location";
import { DefaultButton, LinkButton } from "../../../../components/Buttons";
import AddIcon from "@mui/icons-material/Add";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import BusinessIcon from "@mui/icons-material/Business";
import TextFieldWithLabel from "../../../../components/TextFieldWithLabel";
import {
  CompanySharedLocation,
  fullAddressStringFrom,
  fullEditingAddressStringFrom,
} from "../../../../model/company.shared.location";
import MySelect from "../../../../components/select/MySelect";
import MyCreatableSelect from "../../../../components/select/MyCreatableSelect";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventLocationInfoEdit extends React.Component<Props> {
  state = {
    selectedLocation: null,
    deleteLocationConfirmationVisible: false,
    sharedLocationSelectionVisible: false,
    selectedSharedLocations: [],
  };

  renderCompanySharedLocation(index: number, location: BCLocation, sharedLocation: CompanySharedLocation) {
    return (
      <>
        <Grid
          item
          container
          direction="row"
          sx={AppStyles.detailFormWithoutBottomPadding}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography>
              <b>Standort: {sharedLocation.name}</b>
            </Typography>
          </Grid>
          <Grid item>
            {this.props.eventFormState.readonly === false && (
              <LinkButton
                color={"error"}
                onClick={(event: any) => {
                  event.stopPropagation();
                  this.setState({
                    selectedLocation: location,
                    deleteLocationConfirmationVisible: true,
                  });
                }}
                title={"Standort entfernen"}
              />
            )}
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper} style={{ width: "100%" }}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
              <Typography variant={"body1"}>{fullEditingAddressStringFrom(sharedLocation)}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  renderLocation(index: number, location: BCLocation) {
    if (location.shared_location_id && location.company_shared_location) {
      return this.renderCompanySharedLocation(index, location, location.company_shared_location);
    }

    return (
      <>
        <Grid
          item
          container
          direction="row"
          sx={AppStyles.detailFormWithoutBottomPadding}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography>
              <b>{location.shared_location_id && location.name}</b>
              <b>{!location.shared_location_id && "Standort"}</b>
            </Typography>
          </Grid>
          <Grid item>
            {this.props.eventFormState.readonly === false && (
              <LinkButton
                color={"error"}
                onClick={(event: any) => {
                  event.stopPropagation();
                  this.setState({
                    selectedLocation: location,
                    deleteLocationConfirmationVisible: true,
                  });
                }}
                title={"Standort entfernen"}
              />
            )}
          </Grid>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
              <TextFieldWithLabel
                label={intl.get(`event_form.label.location.address`)}
                margin="normal"
                disabled={this.props.eventFormState.readonly || location.shared_location_id !== undefined}
                onChange={(event) => {
                  location.address = event.target.value;
                  this.props.eventFormActions.updateLocation();
                }}
                value={location.address}
              />
            </Grid>
            <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              <TextFieldWithLabel
                label={intl.get(`event_form.label.location.additional_info`)}
                margin="normal"
                disabled={this.props.eventFormState.readonly || location.shared_location_id !== undefined}
                onChange={(event) => {
                  location.additional_info = event.target.value;
                  this.props.eventFormActions.updateLocation();
                }}
                value={location.additional_info}
              />
            </Grid>
            {this.renderCountry(location)}
            {this.renderState(location)}
            {this.renderRegion(location)}

            {this.renderPostalCode(location)}
            {this.renderCity(location)}
            {this.renderDistrict(location)}

            <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
              <TextFieldWithLabel
                maxLength={1500}
                rows={5}
                disabled={this.props.eventFormState.readonly || location.shared_location_id !== undefined}
                multiline
                maxRows={10}
                label={intl.get(`event_form.label.directions`)}
                margin="normal"
                onChange={(event) => {
                  location.directions = event.target.value;
                  this.props.eventFormActions.updateLocation();
                }}
                value={location.directions}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  private renderCountry(location: BCLocation) {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={true}>{intl.get(`event_form.label.location.country_code`)}</SectionLabel>
          <MySelect
            options={Constants.addressCountryCodeOptions()}
            isDisabled={this.props.eventFormState.readonly || location.shared_location_id !== undefined}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.country_code`)}
            value={Constants.toSingleSelectOption(location.country_code, `event_constants.location.country_code`)}
            onChange={(value) => {
              const newValue = Constants.fromSingleSelectOption(value);
              const hasChanged = newValue !== location.country_code;
              location.country_code = newValue;
              if (value) {
                location.country = value.label;
              } else {
                location.country = null;
              }
              if (hasChanged) {
                location.state = "";
                location.region = "";
                location.city = "";
                location.district = "";
              }
              this.props.eventFormActions.updateLocation();
            }}
          />
        </Grid>
      </Grid>
    );
  }

  private renderState(location: BCLocation) {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={true}>{intl.get(`event_form.label.location.state`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.federalStateOptionsForCountry(location.country_code, location.state)}
            isDisabled={location.country_code === undefined || location.country_code === ""}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.state`)}
            value={Constants.selectedDependentLocationValue(
              "Zuerst Land auswählen",
              "Bundesland auswählen",
              location.state,
              location.country_code,
            )}
            onCreateOption={(value) => {
              const newValue = value;
              const hasChanged = newValue !== location.state;
              location.state = value;
              if (hasChanged) {
                location.region = "";
                location.city = "";
                location.district = "";
              }
              this.props.eventFormActions.updateLocation();
            }}
            onChange={(value) => {
              const oldValue = location.state;
              const newValue = Constants.fromSingleSelectOption(value);
              const hasChanged = newValue !== oldValue;
              location.state = newValue;
              if (hasChanged) {
                location.region = "";
                location.city = "";
                location.district = "";
              }
              this.props.eventFormActions.updateLocation();
            }}
          />
        </Grid>
      </Grid>
    );
  }

  private renderRegion(location: BCLocation) {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get(`event_form.label.location.region`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.regionOptionsForFederalState(location.state, location.region)}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.region`)}
            value={Constants.selectedMultiDependentLocationValue(
              "Zuerst Bundesland auswählen",
              "Region auswählen",
              location.region,
              location.state,
            )}
            onCreateOption={(value) => {
              let newValue = location.region;
              if (newValue && newValue !== "") {
                newValue = `${newValue};${value}`;
              } else {
                newValue = value;
              }
              location.region = newValue;
              this.props.eventFormActions.updateLocation();
            }}
            onChange={(value) => {
              location.region = Constants.fromMultiSelectOption(value);
              this.props.eventFormActions.updateLocation();
            }}
          />
        </Grid>
      </Grid>
    );
  }

  private renderDistrict(location: BCLocation) {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get(`event_form.label.location.district`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.districtOptionsForCity(location.state, location.district)}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.district`)}
            value={Constants.selectedDependentLocationValue(
              "Zuerst Ort auswählen",
              "Ortsteil auswählen",
              location.district,
              location.state,
            )}
            onCreateOption={(value) => {
              location.district = value;
              this.props.eventFormActions.updateLocation();
            }}
            onChange={(value) => {
              location.district = Constants.fromSingleSelectOption(value);
              this.props.eventFormActions.updateLocation();
            }}
          />
        </Grid>
      </Grid>
    );
  }

  private renderCity(location: BCLocation) {
    return (
      <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get(`event_form.label.location.city`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.cityOptionsForFederalState(location.state, location.city)}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.city`)}
            value={Constants.selectedDependentLocationValue(
              "Zuerst Bundesland auswählen",
              "Ort auswählen",
              location.city,
              location.state,
            )}
            onCreateOption={(value) => {
              const oldValue = location.city;
              const newValue = value;
              const hasChanged = newValue !== oldValue;

              location.city = value;
              if (hasChanged) {
                location.district = "";
              }
              this.props.eventFormActions.updateLocation();
            }}
            onChange={(value) => {
              const oldValue = location.city;
              const newValue = Constants.fromSingleSelectOption(value);
              const hasChanged = newValue !== oldValue;

              location.city = Constants.fromSingleSelectOption(value);
              if (hasChanged) {
                location.district = "";
              }
              this.props.eventFormActions.updateLocation();
            }}
          />
        </Grid>
      </Grid>
    );
  }

  private renderPostalCode(location: BCLocation) {
    return (
      <Grid item xs={12} md={2} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
        <TextFieldWithLabel
          label={intl.get(`event_form.label.location.postal_code`)}
          margin="normal"
          disabled={this.props.eventFormState.readonly || location.shared_location_id !== undefined}
          onChange={(event) => {
            location.postal_code = event.target.value;
            this.props.eventFormActions.updateLocation();
          }}
          value={location.postal_code}
        />
      </Grid>
    );
  }

  renderDeleteLocationConfirmationDialog() {
    if (this.state.deleteLocationConfirmationVisible === false) {
      return null;
    }

    return (
      <Dialog
        open={true}
        onClose={() => {
          this.setState({ selectedLocation: null, deleteLocationConfirmationVisible: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Standort entfernen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie den Standort für das Freizeitangebot wirklich entfernen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ selectedLocation: null, deleteLocationConfirmationVisible: false });
            }}
            color="primary"
          >
            Nein, abbrechen
          </Button>
          <Button
            onClick={() => {
              this.props.eventFormActions.removeLocation(this.state.selectedLocation);
              this.setState({ selectedLocation: null, deleteLocationConfirmationVisible: false });
            }}
            color="error"
            autoFocus
          >
            Ja, entfernen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderActionButtons() {
    if (this.props.eventFormState.readonly) {
      return null;
    }

    return (
      <Grid item xs={12} container direction={"row"} sx={AppStyles.detailFormRowNoBorder}>
        <DefaultButton
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.eventFormActions.addLocation();
          }}
        >
          <AddIcon sx={AppStyles.buttonLeftIcon} />
          Weitere Adresse
        </DefaultButton>
        &nbsp;
        <DefaultButton
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.eventFormActions.addCompanyAddressAsLocation(this.props.companyState.company);
          }}
        >
          <BusinessIcon sx={AppStyles.buttonLeftIcon} />
          Firmenadresse hinzufügen
        </DefaultButton>
        &nbsp;
        <DefaultButton
          disabled={this.props.eventFormState.sharedLocations.length === 0}
          onClick={(event: any) => {
            event.stopPropagation();
            this.setState({ sharedLocationSelectionVisible: true, selectedSharedLocations: [] });
          }}
        >
          <LibraryAddIcon sx={AppStyles.buttonLeftIcon} />
          Standort auswählen
        </DefaultButton>
      </Grid>
    );
  }

  toggleSharedLocation(location: CompanySharedLocation) {
    const selectedLocations = this.state.selectedSharedLocations;
    const index = selectedLocations.indexOf(location.location_id);
    if (index === -1) {
      selectedLocations.push(location.location_id);
    } else {
      selectedLocations.splice(index, 1);
    }
    this.setState({ selectedSharedLocations: selectedLocations });
  }

  // use arrow functions, as they don't rescope "this"
  addSelectedSharedLocations = () => {
    const selectedLocations = this.state.selectedSharedLocations;

    const locations = this.props.eventFormState.sharedLocations.filter(
      (value) => selectedLocations.indexOf(value.location_id) !== -1,
    );
    this.props.eventFormActions.addSharedLocations(locations);
    this.setState({ selectedSharedLocations: [], sharedLocationSelectionVisible: false });
  };

  selectAllSharedLocations = () => {
    const alreadyAddedSharedLocations = this.props.eventFormState.event.locations
      .filter((value) => value.shared_location_id !== null)
      .map((value) => value.shared_location_id);

    const locationsToSelect = this.props.eventFormState.sharedLocations
      .filter((value) => alreadyAddedSharedLocations.indexOf(value.location_id) === -1)
      .map((value) => value.location_id);
    this.setState({ selectedSharedLocations: locationsToSelect });
  };

  selectNoSharedLocations = () => {
    this.setState({ selectedSharedLocations: [] });
  };

  renderSharedLocationSelectionDialog() {
    const alreadyAddedSharedLocations = this.props.eventFormState.event.locations
      .filter((value) => value.shared_location_id !== null)
      .map((value) => value.shared_location_id);

    const locationsToSelect = this.props.eventFormState.sharedLocations.filter(
      (value) => alreadyAddedSharedLocations.indexOf(value.location_id) === -1,
    );

    const locationList = locationsToSelect.map((value) => {
      const labelId = `checkbox-list-label-${value.location_id}`;
      return (
        <ListItem
          key={value.location_id}
          role={undefined}
          dense
          button
          onClick={() => this.toggleSharedLocation(value)}
        >
          <ListItemIcon>
            <Checkbox
              edge={"start"}
              color={"primary"}
              checked={this.state.selectedSharedLocations.indexOf(value.location_id) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={`${value.name} (${fullAddressStringFrom(value)})`} />
        </ListItem>
      );
    });
    return (
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        scroll={"paper"}
        open={true}
        onClose={() => {
          this.setState({ sharedLocationSelectionVisible: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Standort hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wählen Sie die vordefinierten Standorte aus, die zu diesem Freizeitangebot hinzugefügt werden sollen.
          </DialogContentText>
          <Stack direction={"row"} sx={{ marginTop: 2 }} gap={0.5}>
            <Button
              size={"small"}
              onClick={() => {
                this.selectAllSharedLocations();
              }}
              color="primary"
            >
              Alle auswählen
            </Button>
            <Button color="primary" autoFocus onClick={this.selectNoSharedLocations} size={"small"}>
              Keine auswählen
            </Button>
          </Stack>

          <List sx={{ width: "100%" }}>{locationList}</List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ sharedLocationSelectionVisible: false });
            }}
            color="secondary"
          >
            Abbrechen
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={this.state.selectedSharedLocations.length === 0}
            onClick={this.addSelectedSharedLocations}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    /*
   let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    const renderedLocations = this.props.eventFormState.event.locations.map((value, index) => {
      return this.renderLocation(index, value);
    });

    return (
      <Stack>
        {this.renderDeleteLocationConfirmationDialog()}
        {this.state.sharedLocationSelectionVisible && this.renderSharedLocationSelectionDialog()}

        {renderedLocations}

        {this.renderActionButtons()}
      </Stack>
    );
  }
}

export default EventLocationInfoEdit;
