import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { RootState } from "../reducers";
import * as ErrorDialogActions from "../actions/errorDialog";
import { ErrorDialogState } from "../model/errorDialogState";

export interface Props {
  errorDialogState: ErrorDialogState;
  actions: typeof ErrorDialogActions;
  title?: string;
}

class ErrorDialog extends React.Component<Props> {
  confirmError() {
    this.props.actions.confirmErrorDialog();
  }

  renderErrorMessage() {
    if (this.props.errorDialogState.error != null) {
      if (typeof this.props.errorDialogState.error === "string") {
        return this.props.errorDialogState.error;
      }
      if (this.props.errorDialogState.error.error_code) {
        const errorMessage = intl.get(`error.code.${this.props.errorDialogState.error.error_code}`);
        if (errorMessage == null || errorMessage.length === 0) {
          return this.props.errorDialogState.error.error_code;
        }
        return errorMessage;
      }
      if (typeof this.props.errorDialogState.error.errorMessage) {
        return this.props.errorDialogState.error.errorMessage;
      }

      return intl.get("error.generic");
    }
    return null;
  }

  render() {
    const dialogTitle = !this.props.title ? intl.get("error.generic_title") : this.props.title;

    if (this.props.errorDialogState.error != null) {
      return (
        <Dialog
          open={true}
          onClose={() => this.confirmError()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.renderErrorMessage()}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.confirmError()} color="primary">
              {intl.get("error.button_confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
}

function mapStateToProps(state: RootState) {
  return {
    errorDialogState: state.errorDialogState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ErrorDialogActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ErrorDialog);
