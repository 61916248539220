import BookingDrawerInfoSection from "../components/BookingDrawerInfoSection";
import NewsletterIcon from "../../../assets/icons/Newsletter";
import intl from "react-intl-universal";

export default function SocialMediaInfoSection() {
  return (
    <BookingDrawerInfoSection
      icon={<NewsletterIcon sx={{ width: "33px", height: "27px" }} />}
      title={intl.get("booking_drawer_info.social_media.title")}
      text1={intl.get("booking_drawer_info.social_media.text1")}
      features={[
        intl.getHTML("booking_drawer_info.social_media.feature1"),
        intl.getHTML("booking_drawer_info.social_media.feature2"),
        intl.getHTML("booking_drawer_info.social_media.feature3"),
        intl.getHTML("booking_drawer_info.social_media.feature4"),
      ]}
    />
  );
}
