import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { SectionLabel } from "../../../../../components/SectionLabel";
import AppStyles from "../../../../../styles/appStyles";

export function EventEditCheckboxField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  withLabel?: boolean;
  disabled?: boolean;
}) {
  return (
    <Grid item container direction="column">
      {props.withLabel === true && <SectionLabel required={false}></SectionLabel>}

      <Grid item>
        <FormControlLabel
          sx={AppStyles.detailFormControlLabel}
          disabled={props.formState.readonly || props.disabled}
          labelPlacement="end"
          control={
            <Checkbox
              color="primary"
              checked={props.formState.event[`${props.field}`]}
              onChange={(event) => {
                props.formActions.updateEventFormField({
                  prop: `event.${props.field}`,
                  value: event.target.checked,
                });
              }}
            />
          }
          label={intl.get(`event_form.label.${props.field}`)}
        />
      </Grid>
    </Grid>
  );
}
