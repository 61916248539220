import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { Alert, Box, Grid, IconButton, Paper, Snackbar, Tabs } from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../../model/eventFormState";
import { RootState } from "../../../reducers";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import EventGeneralInfoTab from "./event_edit/EventGeneralInfoTab";
import EventMediaEdit from "./event_edit/EventMediaEdit";
import { AuthenticationState } from "../../../model/authenticationState";
import { CompanyState } from "../../../model/companyState";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";
import { LinkButton } from "../../../components/Buttons";
import EventLocationInfoEdit from "./event_edit/EventLocationInfoEdit";
import EventPriceInfoTab from "./event_edit/EventPriceInfoTab";
import EventOpeningAndVisibilityEdit from "./event_edit/EventOpeningAndVisibilityEdit";
import EventContactInfoTab from "./event_edit/EventContactInfoTab";
import AppStyles from "../../../styles/appStyles";
import TabGeneralIcon from "../../../assets/icons/TabGeneral";
import TabPricingIcon from "../../../assets/icons/TabPricing";
import TabLocationsIcon from "../../../assets/icons/TabLocations";
import TabContactIcon from "../../../assets/icons/TabContact";
import TabOpeningHoursIcon from "../../../assets/icons/TabOpeningHours";
import TabImagesIcon from "../../../assets/icons/TabImages";
import EventFormTab from "../../../components/eventEdit/EventFormTab";
import EventFormProgress from "../../../components/eventEdit/EventFormProgress";
import { useState } from "react";
import useEventFormValidation from "../../../components/eventEdit/useEventFormValidation";
import * as EventFormActions from "../../../actions/eventForm";
import EventEditTopPlacementTab from "./event_edit/EventEditTopPlacementTab";
import TabTopPlacementIcon from "../../../assets/icons/TabTopPlacement";

export interface Props {
  eventFormState: EventFormState;
  authenticationState: AuthenticationState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  children?: any;
}

export interface State {
  tab: number;
}

function EventEditForm(props: Props) {
  const { eventFormState, eventFormActions, companyState, authenticationState, children } = props;

  const [showShareSuccess, setShowShareSuccess] = useState(false);
  const [tab, setTab] = useState(0);

  function addDedicatedTime() {
    eventFormActions.addEventDedicatedTime();
  }

  function removeDedicatedTime(time: any) {
    eventFormActions.removeEventDedicatedTime(time);
  }

  function changeTab(event: any, newTab: any) {
    setTab(newTab);
  }

  function selectedPromotionMonths() {
    if (eventFormState.event.promotion_months) {
      return eventFormState.event.promotion_months.split(",");
    }
    return [];
  }

  function changeSelectedPromotionMonths(months: string[]) {
    eventFormActions.updateEventFormField({ prop: "event.promotion_months", value: months.join(",") });
  }

  function renderEventURL() {
    if (!eventFormState.event.company_slug || !eventFormState.event.normalized_title) {
      return null;
    }

    return (
      <Paper sx={AppStyles.innerContentPaper}>
        <Grid
          item
          container
          direction="row"
          sx={AppStyles.detailForm}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            {intl.get("event_form.label.url")}{" "}
            <LinkButton
              target={"_blank"}
              to={`${process.env.REACT_APP_SHARING_BASE_URL}/events/${eventFormState.event.company_slug}/${eventFormState.event.normalized_title}`}
              title={`${process.env.REACT_APP_SHARING_BASE_URL}/events/${eventFormState.event.company_slug}/${eventFormState.event.normalized_title}`}
            />
          </Grid>

          <Grid item>
            <CopyToClipboard
              text={`${process.env.REACT_APP_SHARING_BASE_URL}/events/${eventFormState.event.company_slug}/${eventFormState.event.normalized_title}`}
              onCopy={() => {
                setShowShareSuccess(true);
              }}
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                }}
                size="large"
              >
                <LinkIcon />
              </IconButton>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={12}>
            {intl.get("event_form.url_hint")}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function renderNotReviewableReasons() {
    if (!eventFormState.event.not_reviewable_reasons || eventFormState.event.not_reviewable_reasons.length === 0) {
      return null;
    }

    return (
      <Paper sx={AppStyles.innerContentPaperError}>
        <Grid item container direction="column" sx={AppStyles.detailForm} style={{ paddingBottom: 0 }}>
          <Grid item sx={AppStyles.noRightPadding}>
            <p
              style={{
                marginBottom: 0,
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 10,
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              <h5 style={{ color: "rgb(229,52,33)" }}>{intl.get("event_form.label.not_reviewable_reasons_title")}</h5>
              {intl.get("event_form.label.not_reviewable_reasons_description")}

              <ul>
                {eventFormState.event.not_reviewable_reasons.map((value) => {
                  return <li>{intl.get(`error.code.${value}`)}</li>;
                })}
              </ul>
            </p>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function renderValidationErrors() {
    if (eventFormState.validation_errors.length === 0) {
      return null;
    }

    return (
      <Paper sx={AppStyles.innerContentPaperError}>
        <Grid item container direction="column" sx={AppStyles.detailForm} style={{ paddingBottom: 0 }}>
          <Grid item sx={AppStyles.noRightPadding}>
            <p
              style={{
                marginBottom: 0,
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 10,
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              <h5 style={{ color: "rgb(229,52,33)" }}>{intl.get("event_form.validation.title")}</h5>

              <ul>
                {eventFormState.validation_errors.map((value) => {
                  return (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: value,
                      }}
                    />
                  );
                })}
              </ul>
            </p>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const { isMissingTimes, isMissingLocations, isMissingBaseData, isMissingImages } = useEventFormValidation();

  if (eventFormState.event == null) {
    return <div />;
  }

  const hasSomeKindOfUpgrade =
    (eventFormState.event?.booked_hero_slots && eventFormState.event?.booked_hero_slots.length > 0) ||
    (eventFormState.event?.event_upgrade && eventFormState.event?.event_upgrade !== "none");

  return (
    <Box sx={AppStyles.innerContentRootWithoutLimit}>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showShareSuccess}
        autoHideDuration={2000}
        onClose={() => {
          setShowShareSuccess(false);
        }}
      >
        <Alert severity="success">{intl.get("company_events.url_copied_to_clipboard")}</Alert>
      </Snackbar>

      {children}

      {renderNotReviewableReasons()}

      {renderEventURL()}
      {renderValidationErrors()}

      <Tabs
        sx={AppStyles.innerContentPaper}
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={changeTab}
        variant={"scrollable"}
        scrollButtons="auto"
      >
        <EventFormTab
          icon={<TabGeneralIcon />}
          missingData={isMissingBaseData === true}
          label={intl.get("event_form.tabs.tab_info")}
        />
        <EventFormTab
          icon={<TabLocationsIcon />}
          missingData={isMissingLocations === true}
          label={intl.get("event_form.tabs.tab_locations")}
        />
        <EventFormTab label={intl.get("event_form.tabs.tab_pricing")} icon={<TabPricingIcon />} />
        <EventFormTab label={intl.get("event_form.tabs.tab_contact_info")} icon={<TabContactIcon />} />
        <EventFormTab
          icon={<TabOpeningHoursIcon />}
          missingData={isMissingTimes === true}
          label={intl.get("event_form.tabs.tab_dates")}
        />
        <EventFormTab
          icon={<TabImagesIcon />}
          missingData={isMissingImages === true}
          label={intl.get("event_form.tabs.tab_media")}
        />
        <EventFormTab
          icon={<TabTopPlacementIcon />}
          missingData={!hasSomeKindOfUpgrade}
          label={intl.get("event_form.tabs.tab_top_placement")}
        />
      </Tabs>

      <EventFormProgress />

      {eventFormState.event.reject_reason && (
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <Grid item xs={12} container direction="column">
              <TextFieldWithLabel
                disabled={true}
                label={intl.get("event_form.label.reject_reason")}
                id="description"
                value={eventFormState.event.reject_reason}
                multiline
                margin="normal"
              />
            </Grid>
          </Grid>
        </Paper>
      )}

      {tab === 0 && (
        <EventGeneralInfoTab
          eventFormState={eventFormState}
          companyState={companyState}
          authenticationState={authenticationState}
          eventFormActions={eventFormActions}
        />
      )}

      {tab === 1 && (
        <EventLocationInfoEdit
          eventFormState={eventFormState}
          companyState={companyState}
          authenticationState={authenticationState}
          eventFormActions={eventFormActions}
        />
      )}

      {tab === 2 && (
        <EventPriceInfoTab
          eventFormState={eventFormState}
          companyState={companyState}
          authenticationState={authenticationState}
          eventFormActions={eventFormActions}
        />
      )}

      {tab === 3 && (
        <EventContactInfoTab
          companyState={companyState}
          eventFormState={eventFormState}
          authenticationState={authenticationState}
          eventFormActions={eventFormActions}
        />
      )}

      {tab === 4 && (
        <EventOpeningAndVisibilityEdit
          eventFormState={eventFormState}
          authenticationState={authenticationState}
          eventFormActions={eventFormActions}
          actions={eventFormActions}
          selectedPromotionMonths={selectedPromotionMonths}
          changeSelectedPromotionMonths={changeSelectedPromotionMonths}
          removeDedicatedTime={removeDedicatedTime}
          addDedicatedTime={addDedicatedTime}
        />
      )}

      {tab === 5 && <EventMediaEdit />}

      {tab === 6 && <EventEditTopPlacementTab event={eventFormState.event} />}
    </Box>
  );
}

function mapStateToProps(state: RootState) {
  return {
    eventFormState: state.eventFormState,
    companyState: state.companyState,
    pageState: state.pageState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    eventFormActions: bindActionCreators(EventFormActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventEditForm);
