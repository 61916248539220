import * as React from "react";
import { useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { BCEventStatus } from "../../../model/event";
import intl from "react-intl-universal";

interface Props {
  value?: BCEventStatus;
  onChange: (newValue: any) => void;
  sx?: any;
  disabled?: boolean;
}

const getAllowedStatusTransitions = (currentStatus?: BCEventStatus) => {
  switch (currentStatus) {
    case BCEventStatus.PUBLISHED:
      return [BCEventStatus.DRAFT];
    case BCEventStatus.DRAFT:
    case BCEventStatus.REJECTED: // can only be done if the user has permission!
    case BCEventStatus.READY_FOR_REVIEW: // can only be done if the user has permission!
      return [BCEventStatus.PUBLISHED];
    case BCEventStatus.FINISHED:
    case BCEventStatus.DELETED:
    case BCEventStatus.UNPUBLISHED:
    case null: // creating new event
      return [BCEventStatus.PUBLISHED, BCEventStatus.DRAFT];
    default:
      // READY_FOR_REVIEW
      // REJECTED
      return [];
  }
};

const getStatusLabel = (status: BCEventStatus) => {
  return intl.get("event_form.status_select.status_" + status.toLowerCase());
};

export default function EventEditStatusSelect({ value, onChange, sx, disabled }: Props) {
  useEffect(() => {
    // make FINISHED or DELETED events become DRAFT on save
    if (value === BCEventStatus.FINISHED || value === BCEventStatus.DELETED) {
      onChange(BCEventStatus.DRAFT);
    }
  }, [value]);

  const options = [value, ...getAllowedStatusTransitions(value)];

  const isOption = (option: BCEventStatus) => options.indexOf(option) !== -1;

  return (
    <Select
      sx={[
        sx,
        {
          minWidth: 180,
          paddingRight: 1,
          paddingLeft: 1,
          font: "normal normal bold 14px/19px Manrope",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "& .MuiSelect-icon": {
            fill: disabled ? undefined : "rgba(0,0,0,0.87)",
          },
        },
      ]}
      value={value || BCEventStatus.DRAFT}
      disabled={disabled}
      onChange={(event) => onChange(event.target?.value)}
      variant={"outlined"}
    >
      {Object.values(BCEventStatus).map((item: BCEventStatus) => {
        if (isOption(item)) {
          const label = getStatusLabel(item);
          return (
            <MenuItem value={item} title={label} key={item}>
              {label}
            </MenuItem>
          );
        }
      })}
    </Select>
  );
}
