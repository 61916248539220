import { AuthenticationState } from "../../model/authenticationState";

export class AuthUtils {
  static hasGlobalPermission = (authenticationState: AuthenticationState, anyPermission: string[]) => {
    if (authenticationState.user && authenticationState.user.permissions) {
      const userPermissions = authenticationState.user.permissions;
      let hasPermission = false;
      for (const permission of anyPermission) {
        if (userPermissions.indexOf(permission) !== -1) {
          hasPermission = true;
        }
      }
      return hasPermission;
    }
    return false;
  };

  static isAuthenticated = (authState: AuthenticationState) => {
    return authState.loading || authState.user !== null;
  };

  static setupNeeded = (authenticationState: AuthenticationState) => {
    return (
      authenticationState.user !== null &&
      authenticationState.user.b2b_profile != null &&
      authenticationState.user.b2b_profile.completed === false
    );
  };
}
