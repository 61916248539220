import { Box, Grid, Stack, SxProps } from "@mui/material";
import { BCEvent } from "../../model/event";
import intl from "react-intl-universal";
import HeroIcon from "../../assets/icons/Hero";
import HeroActiveIcon from "../../assets/icons/HeroActive";
import NewsletterIcon from "../../assets/icons/Newsletter";
import SocialMediaIcon from "../../assets/icons/SocialMedia";
import SquareButton from "../SquareButton";
import EventUpgradeButton from "./EventUpgradeButton";
import TextClamper from "../textClamper";

interface Props {
  event: BCEvent;
  sx?: SxProps;
  onHeroClicked: () => void;
  onTopPlacementClicked: () => void;
  onSocialMediaClicked: () => void;
  onNewsletterClicked: () => void;
}

export default function EventCardBookingButtons({
  event,
  sx,
  onHeroClicked,
  onTopPlacementClicked,
  onSocialMediaClicked,
  onNewsletterClicked,
}: Props) {
  const gap = { xs: 0.5, sm: 0.75, md: 0.75, lg: 1, xl: 1 };

  return (
    <Grid container sx={sx} columnSpacing={gap}>
      <Grid item xs={4.75} display={"flex"}>
        <EventUpgradeButton event={event} onTopPlacementClicked={onTopPlacementClicked} sx={{ flexGrow: 1 }} />
      </Grid>
      <Grid item xs={4.75} display={"flex"}>
        <SquareButton
          onClick={(e) => {
            e.stopPropagation();
            onHeroClicked();
          }}
          sx={{ flexGrow: 1 }}
          text={intl.get("company_events.event_card.button_hero")}
          active={event.booked_hero_slots?.length > 0}
          renderIcon={(iconProps, active) => (active ? <HeroActiveIcon {...iconProps} /> : <HeroIcon {...iconProps} />)}
          subInfo={<HeroWeekInfo event={event} />}
        />
      </Grid>
      <Grid item xs={2.5} container direction={"row"} rowSpacing={gap}>
        <Grid item xs={12} display={"flex"}>
          <SquareButton
            sx={{ flexGrow: 1 }}
            renderIcon={(iconProps) => <SocialMediaIcon {...iconProps} />}
            onClick={(e) => {
              e.stopPropagation();
              onSocialMediaClicked();
            }}
          />
        </Grid>
        <Grid item xs={12} display={"flex"}>
          <SquareButton
            sx={{ flexGrow: 1 }}
            renderIcon={(iconProps) => <NewsletterIcon {...iconProps} />}
            onClick={(e) => {
              e.stopPropagation();
              onNewsletterClicked();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function HeroWeekInfo({ event }: { event: BCEvent }) {
  if (!event.booked_hero_slots || event.booked_hero_slots.length === 0) {
    return null;
  }

  const weeks = event.booked_hero_slots.map((slot) => slot.slot_week);

  return (
    <Stack
      direction={"row"}
      style={{ font: "normal normal bold 12px/13px Manrope" }}
      alignItems={"baseline"}
      justifyContent={"center"}
    >
      <TextClamper numberOfLines={1} text={`KW ${weeks.filter((week, index) => index < 2).join(" ")}`} />
      {weeks.length > 2 && (
        <Box
          sx={(theme) => ({
            background: "white",
            borderRadius: "9px",
            color: theme.palette.primary.main,
            pl: "3px",
            pr: "3.5px",
            pt: "1.5px",
            pb: "1.5px",
            ml: "5px",
          })}
        >
          +{weeks.length - 2}
        </Box>
      )}
    </Stack>
  );
}
