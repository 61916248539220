import intl from "react-intl-universal";
import { useBeforeUnload, unstable_usePrompt } from "react-router-dom";
import { useCallback } from "react";

export default function useFormLeavePrompt(notSaved: boolean) {
  const confirmationMessage = intl.get(`leave_form_prompt.message`);

  const unloadHandler = useCallback(
    (e: BeforeUnloadEvent) => {
      {
        if (notSaved) {
          (e || window.event).returnValue = confirmationMessage;
          return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
        }
      }
    },
    [notSaved],
  );

  // page refresh,...
  useBeforeUnload(unloadHandler);

  // internal route changes
  unstable_usePrompt({
    message: confirmationMessage,
    when: ({ currentLocation, nextLocation }) => notSaved && currentLocation.pathname !== nextLocation.pathname,
  });
}
