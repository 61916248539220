import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";
import { SeoPage } from "../model/seo_pages";
import { BCEventCategory } from "../model/event";

export const fetchAllSeoPages = (page: number, size: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/seopages?page=${page}&size=${size}`);

    dispatch({
      type: ActionType.FETCH_SEOPAGES,
      payload: {
        data: true,
        seopages: res.data.seopages,
        paging: res.data.paging,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const deleteSeoPage = (seopage_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.DELETE_SEOPAGE, payload: { loading: true } });
  try {
    await axios.delete(`/v2/api/b2b/private/seopages/${seopage_id}`);

    dispatch({ type: ActionType.DELETE_SEOPAGE, payload: { data: true } });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const createNewSeoPage = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SEOPAGE_FORM });

  const cities = (await axios.get("/v2/api/b2b/private/seopages/cities")).data.cities;
  const tags = (await axios.get("/v2/api/tags-sorted")).data.tags;
  const categories = (await axios.get("/v2/api/categories")).data.categories;

  dispatch({
    type: ActionType.CREATE_SEOPAGE,
    payload: { cities, tags, categories },
  });
};

export const editSeoPage = (seopage_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SEOPAGE_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const res = await axios.get(`/v2/api/b2b/private/seopages/${seopage_id}`);
    const cities = (await axios.get("/v2/api/b2b/private/seopages/cities")).data.cities;
    const tags = (await axios.get("/v2/api/tags-sorted")).data.tags;
    const categories = (await axios.get("/v2/api/categories")).data.categories;

    dispatch({
      type: ActionType.EDIT_SEOPAGE,
      payload: {
        data: true,
        seopage: res.data,
        seopage_id: res.data.seopage_id,
        cities,
        tags,
        categories,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const saveSeoPage = (seopage: SeoPage, seopage_id?: string | null) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    if (seopage_id) {
      const res = await axios.put(`/v2/api/b2b/private/seopages/${seopage_id}`, seopage);
      dispatch({
        type: ActionType.SAVE_SEOPAGE,
        payload: { seopage: res.data },
      });
    } else {
      const res = await axios.post("/v2/api/b2b/private/seopages", seopage);
      dispatch({
        type: ActionType.SAVE_SEOPAGE,
        payload: { seopage: res.data },
      });
    }
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const resetSeoPageForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SEOPAGE_FORM });
};

export const closeSeoPage = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CLOSE_SEOPAGES });
};

export const toggleSeoPageCategory = (category: BCEventCategory) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.TOGGLE_SEOPAGE_CATEGORY, payload: category });
};

// @ts-ignore
export const updateSeoPageFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_SEOPAGE_FORM_FIELD,
    payload: { prop, value },
  };
};
