import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const GridViewIcon = (props) => (
  <SvgIcon id="Ebene_1" width="16.279" height="14" viewBox="0 0 16.279 14" {...props}>
    <path
      id="Pfad_6"
      data-name="Pfad 6"
      d="M6.088,6.054H1.041A1.04,1.04,0,0,1,0,5.018V1.036A1.039,1.039,0,0,1,1.041,0H6.088A1.04,1.04,0,0,1,7.129,1.036V5.018A1.04,1.04,0,0,1,6.088,6.054M1.48,4.574H5.649V1.48H1.48Zm13.758,1.48H10.191A1.04,1.04,0,0,1,9.15,5.018V1.036A1.039,1.039,0,0,1,10.191,0h5.047a1.04,1.04,0,0,1,1.041,1.036V5.018a1.04,1.04,0,0,1-1.041,1.036M10.63,4.574H14.8V1.48H10.63ZM6.088,14.059H1.041A1.04,1.04,0,0,1,0,13.023V9.041A1.04,1.04,0,0,1,1.041,8H6.088A1.04,1.04,0,0,1,7.129,9.041v3.982a1.04,1.04,0,0,1-1.041,1.036M1.48,12.579H5.649V9.485H1.48Zm13.758,1.48H10.191A1.04,1.04,0,0,1,9.15,13.023V9.041A1.04,1.04,0,0,1,10.191,8h5.046a1.04,1.04,0,0,1,1.041,1.036v3.982a1.04,1.04,0,0,1-1.041,1.036m-4.608-1.48H14.8V9.485H10.63Z"
      transform="translate(0)"
    />
  </SvgIcon>
);

export default GridViewIcon;
