import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { BCEvent } from "../model/event";
import { mapErrorFromException } from "../model/error";
import { convertPriceForBackend, convertPriceFromBackend } from "../util/util_extensions";

export const startEditingEvent =
  (company_id: string, event_id: string, readonly: boolean) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.RESET_EVENT_FORM });

    dispatch({ type: ActionType.PAGE_LOADING });

    try {
      const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/events/${event_id}`);
      const resTags = await axios.get("/v2/api/tags");
      const resCategories = await axios.get(`/v2/api/b2b/private/companies/${company_id}/categories`);
      const sharedLocations = await axios.get(`/v2/api/b2b/private/companies/${company_id}/shared_locations`);

      const eventData = res.data;
      eventData.price_in_cents = convertPriceFromBackend({ price: eventData.price_in_cents });
      eventData.price_in_cents_3yr = convertPriceFromBackend({ price: eventData.price_in_cents_3yr });
      eventData.price_in_cents_8yr = convertPriceFromBackend({ price: eventData.price_in_cents_8yr });
      eventData.price_in_cents_adult = convertPriceFromBackend({ price: eventData.price_in_cents_adult });

      dispatch({
        type: ActionType.EDIT_EVENT,
        payload: {
          data: true,
          company_id,
          event: eventData,
          event_id,
          categories: resCategories.data.categories,
          tags: resTags.data.tags,
          sharedLocations: sharedLocations.data.locations,
          readonly,
        },
      });

      dispatch({ type: ActionType.PAGE_LOADED });
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const saveEvent =
  (company_id: string, event_id: string | null, event: BCEvent, completion: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      let data = { ...event };
      if (event.age_from) {
        data = { ...data, age_from: Number(event.age_from) };
      }
      if (event.age_to) {
        data = { ...data, age_to: Number(event.age_to) };
      }
      data.price_in_cents = convertPriceForBackend({ price: data.price_in_cents });
      data.price_in_cents_3yr = convertPriceForBackend({ price: data.price_in_cents_3yr });
      data.price_in_cents_8yr = convertPriceForBackend({ price: data.price_in_cents_8yr });
      data.price_in_cents_adult = convertPriceForBackend({ price: data.price_in_cents_adult });
      if (data.website_url != null && data.website_url.isEmpty()) {
        data.website_url = null;
      }
      data.tags = event.tags.map((tagValue) => {
        if (tagValue.tag_id.startsWith("___created___")) {
          return { tag_id: undefined, name: tagValue.name };
        } else {
          return tagValue;
        }
      });

      let eventData: any;
      if (event_id) {
        const response = await axios.put(`/v2/api/b2b/private/companies/${company_id}/events/${event_id}`, data);
        eventData = response.data;
      } else {
        const response = await axios.post(`/v2/api/b2b/private/companies/${company_id}/events`, data);

        eventData = response.data;
      }

      eventData.price_in_cents = convertPriceFromBackend({ price: eventData.price_in_cents });
      eventData.price_in_cents_3yr = convertPriceFromBackend({ price: eventData.price_in_cents_3yr });
      eventData.price_in_cents_8yr = convertPriceFromBackend({ price: eventData.price_in_cents_8yr });
      eventData.price_in_cents_adult = convertPriceFromBackend({ price: eventData.price_in_cents_adult });

      dispatch({ type: ActionType.SAVE_EVENT, payload: { event: eventData } });

      dispatch({ type: ActionType.PAGE_SAVED });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const reviewedEvent = (event_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.EVENT_REVIEW, payload: { event_id } });
};

export const changeEventUpgrade =
  (company_id: string, event_id: string, upgrade_type: string, noUpgradeSlots: any) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.DELETE_EVENT, payload: { loading: true } });
    try {
      const res = await axios.put(`/v2/api/b2b/private/companies/${company_id}/events/${event_id}/product_upgrade`, {
        upgrade: upgrade_type,
      });

      dispatch({
        type: ActionType.UPDATE_EVENT_STATUS,
        payload: { data: true, event: res.data.event, slotInfo: res.data.slot_info },
      });

      return true;
    } catch (error) {
      const anyError = error as any;
      if (
        anyError &&
        anyError.response &&
        anyError.response.data &&
        anyError.response.data.error_code &&
        anyError.response.data.error_code === "ERROR_NO_UPGRADE_SLOTS"
      ) {
        noUpgradeSlots();
      } else {
        dispatch({
          type: ActionType.PAGE_SAVING_ERROR,
          payload: { error: mapErrorFromException(error) },
        });
      }
      return false;
    }
  };

export const changeEventUpgradeBulk =
  (company_id: string, event_ids: string[], upgrade_type: string) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.DELETE_EVENT, payload: { loading: true } });
    try {
      const res = await axios.put(`/v2/api/b2b/private/companies/${company_id}/event_product_upgrade`, {
        upgrade: upgrade_type,
        event_ids: event_ids,
      });

      dispatch({
        type: ActionType.UPDATE_EVENT_STATUS_BULK,
        payload: { data: true, events: res.data.events, slotInfo: res.data.slot_info },
      });

      return { success: true, response: res };
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
      return { success: false, error };
    }
  };

export const deleteEvent = (company_id: string, event_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.DELETE_EVENT, payload: { loading: true } });
  try {
    await axios.delete(`/v2/api/b2b/private/companies/${company_id}/events/${event_id}`);

    dispatch({
      type: ActionType.DELETE_EVENT,
      payload: { data: true, event_id },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const fetchTransferCompaniesForEvent = (companyId: string, eventId: string) => async (dispatch: Dispatch) => {
  try {
    const res = await axios.get(`/v2/api/b2b/private/companies/${companyId}/events/${eventId}/transfer`);

    dispatch({
      type: ActionType.FETCH_TRANSFER_COMPANIES,
      payload: { transferCompanies: res.data.companies, event: res.data.event },
    });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const transferEvent =
  (companyId: string, eventId: string, targetCompanyId: string, completion: any) => async (dispatch: Dispatch) => {
    try {
      await axios.put(`/v2/api/b2b/private/companies/${companyId}/events/${eventId}/transfer`, {
        company_id: targetCompanyId,
        transer_type: "move",
        delete_unused_data: false,
      });

      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };
