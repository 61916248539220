import { Button, ButtonProps, CircularProgress, SxProps } from "@mui/material";
import TextClamper from "./textClamper";

interface SquareButtonProps extends Pick<ButtonProps, "onClick"> {
  text?: string;
  sx?: SxProps;
  renderIcon?: (iconProps: any, active: boolean) => void;
  active?: boolean;
  loading?: boolean;
  subInfo?: any;
  additionalLineCount?: number;
  showPrimaryLineGradient?: boolean;
}

export default function SquareButton({
  text,
  renderIcon,
  active,
  sx,
  onClick,
  loading,
  subInfo,
  additionalLineCount,
  showPrimaryLineGradient,
}: SquareButtonProps) {
  const iconOnlyButton = () => renderIcon({ style: { padding: "3px" } }, Boolean(active));

  const textIconButton = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        maxWidth: "100%",
        fontSize: "0.75rem",
      }}
    >
      <>
        {renderIcon && renderIcon({ style: {} }, Boolean(active))}
        <div>
          <TextClamper numberOfLines={(subInfo ? 1 : 2) + (additionalLineCount ?? 0)} text={text} center fixedHeight />
          {subInfo}
        </div>
      </>
    </div>
  );

  return (
    <Button
      onClick={onClick}
      disabled={loading}
      color={active ? "primary" : "primaryLight"}
      sx={[
        sx as any,
        (theme) => ({
          aspectRatio: "1 / 1",
          minWidth: 0,
          minHeight: 0,
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: loading ? theme.palette.primary.light + " !important" : undefined,
          background:
            loading || !showPrimaryLineGradient
              ? undefined
              : "repeating-linear-gradient(45deg, #6E7CE0,#6E7CE0 5px, #8b96e6 5px, #8b96e6 10px)",
          font: "normal normal bold 12px/13px Manrope",
        }),
      ]}
    >
      {loading && <CircularProgress size={24} />}
      {!loading && ((text ? textIconButton() : iconOnlyButton()) as any)}
    </Button>
  );
}
