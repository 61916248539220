import { DefaultButton } from "../../../components/Buttons";
import RejectIcon from "@mui/icons-material/Close";
import intl from "react-intl-universal";
import ApproveIcon from "@mui/icons-material/Check";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { EventFormState } from "../../../model/eventFormState";
import axios from "axios";
import * as EventActions from "../../../actions/event";

interface Props {
  onApproved: () => void;
  onRejected: () => void;
  rejectionDescription: string;
  companyId: string;
}

export default function EventReviewHeaderActions({ onApproved, onRejected, rejectionDescription, companyId }: Props) {
  const eventFormState = useSelector<RootState, EventFormState>((rootState) => {
    return rootState.eventFormState;
  });

  const dispatch = useDispatch();

  async function rejectEvent() {
    try {
      const eventId = eventFormState.event.event_id;
      await axios.put(`/v2/api/b2b/private/companies/${companyId}/events/${eventId}/status`, {
        status: "REJECTED",
        reject_reason: rejectionDescription,
      });
      dispatch<any>(EventActions.reviewedEvent(eventId));
      onRejected();
    } catch (err) {
      // TODO
    }
  }

  async function approveEvent() {
    try {
      const eventId = eventFormState.event.event_id;
      await axios.put(`/v2/api/b2b/private/companies/${companyId}/events/${eventId}/status`, { status: "PUBLISHED" });
      dispatch<any>(EventActions.reviewedEvent(eventId));
      onApproved();
    } catch (err) {
      // TODO
    }
  }

  return (
    <>
      {(eventFormState.event.status === "READY_FOR_REVIEW" || eventFormState.event.status === "PUBLISHED") && (
        <DefaultButton color={"error"} onClick={rejectEvent}>
          <RejectIcon />
          {intl.get("event_review.button_reject")}
        </DefaultButton>
      )}
      {eventFormState.event.needs_review && (
        <DefaultButton onClick={approveEvent}>
          <ApproveIcon />
          {intl.get("event_review.button_approve")}
        </DefaultButton>
      )}
    </>
  );
}
