import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabContactIcon = (props) => (
  <SvgIcon width="28.048" height="19" viewBox="0 0 28.048 19" {...props}>
    <path
      id="Pfad_160"
      data-name="Pfad 160"
      d="M26.552,0H2.049A2.049,2.049,0,0,0,0,2.049V16.6a2.049,2.049,0,0,0,2.049,2.049h24.5A2.049,2.049,0,0,0,28.6,16.6V2.049A2.049,2.049,0,0,0,26.552,0M19.376,3.585a4.351,4.351,0,1,1-4.361,4.351,4.361,4.361,0,0,1,4.361-4.351M8.935,13.679H5.806a.933.933,0,1,1,0-1.865H8.935a.933.933,0,1,1,0,1.865m2.292-3.42H5.806a.933.933,0,0,1,0-1.865h5.421a.933.933,0,1,1,0,1.865m0-3.42H5.806a.933.933,0,0,1,0-1.865h5.421a.933.933,0,1,1,0,1.865m2.628,9.168a9.51,9.51,0,0,1,3.206-2.921L19,14.316a.658.658,0,0,0,.758-.019l1.935-1.2A9.479,9.479,0,0,1,24.9,16.007Z"
    />
  </SvgIcon>
);

export default TabContactIcon;
