import { BCEvent } from "../../model/event";
import { Box, SxProps, Theme } from "@mui/material";
import * as React from "react";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

interface Props {
  event: BCEvent;
  sx?: SxProps;
  onClick?: () => void;
  placeHolderSize?: number;
}

export default function EventCoverImage({ event, sx, onClick, placeHolderSize }: Props) {
  const handleClick = (e: any) => {
    e.stopPropagation();
    onClick();
  };

  if (!event.media_items || event.media_items.length == 0) {
    return (
      <Box
        onClick={handleClick}
        sx={[
          sx as any,
          (theme: Theme) => ({
            background: theme.palette.divider,
            color: theme.palette.primary.main,
            cursor: onClick ? "pointer" : undefined,
          }),
        ]}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <ImageOutlinedIcon
          style={{
            width: placeHolderSize || 45,
            height: placeHolderSize || 45,
            opacity: 0.2,
            color: "#000",
            cursor: onClick ? "pointer" : undefined,
          }}
        />
      </Box>
    );
  }

  const media = event.media_items[0];

  return (
    <Box
      onClick={handleClick}
      sx={sx}
      component={"img"}
      alt=""
      style={{ alignContent: "center", objectFit: "cover", cursor: onClick ? "pointer" : undefined }}
      src={media.large_preview_url}
    ></Box>
  );
}
