import { Box, SxProps, Typography } from "@mui/material";

export default function InfoBox({ sx, children }: { sx?: SxProps; children?: any }) {
  return (
    <Box
      sx={[
        (theme) => ({ background: theme.palette.primary.light, px: "14px", py: "10px", borderRadius: "5px" }),
        sx as any,
      ]}
    >
      {children}
    </Box>
  );
}

export function InfoBoxLabelValueRow({
  color,
  label,
  value,
  sx,
}: {
  color?: string;
  label: string;
  value: string | number;
  sx?: SxProps;
}) {
  return (
    <Box sx={[{ display: "flex", flexDirection: "row" }, sx as any]}>
      <Typography color={color} variant={"body2"} sx={{ fontWeight: 600 }}>
        {label}
      </Typography>
      <Typography color={color} sx={{ marginLeft: "auto", fontWeight: 600 }} variant={"body2"}>
        {value}
      </Typography>
    </Box>
  );
}
