import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import httpInterceptors from "../interceptors";
import * as AuthActions from "../actions/auth";
import useUser from "../hooks/useUser";

axios.defaults.headers.common["x-app-scope"] = "b2b";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function useAxiosSetup() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useUser();

  useEffect(() => {
    httpInterceptors.setupAxiosInterceptors(() => {
      dispatch<any>(AuthActions.logout(false, null));
      navigate(`/login${location.search}`);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("b2b-access-token");

    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch<any>(AuthActions.fetchUserInfo());
    } else {
      dispatch<any>(AuthActions.logout(false, null));
    }
  }, [user?.user_id]);
}
