import axios, { AxiosError, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import useLogger from "./useLogger";
import { ActionType } from "../model/actions";
import { mapErrorFromException, Error as BCError } from "../model/error";

export interface ApiResponse<BodyType> {
  ok: boolean;
  status: number;
  error?: BCError;
  body?: BodyType;
}

interface Options {
  blockUI?: boolean;
}

const requestWrapper = async <BodyType>(
  logger: any,
  dispatch: any,
  method: "GET" | "POST",
  url: string,
  body?: any,
  options?: Options,
): Promise<ApiResponse<BodyType>> => {
  if (options?.blockUI) {
    dispatch({ type: ActionType.PAGE_LOADING });
  }

  try {
    let request: Promise<AxiosResponse>;

    switch (method) {
      case "GET":
        request = axios.get(url);
        break;
      case "POST":
        request = axios.post(url, JSON.stringify(body));
        break;
      default:
        throw new Error("Unknown Method: " + method);
    }

    const res = await request;

    logger.info(res.status, method, url, res.data);
    if (options?.blockUI) {
      dispatch({ type: ActionType.PAGE_LOADED });
    }
    return {
      ok: true,
      status: res.status,
      body: res.data as BodyType,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      logger.error(error.response?.status || "ERROR", method, url, error);
    } else {
      logger.error("ERROR", method, url, error);
    }

    const mapped = mapErrorFromException(error);
    if (options?.blockUI) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: {
          error: mapped,
        },
      });
    }
    return {
      ok: false,
      status: mapped.statusCode,
      error: mapped,
    };
  }
};

export default function useApi(logTag: string = "useApi") {
  const dispatch = useDispatch();
  const logger = useLogger(logTag);

  const get = async <BodyType>(url: string, options: Options = {}) => {
    return requestWrapper<BodyType>(logger, dispatch, "GET", url, undefined, options);
  };

  const post = async <BodyType>(url: string, body: any, options: Options = {}) => {
    return requestWrapper<BodyType>(logger, dispatch, "POST", url, body, options);
  };

  return {
    get,
    post,
  };
}
