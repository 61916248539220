import * as React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../../../model/eventFormState";
import { CompanyState } from "../../../../model/companyState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import EventContactInfoEdit from "./EventContactInfoEdit";
import EventContactPersonEdit from "./EventContactPersonEdit";
import { DefaultButton } from "../../../../components/Buttons";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventContactInfoTab extends React.Component<Props> {
  renderActionButtons() {
    if (this.props.eventFormState.readonly) {
      return null;
    }

    return (
      <Grid item xs={12} container direction={"row"} sx={AppStyles.detailFormRowNoBorder}>
        <DefaultButton
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.eventFormActions.setCompanyContactAsEventContact(this.props.companyState.company);
          }}
        >
          Kontaktdaten aus Firmendaten übernehmen
        </DefaultButton>
        &nbsp;
        <DefaultButton
          onClick={(event: any) => {
            event.stopPropagation();
            this.props.eventFormActions.setCompanyContactPersonAsEventContactPerson(this.props.companyState.company);
          }}
        >
          Ansprechpartner aus Firmendaten übernehmen
        </DefaultButton>
        &nbsp;
      </Grid>
    );
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <Stack>
        {this.renderActionButtons()}

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.contact_info`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventContactInfoEdit
              actions={this.props.eventFormActions}
              eventFormState={this.props.eventFormState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.contact_person`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventContactPersonEdit
              eventFormState={this.props.eventFormState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>
      </Stack>
    );
  }
}

export default EventContactInfoTab;
