import { Box, Stack, Typography } from "@mui/material";
import intl from "react-intl-universal";
import RecurringIcon from "../../../assets/icons/Recurring";

export default function GeneralProductDrawerHeader() {
  return (
    <Stack direction={"row"} gap={"15px"} alignItems={"center"} sx={{ marginBottom: "41px" }}>
      <Box
        sx={(theme) => ({
          background: theme.palette.primary.light,
          borderRadius: "5px",
          width: "79px",
          height: "44px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <RecurringIcon />
      </Box>
      <Typography sx={{ flexGrow: 1, font: "normal normal bold 14px/18px Manrope" }}>
        {intl.get("booking_drawer.premium_upgrade.header_label")}
      </Typography>
    </Stack>
  );
}
