import TextFieldWithLabel from "../../../../../components/TextFieldWithLabel";
import intl from "react-intl-universal";
import * as React from "react";
import { Constants } from "../event_options";
import { CompanyFormState } from "../../../../../model/companyFormState";
import * as CompanyActions from "../../../../../actions/company";

export function CompanyEditTextInputField(props: {
  formState: CompanyFormState;
  formActions: typeof CompanyActions;
  field: string;
  maxlength?: number;
  required?: boolean;
  onChange?: any;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  hideClearIcon?: boolean;
}) {
  return (
    <TextFieldWithLabel
      placeholder={props.placeholder}
      type={props.type}
      required={props.required ?? false}
      maxLength={props.maxlength}
      hideClearIcon={props.hideClearIcon}
      label={intl.get(`company_form.label.${props.field}`)}
      id={props.field}
      margin="normal"
      disabled={props.disabled}
      onChange={(event) => {
        props.formActions.updateCompanyFormField({
          prop: `company.${props.field}`,
          value: event.target.value,
        });
        if (props.onChange) {
          props.onChange(event);
        }
      }}
      value={Constants.fetchedValueFromCompanyPropPath(props.formState, props.field)}
    />
  );
}
