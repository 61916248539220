import { useDispatch } from "react-redux";
import { Company } from "../../model/company";
import { MenuItem, Select } from "@mui/material";
import * as React from "react";
import * as CompanyActions from "../../actions/company";
import SelectArrowDownIcon from "../../assets/icons/SelectArrowDown";
import { useNavigate } from "react-router-dom";
import useBackendLoader from "../../hooks/useBackendLoader";
import useSelectedCompany from "../../hooks/useSelectedCompany";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export default function HeaderCompanySelection() {
  const dispatch = useDispatch();

  const selectedCompany = useSelectedCompany();

  const loader = useBackendLoader<any, Company[]>(
    "/v2/api/b2b/private/companies",
    (response) => response.companies,
    "company_select",
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [companyToCheck, setCompanyToCheck] = useState<string | undefined>();

  const handleChanged = (event: any) => {
    const newCompanyId = event.target.value;
    setCompanyToCheck(newCompanyId);
    navigate(`/companies/${newCompanyId}/company_events`);
  };

  // dispatch actions after navigation so we don't break functionality or
  // produce inconsistent states in combination with leave prompts
  useEffect(() => {
    if (companyToCheck && location.pathname.indexOf(`/companies/${companyToCheck}/company_events`) >= 0) {
      const companyId = companyToCheck;
      setCompanyToCheck(undefined);
      dispatch<any>(CompanyActions.selectCompany(companyId, false));
      dispatch<any>(CompanyActions.fetchCompanyInformation(companyId));
    }
  }, [location]);

  if (!selectedCompany) {
    return null;
  }

  return (
    <Select
      variant={"standard"}
      disableUnderline
      disabled={loader.loading || !loader.data}
      id="header-company-select"
      value={selectedCompany.company_id}
      onChange={handleChanged}
      IconComponent={SelectArrowDownIcon}
      sx={{
        color: "#3B3D3B",
        font: "normal normal 800 14px/21px Manrope",
        "& .MuiSelect-icon": { color: "#3B3D3B", width: 10 },
        "& .MuiSelect-select": { background: "transparent !important" },
      }}
    >
      {(loader.loading || !loader.data || loader.data.length === 0) && (
        <MenuItem value={selectedCompany.company_id} key={selectedCompany.company_id}>
          {selectedCompany.name}
        </MenuItem>
      )}
      {loader.data?.map((company) => (
        <MenuItem value={company.company_id} key={company.company_id}>
          {company.name}
        </MenuItem>
      ))}
    </Select>
  );
}
