import { Alert, IconButton, Snackbar, Stack, SxProps } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@mui/icons-material/Link";
import WithPermission from "../WithPermission";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WithCompanyPermission from "../WithCompanyPermission";
import * as React from "react";
import { BCEvent } from "../../model/event";
import intl from "react-intl-universal";
import { useState } from "react";

interface Props {
  sx?: SxProps;
  event: BCEvent;
  onDeleteEvent: () => void;
  onTransferEvent: () => void;
  direction: "row" | "column";
  size?: "small" | "large";
}

export default function EventCardActionButtons({ sx, event, direction, onDeleteEvent, onTransferEvent, size }: Props) {
  const [showShareSuccess, setShowShareSuccess] = useState(false);

  return (
    <WithCompanyPermission
      ignoreShowCompanyDetailMenuFlag
      hasAnyPermission={["EDIT_COMPANY_EVENTS", "CREATE_COMPANY_EVENTS"]}
      renderExit={false}
    >
      <Stack sx={sx} direction={direction} justifyContent={"center"} alignItems={"center"}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDeleteEvent();
          }}
          size={size}
        >
          <ClearIcon />
        </IconButton>

        <CopyToClipboard
          text={`${process.env.REACT_APP_SHARING_BASE_URL}/events/${event.company_slug}/${event.normalized_title}`}
          onCopy={() => {
            setShowShareSuccess(true);
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
            size={size}
          >
            <LinkIcon />
          </IconButton>
        </CopyToClipboard>

        <WithPermission hasAnyPermission={["TRANSFER_EVENTS"]} showForCompanyPageAsWell={true}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onTransferEvent();
            }}
            size={size}
          >
            <LocalShippingIcon />
          </IconButton>
        </WithPermission>
      </Stack>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showShareSuccess}
        autoHideDuration={2000}
        onClose={() => {
          setShowShareSuccess(false);
        }}
      >
        <Alert severity="success">{intl.get("company_events.url_copied_to_clipboard")}</Alert>
      </Snackbar>
    </WithCompanyPermission>
  );
}
