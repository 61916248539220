import { PickersCalendarHeaderProps } from "@mui/x-date-pickers-pro";
import { addMonths, format, isAfter, isBefore } from "date-fns";
import { IconButton, Stack, Typography } from "@mui/material";
import NavArrowLeftSmallIcon from "../../assets/icons/NavArrowLeftSmall";
import NavArrowRightSmallIcon from "../../assets/icons/NavArrowRightSmall";

export default function CalendarMonthHeader({
  disabled,
  currentMonth,
  onMonthChange,
  minDate,
  maxDate,
}: PickersCalendarHeaderProps<any>) {
  const canGoLeft = !minDate || isBefore(minDate, currentMonth);
  const canGoRight = !maxDate || isAfter(maxDate, currentMonth);

  const leftArrowClicked = () => {
    if (canGoLeft) {
      onMonthChange(addMonths(currentMonth, -1), "right");
    }
  };

  const rightArrowClicked = () => {
    if (canGoRight) {
      onMonthChange(addMonths(currentMonth, 1), "left");
    }
  };

  return (
    <Stack direction={"row"} alignItems={"center"} sx={{ marginRight: "88px", marginBottom: "24px" }}>
      <IconButton size={"small"} disabled={disabled || !canGoLeft} onClick={leftArrowClicked}>
        <NavArrowLeftSmallIcon sx={{ width: "7px", height: "12px" }} />
      </IconButton>
      <Typography sx={{ flexGrow: 1, textAlign: "center", font: "normal normal normal 14px/19px Manrope" }}>
        {format(currentMonth as Date, "MMMM yyyy")}
      </Typography>
      <IconButton disabled={disabled || !canGoRight} size={"small"} onClick={rightArrowClicked}>
        <NavArrowRightSmallIcon sx={{ width: "7px", height: "12px" }} />
      </IconButton>
    </Stack>
  );
}
