import * as React from "react";
import { ReactComponent as BlueCherriesNetworkLogo } from "../../assets/icons/bcn_logo.svg";
import { Box } from "@mui/material";

export default function HeaderLogoAndName() {
  return (
    <Box
      sx={(theme) => ({
        background: "#f2f2f2",
        color: theme.palette.primary.main,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "row",
        height: "120px",
        minHeight: "120px",
        paddingLeft: "30px",
      })}
    >
      <BlueCherriesNetworkLogo
        style={{
          marginTop: 16,
          height: 80,
          width: 110,
        }}
      />
    </Box>
  );
}
