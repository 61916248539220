import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const ListViewIcon = (props) => (
  <SvgIcon id="Ebene_1" width="18.75" height="12" viewBox="0 0 18.75 12" {...props}>
    <path
      id="Pfad_5"
      data-name="Pfad 5"
      d="M17.64,4.956H8.528a1.109,1.109,0,0,1-1.11-1.1V1.1A1.108,1.108,0,0,1,8.527,0H17.64a1.109,1.109,0,0,1,1.11,1.1V3.851a1.108,1.108,0,0,1-1.11,1.1M9.048,3.325H17.12V1.63H9.048Zm8.592,8.5H8.528a1.109,1.109,0,0,1-1.11-1.105V7.97a1.108,1.108,0,0,1,1.11-1.1H17.64a1.108,1.108,0,0,1,1.11,1.1v2.746a1.109,1.109,0,0,1-1.11,1.1M9.048,10.19H17.12V8.5H9.048ZM4.826,4.956H1.145A1.14,1.14,0,0,1,0,3.824V1.132A1.139,1.139,0,0,1,1.145,0H4.825A1.14,1.14,0,0,1,5.97,1.132V3.824A1.139,1.139,0,0,1,4.826,4.956m-3.2-1.63H4.34V1.63H1.63Zm3.2,8.5H1.145A1.14,1.14,0,0,1,0,10.689V8A1.139,1.139,0,0,1,1.145,6.865H4.825A1.14,1.14,0,0,1,5.97,8v2.692a1.139,1.139,0,0,1-1.145,1.132m-3.2-1.63H4.34V8.5H1.63Z"
    />
  </SvgIcon>
);

export default ListViewIcon;
