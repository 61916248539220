import { Action, ActionType } from "../model/actions";
import createReducer from "./createReducer";
import { UserFormState } from "../model/userFormState";
import { asNonEmptyString, cloneDefaultState, updateStateFieldByProp } from "../util/util_extensions";

const defaultState = {
  user_id: null,
  user: {
    user_id: "",
    email: "",
    first_name: "",
    last_name: "",
    role: "",
  },
  valid: false,
  error: null,
  closePage: false,
};

export const userFormState = createReducer(
  { ...cloneDefaultState(defaultState) },
  {
    [ActionType.RESET_USER_FORM]() {
      return cloneDefaultState(defaultState);
    },
    [ActionType.CREATE_USER]() {
      return { ...cloneDefaultState(defaultState) };
    },
    [ActionType.UPDATE_USER_FORM_FIELD](state: UserFormState, action: Action<any>) {
      let newState = updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);

      if (!asNonEmptyString(newState.user.email)) {
        newState = { ...newState, valid: false };
      } else {
        newState = { ...newState, valid: true };
      }

      return newState;
    },
    [ActionType.SAVE_USER](state: UserFormState) {
      return { ...state, closePage: true };
    },
  },
);
