import { FontWeightProperty } from "csstype";
import { createTheme } from "@mui/material/styles";
import { PaletteColorOptions } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const drawerWidth = "300px";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#6E7CE0",
      contrastText: "#fff",
      light: "#F3F4FC",
    },
    primaryInverse: {
      main: "#fff",
      contrastText: "#6E7CE0",
      dark: "#CECCFF",
    },
    primaryLight: {
      light: "#F6F7FF",
      main: "#F3F4FC",
      dark: "#CECCFF",
      contrastText: "#707CDA",
    },
    secondary: {
      main: "#33B592",
      contrastText: "#fff",
    },
    background: {
      default: "white",
    },
    error: {
      main: "#cc0033",
      dark: "#b7002d",
      light: "#ffe0e7",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff9800",
      dark: "#b26a00",
      light: "#ffc296",
      contrastText: "#fff",
    },
    warningLight: {
      main: "#ffc296",
      dark: "#ffaa6d",
      light: "#ffd1b0",
      contrastText: "#3c3c3b",
    },
    success: {
      main: "#33B592",
      dark: "#357a38",
      contrastText: "#fff",
    },
    defaultTextColor: {
      main: "#3b3d3b",
    },
    divider: "#E0E0E0",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          borderRadius: 5,
          minHeight: 40,
        },
        sizeLarge: {
          minHeight: 50,
        },
      },
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderColor: "#CECCFF",
          color: "#6E7CE0",
          backgroundColor: "#fff",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#F3F4FC !important",
          },
          "&.Mui-selected": {
            borderColor: "#6E7CE0",
            color: "#fff",
            backgroundColor: "#6E7CE0 !important",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: drawerWidth,
        },
        paper: {
          width: drawerWidth,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "0px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#6E7CE0",
          font: "normal normal bold 14px/15px Manrope",
          backgroundColor: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#6E7CE0",
            color: "#fff",
            borderRight: "none",
            marginLeft: "-1px",
            ":first-of-type": {
              marginLeft: "0px",
            },
          },
          textTransform: "none",
          borderRight: "1px solid #e6e6e4",
          ":last-of-type": {
            borderRight: "none",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          },
          ":first-of-type": {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          },
        },
        iconWrapper: {
          marginBottom: "9px !important",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          color: "#6E7CE0",
        },
        indicator: {
          display: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          font: "normal normal bold 14px/18px Manrope !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(224, 224, 224, 0.5)",
        },
        head: {
          borderBottom: "1px solid rgba(224, 224, 224, 0.9)",
        },
        body: {
          fontSize: "0.9em",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "10px 10px",
          paddingTop: "0",
          paddingBottom: "0",
          height: "38px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#3c3c3b",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#c4c4c3",
          borderTop: "none",
        },
      },
    },
  },
  bodyTextColor: "#000000",
  darkContrastText: "#6E7CE0",
  colors: {
    lightGrey: "#FBFBFB",
    darkGrey: "#424242",
    red: "#cc0033",
    darkRed: "#b7002d",
    lightRed: "#ffe0e7",
  },
  typography: {
    fontFamily: "Manrope",
    allVariants: {
      color: "#3B3D3B",
    },
  },
  fontFamily: "Manrope",
  headerFontFamily: "Manrope",
  headerFontWeight: "bold",
  importantBodyFontWeight: "normal",
  fontSizes: {
    // base font size: 16px
    veryLargeFont: "2.5rem", // 40px
    largerFont: "1.875rem", // 30px
    largeFont: "1.375rem", // 22px
    biggerFont: "1.125rem", // 18px
    mediumFont: "1rem", // 16px
    smallFont: "0.875rem", // 14px
    verySmallFont: "0.75rem", // 12px
  },
  maxWidth: 960,
  topBar: {
    backgroundColor: "#f2f2f2",
    contrastText: "#6E7CE0",
    height: 35,
    iconHeight: 22,
  },
  dimensions: {
    drawerWidth: drawerWidth,
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    primaryLight: PaletteColorOptions;
    primaryInverse: PaletteColorOptions;
    warningLight: PaletteColorOptions;
    defaultTextColor: PaletteColorOptions;
  }
  interface PaletteOptions {
    primaryLight: PaletteColorOptions;
    primaryInverse: PaletteColorOptions;
    warningLight: PaletteColorOptions;
    defaultTextColor: PaletteColorOptions;
  }
  interface Theme {
    fontSizes: {
      veryLargeFont: string;
      largerFont: string;
      largeFont: string;
      biggerFont: string;
      mediumFont: string;
      smallFont: string;
      verySmallFont: string;
    };
    darkContrastText: string;
    bodyTextColor: string;
    colors: {
      lightGrey: string;
      darkGrey: string;
      red: string;
      darkRed: string;
      lightRed: string;
    };
    maxWidth: number;
    fontFamily: string;
    headerFontFamily: string;
    headerFontWeight: FontWeightProperty;
    importantBodyFontWeight: FontWeightProperty;
    topBar: {
      backgroundColor: string;
      height: number;
      iconHeight: number;
      contrastText: string;
    };
    dimensions: {
      drawerWidth: string;
    };
  }

  interface ThemeOptions {
    fontSizes: {
      veryLargeFont: string;
      largerFont: string;
      largeFont: string;
      biggerFont: string;
      mediumFont: string;
      smallFont: string;
      verySmallFont: string;
    };
    darkContrastText: string;
    bodyTextColor: string;
    colors: {
      lightGrey: string;
      darkGrey: string;
      red: string;
      darkRed: string;
      lightRed: string;
    };
    maxWidth: number;
    fontFamily: string;
    headerFontFamily: string;
    headerFontWeight: FontWeightProperty;
    importantBodyFontWeight: FontWeightProperty;
    topBar: {
      backgroundColor: string;
      height: number;
      iconHeight: number;
      contrastText: string;
    };
    dimensions: {
      drawerWidth: string;
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
    primaryInverse: true;
    warningLight: true;
    defaultTextColor: true;
  }
}
