import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import {
  asNonEmptyString,
  cloneDefaultState,
  isNonEmptyAndZeroToHundredNumber,
  isNonEmptyNumber,
  updateStateFieldByProp,
} from "../util/util_extensions";
import { ResellerProfileFormState } from "../model/resellerProfileFormState";

const defaultState = {
  error: null,
  profile_id: null,
  valid: false,
  profile: {
    name: null,
    vat_id: null,
    account_name: null,
    account_iban: null,
    address_line_1: null,
    address_line_2: null,
    should_calc_vat: true,
  },
  closePage: false,
};

export const resellerProfileFormState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_RESELLER_PROFILE_FORM]() {
    return cloneDefaultState(defaultState);
  },
  [ActionType.EDIT_RESELLER_PROFILE](state: ResellerProfileFormState, action: Action<any>) {
    if (action.payload.data === true) {
      return {
        profile_id: action.payload.profile_id,
        user_id: action.payload.user_id,
        profile: action.payload.profile,
        valid: checkIfValid(state),
      };
    }
    return { ...state };
  },
  [ActionType.CREATE_RESELLER_PROFILE]() {
    return { ...cloneDefaultState(defaultState) };
  },
  [ActionType.UPDATE_RESELLER_PROFILE_FORM_FIELD](state: ResellerProfileFormState, action: Action<any>) {
    let newState = updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);
    newState = { ...newState, valid: checkIfValid(newState) };
    return newState;
  },
  [ActionType.SAVE_RESELLER_PROFILE](state: ResellerProfileFormState) {
    return { ...state, closePage: true };
  },
  [ActionType.PAGE_SAVING_ERROR](state: ResellerProfileFormState, action: Action<any>) {
    return { ...state, error: action.payload.error };
  },
});

function checkIfValid(newState: ResellerProfileFormState): boolean {
  if (newState.hasManagePermission) {
    return !(
      !isNonEmptyAndZeroToHundredNumber(newState.profile.continuous_commission_percentage) ||
      !isNonEmptyNumber(newState.profile.continuous_commission_months)
    );
  } else {
    return !(
      !asNonEmptyString(newState.profile.name) ||
      !asNonEmptyString(newState.profile.account_name) ||
      !asNonEmptyString(newState.profile.account_iban) ||
      !asNonEmptyString(newState.profile.address_line_1) ||
      !asNonEmptyString(newState.profile.address_line_2)
    );
  }
}
