import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { User } from "../model/user";
import { mapErrorFromException } from "../model/error";

export const updateUser = (user: User) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    const res = await axios.put("/v2/api/private/users/me", user);
    dispatch({ type: ActionType.EDIT_USER, payload: { user: res.data } });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const changePassword = (oldPassword: string, newPassword: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    await axios.put("/v2/api/private/users/me/password", {
      old_password: oldPassword,
      new_password: newPassword,
    });
    dispatch({ type: ActionType.CHANGE_PASSWORD, payload: {} });
    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};
