import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const RecurringIcon = (props) => (
  <SvgIcon width="24.676" height="12.903" viewBox="0 0 24.676 12.903" {...props}>
    <path
      id="Pfad_30"
      data-name="Pfad 30"
      d="M24.36,9.22l-3.369,3.343a1.083,1.083,0,0,1-1.521,0L16.094,9.22a1.065,1.065,0,0,1-.017-1.511,1.04,1.04,0,0,1,1.488,0l1.478,1.526V2.174H9.631a1.073,1.073,0,1,1,0-2.146h10.6a.95.95,0,0,1,.954,1.039V9.233l1.6-1.526a1.151,1.151,0,0,1,1.577,0,1.065,1.065,0,0,1,0,1.511m-9.315,1.537H5.632V3.642L7.11,5.168a1,1,0,0,0,.728.312,1.037,1.037,0,0,0,.745-.314,1.065,1.065,0,0,0-.01-1.511L5.2.312a1.079,1.079,0,0,0-1.517,0L.315,3.655a1.065,1.065,0,0,0,0,1.511,1.15,1.15,0,0,0,1.577,0l1.6-1.526v8.165a1,1,0,0,0,.954,1.1h10.6a1.073,1.073,0,1,0,0-2.146"
      transform="translate(0 0)"
      fill="#707cda"
    />
  </SvgIcon>
);

export default RecurringIcon;
