import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabPricingIcon = (props) => (
  <SvgIcon width="22" height="20" viewBox="0 0 22 20" {...props}>
    <path
      id="Pfad_159"
      data-name="Pfad 159"
      d="M19.332,3.924h-.449V2.45A2.4,2.4,0,0,0,16.493,0H2.932A2.936,2.936,0,0,0,0,2.934V17.282a2.34,2.34,0,0,0,2.337,2.337H20.082a1.992,1.992,0,0,0,1.99-1.99V6.785a2.849,2.849,0,0,0-2.739-2.861M1.824,2.943a.981.981,0,0,1,.981-.981H16.491a.43.43,0,0,1,.43.43V3.924H2.805a.981.981,0,0,1-.981-.981m15.664,10.1a1.717,1.717,0,1,1,.5-1.214,1.727,1.727,0,0,1-.5,1.214"
    />
  </SvgIcon>
);

export default TabPricingIcon;
