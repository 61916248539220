import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import {
  asNonEmptyString,
  cloneDefaultState,
  isNonEmptyAndZeroNumber,
  isNonEmptyNumber,
  updateStateFieldByProp,
} from "../util/util_extensions";
import { PaymentPlanFormState } from "../model/paymentPlanFormState";

const defaultState = {
  error: null,
  product_id: null,
  valid: false,
  product: {
    product_id: null,
    name: "",
    type: "subscription",
    currency: "EUR",
    billing_type: "",
    granted_credits: "",
    price_in_cents: 0,
    product_sku_group: "",
    product_sku: "",
    premium_upgrade_slots: 0,
    premium_plus_upgrade_slots: 0,
    fai_credits: 0,
    social_credits: 0,
    hero_credits: 0,
    display_order: 0,
    reduced_price_in_cents: 0,
    number_of_reduced_price_periods: 0,
    trial_interval: 0,
    trial_unit: null,
    single_use_only: true,
    is_used: false,
    company_id: null,
  },
  skuGroups: [],
  companies: [],
  company_id: null,
  closePage: false,
};

export const paymentPlanFormState = createReducer(cloneDefaultState(defaultState), {
  [ActionType.RESET_PAYMENTPLAN_FORM]() {
    return cloneDefaultState(defaultState);
  },
  [ActionType.EDIT_PAYMENTPLAN](state: PaymentPlanFormState, action: Action<any>) {
    if (action.payload.data === true) {
      return {
        product_id: action.payload.product_id,
        product: action.payload.product,
        skuGroups: action.payload.skuGroups,
        companies: action.payload.companies,
        valid: true,
      };
    }
    return { ...state };
  },
  [ActionType.CREATE_PAYMENTPLAN](state: PaymentPlanFormState, action: Action<any>) {
    return {
      ...cloneDefaultState(defaultState),
      companies: action.payload.companies,
      skuGroups: action.payload.skuGroups,
    };
  },
  [ActionType.UPDATE_PAYMENTPLAN_FORM_FIELD](state: PaymentPlanFormState, action: Action<any>) {
    let newState = updateStateFieldByProp({ ...state, error: null }, action.payload.prop, action.payload.value);

    if (newState.product.billing_type === "PPH") {
      newState.product.billing_interval_unit = "hour";
    } else if (newState.product.billing_type === "PPD") {
      newState.product.billing_interval_unit = "day";
    } else if (newState.product.billing_type === "PPM") {
      newState.product.billing_interval_unit = "month";
    } else if (newState.product.billing_type === "PPY") {
      newState.product.billing_interval_unit = "year";
    }

    if (
      !asNonEmptyString(newState.product.name) ||
      (!asNonEmptyString(newState.product.country) && !asNonEmptyString(newState.product.company_id)) ||
      !isNonEmptyNumber(newState.product.price_in_cents) ||
      !asNonEmptyString(newState.product.currency) ||
      !isNonEmptyNumber(newState.product.tax_rate_percentage) ||
      !asNonEmptyString(newState.product.type) ||
      !asNonEmptyString(newState.product.product_sku_group) ||
      !asNonEmptyString(newState.product.product_sku)
    ) {
      newState = { ...newState, valid: false };
    } else {
      if (
        newState.product.type === "subscription" &&
        (!isNonEmptyAndZeroNumber(newState.product.billing_interval_value) ||
          !asNonEmptyString(newState.product.billing_type))
      ) {
        newState = { ...newState, valid: false };
      } else {
        newState = { ...newState, valid: true };
      }
    }

    return newState;
  },
  [ActionType.DELETE_PAYMENTPLAN](state: PaymentPlanFormState) {
    return { ...state, closePage: true };
  },
  [ActionType.SAVE_PAYMENTPLAN](state: PaymentPlanFormState) {
    return { ...state, closePage: true };
  },
  [ActionType.PAGE_SAVING_ERROR](state: PaymentPlanFormState, action: Action<any>) {
    return { ...state, error: action.payload.error };
  },
});
