import { Box, Stack, Typography } from "@mui/material";
import BookingDrawerInfoSectionFeature from "./BookingDrawerInfoSectionFeature";
import intl from "react-intl-universal";
import Link from "@mui/material/Link";

interface Props {
  icon: any;
  children?: any;
  title: string;
  text1: string;
  text2?: string;
  features: string[];
}

export default function BookingDrawerInfoSection({ children, icon, title, text1, text2, features }: Props) {
  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {icon}
        <Typography
          color={"primary"}
          sx={{
            font: "normal normal 800 30px/35px Manrope",
            marginTop: "12px",
            paddingLeft: 3,
            paddingRight: 3,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography sx={{ font: "normal normal normal 18px/21px Manrope", marginTop: "44px" }}>{text1}</Typography>
      <Typography color={"primary"} sx={{ font: "normal normal bold 18px/21px Manrope", marginTop: "19px" }}>
        {text2 || intl.get("booking_drawer_info.general.text2")}
      </Typography>
      <Stack gap={"7px"} sx={{ marginTop: "22px" }}>
        {features.map((feature, index) => (
          <BookingDrawerInfoSectionFeature title={feature} key={"feature_" + index} />
        ))}
        <Link
          sx={{ font: "normal normal normal 14px/19px Manrope", marginLeft: "20px" }}
          href={"https://blue-cherries.com/pakete-preise"}
          target={"_blank"}
        >
          {intl.get("booking_drawer_info.general.more_infos")}
        </Link>
      </Stack>
      {children}
    </Box>
  );
}
