import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Stack } from "@mui/material";
import { BCEvent } from "../../model/event";
import EventCard, { EventCardActionEvent } from "../events/EventCard";

interface Props {
  sx?: any;
  onActionClicked: EventCardActionEvent;
}
export default function CompanyEventsList({ sx, onActionClicked }: Props) {
  const events = useSelector<RootState, BCEvent[]>((state) => state.eventState.events);

  return (
    <Stack sx={sx} gap={2}>
      {events.map((event) => (
        <EventCard
          sx={{ maxHeight: "122px" }}
          variant={"list"}
          event={event}
          key={event.event_id}
          onActionClicked={onActionClicked}
        />
      ))}
    </Stack>
  );
}
