import BCSideDrawer from "../components/BCSideDrawer";
import { DialogHandler } from "../../dialog/useDialogHandler";
import BookingDrawerLayout from "../BookingDrawerLayout";
import BulkEventUpgradeDrawerPage from "./BulkEventUpgradeDrawerPage";
import { BCEventUpgradeType } from "../../../model/event";
import intl from "react-intl-universal";
import PremiumDrawerInfoSection from "../premium/PremiumDrawerInfoSection";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import ProductInfoSection from "../ProductInfoSection";
import { CompanyPaymentProduct } from "../../../model/payment/company.payment.product";

export interface BulkEventUpgradeDrawerPayload {
  upgradeType: BCEventUpgradeType;
  numberOfSlots: number;
}

interface Props {
  handler: DialogHandler<BulkEventUpgradeDrawerPayload>;
}

export default function BulkEventUpgradeDrawer({ handler }: Props) {
  const productName = handler.payload?.upgradeType === "premium_plus" ? "Premium Plus" : "Premium";

  const activeProducts = useSelector<RootState, CompanyPaymentProduct[]>((rootState) => {
    return rootState.paymentState.activeProducts;
  });

  let product: CompanyPaymentProduct = undefined;

  switch (handler.payload?.upgradeType) {
    case "premium_plus":
      product = activeProducts.find((item) => item.product_sku_group.indexOf("premium_plus") >= 0);
      break;
    case "premium":
      product = activeProducts.find((item) => item.product_sku_group.indexOf("premium_plus") >= 0);
      break;
    default:
      break;
  }

  return (
    <BCSideDrawer handler={handler}>
      <BookingDrawerLayout
        infoSection={product ? <ProductInfoSection product={product as any} /> : <PremiumDrawerInfoSection />}
      >
        {handler.payload && (
          <BulkEventUpgradeDrawerPage
            onSkip={handler.close}
            onSuccess={handler.close}
            numberOfSlots={handler.payload.numberOfSlots}
            upgradeType={handler.payload.upgradeType}
            title={intl.get("booking_drawer.bulk_event_upgrade.title", { productName })}
            message={intl.getHTML("booking_drawer.bulk_event_upgrade.message")}
          />
        )}
      </BookingDrawerLayout>
    </BCSideDrawer>
  );
}
