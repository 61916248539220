import CalendarWeekSelection from "../../calendarWeekSelection/CalendarWeekSelection";
import SlotSelectionSummaryBox from "../../calendarWeekSelection/SlotSelectionSummaryBox";
import BookingDrawerSumRow from "../components/BookingDrawerSumRow";
import intl from "react-intl-universal";
import HeroSlotsDrawerBackendResponse from "./HeroSlotsDrawerBackendResponse";
import { CalendarWeekSelectionHandler } from "../../calendarWeekSelection/useCalendarWeekSelection";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as HeroActions from "../../../actions/event.hero.actions";
import BookingButtonAndHint from "../../BookingButtonAndHint";
import * as React from "react";
import PurchaseConfirmationDrawerPage from "../PurchaseConfirmationDrawerPage";
import { Typography } from "@mui/material";
import { useDialogContext } from "../../dialog/DialogContext";

interface Props {
  data: HeroSlotsDrawerBackendResponse;
  handler: CalendarWeekSelectionHandler;
  eventId: string;
  companyId: string;
  onSuccess: () => void;
}

export default function DrawerContentHeroBooking({
  data,
  handler: calendarHandler,
  eventId,
  companyId,
  onSuccess,
}: Props) {
  const { handler } = useDialogContext();

  const availableSlots = data.available_slots || 0;

  const slotsToPurchase = Math.max(0, calendarHandler.newBookings.length - availableSlots);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [step, setStep] = useState<"select" | "confirm">("select");

  const bookSlots = async () => {
    setLoading(true);
    handler.setCanClose(false);

    const success = await dispatch<any>(
      HeroActions.bookSlots(
        companyId,
        eventId,
        calendarHandler.newBookings.map((booking) => booking.slotId),
      ),
    );

    if (success) {
      onSuccess();
    }

    setLoading(false);
    handler.setCanClose(true);
  };

  const handleBookingButtonClicked = async () => {
    if (slotsToPurchase > 0) {
      setStep("confirm");
    } else {
      await bookSlots();
    }
  };

  if (step === "confirm") {
    return (
      <PurchaseConfirmationDrawerPage
        productSku={data.product.product_sku}
        onConfirm={bookSlots}
        onCancel={() => setStep("select")}
        amount={slotsToPurchase}
      />
    );
  }

  return (
    <>
      <Typography sx={{ marginTop: "41px", font: "normal normal bold 18px/21px Manrope" }}>
        {intl.get("booking_drawer.hero_slots.label_select_weeks")}
      </Typography>

      <CalendarWeekSelection
        handler={calendarHandler}
        sx={{ marginTop: "22px", whiteSpace: "nowrap" }}
        product={data.product}
      />

      {availableSlots > 0 && (
        <SlotSelectionSummaryBox
          sx={{ marginTop: "22px" }}
          availableSlots={availableSlots}
          selectedSlots={calendarHandler.newBookings.length}
        />
      )}

      {slotsToPurchase > 0 && (
        <BookingDrawerSumRow sx={{ marginTop: "22px" }} product={data.product} productCount={slotsToPurchase} />
      )}

      <BookingButtonAndHint
        onPerformBooking={handleBookingButtonClicked}
        doesNotRequirePurchase={slotsToPurchase === 0}
        variant={"contained"}
        loading={loading}
        fullWidth
        disabled={calendarHandler.newBookings.length === 0}
        sx={{ marginTop: "21px" }}
        buttonLabel={intl.get("booking_drawer.hero_slots.button_book_now")}
      />
    </>
  );
}
