import { Stack, SxProps } from "@mui/material";
import intl from "react-intl-universal";
import InfoBox, { InfoBoxLabelValueRow } from "../InfoBox";

export default function SlotSelectionSummaryBox({
  availableSlots,
  selectedSlots,
  sx,
}: {
  availableSlots: number;
  selectedSlots: number;
  sx?: SxProps;
}) {
  return (
    <InfoBox sx={sx}>
      <Stack gap={"7px"}>
        <InfoBoxLabelValueRow
          color={"primary"}
          label={intl.get("booking_drawer.general.label_selected_slots")}
          value={selectedSlots}
        />

        <InfoBoxLabelValueRow
          color={"primary"}
          label={intl.get("booking_drawer.general.label_slots_included")}
          value={availableSlots}
        />

        {selectedSlots > availableSlots && (
          <InfoBoxLabelValueRow
            label={intl.get("booking_drawer.general.label_slots_not_included")}
            value={selectedSlots - availableSlots}
          />
        )}
      </Stack>
    </InfoBox>
  );
}
