import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { CompanyState } from "../../../../../model/companyState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { AuthenticationState } from "../../../../../model/authenticationState";
import * as React from "react";
import { isNonEmptyNumber } from "../../../../../util/util_extensions";
import { EventEditNumberInputField } from "../fields/EventEditNumberInputField";
import { EventEditMultiSelectField } from "../fields/EventEditMultiSelectField";
import { Constants } from "../event_options";
import { EventEditCheckboxField } from "../fields/EventEditCheckboxField";
import { EventEditSingleSelectField } from "../fields/EventEditSingleSelectField";
import { FormGridItem } from "../FormGridItem";
import { EventEditTextInputField } from "../fields/EventEditTextInputField";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventGeneralInfoOverviewEdit extends React.Component<Props> {
  isAgeValid() {
    if (this.props.eventFormState.event.age_from === null && this.props.eventFormState.event.age_to === null) {
      return true;
    } else if (
      !isNonEmptyNumber(this.props.eventFormState.event.age_from) ||
      !isNonEmptyNumber(this.props.eventFormState.event.age_to)
    ) {
      return false;
    } else {
      return this.props.eventFormState.event.age_from <= this.props.eventFormState.event.age_to;
    }
  }

  renderAgeFrom() {
    return (
      <EventEditNumberInputField
        required={true}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"age_from"}
        error={!this.isAgeValid()}
      />
    );
  }

  renderAgeTo() {
    return (
      <EventEditNumberInputField
        required={true}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"age_to"}
        error={!this.isAgeValid()}
      />
    );
  }

  renderWeatherConditions() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.weatherConditionOptions()}
        field={"weather_conditions"}
      />
    );
  }

  renderIsWinterDestination() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_winter_destination"}
      />
    );
  }

  renderIsHostedWithBadWeather() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_hosted_with_bad_weather"}
      />
    );
  }

  renderIsChildcareAvailable() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_childcare_available"}
        withLabel={true}
      />
    );
  }

  renderIsAccessible() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_accessible"}
      />
    );
  }

  renderStrollerConditionOptions() {
    return (
      <EventEditSingleSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.strollerConditionOptions()}
        field={"stroller_condition"}
      />
    );
  }

  renderDogsAllowed() {
    return (
      <EventEditSingleSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.dogsAllowedOptions()}
        field={"is_dogs_allowed"}
      />
    );
  }

  renderIsRegistrationNecessary() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_registration_necessary"}
      />
    );
  }

  renderHasSchoolOffers() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"has_school_offers"}
      />
    );
  }

  renderNumberOfAnimalSpecies() {
    return (
      <EventEditNumberInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"number_of_animal_species"}
      />
    );
  }

  renderActivityDuration() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.activityDurationOptions()}
        field={"activity_duration"}
      />
    );
  }

  renderKidsBirthdayParties() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"is_available_for_kids_birthday_parties"}
        withLabel={true}
      />
    );
  }

  renderEmbeddedVideoLink() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"video_link"}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={3}>{this.renderAgeFrom()}</FormGridItem>
          <FormGridItem md={3}>{this.renderAgeTo()}</FormGridItem>
          <FormGridItem md={6}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={6}>{this.renderWeatherConditions()}</FormGridItem>
          <FormGridItem md={6}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem>{this.renderIsWinterDestination()}</FormGridItem>
          <FormGridItem noTopPadding={true}>{this.renderIsHostedWithBadWeather()}</FormGridItem>

          <FormGridItem noTopPadding={true}>{this.renderHasSchoolOffers()}</FormGridItem>

          <FormGridItem md={6}>{this.renderStrollerConditionOptions()}</FormGridItem>
          <FormGridItem md={6}>{this.renderDogsAllowed()}</FormGridItem>
          <FormGridItem md={3}>{this.renderIsAccessible()}</FormGridItem>
          <FormGridItem md={6}>{this.renderIsRegistrationNecessary()}</FormGridItem>
          <FormGridItem md={3}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={6}>{this.renderNumberOfAnimalSpecies()}</FormGridItem>
          <FormGridItem md={6}>{this.renderLayoutPlaceholder()}</FormGridItem>
          <FormGridItem md={6}>{this.renderActivityDuration()}</FormGridItem>
          <FormGridItem md={3}>{this.renderIsChildcareAvailable()}</FormGridItem>
          <FormGridItem md={3}>{this.renderKidsBirthdayParties()}</FormGridItem>
          <FormGridItem md={12}>{this.renderEmbeddedVideoLink()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventGeneralInfoOverviewEdit;
