import * as intl from "react-intl-universal";
import { Company } from "./company";
import { User } from "./user";

export interface ResellerSalespartnerAccountingEntry {
  entry_id: string;
  reseller_id: string;
  company_id: string;
  company: Company;
  type: string;
  created_at: Date;
  amount_in_cents: number;
  commission_percentage: number;
  discount_value: number;
  discount_percentage: number;
  invoice_number?: string;
  invoice_paid?: boolean;
}

export interface ResellerCreditNote {
  note_id: string;
  reseller_id: string;
  note_number: string;
  subject: string;
  billing_period_start: Date;
  billing_period_end: Date;
  billing_timestamp: Date;
  amount_in_cents: number;
  tax_rate_percentage: number;
  currency: string;
  pdf_data?: any;
  normalized_invoice_date: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface ResellerSalespartnerEarningsReport {
  accounting_entries: ResellerSalespartnerAccountingEntry[];
  summary: ResellerSalespartnerEarningsSummary;
  reseller: User;
  creditNote?: ResellerCreditNote;
  unpaid_invoices: boolean;
}

export interface ResellerSalespartnerEarningsSummary {
  total_amount_in_cents: number;
  current_year_amount_in_cents: number;
  current_filter_amount_in_cents: number;
}

export const formattedEarningsAmount = (amount_in_cents: number, currency: string) => {
  let formattedCurrency = currency;
  if (intl.get(`currencies_short.${currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${currency}`);
  }

  return `${formattedCurrency} ${(amount_in_cents / 100).toLocaleString("de", { minimumFractionDigits: 2 })}`;
};
