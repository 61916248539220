import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import { EventFormState } from "../../model/eventFormState";
import { CompanyState } from "../../model/companyState";
import * as React from "react";
import { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { BackButton, DefaultButton } from "../../components/Buttons";
import PaymentIcon from "@mui/icons-material/Payment";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { SectionLabel } from "../../components/SectionLabel";
import { Company } from "../../model/company";
import * as EventActions from "../../actions/event";
import * as CompanyActions from "../../actions/company";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import MyReactSelect from "../../components/select/MyReactSelect";
import AppStyles from "../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventActions: typeof EventActions;
  companyActions: typeof CompanyActions;
}

export interface State {
  targetCompanyId: number | null;
  showTransferConfirmationDialog: boolean;
  showTransferSuccessDialog: boolean;
}

function EventTransferPage(props: Props) {
  const [targetCompanyId, setTargetCompanyId] = useState(null);
  const [showTransferConfirmationDialog, setShowTransferConfirmationDialog] = useState(false);
  const [showTransferSuccessDialog, setShowTransferSuccessDialog] = useState(false);

  const navigate = useNavigate();

  const closePage = () => {
    navigate(-1);
  };

  const transferEvent = () => {
    setShowTransferConfirmationDialog(true);
  };

  const confirmTransfer = () => {
    setShowTransferConfirmationDialog(false);
    props.eventActions.transferEvent(
      props.companyState.selected_company_id,
      props.companyState.transferEvent.event_id,
      targetCompanyId,
      () => {
        setShowTransferSuccessDialog(true);
      },
    );
  };

  const params = useParams();

  useEffect(() => {
    const companyId = params.company_id;
    const eventId = params.event_id;

    if (companyId && eventId) {
    } else {
      closePage();
    }

    props.eventActions.fetchTransferCompaniesForEvent(companyId, eventId);
  }, []);

  const renderHeader = () => {
    return (
      <Grid container direction="row">
        <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.actionHeaderLeft}>
            <BackButton
              title={"Event transferieren"}
              onClick={() => {
                closePage();
              }}
            />
          </Grid>

          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton
              onClick={() => {
                transferEvent();
              }}
              disabled={targetCompanyId === null}
            >
              <PaymentIcon sx={AppStyles.buttonLeftIcon} />
              {"Transferieren"}
            </DefaultButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderTransferForm = () => {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            {props.companyState.transferEvent && (
              <HeaderLabel>Transfer von {props.companyState.transferEvent.title}</HeaderLabel>
            )}
            {props.companyState.transferEvent === null && <HeaderLabel>Transfer von </HeaderLabel>}
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton sx={AppStyles.buttonPlaceholder} />
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm} spacing={5}>
            <Grid item xs={12} sm={6} container direction="column">
              <SectionLabel required={false}>{"Zielfirma"}</SectionLabel>
              <MyReactSelect
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionValue={(option) => option.company_id}
                options={props.companyState.transferCompanies}
                value={props.companyState.transferCompanies.find((value) => {
                  return targetCompanyId === value.company_id;
                })}
                onChange={(value) => {
                  setTargetCompanyId(value ? (value as Company).company_id : null);
                }}
                placeholder={"Zielfirma auswählen"}
                isClearable
                isSearchable={true}
              />
            </Grid>
          </Grid>
        </Paper>

        {showTransferConfirmationDialog && (
          <Dialog
            open={showTransferConfirmationDialog}
            onClose={() => {
              setShowTransferConfirmationDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Freizeitangebot transferieren</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Wollen Sie das Freizeitangebot <b>{props.companyState.transferEvent.title}</b> wirklich zu Firma{" "}
                <b>
                  {
                    props.companyState.transferCompanies.find((value) => {
                      return targetCompanyId === value.company_id;
                    }).name
                  }
                </b>{" "}
                transferieren?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowTransferConfirmationDialog(false);
                }}
                color="primary"
              >
                Abbrechen
              </Button>
              <Button onClick={confirmTransfer} color="primary" autoFocus>
                Ja, Freizeitangebot transferieren
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {showTransferSuccessDialog && (
          <Dialog
            open={showTransferSuccessDialog}
            onClose={() => {
              setShowTransferSuccessDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Freizeitangebot transferiert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Das Freizeitangebot wurde erfolgreich transferiert!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closePage()} color="primary" autoFocus>
                OK
              </Button>
              <Button
                onClick={() => {
                  navigate("/", { replace: true });
                  setTimeout(() => {
                    navigate(`/companies/${targetCompanyId}/company_events`);
                  }, 1);
                }}
                color="primary"
                autoFocus
              >
                Zu Firma{" "}
                {
                  props.companyState.transferCompanies.find((value) => {
                    return targetCompanyId === value.company_id;
                  }).name
                }{" "}
                wechseln
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  };

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        {renderHeader()}

        {renderTransferForm()}
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    eventFormState: state.eventFormState,
    companyState: state.companyState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    eventActions: bindActionCreators(EventActions as any, dispatch),
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventTransferPage);
