import { useLocation } from "react-router";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../reducers";
import { AuthenticationState } from "../model/authenticationState";
import * as CompanyActions from "../actions/company";
import { Navigate } from "react-router-dom";

export interface Props {
  authenticationState: AuthenticationState;
  actions: typeof CompanyActions;
}

function LandingPage(props: Props) {
  const location = useLocation();

  if (props.authenticationState.authenticated && props.authenticationState.user !== null) {
    if (
      props.authenticationState.user.companies &&
      props.authenticationState.user.companies.length === 1 &&
      props.authenticationState.user.role == null
    ) {
      // user has only one company and she has no global role -> let's select this company
      return (
        <Navigate
          to={{
            pathname: `/companies/${props.authenticationState.user.companies[0].company_id}/company_events`,
            search: location.search,
          }}
        />
      );
    }
    if (location.state) {
      return <Navigate to={location.state} />;
    }
    return <Navigate to={{ pathname: "/companies", search: location.search }} />;
  }
  return <Navigate to={{ pathname: "/login", search: location.search }} />;
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
