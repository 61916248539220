import * as React from "react";
import { AuthenticationState } from "../../model/authenticationState";
import { RootState } from "../../reducers";
import { connect } from "react-redux";
import { AuthUtils } from "./AuthUtils";
import NavigateWithSearchAndState from "./NavigateWithSearchAndState";

interface Props {
  authenticationState: AuthenticationState;
  anyPermission: string[];
  children?: any;
}

function PrivatePermissionGuard({ children, authenticationState, anyPermission }: Props) {
  if (AuthUtils.setupNeeded(authenticationState)) {
    return <NavigateWithSearchAndState to={"/setupProfile"} />;
  } else if (
    AuthUtils.isAuthenticated(authenticationState) &&
    AuthUtils.hasGlobalPermission(authenticationState, anyPermission)
  ) {
    return children;
  } else {
    return <NavigateWithSearchAndState to={"/login"} />;
  }
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivatePermissionGuard);
