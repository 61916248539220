import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import HandlerDialog from "../../../components/dialog/handlerDialog";

interface Props {
  handler: DialogHandler<any>;
  onAgree: () => void;
}

export default function SepaMandateDialog({ handler, onAgree }: Props) {
  return (
    <HandlerDialog handler={handler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogTitle id="alert-dialog-title">{intl.get("company_plan_info.sepa_mandate.title")}</DialogTitle>
        <DialogContentText id="alert-dialog-description">
          {intl.getHTML("company_plan_info.sepa_mandate.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handler.close} color="primary">
          {intl.get("company_plan_info.sepa_mandate.disagree")}
        </Button>
        <Button onClick={onAgree} color="primary" autoFocus>
          {intl.get("company_plan_info.sepa_mandate.agree")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
