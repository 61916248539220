import * as intl from "react-intl-universal";
import createReducer from "./createReducer";
import { cloneDefaultState } from "../util/util_extensions";
import { Action, ActionType } from "../model/actions";
import { ErrorDialogState } from "../model/errorDialogState";

export const errorDialogState = createReducer(cloneDefaultState({ error: null }), {
  [ActionType.CONFIRM_ERROR_DIALOG]() {
    return { error: null };
  },

  [ActionType.UPDATE_EVENT_STATUS](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { error: action.payload.error };
    }
    return state;
  },
  [ActionType.FETCH_USER](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.has_rejected_events) {
      return { error: intl.get("event_rejected_hint_dialog.message") };
    }
    return state;
  },
  [ActionType.DELETE_COMPANY_MEDIA_ITEM](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { error: action.payload.error };
    }
    return state;
  },
  [ActionType.UPLOAD_COMPANY_MEDIA_ITEM](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { error: action.payload.error };
    }
    return state;
  },
  [ActionType.UPDATE_COMPANY_MEDIA_ITEM](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { error: action.payload.error };
    }
    return state;
  },
});
