export interface ReportingEventInfo {
  event_id: string;
  title: string;
  total_views: number;
  potential_views: number;
  hero: boolean;
  premium: boolean;
  premium_plus: boolean;
  benefits_from_upgrade: boolean;
}

export interface CompanyReportingResult {
  events: ReportingEventInfo[];
  granularity: EventReportingEntryGranularity;
  total_views: number;
  potential_views: number;
  entries: EventReportingEntry[];
}

export interface EventReportingEntry {
  date_value: string;
  event_entries: Map<string, number>;
}

export enum EventReportingEntryGranularity {
  Day = "day",
  Month = "month",
}

export enum EventReportingTimeframe {
  LastMonth = "30d",
  LastYear = "365d",
  Full = "full",
}
