import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import { useDispatch, useSelector } from "react-redux";
import { PaymentInfo, PaymentInfoStatus } from "../../../model/payment_info";
import intl from "react-intl-universal";
import usePolling from "../../../hooks/usePolling";
import useDialogReset from "../../../components/dialog/useDialogReset";
import * as CompanyActions from "../../../actions/company";
import useSelectedCompanyId from "../../../hooks/useSelectedCompanyId";
import { RootState } from "../../../reducers";

export interface PaymentMethod2FADialogPayload {
  authorizationUrl: string;
}

interface Props {
  handler: DialogHandler<PaymentMethod2FADialogPayload>;
  onSuccess: (PaymentInfo) => void;
  onCancel: () => void;
}

export default function PaymentMethod2FAPollingDialog({ handler, onSuccess, onCancel }: Props) {
  const dispatch = useDispatch();
  const companyId = useSelectedCompanyId();

  const [polling, setPolling] = useState(false);
  const paymentInfo = useSelector<RootState, PaymentInfo>((rootState) => rootState.paymentState.paymentInfo);

  const fetchPaymentInfo = (force = false) => {
    if (polling || force) {
      dispatch<any>(CompanyActions.loadPaymentInformation(companyId));
    }
  };

  const openAuthorizationUrl = () => {
    window.open(handler.payload.authorizationUrl, "_blank");
  };

  let hasAutomaticallyOpenedAuthorizationUrl = false;

  useDialogReset(handler, () => {
    if (paymentInfo?.authorizationRedirectUrl) {
      if (hasAutomaticallyOpenedAuthorizationUrl === false) {
        hasAutomaticallyOpenedAuthorizationUrl = true;
        openAuthorizationUrl();
      }
    }
    setPolling(true);
    fetchPaymentInfo(true);
  });

  usePolling(fetchPaymentInfo, 2500, polling && handler.isOpen);

  useEffect(() => {
    if (paymentInfo !== null) {
      switch (paymentInfo?.status) {
        case PaymentInfoStatus.VALID:
          setPolling(false);
          handler.close();
          onSuccess(paymentInfo);
          break;
        case PaymentInfoStatus.PENDING_AUTHORIZATION:
          break;
        default:
          // do we need to treat INVALID differently?
          handler.close();
          setPolling(false);
          break;
      }
    }
  }, [paymentInfo?.status]);

  return (
    <HandlerDialog
      handler={handler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{intl.get("payment_method_2fa_dialog.title")}</DialogTitle>
      <DialogContent>
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Stack direction={"row"} sx={{ marginTop: 10 }} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress size={16} />
            <Typography variant={"body2"} sx={{ marginLeft: "11px" }} color={"primary"}>
              {intl.get("payment_method_2fa_dialog.message_loading")}
            </Typography>
          </Stack>
          <Stack
            sx={(theme) => ({
              background: theme.palette.primary.light,
              px: 2,
              borderRadius: "5px",
              pt: "9px",
              marginTop: 6,
              marginBottom: 4,
            })}
          >
            <Typography variant={"body2"} textAlign={"center"}>
              {intl.get("payment_method_2fa_dialog.hint")}
            </Typography>
            <Button
              onClick={openAuthorizationUrl}
              variant={"text"}
              sx={{ marginLeft: "auto", marginRight: "auto", marginTop: "0px", minHeight: 0 }}
            >
              {intl.get("payment_method_2fa_dialog.button_open_url")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handler.close();
            onCancel();
          }}
        >
          {intl.get("payment_method_2fa_dialog.button_cancel")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
