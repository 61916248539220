import { Alert, Box, Grid, Snackbar, Toolbar } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import SaveIcon from "@mui/icons-material/Check";
import { RootState } from "../reducers";
import * as ProfileActions from "../actions/setupProfile";
import { AuthenticationState } from "../model/authenticationState";
import { PageState } from "../model/pageState";
import CompanyEditForm from "./components/forms/CompanyEditForm";
import { CompanyFormState } from "../model/companyFormState";
import * as CompanyActions from "../actions/company";
import { DefaultButton } from "../components/Buttons";
import { PageTracker } from "../util/pageTracker";
import NavigateWithSearch from "../components/router/NavigateWithSearch";
import AppStyles from "../styles/appStyles";

export interface Props {
  authenticationState: AuthenticationState;
  profileActions: typeof ProfileActions;
  companyActions: typeof CompanyActions;
  companyFormState: CompanyFormState;
  pageState: PageState;
}

export interface State {}

class SetupProfilePage extends React.Component<Props> {
  state = {
    addCompanyInfo: false,
    showSaveSuccess: false,
  };

  componentDidMount(): void {
    this.props.companyActions.createNewCompany(false);
  }

  continue = () => {
    this.props.profileActions.updateProfile({ completed: true });
  };

  saveChanges(): void {
    this.props.companyActions.saveCompany(
      this.props.companyFormState.company_id,
      this.props.companyFormState.company,
      () => {
        this.setState({ showSaveSuccess: true });
      },
    );
  }

  renderCompanyForm = () => {
    if (this.props.companyFormState.closePage) {
      this.continue();
      return <div />;
    }
    const disableSave = !this.props.companyFormState.valid;
    return (
      <CompanyEditForm>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={this.state.showSaveSuccess}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({ showSaveSuccess: false });
          }}
        >
          <Alert severity="success">{intl.get("company.company_saved_confirmation")}</Alert>
        </Snackbar>

        <Toolbar sx={AppStyles.formActionBar}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item sx={AppStyles.headerRight}>
              <DefaultButton
                style={{ marginRight: 10 }}
                disabled={disableSave}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.saveChanges();
                }}
              >
                <SaveIcon />
                {intl.get("setupProfile.create_company_button")}
              </DefaultButton>

              <DefaultButton
                onClick={() => {
                  this.continue();
                }}
              >
                <SaveIcon />
                {intl.get("setupProfile.continue_button")}
              </DefaultButton>
            </Grid>
          </Grid>
        </Toolbar>
      </CompanyEditForm>
    );
  };

  render() {
    if (this.props.authenticationState.user.b2b_profile.completed) {
      return <NavigateWithSearch to={"/companies"} />;
    }

    return (
      <div
        style={{
          maxWidth: 800,
          paddingTop: 20,
          paddingRight: 10,
          paddingLeft: 10,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <PageTracker />
        <Box
          sx={(theme) => ({
            fontSize: theme.fontSizes.largeFont,
          })}
        >
          {intl.get("setupProfile.add_company_info_hint")}
        </Box>

        {this.renderCompanyForm()}
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
    companyFormState: state.companyFormState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    profileActions: bindActionCreators(ProfileActions as any, dispatch),
    companyActions: bindActionCreators(CompanyActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SetupProfilePage);
