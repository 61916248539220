import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { RootState } from "../../reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { CompanyState } from "../../model/companyState";
import { CompanySharedLocationsState } from "../../model/company.shared.locations.state";
import * as CompanySharedLocationsActions from "../../actions/shared.location.actions";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton } from "../../components/Buttons";
import AddIcon from "@mui/icons-material/Add";
import { CompanySharedLocation, fullAddressStringFrom } from "../../model/company.shared.location";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import AppStyles from "../../styles/appStyles";

export interface Props {
  companyState: CompanyState;
  companySharedLocationsState: CompanySharedLocationsState;
  actions: typeof CompanySharedLocationsActions;
}

export interface State {}

function CompanyLocationsPage(props: Props) {
  useEffect(() => {
    props.actions.fetchSharedLocations(props.companyState.selected_company_id);
  }, []);

  const navigate = useNavigate();

  function renderLocation(location: CompanySharedLocation) {
    let name = location.name;
    if (location.internal_name && location.internal_name.length > 0) {
      name = `${name} (${location.internal_name})`;
    }

    return (
      <TableRow
        key={location.location_id}
        hover
        onClick={() => {
          navigate(
            `/companies/${props.companyState.selected_company_id}/company_shared_locations/${location.location_id}/edit`,
          );
        }}
      >
        <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
          {name}
        </TableCell>

        <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
          {fullAddressStringFrom(location)}
        </TableCell>
      </TableRow>
    );
  }

  const mappedLocations = props.companySharedLocationsState.shared_locations.map((value) => renderLocation(value));

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>Standorte</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton
              onClick={() => {
                navigate(`/companies/${props.companyState.selected_company_id}/company_shared_locations/new`);
              }}
            >
              <AddIcon />
              Neuer Standort
            </DefaultButton>
          </Grid>
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  Name
                </TableCell>

                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  Adresse
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mappedLocations}</TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    companySharedLocationsState: state.companySharedLocationsState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanySharedLocationsActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyLocationsPage);
