import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import * as intl from "react-intl-universal";
import * as React from "react";

export interface Props {
  credits: string;
  onSave: any;
  onCancel: any;
}

export interface State {}

class MediaCreditsEditor extends React.Component<Props> {
  state = {
    credits: "",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    this.setState({ credits: nextProps.credits });
  }

  render() {
    return (
      <Dialog
        open={true}
        onClose={() => {
          this.props.onCancel();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{intl.get("media.credits_edit_dialog.title")}</DialogTitle>
        <DialogContent>
          <TextField
            value={this.state.credits}
            onChange={(event) => {
              this.setState({ credits: event.target.value });
            }}
            autoFocus
            margin="dense"
            id="info_source"
            label={intl.get("media.credits_edit_dialog.label")}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.onCancel();
            }}
            color="secondary"
          >
            {intl.get("media.credits_edit_dialog.button_cancel")}
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state.credits);
            }}
            color="primary"
          >
            {intl.get("media.credits_edit_dialog.button_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MediaCreditsEditor;
