import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import axios from "axios";
import { mapErrorFromException } from "../model/error";

export const resetActivityLogs = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_ACTIVITY_LOGS });
};

export const fetchActivityLogs = (company_id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`/v2/api/b2b/private/activity_logs/${company_id}`);

    dispatch({
      type: ActionType.LOAD_ACTIVITY_LOGS,
      payload: response.data.entries,
    });

    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const createActivityLogEntry =
  (company_id: string, text: string, type: string, completion: any) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(`/v2/api/b2b/private/activity_logs/${company_id}`, { text, type });
      dispatch({ type: ActionType.CREATE_ACTIVITY_LOG_ENTRY, payload: response.data });
      completion(true);
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_LOADING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
      completion(false);
    }
  };
