import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { ActivityLogsState } from "../model/activity.logs.state";

export const activityLogsState = createReducer(
  { logs: [] },
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [ActionType.RESET_ACTIVITY_LOGS](state: ActivityLogsState) {
      return { logs: [] };
    },
    [ActionType.LOAD_ACTIVITY_LOGS](state: ActivityLogsState, action: Action<any>) {
      if (action.payload) {
        return { ...state, logs: action.payload };
      }
      return { ...state };
    },
    [ActionType.CREATE_ACTIVITY_LOG_ENTRY](state: ActivityLogsState, action: Action<any>) {
      if (action.payload) {
        const logs = state.logs;
        logs.unshift(action.payload);
        return { ...state, logs: logs };
      }
      return { ...state };
    },
  },
);
