import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { ResellerSalespartnerEarningsState } from "../model/resellerSalespartnerEarningsState";
import { endOfMonth, startOfMonth } from "date-fns";

export const resellerSalespartnerEarningsState = createReducer(
  {
    selected_entry_id: null,
    singleEntries: null,
    earningsList: null,
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    paging: {
      page_size: 50,
      current_page: 1,
    },
  },
  {
    [ActionType.SELECT_EARNINGS_ENTRY](state: ResellerSalespartnerEarningsState, action: Action<any>) {
      return {
        ...state,
        selected_user_id: action.payload.selected_entry_id,
      };
    },
    [ActionType.FETCH_EARNINGS](state: ResellerSalespartnerEarningsState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          singleEntries: action.payload.accounting_entries,
          unpaid_invoices: action.payload.unpaid_invoices,
          globalResellerEarnings: action.payload.reseller_earnings,
          globalSalespartnerEarnings: action.payload.salespartner_earnings,
          globalCreditNotes: action.payload.credit_notes,
          singleCreditNotes: action.payload.single_credit_notes,
          paging: action.payload.paging,
          total_amount_in_cents: action.payload.calculated_earnings.total_amount_in_cents,
          current_year_amount_in_cents: action.payload.calculated_earnings.current_year_amount_in_cents,
          current_filter_amount_in_cents: action.payload.calculated_earnings.current_filter_amount_in_cents,
        };
      }
      return { ...state };
    },
  },
);
