import { useEffect, useRef } from "react";

export default function usePolling(pollFunction: () => void, intervalMs: number, enabled: boolean) {
  const timerIdRef = useRef(null);

  const startPolling = () => {
    if (timerIdRef.current == null) {
      timerIdRef.current = setInterval(pollFunction, intervalMs);
    }
  };

  const stopPolling = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = null;
  };

  useEffect(() => {
    if (enabled) {
      startPolling();
    } else {
      stopPolling();
    }
  }, [enabled]);
}
