import { Box, Checkbox, CheckboxProps, Grid, Paper, Theme, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { RootState } from "../reducers";
import { AuthenticationState } from "../model/authenticationState";
import * as AuthActions from "../actions/auth";
import { ErrorView } from "../components/ErrorView";
import { DefaultButton, LinkButton } from "../components/Buttons";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { getParamValue } from "../util/util_extensions";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import AppStyles from "../styles/appStyles";
import StringUtils from "../util/StringUtils";

const classes = {
  contactByFax: {
    height: 20,
    visibility: "hidden",
    autocomplete: "off",
  },
  subTitle: (theme: Theme) => ({
    fontWeight: "bold",
    fontSize: theme.fontSizes.largeFont,
    marginBottom: 10,
  }),
  section: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  link: (theme: Theme) => ({
    color: theme.palette.secondary.main,
  }),
};

const SignupCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      sx={(theme) => ({
        color: "#e3e3e3",
        "& .Mui-checked": {
          color: theme.palette.primary.main,
          fontFamily: theme.fontFamily,
        },
      })}
      color="primary"
      {...props}
    />
  );
};

export interface Props {
  authenticationState: AuthenticationState;
  authActions: typeof AuthActions;
}

export interface State {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  passwordRepeat: string;
  code: string | undefined;
  prefilledEmail: boolean;
  hasAcceptedAGBs: boolean;
  hasAcceptedPrivacyStatement: boolean;
  hasCheckedShouldContactByFax: boolean;
}

function SignupPage(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    passwordRepeat: "",
    code: undefined,
    prefilledEmail: false,
    hasAcceptedAGBs: false,
    hasAcceptedPrivacyStatement: false,
    agbDialogOpen: false,
    privacyStatementDialogOpen: false,
    hasCheckedShouldContactByFax: false,
  });

  useEffect(() => {
    const codeValue = getParamValue("code");
    const emailValue = getParamValue("email");
    if (codeValue && emailValue) {
      setState({ ...state, code: codeValue, email: emailValue, prefilledEmail: true });
    } else if (codeValue) {
      setState({ ...state, code: codeValue });
    } else if (emailValue) {
      setState({ ...state, email: emailValue, prefilledEmail: true });
    }

    return () => {
      props.authenticationState.waitingForActivation = false;
    };
  }, []);

  const handleChange = (name: any) => (event: any) => {
    setState({ ...state, [name]: event.target.value });
  };

  const signupPossible = () => {
    return (
      state.hasAcceptedAGBs &&
      state.hasAcceptedPrivacyStatement &&
      !StringUtils.isNullOrEmpty(state.first_name) &&
      !StringUtils.isNullOrEmpty(state.last_name) &&
      !StringUtils.isNullOrEmpty(state.email) &&
      !StringUtils.isNullOrEmpty(state.password) &&
      !StringUtils.isNullOrEmpty(state.passwordRepeat) &&
      state.passwordRepeat === state.password
    );
  };

  const passwordsInvalid = () => {
    return (
      !StringUtils.isNullOrEmpty(state.password) &&
      !StringUtils.isNullOrEmpty(state.passwordRepeat) &&
      state.passwordRepeat !== state.password
    );
  };

  const signup = () => {
    if (state.hasCheckedShouldContactByFax) {
      // probably a bot -> redirect to landing page
      setTimeout(function () {
        navigate("/landing");
      }, 1000);
    } else {
      props.authActions.signup(
        state.first_name,
        state.last_name,
        state.email,
        state.password,
        state.passwordRepeat,
        state.code,
      );
    }
  };

  function toggleAGBs() {
    setState({ ...state, hasAcceptedAGBs: !state.hasAcceptedAGBs });
  }

  function togglePrivacyStatement() {
    setState({ ...state, hasAcceptedPrivacyStatement: !state.hasAcceptedPrivacyStatement });
  }

  function toggleHasCheckedShouldContactByFax() {
    setState({ ...state, hasCheckedShouldContactByFax: !state.hasCheckedShouldContactByFax });
  }

  function renderWaitingForActivation() {
    return (
      <Box component={"main"} sx={AppStyles.centerCardLayout}>
        <PageTracker />
        <Paper sx={AppStyles.centerPaper}>
          <Grid style={{ width: "100%" }}>
            <HeaderLabel>{intl.get("signup.title")}</HeaderLabel>
            <Typography sx={classes.subTitle}>{intl.get("signup.waiting_for_activation")}</Typography>
          </Grid>

          <Grid container sx={AppStyles.section} direction="row" justifyContent="flex-end" alignItems={"flex-end"}>
            <DefaultButton
              style={{ paddingLeft: 50, paddingRight: 50 }}
              sx={{ flexGrow: 1 }}
              onClick={() => {
                navigate("/login");
              }}
              title={intl.get("signup.jump_to_login")}
            />
          </Grid>
        </Paper>
      </Box>
    );
  }

  function renderFinishedActivation() {
    return (
      <Box component={"main"} sx={AppStyles.centerCardLayout}>
        <PageTracker />
        <Paper sx={AppStyles.centerPaper}>
          <Grid style={{ width: "100%" }}>
            <HeaderLabel>{intl.get("signup.title")}</HeaderLabel>
            <Typography sx={classes.subTitle}>{intl.get("signup.finished_activation")}</Typography>
          </Grid>

          <Grid container sx={AppStyles.section} direction="row" justifyContent="flex-end" alignItems={"flex-end"}>
            <DefaultButton
              style={{ paddingLeft: 50, paddingRight: 50 }}
              sx={{ flexGrow: 1 }}
              onClick={() => {
                navigate("/login");
              }}
              title={intl.get("signup.jump_to_login")}
            />
          </Grid>
        </Paper>
      </Box>
    );
  }

  const { error } = props.authenticationState;

  if (props.authenticationState.waitingForActivation) {
    return <div>{renderWaitingForActivation()}</div>;
  }
  if (props.authenticationState.finishedActivation) {
    return <div>{renderFinishedActivation()}</div>;
  }
  return (
    <Box>
      <PageTracker />
      <Box component={"main"} sx={AppStyles.centerCardLayout}>
        <Paper sx={AppStyles.centerPaper}>
          <Grid style={{ width: "100%" }}>
            <HeaderLabel>{intl.get("signup.title")}</HeaderLabel>
          </Grid>
          <Box sx={AppStyles.appForm}>
            <ErrorView error={error} defaultErrorMessage={intl.get("login.error")} />
            <Grid container alignItems="stretch" justifyContent="center" direction="column">
              <Grid item sx={AppStyles.section} container direction="row">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Grid item container sx={AppStyles.section} direction="column">
                      <TextFieldWithLabel
                        label={intl.get("label.first_name")}
                        placeholder={"Vorname"}
                        id="first_name"
                        onChange={handleChange("first_name")}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid item container sx={AppStyles.section} direction="column">
                      <TextFieldWithLabel
                        placeholder={"Nachname"}
                        label={intl.get("label.last_name")}
                        id="last_name"
                        onChange={handleChange("last_name")}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={AppStyles.section} direction="column">
              <TextFieldWithLabel
                placeholder={"E-Mail"}
                label={intl.get("label.email")}
                id="email"
                value={state.email}
                onChange={handleChange("email")}
                margin="normal"
                disabled={state.prefilledEmail}
              />
            </Grid>
            <Grid item sx={AppStyles.section} container direction="row">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Grid item container sx={AppStyles.section} direction="column">
                    <TextFieldWithLabel
                      label={intl.get("label.password")}
                      placeholder={intl.get("label.password")}
                      id="password-input"
                      type="password"
                      autoComplete="current-password"
                      onChange={handleChange("password")}
                      margin="normal"
                      error={passwordsInvalid()}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item container sx={AppStyles.section} direction="column">
                    <TextFieldWithLabel
                      label={intl.get("label.password_repeat")}
                      placeholder={intl.get("label.password_repeat")}
                      id="password-repeat-input"
                      type="password"
                      autoComplete="current-password"
                      onChange={handleChange("passwordRepeat")}
                      margin="normal"
                      error={passwordsInvalid()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container alignItems="center">
              <SignupCheckbox checked={state.hasAcceptedAGBs} onChange={() => toggleAGBs()} />
              <Box component={"a"} href="https://family-cherries.com/impressum" target="_blank" sx={classes.link}>
                {intl.get("signup.accept_agb")}
              </Box>
            </Grid>
            <Grid item container alignItems="center">
              <SignupCheckbox checked={state.hasAcceptedPrivacyStatement} onChange={() => togglePrivacyStatement()} />
              <Box component={"a"} href="https://family-cherries.com/datenschutz" target="_blank" sx={classes.link}>
                {intl.get("signup.accept_privacy_statement")}
              </Box>
            </Grid>
            {/* Honeypot: add a hidden field to lure bots into filling this field out so we can detect bots --> */}
            <Grid item container alignItems="center">
              <Box sx={classes.contactByFax}>
                <SignupCheckbox onChange={() => toggleHasCheckedShouldContactByFax()} />
                <LinkButton
                  onClick={() => {
                    setState({ ...state, hasCheckedShouldContactByFax: true });
                  }}
                  title="Contact me by fax"
                />
              </Box>
            </Grid>
            <Grid
              container
              sx={AppStyles.section}
              direction="row"
              justifyContent="space-between"
              alignItems={"flex-end"}
            >
              <LinkButton title={intl.get("signup.jump_to_login")} to="/login" search={location.search} />

              <DefaultButton
                style={{ paddingLeft: 50, paddingRight: 50 }}
                onClick={signup}
                disabled={!signupPossible()}
                title={intl.get("signup.button")}
              />
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

function authMapStateToProps(state: RootState) {
  return {
    authenticationState: state.authenticationState,
  };
}

function authMapDispatchToProps(dispatch: any) {
  return {
    authActions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default connect(authMapStateToProps, authMapDispatchToProps)(SignupPage);
