import * as React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../../../model/eventFormState";
import { CompanyState } from "../../../../model/companyState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import EventGeneralInfoEdit from "./general/EventGeneralInfoEdit";
import EventGeneralInfoCategoryEdit from "./general/EventGeneralInfoCategoryEdit";
import EventGeneralInfoOverviewEdit from "./general/EventGeneralInfoOverviewEdit";
import EventGeneralInfoLocationEdit from "./general/EventGeneralInfoLocationEdit";
import EventGeneralInfoInfrastructureEdit from "./general/EventGeneralInfoInfrastructureEdit";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventGeneralInfoTab extends React.Component<Props> {
  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <Stack>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.general_general`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventGeneralInfoEdit
              eventFormState={this.props.eventFormState}
              companyState={this.props.companyState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.general_category`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventGeneralInfoCategoryEdit
              eventFormState={this.props.eventFormState}
              companyState={this.props.companyState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.general_overview`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventGeneralInfoOverviewEdit
              eventFormState={this.props.eventFormState}
              companyState={this.props.companyState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.general_location`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventGeneralInfoLocationEdit
              eventFormState={this.props.eventFormState}
              companyState={this.props.companyState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.general_infrastructure`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventGeneralInfoInfrastructureEdit
              eventFormState={this.props.eventFormState}
              companyState={this.props.companyState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>
      </Stack>
    );
  }
}

export default EventGeneralInfoTab;
