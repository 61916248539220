import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import {
  BCEventAvailabilityRange,
  BCEventCategory,
  BCEventDedicatedTime,
  BCEventRangeOpeningHours,
  BCEventTag,
} from "../model/event";
import { mapErrorFromException } from "../model/error";
import { Company } from "../model/company";
import { BCLocation } from "../model/location";
import { CompanySharedLocation } from "../model/company.shared.location";

export const createNewEvent = (company_id: string, company: Company) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_EVENT_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const resCategories = await axios.get(`/v2/api/b2b/private/companies/${company_id}/categories`);
    const resTags = await axios.get("/v2/api/tags");
    const sharedLocations = await axios.get(`/v2/api/b2b/private/companies/${company_id}/shared_locations`);

    dispatch({
      type: ActionType.CREATE_EVENT,
      payload: {
        company_id,
        categories: resCategories.data.categories,
        tags: resTags.data.tags,
        sharedLocations: sharedLocations.data.locations,
        company,
      },
    });
    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

// @ts-ignore
export const updateEventFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_EVENT_FORM_FIELD,
    payload: { prop, value },
  };
};

export const validateEventForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.VALIDATE_EVENT_FORM });
};

export const resetEventForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_EVENT_FORM });
};

export const toggleEventCategory = (category: BCEventCategory) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.TOGGLE_EVENT_CATEGORY, payload: category });
};

export const addTag = (tag: BCEventTag) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_TAG, payload: tag });
};

export const removeTag = (tag: BCEventTag) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.REMOVE_TAG, payload: tag });
};

export const addEventDedicatedTime = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_EVENT_DEDICATED_TIME });
};

export const removeEventDedicatedTime = (time: BCEventDedicatedTime) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.REMOVE_EVENT_DEDICATED_TIME, payload: time });
  dispatch({ type: ActionType.UPDATE_AVAILABILITY_RANGE });
};

export const addAvailabilityRangeOpeningHour = (range: BCEventAvailabilityRange) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_EVENT_OPENING_HOUR, payload: range });
  dispatch({ type: ActionType.UPDATE_AVAILABILITY_RANGE });
};

export const updateAvailabilityRangeOpeningHour =
  (range: BCEventAvailabilityRange, index: number, value: BCEventRangeOpeningHours) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.UPDATE_EVENT_OPENING_HOUR, payload: { range, value, index } });
  };

export const addAvailabilityRange = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_EVENT_AVAILABILITY_RANGE });
  dispatch({ type: ActionType.UPDATE_AVAILABILITY_RANGE });
};

export const removeAvailabilityRange = (range: BCEventAvailabilityRange) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.REMOVE_EVENT_AVAILABILITY_RANGE, payload: range });
  dispatch({ type: ActionType.UPDATE_AVAILABILITY_RANGE });
};

export const removeAvailabilityRangeOpeningHour =
  (range: BCEventAvailabilityRange, hour: BCEventRangeOpeningHours) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.REMOVE_EVENT_OPENING_HOUR, payload: { range, hour } });
    dispatch({ type: ActionType.UPDATE_AVAILABILITY_RANGE });
  };

export const addCompanyAddressAsLocation = (company: Company) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_COMPANY_ADDRESS_AS_LOCATION, payload: company });
};

export const setCompanyContactAsEventContact = (company: Company) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.SET_COMPANY_CONTACT_AS_EVENT_CONTACT, payload: company });
};
export const setCompanyContactPersonAsEventContactPerson = (company: Company) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.SET_COMPANY_CONTACT_PERSON_AS_EVENT_CONTACT_PERSON, payload: company });
};

export const addSharedLocations = (locations: CompanySharedLocation[]) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_SHARED_LOCATION, payload: locations });
};

export const addLocation = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.ADD_LOCATION });
};
export const updateLocation = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.UPDATE_LOCATION });
};

export const updateEventAvailabilityRangesRange = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.UPDATE_AVAILABILITY_RANGE });
};

export const removeLocation = (location: BCLocation) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.REMOVE_LOCATION, payload: location });
};
