import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TabOpeningHoursIcon = (props) => (
  <SvgIcon width="21" height="21" viewBox="0 0 21 21" {...props}>
    <path
      id="Pfad_148"
      data-name="Pfad 148"
      d="M10.3,0A10.3,10.3,0,1,0,20.6,10.3,10.313,10.313,0,0,0,10.3,0M13.72,12.453a.9.9,0,1,1-1.267,1.267L9.667,10.934A.9.9,0,0,1,9.4,10.3V4.963a.9.9,0,1,1,1.791,0V9.93Z"
    />
  </SvgIcon>
);

export default TabOpeningHoursIcon;
