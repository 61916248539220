import * as React from "react";
import { useEffect, useState } from "react";
import * as intl from "react-intl-universal";
import { AuthenticationState } from "./model/authenticationState";
import * as AuthActions from "./actions/auth";
import * as PageStateActions from "./actions/pageState";
import "./override.css";
import { Progress } from "./components/Progress";
import "rsuite/dist/rsuite.min.css";
import "@fontsource/manrope/200.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "@fontsource/manrope/800.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/material-icons";
import "@fontsource/material-icons-round";
import "@fontsource/material-icons-outlined";
import { Box } from "@mui/material";
import "yet-another-react-lightbox/styles.css";
import { de } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import AppRouter from "./router/AppRouter";

const germanLocalizations = require("./translations/de.json");

export interface Props {
  authenticationState: AuthenticationState;
  actions: typeof AuthActions;
  pageStateActions: typeof PageStateActions;
}

export interface State {
  initDone: boolean;
}

const locales = {
  de: germanLocalizations,
};

// date-fns
setDefaultOptions({ locale: de });

export default function App() {
  const [initDone, setInitDone] = useState(false);

  const determineLocale = () => {
    return "de";
    // TODO: As soon as we support more locales
    /* let localeString: string = locale2;
        if (locales[localeString]) {
          return localeString;
        }
        if (localeString.indexOf("-") !== -1) {
          localeString = localeString.substring(0, localeString.indexOf("-"));
          if (locales[localeString]) {
            return localeString;
          }
          return "en";
        } else {
          return "en";
        } */
  };

  useEffect(() => {
    if (!initDone) {
      intl
        .init({
          currentLocale: determineLocale(),
          locales,
        })
        .then(() => {
          setInitDone(true);
        });
    }
  }, []);

  if (!initDone) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
        <Progress />
      </Box>
    );
  }
  return <AppRouter />;
}
