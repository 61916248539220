import * as React from "react";
import { Box } from "@mui/material";
import axios from "axios";
import { PageTracker } from "../util/pageTracker";
import AppStyles from "../styles/appStyles";

interface Props {}

export interface State {
  reportingURL: string | null;
}

class GlobalReportingPage extends React.Component<Props, State> {
  state = {
    reportingURL: null,
  };

  async fetchReportingURL() {
    try {
      const res = await axios.get("/v2/api/b2b/private/reporting/url");
      if (res.data.url) {
        this.setState({ reportingURL: res.data.url });
      }
    } catch (error) {}
  }

  componentDidMount(): void {
    this.fetchReportingURL();
  }

  render() {
    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit} style={{ height: "100%", margin: "0" }}>
          {this.state.reportingURL && (
            <iframe
              title="Global Reporting"
              id="reportingFrame"
              style={{ height: "100%", width: "100%" }}
              frameBorder="0"
              src={this.state.reportingURL}
            />
          )}
        </Box>
      </>
    );
  }
}

export default GlobalReportingPage;
