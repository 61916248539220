import { Box, Grid, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import AddIcon from "@mui/icons-material/Add";
import * as intl from "react-intl-universal";
import * as SeoPageActions from "../actions/seoPage";
import { RootState } from "../reducers";
import { DefaultButton } from "../components/Buttons";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { PageState, PageStateType } from "../model/pageState";
import { Pagination } from "../components/TablePagination";
import { SeoPageState } from "../model/seoPageState";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import AppStyles from "../styles/appStyles";

const moment = require("moment");

export interface Props {
  seoPageState: SeoPageState;
  pageState: PageState;
  actions: typeof SeoPageActions;
}

export interface State {}

function SeoPagesPage(props: Props) {
  const navigate = useNavigate();
  const handleChangePage = (event: any, page: any) => {
    props.seoPageState.paging.current_page = page + 1;
    props.actions.fetchAllSeoPages(props.seoPageState.paging.current_page, props.seoPageState.paging.page_size);
  };

  const handleChangeRowsPerPage = (event: any) => {
    props.seoPageState.paging.page_size = event.target.value;
    props.actions.fetchAllSeoPages(props.seoPageState.paging.current_page, props.seoPageState.paging.page_size);
  };

  useEffect(() => {
    props.actions.fetchAllSeoPages(props.seoPageState.paging.current_page, props.seoPageState.paging.page_size);
  }, []);

  function editSeoPage(seopage_id?: string) {
    navigate(`/seopages/${seopage_id}`); // + '/edit');
  }

  function createNewSeoPage() {
    navigate("/seopages/new");
  }

  if (props.pageState.state === PageStateType.Loading) {
    return <></>;
  }

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("seopages.title")}</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton
              onClick={() => {
                createNewSeoPage();
              }}
            >
              <AddIcon sx={AppStyles.buttonLeftIcon} />
              {intl.get("seopages.button_add")}
            </DefaultButton>
          </Grid>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("seopages.list_header_title")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("seopages.list_header_url")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("seopages.list_header_created_at")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("seopages.list_header_state")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("seopages.list_header_page_count")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.seoPageState.allSeoPages != null &&
                props.seoPageState.allSeoPages.map((row) => {
                  return (
                    <TableRow
                      key={row.seopage_id}
                      onClick={() => {
                        editSeoPage(row.seopage_id);
                      }}
                      hover
                    >
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {row.title}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {row.url}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {moment(row.created_at).format("LL")}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {row.active ? intl.get("seopages.state_active") : intl.get("seopages.state_inactive")}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={AppStyles.tableCell}>
                        {row.active ? row.matching_page_count : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <Pagination
                  paging={props.seoPageState.paging}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    seoPageState: state.seoPageState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(SeoPageActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SeoPagesPage);
