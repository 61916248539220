import axios from "axios";
import { UploadInfo } from "../model/media";

export const cloudinaryUploader = async function (
  uploadInfo: UploadInfo,
  company_id: string,
  uploadInfoUpdated: any,
  finished: any,
) {
  let { uploadSignature } = uploadInfo;
  let { cloudName } = uploadInfo;
  let { apiKey } = uploadInfo;
  let { uploadSignatureTimestamp } = uploadInfo;
  let { folder } = uploadInfo;
  let { uploadPreset } = uploadInfo;

  if (!uploadSignature) {
    try {
      const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/media/signature`);
      uploadSignature = res.data.signature;
      cloudName = res.data.cloud_name;
      apiKey = res.data.api_key;
      uploadSignatureTimestamp = res.data.timestamp;
      folder = res.data.folder;
      uploadPreset = res.data.upload_preset;

      uploadInfoUpdated({
        folder,
        uploadSignature,
        cloudName,
        apiKey,
        uploadSignatureTimestamp,
        uploadPreset,
      });
    } catch (error) {
      console.log("Failed fetching signature");
    }
  }

  if (uploadSignature) {
    const cl: any = (window as any).cloudinary;
    const parameters = {
      sources: ["local"],
      theme: "minimal",
      language: "de",
      cloudName,

      folder,
      uploadSignature,
      apiKey,
      uploadSignatureTimestamp,
      uploadPreset: null,
    };
    if (uploadPreset) {
      parameters.uploadPreset = uploadPreset;
    }
    const widget = cl.openUploadWidget(parameters, function (error: any, result: any) {
      if (result && result.event === "success") {
        finished(result.info);
      } else if (result && result.event === "queues-end") {
        widget.close({ quiet: true });
      }
    });
  }
};
