import intl from "react-intl-universal";

export interface GlobalInvoiceStatus {
  invoice_id: string;
  company_id: string;
  company_name: string;
  invoice_number: string;
  billing_timestamp: Date;
  amount_in_cents: number;
  normalized_invoice_date: string;
  currency: string;
  status: string;
  paymentResponses: string[];
}

export const formattedAmount = (amount_in_cents: number, currency: string, minimumFractionDigits = 2) => {
  let formattedCurrency = currency;
  if (intl.get(`currencies_short.${currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${currency}`);
  }

  return `${formattedCurrency} ${(amount_in_cents / 100).toLocaleString("de", {
    minimumFractionDigits: minimumFractionDigits,
  })}`;
};
