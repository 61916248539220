import * as React from "react";
import { Box, Grid } from "@mui/material";
import { DefaultButton } from "../Buttons";
import useDialogHandler from "../dialog/useDialogHandler";
import BookingDrawer, { BookingDrawerHandlerPayload } from "../drawer/BookingDrawer";

export default function PlanPromotionBanner({ companyId }: { companyId: string }) {
  const handler = useDialogHandler<BookingDrawerHandlerPayload>();

  return (
    <Box
      sx={{
        marginLeft: 4,
        marginRight: 4,
        paddingLeft: "32px",
        paddingRight: "32px",
        marginTop: 2,
        marginBottom: 2,
        height: "90px",
        backgroundImage: 'url("/plan_banner_balken.png")',
        backgroundSize: "100% 100%",
        resize: "both",
      }}
    >
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"space-between"}
        style={{ height: "100%" }}
      >
        <Grid
          item
          direction={"row"}
          alignItems={"center"}
          alignContent={"center"}
          style={{ height: "100%", display: "flex" }}
        >
          <Grid item>
            <img src={"/plan_banner_plus.png"} width={50} height={50} />
          </Grid>
          <Grid item style={{ color: "#FFFFFF", marginLeft: "16px" }}>
            <b>bis zu 20x mehr Besuche</b> auf deiner exklusiven Detailseite
          </Grid>
        </Grid>
        <Grid item style={{ color: "#FFFFFF" }}>
          <DefaultButton
            color={"warningLight"}
            title={"Jetzt Paket buchen"}
            onClick={() => handler.open({ companyId, intent: "premium_purchase" })}
          />
        </Grid>
      </Grid>
      <BookingDrawer handler={handler} />
    </Box>
  );
}
