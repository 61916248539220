import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { BCEvent } from "../model/event";
import { PagingInformation } from "../model/pagingInformation";
import * as CompanyActions from "../actions/company";
import usePagination from "./usePagination";
import { useCallback, useEffect, useState } from "react";
import useSelectedCompanyId from "./useSelectedCompanyId";

const DEFAULT_PAGE_SIZE = 50;

export default function useCompanyEvents(autoLoad = true) {
  const dispatch = useDispatch();
  const companyId = useSelectedCompanyId();

  const [loading, setLoading] = useState(false);

  const events = useSelector<RootState, BCEvent[]>((state) => state.eventState.events);
  const pagingInformation = useSelector<RootState, PagingInformation>((state) => state.eventState.paging);

  const paginationHandler = usePagination(pagingInformation, DEFAULT_PAGE_SIZE);

  const loadEvents = useCallback(async () => {
    setLoading(true);
    await dispatch<any>(
      CompanyActions.fetchCompanyEvents(companyId, paginationHandler.currentPage, paginationHandler.pageSize),
    );
    setLoading(false);
  }, [paginationHandler.currentPage, paginationHandler.pageSize, companyId]);

  useEffect(() => {
    if (autoLoad) {
      loadEvents();
    }
  }, [paginationHandler.currentPage, paginationHandler.pageSize, companyId]);

  return {
    events,
    paginationHandler,
    pagingInformation,
    loadEvents,
    loading,
  };
}
