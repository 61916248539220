import axios from "axios";
import * as intl from "react-intl-universal";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { Error, mapErrorFromException } from "../model/error";
import { LoginMethod, User } from "../model/user";

export const fetchUserInfo = () => async (dispatch: Dispatch) => {
  const token = localStorage.getItem("b2b-access-token");
  try {
    if (token !== null) {
      const res = await axios.get("/v2/api/private/user");

      await retrieveInboxData(res.data, dispatch);

      dispatch({ type: ActionType.FETCH_USER, payload: res.data });
    } else {
      dispatch({ type: ActionType.LOGOUT });
    }
  } catch (error) {
    dispatch({ type: ActionType.LOGOUT });
  }
};

export const resendMyConfirmationMail = () => async () => {
  try {
    await axios.post("/v2/api/private/user/resend_confirmation");
  } catch (error) {}
};

export const signup =
  (firstName: string, lastName: string, email: string, password: string, passwordRepeat: string, code?: string) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.SIGNUP_PROGRESS });

    if (password !== passwordRepeat) {
      const message = intl.get("signup.passwords_not_equal");
      const error: Error = {
        errorMessage: message,
        statusCode: 403,
      };
      dispatch({ type: ActionType.SIGNUP_ERROR, payload: error });
    } else {
      try {
        const response = await axios.post("/v2/api/auth/signup", {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          scope: "b2b",
          code: code,
        });
        if (response.data.status === "activated") {
          dispatch({
            type: ActionType.SIGNUP_SUCCESS,
            payload: { needs_activation: false, finished_activation: true },
          });
        } else {
          dispatch({
            type: ActionType.SIGNUP_SUCCESS,
            payload: { needs_activation: true, finished_activation: false },
          });
        }
      } catch (error) {
        dispatch({
          type: ActionType.SIGNUP_ERROR,
          payload: { error: mapErrorFromException(error) },
        });
      }
    }
  };

export const signInWithFacebook = (token: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.AUTH_PROGRESS });

  try {
    const res = await axios.post("/v2/api/auth/b2b/facebook", {
      access_token: token,
      scope: "b2b",
    });
    if (handleAuthenticationResponse(res)) {
      await retrieveInboxData(res.data.user, dispatch);
      dispatch({ type: ActionType.AUTH_SUCCESS, payload: res.data.user });
    } else {
      dispatch({ type: ActionType.AUTH_ERROR });
    }
  } catch (error) {
    dispatch({
      type: ActionType.AUTH_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const signInWithGoogle = (credential: any) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.AUTH_PROGRESS });

  try {
    const res = await axios.post(`/v2/api/auth/b2b/google`, { credential, scope: "b2b" });

    if (handleAuthenticationResponse(res)) {
      await retrieveInboxData(res.data.user, dispatch);
      dispatch({ type: ActionType.AUTH_SUCCESS, payload: res.data.user });
    } else {
      dispatch({ type: ActionType.AUTH_ERROR });
    }
  } catch (error) {
    dispatch({
      type: ActionType.AUTH_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const login = (email: string, password: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.AUTH_PROGRESS });

  try {
    const res = await axios.post("/v2/api/auth/authorize", {
      email,
      password,
      scope: "b2b",
    });
    if (handleAuthenticationResponse(res)) {
      await retrieveInboxData(res.data.user, dispatch);
      dispatch({ type: ActionType.AUTH_SUCCESS, payload: res.data.user });
    } else {
      dispatch({ type: ActionType.AUTH_ERROR });
    }
  } catch (error) {
    dispatch({
      type: ActionType.AUTH_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const logout = (serverCall: boolean, user?: User | null) => async (dispatch: Dispatch) => {
  if (user && user.login_method === LoginMethod.Facebook) {
    await disconnectFB();
  } else if (user && user.login_method === LoginMethod.Google) {
    await disconnectGoogle();
  }

  if (serverCall) {
    try {
      await axios.delete("/v2/api/auth/token");
    } catch (error) {
      localStorage.removeItem("b2b-access-token");
    }
  }

  localStorage.removeItem("b2b-access-token");

  axios.defaults.headers.common.Authorization = null;

  dispatch({ type: ActionType.LOGOUT });
};

export const forgotPassword = (email: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.FORGOT_PASSWORD_PROGRESS });
  try {
    await axios.post("/v2/api/auth/forgotPassword", { email, scope: "b2b" });
    dispatch({ type: ActionType.FORGOT_PASSWORD_SUCCESS, payload: null });
  } catch (error) {
    dispatch({
      type: ActionType.FORGOT_PASSWORD_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const resetPassword = (newPassword: string, resetCode: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_PASSWORD_PROGRESS });
  try {
    await axios.post("/v2/api/auth/resetPassword", {
      newPassword,
      resetCode,
      scope: "b2b",
    });

    dispatch({ type: ActionType.RESET_PASSWORD_SUCCESS, payload: null });
  } catch (error) {
    dispatch({
      type: ActionType.RESET_PASSWORD_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

function handleAuthenticationResponse(res: any) {
  if (res.data.token && res.data.user) {
    localStorage.setItem("b2b-access-token", res.data.token);

    axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;

    return true;
  }
  localStorage.removeItem("b2b-access-token");

  axios.defaults.headers.common.Authorization = null;

  return false;
}

export const resendUserInvitation =
  (userId: string, companyId: string | null, role: string | null) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.RESEND_INVITATION,
      payload: { loading: true },
    });

    try {
      await axios.post(`/v2/api/private/users/${userId}/resendInvitation`, {
        company_id: companyId,
        role,
      });

      dispatch({ type: ActionType.RESEND_INVITATION, payload: {} });
    } catch (error) {
      dispatch({
        type: ActionType.RESEND_INVITATION,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

async function disconnectFB() {
  const w: any = window;
  if (w.FB) {
    await w.FB.logout();
  }
}

async function disconnectGoogle() {
  const w: any = window;

  if (w.gapi != null && w.gapi.auth2 != null && w.gapi.auth2.getAuthInstance() != null) {
    const auth2: any = w.gapi.auth2.getAuthInstance();
    auth2.signOut();
    auth2.disconnect();
    auth2.currentUser = null;
  }
}

async function retrieveInboxData(user: User, dispatch: Dispatch) {
  if (user.permissions.indexOf("REVIEW_COMPANY_EVENTS") !== -1) {
    const inboxRes = await axios.get("/v2/api/b2b/private/event_inbox?page=1&size=1");

    dispatch({
      type: ActionType.FETCH_INBOX_EVENTS,
      payload: {
        data: true,
        overview: true,
        events: inboxRes.data.events,
        paging: inboxRes.data.paging,
      },
    });
  }
}
