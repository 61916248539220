import * as intl from "react-intl-universal";

export interface Invoice {
  invoice_id: string;
  invoice_number: string;
  billing_period: string;
  billing_period_start: Date;
  billing_period_end: Date;
  amount_in_cents: number;
  total_amount_in_cents: number;
  currency: string;
  billing_timestamp?: Date;
  normalized_invoice_date: string;
  status: string;
  created_at: Date;
  updated_at: Date;
}

export enum InvoiceStatus {
  Open = "OPEN",
  Overdue = "OVERDUE",
  Paid = "PAID",
  Cancelled = "CANCELLED",
  Pending = "PENDING",
}

export const formattedInvoiceAmount = (invoice: Invoice) => {
  let formattedCurrency = invoice.currency;
  if (intl.get(`currencies_short.${invoice.currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${invoice.currency}`);
  }

  return `${formattedCurrency} ${(invoice.total_amount_in_cents / 100).toLocaleString("de", {
    minimumFractionDigits: 2,
  })}`;
};
