import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const CloseIcon = (props) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      id="Pfad_29"
      data-name="Pfad 29"
      d="M11.7,10.227A1.038,1.038,0,0,1,10.227,11.7L6,7.469,1.773,11.7A1.038,1.038,0,0,1,.3,10.227L4.532,6,.3,1.773A1.038,1.038,0,0,1,1.773.3L6,4.532,10.227.3A1.038,1.038,0,0,1,11.7,1.773L7.469,6Z"
      transform="translate(0 0)"
      fill="#3b3d3b"
    />
  </SvgIcon>
);

export default CloseIcon;
