import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import intl from "react-intl-universal";
import { BCEvent } from "../../../model/event";
import EventCoverImage from "../../events/EventCoverImage";
import TextClamper from "../../textClamper";

interface Props {
  event: BCEvent;
  sx?: SxProps;
}

export default function BookingDrawerEventHeader({ event, sx }: Props) {
  const theme = useTheme();

  return (
    <Stack direction={"row"} gap={"15px"} alignItems={"center"} sx={sx}>
      <EventCoverImage
        event={event}
        sx={{
          background: theme.palette.primary.light,
          borderRadius: "5px",
          width: "79px",
          height: "44px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Stack>
        <Typography sx={{ font: "normal normal bold 11px/18px Manrope", textTransform: "uppercase" }}>
          {intl.get("booking_drawer.general.label_event")}
        </Typography>
        <Typography sx={{ font: "normal normal bold 14px/18px Manrope" }}>
          <TextClamper numberOfLines={1} text={event.title} />
        </Typography>
      </Stack>
    </Stack>
  );
}
