import * as intl from "react-intl-universal";
import { CompanyPaymentProduct } from "./payment/company.payment.product";
import { PaymentProduct } from "./payment/payment.product";
import moment from "moment";
import "moment/locale/de";

export enum PaymentType {
  sepa = "sepa",
  card = "card",
  byTransaction = "transaction",
}

export interface PaymentInfo {
  payment_provider_payment_type_id: string;
  payment_provider_payment_type: string;
  payment_method: string;
  status: PaymentInfoStatus;
  authorizationRedirectUrl: string | null;
  data: any;
}

export enum PaymentInfoStatus {
  VALID = "VALID",
  INVALID = "INVALID",
  PENDING_AUTHORIZATION = "PENDING_AUTHORIZATION",
}

export enum BillingType {
  PrePaidHourly = "PPH",
  PrePaidDaily = "PPD",
  PrePaidMonthly = "PPM",
  PrePaidYearly = "PPY",
}

export class BillingTypeUtil {
  static toBillingIntervalUnit(type: BillingType, productType: string): string {
    if (productType === "credit") {
      return "SP";
    }
    switch (type) {
      case BillingType.PrePaidHourly:
        return "hour";
      case BillingType.PrePaidDaily:
        return "day";
      case BillingType.PrePaidMonthly:
        return "month";
      case BillingType.PrePaidYearly:
        return "year";
      default:
        return "";
    }
  }

  static fromString(s: string): BillingType | undefined {
    switch (s) {
      case "PPH":
        return BillingType.PrePaidHourly;
      case "PPD":
        return BillingType.PrePaidDaily;
      case "PPM":
        return BillingType.PrePaidMonthly;
      case "PPY":
        return BillingType.PrePaidYearly;
      default:
        return undefined;
    }
  }
}

export enum DiscountType {
  Percentage = "PERCENTAGE",
  AmountInCents = "AMOUNT_IN_CENTS",
}

export const formattedPaymentInfo = (paymentInfo: PaymentInfo) => {
  if (paymentInfo.payment_provider_payment_type === "card") {
    if (paymentInfo.data && (paymentInfo.data._panNumber || paymentInfo.data._number) && paymentInfo.data._expiryDate) {
      return intl.get("company_plan_info.payment_method.card_description", {
        number: paymentInfo.data._panNumber ? paymentInfo.data._panNumber : paymentInfo.data._number,
        expiry: paymentInfo.data._expiryDate,
      });
    }
    return intl.get("company_plan_info.payment_method.card_description_invalid");
  }
  if (paymentInfo.payment_provider_payment_type === "sepa") {
    if (paymentInfo.data && paymentInfo.data._iban) {
      return intl.get("company_plan_info.payment_method.sepa_description", {
        iban: paymentInfo.data._iban,
      });
    }
    return intl.get("company_plan_info.payment_method.sepa_description_invalid");
  }
  if (paymentInfo.payment_method === "TRANSACTION") {
    return intl.get("company_plan_info.payment_method.by_transaction_description");
  }
  return "";
};

export const formattedProductConfirmationTitle = (product: PaymentProduct) => {
  return intl.get(`company_plan_info.plans.${product.type}_activation.title`);
};

export const formattedProductConfirmationMessage = (product: PaymentProduct, credit_amount = 1) => {
  const values: string[] = [];
  values.push(
    intl.get(`company_plan_info.plans.${product.type}_activation.message_confirmation`, { name: product.name }),
  );

  const regularPriceText = formattedPaymentProductPrice(product, credit_amount);
  const reducedPriceTextWithBillingInterval = `${formattedReducedPricingInfo(product)} ${formattedPlanBillingInterval(
    product.billing_interval_unit,
    product.billing_interval_value,
  )}`;
  const reducedPriceText = formattedReducedPricingInfo(product);
  const taxInformation = formattedPaymentProductTax(product);

  if (product.trial_unit && product.trial_unit !== "" && product.trial_interval > 0) {
    const shortTrialInfo = formattedShortTrialPhase(product.trial_unit, product.trial_interval);
    if (product.number_of_reduced_price_periods > 0) {
      values.push(
        intl.get(`company_plan_info.plans.${product.type}_activation.message_trial_reduced`, {
          trial_phase: shortTrialInfo,
          price: reducedPriceText,
          tax: taxInformation,
        }),
      );
    } else {
      values.push(
        intl.get(`company_plan_info.plans.${product.type}_activation.message_trial`, {
          trial_phase: shortTrialInfo,
          price: regularPriceText,
          tax: taxInformation,
        }),
      );
    }
  } else {
    if (product.number_of_reduced_price_periods > 0) {
      values.push(
        intl.get(`company_plan_info.plans.${product.type}_activation.message_no_trial_reduced`, {
          price: reducedPriceTextWithBillingInterval,
          tax: taxInformation,
        }),
      );
    } else {
      values.push(
        intl.get(
          product.type === "credit" && credit_amount > 1
            ? `company_plan_info.plans.${product.type}_activation.message_no_trial_multiple`
            : `company_plan_info.plans.${product.type}_activation.message_no_trial`,
          {
            price: regularPriceText,
            tax: taxInformation,
            name: product.name,
            credits: credit_amount,
          },
        ),
      );
    }
  }
  if (product.number_of_reduced_price_periods > 0) {
    const reducedPriceInfo =
      product.number_of_reduced_price_periods === 1
        ? intl.get(`company_plan_info.plans.${product.type}_activation.reduced_price_single`)
        : intl.get(`company_plan_info.plans.${product.type}_activation.reduced_price_multiple`, {
            count: product.number_of_reduced_price_periods,
          });
    values.push(
      intl.get(`company_plan_info.plans.${product.type}_activation.message_reduced_price`, {
        price: reducedPriceTextWithBillingInterval,
        tax: taxInformation,
        reduced_period_info: reducedPriceInfo,
      }),
    );
  }

  values.push(
    intl.get(`company_plan_info.plans.${product.type}_activation.message_continuous_payment`, {
      billing_info: formattedPaymentProductBillingInfo(product, false, true),
      tax: taxInformation,
    }),
  );

  return values.join("<br/><br/>");
};

export const formattedShortTrialPhase = (trial_unit: string, trial_interval: number) => {
  if (trial_unit && trial_unit !== "" && trial_interval > 0) {
    if (trial_unit === "year") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length_short.single.year")
        : intl.get("company_plan_info.plans.trial_length_short.multi.year", {
            count: trial_interval,
          });
    }
    if (trial_unit === "month") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length_short.single.month")
        : intl.get("company_plan_info.plans.trial_length_short.multi.month", {
            count: trial_interval,
          });
    }
    if (trial_unit === "day") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length_short.single.day")
        : intl.get("company_plan_info.plans.trial_length_short.multi.day", {
            count: trial_interval,
          });
    }
    if (trial_unit === "hour") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length_short.single.hour")
        : intl.get("company_plan_info.plans.trial_length_short.multi.hour", {
            count: trial_interval,
          });
    }
  }
  return "";
};

export const formattedTrialPhase = (trial_unit: string, trial_interval: number) => {
  if (trial_unit && trial_unit !== "" && trial_interval > 0) {
    if (trial_unit === "year") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length.single.year")
        : intl.get("company_plan_info.plans.trial_length.multi.year", {
            count: trial_interval,
          });
    }
    if (trial_unit === "month") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length.single.month")
        : intl.get("company_plan_info.plans.trial_length.multi.month", {
            count: trial_interval,
          });
    }
    if (trial_unit === "day") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length.single.day")
        : intl.get("company_plan_info.plans.trial_length.multi.day", {
            count: trial_interval,
          });
    }
    if (trial_unit === "hour") {
      return trial_interval === 1
        ? intl.get("company_plan_info.plans.trial_length.single.hour")
        : intl.get("company_plan_info.plans.trial_length.multi.hour", {
            count: trial_interval,
          });
    }
  }
  return "";
};

export const formattedPlanBillingInterval = (billing_interval_unit: string, billing_interval_value: number) => {
  if (billing_interval_unit === "year") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.billing_interval.single.year")
      : intl.get("company_plan_info.plans.billing_interval.multi.year", {
          count: billing_interval_value,
        });
  }
  if (billing_interval_unit === "month") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.billing_interval.single.month")
      : intl.get("company_plan_info.plans.billing_interval.multi.month", {
          count: billing_interval_value,
        });
  }
  if (billing_interval_unit === "day") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.billing_interval.single.day")
      : intl.get("company_plan_info.plans.billing_interval.multi.day", {
          count: billing_interval_value,
        });
  }
  if (billing_interval_unit === "hour") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.billing_interval.single.hour")
      : intl.get("company_plan_info.plans.billing_interval.multi.hour", {
          count: billing_interval_value,
        });
  }
  return "";
};

export const formattedPlanReducedPlanBillingInterval = (
  billing_interval_unit: string,
  billing_interval_value: number,
) => {
  if (billing_interval_unit === "year") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.reduced_billing_interval.single.year")
      : intl.get("company_plan_info.plans.reduced_billing_interval.multi.year", {
          count: billing_interval_value,
        });
  }
  if (billing_interval_unit === "month") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.reduced_billing_interval.single.month")
      : intl.get("company_plan_info.plans.reduced_billing_interval.multi.month", {
          count: billing_interval_value,
        });
  }
  if (billing_interval_unit === "day") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.reduced_billing_interval.single.day")
      : intl.get("company_plan_info.plans.reduced_billing_interval.multi.day", {
          count: billing_interval_value,
        });
  }
  if (billing_interval_unit === "hour") {
    return billing_interval_value === 1
      ? intl.get("company_plan_info.plans.reduced_billing_interval.single.hour")
      : intl.get("company_plan_info.plans.reduced_billing_interval.multi.hour", {
          count: billing_interval_value,
        });
  }
  return "";
};

export const formattedCompanyProductBillingInfo = (product: CompanyPaymentProduct) => {
  return formattedCompanyProductBillingInfoWithSeparator(product, "<br/>", true);
};

export const formattedCompanyProductBillingInfoWithSeparator = (
  product: CompanyPaymentProduct,
  separator: string,
  showCancellationHint: boolean,
) => {
  const reducedPricingInfo = formattedReducedCompanyPricingInfo(product);
  const interval = formattedPlanBillingInterval(product.billing_interval_unit, product.billing_interval_value);

  let formattedCurrency = product.currency;
  if (intl.get(`currencies_short.${product.currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${product.currency}`);
  }
  let formattedPrice = `${formattedCurrency} ${(product.price_in_cents / 100).toLocaleString("de", {
    minimumFractionDigits: 2,
  })}`;
  if (product.tax_rate_percentage !== 0) {
    formattedPrice += intl.get("company_plan_info.plans.excluding_tax", {
      percentage: product.tax_rate_percentage,
    });
  }

  if (product.discount_value) {
    if (product.discount_type === DiscountType.AmountInCents) {
      const formattedDiscountValue = `${formattedCurrency} ${(product.discount_value / 100).toLocaleString("de", {
        minimumFractionDigits: 2,
      })}`;
      formattedPrice = `${formattedPrice} ${intl.get("company_plan_info.plans.discount_value", {
        value: formattedDiscountValue,
      })}`;
    } else if (product.discount_type === DiscountType.Percentage) {
      const value = formattedAmountWithScaling(product.discount_value, 0);
      formattedPrice = `${formattedPrice} ${intl.get("company_plan_info.plans.discount_percentage", { value })}`;
    }
  }

  const parts = [];

  if (product.first_invoice_date && moment(product.first_invoice_date).isAfter(moment())) {
    const formatString = product.billing_interval_unit === "hour" ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
    const formattedDate = moment(product.first_invoice_date).locale("de").format(formatString);
    parts.push(`<strong>Probephase</strong> bis zu erster Rechnung am ${formattedDate}`);
  }

  if (reducedPricingInfo.length > 0) {
    parts.push(reducedPricingInfo);
    parts.push(`danach ${formattedPrice} - ${interval}`);
  } else {
    parts.push(`${formattedPrice} - ${interval}`);
  }

  if (product.status === "ACTIVE" && showCancellationHint) {
    parts.push(intl.get("company_plan_info.plans.until_cancellation_suffix"));
  }
  return parts.join(separator);
};

const formattedAmountWithScaling = (amount: number, precision: number): string => {
  const value = amount / Math.pow(10, precision);
  const nf = new Intl.NumberFormat("de", {
    style: "decimal",
    minimumFractionDigits: precision,
    minimumIntegerDigits: 1,
  });
  return nf.format(value);
};

export const formattedPaymentProductPrice = (
  product: Pick<PaymentProduct, "currency" | "price_in_cents">,
  amount = 1,
) => {
  let formattedCurrency = product.currency;
  if (intl.get(`currencies_short.${product.currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${product.currency}`);
  }

  return `${formattedCurrency} ${((product.price_in_cents * amount) / 100).toLocaleString("de", {
    minimumFractionDigits: 2,
  })}`;
};

export const formattedPaymentProductTax = (product: PaymentProduct) => {
  if (product.tax_rate_percentage !== 0) {
    return intl.get("company_plan_info.plans.excluding_tax", {
      percentage: product.tax_rate_percentage,
    });
  }
  return "";
};

export const formattedPaymentProductBillingInfo = (
  product: Pick<
    PaymentProduct,
    "billing_interval_unit" | "billing_interval_value" | "currency" | "price_in_cents" | "tax_rate_percentage"
  >,
  includeTaxNote?: boolean,
  includeBillingInterval?: boolean,
  productCount?: number,
) => {
  const interval = formattedPlanBillingInterval(product.billing_interval_unit, product.billing_interval_value);

  let formattedCurrency = product.currency;
  if (intl.get(`currencies_short.${product.currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${product.currency}`);
  }

  let formattedPrice = `${formattedCurrency} ${((product.price_in_cents * (productCount || 1)) / 100).toLocaleString(
    "de",
    {
      minimumFractionDigits: 2,
    },
  )}`;

  if (includeTaxNote && product.tax_rate_percentage !== 0) {
    formattedPrice += intl.get("company_plan_info.plans.excluding_tax", {
      percentage: product.tax_rate_percentage,
    });
  }

  if (includeBillingInterval) {
    return `${formattedPrice} ${interval}`;
  }
  return formattedPrice;
};

export const formattedReducedPricingInfo = (product: PaymentProduct, productCount = 1) => {
  let formattedCurrency = product.currency;
  if (intl.get(`currencies_short.${product.currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${product.currency}`);
  }

  return `${formattedCurrency} ${((product.reduced_price_in_cents * productCount) / 100).toLocaleString("de", {
    minimumFractionDigits: 2,
  })}`;
};

export const formattedReducedCompanyPricingInfo = (product: CompanyPaymentProduct, productCount = 1) => {
  if (product.remaining_reduced_price_periods === 0) {
    return "";
  }
  let formattedCurrency = product.currency;
  if (intl.get(`currencies_short.${product.currency}`)) {
    formattedCurrency = intl.get(`currencies_short.${product.currency}`);
  }

  const pricing = `${formattedCurrency} ${((product.reduced_price_in_cents * productCount) / 100).toLocaleString("de", {
    minimumFractionDigits: 2,
  })}`;

  return `<strong>${product.remaining_reduced_price_periods} Mal reduzierter Preis</strong> von ${pricing}`;
};

export const formattedExpiryDateForCompanyProduct = (product: CompanyPaymentProduct) => {
  if (product.active_until_date) {
    return intl.get("company_plan_info.plans.plan_active_until", {
      date: moment(product.active_until_date).locale("de").format("DD.MM.YYYY HH:mm"),
    });
  }
  return "";
};

export const formattedNextInvoiceDateForCompanyProduct = (product: CompanyPaymentProduct) => {
  if (product.next_invoice_date) {
    const formatString = product.billing_interval_unit === "hour" ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
    if (product.next_invoice_date === product.first_invoice_date) {
      return intl.get("company_plan_info.plans.first_invoice_date", {
        date: moment(product.first_invoice_date).locale("de").format(formatString),
      });
    }
    return intl.get("company_plan_info.plans.next_invoice_date", {
      date: moment(product.next_invoice_date).locale("de").format(formatString),
    });
  }
  return "";
};

export const formattedShortInfoForCompanyProduct = (product: CompanyPaymentProduct) => {
  return formattedCompanyProductBillingInfoWithSeparator(product, "<br/>", false);
};
