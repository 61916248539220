import intl from "react-intl-universal";

export interface ActivityLog {
  log_id: string;

  company?: ActivityLogCompany;
  user?: ActivityLogUser;
  source: string;

  text: string;
  timestamp: Date;
  type: string;
}

export interface ActivityLogUser {
  user_id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface ActivityLogCompany {
  company_id: string;
  name: string;
}

export function formattedActivityLogUser(log: ActivityLog): string {
  let prefix = "";
  if (log.source === "customer") {
    prefix = "Kunde";
  } else if (log.source === "system") {
    prefix = "System";
  } else if (log.source === "internal") {
    prefix = "Intern";
  } else {
    prefix = "Unbekannt";
  }
  if (log.user) {
    if (log.user.first_name && log.user.last_name) {
      if (log.user.email) {
        return `${prefix}: ${log.user.first_name} ${log.user.last_name} (${log.user.email})`;
      } else {
        return `${prefix}: ${log.user.first_name} ${log.user.last_name}`;
      }
    } else if (log.user.email) {
      return `${prefix}: ${log.user.email}`;
    } else {
      return `${prefix}: Unbekannter Benutzer`;
    }
  }
  return prefix;
}

export function formattedActivityLogType(log: ActivityLog): string {
  return intl.get(`activity_log_types.${log.type}`);
}
