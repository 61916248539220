import useEnv from "./useEnv";

enum LogLevel {
  Info = 0,
  Plaintext = 1,
  Warn = 2,
  Error = 3,
  Off = 99,
}

const envLogLevel = {
  development: LogLevel.Info,
  production: LogLevel.Off,
  test: LogLevel.Off,
};

interface Logger {
  log: (...data: any[]) => void;
  info: (...data: any[]) => void;
  warn: (...data: any[]) => void;
  error: (...data: any[]) => void;
  fromJson: (content: string) => any | string;
}

export default function useLogger(tag?: string): Logger {
  const env = useEnv();

  const level = envLogLevel[env.nodeEnv];

  const log = (...data: any[]) => {
    if (level > LogLevel.Plaintext) {
      return;
    }

    if (tag) {
      // eslint-disable-next-line no-console
      console.log(...data, tag);
    } else {
      // eslint-disable-next-line no-console
      console.log(...data);
    }
  };

  const info = (...data: any[]) => {
    if (level > LogLevel.Info) {
      return;
    }

    if (tag) {
      // eslint-disable-next-line no-console
      console.info(...data, tag);
    } else {
      // eslint-disable-next-line no-console
      console.info(...data);
    }
  };

  const warn = (...data: any[]) => {
    if (level > LogLevel.Warn) {
      return;
    }

    if (tag) {
      // eslint-disable-next-line no-console
      console.warn(...data, tag);
    } else {
      // eslint-disable-next-line no-console
      console.warn(...data);
    }
  };

  const error = (...data: any[]) => {
    if (level > LogLevel.Error) {
      return;
    }

    if (tag) {
      // eslint-disable-next-line no-console
      console.error(...data, tag);
    } else {
      // eslint-disable-next-line no-console
      console.error(...data);
    }
  };

  const fromJson = (content: string) => {
    if (env.nodeEnv === `development`) {
      return JSON.parse(content);
    }
    return content;
  };

  return {
    log,
    info,
    warn,
    error,
    fromJson,
  };
}
