import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import axios from "axios";
import { mapErrorFromException } from "../model/error";

export const fetchReportingResult = (company_id: string, timeframe: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_COMPANY_REPORTING_RESULT });

  try {
    const response = await axios.get(
      `/v2/api/b2b/private/companies/${company_id}/reporting/data?timeframe=${timeframe}`,
    );

    dispatch({
      type: ActionType.LOADED_COMPANY_REPORTING_RESULT,
      payload: {
        data: true,
        result: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.FAILED_COMPANY_REPORTING_RESULT,
    });
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};
