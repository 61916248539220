import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Tab, Tabs } from "@mui/material";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { SeoPageFormState } from "../../../model/seoPageFormState";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import { SectionLabel } from "../../../components/SectionLabel";
import { PageState, PageStateType } from "../../../model/pageState";
import { AuthenticationState } from "../../../model/authenticationState";
import { RootState } from "../../../reducers";
import * as SeoPageActions from "../../../actions/seoPage";
import { SeoPageState } from "../../../model/seoPageState";
import { BCEventCategory } from "../../../model/event";
import * as React from "react";
import MySelect from "../../../components/select/MySelect";
import AppStyles from "../../../styles/appStyles";

export interface Props {
  seoPageFormState: SeoPageFormState;
  seoPageState: SeoPageState;
  pageState: PageState;
  actions: typeof SeoPageActions;
  readonly: boolean;
  showResellerSelection: boolean;
  authenticationState: AuthenticationState;
  children?: any;
}

export interface State {
  tab: number;
}

class SeoPageEditForm extends React.Component<Props> {
  state = {
    tab: 0,
  };

  handleChange = (propName: any) => (event: any) => {
    this.props.actions.updateSeoPageFormField({
      prop: propName,
      value: event.target.value,
    });
  };

  handleCheckboxChange = (propName: any) => (event: any) => {
    this.props.actions.updateSeoPageFormField({
      prop: propName,
      value: event.target.checked,
    });
  };

  renderTitle(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_title")}
        id="title"
        onChange={this.handleChange("seopage.title")}
        value={this.props.seoPageFormState.seopage.title}
        margin="normal"
        error={invalidFields.includes("title")}
      />
    );
  }

  renderHeadline(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_headline")}
        id="headline"
        onChange={this.handleChange("seopage.headline")}
        value={this.props.seoPageFormState.seopage.headline}
        margin="normal"
        error={invalidFields.includes("headline")}
      />
    );
  }

  renderDescription(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        maxLength={1500}
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_description")}
        id="description"
        onChange={this.handleChange("seopage.description")}
        value={this.props.seoPageFormState.seopage.description}
        multiline
        rows={10}
        maxRows={10}
        margin="normal"
        error={invalidFields.includes("description")}
      />
    );
  }

  renderBottomDescription(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        maxLength={1500}
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_bottom_description")}
        id="bottom_description"
        onChange={this.handleChange("seopage.bottom_description")}
        value={this.props.seoPageFormState.seopage.bottom_description}
        multiline
        rows={10}
        maxRows={10}
        margin="normal"
        error={invalidFields.includes("bottom_description")}
      />
    );
  }

  renderInternalNotes(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        maxLength={3000}
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_internal_notes")}
        id="internal_notes"
        onChange={this.handleChange("seopage.internal_notes")}
        value={this.props.seoPageFormState.seopage.internal_notes}
        multiline
        rows={10}
        maxRows={10}
        margin="normal"
        error={invalidFields.includes("internal_notes")}
      />
    );
  }

  renderMetaDescription(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        maxLength={200}
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_metadescription")}
        id="metadescription"
        onChange={this.handleChange("seopage.meta_description")}
        value={this.props.seoPageFormState.seopage.meta_description}
        margin="normal"
        error={invalidFields.includes("metadescription")}
      />
    );
  }

  renderUrl(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly}
        label={intl.get("seopage_form.info.label_url")}
        id="url"
        onChange={this.handleChange("seopage.url")}
        value={this.props.seoPageFormState.seopage.url}
        margin="normal"
        error={invalidFields.includes("url")}
      />
    );
  }

  renderActiveCheckbox() {
    return (
      <>
        <FormGroup>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={this.props.seoPageFormState.seopage.active !== false}
                onChange={this.handleCheckboxChange("seopage.active")}
              />
            }
            label={intl.get("seopage_form.info.label_active")}
          />
        </FormGroup>
      </>
    );
  }

  renderFilterText(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        maxLength={1500}
        multiline
        rows={10}
        maxRows={10}
        disabled={this.props.readonly}
        label={intl.get("seopage_form.filter.label_text")}
        id="filter_text"
        onChange={this.handleChange("seopage.filter_text")}
        value={this.props.seoPageFormState.seopage.filter_text}
        margin="normal"
        error={invalidFields.includes("filter_text")}
      />
    );
  }

  renderFilterFreeEventsCheckbox() {
    return (
      <>
        <FormGroup>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={this.props.seoPageFormState.seopage.filter_free_only}
                onChange={this.handleCheckboxChange("seopage.filter_free_only")}
              />
            }
            label={intl.get("seopage_form.filter.label_free")}
          />
        </FormGroup>
      </>
    );
  }

  renderFilterTags() {
    return (
      <Grid item container direction="column" sx={AppStyles.noRightPadding}>
        <SectionLabel required={true}>{intl.get("seopage_form.filter.label_tags")}</SectionLabel>
        <MySelect
          options={this.props.seoPageFormState.tags.map((t) => ({
            value: t.tag_id,
            label: t.name,
          }))}
          isMulti={true}
          value={
            this.props.seoPageFormState.seopage.filter_tags.map((t) => ({
              value: t.tag_id,
              label: t.name,
            })) as any
          }
          isClearable
          isSearchable={true}
          placeholder={intl.get("seopage_form.filter.placeholder_tags")}
          onChange={(value) => {
            this.props.actions.updateSeoPageFormField({
              prop: "seopage.filter_tags",
              value: value ? value.map((t: any) => ({ tag_id: t.value, name: t.label })) : [],
            });
          }}
        />
      </Grid>
    );
  }

  hasCategorySelected(category_id: string): boolean {
    // @ts-ignore
    const index = this.props.seoPageFormState.seopage.filter_categories.findIndex(
      (category: any) => category.category_id === category_id,
    );
    return index !== -1;
  }

  handleCategorySelection(value: BCEventCategory) {
    this.props.actions.toggleSeoPageCategory(value);
  }

  renderFilterCity() {
    return (
      <>
        <SectionLabel required={false}>{intl.get("seopage_form.filter.label_city")}</SectionLabel>
        <MySelect
          options={this.props.seoPageFormState.cities.map((t) => ({
            value: t,
            label: t,
          }))}
          value={
            this.props.seoPageFormState.seopage.filter_city
              ? {
                  value: this.props.seoPageFormState.seopage.filter_city,
                  label: this.props.seoPageFormState.seopage.filter_city,
                }
              : null
          }
          onChange={(value) => {
            this.props.actions.updateSeoPageFormField({
              prop: "seopage.filter_city",
              value: value ? value.value : "",
            });
          }}
          placeholder={intl.get("seopage_form.filter.city_hint")}
          isClearable
          isSearchable={true}
        />
      </>
    );
  }

  renderFilterCategories() {
    return (
      <>
        <SectionLabel required>{intl.get("seopage_form.filter.label_categories")}</SectionLabel>
        <Box sx={AppStyles.formInputContainer}>
          <FormGroup>
            {this.props.seoPageFormState.categories != null &&
              this.props.seoPageFormState.categories.map((row) => {
                return (
                  <FormControlLabel
                    key={row.identifier}
                    control={
                      <Checkbox
                        disabled={this.props.readonly}
                        checked={this.hasCategorySelected(row.category_id)}
                        color="primary"
                        onChange={() => {
                          this.handleCategorySelection(row);
                        }}
                        value={row.category_id}
                      />
                    }
                    label={intl.get(`categories.${row.identifier}`)}
                  />
                );
              })}
          </FormGroup>
        </Box>
      </>
    );
  }

  changeTab = (event: any, tab: any) => {
    this.setState({ tab });
  };

  render() {
    if (this.props.pageState.state === PageStateType.Loading) {
      return <></>;
    }

    let invalidFields: string[] = [];
    if (this.props.seoPageFormState.error && this.props.seoPageFormState.error.validationErrors) {
      invalidFields = this.props.seoPageFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }

    return (
      <>
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          {this.props.children}

          <Paper sx={AppStyles.innerContentPaper}>
            <Tabs value={this.state.tab} indicatorColor="primary" textColor="primary" onChange={this.changeTab}>
              <Tab disabled={this.props.readonly} label={intl.get("seopage_form.tab_info")} />
              <Tab disabled={this.props.readonly} label={intl.get("seopage_form.tab_filter")} />
            </Tabs>

            {this.state.tab === 0 && (
              <>
                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderActiveCheckbox()}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderTitle(invalidFields)}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderUrl(invalidFields)}
                  </Grid>
                </Grid>
                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderHeadline(invalidFields)}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderMetaDescription(invalidFields)}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderDescription(invalidFields)}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderBottomDescription(invalidFields)}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderInternalNotes(invalidFields)}
                  </Grid>
                </Grid>
              </>
            )}

            {this.state.tab === 1 && (
              <>
                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderFilterText(invalidFields)}
                  </Grid>
                </Grid>
                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderFilterTags()}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderFilterFreeEventsCheckbox()}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderFilterCategories()}
                  </Grid>
                </Grid>

                <Grid item container direction="row" sx={AppStyles.detailForm}>
                  <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRow}>
                    {this.renderFilterCity()}
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    seoPageFormState: state.seoPageFormState,
    seoPageState: state.seoPageState,
    pageState: state.pageState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(SeoPageActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SeoPageEditForm);
