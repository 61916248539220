import BookingDrawerLayout, { BookingDrawerLayoutProps } from "../BookingDrawerLayout";
import { useEffect, useState } from "react";
import PremiumUpgradeDrawerPlanSelectionPage from "./PremiumUpgradeDrawerPlanSelectionPage";
import useDialogReset from "../../dialog/useDialogReset";
import { useDispatch } from "react-redux";
import * as ProductActions from "../../../actions/company.product.actions";
import GeneralProductDrawerHeader from "../components/GeneralProductDrawerHeader";
import PurchaseConfirmationFlow from "../PurchaseConfirmationFlow";
import ProductInfoSection from "../ProductInfoSection";
import usePaymentProduct from "../../../hooks/usePaymentProduct";
import { useDialogContext } from "../../dialog/DialogContext";
import useSelectedCompanyId from "../../../hooks/useSelectedCompanyId";
import BulkEventUpgradeForProductDrawerPage from "../bulkEventUpgrade/BulkEventUpgradeForProductDrawerPage";
import PremiumDrawerInfoSection from "./PremiumDrawerInfoSection";
import BookingDrawerErrorContent from "../components/BookingDrawerErrorContent";

type BookingStep = "plan_selection" | "confirm_booking" | "booking_success";

export default function PremiumUpgradeDrawerContent(props: BookingDrawerLayoutProps) {
  const { handler } = useDialogContext<any>();
  const companyId = useSelectedCompanyId();

  const [step, setStep] = useState<BookingStep>("plan_selection");
  const [selectedProductSku, setSelectedProductSku] = useState<string>("standard_premium_yearly");

  const { selectedProduct, error } = usePaymentProduct(selectedProductSku);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(ProductActions.loadCompanyProductInformation(true, companyId));
  }, [handler.payload]);

  useDialogReset(handler, () => {
    setStep("plan_selection");
  });

  if (error) {
    return <BookingDrawerErrorContent error={error} infoSection={<PremiumDrawerInfoSection />} />;
  }

  if (step === "confirm_booking") {
    return (
      <PurchaseConfirmationFlow
        onCancel={() => setStep("plan_selection")}
        onSuccess={() => setStep("booking_success")}
        selectedProduct={selectedProduct}
      />
    );
  }

  return (
    <BookingDrawerLayout {...props} infoSection={<ProductInfoSection product={selectedProduct} />}>
      <GeneralProductDrawerHeader />
      {step === "plan_selection" && (
        <PremiumUpgradeDrawerPlanSelectionPage
          value={selectedProductSku}
          onChange={setSelectedProductSku}
          onBookingButtonClicked={() => setStep("confirm_booking")}
        />
      )}
      {step === "booking_success" && (
        <BulkEventUpgradeForProductDrawerPage
          product={selectedProduct}
          onSkip={handler.close}
          onSuccess={handler.close}
          originatingEvent={handler.payload.event}
        />
      )}
    </BookingDrawerLayout>
  );
}
