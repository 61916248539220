import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../reducers";
import * as CompanyReportingActions from "../../actions/company.reporting.actions";
import { AuthenticationState } from "../../model/authenticationState";
import { CompanyState } from "../../model/companyState";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { CompanyReportingState } from "../../model/company.reporting.state";
import intl from "react-intl-universal";
import { EventReportingTimeframe, ReportingEventInfo } from "../../model/company.reporting.result";
import { Progress } from "../../components/Progress";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { PageTracker } from "../../util/pageTracker";
import AppStyles from "../../styles/appStyles";

const classes = {
  innerContent: (theme: Theme) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 1000,
    overflow: "scroll",
    flexGrow: 1,
    paddingTop: "65px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "56px",
    },
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f2f2f2",
  }),
  innerContentError: (theme: Theme) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "#f2f2f2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.colors.darkGrey,
  }),
  clickCountContainer: (theme: Theme) => ({
    width: "150px",
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    border: "2px solid",
    margin: 8,
    textAlign: "center",
    padding: "16px",
    marginTop: "16px",
    color: theme.palette.primary.main,
  }),
  clickCountLabel: (theme: Theme) => ({
    fontSize: theme.fontSizes.biggerFont,
    fontWeight: 800,
  }),
  upgradeContainer: {
    width: "250px",
    padding: "16px",
    margin: "8px",
    textAlign: "center",
  },
  upgradeText: {
    color: "#4caf50",
  },
  upgradeCount: (theme: Theme) => ({
    fontSize: theme.fontSizes.biggerFont,
    fontWeight: 800,
    color: "#4caf50",
  }),
  upgradeBanner: {
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingTop: "8px",
    paddingBottom: "8px",
    marginTop: "2px",
    backgroundImage: 'url("/plan_banner_balken.png")',
    backgroundSize: "100% 100%",
    resize: "both",
    height: "50px",
  },
  reportingIssuesWarningText: (theme: Theme) => ({
    color: "#FFFFFF",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }),
  reportingIssuesWarningContainer: (theme: Theme) => ({
    color: "white",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    padding: 2,
    height: "100%",
    marginLeft: "24px",
    marginRight: "24px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
  }),
  reportingIssuesWarningSubContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export interface Props {
  companyState: CompanyState;
  authState: AuthenticationState;
  companyReportingState: CompanyReportingState;
  actions: typeof CompanyReportingActions;
}

export interface State {
  timeframe: string;
  expandedEvents: string[];
}

class CompanyReportingPage extends React.Component<Props, State> {
  state = {
    timeframe: EventReportingTimeframe.LastYear.valueOf(),
    expandedEvents: [],
  };

  componentDidMount(): void {
    this.props.actions.fetchReportingResult(this.props.companyState.selected_company_id, this.state.timeframe);
  }

  renderLoadingState() {
    if (this.props.companyReportingState.loading) {
      return (
        <Box sx={classes.innerContent}>
          <Progress />
        </Box>
      );
    }
    return null;
  }

  renderFailedState() {
    if (this.props.companyReportingState.failed) {
      return (
        <Box sx={classes.innerContentError}>
          <ErrorIcon />
          <div>{intl.get("loading_failed")}</div>
        </Box>
      );
    }
    return null;
  }

  renderChartForEvent(event: ReportingEventInfo) {
    const data = this.props.companyReportingState.reportingResult.entries.map((value) => {
      return {
        date: value.date_value,
        Klicks: value.event_entries[event.event_id] ?? 0,
      };
    });

    return (
      <TableRow key={`${event.event_id}_chart`} hover onClick={() => {}}>
        <TableCell scope="row" padding="none" sx={AppStyles.tableCell} colSpan={2} style={{ height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{
                top: 16,
                right: 16,
                left: 16,
                bottom: 32,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" fontSize={12} stroke="#6E7CE0" />
              <YAxis fontSize={12} stroke="#6E7CE0" name={"Klicks"} />
              <Legend />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="Klicks"
                name={`Klicks für ${event.title}`}
                stroke="#6E7CE0"
                strokeWidth={2}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </TableCell>
      </TableRow>
    );
  }

  renderEventReport(event: ReportingEventInfo) {
    return (
      <>
        <TableRow
          key={event.event_id}
          hover
          onClick={() => {
            const index = this.state.expandedEvents.indexOf(event.event_id);
            if (index === -1) {
              const newExpandedEvents = this.state.expandedEvents;
              newExpandedEvents.push(event.event_id);
              this.setState({ expandedEvents: newExpandedEvents });
            } else {
              const newExpandedEvents = this.state.expandedEvents;
              newExpandedEvents.splice(index, 1);
              this.setState({ expandedEvents: newExpandedEvents });
            }
          }}
        >
          <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
            <Grid container direction={"column"} justifyContent={"center"}>
              <Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                <Grid item>{event.title}</Grid>
                {event.premium && (
                  <Grid item>
                    <Chip
                      label={"Premium Event"}
                      variant="outlined"
                      style={{ marginLeft: "16px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
                    />
                  </Grid>
                )}
                {event.premium_plus && (
                  <Grid item>
                    <Chip
                      label={"Premium Plus Event"}
                      variant="outlined"
                      style={{ marginLeft: "16px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
                    />
                  </Grid>
                )}
                {event.hero && (
                  <Grid item>
                    <Chip
                      label={"Hero"}
                      variant="outlined"
                      style={{ marginLeft: "16px", color: "#FFFFFF", backgroundColor: "#6E7CE0" }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item style={{ color: "#6E7CE0", textDecoration: "underline", marginTop: 4 }}>
                {this.state.expandedEvents.indexOf(event.event_id) === -1 && "Klicken für mehr Details..."}
                {this.state.expandedEvents.indexOf(event.event_id) !== -1 && "Klicken für weniger Details..."}
              </Grid>
            </Grid>
          </TableCell>

          <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid
                item
                sx={classes.clickCountContainer}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sx={classes.clickCountLabel}>
                  {event.total_views}
                </Grid>
                <Grid item>Klicks</Grid>
              </Grid>
              <Grid
                item
                sx={classes.upgradeContainer}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ opacity: event.benefits_from_upgrade ? 1 : 0 }}
              >
                <Grid item sx={classes.upgradeText}>
                  Durchschnittswert unserer Top 10 Premium Partner:
                </Grid>
                <Grid item sx={classes.upgradeCount}>
                  {event.potential_views !== 0 ? event.potential_views : "10.000"} Klicks
                </Grid>
                <Grid item sx={classes.upgradeText}>
                  Erreiche auch Du mehr und buche jetzt DEIN Paket!
                </Grid>
                {event.benefits_from_upgrade &&
                  this.props.companyState.company.permissions.indexOf("EDIT_COMPANY_PAYMENT_DATA") !== -1 && (
                    <Grid item sx={classes.upgradeBanner}>
                      <Link
                        to={`/companies/${this.props.companyState.selected_company_id}/plan`}
                        style={{ textDecoration: "none" }}
                      >
                        <Grid
                          container
                          direction={"row"}
                          alignItems={"center"}
                          alignContent={"center"}
                          justifyContent={"space-between"}
                          style={{ height: "100%" }}
                        >
                          <Grid
                            item
                            direction={"row"}
                            alignItems={"center"}
                            alignContent={"center"}
                            style={{ height: "100%", display: "flex" }}
                          >
                            <Grid item style={{ color: "#FFFFFF" }}>
                              Jetzt Premium buchen
                            </Grid>
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        {this.state.expandedEvents.indexOf(event.event_id) !== -1 && this.renderChartForEvent(event)}
      </>
    );
  }

  renderResults() {
    if (this.props.companyReportingState.reportingResult) {
      const mappedEvents = this.props.companyReportingState.reportingResult.events.map((value) =>
        this.renderEventReport(value),
      );

      return (
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                  Event
                </TableCell>

                <TableCell padding="none" sx={AppStyles.tableHeaderCell} style={{ width: 450, textAlign: "center" }}>
                  Klicks
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mappedEvents}</TableBody>
          </Table>
        </Paper>
      );
    }
    return null;
  }

  renderInfoAboutReportingIssues() {
    return (
      <>
        <Grid sx={classes.reportingIssuesWarningContainer}>
          <Grid sx={classes.reportingIssuesWarningSubContainer}>
            <Grid item container direction={"column"} sx={classes.reportingIssuesWarningText}>
              <span>
                Bitte beachten Sie, dass es aufgrund einer Umstellung des Cookie-Banners in den letzten Monaten zu
                Abweichungen in den Statistiken kommen kann. Genauere Auskünfte erhalten Sie unter{" "}
                <a
                  href="mailto:support@blue-cherries.com"
                  style={{ color: "white", fontWeight: "bold", textDecoration: "underline" }}
                >
                  support@blue-cherries.com
                </a>
                .
              </span>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const mappedOptions = [
      EventReportingTimeframe.LastMonth,
      EventReportingTimeframe.LastYear,
      EventReportingTimeframe.Full,
    ].map((option) => (
      <FormControlLabel
        value={option}
        disabled={this.props.companyReportingState.loading}
        control={<Radio color="primary" checked={this.state.timeframe === option} />}
        label={intl.get(`reporting_timeframe.${option}`)}
      />
    ));

    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          {this.renderInfoAboutReportingIssues()}
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item sx={AppStyles.headerLeft}>
                <HeaderLabel>Event-Reporting</HeaderLabel>
              </Grid>
              <Grid item sx={AppStyles.headerRight}>
                <div>
                  <RadioGroup
                    color="primary"
                    aria-label="Type"
                    name="type"
                    onChange={(event) => {
                      this.setState({ timeframe: event.target.value });

                      this.props.actions.fetchReportingResult(
                        this.props.companyState.selected_company_id,
                        event.target.value,
                      );
                    }}
                    row
                  >
                    {mappedOptions}
                  </RadioGroup>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              sx={AppStyles.innerContentWithHeaderMargins}
              direction="row"
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <Grid item>
                <InfoIcon color={"primary"} style={{ marginRight: 8 }} />
              </Grid>
              <Grid item>Klicken Sie auf ein Event, um mehr Details zum Verlauf der Klicks zu sehen.</Grid>
            </Grid>

            {this.renderLoadingState()}
            {this.renderFailedState()}
            {this.renderResults()}
          </>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    authState: state.authenticationState,
    companyReportingState: state.companyReportingState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyReportingActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyReportingPage);
