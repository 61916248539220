import * as React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../../../model/eventFormState";
import { CompanyState } from "../../../../model/companyState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import EventPricingInfoEdit from "./pricing/EventPricingInfoEdit";
import EventBookingInfoEdit from "./pricing/EventBookingInfoEdit";
import EventDiscountInfoEdit from "./pricing/EventDiscountInfoEdit";
import { DefaultButton } from "../../../../components/Buttons";
import EuroIcon from "@mui/icons-material/Euro";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventPriceInfoTab extends React.Component<Props> {
  numberOfPremiumSlotsForCompany(): number {
    return (
      (this.props.companyState.company?.premium_slots ?? 0) + (this.props.companyState.company?.premium_plus_slots ?? 0)
    );
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <Stack>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.pricing_price_info`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventPricingInfoEdit
              eventFormState={this.props.eventFormState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.pricing_booking_info`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventBookingInfoEdit
              eventFormState={this.props.eventFormState}
              authenticationState={this.props.authenticationState}
              eventFormActions={this.props.eventFormActions}
            />
          </Grid>
        </Paper>

        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.pricing_discount_info`)}</b>
          </Typography>
        </Grid>
        {this.numberOfPremiumSlotsForCompany() === 0 && (
          <>
            <Grid
              item
              container
              direction={"row"}
              sx={(theme) => ({
                ...AppStyles.detailFormWithoutBottomPadding,
                color: theme.palette.error.main,
                paddingTop: 0,
              })}
            >
              <Typography>{intl.get(`event_form.discount_premium_hint`)}</Typography>
            </Grid>
            <Grid item xs={12} container direction={"row"} sx={AppStyles.detailFormRowNoBorder}>
              <DefaultButton
                onClick={(event: any) => {
                  event.stopPropagation(); //TODO: premium booking?
                }}
              >
                <EuroIcon sx={AppStyles.buttonLeftIcon} />
                {intl.get(`event_form.discount_book_premium_button`)}
              </DefaultButton>
            </Grid>
          </>
        )}
        {this.numberOfPremiumSlotsForCompany() > 0 && (
          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm}>
              <EventDiscountInfoEdit
                eventFormState={this.props.eventFormState}
                companyState={this.props.companyState}
                authenticationState={this.props.authenticationState}
                eventFormActions={this.props.eventFormActions}
              />
            </Grid>
          </Paper>
        )}
      </Stack>
    );
  }
}

export default EventPriceInfoTab;
