import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const NavArrowLeftSmallIcon = (props) => (
  <SvgIcon width="7.034" height="12" viewBox="0 0 7.034 12" {...props}>
    <g id="Gruppe_159" data-name="Gruppe 159" transform="translate(7.034) rotate(90)">
      <g id="Gruppe_158" data-name="Gruppe 158">
        <path
          id="Pfad_28"
          data-name="Pfad 28"
          d="M6,7.034a.828.828,0,0,1-.6-.254L.231,1.4A.828.828,0,1,1,1.424.254L6,5.013,10.576.254A.828.828,0,1,1,11.769,1.4L6.6,6.781a.828.828,0,0,1-.6.254"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default NavArrowLeftSmallIcon;
