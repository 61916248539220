import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Stack } from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import { connect } from "react-redux";
import { CancelButton, DefaultButton } from "../../../../components/Buttons";
import { EventFormState } from "../../../../model/eventFormState";
import { RootState } from "../../../../reducers";
import * as EventFormActions from "../../../../actions/eventForm";
import * as MediaActions from "../../../../actions/media";
import { CompanyState } from "../../../../model/companyState";
import { MediaState } from "../../../../model/mediaState";
import { Media, Photo, UploadInfo } from "../../../../model/media";
import { cloudinaryUploader } from "../../../../util/cloudinaryUploader";
import { isMobileSafari, isSafari } from "react-device-detect";
import StarIcon from "@mui/icons-material/Star";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import ArrayUtils from "../../../../util/ArrayUtils";
import AppStyles from "../../../../styles/appStyles";
import MediaImageWrapper from "../../media/MediaImageWrapper";
import PhotoAlbum from "react-photo-album";

const classes = {
  gridList: {
    width: "100%",
    height: "100%",
  },
};

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  mediaState: MediaState;
  eventFormActions: typeof EventFormActions;
  mediaActions: typeof MediaActions;
}

export interface State {}

class EventMediaEdit extends React.Component<Props> {
  state = {
    companyMediaDialogOpen: false,
    uploadInfo: new UploadInfo(),
  };

  removeMediaItem(mediaItem: Media) {
    this.props.mediaActions.removeMediaItemFromEvent(mediaItem);

    if (mediaItem.uploaded_in_event_creation) {
      this.props.mediaActions.deleteMediaItemForCompany(
        this.props.companyState.selected_company_id,
        mediaItem.media_id,
      );
    }
  }

  onMakeAsCover(media: Media) {
    const oldIndex = this.props.eventFormState.event.media_items.indexOf(media);
    ArrayUtils.moveWithinInstance(this.props.eventFormState.event.media_items, oldIndex, 0);
    this.setState({});
  }

  renderImage(photo: Photo) {
    if (photo.media.type !== "video" || isSafari || isMobileSafari) {
      return (
        <img alt="" style={{ alignContent: "center", width: "100%", height: "100%" }} {...photo} onClick={() => {}} />
      );
    }

    if (photo.media.type === "video" && !isSafari && !isMobileSafari) {
      return <video loop autoPlay muted playsInline style={{ alignContent: "center" }} {...photo} onClick={() => {}} />;
    }
    return null;
  }

  renderSinglePhoto(photo: Photo, index: number) {
    const showCoverButton = this.props.eventFormState.readonly === false && index !== 0;
    const showMoveUpButton = this.props.eventFormState.readonly === false && index !== 0;
    const showMoveDownButton =
      this.props.eventFormState.readonly === false && index !== this.props.eventFormState.event.media_items.length - 1;

    return (
      <>
        <Grid item container direction={"row"}>
          <Grid
            item
            container
            xs={4}
            justifyContent={"flex-start"}
            alignItems={"stretch"}
            sx={AppStyles.detailContentWithPadding}
          >
            {this.renderImage(photo)}
          </Grid>
          <Grid
            item
            container
            xs={8}
            sx={AppStyles.detailContentWithPadding}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            {showCoverButton && (
              <DefaultButton
                onClick={() => {
                  this.onMakeAsCover(photo.media);
                }}
              >
                <StarIcon sx={AppStyles.buttonLeftIcon} />
                Als Cover-Photo
              </DefaultButton>
            )}
            {showMoveUpButton && (
              <DefaultButton
                style={{
                  marginTop: showCoverButton ? 10 : 0,
                }}
                color={"secondary"}
                onClick={() => {
                  this.onSortEnd({ oldIndex: index, newIndex: index - 1 });
                }}
              >
                <ArrowUpIcon sx={AppStyles.buttonLeftIcon} />
                Nach oben
              </DefaultButton>
            )}
            {showMoveDownButton && (
              <DefaultButton
                style={{
                  marginTop: showCoverButton || showMoveUpButton ? 10 : 0,
                }}
                color={"secondary"}
                onClick={() => {
                  this.onSortEnd({ oldIndex: index, newIndex: index + 1 });
                }}
              >
                <ArrowDownIcon sx={AppStyles.buttonLeftIcon} />
                Nach unten
              </DefaultButton>
            )}
            {this.props.eventFormState.readonly === false && (
              <DefaultButton
                style={{
                  marginTop: showCoverButton || showMoveUpButton || showMoveDownButton ? 10 : 0,
                }}
                color={"error"}
                onClick={() => {
                  this.removeMediaItem(photo.media);
                }}
              >
                Aus Event entfernen
              </DefaultButton>
            )}
          </Grid>
          <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
            <Divider />
          </Grid>
        </Grid>
      </>
    );
  }

  onSortEnd({ oldIndex, newIndex }: any) {
    ArrayUtils.moveWithinInstance(this.props.eventFormState.event.media_items, oldIndex, newIndex);
    this.setState({});
  }

  selectAndAddCompanyMediaItem(item: Media) {
    this.props.mediaActions.addMediaItemToEvent({
      ...item,
      uploaded_in_event_creation: false,
    });
    this.setState({ companyMediaDialogOpen: false });
  }

  renderAddCompanyMediaDialog() {
    if (this.state.companyMediaDialogOpen) {
      let mediaItems: Media[] = [];
      if (this.props.mediaState.media) {
        mediaItems = this.props.mediaState.media;
      }
      mediaItems = mediaItems.filter((mediaItem) => {
        const index = this.props.eventFormState.event.media_items.findIndex(
          (value) => value.media_id === mediaItem.media_id,
        );
        return index === -1;
      });
      const images = mediaItems.map((mediaItem) => {
        return new Photo(mediaItem);
      });

      return (
        <Dialog
          open={this.state.companyMediaDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.get("event_form.select_image_from_media_dialog_title")}
          </DialogTitle>
          <DialogContent>
            <PhotoAlbum
              layout={"rows"}
              photos={images}
              targetRowHeight={240}
              rowConstraints={{ singleRowMaxHeight: 300 }}
              renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                <MediaImageWrapper
                  style={wrapperStyle}
                  photo={photo}
                  photoIndex={images.indexOf(photo)}
                  onClick={() => this.selectAndAddCompanyMediaItem(mediaItems[images.indexOf(photo)])}
                  key={photo.media.media_id}
                  disableControls={false}
                  managementView={false}
                  direction={"row"}
                >
                  {renderDefaultPhoto({ wrapped: true })}
                </MediaImageWrapper>
              )}
            />
          </DialogContent>
          <DialogActions>
            <CancelButton
              onClick={() => {
                this.setState({ companyMediaDialogOpen: false });
              }}
              title={intl.get("event_form.button_cancel_image_selection")}
            />
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

  render() {
    const disabledControls = this.props.eventFormState.readonly;

    const images = this.props.eventFormState.event.media_items.map((mediaItem) => {
      return new Photo(mediaItem);
    });

    const mappedImages = images.map((value, index) => this.renderSinglePhoto(value, index));

    return (
      <Stack>
        <Grid item container direction="row" sx={AppStyles.detailContent}>
          {disabledControls === false && (
            <Grid item container xs={12} sx={AppStyles.detailContentWithPadding} justifyContent={"center"}>
              <DefaultButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  cloudinaryUploader(
                    this.state.uploadInfo,
                    this.props.companyState.selected_company_id,
                    (uploadInfo: any) => {
                      this.setState({ ...this.state, uploadInfo });
                    },
                    (info: any) => {
                      this.props.mediaActions.uploadCloudinaryResult(this.props.companyState.selected_company_id, info);
                    },
                  );
                }}
                title={intl.get("event_form.button_upload_new_image")}
              />

              <DefaultButton
                style={{ marginLeft: 16 }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  this.props.mediaActions.fetchMediaForCompany(this.props.companyState.selected_company_id);
                  this.setState({ companyMediaDialogOpen: true });
                }}
                title={intl.get("event_form.button_select_image_from_media")}
              />
            </Grid>
          )}
        </Grid>

        <Paper sx={AppStyles.innerContentPaper}>
          <Box sx={classes.gridList}>{mappedImages}</Box>
        </Paper>
        {this.renderAddCompanyMediaDialog()}
      </Stack>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    eventFormState: state.eventFormState,
    companyState: state.companyState,
    mediaState: state.mediaState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    eventFormActions: bindActionCreators(EventFormActions as any, dispatch),
    mediaActions: bindActionCreators(MediaActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventMediaEdit);
