import * as React from "react";
import ReactDOM from "react-dom/client";
import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { CustomProvider } from "rsuite";
import deRsuiteLocalization from "rsuite/esm/locales/de_DE";
import App from "./App";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, createStore, Store } from "redux";
import thunk from "redux-thunk";
import rootReducer, { RootState } from "./reducers";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import de from "date-fns/locale/de";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, responsiveFontSizes } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import * as Cherriestheme from "./theme";
import { LicenseInfo } from "@mui/x-license-pro";

const theme = Cherriestheme.theme;

LicenseInfo.setLicenseKey(
  `74c03297ab94f6e4473d663a1a349e30Tz01NTYzMCxFPTE3MDE4ODE2Nzg1NjUsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=`,
);

if (window.location.toString().includes("close_after_finished_authorization=true")) {
  window.close();
}

const matomoInstance = createInstance({
  urlBase: "https://matomo.blue-cherries.com",
  siteId: Number.parseInt(process.env.REACT_APP_MATOMO_SITE_ID!),
  disabled: Number.parseInt(process.env.REACT_APP_MATOMO_SITE_ID!) === -1,
  linkTracking: false,
});

let middleware;

if (process.env.NODE_ENV === "development") {
  const logger = (createLogger as any)();
  middleware = composeWithDevTools(applyMiddleware(logger, thunk));
} else {
  middleware = applyMiddleware(thunk);
}

const store = createStore(rootReducer, {}, middleware) as Store<RootState>;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MatomoProvider value={matomoInstance}>
      <Provider store={store}>
        <CustomProvider locale={deRsuiteLocalization}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={responsiveFontSizes(theme)}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </CustomProvider>
      </Provider>
    </MatomoProvider>
  </React.StrictMode>,
);
