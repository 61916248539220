import { Stack } from "@mui/material";
import EventEditTransferAction from "./EventEditTransferAction";
import EventReviewHeaderActions from "./EventReviewHeaderActions";
import EventEditStatusSelect from "./EventEditStatusSelect";
import { DefaultButton } from "../../../components/Buttons";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AppStyles from "../../../styles/appStyles";
import * as React from "react";
import useUser from "../../../hooks/useUser";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { EventFormState } from "../../../model/eventFormState";
import { useNavigate } from "react-router-dom";
import { BCEventStatus } from "../../../model/event";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";

interface Props {
  onSave: () => void;
  rejectionDescription?: string;
  eventStatus: BCEventStatus;
  onEventStatusChange: (newStatus: BCEventStatus) => void;
  onSubmitForReview: () => void;
  companyId: string;
}

type UiControlState = "default" | "disabled" | "hidden";

interface UIState {
  saveButton: UiControlState;
  stateSelect: UiControlState;
}

export default function EventEditHeaderActions({
  onSave,
  rejectionDescription,
  eventStatus,
  onEventStatusChange,
  onSubmitForReview,
  companyId,
}: Props) {
  const navigate = useNavigate();

  const user = useUser();

  const eventFormState = useSelector<RootState, EventFormState>((rootState) => {
    return rootState.eventFormState;
  });

  function closePage(): void {
    navigate(-1);
  }

  const isCreate = !Boolean(eventFormState.event_id);
  const disableControls = eventFormState.readonly;

  const canPublishRejectedEvents = user.hasOneOfRoles(["ADMIN", "INTERNAL_SALES_MANAGER", "REVIEWER"]);

  let uiState: UIState;

  if (isCreate) {
    uiState = {
      saveButton: "default",
      stateSelect: "default",
    };
  } else {
    switch (eventStatus) {
      case BCEventStatus.DRAFT:
      case BCEventStatus.PUBLISHED:
      case BCEventStatus.FINISHED:
      case BCEventStatus.DELETED:
        uiState = {
          saveButton: "default",
          stateSelect: "default",
        };
        break;
      case BCEventStatus.READY_FOR_REVIEW:
        uiState = {
          saveButton: "default",
          stateSelect: "disabled",
        };
        break;
      case BCEventStatus.REJECTED:
        if (canPublishRejectedEvents) {
          uiState = {
            saveButton: "default",
            stateSelect: "default",
          };
        } else {
          uiState = {
            saveButton: "hidden",
            stateSelect: "hidden",
          };
        }
        break;
      default: {
        // this shouldn't happen unless we missed to handle a state
        // set a value anyways to prevent crashes
        uiState = {
          saveButton: "default",
          stateSelect: "default",
        };
      }
    }
  }

  if (eventStatus === BCEventStatus.REJECTED && !canPublishRejectedEvents) {
    return (
      <>
        <Stack direction={"row"} gap={"24px"}>
          <DefaultButton
            color={"primary"}
            disabled={uiState.saveButton === "disabled" || disableControls}
            onClick={() => {
              onSave();
            }}
          >
            <SaveAltIcon sx={AppStyles.buttonLeftIcon} />
            {"Änderungen Speichern"}
          </DefaultButton>
          <DefaultButton
            color={"success"}
            disabled={uiState.saveButton === "disabled" || disableControls}
            onClick={() => {
              onSubmitForReview();
            }}
          >
            <FactCheckOutlinedIcon sx={AppStyles.buttonLeftIcon} />
            {"Zur erneuten Überprüfung übermitteln"}
          </DefaultButton>
        </Stack>
      </>
    );
  }

  return (
    <>
      {disableControls === false && (
        <Stack direction={"row"} gap={"24px"}>
          {user.hasPermission("TRANSFER_EVENTS") && <EventEditTransferAction />}

          {user.hasPermission("REVIEW_COMPANY_EVENTS") && (
            <EventReviewHeaderActions
              onApproved={closePage}
              onRejected={closePage}
              rejectionDescription={rejectionDescription}
              companyId={companyId}
            />
          )}
        </Stack>
      )}

      <Stack direction={"row"} gap={"24px"}>
        {uiState.stateSelect !== "hidden" && (
          <EventEditStatusSelect
            disabled={uiState.stateSelect === "disabled" || disableControls}
            value={eventStatus || eventFormState.event.status}
            onChange={(newStatus) => {
              onEventStatusChange(newStatus);
            }}
          />
        )}

        {uiState.saveButton !== "hidden" && (
          <DefaultButton
            color={"success"}
            disabled={uiState.saveButton === "disabled" || disableControls}
            onClick={() => {
              onSave();
            }}
          >
            <SaveAltIcon sx={AppStyles.buttonLeftIcon} />
            {isCreate ? "Erstellen" : "Speichern"}
          </DefaultButton>
        )}
      </Stack>
    </>
  );
}
