import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";
import { BCEvent } from "../model/event";

export const loadCompanyProductInformation =
  (loadAvailableProducts: boolean, company_id: string) => async (dispatch: Dispatch) => {
    try {
      let availableProducts = null;
      let status = null;
      const activeProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/active_products`);
      const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);
      if (loadAvailableProducts) {
        const availableProductsResponse = await axios.get(
          `/v2/api/b2b/private/companies/${company_id}/available_products`,
        );
        availableProducts = availableProductsResponse.data.products;
        status = availableProductsResponse.data.status;
      }

      dispatch({
        type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
        payload: {
          data: true,
          activeProducts: activeProductsResponse.data.active_products,
          credits: creditsResponse.data.credits,
          availableProducts,
          status,
        },
      });
    } catch (error) {}
  };

export const loadCompanyCredits = (company_id: string) => async (dispatch: Dispatch) => {
  try {
    const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);

    dispatch({
      type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
      payload: {
        data: true,
        credits: creditsResponse.data.credits,
      },
    });
  } catch (error) {}
};

export const grantCredit =
  (company_id: string, credit_type: string, finishCallback: any) => async (dispatch: Dispatch) => {
    try {
      await axios.put(`/v2/api/b2b/private/companies/${company_id}/credits/grant`, { credit_type });

      const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);

      dispatch({
        type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
        payload: {
          data: true,
          credits: creditsResponse.data.credits,
        },
      });

      finishCallback();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const redeemCredit =
  (company_id: string, credit_type: string, finishCallback: any) => async (dispatch: Dispatch) => {
    try {
      await axios.put(`/v2/api/b2b/private/companies/${company_id}/credits/redeem`, {
        credit_type,
      });

      const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);

      dispatch({
        type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
        payload: {
          data: true,
          credits: creditsResponse.data.credits,
        },
      });

      finishCallback();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const cancelProduct = (company_id: string, product_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.PAGE_SAVING });

  try {
    await axios.delete(`/v2/api/b2b/private/companies/${company_id}/active_products/${product_id}`);

    const activeProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/active_products`);
    const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);
    const availableProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/available_products`);

    dispatch({
      type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
      payload: {
        data: true,
        activeProducts: activeProductsResponse.data.active_products,
        credits: creditsResponse.data.credits,
        availableProducts: availableProductsResponse.data.products,
      },
    });

    const companyInformationRes = await axios.get(`/v2/api/b2b/private/companies/${company_id}`);

    dispatch({
      type: ActionType.FETCH_COMPANY,
      payload: { data: true, company: companyInformationRes.data },
    });

    dispatch({ type: ActionType.PAGE_SAVED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_SAVING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const activateProduct =
  (company_id: string, product_id: string, event: BCEvent = null) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });
    try {
      const res = await axios.post(`/v2/api/b2b/private/companies/${company_id}/products/${product_id}/book`, {
        event_id: event?.event_id,
      });

      dispatch({
        type: ActionType.UPDATE_COMPANY_SLOT_INFO,
        payload: {
          slotInfo: res.data.slot_info,
        },
      });

      const activeProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/active_products`);
      const creditsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/credits`);
      const availableProductsResponse = await axios.get(
        `/v2/api/b2b/private/companies/${company_id}/available_products`,
      );

      dispatch({
        type: ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION,
        payload: {
          data: true,
          activeProducts: activeProductsResponse.data.active_products,
          credits: creditsResponse.data.credits,
          availableProducts: availableProductsResponse.data.products,
        },
      });

      const invoiceRes = await axios.get(`/v2/api/b2b/private/companies/${company_id}/invoices?page=${1}&size=${10}`);

      dispatch({
        type: ActionType.FETCH_COMPANY_INVOICES,
        payload: {
          data: true,
          invoices: invoiceRes.data.invoices,
          paging: invoiceRes.data.paging,
        },
      });

      const companyInformationRes = await axios.get(`/v2/api/b2b/private/companies/${company_id}`);

      dispatch({
        type: ActionType.FETCH_COMPANY,
        payload: { data: true, company: companyInformationRes.data },
      });

      dispatch({ type: ActionType.PAGE_SAVED });

      return true;
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
      return false;
    }
  };

export const fetchAvailablePaymentProducts = (company_id: string) => async (dispatch: Dispatch) => {
  try {
    const availableProductsResponse = await axios.get(`/v2/api/b2b/private/companies/${company_id}/available_products`);

    dispatch({
      type: ActionType.FETCH_AVAILABLE_PAYMENT_PRODUCTS,
      payload: {
        data: true,
        availableProducts: availableProductsResponse.data.products,
      },
    });
    return null;
  } catch (error) {
    return mapErrorFromException(error);
  }
};
