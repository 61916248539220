import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import { RootState } from "../reducers";
import * as AdminUserActions from "../actions/adminUser";
import { User } from "../model/user";
import { AdminUsersState } from "../model/adminUsersState";
import { UserFormState } from "../model/userFormState";
import UsersList from "./components/UsersList";
import * as UserFormActions from "../actions/addUserForm";
import * as AuthActions from "../actions/auth";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";

export interface Props {
  adminUserState: AdminUsersState;
  adminUserFormState: UserFormState;
  actions: typeof AdminUserActions;
  userFormActions: typeof UserFormActions;
  authActions: typeof AuthActions;
}

export interface State {
  showRoleEdit: boolean;
}

function AdminUsersPage(props: Props) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [removeRolesConfirmationVisible, setRemoveRolesConfirmationVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    props.actions.fetchAllAdminUsers();
  }, []);

  const addNewAdminUser = () => {
    navigate("/users/new");
  };

  const confirmRemoveRole = (user: User) => {
    setSelectedUser(user);
    setRemoveRolesConfirmationVisible(true);
  };

  const cancelRemoveRole = () => {
    setRemoveRolesConfirmationVisible(false);
  };

  const removeRoles = () => {
    if (selectedUser != null) {
      props.actions.removeRoles(selectedUser);
    }

    setRemoveRolesConfirmationVisible(false);
  };

  const changeAdminUserRole = (user: User, role: string) => {
    if (role !== user.role) {
      props.actions.changeAdminUserRole(user.user_id, role);
    }
  };

  const resendInvitation = (user: User) => {
    props.authActions.resendUserInvitation(user.user_id, null, user.role);
  };

  return (
    <>
      <PageTracker />
      <UsersList
        showAddUser={true}
        users={props.adminUserState.users}
        addUser={() => addNewAdminUser()}
        changeRole={(user: any, role: any) => changeAdminUserRole(user, role)}
        removeAction={(user: any) => {
          confirmRemoveRole(user);
        }}
        canModifyUser={() => {
          return true;
        }}
        fetchUsers={props.actions.fetchAllAdminUsers}
        roles={["ADMIN", "REVIEWER", "GROUP_MANAGER", "RESELLER", "INTERNAL_SALES_MANAGER"]}
        hintKey="user_role.hint_admin_roles"
        resendInvitation={(user: any) => resendInvitation(user)}
      />
      {removeRolesConfirmationVisible && (
        <Dialog
          open={removeRolesConfirmationVisible}
          onClose={() => cancelRemoveRole()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.get("admin_user.delete_confirmation.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.get("admin_user.delete_confirmation.message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelRemoveRole()} color="primary">
              {intl.get("admin_user.delete_confirmation.button_cancel")}
            </Button>
            <Button onClick={() => removeRoles()} color="error" autoFocus>
              {intl.get("admin_user.delete_confirmation.button_confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    adminUserState: state.adminUsersState,
    adminUserFormState: state.userFormState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AdminUserActions as any, dispatch),
    userFormActions: bindActionCreators(UserFormActions as any, dispatch),
    authActions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminUsersPage);
