import { Stack, SxProps, Typography } from "@mui/material";
import { BCEvent, BCEventStatus } from "../../model/event";
import intl from "react-intl-universal";

interface Props {
  sx?: SxProps;
  event: BCEvent;
  gap?: string | number;
}

const Element = ({
  title,
  text,
  textColor,
}: {
  title: string;
  text: string;
  textColor?: string;
  width?: string | number;
}) => {
  return (
    <div style={{ flexBasis: "min-content", flexGrow: 1 }}>
      <Typography
        sx={{
          textTransform: "uppercase",
          font: "normal normal bold 11px/15px Manrope",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {title}
      </Typography>
      <Typography
        color={textColor}
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          font: "normal normal normal 14px/19px Manrope",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default function EventCardTypeAndStatus({ sx, event, gap }: Props) {
  let color: string | undefined;
  switch (event.status) {
    case BCEventStatus.PUBLISHED:
    case BCEventStatus.FINISHED:
      color = "secondary";
      break;
    case BCEventStatus.DELETED:
    case BCEventStatus.DRAFT:
    case BCEventStatus.REJECTED:
      color = "error";
      break;
    case BCEventStatus.READY_FOR_REVIEW:
      color = "warning";
      break;
    default:
      break;
  }

  return (
    <Stack direction={"row"} sx={sx} gap={gap || 1}>
      <Element title={intl.get(`company_events.list_header_type`)} text={intl.get(`event_type.${event.type}`)} />
      <Element
        title={intl.get(`company_events.list_header_status`)}
        text={intl.get(`event_status.${event.status}`)}
        textColor={color}
      />
    </Stack>
  );
}
