import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const TopPlacementActiveIcon = (props) => (
  <SvgIcon id="Ebene_1" width="24" height="16" viewBox="0 0 24 16" {...props}>
    <path
      id="Pfad_1"
      data-name="Pfad 1"
      d="M23.847,4.079A2.51,2.51,0,1,0,19.412,5.69L16.944,8.158,13.356,4.57a2.51,2.51,0,1,0-2.865,0L6.9,8.158,4.435,5.69a2.511,2.511,0,1,0-2.093.891l1.214,9.108H20.291l1.214-9.108a2.508,2.508,0,0,0,2.342-2.5"
      fill="#ffe200"
    />
  </SvgIcon>
);

export default TopPlacementActiveIcon;
