import { createContext, useContext } from "react";
import { DialogHandler } from "./useDialogHandler";

interface Context<HandlerPayloadType> {
  handler?: DialogHandler<HandlerPayloadType>;
}

const xContext = createContext<Context<any>>({
  handler: undefined,
});

export function DialogContext({ handler, children }: { handler: DialogHandler<any>; children?: any }) {
  return <xContext.Provider value={{ handler }}>{children}</xContext.Provider>;
}

export function useDialogContext<HandlerPayloadType>() {
  return useContext(xContext) as Context<HandlerPayloadType>;
}
