import BookingDrawerEventHeader from "./BookingDrawerEventHeader";
import GeneralProductDrawerHeader from "./GeneralProductDrawerHeader";
import { BCEvent } from "../../../model/event";

interface Props {
  event?: BCEvent;
}

export default function BookingDrawerHeader({ event }: Props) {
  if (event) {
    return <BookingDrawerEventHeader event={event} sx={{ marginBottom: "41px" }} />;
  }
  return <GeneralProductDrawerHeader />;
}
