import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { PaymentPlanState } from "../model/payment/payment.plan.state";

export const paymentPlanState = createReducer(
  {
    paymentProduct: null,
    allPaymentProducts: [],
    paging: {
      page_size: 50,
      current_page: 1,
    },
  },
  {
    [ActionType.FETCH_PAYMENTPLANS](state: PaymentPlanState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          allPaymentProducts: action.payload.products,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
  },
);
