import { formattedInvoiceAmount, Invoice } from "../../../model/invoice";
import { IconButton, TableCell, TableRow } from "@mui/material";
import AppStyles from "../../../styles/appStyles";
import moment from "moment/moment";
import intl from "react-intl-universal";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import { LinkButton } from "../../Buttons";
import * as React from "react";

export default function CompanyInvoiceCell({
  invoice,
  onDownload,
  onRegeneratePdf,
}: {
  invoice: Invoice;
  onDownload: () => void;
  onRegeneratePdf: () => void;
}) {
  return (
    <TableRow
      onClick={(event) => {
        event.stopPropagation();
        onDownload();
      }}
    >
      <TableCell sx={AppStyles.tableCell}>{moment(invoice.created_at).format("LL")}</TableCell>
      <TableCell sx={AppStyles.tableCell}>{invoice.invoice_number}</TableCell>
      <TableCell sx={AppStyles.tableCell}>{intl.get(`company_plan_info.invoice.status.${invoice.status}`)}</TableCell>
      <TableCell align="right" sx={AppStyles.tableCell}>
        {formattedInvoiceAmount(invoice)}
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onDownload();
          }}
          size="large"
        >
          <PdfIcon />
        </IconButton>
        <LinkButton
          title={intl.get("company_plan_info.invoice.regenerate_pdf_button")}
          onClick={(event) => {
            event.stopPropagation();
            onRegeneratePdf();
          }}
        />
      </TableCell>
    </TableRow>
  );
}
