import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { SeoPageState } from "../model/seoPageState";

export const seoPageState = createReducer(
  {
    selected_plan_id: null,
    paymentPlan: null,
    allPaymentPlans: null,
    paging: {
      page_size: 50,
      current_page: 1,
    },
  },
  {
    [ActionType.SELECT_SEOPAGES](state: SeoPageState, action: Action<any>) {
      return {
        ...state,
        selected_seopage_id: action.payload.selected_seopage_id,
      };
    },
    [ActionType.FETCH_SEOPAGES](state: SeoPageState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          allSeoPages: action.payload.seopages,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
  },
);
