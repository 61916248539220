import {
  Box,
  Grid,
  IconButton,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import * as intl from "react-intl-universal";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ReactTooltip from "react-tooltip";
import { User } from "../../model/user";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import CopyToClipboard from "react-copy-to-clipboard";
import AppStyles from "../../styles/appStyles";

export interface BaseProps {
  users: User[];
  roles: string[];
  hintKey: string;

  showAddUser: boolean;

  addUser(): void;

  fetchUsers(): void;

  changeRole(user: User, role: string): void;

  removeAction(user: User): void;

  resendInvitation(user: User): void;

  canModifyUser(user: User): boolean;
}

const classes = {
  cellCenter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "stretch",
  },
};

export interface Props extends BaseProps {}

function UsersList(props: Props) {
  const changeRole = (user: any, event: any) => {
    props.changeRole(user, event.target.value);
  };

  if (props.users === null) {
    return <></>;
  }

  return (
    <>
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("users.title")}</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            {props.showAddUser && (
              <DefaultButton onClick={props.addUser}>
                <AddIcon sx={AppStyles.buttonLeftIcon} />
                {intl.get("users.button_add")}
              </DefaultButton>
            )}

            {!props.showAddUser && <DefaultButton sx={AppStyles.buttonPlaceholder} />}
          </Grid>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("users.list_header_email")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("users.list_header_first_name")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("users.list_header_last_name")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  <Box sx={classes.cellCenter}>
                    <div style={{ textAlign: "center" }}>{intl.get("users.list_header_role")}</div>
                    <Box component={"a"} style={{ marginLeft: 5 }} data-tip data-for="global" sx={classes.cellCenter}>
                      <InfoIcon />
                    </Box>

                    <ReactTooltip id="global" aria-haspopup="true" role="example" place="bottom">
                      {intl.getHTML(props.hintKey)}
                    </ReactTooltip>
                  </Box>
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users.map((user) => {
                return (
                  <TableRow key={user.user_id} hover={false}>
                    <TableCell padding="none" component="th" scope="row" sx={AppStyles.tableCellNotSelectable}>
                      {user.email}
                    </TableCell>
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                      {user.first_name}
                    </TableCell>
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                      {user.last_name}
                    </TableCell>
                    <TableCell padding="none" sx={AppStyles.tableCell}>
                      {props.canModifyUser(user) && (
                        <NativeSelect
                          onChange={(event) => {
                            changeRole(user, event);
                            event.stopPropagation();
                          }}
                          value={user.role ? user.role : ""}
                          name="role"
                        >
                          {props.roles.map((role) => {
                            return (
                              <option key={role} value={role}>
                                {intl.get(`user_role.${role}`)}
                              </option>
                            );
                          })}
                        </NativeSelect>
                      )}

                      {props.canModifyUser(user) === false && <div>{intl.get(`user_role.${user.role}`)}</div>}
                    </TableCell>
                    <TableCell>
                      {user.status === "PLACEHOLDER" && (
                        <LinkButton
                          onClick={(event: any) => {
                            event.stopPropagation();
                            props.resendInvitation(user);
                          }}
                          title={intl.get("users.button_resend_invitation")}
                        />
                      )}
                      {user.signup_link && (
                        <CopyToClipboard text={user.signup_link}>
                          <LinkButton
                            onClick={(event: any) => {
                              event.stopPropagation();
                            }}
                            title={"Registrierungslink kopieren"}
                          />
                        </CopyToClipboard>
                      )}
                      {user.confirmation_link && (
                        <CopyToClipboard text={user.confirmation_link}>
                          <LinkButton
                            onClick={(event: any) => {
                              event.stopPropagation();
                            }}
                            title={"Bestätigungsslink kopieren"}
                          />
                        </CopyToClipboard>
                      )}
                    </TableCell>
                    <TableCell padding="none" align="right" sx={AppStyles.tableCell} style={{ paddingRight: 10 }}>
                      {props.canModifyUser(user) && (
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            props.removeAction(user);
                          }}
                          size="large"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  );
}

export default UsersList;
