import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import HandlerDialog from "../../../components/dialog/handlerDialog";

interface Props {
  handler: DialogHandler<any>;
  onDeletePaymentMethod: () => void;
}

export default function DeletePaymentMethodDialog({ handler, onDeletePaymentMethod }: Props) {
  return (
    <HandlerDialog
      handler={handler}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{intl.get("company_plan_info.delete_payment_method.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.get("company_plan_info.delete_payment_method.message")}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onDeletePaymentMethod} color={"error"}>
          {intl.get("company_plan_info.delete_payment_method.button_confirm")}
        </Button>
        <Button onClick={handler.close} color="secondary" autoFocus>
          {intl.get("company_plan_info.delete_payment_method.button_cancel")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
