import { Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../reducers";
import { EventFormState } from "../../model/eventFormState";
import * as EventFormActions from "../../actions/eventForm";
import * as EventActions from "../../actions/event";
import EventEditForm from "../components/forms/EventEditForm";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import EventReviewForm from "../components/eventedit/EventReviewForm";
import EventReviewHeaderActions from "../components/eventedit/EventReviewHeaderActions";

export interface Props {
  eventFormState: EventFormState;
  eventActions: typeof EventActions;
  eventFormActions: typeof EventFormActions;
}

export interface State {}

function EventReviewPage(props: Props) {
  const [rejectionDescription, setRejectionDescription] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  function closePage(): void {
    navigate(-1);
  }

  const companyId = params.company_id;

  useEffect(() => {
    const eventId = params.event_id;
    if (companyId && eventId) {
      props.eventActions.startEditingEvent(companyId, eventId, true);
    }
  }, []);

  if (props.eventFormState.closePage) {
    props.eventFormActions.resetEventForm();
    closePage();
    return <div />;
  }

  if (props.eventFormState.event == null) {
    return <div />;
  }

  return (
    <>
      <PageTracker />
      <EventEditForm>
        <Stack>
          <Stack direction={"row"} gap={"12px"} justifyContent={"end"} sx={{ marginRight: "16px" }}>
            <EventReviewHeaderActions
              onApproved={closePage}
              onRejected={closePage}
              rejectionDescription={rejectionDescription}
              companyId={companyId}
            />
          </Stack>

          <EventReviewForm
            rejectionDescription={rejectionDescription}
            onRejectionDescriptionChanged={setRejectionDescription}
          />
        </Stack>
      </EventEditForm>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    eventFormState: state.eventFormState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    eventFormActions: bindActionCreators(EventFormActions as any, dispatch),
    eventActions: bindActionCreators(EventActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventReviewPage);
