import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { CompanyState } from "../../../../../model/companyState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { AuthenticationState } from "../../../../../model/authenticationState";
import * as React from "react";
import { FormGridItem } from "../FormGridItem";
import { SectionLabel } from "../../../../../components/SectionLabel";
import intl from "react-intl-universal";
import { Constants } from "../event_options";
import { EventEditCheckboxField } from "../fields/EventEditCheckboxField";
import { EventEditMultiSelectField } from "../fields/EventEditMultiSelectField";
import MySelect from "../../../../../components/select/MySelect";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventGeneralInfoCategoryEdit extends React.Component<Props> {
  renderCategories() {
    return (
      <Grid item container direction="column">
        <SectionLabel required={true}>{intl.get(`event_form.label.categories`)}</SectionLabel>
        <MySelect
          options={Constants.eventCategories(this.props.eventFormState.categories)}
          closeMenuOnSelect={false}
          isMulti
          isDisabled={this.props.eventFormState.readonly}
          isClearable
          isSearchable
          isOptionDisabled={(option) => {
            const selectedExclusiveCategoryIndex = this.props.eventFormState.event.categories.findIndex(
              (value) => value.is_exclusive,
            );
            if (selectedExclusiveCategoryIndex !== -1) {
              const selectedExclusiveCategory =
                this.props.eventFormState.event.categories[selectedExclusiveCategoryIndex];
              if (option.category.category_id === selectedExclusiveCategory.category_id) {
                return false;
              }
              return true;
            }
            return this.props.eventFormState.event.categories?.length === 2;
          }}
          placeholder={intl.get(`event_form.label.categories`)}
          value={Constants.toSelectedCategories(this.props.eventFormState.event)}
          onChange={(value) => {
            let selectedCategories = (value as any[]).map((value1) => value1.category);
            const exclusiveCategoryIndex = selectedCategories.findIndex((value1) => value1.is_exclusive);
            if (exclusiveCategoryIndex !== -1) {
              selectedCategories = selectedCategories.slice(exclusiveCategoryIndex, exclusiveCategoryIndex + 1);
            }
            this.props.eventFormActions.updateEventFormField({
              prop: "event.categories",
              value: selectedCategories as any,
            });
          }}
        />
      </Grid>
    );
  }

  renderTags() {
    return (
      <Grid item container direction="column">
        <SectionLabel required={true}>{intl.get(`event_form.label.tags`)}</SectionLabel>
        <MySelect
          options={Constants.eventTags(this.props.eventFormState.tags)}
          closeMenuOnSelect={false}
          isMulti
          isDisabled={this.props.eventFormState.readonly}
          isClearable
          isSearchable
          isOptionDisabled={() => {
            return this.props.eventFormState.event.tags?.length >= 10;
          }}
          placeholder={intl.get(`event_form.label.add_tag_label`)}
          value={Constants.toSelectedTags(this.props.eventFormState.event)}
          onChange={(value) => {
            const selectedTags = (value as any[]).map((value1) => value1.tag);
            this.props.eventFormActions.updateEventFormField({
              prop: "event.tags",
              value: selectedTags as any,
            });
          }}
        />
      </Grid>
    );
  }

  renderIsLocationIndependent() {
    return (
      <EventEditCheckboxField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"location_independent"}
      />
    );
  }

  renderDestinationType() {
    return (
      <EventEditMultiSelectField
        maxCount={5}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.destinationTypeOptions()}
        field={"destination_type"}
        onChange={() => {
          const allowedSubtypeOptions = Constants.getSubtypeOptionsForDestinationType(
            this.props.eventFormState.event.destination_type,
          );
          const currentSubtypes = Constants.toMultiSelectOption(
            this.props.eventFormState.event.destination_subtype,
            allowedSubtypeOptions,
          );
          const newSubtype = Constants.fromMultiSelectOption(currentSubtypes);
          if (newSubtype.length !== this.props.eventFormState.event.destination_subtype.length) {
            this.props.eventFormState.event.destination_subtype = newSubtype;
          }
        }}
      />
    );
  }

  renderDestinationSubType() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"destination_subtype"}
        options={Constants.getSubtypeOptionsForDestinationType(this.props.eventFormState.event.destination_type)}
      />
    );
  }

  renderMainTheme() {
    return (
      <EventEditMultiSelectField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        options={Constants.mainThemeOptions()}
        field={"main_theme"}
      />
    );
  }

  renderLayoutPlaceholder() {
    return <>&nbsp;</>;
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem>{this.renderCategories()}</FormGridItem>
          <FormGridItem>{this.renderTags()}</FormGridItem>
          <FormGridItem>{this.renderDestinationType()}</FormGridItem>
          <FormGridItem>{this.renderDestinationSubType()}</FormGridItem>
          <FormGridItem>{this.renderMainTheme()}</FormGridItem>

          <FormGridItem md={6}>
            {(this.props.authenticationState.user.role === "ADMIN" ||
              this.props.authenticationState.user.role === "INTERNAL_SALES_MANAGER") &&
              this.renderIsLocationIndependent()}
            {this.props.authenticationState.user.role !== "ADMIN" && this.renderLayoutPlaceholder()}
          </FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventGeneralInfoCategoryEdit;
