import { DateCalendar } from "@mui/x-date-pickers-pro";
import { useState } from "react";
import { endOfMonth, format, getISOWeek, getISOWeeksInYear, startOfMonth } from "date-fns";
import { SxProps } from "@mui/material";
import { CalendarWeekSelectionHandler } from "./useCalendarWeekSelection";
import CalendarMonthHeader from "./CalendarMonthHeader";
import CalendarWeekSelectionDay from "./CalendarWeekSelectionDay";
import { PaymentProduct } from "../../model/payment/payment.product";
import { formattedAmount } from "../../model/global_invoices/global.invoice.status";

interface Props {
  handler: CalendarWeekSelectionHandler;
  product: PaymentProduct;
  sx?: SxProps;
}

export default function CalendarWeekSelection({ handler, sx, product }: Props) {
  const [hoveredDay, setHoveredDay] = useState<any>();
  const [currentlyDisplayedMonth, setCurrentlyDisplayedMonth] = useState(startOfMonth(new Date()));

  const start = startOfMonth(currentlyDisplayedMonth);
  const end = endOfMonth(currentlyDisplayedMonth);

  const startWeek = getISOWeek(start);
  let endWeek = getISOWeek(end);

  if (endWeek < startWeek) {
    endWeek = endWeek + getISOWeeksInYear(start);
  }

  const numberOfDisplayedWeeks = endWeek - startWeek + 1;

  return (
    <DateCalendar
      views={["day"]}
      sx={[
        {
          height: "auto",
          "& .MuiDayCalendar-header": {
            justifyContent: "flex-start",
            marginLeft: "5px",
            marginBottom: "12px",
          },
          "& .MuiDayCalendar-weekDayLabel": {
            px: 0,
            width: "32px",
            height: "15px",
            margin: 0,
            font: "normal normal bold 14px/18px Manrope",
          },
          "& .MuiDayCalendar-monthContainer": {},
          "& .MuiPickersSlideTransition-root": {
            minHeight: numberOfDisplayedWeeks >= 6 ? "230px" : "192px",
            overflowY: "hidden",
          },
        },
        sx as any,
      ]}
      onMonthChange={(newMonth) => {
        setCurrentlyDisplayedMonth(newMonth);
      }}
      value={null}
      dayOfWeekFormatter={(_day: string, date: Date) => format(date, "EE").replace(".", "").toUpperCase()}
      showDaysOutsideCurrentMonth
      disableHighlightToday
      minDate={handler.minDate}
      maxDate={handler.maxDate}
      slots={{ day: CalendarWeekSelectionDay, calendarHeader: CalendarMonthHeader }}
      slotProps={{
        day: (ownerState) => {
          const booking = handler.getBookingForWeekIfExists(ownerState.day);
          const price = formattedAmount(
            (!booking && handler.nextBookingIsFree) || (booking && booking.free) ? 0 : product.price_in_cents,
            product.currency,
            0,
          );
          const selectable = handler.isWeekSelectable(ownerState.day);
          return {
            price,
            isSelected: Boolean(booking),
            readOnly: Boolean(booking?.previouslyBooked),
            hoveredDay: handler.isWeekSelectable(hoveredDay) ? hoveredDay : undefined,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
            selectable,
            onClick: selectable
              ? (event: any) => {
                  event.stopPropagation();
                  handler.toggleWeek(ownerState.day);
                }
              : undefined,
          } as any;
        },
      }}
    />
  );
}
