import * as React from "react";
import { PaymentProduct } from "../../../model/payment/payment.product";
import BulkEventUpgradeDrawerPage from "./BulkEventUpgradeDrawerPage";
import intl from "react-intl-universal";
import { BCEvent } from "../../../model/event";

interface Props {
  product: PaymentProduct;
  onSkip: () => void;
  onSuccess: () => void;
  originatingEvent?: BCEvent;
}

export default function BulkEventUpgradeForProductDrawerPage({ product, onSkip, onSuccess, originatingEvent }: Props) {
  const selectedUpgradeType =
    product && product.product_sku_group.indexOf("premium_plus") >= 0 ? "premium_plus" : "premium";

  const numberOfAvailableSlots =
    product && selectedUpgradeType === "premium_plus"
      ? product.premium_plus_upgrade_slots
      : product?.premium_upgrade_slots || 0;

  return (
    <BulkEventUpgradeDrawerPage
      onSkip={onSkip}
      onSuccess={onSuccess}
      numberOfSlots={numberOfAvailableSlots}
      upgradeType={selectedUpgradeType}
      originatingEvent={originatingEvent}
      title={intl.get("booking_drawer.premium_upgrade.event_selection.title", {
        productName: product.name,
      })}
      message={intl.getHTML("booking_drawer.premium_upgrade.event_selection.message", {
        productName: product.name,
        numberOfEvents: numberOfAvailableSlots,
      })}
      hint={intl.getHTML("booking_drawer.premium_upgrade.event_selection.hint_extend")}
    />
  );
}
