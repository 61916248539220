import { Box, Card, CardActions, CardContent, CardHeader, Grid, Theme, Typography } from "@mui/material";
import * as React from "react";
import * as intl from "react-intl-universal";
import { DefaultButton } from "../../components/Buttons";
import {
  formattedPaymentProductBillingInfo,
  formattedPlanBillingInterval,
  formattedPlanReducedPlanBillingInterval,
  formattedReducedPricingInfo,
  formattedTrialPhase,
} from "../../model/payment_info";
import {
  formattedAddonFeatures,
  formattedAddonInfo,
  formattedNoProductEntries,
  formattedProductFeaturesNew,
  PaymentProduct,
} from "../../model/payment/payment.product";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import CheckmarkPlans from "../../assets/icons/checkmark_plans.svg";
import AppStyles from "../../styles/appStyles";

const classes = {
  card: (theme: Theme) => ({
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingTop: "32px",
    paddingBottom: "32px",
    marginLeft: "12px",
    marginRight: "12px",
    marginTop: "8px",
    marginBottom: "8px",
    [theme.breakpoints.only("sm")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
    "&.fullHeight": {
      height: "100%",
    },
  }),
  cardContent: {
    padding: 0,
  },
  cardAction: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  cardsContainer: {
    marginTop: "8px",
    marginBottom: "42px",
    marginLeft: "4px",
    paddingRight: "12px",
  },
  planHeader: (theme: Theme) => ({
    paddingBottom: "10px",
    textAlign: "center",
    color: theme.bodyTextColor,
    fontSize: theme.fontSizes.largerFont,
    fontWeight: 800,
  }),
  addOnHeader: (theme: Theme) => ({
    paddingBottom: "37px",
    textAlign: "center",
    color: theme.bodyTextColor,
    fontSize: theme.fontSizes.largerFont,
    fontWeight: 800,
  }),
  planPrice: (theme: Theme) => ({
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: theme.fontSizes.veryLargeFont,
    fontWeight: 800,
  }),
  planInterval: (theme: Theme) => ({
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: theme.fontSizes.largeFont,
    fontWeight: 400,
  }),
  regularPrice: (theme: Theme) => ({
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: theme.fontSizes.mediumFont,
    fontWeight: 400,
    marginTop: "16px",
    marginBottom: "16px",
  }),
  planTrialInterval: (theme: Theme) => ({
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: theme.fontSizes.largeFont,
    fontWeight: 800,
    marginTop: "32px",
  }),
  planNoProductEntries: (theme: Theme) => ({
    textAlign: "center",
    color: "#ffc296",
    fontSize: theme.fontSizes.mediumFont,
    paddingBottom: "23px",
  }),
  addOnSubHeaderFeatures: (theme: Theme) => ({
    color: theme.palette.primary.main,
    paddingTop: "2px",
    paddingBottom: "14px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "uppercase",
    fontWeight: 800,
  }),
  addOnDescription: {
    paddingBottom: "14px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  addOnFeature: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  planFeatureContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "11px",
    paddingBottom: "4px",
  },
  planFeatureText: {
    paddingTop: "11px",
  },
  planFeatureCheckmark: {
    width: "48px",
    height: "50px",
  },
  plansHint: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
  },
};

export interface Props {
  availableProducts: PaymentProduct[];
  onSelectProduct: any;
  hasValidPaymentMethod: boolean;
  supportsAddingPaymentMethod: boolean;
  onAddPaymentMethod: any;
  isManagedBySalespartner: boolean;
}

class CompanyPlanSelection extends React.Component<Props> {
  render() {
    const subscriptions = this.props.availableProducts.filter((value) => value.type === "subscription");
    const credits = this.props.availableProducts.filter((value) => value.type === "credit");
    return (
      <>
        <Grid container direction="row" justifyContent="center" alignItems="flex-end" style={{ marginTop: 64 }}>
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>Verfügbare Partner-Pakete</HeaderLabel>
          </Grid>
        </Grid>

        <Grid container direction="row" sx={classes.cardsContainer}>
          {subscriptions.map((row: PaymentProduct) => (
            <ProductCard
              key={"subscription_" + row.product_id}
              product={row}
              hasValidPaymentMethod={this.props.hasValidPaymentMethod}
              supportsAddingPaymentMethod={
                this.props.supportsAddingPaymentMethod && this.props.isManagedBySalespartner === false
              }
              isManagedBySalespartner={this.props.isManagedBySalespartner}
              onSelectProduct={this.props.onSelectProduct}
              onAddPaymentMethod={this.props.onAddPaymentMethod}
            />
          ))}
        </Grid>
        <Grid container direction="row" sx={classes.cardsContainer}>
          <Grid item sx={classes.plansHint}>
            <Typography variant={"body2"}>{intl.get("company_plan_info.plans.hint_no_entries")}</Typography>
            <Typography variant={"body2"}>{intl.get("company_plan_info.plans.hint_tax_markup")}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="flex-end" style={{ marginTop: 64 }}>
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>Add-Ons / Zusatzpakete</HeaderLabel>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" sx={classes.cardsContainer}>
          {credits.map((row: PaymentProduct) => (
            <CreditCard
              key={"credit_" + row.product_id}
              product={row}
              hasValidPaymentMethod={this.props.hasValidPaymentMethod}
              supportsAddingPaymentMethod={
                this.props.supportsAddingPaymentMethod && this.props.isManagedBySalespartner === false
              }
              isManagedBySalespartner={this.props.isManagedBySalespartner}
              onSelectProduct={this.props.onSelectProduct}
              onAddPaymentMethod={this.props.onAddPaymentMethod}
            />
          ))}
        </Grid>
        <Grid item sx={classes.plansHint}>
          <Typography variant={"body2"}>{intl.get("company_plan_info.plans.hint_tax_markup")}</Typography>
        </Grid>
      </>
    );
  }
}

interface ProductProps {
  product: PaymentProduct;
  hasValidPaymentMethod: boolean;
  supportsAddingPaymentMethod: boolean;
  isManagedBySalespartner: boolean;
  onSelectProduct: (selectedProduct: PaymentProduct) => void;
  onAddPaymentMethod: () => void;
}

const ProductCard = (props: ProductProps) => {
  const {
    product,
    hasValidPaymentMethod,
    supportsAddingPaymentMethod,
    isManagedBySalespartner,
    onSelectProduct,
    onAddPaymentMethod,
  } = props;

  const featureEntries = formattedProductFeaturesNew(product);
  const isFreeProduct = product.price_in_cents === 0 && product.reduced_price_in_cents === 0;

  return (
    <>
      <Grid item xs={12} lg={4} md={12} sm={12}>
        <Card sx={classes.card}>
          <CardHeader title={product.name} sx={classes.planHeader} disableTypography={true} />
          <CardContent sx={classes.cardContent}>
            <Grid container direction="column">
              <Typography variant="body2" color={"primary"} sx={classes.planNoProductEntries}>
                <b>{formattedNoProductEntries(product.premium_upgrade_slots + product.premium_plus_upgrade_slots)}</b>
              </Typography>
              {product.number_of_reduced_price_periods > 0 && (
                <Typography variant="h4" color={"primary"} sx={classes.planPrice}>
                  {formattedReducedPricingInfo(product)}
                </Typography>
              )}
              {product.number_of_reduced_price_periods > 0 && (
                <Typography variant="h4" color={"primary"} sx={classes.planInterval}>
                  {formattedPlanReducedPlanBillingInterval(
                    product.billing_interval_unit,
                    product.number_of_reduced_price_periods,
                  )}
                </Typography>
              )}
              {product.number_of_reduced_price_periods > 0 && (
                <Typography variant="h4" color={"primary"} sx={classes.regularPrice}>
                  danach {formattedPaymentProductBillingInfo(product)}{" "}
                  {formattedPlanBillingInterval(product.billing_interval_unit, product.billing_interval_value)}
                </Typography>
              )}
              {product.number_of_reduced_price_periods === 0 && (
                <Typography variant="h4" color={"primary"} sx={classes.planPrice}>
                  {formattedPaymentProductBillingInfo(product)}
                </Typography>
              )}
              {product.number_of_reduced_price_periods === 0 && (
                <Typography variant="h6" color={"primary"} sx={classes.planInterval}>
                  {formattedPlanBillingInterval(product.billing_interval_unit, product.billing_interval_value)}
                </Typography>
              )}

              {product.trial_unit && product.trial_interval > 0 && (
                <Typography variant="h6" color={"primary"} sx={classes.planTrialInterval}>
                  {formattedTrialPhase(product.trial_unit, product.trial_interval)}
                </Typography>
              )}
            </Grid>
          </CardContent>
          <CardActions sx={classes.cardAction}>
            <Grid container direction="row" justifyContent="center">
              <DefaultButton
                color={"warningLight"}
                disabled={
                  hasValidPaymentMethod === false && supportsAddingPaymentMethod === false && isFreeProduct === false
                }
                onClick={() => {
                  if (hasValidPaymentMethod || isFreeProduct) {
                    onSelectProduct(product);
                  } else if (supportsAddingPaymentMethod) {
                    onAddPaymentMethod();
                  }
                }}
              >
                {hasValidPaymentMethod || isFreeProduct
                  ? product.type === "subscription"
                    ? intl.get("company_plan_info.plans.button_select_subscription")
                    : intl.get("company_plan_info.plans.button_select_credit")
                  : supportsAddingPaymentMethod
                    ? intl.get("company_plan_info.payment_method.button_add")
                    : isManagedBySalespartner
                      ? intl.get("company_plan_info.payment_method.button_contact_salespartner")
                      : intl.get("company_plan_info.payment_method.button_contact_admin")}
              </DefaultButton>
            </Grid>
          </CardActions>
          <CardContent sx={classes.cardContent}>
            <Grid container direction="column">
              {featureEntries.map((feature: string, index) => {
                return (
                  <Box sx={classes.planFeatureContainer} key={"planfeature_" + feature + index}>
                    <img src={CheckmarkPlans} style={classes.planFeatureCheckmark} />
                    <Typography variant="body1" sx={classes.planFeatureText}>
                      <p>{feature}</p>
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const CreditCard = (props: ProductProps) => {
  const {
    product,
    hasValidPaymentMethod,
    supportsAddingPaymentMethod,
    isManagedBySalespartner,
    onSelectProduct,
    onAddPaymentMethod,
  } = props;

  const featureEntries = formattedAddonFeatures(product.product_sku_group);
  const isFreeProduct = product.price_in_cents === 0;

  return (
    <>
      <Grid item xs={12} lg={4} md={12} sm={12}>
        <Card sx={classes.card}>
          <CardHeader title={product.name} sx={classes.addOnHeader} disableTypography={true} />
          <CardContent sx={classes.cardContent}>
            <Grid container direction="column" alignItems="center" style={{ textAlign: "center" }}>
              <Typography variant="h4" color={"primary"} sx={classes.planPrice}>
                {formattedPaymentProductBillingInfo(product)}
              </Typography>
              <Typography variant="h6" color={"primary"} sx={classes.planInterval}>
                {product.product_sku_group === "hero"
                  ? intl.get("company_plan_info.plans.billing_interval.single.calender_week")
                  : intl.get("company_plan_info.plans.billing_interval.single.one_time")}
              </Typography>
            </Grid>
          </CardContent>
          <CardActions sx={classes.cardAction}>
            <Grid container direction="row" justifyContent="center">
              <DefaultButton
                disabled={
                  hasValidPaymentMethod === false && supportsAddingPaymentMethod === false && isFreeProduct === false
                }
                onClick={() => {
                  if (hasValidPaymentMethod || isFreeProduct) {
                    onSelectProduct(product);
                  } else if (supportsAddingPaymentMethod) {
                    onAddPaymentMethod();
                  }
                }}
              >
                {hasValidPaymentMethod || isFreeProduct
                  ? product.type === "subscription"
                    ? intl.get("company_plan_info.plans.button_select_subscription")
                    : intl.get("company_plan_info.plans.button_select_credit")
                  : supportsAddingPaymentMethod
                    ? intl.get("company_plan_info.payment_method.button_add")
                    : isManagedBySalespartner
                      ? intl.get("company_plan_info.payment_method.button_contact_salespartner")
                      : intl.get("company_plan_info.payment_method.button_contact_admin")}
              </DefaultButton>
            </Grid>
          </CardActions>
          <CardContent sx={classes.cardContent}>
            <Grid container direction="column">
              <Typography variant="subtitle1" sx={classes.addOnSubHeaderFeatures}>
                {intl.get("add_on_info.subheader_features")}
              </Typography>
              <Typography variant="body1" sx={classes.addOnDescription}>
                {formattedAddonInfo(product.product_sku_group)}
              </Typography>
              {featureEntries.map((feature: string) => {
                return (
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left" }}
                    sx={classes.addOnFeature}
                    key={"addonfeature" + feature}
                  >
                    <p dangerouslySetInnerHTML={{ __html: feature }} />
                  </Typography>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CompanyPlanSelection;
