import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import { CompanyPaymentProduct } from "../../../model/payment/company.payment.product";
import { useDispatch } from "react-redux";
import useSelectedCompanyId from "../../../hooks/useSelectedCompanyId";
import * as ProductActions from "../../../actions/company.product.actions";

interface Props {
  handler: DialogHandler<CompanyPaymentProduct>;
}

export default function ProductCancellationDialog({ handler }: Props) {
  const localizationKey = "cancel_active_plan";

  const selectedCompanyId = useSelectedCompanyId();

  const dispatch = useDispatch();

  function confirmProductCancellation() {
    dispatch<any>(ProductActions.cancelProduct(selectedCompanyId, handler.payload!.company_product_id));
    handler.close();
  }

  return (
    <HandlerDialog
      handler={handler}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div
          dangerouslySetInnerHTML={{
            __html: intl.get(`company_plan_info.cancellation.${localizationKey}.title`),
          }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div
            dangerouslySetInnerHTML={{
              __html: intl.get(`company_plan_info.cancellation.${localizationKey}.message`),
            }}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handler.close} color="primary">
          {intl.get(`company_plan_info.cancellation.${localizationKey}.button_cancel`)}
        </Button>
        <Button onClick={confirmProductCancellation} color="primary" autoFocus>
          {intl.get(`company_plan_info.cancellation.${localizationKey}.button_cancel_plan`)}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
