import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import { RootState } from "../../reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect, useState } from "react";
import * as CompanySharedLocationsActions from "../../actions/shared.location.actions";
import { CompanyState } from "../../model/companyState";
import { CompanySharedLocationsState } from "../../model/company.shared.locations.state";
import { BackButton, DefaultButton } from "../../components/Buttons";
import intl from "react-intl-universal";
import SaveIcon from "@mui/icons-material/Save";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import { SectionLabel } from "../../components/SectionLabel";
import { Constants } from "../components/forms/event_edit/event_options";
import ClearIcon from "@mui/icons-material/Clear";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import MySelect from "../../components/select/MySelect";
import MyCreatableSelect from "../../components/select/MyCreatableSelect";
import AppStyles from "../../styles/appStyles";

export interface Props {
  companyState: CompanyState;
  companySharedLocationsState: CompanySharedLocationsState;
  actions: typeof CompanySharedLocationsActions;
}

export interface State {
  deleteConfirmationVisible: boolean;
}

function CompanySharedLocationEditPage(props: Props) {
  const [state, setState] = useState({
    deleteConfirmationVisible: false,
  });

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params == null) {
      props.actions.startEditingNewSharedLocation(props.companyState.company);
    } else {
      const companyId = params.company_id;
      const locationId = params.location_id;

      if (companyId && locationId) {
        props.actions.editSharedLocation(companyId, locationId);
      } else {
        props.actions.startEditingNewSharedLocation(props.companyState.company);
      }
    }
  }, []);

  function saveChanges(): void {
    props.actions.saveSharedLocation(
      props.companyState.selected_company_id,
      props.companySharedLocationsState.editing_shared_location,
      () => {
        navigate(-1);
      },
    );
  }

  function deleteLocation() {
    props.actions.deleteSharedLocation(
      props.companyState.selected_company_id,
      props.companySharedLocationsState.location_id,
      () => {
        navigate(-1);
      },
    );
  }

  function renderCity() {
    return (
      <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get(`event_form.label.location.city`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.cityOptionsForFederalState(
              props.companySharedLocationsState.editing_shared_location.state,
              props.companySharedLocationsState.editing_shared_location.city,
            )}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.city`)}
            value={Constants.selectedDependentLocationValue(
              "Zuerst Bundesland auswählen",
              "Ort auswählen",
              props.companySharedLocationsState.editing_shared_location.city,
              props.companySharedLocationsState.editing_shared_location.state,
            )}
            onCreateOption={(value) => {
              const oldValue = props.companySharedLocationsState.editing_shared_location.city;
              const newValue = value;
              const hasChanged = newValue !== oldValue;

              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.city",
                value: value,
              });
              if (hasChanged) {
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.district",
                  value: "",
                });
              }
            }}
            onChange={(value) => {
              const oldValue = props.companySharedLocationsState.editing_shared_location.city;
              const newValue = Constants.fromSingleSelectOption(value);
              const hasChanged = newValue !== oldValue;

              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.city",
                value: Constants.fromSingleSelectOption(value),
              });
              if (hasChanged) {
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.district",
                  value: "",
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderDistrict() {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get(`event_form.label.location.district`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.districtOptionsForCity(
              props.companySharedLocationsState.editing_shared_location.state,
              props.companySharedLocationsState.editing_shared_location.district,
            )}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.district`)}
            value={Constants.selectedDependentLocationValue(
              "Zuerst Ort auswählen",
              "Ortsteil auswählen",
              props.companySharedLocationsState.editing_shared_location.district,
              props.companySharedLocationsState.editing_shared_location.state,
            )}
            onCreateOption={(value) => {
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.district",
                value: value,
              });
            }}
            onChange={(value) => {
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.district",
                value: Constants.fromSingleSelectOption(value),
              });
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderCountry() {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={true}>{intl.get(`event_form.label.location.country_code`)}</SectionLabel>
          <MySelect
            options={Constants.addressCountryCodeOptions()}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.country_code`)}
            value={Constants.toSingleSelectOption(
              props.companySharedLocationsState.editing_shared_location.country_code,
              `event_constants.location.country_code`,
            )}
            onChange={(value) => {
              const oldValue = props.companySharedLocationsState.editing_shared_location.country_code;
              const newValue = Constants.fromSingleSelectOption(value);
              const hasChanged = newValue !== oldValue;
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.country_code",
                value: Constants.fromSingleSelectOption(value),
              });
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.country",
                value: value.label,
              });
              if (hasChanged) {
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.state",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.region",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.city",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.district",
                  value: "",
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderState() {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={true}>{intl.get(`event_form.label.location.state`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.federalStateOptionsForCountry(
              props.companySharedLocationsState.editing_shared_location.country_code,
              props.companySharedLocationsState.editing_shared_location.state,
            )}
            isDisabled={
              props.companySharedLocationsState.editing_shared_location.country_code === undefined ||
              props.companySharedLocationsState.editing_shared_location.country_code === ""
            }
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.state`)}
            value={Constants.selectedDependentLocationValue(
              "Zuerst Land auswählen",
              "Bundesland auswählen",
              props.companySharedLocationsState.editing_shared_location.state,
              props.companySharedLocationsState.editing_shared_location.country_code,
            )}
            onCreateOption={(value) => {
              const newValue = value;
              const hasChanged = newValue !== props.companySharedLocationsState.editing_shared_location.state;
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.state",
                value: value,
              });
              if (hasChanged) {
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.region",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.city",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.district",
                  value: "",
                });
              }
            }}
            onChange={(value) => {
              const oldValue = props.companySharedLocationsState.editing_shared_location.state;
              const newValue = Constants.fromSingleSelectOption(value);
              const hasChanged = newValue !== oldValue;
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.state",
                value: Constants.fromSingleSelectOption(value),
              });
              if (hasChanged) {
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.region",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.city",
                  value: "",
                });
                props.actions.updateSharedLocationFormField({
                  prop: "editing_shared_location.district",
                  value: "",
                });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderRegion() {
    return (
      <Grid item xs={12} md={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get(`event_form.label.location.region`)}</SectionLabel>
          <MyCreatableSelect
            options={Constants.regionOptionsForFederalState(
              props.companySharedLocationsState.editing_shared_location.state,
              props.companySharedLocationsState.editing_shared_location.region,
            )}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            isSearchable
            placeholder={intl.get(`event_form.label.location.region`)}
            value={Constants.selectedMultiDependentLocationValue(
              "Zuerst Bundesland auswählen",
              "Region auswählen",
              props.companySharedLocationsState.editing_shared_location.region,
              props.companySharedLocationsState.editing_shared_location.state,
            )}
            onCreateOption={(value) => {
              let newValue = props.companySharedLocationsState.editing_shared_location.region;
              if (newValue && newValue !== "") {
                newValue = `${newValue};${value}`;
              } else {
                newValue = value;
              }
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.region",
                value: newValue,
              });
            }}
            onChange={(value) => {
              props.actions.updateSharedLocationFormField({
                prop: "editing_shared_location.region",
                value: Constants.fromMultiSelectOption(value),
              });
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderEditForm() {
    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          <Grid container direction="row">
            <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item sx={AppStyles.actionHeaderLeft}>
                <BackButton
                  title={props.companySharedLocationsState.location_id ? "Standort bearbeiten" : "Neuer Standort"}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Grid>

              <Grid item sx={AppStyles.headerRight}>
                {props.companySharedLocationsState.location_id && (
                  <DefaultButton
                    color={"error"}
                    onClick={() => {
                      setState({ deleteConfirmationVisible: true });
                    }}
                  >
                    <ClearIcon sx={AppStyles.buttonLeftIcon} />
                    Standort löschen
                  </DefaultButton>
                )}

                <DefaultButton
                  style={{
                    marginLeft: props.companySharedLocationsState.location_id ? 10 : 0,
                  }}
                  onClick={() => {
                    saveChanges();
                  }}
                >
                  <SaveIcon sx={AppStyles.buttonLeftIcon} />
                  {props.companySharedLocationsState.location_id ? "Speichern" : "Erstellen"}
                </DefaultButton>
              </Grid>
            </Grid>
          </Grid>

          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm}>
              <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                <TextFieldWithLabel
                  required={true}
                  label={intl.get(`event_form.label.location.name`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.name",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.name}
                />
              </Grid>
              <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <TextFieldWithLabel
                  label={intl.get(`event_form.label.location.internal_name`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.internal_name",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.internal_name}
                />
              </Grid>

              <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                <TextFieldWithLabel
                  label={intl.get(`event_form.label.location.address`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.address",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.address}
                />
              </Grid>
              <Grid item xs={12} md={6} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <TextFieldWithLabel
                  label={intl.get(`event_form.label.location.additional_info`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.additional_info",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.additional_info}
                />
              </Grid>

              {renderCountry()}
              {renderState()}
              {renderRegion()}

              <Grid item xs={12} md={2} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                <TextFieldWithLabel
                  label={intl.get(`event_form.label.location.postal_code`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.postal_code",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.postal_code}
                />
              </Grid>

              {renderCity()}

              {renderDistrict()}

              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <TextFieldWithLabel
                  maxLength={1500}
                  rows={5}
                  multiline
                  maxRows={10}
                  label={intl.get(`event_form.label.directions`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.directions",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.directions}
                />
              </Grid>

              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <TextFieldWithLabel
                  maxLength={1500}
                  rows={5}
                  multiline
                  maxRows={10}
                  label={intl.get(`event_form.label.description`)}
                  margin="normal"
                  onChange={(event) => {
                    props.actions.updateSharedLocationFormField({
                      prop: "editing_shared_location.description",
                      value: event.target.value,
                    });
                  }}
                  value={props.companySharedLocationsState.editing_shared_location.description}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        {state.deleteConfirmationVisible && (
          <Dialog
            open={state.deleteConfirmationVisible}
            onClose={() => {
              setState({ deleteConfirmationVisible: false });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Standort löschen</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Möchten Sie den Standort wirklich löschen? (Der Standort wird bei bestehenden Freizeitangeboten NICHT
                entfernt, kann aber nach dem Löschen nicht mehr ausgewählt werden.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setState({ deleteConfirmationVisible: false });
                }}
                color="primary"
              >
                Nein, nicht löschen
              </Button>
              <Button onClick={() => deleteLocation()} color="error" autoFocus>
                Ja, löschen
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  return <>{renderEditForm()}</>;
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    companySharedLocationsState: state.companySharedLocationsState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanySharedLocationsActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanySharedLocationEditPage);
