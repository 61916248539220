import { Company } from "./company";
import { Error } from "./error";
import { ResellerProfile } from "./resellerProfile";

export enum LoginMethod {
  Facebook = "Facebook",
  Google = "Google",
  Email = "Email",
}

export interface User {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  permissions: string[];
  role: string;
  companies: Company[];
  b2b_profile: B2BProfile;
  status: string;
  signup_link?: string;
  confirmation_link?: string;
  login_method: string;
  has_rejected_events: boolean;
  supportEmail: string;
  reseller_profile?: ResellerProfile;
}

export interface B2BProfile {
  completed: boolean;
  created: boolean;
}

export class ForgotPasswordState {
  triggered: false;

  performing: false;

  error: Error | null = null;
}

export class ResetPasswordState {
  triggered: false;

  performing: false;

  error: Error | null = null;
}

export function getDisplayName(user: User): string {
  if (user.last_name.isEmpty() && user.first_name.isEmpty()) {
    return user.email;
  }
  if (!user.last_name.isEmpty() && !user.first_name.isEmpty()) {
    return `${user.first_name} ${user.last_name}`;
  }
  if (!user.first_name.isEmpty()) {
    return `${user.first_name}`;
  }
  return `${user.last_name}`;
}

export function getRoleForUser(user?: User, company?: Company): string {
  if (!user) {
    return "";
  }
  if (company && company.role) {
    return company.role;
  }
  return user.role;
}
