import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { CompanyState } from "../../model/companyState";
import { ActivityLogsState } from "../../model/activity.logs.state";
import * as ActivityLogsActions from "../../actions/activity.logs.actions";
import { RootState } from "../../reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import { DefaultButton } from "../../components/Buttons";
import { ActivityLog, formattedActivityLogType, formattedActivityLogUser } from "../../model/activity.log";
import moment from "moment";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import { SectionLabel } from "../../components/SectionLabel";
import { Constants } from "../components/forms/event_edit/event_options";
import SaveIcon from "@mui/icons-material/Save";
import { PageTracker } from "../../util/pageTracker";
import MySelect from "../../components/select/MySelect";
import AppStyles from "../../styles/appStyles";

export interface Props {
  companyState: CompanyState;
  activityLogsState: ActivityLogsState;
  actions: typeof ActivityLogsActions;
}

export interface State {
  text: string;
  type: string;
  loading: boolean;
}

class CompanyActivityLogsPage extends React.Component<Props, State> {
  state = {
    text: "",
    type: "note",
    loading: false,
  };

  componentDidMount(): void {
    this.props.actions.fetchActivityLogs(this.props.companyState.selected_company_id);
  }

  renderActivityLog(log: ActivityLog) {
    return (
      <TableRow key={log.log_id} hover>
        <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
          {moment(`${log.timestamp}`).locale("de").format("D. MMMM YYYY HH:mm")}
        </TableCell>

        <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
          {formattedActivityLogType(log)}
        </TableCell>
        <TableCell
          scope="row"
          padding="none"
          sx={AppStyles.tableCell}
          dangerouslySetInnerHTML={{ __html: log.text.replaceAll("\n", "<br/>") }}
        ></TableCell>
        <TableCell scope="row" padding="none" sx={AppStyles.tableCell}>
          {formattedActivityLogUser(log)}
        </TableCell>
      </TableRow>
    );
  }

  createNewActivityLogEntry() {
    this.setState({ loading: true });
    this.props.actions.createActivityLogEntry(
      this.props.companyState.selected_company_id,
      this.state.text,
      this.state.type,
      (success) => {
        if (success) {
          this.setState({ text: "", loading: false });
        } else {
          this.setState({ loading: false });
        }
      },
    );
  }

  render() {
    const mappedActivityLogEntries = this.props.activityLogsState.logs.map((value) => this.renderActivityLog(value));

    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.headerLeft}>
              <HeaderLabel>Aktivitäten</HeaderLabel>
            </Grid>
          </Grid>

          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm}>
              <Grid item xs={12} md={8} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
                <TextFieldWithLabel
                  maxLength={10000}
                  rows={5}
                  maxRows={5}
                  multiline
                  required={true}
                  label={"Text"}
                  margin="normal"
                  onChange={(event) => {
                    this.setState({ text: event.target.value });
                  }}
                  value={this.state.text}
                />
              </Grid>
              <Grid item xs={12} md={2} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <Grid item container direction="column">
                  <SectionLabel required={true}>{"Art"}</SectionLabel>
                  <MySelect
                    options={Constants.activityLogTypeOptions()}
                    isClearable
                    isSearchable
                    placeholder={"Art wählen"}
                    value={Constants.toSingleSelectOption(this.state.type, `activity_log_types`)}
                    onChange={(value) => {
                      this.setState({ type: value.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                container
                direction={"column"}
                justifyContent={"flex-start"}
                sx={AppStyles.detailFormRowWithSideBorder}
              >
                <SectionLabel>&nbsp;</SectionLabel>
                <DefaultButton
                  style={{}}
                  disabled={this.state.text.trim().length === 0 || this.state.loading}
                  onClick={() => {
                    this.createNewActivityLogEntry();
                  }}
                >
                  <SaveIcon sx={AppStyles.buttonLeftIcon} />
                  {"Erstellen"}
                </DefaultButton>
              </Grid>
            </Grid>
          </Paper>

          <Paper sx={AppStyles.innerContentPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell} style={{ width: 150 }}>
                    Datum
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell} style={{ width: 200 }}>
                    Typ
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell}>
                    Text
                  </TableCell>
                  <TableCell padding="none" sx={AppStyles.tableHeaderCell} style={{ width: 250 }}>
                    Ersteller
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mappedActivityLogEntries}</TableBody>
            </Table>
          </Paper>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    activityLogsState: state.activityLogsState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ActivityLogsActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyActivityLogsPage);
