import ContactSupportIcon from "../../../assets/icons/ContactSupport";
import { Stack, SxProps, Typography } from "@mui/material";
import intl from "react-intl-universal";

export default function BookingDrawerContactHintBox({ sx }: { sx?: SxProps }) {
  return (
    <Stack
      gap={"18px"}
      direction={"row"}
      sx={[
        (theme) => ({
          background: theme.palette.primary.light,
          padding: "19px",
          borderRadius: "5px",
        }),
        sx as any,
      ]}
    >
      <ContactSupportIcon sx={{ marginTop: "4px" }} />
      <Typography
        sx={(theme) => ({
          "& span > span": { color: theme.palette.primary.main },
          font: "normal normal normal 14px/18px Manrope",
        })}
      >
        {intl.getHTML("booking_drawer.general.hint_contact")}
      </Typography>
    </Stack>
  );
}
