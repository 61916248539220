import BookingDrawerLayout, { BookingDrawerLayoutProps } from "../BookingDrawerLayout";
import intl from "react-intl-universal";
import BookingDrawerEventHeader from "../components/BookingDrawerEventHeader";
import { Button, SxProps, Typography } from "@mui/material";
import useDialogReset from "../../dialog/useDialogReset";
import { useEffect, useState } from "react";
import TopPlacementDrawerBackendResponse from "./TopPlacementDrawerBackendResponse";
import ContainedTab from "../../tabs/ContainedTab";
import ContainedTabs from "../../tabs/ContainedTabs";
import { BCEventUpgradeType } from "../../../model/event";
import { useDispatch } from "react-redux";
import * as EventActions from "../../../actions/event";
import { BookingDrawerHandlerPayload } from "../BookingDrawer";
import PremiumDrawerInfoSection from "../premium/PremiumDrawerInfoSection";
import InfoBox, { InfoBoxLabelValueRow } from "../../InfoBox";
import { useDialogContext } from "../../dialog/DialogContext";
import useBackendLoader from "../../../hooks/useBackendLoader";
import BookingDrawerLoaderWrapper from "../components/BookingDrawerLoaderWrapper";
import BookingDrawerErrorContent from "../components/BookingDrawerErrorContent";

export interface Props extends BookingDrawerLayoutProps {
  onNeedToBookMoreSlots: () => void;
}

type UpgradeType = Omit<BCEventUpgradeType, "none">;

function getType(data: TopPlacementDrawerBackendResponse): UpgradeType {
  if (data.premium_plus.available_slots > 0) {
    return "premium_plus";
  } else {
    return "premium";
  }
}

export default function TopPlacementDrawerContent({ onNeedToBookMoreSlots, ...otherProps }: Props) {
  const { handler } = useDialogContext<BookingDrawerHandlerPayload>();
  const event = handler.payload?.event;
  const companyId = handler.payload?.companyId;

  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState<UpgradeType>("premium");

  const loader = useBackendLoader<TopPlacementDrawerBackendResponse>(
    `/v2/api/b2b/private/companies/${companyId}/events/${event.event_id}/upgrade`,
    undefined,
    "top_placement_drawer",
  );

  useEffect(() => {
    if (loader.data) {
      setSelectedType(getType(loader.data) || "premium");
    }
  }, [loader.data]);

  useDialogReset(handler, () => {
    loader.reload();
  });

  const performUpgradeChange = () => {
    dispatch<any>(
      EventActions.changeEventUpgrade(companyId, event.event_id, selectedType as string, () => {
        onNeedToBookMoreSlots();
      }),
    );
    handler.close();
  };

  const cancelCurrentUpgrade = () => {
    dispatch<any>(EventActions.changeEventUpgrade(companyId, event.event_id, "none", () => {}));
    handler.close();
  };

  const hasActiveType =
    loader.data?.premium.status === "UPGRADE_ACTIVE" || loader.data?.premium_plus.status === "UPGRADE_ACTIVE";

  if (loader.error) {
    return <BookingDrawerErrorContent error={loader.error} infoSection={<PremiumDrawerInfoSection />} />;
  }

  return (
    <BookingDrawerLayout {...otherProps} infoSection={<PremiumDrawerInfoSection />}>
      <BookingDrawerEventHeader event={event} sx={{ marginBottom: "41px" }} />
      <BookingDrawerLoaderWrapper loader={loader}>
        <>
          {loader.data && !hasActiveType && (
            <ContentSelectUpgrade
              value={selectedType}
              onChange={setSelectedType}
              onSaveClicked={performUpgradeChange}
              onGoToBookingClicked={onNeedToBookMoreSlots}
              data={loader.data}
            />
          )}
          {loader.data && hasActiveType && (
            <ContentUpgradeActive value={selectedType} onCancelTopPlacement={cancelCurrentUpgrade} data={loader.data} />
          )}
        </>
      </BookingDrawerLoaderWrapper>
    </BookingDrawerLayout>
  );
}

function ContentSelectUpgrade({
  value,
  onChange,
  onSaveClicked,
  data,
  onGoToBookingClicked,
}: {
  value: UpgradeType;
  onChange: (newValue: UpgradeType) => void;
  onSaveClicked: () => void;
  onGoToBookingClicked: () => void;
  data: TopPlacementDrawerBackendResponse;
}) {
  const needMoreSlots =
    (value === "premium" && data.premium.available_slots <= 0) ||
    (value === "premium_plus" && data.premium_plus.available_slots <= 0);

  return (
    <>
      <Typography sx={{ marginTop: "41px", font: "normal normal bold 18px/21px Manrope" }}>
        {intl.get("booking_drawer.top_placement.title_select_upgrade")}
      </Typography>

      <UpgradeSelection value={value} onChange={onChange} />

      <AvailableSlotsInfoBox
        availableSlots={value === "premium_plus" ? data.premium_plus.available_slots : data.premium.available_slots}
        sx={{ marginTop: "21px" }}
      />

      {needMoreSlots && (
        <>
          <Typography sx={{ marginTop: "21px", font: "normal normal normal 14px/18px Manrope" }}>
            {intl.get("booking_drawer.top_placement.hint_need_more_slots")}
          </Typography>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={onGoToBookingClicked}
            fullWidth
            sx={{ marginTop: "21px" }}
          >
            {intl.get("booking_drawer.top_placement.button_go_to_booking")}
          </Button>
        </>
      )}

      {!needMoreSlots && (
        <Button color={"primary"} onClick={onSaveClicked} sx={{ marginTop: "21px" }} fullWidth>
          {intl.get("booking_drawer.top_placement.button_save")}
        </Button>
      )}
    </>
  );
}

function ContentUpgradeActive({
  value,
  onCancelTopPlacement,
  data,
}: {
  value: UpgradeType;
  onCancelTopPlacement: () => void;
  data: TopPlacementDrawerBackendResponse;
}) {
  return (
    <>
      <Typography sx={{ marginTop: "41px", font: "normal normal bold 18px/21px Manrope" }}>
        {intl.get("booking_drawer.top_placement.title_active_upgrade")}
      </Typography>

      <UpgradeSelection value={value} singleElement={value} />

      <AvailableSlotsInfoBox
        availableSlots={value === "premium_plus" ? data.premium_plus.available_slots : data.premium.available_slots}
        sx={{ marginTop: "21px" }}
      />

      <Typography sx={{ marginTop: "21px", font: "normal normal normal 14px/18px Manrope" }}>
        {intl.get("booking_drawer.top_placement.hint_active_upgrade")}
      </Typography>

      <Button fullWidth variant={"outlined"} sx={{ marginTop: "21px" }} onClick={onCancelTopPlacement}>
        {intl.get("booking_drawer.top_placement.button_cancel_current")}
      </Button>
    </>
  );
}

function UpgradeSelection({
  value,
  onChange,
  singleElement,
}: {
  value: UpgradeType;
  onChange?: (newValue: UpgradeType) => void;
  singleElement?: UpgradeType;
}) {
  return (
    <ContainedTabs
      sx={{ marginTop: "21px" }}
      variant={"fullWidth"}
      value={value}
      onChange={onChange ? (event, newValue) => onChange(newValue) : undefined}
      color={"primary"}
      indicatorColor={"primary"}
      textColor={"primary"}
    >
      {(!singleElement || singleElement === "premium") && (
        <ContainedTab
          value={"premium"}
          label={intl.get("booking_drawer.top_placement.option_premium")}
          key={"type_premium"}
        />
      )}
      {(!singleElement || singleElement === "premium_plus") && (
        <ContainedTab
          value={"premium_plus"}
          label={intl.get("booking_drawer.top_placement.option_premium_plus")}
          key={"type_premium_plus"}
        />
      )}
    </ContainedTabs>
  );
}

function AvailableSlotsInfoBox({ sx, availableSlots }: { sx?: SxProps; availableSlots: number }) {
  return (
    <InfoBox sx={sx}>
      <InfoBoxLabelValueRow
        label={intl.get("booking_drawer.top_placement.label_available_slots")}
        value={availableSlots}
      />
    </InfoBox>
  );
}
