import { Box, Tab, TabProps } from "@mui/material";

interface Props extends TabProps {
  badge?: any;
  badgeInset?: number;
}

export default function BadgeTab(props: Props) {
  return <Tab component={BadgeTabComponent} {...props} />;
}

function BadgeTabComponent({ badge, badgeInset, children, ...props }: Props) {
  return (
    <Box {...props}>
      {children}
      <Box sx={{ position: "absolute", top: badgeInset || 5, right: badgeInset || 5 }}>{badge}</Box>
    </Box>
  );
}
