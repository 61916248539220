import BadgeTab from "../tabs/BadgeTab";
import { Box, TabProps } from "@mui/material";
import CheckmarkIcon from "../../assets/icons/Checkmark";

interface Props extends Pick<TabProps, "label" | "icon"> {
  missingData?: boolean;
}

export default function EventFormTab({ missingData, ...props }: Props) {
  let badgeIcon = null;
  let badgeInset = 5;

  switch (missingData) {
    case true:
      badgeIcon = <Box sx={{ background: "#ff9314", width: 10, height: 10, borderRadius: "50%" }} />;
      badgeInset = 10;
      break;
    case false:
      badgeIcon = (
        <Box
          sx={{
            background: "#33B592",
            width: 20,
            height: 20,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckmarkIcon sx={{ color: "#fff", height: "8px" }} />
        </Box>
      );
      break;
    default:
      break;
  }

  return (
    <BadgeTab
      {...props}
      sx={{ minHeight: "82px", minWidth: "157px", "& .MuiTab-iconWrapper": { marginBottom: "9px" } }}
      badge={badgeIcon}
      badgeInset={badgeInset}
    />
  );
}
