import { Grid, MenuItem, Paper, Select } from "@mui/material";
import AppStyles from "../../../styles/appStyles";
import { SectionLabel } from "../../../components/SectionLabel";
import intl from "react-intl-universal";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { EventFormState } from "../../../model/eventFormState";

const classes = {
  select: {
    paddingTop: "6px",
    paddingBottom: "7px",
    marginTop: "16px",
  },
};

interface Props {
  rejectionDescription: string;
  onRejectionDescriptionChanged: (newValue: string) => void;
}

export default function EventReviewForm({ rejectionDescription, onRejectionDescriptionChanged }: Props) {
  const eventFormState = useSelector<RootState, EventFormState>((rootState) => {
    return rootState.eventFormState;
  });

  if (eventFormState.event.status === "READY_FOR_REVIEW" || eventFormState.event.status === "PUBLISHED") {
    return (
      <Grid item xs={12}>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" spacing={3} sx={AppStyles.detailForm}>
            <Grid item container direction="column" sx={AppStyles.noRightPadding}>
              <SectionLabel required={false}>{intl.get("event_review.label.add_reject_reason")}</SectionLabel>
              <Select
                onChange={(event) => {
                  const reason = event.target.value as string;
                  onRejectionDescriptionChanged(
                    `${intl.get(`event_review.reject_reasons.${reason}.long`)}\n\n${rejectionDescription}`,
                  );
                }}
                displayEmpty
                sx={classes.select}
                inputProps={{
                  name: "reject_reasons",
                  id: "reject_reasons",
                }}
              >
                <MenuItem value="text_incomplete">
                  {intl.get("event_review.reject_reasons.text_incomplete.short")}
                </MenuItem>
                <MenuItem value="text_inappropriate">
                  {intl.get("event_review.reject_reasons.text_inappropriate.short")}
                </MenuItem>
                <MenuItem value="images_inappropriate">
                  {intl.get("event_review.reject_reasons.images_inappropriate.short")}
                </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} container direction="column">
              <TextFieldWithLabel
                value={rejectionDescription}
                onChange={(event: any) => {
                  onRejectionDescriptionChanged(event.target.value);
                }}
                required
                label={intl.get("event_review.label.rejection_description")}
                id="description"
                multiline
                rows={10}
                margin="normal"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return null;
}
