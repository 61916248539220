import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { EventFormState } from "../../../../../model/eventFormState";
import * as EventFormActions from "../../../../../actions/eventForm";
import { SectionLabel } from "../../../../../components/SectionLabel";
import AppStyles from "../../../../../styles/appStyles";

export function EventEditRadioField(props: {
  formState: EventFormState;
  formActions: typeof EventFormActions;
  field: string;
  options: string[];
  disabled?: boolean;
  required?: boolean;
  hideLabel?: boolean;
}) {
  const mappedOptions = props.options.map((option) => (
    <FormControlLabel
      key={option}
      value={option}
      control={
        <Radio
          color="primary"
          disabled={props.formState.readonly || props.disabled}
          checked={props.formState.event[`${props.field}`] === option}
        />
      }
      label={intl.get(`event_constants.${props.field}.${option}`)}
    />
  ));

  return (
    <Grid item container direction="column">
      {(props.hideLabel === undefined || props.hideLabel === false) && (
        <SectionLabel required={props.required ?? false}>{intl.get(`event_form.label.${props.field}`)}</SectionLabel>
      )}
      <Box sx={AppStyles.formInputContainer}>
        <RadioGroup
          color="primary"
          aria-label="Type"
          name="type"
          onChange={(event) => {
            props.formActions.updateEventFormField({
              prop: `event.${props.field}`,
              value: event.target.value,
            });
          }}
          row
        >
          {mappedOptions}
        </RadioGroup>
      </Box>
    </Grid>
  );
}
