import { Box, Grid, Theme, Typography } from "@mui/material";
import * as intl from "react-intl-universal";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import { DefaultButton, DeleteButton } from "../../../components/Buttons";
import MediaCreditsEditor from "./MediaCreditsEditor";
import { asNonEmptyString } from "../../../util/util_extensions";
import { Photo } from "../../../model/media";

const classes = {
  overlay: {
    position: "absolute",
    maxHeight: "150px",
    bottom: "0",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    opacity: 0.0,
    "-webkit-transition": "all 250ms ease-in-out",
    "-moz-transition": "all 250ms ease-in-out",
    "-ms-transition": "all 250ms ease-in-out",
    "-o-transition": "all 250ms ease-in-out",
    transition: "all 250ms ease-in-out",
    "&:hover": {
      opacity: 1.0,
    },
  },
  favoriteOverlay: (theme: Theme) => ({
    position: "absolute",
    top: "0",
    width: "100%",
    padding: "5px",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  }),
  creditsContainer: {
    padding: 0,
    right: 0,
    transformOrigin: "top left",
    marginTop: 5,
    marginLeft: -5,
    width: "100%s",
    textAlign: "center",
    transform: "rotate(-90deg) translate(-100%)",
  },
  credits: (theme: Theme) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#646464",
    fontSize: theme.fontSizes.verySmallFont,
  }),
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const cont = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
  left: null,
  top: null,
};

interface BaseProps {
  photoIndex: any;
  photo: Photo;
  onClick: (event: any) => void;
  onSaveCreditsInfo?: any;
  onDelete?: any;
  onMakeAsCover?: (event: any, object: any) => void;
  disableControls: boolean;
  managementView: boolean;
  style?: any;
  children?: any;
}
interface RowDirectionProps extends BaseProps {
  direction: "row";
}

interface ColumnDirectionProps extends BaseProps {
  direction: "column";
  top: any;
  left: any;
}

export type Props = ColumnDirectionProps | RowDirectionProps;

export interface State {}

class MediaImageWrapper extends React.Component<Props> {
  state = {
    hover: false,
    editDialogVisible: false,
    credits: "",
  };

  closeEditDialog() {
    this.setState({
      editDialogVisible: false,
      activeMediaItem: null,
      mediaItemCopyright: null,
    });
  }

  onSaveCreditsInfo(value: string) {
    const { photo, photoIndex } = this.props;
    this.props.onSaveCreditsInfo(value, { index: photoIndex, photo });
  }

  editCredits() {
    const { photo } = this.props;
    this.setState({
      activeMediaItem: photo,
      credits: asNonEmptyString(photo.credits) ? photo.credits : "",
      editDialogVisible: true,
    });
  }

  renderCreditsDialog() {
    if (this.state.editDialogVisible) {
      return (
        <MediaCreditsEditor
          credits={this.state.credits}
          onSave={(value: any) => {
            this.onSaveCreditsInfo(value);
          }}
          onCancel={() => {
            this.closeEditDialog();
          }}
        />
      );
    }
    return null;
  }

  render() {
    if (this.props.direction === "column") {
      cont.position = "absolute";
      cont.left = this.props.left;
      cont.top = this.props.top;
    }

    const c: any = cont;

    const opacity = this.state.hover ? 1 : 0;

    return (
      <div style={{ ...this.props.style }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              ...c,
              width: "100%",
              height: "100%",
            }}
            onMouseEnter={() => {
              if (this.props.disableControls === false) {
                this.setState({ hover: true });
              }
            }}
            onMouseLeave={() => {
              if (this.props.disableControls === false) {
                this.setState({ hover: false });
              }
            }}
          >
            <Box sx={classes.imageContainer} onClick={this.props.onClick}>
              {this.props.children}
            </Box>
            {this.props.onMakeAsCover && this.props.photoIndex === 0 && (
              <Box sx={classes.favoriteOverlay}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <StarIcon style={{ color: "#fff" }} />
                  <Typography
                    variant="subtitle1"
                    sx={(theme) => ({
                      color: "#fff",
                      fontSize: theme.fontSizes.smallFont,
                    })}
                  >
                    {intl.get("event_form.label.main_image")}
                  </Typography>
                </Grid>
              </Box>
            )}
            {(this.props.onSaveCreditsInfo || this.props.onDelete || this.props.onMakeAsCover) &&
              (this.props.photo.media.is_external === false ||
                this.props.managementView === false ||
                (this.props.onMakeAsCover && this.props.photoIndex !== 0)) && (
                <Box sx={classes.overlay} style={{ padding: "5px", opacity }}>
                  <Grid item container direction="row" justifyContent="space-evenly" alignItems="center">
                    {this.props.onMakeAsCover && this.props.photoIndex !== 0 && (
                      <DefaultButton
                        color={"secondary"}
                        disabled={false}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          this.props.onMakeAsCover(e, {
                            index: this.props.photoIndex,
                            photo: this.props.photo,
                          });
                        }}
                      >
                        <StarIcon />
                      </DefaultButton>
                    )}
                    {this.props.onSaveCreditsInfo && this.props.photo.media.is_external === false && (
                      <DefaultButton
                        variant="contained"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          this.editCredits();
                        }}
                      >
                        <EditIcon />
                      </DefaultButton>
                    )}
                    {this.props.onDelete &&
                      (this.props.photo.media.is_external === false || this.props.managementView === false) && (
                        <DeleteButton
                          disabled={false}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            this.props.onDelete(e, {
                              index: this.props.photoIndex,
                              photo: this.props.photo,
                            });
                          }}
                        >
                          <ClearIcon />
                        </DeleteButton>
                      )}
                  </Grid>
                </Box>
              )}
          </div>
          {this.props.photo.credits != null && !this.props.photo.credits.isEmpty() && (
            <Box sx={classes.creditsContainer}>
              <Typography variant="subtitle1" sx={classes.credits}>
                {this.props.photo.credits}
              </Typography>
            </Box>
          )}
        </div>
        {this.renderCreditsDialog()}
      </div>
    );
  }
}

export default MediaImageWrapper;
