import createReducer from "./createReducer";
import { cloneDefaultState } from "../util/util_extensions";
import { Action, ActionType } from "../model/actions";
import { CompanyReportingState } from "../model/company.reporting.state";

const defaultState = {
  loading: true,
  failed: false,
  reportingResult: null,
};

export const companyReportingState = createReducer(cloneDefaultState(defaultState), {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionType.RESET_COMPANY_REPORTING_RESULT](state: CompanyReportingState) {
    return {
      loading: true,
      failed: false,
      reportingResult: null,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ActionType.FAILED_COMPANY_REPORTING_RESULT](state: CompanyReportingState) {
    return {
      loading: false,
      failed: true,
      reportingResult: null,
    };
  },
  [ActionType.LOADED_COMPANY_REPORTING_RESULT](state: CompanyReportingState, action: Action<any>) {
    return {
      loading: false,
      failed: false,
      reportingResult: action.payload.result,
    };
  },
});
