import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import * as intl from "react-intl-universal";
import * as React from "react";
import { EventFormState } from "../../../../model/eventFormState";
import { allowedEventMonthValues } from "../../../../model/event";
import AppStyles from "../../../../styles/appStyles";

export interface BaseProps {
  eventFormState: EventFormState;
  selectedPromotionMonths: any | null;
  changeSelectedPromotionMonths: any | null;
}

export interface Props extends BaseProps {}

class EventPromotionTimes extends React.Component<Props> {
  state = {};

  render() {
    const disableControls = this.props.eventFormState.readonly;

    const selectedMonths = this.props.selectedPromotionMonths();
    const isWinter =
      selectedMonths &&
      selectedMonths.length == 6 &&
      selectedMonths.includes("nov") &&
      selectedMonths.includes("dec") &&
      selectedMonths.includes("jan") &&
      selectedMonths.includes("feb") &&
      selectedMonths.includes("mar") &&
      selectedMonths.includes("apr");
    const isSummer =
      selectedMonths &&
      selectedMonths.length == 6 &&
      selectedMonths.includes("may") &&
      selectedMonths.includes("jun") &&
      selectedMonths.includes("jul") &&
      selectedMonths.includes("aug") &&
      selectedMonths.includes("sep") &&
      selectedMonths.includes("oct");
    const isAllYear = selectedMonths.length === 12;

    return (
      <>
        <Grid item xs={12} container direction="row" spacing={3}>
          <Grid item>
            <Typography
              component="h5"
              variant="h5"
              color="secondary"
              sx={(theme) => ({
                fontSize: theme.fontSizes.smallFont,
                marginBottom: "5px",
              })}
            >
              {intl.get("event_form.promotion.description_for_recurring_event")}
            </Typography>

            <Box sx={AppStyles.formInputContainer}>
              <RadioGroup
                color="primary"
                aria-label="Type"
                name="type"
                onChange={(event) => {
                  if (event.target.value === "winter") {
                    this.props.changeSelectedPromotionMonths(["nov", "dec", "jan", "feb", "mar", "apr"]);
                  } else if (event.target.value === "summer") {
                    this.props.changeSelectedPromotionMonths(["may", "jun", "jul", "aug", "sep", "oct"]);
                  } else if (event.target.value === "all_year") {
                    this.props.changeSelectedPromotionMonths([
                      "jan",
                      "feb",
                      "mar",
                      "apr",
                      "may",
                      "jun",
                      "jul",
                      "aug",
                      "sep",
                      "oct",
                      "nov",
                      "dec",
                    ]);
                  }
                }}
                row
              >
                <FormControlLabel
                  value={"winter"}
                  control={<Radio color="primary" disabled={this.props.eventFormState.readonly} checked={isWinter} />}
                  label={"Winter"}
                />
                <FormControlLabel
                  value={"summer"}
                  control={<Radio color="primary" disabled={this.props.eventFormState.readonly} checked={isSummer} />}
                  label={"Sommer"}
                />
                <FormControlLabel
                  value={"all_year"}
                  control={<Radio color="primary" disabled={this.props.eventFormState.readonly} checked={isAllYear} />}
                  label={"Ganzjährig"}
                />
              </RadioGroup>
            </Box>

            <Box sx={AppStyles.toggleContainer}>
              <ToggleButtonGroup
                value={this.props.selectedPromotionMonths()}
                onChange={(event, value) => {
                  this.props.changeSelectedPromotionMonths(value);
                }}
              >
                {allowedEventMonthValues.map((month) => {
                  return (
                    <ToggleButton disabled={disableControls} key={month} value={month}>
                      {intl.get(`months.${month}`)}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default EventPromotionTimes;
