import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Paper } from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { ResellerProfileFormState } from "../../../model/resellerProfileFormState";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import { SectionLabel } from "../../../components/SectionLabel";
import { PageState, PageStateType } from "../../../model/pageState";
import { AuthenticationState } from "../../../model/authenticationState";
import { RootState } from "../../../reducers";
import * as ResellerProfileActions from "../../../actions/resellerProfile";
import { ResellerProfileState } from "../../../model/resellerProfileState";
import { Constants } from "./event_edit/event_options";
import { ResellerProfileEditSingleSelectField } from "./event_edit/fields/ResellerProfileEditSingleSelectField";
import AppStyles from "../../../styles/appStyles";

export interface Props {
  resellerProfileFormState: ResellerProfileFormState;
  resellerProfileState: ResellerProfileState;
  pageState: PageState;
  actions: typeof ResellerProfileActions;
  readonly: boolean;
  authenticationState: AuthenticationState;
  children?: any;
}

export interface State {
  tab: number;
}

class ResellerProfileEditForm extends React.Component<Props> {
  state = {
    profile_id: null,
  };

  handleChange = (propName: any) => (event: any) => {
    this.props.actions.updateResellerProfileFormField({ prop: propName, value: event.target.value });
  };

  handleCheckboxChange = (propName: any) => (event: any) => {
    this.props.actions.updateResellerProfileFormField({ prop: propName, value: event.target.checked });
  };

  handleIntNumberChange = (propName: any) => (event: any) => {
    const regExpression = /^\d+$/;
    let value = null;
    if (event.target.value) {
      if (regExpression.test(event.target.value)) {
        value = Number.parseInt(event.target.value, undefined);
      }
    }
    this.props.actions.updateResellerProfileFormField({ prop: propName, value: value });
  };

  changeTab = (event: any, tab: any) => {
    this.setState({ tab });
  };

  renderName(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_name")}
        id="name"
        onChange={this.handleChange("profile.name")}
        value={this.props.resellerProfileFormState.profile.name}
        margin="normal"
        error={invalidFields.includes("name")}
      />
    );
  }

  renderIBAN(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_iban")}
        id="iban"
        onChange={this.handleChange("profile.account_iban")}
        value={this.props.resellerProfileFormState.profile.account_iban}
        margin="normal"
        error={invalidFields.includes("iban")}
      />
    );
  }

  rendervat_id(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_vat_id")}
        id="vat_id"
        onChange={this.handleChange("profile.vat_id")}
        value={this.props.resellerProfileFormState.profile.vat_id}
        margin="normal"
        error={invalidFields.includes("vat_id")}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderCountrySelection(invalidFields: string[]) {
    return (
      <ResellerProfileEditSingleSelectField
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        formState={this.props.resellerProfileFormState}
        formActions={this.props.actions}
        options={Constants.addressCountryCodeOptions()}
        field={"country_code"}
        onChange={(value) => {
          if (value) {
            this.props.actions.updateResellerProfileFormField({
              prop: "profile.country",
              value: value.label,
            });
          } else {
            this.props.actions.updateResellerProfileFormField({
              prop: "profile.country",
              value: null,
            });
          }
        }}
      />
    );
  }

  renderaddress_line_1(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_address_line_1")}
        id="address_line_1"
        onChange={this.handleChange("profile.address_line_1")}
        value={this.props.resellerProfileFormState.profile.address_line_1}
        margin="normal"
        error={invalidFields.includes("address_line_1")}
      />
    );
  }

  renderaddress_line_2(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_address_line_2")}
        id="address_line_2"
        onChange={this.handleChange("profile.address_line_2")}
        value={this.props.resellerProfileFormState.profile.address_line_2}
        margin="normal"
        error={invalidFields.includes("address_line_2")}
      />
    );
  }

  renderaccount_name(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_account_name")}
        id="account_name"
        onChange={this.handleChange("profile.account_name")}
        value={this.props.resellerProfileFormState.profile.account_name}
        margin="normal"
        error={invalidFields.includes("account_name")}
      />
    );
  }

  renderCalculateVATCheckbox() {
    return (
      <>
        <SectionLabel>{intl.get("ResellerProfile_form.label_calcVat")}</SectionLabel>
        <FormGroup>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
                checked={this.props.resellerProfileFormState.profile.should_calc_vat !== false}
                onChange={this.handleCheckboxChange("profile.should_calc_vat")}
              />
            }
            label={intl.get("ResellerProfile_form.checkbox_calcVat")}
          />
        </FormGroup>
      </>
    );
  }

  rendercontinuous_commission_months(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_continuous_commission_months")}
        id="continuous_commission_months"
        onChange={this.handleIntNumberChange("profile.continuous_commission_months")}
        value={this.props.resellerProfileFormState.profile.continuous_commission_months}
        margin="normal"
        error={invalidFields.includes("continuous_commission_months")}
      />
    );
  }

  rendercontinuous_commission_percentage(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly || !this.userHasManageResellerProfilesPermission()}
        label={intl.get("ResellerProfile_form.label_continuous_commission_percentage")}
        id="continuous_commission_percentage"
        onChange={this.handleIntNumberChange("profile.continuous_commission_percentage")}
        value={this.props.resellerProfileFormState.profile.continuous_commission_percentage}
        margin="normal"
        error={invalidFields.includes("continuous_commission_percentage")}
      />
    );
  }

  userHasManageResellerProfilesPermission() {
    return this.props.authenticationState.user.permissions.includes("MANAGE_RESELLER_PROFILES");
  }

  render() {
    if (this.props.pageState.state === PageStateType.Loading) {
      return <></>;
    }

    let invalidFields: string[] = [];
    if (this.props.resellerProfileFormState.error && this.props.resellerProfileFormState.error.validationErrors) {
      invalidFields = this.props.resellerProfileFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }

    return (
      <>
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          {this.props.children}
          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm} spacing={4}>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.renderName(invalidFields)}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent="center"
                direction="column"
                sx={AppStyles.detailFormRow}
              >
                {this.renderCalculateVATCheckbox()}
              </Grid>
            </Grid>
            <Grid item container direction="row" sx={AppStyles.detailForm} spacing={4}>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.rendervat_id(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.renderCountrySelection(invalidFields)}
              </Grid>
            </Grid>
            <Grid item container direction="row" sx={AppStyles.detailForm} spacing={4}>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.renderaddress_line_1(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.renderaddress_line_2(invalidFields)}
              </Grid>
            </Grid>
            <Grid item container direction="row" sx={AppStyles.detailForm} spacing={4}>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.renderaccount_name(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.renderIBAN(invalidFields)}
              </Grid>
            </Grid>
          </Paper>

          <br />
          <br />
          <Box sx={AppStyles.actionHeaderLeft}>
            <SectionLabel>{intl.get("ResellerProfile_form.label_title_contract")}</SectionLabel>
          </Box>
          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm} spacing={4}>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.rendercontinuous_commission_percentage(invalidFields)}
              </Grid>
              <Grid item xs={12} sm={6} container direction="column" sx={AppStyles.detailFormRow}>
                {this.rendercontinuous_commission_months(invalidFields)}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    resellerProfileFormState: state.resellerProfileFormState,
    resellerProfileState: state.resellerProfileState,
    pageState: state.pageState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ResellerProfileActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ResellerProfileEditForm);
