import { Grid, Paper, Stack, Typography } from "@mui/material";
import { EventFormState } from "../../../../model/eventFormState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import * as React from "react";
import EventDedicatedTimes from "./EventDedicatedTimes";
import EventRecurringRuleEdit from "./EventRecurringRuleEdit";
import { BCEventType } from "../../../../model/event";
import intl from "react-intl-universal";
import EventPromotionTimes from "./EventPromotionTimes";
import { EventEditMultilineTextInputField } from "./fields/EventEditMultilineTextInputField";
import { FormGridItem } from "./FormGridItem";
import AppStyles from "../../../../styles/appStyles";

export interface Props {
  eventFormState: EventFormState;
  actions: any;
  authenticationState: AuthenticationState;
  eventFormActions: typeof EventFormActions;
  removeDedicatedTime: any;
  addDedicatedTime: any;
  selectedPromotionMonths: any | null;
  changeSelectedPromotionMonths: any | null;
}

class EventOpeningAndVisibilityEdit extends React.Component<Props> {
  renderRecurringEventEdit() {
    return (
      <Paper sx={AppStyles.innerContentPaper}>
        <EventRecurringRuleEdit />
      </Paper>
    );
  }

  renderDedicatedEventEdit() {
    return (
      <Paper sx={AppStyles.innerContentPaper}>
        <EventDedicatedTimes
          eventFormActions={this.props.eventFormActions}
          eventFormState={this.props.eventFormState}
          removeDedicatedTime={this.props.removeDedicatedTime}
          addDedicatedTime={this.props.addDedicatedTime}
        />
      </Paper>
    );
  }

  renderOpeningHourNotes() {
    return (
      <EventEditMultilineTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"notes_opening_hours"}
        maxLength={500}
        rows={5}
      />
    );
  }

  render() {
    return (
      <Stack>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.opening_hours`)}</b>
          </Typography>
        </Grid>
        {this.props.eventFormState.event.type === BCEventType.Recurring && this.renderRecurringEventEdit()}
        {this.props.eventFormState.event.type === BCEventType.Dedicated && this.renderDedicatedEventEdit()}
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <FormGridItem>{this.renderOpeningHourNotes()}</FormGridItem>
          </Grid>
        </Paper>
        <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
          <Typography>
            <b>{intl.get(`event_form.sections.visibility`)}</b>
          </Typography>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <EventPromotionTimes
              eventFormState={this.props.eventFormState}
              selectedPromotionMonths={this.props.selectedPromotionMonths}
              changeSelectedPromotionMonths={this.props.changeSelectedPromotionMonths}
            />
          </Grid>
        </Paper>
      </Stack>
    );
  }
}

export default EventOpeningAndVisibilityEdit;
