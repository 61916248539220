import { formattedAmount, GlobalInvoiceStatus } from "../../model/global_invoices/global.invoice.status";
import { Box, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import intl from "react-intl-universal";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ReactTooltip from "react-tooltip";
import AppStyles from "../../styles/appStyles";
import { LinkButton } from "../Buttons";
import * as React from "react";

export default function GlobalInvoiceCell({
  invoice,
  onShowCharges,
  onMarkAsPaid,
}: {
  invoice: GlobalInvoiceStatus;
  onShowCharges: () => void;
  onMarkAsPaid: () => void;
}) {
  const cellClassName = invoice.status !== "PAID" ? AppStyles.tableCellWarning : AppStyles.tableCell;

  return (
    <TableRow key={invoice.invoice_number} hover>
      <TableCell component="th" scope="row" sx={cellClassName}>
        {invoice.company_name}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellClassName}>
        {invoice.invoice_number}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellClassName}>
        {moment(invoice.normalized_invoice_date).locale("de").format("LL")}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellClassName}>
        {formattedAmount(invoice.amount_in_cents, invoice.currency)}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellClassName}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "stretch",
          }}
        >
          {intl.get(`global_invoices.status.${invoice.status}`)}
          <>
            <a style={{ marginLeft: 5 }} data-tip data-for={`global_invoice_status_${invoice.invoice_number}`}>
              <InfoIcon />
            </a>
            <ReactTooltip
              id={`global_invoice_status_${invoice.invoice_number}`}
              aria-haspopup="true"
              role="example"
              place="bottom"
            >
              <div>{intl.getHTML(`global_invoices.status_tooltip.${invoice.status}`)}</div>
            </ReactTooltip>
          </>
        </Box>
      </TableCell>
      <TableCell padding="none" sx={AppStyles.tableCell} align={"center"}>
        {invoice.paymentResponses.length > 0 && (
          <LinkButton
            onClick={(event: any) => {
              event.stopPropagation();
              onShowCharges();
            }}
            title={intl.get("global_invoices.action_show_payments")}
          />
        )}
      </TableCell>
      <TableCell padding="none" sx={AppStyles.tableCell} align={"center"}>
        {invoice.status !== "PAID" && (
          <LinkButton
            onClick={(event: any) => {
              event.stopPropagation();
              onMarkAsPaid();
            }}
            title={intl.get("global_invoices.action_mark_as_paid")}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
