import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { MediaState } from "../model/mediaState";

export const mediaState = createReducer(
  {
    error: null,
    // paging: null,
    media: [],
  },
  {
    [ActionType.FETCH_COMPANY_MEDIA_ITEMS](state: MediaState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          media: action.payload.media,
        };
      }
      return { ...state };
    },
    [ActionType.DELETE_COMPANY_MEDIA_ITEM](state: MediaState, action: Action<any>) {
      if (action.payload.media_item_id) {
        const newState = { ...state };
        const index = newState.media.findIndex((value) => value.media_id === action.payload.media_item_id);
        newState.media.splice(index, 1);
        return newState;
      }
      return state;
    },
    [ActionType.UPDATE_COMPANY_MEDIA_ITEM](state: MediaState, action: Action<any>) {
      if (action.payload.media_item) {
        const newState = { ...state };
        const index = newState.media.findIndex((value) => value.media_id === action.payload.media_item.media_id);
        newState.media[index] = action.payload.media_item;
        return newState;
      }
      return state;
    },
    [ActionType.UPLOAD_COMPANY_MEDIA_ITEM](state: MediaState, action: Action<any>) {
      if (action.payload.data === true) {
        const newState = { ...state };
        newState.media.unshift(action.payload.media_item);
        return newState;
      }
      return state;
    },
    [ActionType.CLOSE_COMPANY]() {
      return {
        media: [],
      };
    },
  },
);
