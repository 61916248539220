import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { AuthenticationState } from "../model/authenticationState";

export const authenticationState = createReducer(
  { user: null, loading: false, authenticated: false },
  {
    [ActionType.FETCH_USER](state: AuthenticationState, action: Action<any>) {
      return {
        ...state,
        user: action.payload,
        authenticated: true,
        loading: false,
      };
    },
    [ActionType.AUTH_PROGRESS](state: AuthenticationState) {
      return { ...state, loading: true };
    },
    [ActionType.AUTH_ERROR](state: AuthenticationState, action: Action<any>) {
      const error = action.payload;
      if (error.error) {
        return {
          ...state,
          authenticated: false,
          loading: false,
          error: error.error,
        };
      }
      return { ...state, authenticated: false, loading: false, error: null };
    },
    [ActionType.AUTH_SUCCESS](state: AuthenticationState, action: Action<any>) {
      return {
        ...state,
        user: action.payload,
        authenticated: true,
        loading: false,
      };
    },
    [ActionType.SIGNUP_PROGRESS](state: AuthenticationState) {
      return { ...state, loading: true };
    },
    [ActionType.SIGNUP_ERROR](state: AuthenticationState, action: Action<any>) {
      const error = action.payload;
      if (error.error) {
        return {
          ...state,
          authenticated: false,
          loading: false,
          error: error.error,
        };
      }
      return { ...state, authenticated: false, loading: false, error: null };
    },
    [ActionType.SIGNUP_SUCCESS](state: AuthenticationState, action: Action<any>) {
      return {
        user: null,
        authenticated: false,
        loading: false,
        waitingForActivation: action.payload.needs_activation,
        finishedActivation: action.payload.finished_activation,
      };
    },
    [ActionType.LOGOUT]() {
      return {
        user: null,
        authenticated: false,
        loading: false,
        loggedOut: true,
      };
    },
    [ActionType.UPDATE_PROFILE](state: AuthenticationState, action: Action<any>) {
      Object.assign(state.user.b2b_profile, action.payload);
      return { ...state, authenticated: true, loading: false, error: null };
    },
    [ActionType.EDIT_USER](state: AuthenticationState, action: Action<any>) {
      const error = action.payload;
      if (error.error) {
        return {
          ...state,
          authenticated: false,
          loading: false,
          error: error.error,
        };
      }
      if (action.payload.user != null) {
        Object.assign(state.user, action.payload.user);
        return { ...state, authenticated: true, loading: false, error: null };
      }
      return state;
    },
    [ActionType.CHANGE_PASSWORD](state: AuthenticationState, action: Action<any>) {
      const error = action.payload;
      if (error.error) {
        return { ...state, loading: false, error: error.error };
      }
      if (action.payload.user != null) {
        Object.assign(state.user, action.payload.user);
        return { ...state, loading: false, error: null };
      }
      return state;
    },
  },
);
