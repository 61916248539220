import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import * as intl from "react-intl-universal";
import { RootState } from "../../reducers";
import * as CompanyUserActions from "../../actions/companyUser";
import { User } from "../../model/user";
import { UserFormState } from "../../model/userFormState";
import UsersList from "../components/UsersList";
import { CompanyUsersState } from "../../model/companyUsersState";
import { CompanyState } from "../../model/companyState";
import * as UserFormActions from "../../actions/addUserForm";
import * as AuthActions from "../../actions/auth";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";

export interface Props {
  companyUsersState: CompanyUsersState;
  companyUserFormState: UserFormState;
  companyState: CompanyState;
  actions: typeof CompanyUserActions;
  formActions: typeof UserFormActions;
  authActions: typeof AuthActions;
}

function CompanyUsersPage(props: Props) {
  const [state, setState] = useState({
    selectedUser: null,
    removeFromCompanyConfirmationVisible: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (props.companyState.selected_company_id == null) {
      return;
    }
    fetchUsers();
  }, []);

  function addNewCompanyUser() {
    navigate(`/companies/${props.companyState.selected_company_id}/users/new`);
  }

  function confirmRemoveFromCompany(user: User) {
    setState({
      removeFromCompanyConfirmationVisible: true,
      selectedUser: user,
    });
  }

  function cancelRemoveFromCompany() {
    setState({ ...state, removeFromCompanyConfirmationVisible: false });
  }

  function removeFromCompany() {
    if (state.selectedUser != null) {
      props.actions.removeUserFromCompany(props.companyState.selected_company_id, state.selectedUser);
    }
    setState({ ...state, removeFromCompanyConfirmationVisible: false });
  }

  function fetchUsers() {
    props.actions.fetchUsers(props.companyState.selected_company_id);
  }

  function changeUserRole(user: User, role: string) {
    if (role !== user.role) {
      props.actions.changeCompanyUserRole(props.companyState.selected_company_id, user.user_id, role);
    }
  }

  function resendInvitation(user: User) {
    props.authActions.resendUserInvitation(user.user_id, props.companyState.company.company_id, user.role);
  }

  let allowedRoles: string[];
  let hintKey = "";

  if (props.companyState.company.is_salespartner) {
    allowedRoles = ["COMPANY_ADMIN", "COMPANY_MANAGER", "COMPANY_EDITOR", "SALESPARTNER_SALESPERSON"];
    hintKey = "user_role.hint_salespartner_company_roles";
  } else if (props.companyState.company.salespartner_company_id) {
    if (props.companyState.company.role === "COMPANY_SALESPARTNER_ADMIN") {
      allowedRoles = ["COMPANY_EDITOR", "COMPANY_LIGHT_ADMIN", "COMPANY_SALESPARTNER_ADMIN"];
      hintKey = "user_role.hint_salespartner_linked_admin_company_roles";
    } else {
      allowedRoles = ["COMPANY_EDITOR", "COMPANY_LIGHT_ADMIN"];
      hintKey = "user_role.hint_salespartner_linked_company_roles";
    }
  } else {
    allowedRoles = ["COMPANY_ADMIN", "COMPANY_MANAGER", "COMPANY_EDITOR"];
    hintKey = "user_role.hint_company_roles";
  }

  return (
    <>
      <PageTracker />
      <UsersList
        canModifyUser={(user: User) => {
          if (
            user.role === "COMPANY_SALESPARTNER_ADMIN" &&
            props.companyState.company.role !== "COMPANY_SALESPARTNER_ADMIN"
          ) {
            return false;
          }
          return true;
        }}
        showAddUser={props.companyState.company.status !== "DELETED"}
        users={props.companyUsersState.users}
        addUser={() => {
          addNewCompanyUser();
        }}
        changeRole={(user, role) => {
          changeUserRole(user, role);
        }}
        removeAction={(user) => {
          confirmRemoveFromCompany(user);
        }}
        fetchUsers={fetchUsers}
        roles={allowedRoles}
        hintKey={hintKey}
        resendInvitation={(user) => resendInvitation(user)}
      />
      {state.removeFromCompanyConfirmationVisible && (
        <Dialog
          open={state.removeFromCompanyConfirmationVisible}
          onClose={() => cancelRemoveFromCompany()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.get("company.user.delete_confirmation.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.get("company..delete_confirmation.message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelRemoveFromCompany()} color="primary">
              {intl.get("company.user.delete_confirmation.button_cancel")}
            </Button>
            <Button onClick={() => removeFromCompany()} color="error" autoFocus>
              {intl.get("company.user.delete_confirmation.button_confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyUsersState: state.companyUsersState,
    companyState: state.companyState,
    companyUserFormState: state.userFormState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyUserActions as any, dispatch),
    formActions: bindActionCreators(UserFormActions as any, dispatch),
    authActions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyUsersPage);
