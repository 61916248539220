import { Alert, Snackbar } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";

export default function PdfRegenerationSuccessSnackbar({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
    >
      <Alert severity="success">{intl.get("company_plan_info.invoices_list.pdf_deleted")}</Alert>
    </Snackbar>
  );
}
