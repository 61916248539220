import { Box, Grid, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { connect } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import AddIcon from "@mui/icons-material/Add";
import * as intl from "react-intl-universal";
import { PaymentPlanState } from "../model/payment/payment.plan.state";
import * as PaymentProductActions from "../actions/payment.product.actions";
import { RootState } from "../reducers";
import { DefaultButton } from "../components/Buttons";
import { HeaderLabel } from "../components/header/HeaderLabel";
import { PageState, PageStateType } from "../model/pageState";
import { Pagination } from "../components/TablePagination";
import { formattedPaymentProductBillingInfo } from "../model/payment_info";
import { PaymentProduct, shortFormattedFeatures } from "../model/payment/payment.product";
import { Company } from "../model/company";
import { PageTracker } from "../util/pageTracker";
import { useNavigate } from "react-router-dom";
import AppStyles from "../styles/appStyles";

export interface Props {
  planState: PaymentPlanState;
  pageState: PageState;
  productActions: typeof PaymentProductActions;
}

export interface State {}

function PaymentPlansPage(props: Props) {
  const navigate = useNavigate();

  const handleChangePage = (event: any, page: any) => {
    props.planState.paging.current_page = page + 1;
    props.productActions.fetchAllPaymentProducts(props.planState.paging.current_page, props.planState.paging.page_size);
  };

  const handleChangeRowsPerPage = (event: any) => {
    props.planState.paging.page_size = event.target.value;
    props.productActions.fetchAllPaymentProducts(props.planState.paging.current_page, props.planState.paging.page_size);
  };

  useEffect(() => {
    props.productActions.fetchAllPaymentProducts(props.planState.paging.current_page, props.planState.paging.page_size);
  }, []);

  function editPaymentProduct(payment_product_id?: string) {
    navigate(`/paymentplans/${payment_product_id}`);
  }

  function createNewPaymentPlan() {
    navigate("/paymentplans/new");
  }

  function renderProduct(product: PaymentProduct) {
    let companyInfo = "-";
    let deletedCompany = false;
    if (product.companyInfo) {
      if ((product.companyInfo as Company).status === "DELETED") {
        companyInfo = `${product.companyInfo.name} (Gelöscht)`;
        deletedCompany = true;
      } else {
        companyInfo = product.companyInfo.name;
      }
    }

    const cellClassName = deletedCompany ? AppStyles.tableCellRed : AppStyles.tableCell;

    return (
      <TableRow
        key={product.product_id}
        onClick={() => {
          editPaymentProduct(product.product_id);
        }}
        hover
      >
        <TableCell component="th" scope="row" sx={cellClassName}>
          {product.name}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {companyInfo}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {product.country}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {product.product_sku_group}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {product.product_sku}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {product.type === "subscription" ? "Paket" : "Credit"}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {shortFormattedFeatures(product)}
        </TableCell>
        <TableCell component="th" scope="row" sx={cellClassName}>
          {formattedPaymentProductBillingInfo(product)}
        </TableCell>
      </TableRow>
    );
  }

  if (props.pageState.state === PageStateType.Loading) {
    return <></>;
  }

  const mapped = props.planState.allPaymentProducts.map((value) => renderProduct(value));

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Grid item sx={AppStyles.headerLeft}>
            <HeaderLabel>{intl.get("paymentplans.title")}</HeaderLabel>
          </Grid>
          <Grid item sx={AppStyles.headerRight}>
            <DefaultButton
              onClick={() => {
                createNewPaymentPlan();
              }}
            >
              <AddIcon sx={AppStyles.buttonLeftIcon} />
              {intl.get("paymentplans.button_add")}
            </DefaultButton>
          </Grid>
        </Grid>
        <Paper sx={AppStyles.innerContentPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("paymentplans.list_header_name")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("paymentplans.list_header_company")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("paymentplans.list_header_country")}
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  SKU-Gruppe
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  SKU
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  Typ
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  Features
                </TableCell>
                <TableCell padding="none" sx={AppStyles.tableCell}>
                  {intl.get("paymentplans.list_header_price")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapped}</TableBody>
            <TableFooter>
              <TableRow>
                <Pagination
                  paging={props.planState.paging}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    planState: state.paymentPlanState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    productActions: bindActionCreators(PaymentProductActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentPlansPage);
