import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const Progress = function (props: { marginTop?: number }) {
  const marginTop = props.marginTop ? props.marginTop : 0;

  return (
    <div
      style={{
        marginTop,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Progress;
