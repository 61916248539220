import { Dispatch } from "redux";
import axios from "axios";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";

export const updateSearchQuery = (query: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.UPDATE_SEARCH_QUERY, payload: query });
};

export const search = (query: string) => async (dispatch: Dispatch) => {
  if (query.trim().length < 3) {
    dispatch({
      type: ActionType.SEARCH_FAILED,
      payload: { error: { error_code: "ERROR_SEARCH_QUERY_TOO_SHORT" }, query },
    });
    return;
  }
  dispatch({ type: ActionType.SEARCH_START });

  try {
    const response = await axios.get(`/v2/api/b2b/private/search?query=${encodeURIComponent(query)}`);

    dispatch({
      type: ActionType.SEARCH_FINISHED,
      payload: { response: response.data, query },
    });
  } catch (error) {
    dispatch({
      type: ActionType.SEARCH_FAILED,
      payload: { error: mapErrorFromException(error), query },
    });
  }
};
