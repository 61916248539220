import { TableRow } from "@mui/material";
import PlaceholderTextTableCell from "./PlaceholderTextTableCell";

export default function PlaceholderTextTableRow({ text, span }: { text: string; span: number | undefined }) {
  return (
    <TableRow>
      <PlaceholderTextTableCell text={text} span={span} />
    </TableRow>
  );
}
