import * as React from "react";
import { EventFormState } from "../../../../model/eventFormState";
import { AuthenticationState } from "../../../../model/authenticationState";
import * as EventFormActions from "../../../../actions/eventForm";
import { AsYouType } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";
import { EventEditTextInputField } from "./fields/EventEditTextInputField";
import { FormGridItem } from "./FormGridItem";

export interface Props {
  eventFormState: EventFormState;
  actions: any;
  authenticationState: AuthenticationState;
  eventFormActions: typeof EventFormActions;
}

class EventContactInfoEdit extends React.Component<Props> {
  state = {};

  onChangeNumber(value: any, prop: string) {
    const formattedPhoneNumber = new AsYouType(undefined, metadata).input(value);
    if (formattedPhoneNumber) {
      this.props.eventFormActions.updateEventFormField({ prop: prop, value: formattedPhoneNumber });
    } else {
      this.props.eventFormActions.updateEventFormField({ prop: prop, value: "" });
    }
  }

  renderPhone() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"phone"}
        onChange={(event) => {
          this.onChangeNumber(event.target.value, "event.phone");
        }}
      />
    );
  }

  renderPhoneAlternative() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"phone_alternative"}
        onChange={(event) => {
          this.onChangeNumber(event.target.value, "event.phone_alternative");
        }}
      />
    );
  }

  renderFax() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"fax"}
        onChange={(event) => {
          this.onChangeNumber(event.target.value, "event.fax");
        }}
      />
    );
  }

  renderWeb() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"website_url"}
      />
    );
  }

  renderEmail() {
    return (
      <EventEditTextInputField
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"email"}
        type={"email"}
      />
    );
  }

  render() {
    /*
    const disableControls = this.props.eventFormState.readonly;

    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <FormGridItem md={4}>{this.renderPhone()}</FormGridItem>
        <FormGridItem md={4}>{this.renderPhoneAlternative()}</FormGridItem>
        <FormGridItem md={4}>{this.renderFax()}</FormGridItem>

        <FormGridItem md={6}>{this.renderWeb()}</FormGridItem>
        <FormGridItem md={6}>{this.renderEmail()}</FormGridItem>
      </>
    );
  }
}

export default EventContactInfoEdit;
