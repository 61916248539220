import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/actions";
import { CompanySharedLocation } from "../model/company.shared.location";
import { mapErrorFromException } from "../model/error";
import { Company } from "../model/company";

export const fetchSharedLocations = (company_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SHARED_LOCATION_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });

  try {
    const response = await axios.get(`/v2/api/b2b/private/companies/${company_id}/shared_locations`);

    dispatch({
      type: ActionType.FETCH_SHARED_LOCATIONS,
      payload: {
        data: true,
        locations: response.data.locations,
      },
    });

    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const editSharedLocation = (company_id: string, location_id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SHARED_LOCATION_FORM });
  dispatch({ type: ActionType.PAGE_LOADING });
  try {
    const response = await axios.get(`/v2/api/b2b/private/companies/${company_id}/shared_locations/${location_id}`);

    dispatch({
      type: ActionType.EDIT_SHARED_LOCATION,
      payload: {
        data: true,
        location_id: location_id,
        location: response.data,
      },
    });

    dispatch({ type: ActionType.PAGE_LOADED });
  } catch (error) {
    dispatch({
      type: ActionType.PAGE_LOADING_ERROR,
      payload: { error: mapErrorFromException(error) },
    });
  }
};

export const saveSharedLocation =
  (company_id: string, sharedLocation: CompanySharedLocation, completion: any) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      if (sharedLocation.location_id) {
        await axios.put(
          `/v2/api/b2b/private/companies/${company_id}/shared_locations/${sharedLocation.location_id}`,
          sharedLocation,
        );
      } else {
        await axios.post(`/v2/api/b2b/private/companies/${company_id}/shared_locations`, sharedLocation);
      }
      dispatch({ type: ActionType.PAGE_SAVED });

      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const deleteSharedLocation =
  (company_id: string, location_id: string, completion: any) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.PAGE_SAVING });

    try {
      await axios.delete(`/v2/api/b2b/private/companies/${company_id}/shared_locations/${location_id}`);

      dispatch({ type: ActionType.PAGE_SAVED });

      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const startEditingNewSharedLocation = (company: Company) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SHARED_LOCATION_FORM });
  dispatch({
    type: ActionType.UPDATE_SHARED_LOCATION_FORM_FIELD,
    payload: { prop: "editing_shared_location.country_code", value: company.country_code },
  });
  dispatch({
    type: ActionType.UPDATE_SHARED_LOCATION_FORM_FIELD,
    payload: { prop: "editing_shared_location.country", value: company.country },
  });
};

// @ts-ignore
export const updateSharedLocationFormField = ({ prop, value }) => {
  return {
    type: ActionType.UPDATE_SHARED_LOCATION_FORM_FIELD,
    payload: { prop, value },
  };
};

export const resetSharedLocationForm = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.RESET_SHARED_LOCATION_FORM });
};
