import { Box, Grid, Paper, Table, TableBody, TableFooter, TableHead, TableRow } from "@mui/material";
import { PageState, PageStateType } from "../model/pageState";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { RootState } from "../reducers";
import { GlobalInvoicesState } from "../model/global_invoices/global.invoices.state";
import * as GlobalInvoicesActions from "../actions/global.invoices.actions";
import { GlobalInvoiceStatus } from "../model/global_invoices/global.invoice.status";
import { PageTracker } from "../util/pageTracker";
import { HeaderLabel } from "../components/header/HeaderLabel";
import intl from "react-intl-universal";
import { Pagination } from "../components/TablePagination";
import AppStyles from "../styles/appStyles";
import DialogShowInvoiceCharges from "../components/admin_invoices/DialogShowInvoiceCharges";
import TableHeaderCell from "../components/admin_invoices/TableHeaderCell";
import DialogMarkAsPaidConfirmation from "../components/admin_invoices/DialogMarkAsPaidConfirmation";
import GlobalInvoiceCell from "../components/admin_invoices/GlobalInvoiceCell";

export interface Props {
  globalInvoicesState: GlobalInvoicesState;
  pageState: PageState;
  globalInvoicesActions: typeof GlobalInvoicesActions;
}

export interface State {
  showChargesDialog: boolean;
  showMarkAsPaidConfirmation: boolean;
  selectedInvoice?: GlobalInvoiceStatus;
  charges: string;
}

class GlobalInvoicesPages extends React.Component<Props> {
  state: State = {
    showChargesDialog: false,
    showMarkAsPaidConfirmation: false,
    charges: "",
    selectedInvoice: undefined,
  };

  handleChangePage = (event: any, page: any) => {
    this.props.globalInvoicesState.paging.current_page = page + 1;
    this.props.globalInvoicesActions.fetchAllGlobalInvoices(
      this.props.globalInvoicesState.paging.current_page,
      this.props.globalInvoicesState.paging.page_size,
    );
  };

  handleChangeRowsPerPage = (event: any) => {
    this.props.globalInvoicesState.paging.page_size = event.target.value;
    this.props.globalInvoicesActions.fetchAllGlobalInvoices(
      this.props.globalInvoicesState.paging.current_page,
      this.props.globalInvoicesState.paging.page_size,
    );
  };

  handleStatusChange = () => {
    this.props.globalInvoicesActions.fetchAllGlobalInvoices(
      this.props.globalInvoicesState.paging.current_page,
      this.props.globalInvoicesState.paging.page_size,
    );
  };

  markInvoiceAsPaid = (invoice: GlobalInvoiceStatus) => {
    this.props.globalInvoicesActions.markInvoiceAsPaid(
      invoice.invoice_id,
      this.props.globalInvoicesState.paging.current_page,
      this.props.globalInvoicesState.paging.page_size,
    );
  };

  componentDidMount(): void {
    this.props.globalInvoicesActions.fetchAllGlobalInvoices(
      this.props.globalInvoicesState.paging.current_page,
      this.props.globalInvoicesState.paging.page_size,
    );
  }

  render() {
    if (this.props.pageState.state === PageStateType.Loading) {
      return <></>;
    }

    const mapped = this.props.globalInvoicesState.allInvoices.map((invoice) => (
      <GlobalInvoiceCell
        invoice={invoice}
        onShowCharges={() => {
          this.setState({
            showChargesDialog: true,
            charges: JSON.stringify(JSON.parse(`[${invoice.paymentResponses.join(",")}]`), null, "  "),
          });
        }}
        onMarkAsPaid={() => {
          this.setState({
            showMarkAsPaidConfirmation: true,
            selectedInvoice: invoice,
          });
        }}
      />
    ));

    return (
      <>
        <PageTracker />
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item sx={AppStyles.headerLeft}>
              <HeaderLabel>{intl.get("global_invoices.title")}</HeaderLabel>
            </Grid>
            <Grid item sx={AppStyles.headerRight}></Grid>
          </Grid>
          <Paper sx={AppStyles.innerContentPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell text={intl.get("global_invoices.list_company_name")} />
                  <TableHeaderCell text={intl.get("global_invoices.list_invoice_number")} />
                  <TableHeaderCell text={intl.get("global_invoices.list_timestamp")} />
                  <TableHeaderCell text={intl.get("global_invoices.list_amount")} />
                  <TableHeaderCell text={intl.get("global_invoices.list_status")} />
                  <TableHeaderCell text={""} align={"center"} />
                  <TableHeaderCell text={""} align={"center"} />
                </TableRow>
              </TableHead>
              <TableBody>{mapped}</TableBody>
              <TableFooter>
                <TableRow>
                  <Pagination
                    paging={this.props.globalInvoicesState.paging}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Box>

        {this.state.showChargesDialog && (
          <DialogShowInvoiceCharges
            open={this.state.showChargesDialog}
            onClose={() => {
              this.setState({ showChargesDialog: false });
            }}
            formattedCharges={this.state.charges}
          />
        )}

        {this.state.showMarkAsPaidConfirmation && (
          <DialogMarkAsPaidConfirmation
            open={this.state.showMarkAsPaidConfirmation}
            onClose={() => {
              this.setState({ showMarkAsPaidConfirmation: false, selectedInvoice: null });
            }}
            invoice_number={this.state.selectedInvoice?.invoice_number ?? ""}
            onMarkAsPaid={() => {
              this.markInvoiceAsPaid(this.state.selectedInvoice);
              this.setState({ showMarkAsPaidConfirmation: false, selectedInvoice: null });
            }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    globalInvoicesState: state.globalInvoicesState,
    pageState: state.pageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    globalInvoicesActions: bindActionCreators(GlobalInvoicesActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GlobalInvoicesPages);
