import { CalendarBookingSlot } from "../drawer/hero/HeroSlotsDrawerBackendResponse";

export default class CalendarSlotBooking {
  slotId: string;

  startDate: Date;

  previouslyBooked: boolean;

  constructor(
    slot: CalendarBookingSlot,
    public free = false,
  ) {
    this.slotId = slot.slot.slot_id;
    this.startDate = slot.slot.parsedSlotStartDate;
    this.previouslyBooked = slot.booked;
  }
}
