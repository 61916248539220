import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Error, errorMessageFromError } from "../model/error";

const PREFIX = "ErrorView";

const classes = {
  error: `${PREFIX}-error`,
  errorContainer: `${PREFIX}-errorContainer`,
  errorSubContainer: `${PREFIX}-errorSubContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.error}`]: {
    color: theme.palette.error.contrastText,
    background: theme.palette.error.main,
    fontSize: theme.fontSizes.mediumFont,
    padding: 5,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`&.${classes.errorContainer}`]: {
    color: "white",
    background: theme.palette.error.main,
    fontSize: theme.fontSizes.mediumFont,
    padding: 5,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.errorSubContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const aErrorView = function (props: {
  error?: Error | null;
  defaultErrorMessage?: string | null;
  message?: string | null;
  showClearIcon?: boolean | null;
  onClearButton?: any | null;
  children?: any;
}) {
  const { error, defaultErrorMessage } = props;

  let errorMessage: string | null = null;
  if (props.message) {
    errorMessage = props.message;
  } else {
    errorMessage = errorMessageFromError(error, defaultErrorMessage);
  }
  if (errorMessage != null) {
    return (
      <Root className={classes.errorContainer}>
        <div className={classes.errorSubContainer}>
          <div className={classes.error}>{errorMessage}</div>
          {props.showClearIcon != null && props.showClearIcon && (
            <IconButton style={{ color: "white" }} onClick={props.onClearButton} size="large">
              <ClearIcon style={{ alignSelf: "center" }} />
            </IconButton>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>{props.children != null && props.children}</div>
      </Root>
    );
  }
  return null;
};

export default aErrorView;
export const ErrorView = aErrorView;
