import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const SocialMediaIcon = (props) => (
  <SvgIcon id="Ebene_1" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g id="Gruppe_523" data-name="Gruppe 523">
      <path
        id="Pfad_119"
        data-name="Pfad 119"
        d="M15.187,13.824V1.363A1.363,1.363,0,0,0,13.824,0H1.363A1.363,1.363,0,0,0,0,1.363V13.824a1.363,1.363,0,0,0,1.363,1.363H13.824a1.363,1.363,0,0,0,1.363-1.363M7.593,12.266a4.673,4.673,0,1,1,4.673-4.673,4.673,4.673,0,0,1-4.673,4.673m5.16-9.054a.779.779,0,1,1,.779-.779.779.779,0,0,1-.779.779"
        fill="#707cda"
      />
      <path
        id="Pfad_120"
        data-name="Pfad 120"
        d="M49.115,46a3.115,3.115,0,1,0,3.115,3.115A3.115,3.115,0,0,0,49.115,46"
        transform="translate(-41.522 -41.522)"
        fill="#707cda"
      />
    </g>
  </SvgIcon>
);

export default SocialMediaIcon;
