import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

const NewsletterIcon = (props) => (
  <SvgIcon id="Ebene_1" width="16" height="13" viewBox="0 0 16 13" {...props}>
    <g id="Gruppe_527" data-name="Gruppe 527">
      <path
        id="Pfad_123"
        data-name="Pfad 123"
        d="M2.829.619l7.626,6.24L18.082.619A.882.882,0,0,1,18.2.536,1.3,1.3,0,0,0,17.153,0H3.758A1.3,1.3,0,0,0,2.707.536a.882.882,0,0,1,.122.083"
        transform="translate(-2.455 -0.001)"
        fill="#707cda"
      />
      <path
        id="Pfad_124"
        data-name="Pfad 124"
        d="M8.56,34A.884.884,0,0,1,7.44,34L0,27.911v9.125a1.3,1.3,0,0,0,1.3,1.3H14.7a1.3,1.3,0,0,0,1.3-1.3V27.911Z"
        transform="translate(0 -25.315)"
        fill="#707cda"
      />
    </g>
  </SvgIcon>
);

export default NewsletterIcon;
