import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { ResellerProfileState } from "../model/resellerProfileState";

export const resellerProfileState = createReducer(
  {
    selected_user_id: null,
    resellerProfile: null,
    allResellers: null,
    paging: {
      page_size: 50,
      current_page: 1,
    },
  },
  {
    [ActionType.SELECT_RESELLER_PROFILES](state: ResellerProfileState, action: Action<any>) {
      return {
        ...state,
        selected_user_id: action.payload.selected_user_id,
        resellerProfile: null,
      };
    },
    [ActionType.FETCH_RESELLER_PROFILES](state: ResellerProfileState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          allResellers: action.payload.resellers,
          paging: action.payload.paging,
        };
      }
      return { ...state };
    },
  },
);
