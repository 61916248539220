import { DialogHandler } from "../../dialog/useDialogHandler";
import { BCEvent } from "../../../model/event";
import * as React from "react";
import usePaymentProduct from "../../../hooks/usePaymentProduct";
import PurchaseConfirmationDrawer from "../PurchaseConfirmationDrawer";
import useDialogReset from "../../dialog/useDialogReset";
import BCSideDrawer from "../components/BCSideDrawer";
import BookingDrawerErrorContent from "../components/BookingDrawerErrorContent";
import SocialMediaInfoSection from "./SocialMediaInfoSection";

interface Props {
  handler: DialogHandler<BCEvent>;
}

export default function SocialMediaDrawer({ handler }: Props) {
  const { selectedProduct: product, error, refresh } = usePaymentProduct("standard_social");

  useDialogReset(handler, () => {
    refresh();
  });

  if (error) {
    return (
      <BCSideDrawer handler={handler}>
        <BookingDrawerErrorContent error={error} infoSection={<SocialMediaInfoSection />} />
      </BCSideDrawer>
    );
  }

  return (
    <PurchaseConfirmationDrawer
      handler={{ ...handler, payload: product } as any}
      event={handler.payload}
      infoSection={<SocialMediaInfoSection />}
    />
  );
}
