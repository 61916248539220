import { DialogHandler } from "../dialog/useDialogHandler";
import { BCEvent } from "../../model/event";
import PremiumUpgradeDrawerContent from "./premium/PremiumUpgradeDrawerContent";
import HeroSlotsDrawerContent from "./hero/HeroSlotsDrawerContent";
import TopPlacementDrawerContent from "./topplacement/TopPlacementDrawerContent";
import { useState } from "react";
import useDialogReset from "../dialog/useDialogReset";
import BCSideDrawer from "./components/BCSideDrawer";
import BookingDrawerLoadingIndicator from "./components/BookingDrawerLoadingIndicator";
import * as React from "react";

export interface BookingDrawerHandlerPayload {
  event?: BCEvent;
  companyId: string;
  intent: "top_placement" | "hero_slots" | "premium_purchase";
}

export interface BookingDrawerProps {
  handler: DialogHandler<BookingDrawerHandlerPayload>;
}

export default function BookingDrawer({ handler }: BookingDrawerProps) {
  const [content, setContent] = useState(handler.payload?.intent);

  useDialogReset(handler, () => {
    setContent(handler.payload?.intent);
  });

  const redirectToPurchase = () => {
    setContent("premium_purchase");
  };

  return (
    <BCSideDrawer handler={handler}>
      {!content && <BookingDrawerLoadingIndicator />}
      {content === "premium_purchase" && <PremiumUpgradeDrawerContent />}
      {content === "hero_slots" && <HeroSlotsDrawerContent />}
      {content === "top_placement" && <TopPlacementDrawerContent onNeedToBookMoreSlots={redirectToPurchase} />}
    </BCSideDrawer>
  );
}
