import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as intl from "react-intl-universal";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { PaymentInfo, PaymentInfoStatus, PaymentType } from "../../../model/payment_info";
import AppStyles from "../../../styles/appStyles";
import SepaMandateDialog from "./SepaMandateDialog";
import { useState } from "react";
import * as CompanyActions from "../../../actions/company";
import useDialogHandler, { DialogHandler } from "../../../components/dialog/useDialogHandler";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import useUnzerPaymentScripts from "./useUnzerPaymentScripts";
import { RootState } from "../../../reducers";
import { PaymentState } from "../../../model/paymentState";
import PaymentMethod2FAPollingDialog, { PaymentMethod2FADialogPayload } from "./PaymentMethod2FAPollingDialog";
import useSelectedCompanyId from "../../../hooks/useSelectedCompanyId";
import useDialogReset from "../../../components/dialog/useDialogReset";

export interface Props {
  handler: DialogHandler<any>;
  onCancel?: () => void;
  onSuccess: () => void;
}

export default function UpdatePaymentMethodDialog({ handler, onSuccess, onCancel }: Props) {
  const dispatch = useDispatch();
  const companyId = useSelectedCompanyId();

  const { sepaPaymentMethod, cardPaymentMethod, hideSandboxNotification } = useUnzerPaymentScripts();

  const paymentState = useSelector<RootState, PaymentState>((rootState) => {
    return rootState.paymentState;
  });

  const [paymentType, setPaymentType] = useState(
    paymentState.paymentInfo?.payment_provider_payment_type || PaymentType.card,
  );

  useDialogReset(handler, () => {
    setPaymentType(paymentState.paymentInfo?.payment_provider_payment_type || PaymentType.card);
  });

  const sepaMandateHandler = useDialogHandler();
  const twoFAPollingHandler = useDialogHandler<PaymentMethod2FADialogPayload>("PaymentMethod2FADialog", false, false);

  const handleCancel = () => {
    handler.close();
    hideSandboxNotification();
    if (onCancel) {
      onCancel();
    }
  };

  function handleFinish(paymentInfo: PaymentInfo) {
    if (
      paymentInfo &&
      paymentInfo.status === PaymentInfoStatus.PENDING_AUTHORIZATION &&
      paymentInfo.authorizationRedirectUrl != null
    ) {
      twoFAPollingHandler.open({ authorizationUrl: paymentInfo.authorizationRedirectUrl });
    } else {
      dispatch<any>(CompanyActions.loadPaymentInformation(companyId));
      onSuccess();

      // Close dialog only in case of success without pending 2FA because otherwise 2FAPollingHandler would also get released
      handler.close();
    }

    hideSandboxNotification();
  }

  async function addPaymentMethod() {
    if (paymentType === PaymentType.card) {
      await createPaymentMethod();
    } else if (paymentType === PaymentType.sepa) {
      sepaMandateHandler.open();
    } else if (paymentType === PaymentType.byTransaction) {
      dispatch<any>(
        CompanyActions.setupPaymentByTransaction(companyId, (paymentInfo) => {
          handleFinish(paymentInfo);
        }),
      );
    }
  }

  async function createPaymentMethod() {
    const paymentMethod = paymentType === PaymentType.card ? cardPaymentMethod : sepaPaymentMethod;
    dispatch<any>(
      CompanyActions.createPaymentMethod(paymentMethod, companyId, paymentType, (paymentInfo) => {
        handleFinish(paymentInfo);
      }),
    );
  }

  const sepaVisibilityStyle: any = paymentType === PaymentType.sepa ? {} : { display: "none" };
  const cardVisibilityStyle: any = paymentType === PaymentType.card ? {} : { display: "none" };
  /*const byTransactionVisibilityStyle: any =
      paymentType === PaymentType.byTransaction ? {} : { display: "none" }; */

  return (
    <>
      <HandlerDialog
        sx={{ display: twoFAPollingHandler.isOpen ? "none" : "block" }}
        fullWidth={true}
        maxWidth="sm"
        handler={handler}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.get("company_plan_info.add_payment_method.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.get("company_plan_info.add_payment_method.message")}
          </DialogContentText>
          <form id="payment-form" className="unzerUI form" noValidate>
            <Grid item container direction="row" sx={AppStyles.detailFormWithoutSidePadding}>
              <RadioGroup
                style={{ flexDirection: "row" }}
                aria-label=""
                name="paymentType"
                value={paymentType}
                onChange={(event) => setPaymentType(event.target.value)}
              >
                <FormControlLabel
                  value={PaymentType.card}
                  control={<Radio />}
                  label={intl.get("company_plan_info.add_payment_method.radio_button_label_card")}
                />
                <FormControlLabel
                  value={PaymentType.sepa}
                  control={<Radio />}
                  label={intl.get("company_plan_info.add_payment_method.radio_button_label_sepa")}
                />
                <FormControlLabel
                  value={PaymentType.byTransaction}
                  control={<Radio />}
                  label={intl.get("company_plan_info.add_payment_method.radio_button_label_by_transaction")}
                />
              </RadioGroup>

              <Grid item xs={12} container direction="column">
                <div style={cardVisibilityStyle}>
                  <div className="fields">
                    <div className="field sixteen wide">
                      <div id="card-element-id-number" className="unzerInput" />
                    </div>
                  </div>
                  <div className="two fields">
                    <div className="field eight wide">
                      <div id="card-element-id-expiry" className="unzerInput" />
                    </div>
                    <div className="field eight wide">
                      <div id="card-element-id-cvc" className="unzerInput" />
                    </div>
                  </div>
                </div>
                <div id="sepa-IBAN" className="field" style={sepaVisibilityStyle} />
              </Grid>
            </Grid>
          </form>

          {paymentType === PaymentType.byTransaction && (
            <DialogContentText
              id="alert-dialog-description"
              dangerouslySetInnerHTML={{
                __html: intl.get("company_plan_info.add_payment_method.by_transaction_hint"),
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {intl.get("company_plan_info.add_payment_method.button_cancel")}
          </Button>
          <Button onClick={addPaymentMethod} color="primary" autoFocus>
            {intl.get("company_plan_info.add_payment_method.button_add")}
          </Button>
        </DialogActions>
      </HandlerDialog>
      {sepaMandateHandler.isOpen && <SepaMandateDialog handler={sepaMandateHandler} onAgree={createPaymentMethod} />}
      {twoFAPollingHandler.isOpen && (
        <PaymentMethod2FAPollingDialog
          handler={twoFAPollingHandler}
          onSuccess={handleFinish}
          onCancel={() => {
            handler.close();
          }}
        />
      )}
    </>
  );
}
