import * as React from "react";
import { Grid } from "@mui/material";
import { EventFormState } from "../../../../../model/eventFormState";
import { BCEventType } from "../../../../../model/event";
import { CompanyState } from "../../../../../model/companyState";
import { AuthenticationState } from "../../../../../model/authenticationState";
import { EventEditTextInputField } from "../fields/EventEditTextInputField";
import * as EventFormActions from "../../../../../actions/eventForm";
import { EventEditMultilineTextInputField } from "../fields/EventEditMultilineTextInputField";
import { EventEditRadioField } from "../fields/EventEditRadioField";
import { FormGridItem } from "../FormGridItem";

export interface Props {
  eventFormState: EventFormState;
  companyState: CompanyState;
  eventFormActions: typeof EventFormActions;
  authenticationState: AuthenticationState;
}

class EventGeneralInfoEdit extends React.Component<Props> {
  renderTitle() {
    return (
      <EventEditTextInputField
        required={true}
        maxLength={80}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"title"}
      />
    );
  }

  renderSummary() {
    return (
      <EventEditTextInputField
        required={true}
        maxLength={100}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"summary"}
      />
    );
  }

  renderDescription() {
    return (
      <EventEditMultilineTextInputField
        required={true}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"description"}
        rows={10}
        maxLength={1500}
      />
    );
  }

  renderEventType() {
    return (
      <EventEditRadioField
        required={true}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"type"}
        options={[BCEventType.Recurring, BCEventType.Dedicated]}
      />
    );
  }

  render() {
    /*
    let invalidFields: string[] = [];
    if (this.props.eventFormState.error && this.props.eventFormState.error.validationErrors) {
      invalidFields = this.props.eventFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }
     */

    return (
      <>
        <Grid item container direction="row">
          <FormGridItem md={6}>{this.renderTitle()}</FormGridItem>
          <FormGridItem md={6}>{this.renderEventType()}</FormGridItem>
          <FormGridItem>{this.renderSummary()}</FormGridItem>
          <FormGridItem>{this.renderDescription()}</FormGridItem>
        </Grid>
      </>
    );
  }
}

export default EventGeneralInfoEdit;
