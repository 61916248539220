import axios from "axios";

export default {
  setupAxiosInterceptors: (callback401: () => void) => {
    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (error?.response?.status === 401) {
          callback401();
        }
        return Promise.reject(error);
      },
    );
  },
};
