import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import * as React from "react";

interface MyMenuItemProps {
  children?: any;
  link?: string;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export default function UnstyledMyMenuItem({ selected, link, children, onClick, disabled }: MyMenuItemProps) {
  return (
    <MenuItem
      sx={(theme) => ({
        height: "50px",
        color: `#3c3c3b !important`,
        fontWeight: 400,
        paddingLeft: "30px",
        fontSize: theme.fontSizes.mediumFont,
        "& .Mui-selected": {
          color: `#3c3c3b !important`,
          backgroundColor: "#f2f2f2 !important",
          paddingLeft: "23px",
          borderLeft: "8px solid",
          borderColor: theme.palette.primary.main,
          fontSize: theme.fontSizes.mediumFont,
          fontWeight: 800,
        },
      })}
      disabled={disabled}
      selected={selected}
      component={link ? Link : undefined}
      to={link}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
}
