import HeroDrawerInfoSection from "./hero/HeroDrawerInfoSection";
import { PaymentProduct } from "../../model/payment/payment.product";
import PremiumDrawerInfoSection from "./premium/PremiumDrawerInfoSection";
import SocialMediaInfoSection from "./social/SocialMediaInfoSection";
import NewsletterInfoSection from "./newsletter/NewsletterInfoSection";
import PremiumPlusDrawerInfoSection from "./premium/PremiumPlusDrawerInfoSection";

interface Props {
  product?: PaymentProduct;
}

export default function ProductInfoSection({ product }: Props) {
  switch (product?.product_sku_group) {
    case "premium_monthly":
    case "premium_yearly":
      return <PremiumDrawerInfoSection product={product} />;
    case "premium_plus_yearly":
      return <PremiumPlusDrawerInfoSection product={product} />;
    case "hero":
      return <HeroDrawerInfoSection />;
    case "social":
      return <SocialMediaInfoSection />;
    case "fai_newsletter":
      return <NewsletterInfoSection />;
  }
  return null;
}
