import AppStyles from "../styles/appStyles";
import { TableCell } from "@mui/material";

export default function PlaceholderTextTableCell({ text, span }: { text: string; span: number | undefined }) {
  return (
    <TableCell scope="row" colSpan={span} sx={AppStyles.tableCellNotSelectable}>
      {text}
    </TableCell>
  );
}
