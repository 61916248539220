import { Error } from "./error";

export enum PageStateType {
  Unknown,
  Loading,
  Loaded,
  FailedLoading,
  Saving,
  Saved,
  FailedSaving,
}

export class PageState {
  state: PageStateType = PageStateType.Unknown;

  error: Error | null;
}
