import { Stack, Typography } from "@mui/material";
import FeatureCheckmarkIcon from "../../../assets/icons/FeatureCheckmark";
import React from "react";

interface Props {
  title: string | React.ReactElement;
  bold?: boolean;
  color?: string;
}

export default function BookingDrawerInfoSectionFeature({ title, bold, color }: Props) {
  return (
    <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
      <FeatureCheckmarkIcon sx={{ width: "10px", height: "7px" }} />
      <Typography
        color={color}
        sx={{ font: bold ? "normal normal bold 14px/18px Manrope" : "normal normal normal 14px/18px Manrope" }}
      >
        {title}
      </Typography>
    </Stack>
  );
}
