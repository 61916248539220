import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as intl from "react-intl-universal";
import { NumericFormat } from "react-number-format";
import { PaymentPlanFormState } from "../../../model/paymentPlanFormState";
import TextFieldWithLabel from "../../../components/TextFieldWithLabel";
import { SectionLabel } from "../../../components/SectionLabel";
import { PageState, PageStateType } from "../../../model/pageState";
import { AuthenticationState } from "../../../model/authenticationState";
import { RootState } from "../../../reducers";
import * as PaymentProductActions from "../../../actions/payment.product.actions";
import { PaymentPlanState } from "../../../model/payment/payment.plan.state";
import { BillingType } from "../../../model/payment_info";
import { Constants } from "./event_edit/event_options";
import MySelect from "../../../components/select/MySelect";
import MyCreatableSelect from "../../../components/select/MyCreatableSelect";
import AppStyles from "../../../styles/appStyles";

export interface Props {
  paymentPlanFormState: PaymentPlanFormState;
  paymentPlanState: PaymentPlanState;
  pageState: PageState;
  actions: typeof PaymentProductActions;
  readonly: boolean;
  showResellerSelection: boolean;
  authenticationState: AuthenticationState;
  children?: any;
}

export interface State {
  tab: number;
}

class PaymentPlanEditForm extends React.Component<Props> {
  state = {
    tab: 0,
    reseller_id: null,
  };

  handleChange = (propName: any) => (event: any) => {
    this.props.actions.updatePaymentProductFormField({
      prop: propName,
      value: event.target.value,
    });
  };

  handleIntNumberChange = (propName: any) => (event: any) => {
    const regExpression = /^\d+$/;
    let value = null;
    if (event.target.value) {
      if (regExpression.test(event.target.value)) {
        value = Number.parseInt(event.target.value, undefined);
      }
    }
    this.props.actions.updatePaymentProductFormField({
      prop: propName,
      value: value,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderCompanySelection(invalidFields: string[]) {
    if (this.props.paymentPlanFormState.companies) {
      const options: any = this.props.paymentPlanFormState.companies.map((value) => {
        return { value: value.company_id, label: value.status === "DELETED" ? `${value.name} (Gelöscht)` : value.name };
      });

      let selectedCompany: any = undefined;
      if (this.props.paymentPlanFormState.product.company_id) {
        const company = this.props.paymentPlanFormState.companies.find((value) => {
          return this.props.paymentPlanFormState.product.company_id === value.company_id;
        });
        selectedCompany = {
          value: company.company_id,
          label: company.status === "DELETED" ? `${company.name} (Gelöscht)` : company.name,
        };
      }

      return (
        <Grid item container direction="column">
          <SectionLabel required={false}>{intl.get("paymentplan_form.label_company_selection")}</SectionLabel>
          <MySelect
            options={options}
            isClearable
            isSearchable
            placeholder={intl.get("paymentplan_form.label_company_selection_hint")}
            value={selectedCompany}
            onChange={(value) => {
              this.props.actions.updatePaymentProductFormField({
                prop: "product.company_id",
                value: value ? value.value : undefined,
              });
            }}
          />
        </Grid>
      );
    }
    return null;
  }

  renderName(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly}
        label={intl.get("paymentplan_form.label_name")}
        id="name"
        onChange={this.handleChange("product.name")}
        value={this.props.paymentPlanFormState.product.name}
        margin="normal"
        error={invalidFields.includes("name")}
      />
    );
  }

  renderGrantedCredits(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Credits"}
        id="granted_credits"
        onChange={this.handleChange("product.granted_credits")}
        value={this.props.paymentPlanFormState.product.granted_credits}
        margin="normal"
        error={invalidFields.includes("granted_credits")}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderHeroCredits(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Hero Credits"}
        id="hero_credits"
        onChange={this.handleChange("product.hero_credits")}
        value={this.props.paymentPlanFormState.product.hero_credits}
        margin="normal"
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderSocialCredits(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Social Media Credits"}
        id="social_credits"
        onChange={this.handleChange("product.social_credits")}
        value={this.props.paymentPlanFormState.product.social_credits}
        margin="normal"
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderFaiCredits(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Newsletter Credits"}
        id="fai_credits"
        onChange={this.handleChange("product.fai_credits")}
        value={this.props.paymentPlanFormState.product.fai_credits}
        margin="normal"
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderSkuGroup(invalidFields: string[]) {
    const options = this.props.paymentPlanFormState.skuGroups.map((value) => {
      return {
        value: value,
        label: value,
      };
    });
    const skuGroup = this.props.paymentPlanFormState.product.product_sku_group;
    const selectedOption = skuGroup ? { value: skuGroup, label: skuGroup } : undefined;
    return (
      <Grid item container direction="column">
        <SectionLabel required={true}>SKU-Gruppe</SectionLabel>
        <MyCreatableSelect
          options={options}
          isDisabled={this.props.readonly}
          isClearable
          isSearchable
          placeholder={"SKU-Gruppe"}
          value={selectedOption}
          onCreateOption={(value) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.product_sku_group",
              value: value,
            });
          }}
          onChange={(value: any) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.product_sku_group",
              value: value ? value.value : undefined,
            });
          }}
        />
      </Grid>
    );
  }

  renderSku(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly || this.props.paymentPlanFormState.product_id !== null}
        label={"SKU"}
        id="product_sku"
        onChange={this.handleChange("product.product_sku")}
        value={this.props.paymentPlanFormState.product.product_sku}
        margin="normal"
        error={invalidFields.includes("product_sku")}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderDisplayOrder(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Position"}
        id="display_order"
        onChange={this.handleIntNumberChange("product.display_order")}
        value={this.props.paymentPlanFormState.product.display_order}
        margin="normal"
      />
    );
  }

  renderSingleUse() {
    return (
      <FormGroup sx={AppStyles.detailFormGroup}>
        <FormControlLabel
          sx={AppStyles.detailFormControlLabel}
          disabled={this.props.readonly || !this.props.paymentPlanFormState.product.company_id}
          labelPlacement="end"
          label={intl.get("paymentplan_form.label_single_use")}
          control={
            <Checkbox
              color="primary"
              checked={
                this.props.paymentPlanFormState.product.company_id
                  ? this.props.paymentPlanFormState.product.single_use_only
                  : false
              }
              onChange={(event) => {
                this.props.actions.updatePaymentProductFormField({
                  prop: `product.single_use_only`,
                  value: event.target.checked,
                });
              }}
            />
          }
        />
      </FormGroup>
    );
  }

  renderPrice() {
    return (
      <FormControl>
        <SectionLabel required={true}>{intl.get("paymentplan_form.label_price")}</SectionLabel>
        <NumericFormat
          value={this.props.paymentPlanFormState.product.price_in_cents / 100}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={TextField}
          required
          disabled={false}
          onValueChange={(values) => {
            const { value } = values;

            if (value && value.length > 0) {
              this.props.actions.updatePaymentProductFormField({
                prop: "product.price_in_cents",
                value: Math.round(Number(value) * 100),
              });
            } else {
              this.props.actions.updatePaymentProductFormField({
                prop: "product.price_in_cents",
                value: undefined,
              });
            }
          }}
        />
      </FormControl>
    );
  }

  renderReducedPrice() {
    return (
      <FormControl>
        <SectionLabel required={false}>{intl.get("paymentplan_form.label_reduced_price")}</SectionLabel>
        <NumericFormat
          value={this.props.paymentPlanFormState.product.reduced_price_in_cents / 100}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={TextField}
          required
          disabled={false}
          onValueChange={(values) => {
            const { value } = values;

            if (value && value.length > 0) {
              this.props.actions.updatePaymentProductFormField({
                prop: "product.reduced_price_in_cents",
                value: Math.round(Number(value) * 100),
              });
            } else {
              this.props.actions.updatePaymentProductFormField({
                prop: "product.reduced_price_in_cents",
                value: undefined,
              });
            }
          }}
        />
      </FormControl>
    );
  }

  renderNumberOfPeriodsWithReducedPrice(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={intl.get("paymentplan_form.label_reduced_price_periods")}
        id="number_of_reduced_price_periods"
        onChange={this.handleIntNumberChange("product.number_of_reduced_price_periods")}
        value={this.props.paymentPlanFormState.product.number_of_reduced_price_periods}
        margin="normal"
        error={invalidFields.includes("number_of_reduced_price_periods")}
      />
    );
  }

  renderTrialInterval(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={intl.get("paymentplan_form.label_trial_interval")}
        id="trial_interval"
        onChange={this.handleIntNumberChange("product.trial_interval")}
        value={this.props.paymentPlanFormState.product.trial_interval}
        margin="normal"
        error={invalidFields.includes("trial_interval")}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderTrialUnit(invalidFields: string[]) {
    const options = [
      {
        value: "hour",
        label: intl.get("paymentplan_form.billingtype.hour"),
      },
      {
        value: "day",
        label: intl.get("paymentplan_form.billingtype.day"),
      },
      {
        value: "month",
        label: intl.get("paymentplan_form.billingtype.month"),
      },
      {
        value: "year",
        label: intl.get("paymentplan_form.billingtype.year"),
      },
    ];

    const selectedOption = this.props.paymentPlanFormState.product.trial_unit
      ? options.filter((value) => value.value === this.props.paymentPlanFormState.product.trial_unit)[0]
      : undefined;

    return (
      <Grid item container direction="column">
        <SectionLabel>{intl.get("paymentplan_form.label_trial_unit")}</SectionLabel>
        <MySelect
          options={options}
          isClearable
          isDisabled={this.props.readonly}
          onChange={(value) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.trial_unit",
              value: value ? value.value : undefined,
            });
          }}
          placeholder={"Einheit wählen..."}
          value={selectedOption}
        />
      </Grid>
    );
  }

  renderCurrency() {
    const selectedCurrency = this.props.paymentPlanFormState.product.currency
      ? {
          value: this.props.paymentPlanFormState.product.currency,
          label: intl.get(`currencies.${this.props.paymentPlanFormState.product.currency}`),
        }
      : undefined;
    return (
      <Grid item container direction="column">
        <SectionLabel required>{intl.get("paymentplan_form.label_currency")}</SectionLabel>
        <MySelect
          options={[
            {
              value: "EUR",
              label: intl.get("currencies.EUR"),
            },
          ]}
          isClearable
          isDisabled={this.props.readonly}
          onChange={(value) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.currency",
              value: value ? value.value : undefined,
            });
          }}
          value={selectedCurrency}
        />
      </Grid>
    );
  }

  renderTaxRate(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly}
        label={intl.get("paymentplan_form.label_tax_rate")}
        id="tax_rate_percentage"
        onChange={this.handleIntNumberChange("product.tax_rate_percentage")}
        value={this.props.paymentPlanFormState.product.tax_rate_percentage}
        margin="normal"
        error={invalidFields.includes("tax_rate_percentage")}
      />
    );
  }

  renderBillingIntervalValue(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        required
        disabled={this.props.readonly}
        label={intl.get("paymentplan_form.label_billing_interval_value")}
        id="billing_interval_value"
        onChange={this.handleIntNumberChange("product.billing_interval_value")}
        value={this.props.paymentPlanFormState.product.billing_interval_value}
        margin="normal"
        error={invalidFields.includes("billing_interval_value")}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderBillingIntervalUnit(invalidFields: string[]) {
    const options = [
      {
        value: BillingType.PrePaidHourly,
        label: intl.get("paymentplan_form.billingtype.hour"),
      },
      {
        value: BillingType.PrePaidDaily,
        label: intl.get("paymentplan_form.billingtype.day"),
      },
      {
        value: BillingType.PrePaidMonthly,
        label: intl.get("paymentplan_form.billingtype.month"),
      },
      {
        value: BillingType.PrePaidYearly,
        label: intl.get("paymentplan_form.billingtype.year"),
      },
    ];

    const selectedOption = this.props.paymentPlanFormState.product.billing_type
      ? options.filter((value) => value.value === this.props.paymentPlanFormState.product.billing_type)[0]
      : undefined;

    return (
      <Grid item container direction="column">
        <SectionLabel required={true}>{intl.get("paymentplan_form.label_billing_interval_unit")}</SectionLabel>
        <MySelect
          options={options}
          isClearable
          isDisabled={this.props.readonly}
          onChange={(value) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.billing_type",
              value: value ? value.value : undefined,
            });
          }}
          placeholder={"Einheit wählen..."}
          value={selectedOption}
        />
      </Grid>
    );
  }

  renderType() {
    const options = [
      {
        value: "subscription",
        label: "Paket",
      },
      {
        value: "credit",
        label: "Credit",
      },
    ];
    const selectedOption = options.filter((value) => value.value === this.props.paymentPlanFormState.product.type)[0];
    return (
      <Grid item container direction="column">
        <SectionLabel required>Art</SectionLabel>
        <MySelect
          options={options}
          isDisabled={this.props.readonly || this.props.paymentPlanFormState.product_id !== null}
          onChange={(value) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.type",
              value: value ? value.value : undefined,
            });
          }}
          value={selectedOption}
        />
      </Grid>
    );
  }

  renderPremiumUpgradeSlots(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Premium-Slots"}
        id="premium_upgrade_slots"
        onChange={this.handleIntNumberChange("product.premium_upgrade_slots")}
        value={this.props.paymentPlanFormState.product.premium_upgrade_slots}
        margin="normal"
        error={invalidFields.includes("premium_upgrade_slots")}
      />
    );
  }

  renderPremiumPlusUpgradeSlots(invalidFields: string[]) {
    return (
      <TextFieldWithLabel
        disabled={this.props.readonly}
        label={"Premium Plus Slots"}
        id="premium_plus_upgrade_slots"
        onChange={this.handleIntNumberChange("product.premium_plus_upgrade_slots")}
        value={this.props.paymentPlanFormState.product.premium_plus_upgrade_slots}
        margin="normal"
        error={invalidFields.includes("premium_plus_upgrade_slots")}
      />
    );
  }

  renderCountryDisplayValue(code: string, value: any) {
    if (!this) {
      return undefined;
    }

    if (!value || value === "") {
      return {
        value: undefined,
        label: this.props.paymentPlanFormState.product.company_id
          ? intl.get("paymentplan_form.placeholder_country_no_company_address")
          : intl.get("paymentplan_form.placeholder_country"),
      };
    }

    return Constants.toSingleSelectOption(value, "event_constants.location.country_code");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderCountry(invalidFields: string[]) {
    let countryCode = this.props.paymentPlanFormState.product.country;

    if (this.props.paymentPlanFormState.product.company_id) {
      const company = this.props.paymentPlanFormState.companies.find((value) => {
        return this.props.paymentPlanFormState.product.company_id === value.company_id;
      });
      if (company) {
        if (company.salespartner_company_id) {
          countryCode = company.salespartner_company_country_code;
        } else {
          countryCode = company.country_code;
        }
      } else {
        countryCode = undefined;
      }
    }

    return (
      <Grid item container direction="column">
        <SectionLabel required>{intl.get("paymentplan_form.label_country")}</SectionLabel>
        <MySelect
          options={Constants.addressCountryCodeOptions()}
          isDisabled={this.props.readonly || this.props.paymentPlanFormState.product.company_id != null}
          isClearable
          isSearchable
          placeholder={intl.get("paymentplan_form.label_company_selection_hint")}
          value={this.renderCountryDisplayValue(countryCode, countryCode)}
          onChange={(value) => {
            this.props.actions.updatePaymentProductFormField({
              prop: "product.country",
              value: value ? value.value : undefined,
            });
          }}
        />
      </Grid>
    );
  }

  render() {
    if (this.props.pageState.state === PageStateType.Loading) {
      return <></>;
    }

    let invalidFields: string[] = [];
    if (this.props.paymentPlanFormState.error && this.props.paymentPlanFormState.error.validationErrors) {
      invalidFields = this.props.paymentPlanFormState.error.validationErrors.map((v) => {
        return v.field;
      });
    }

    return (
      <>
        <Box sx={AppStyles.innerContentRootWithoutLimit}>
          {this.props.children}

          <Paper sx={AppStyles.innerContentPaper}>
            <Tabs value={this.state.tab} indicatorColor="primary" textColor="primary">
              <Tab disabled={this.props.readonly} label={intl.get("paymentplan_form.tab_info")} />
            </Tabs>

            <Grid item container direction="row" sx={AppStyles.detailForm}>
              <Grid item xs={12} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderName(invalidFields)}
              </Grid>
              <Grid item xs={12} md={5} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderSkuGroup(invalidFields)}
              </Grid>
              <Grid item xs={12} md={5} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderSku(invalidFields)}
              </Grid>
              <Grid item xs={12} md={2} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderDisplayOrder(invalidFields)}
              </Grid>
              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} md={5} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderCompanySelection(invalidFields)}
                </Grid>
              )}
              {this.props.authenticationState.user.role !== "ADMIN" && (
                <Grid item xs={12} md={6} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderCompanySelection(invalidFields)}
                </Grid>
              )}
              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} md={5} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  {this.renderCountry(invalidFields)}
                </Grid>
              )}

              {this.props.authenticationState.user.role !== "ADMIN" && (
                <Grid item xs={12} md={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  {this.renderCountry(invalidFields)}
                </Grid>
              )}
              {this.props.authenticationState.user.role === "ADMIN" && (
                <Grid item xs={12} md={2} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  {this.renderSingleUse()}
                </Grid>
              )}

              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderType()}
              </Grid>
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderBillingIntervalValue(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  {this.renderBillingIntervalUnit(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  &nbsp;
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type !== "subscription" && (
                <Grid item xs={12} md={9} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  &nbsp;
                </Grid>
              )}

              <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderPrice()}
              </Grid>
              <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderCurrency()}
              </Grid>
              <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderTaxRate(invalidFields)}
              </Grid>

              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                  <Divider />
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderReducedPrice()}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderNumberOfPeriodsWithReducedPrice(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  &nbsp;
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderTrialInterval(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderTrialUnit(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  &nbsp;
                </Grid>
              )}

              <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
                <Divider />
              </Grid>

              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderPremiumUpgradeSlots(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                  {this.renderPremiumPlusUpgradeSlots(invalidFields)}
                </Grid>
              )}
              {this.props.paymentPlanFormState.product.type === "subscription" && (
                <Grid item xs={12} md={6} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                  &nbsp;
                </Grid>
              )}
              <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderHeroCredits(invalidFields)}
              </Grid>
              <Grid item xs={12} md={3} container direction="column" sx={AppStyles.detailFormRowWithSideBorder}>
                {this.renderSocialCredits(invalidFields)}
              </Grid>
              <Grid item xs={12} md={4} container direction="column" sx={AppStyles.detailFormRowNoBorder}>
                {this.renderFaiCredits(invalidFields)}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    paymentPlanFormState: state.paymentPlanFormState,
    paymentPlanState: state.paymentPlanState,
    pageState: state.pageState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(PaymentProductActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentPlanEditForm);
