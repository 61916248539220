import { Box } from "@mui/material";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import * as intl from "react-intl-universal";
import { RootState } from "../../reducers";
import * as CompanyActions from "../../actions/company";
import * as CompanyProductActions from "../../actions/company.product.actions";
import { CompanyState } from "../../model/companyState";
import { PaymentState } from "../../model/paymentState";
import { PaymentInfoStatus } from "../../model/payment_info";
import UpdatePaymentMethodDialog from "./payment/UpdatePaymentMethodDialog";
import CompanyPlanSelection from "./CompanyPlanSelection";
import CompanyPlanInformation from "./CompanyPlanInformation";
import CompanyPaymentInfoComponent from "./CompanyPaymentInfoComponent";
import { ErrorView } from "../../components/ErrorView";
import { AuthenticationState } from "../../model/authenticationState";
import { CompanyPaymentProduct } from "../../model/payment/company.payment.product";
import { PaymentProduct } from "../../model/payment/payment.product";
import { PageTracker } from "../../util/pageTracker";
import AppStyles from "../../styles/appStyles";
import { useEffect, useState } from "react";
import InvalidPaymentMethodOrOverdueInvoiceInfo from "./payment/InvalidPaymentMethodOrOverdueInvoicesInfo";
import DeletePaymentMethodDialog from "./payment/DeletePaymentMethodDialog";
import useDialogHandler from "../../components/dialog/useDialogHandler";
import ProductCancellationDialog from "./payment/ProductCancellactionDialog";
import PlanPromotionBanner from "./payment/PlanPromotionBanner";
import PurchaseConfirmationDrawer from "../../components/drawer/PurchaseConfirmationDrawer";

export interface Props {
  companyState: CompanyState;
  paymentState: PaymentState;
  authenticationState: AuthenticationState;
  actions: typeof CompanyActions;
  productActions: typeof CompanyProductActions;
}

function CompanyPaymentPage(props: Props) {
  const [overdueInvoicesRecharged, setOverdueInvoicesRecharged] = useState(false);
  const [productToActivateAfterPaymentMethodAdd, setProductToActivateAfterPaymentMethodAdd] =
    useState<PaymentProduct>();

  const paymentMethodDialogHandler = useDialogHandler();

  const { paymentState, actions, productActions, companyState } = props;

  const deletePaymentMethodDialogHandler = useDialogHandler();
  const productCancellationDialogHandler = useDialogHandler<CompanyPaymentProduct>();
  const productConfirmationDialogHandler = useDialogHandler<PaymentProduct>();

  function reloadPaymentInformation() {
    if (
      paymentState.paymentInfo &&
      paymentState.paymentInfo.status === PaymentInfoStatus.PENDING_AUTHORIZATION &&
      paymentState.paymentInfo.authorizationRedirectUrl != null
    ) {
    } else {
      actions.loadPaymentInformation(companyState.selected_company_id);
    }
  }

  useEffect(() => {
    productActions.loadCompanyProductInformation(canEditPaymentInformation(), companyState.selected_company_id);
    reloadPaymentInformation();
  }, []);

  useEffect(() => {
    if (overdueInvoicesRecharged && paymentState.reloadInvoices) {
      actions.loadInvoices(
        companyState.selected_company_id,
        paymentState.invoice_paging.current_page,
        paymentState.invoice_paging.page_size,
      );
      setOverdueInvoicesRecharged(false);
    }
  }, [overdueInvoicesRecharged, paymentState.reloadInvoices]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (paymentState.paymentInfo && paymentState.paymentInfo.status === PaymentInfoStatus.PENDING_AUTHORIZATION) {
      timer = setTimeout(() => {
        reloadPaymentInformation();
      }, 5000);
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [paymentState.paymentInfo?.status]);

  function canEditPaymentInformation(): boolean {
    return companyState.company.permissions.indexOf("EDIT_COMPANY_PAYMENT_DATA") !== -1;
  }

  function activateProduct(product: PaymentProduct) {
    const isFreeProduct = product.price_in_cents === 0 && product.reduced_price_in_cents === 0;
    if (
      !companyState.company.salespartner_company_id &&
      (!paymentState.paymentInfo || paymentState.paymentInfo.status !== PaymentInfoStatus.VALID) &&
      !isFreeProduct
    ) {
      productConfirmationDialogHandler.close();
      setProductToActivateAfterPaymentMethodAdd(product);
      paymentMethodDialogHandler.open(paymentState.paymentInfo?.payment_provider_payment_type);
    } else {
      productConfirmationDialogHandler.open(product);
      paymentMethodDialogHandler.close();
    }
  }

  function deletePaymentMethod() {
    if (companyState.selected_company_id) {
      actions.deletePaymentInformation(companyState.selected_company_id);
    }
    deletePaymentMethodDialogHandler.close();
  }

  function confirmProductActivation() {
    setProductToActivateAfterPaymentMethodAdd(null);
  }

  const handleRechargeInvoicesClicked = () => {
    setOverdueInvoicesRecharged(true);
    actions.chargeOverdueInvoices(companyState.selected_company_id);
  };

  return (
    <>
      <PageTracker />
      <Box sx={AppStyles.innerContentRootWithoutLimit}>
        <InvalidPaymentMethodOrOverdueInvoiceInfo onChargeInvoicesClicked={handleRechargeInvoicesClicked} />

        {paymentState.status === "ERROR_NO_COMPANY_COUNTRY" && canEditPaymentInformation() && (
          <ErrorView message={intl.get("company_plan_info.payment_no_country_set")} />
        )}
        {paymentState.status === "ERROR_NO_COMPANY_PAYMENT_INFO" && canEditPaymentInformation() && (
          <ErrorView message={intl.get("company_plan_info.payment_no_payment_info_set")} />
        )}
        {paymentState.status === "ERROR_NO_COMPANY_COUNTRY_SALESPARTNER" && canEditPaymentInformation() && (
          <ErrorView message={intl.get("company_plan_info.payment_no_country_set_salespartner")} />
        )}
        {paymentState.status === "ERROR_NO_COMPANY_PAYMENT_INFO_SALESPARTNER" && canEditPaymentInformation() && (
          <ErrorView message={intl.get("company_plan_info.payment_no_payment_info_set_partner")} />
        )}

        {companyState.company.number_of_active_subscriptions !== undefined &&
          companyState.company.number_of_active_subscriptions === 0 && <PlanPromotionBanner />}

        {!companyState.company.salespartner_company_id && (
          <CompanyPaymentInfoComponent
            paymentInfo={paymentState.paymentInfo}
            supportsEditing={canEditPaymentInformation()}
            onChangePaymentMethod={() => {
              paymentMethodDialogHandler.open(paymentState.paymentInfo?.payment_provider_payment_type);
            }}
            onDeletePaymentMethod={deletePaymentMethodDialogHandler.open}
          />
        )}

        <CompanyPlanInformation
          activeProducts={paymentState.activeProducts}
          credits={paymentState.credits}
          companyCountryCode={
            companyState.company.salespartner_company_country_code
              ? companyState.company.salespartner_company_country_code
              : companyState.company.country_code
          }
          canCancelProducts={canEditPaymentInformation()}
          cancelProduct={(product: CompanyPaymentProduct) => {
            productCancellationDialogHandler.open(product);
          }}
        />

        {!paymentState.status && paymentState.availableProducts && canEditPaymentInformation() && (
          <CompanyPlanSelection
            hasValidPaymentMethod={
              paymentState.paymentInfo && paymentState.paymentInfo.status === PaymentInfoStatus.VALID
            }
            supportsAddingPaymentMethod={canEditPaymentInformation()}
            isManagedBySalespartner={companyState.company.salespartner_company_id !== null}
            onAddPaymentMethod={() => {
              paymentMethodDialogHandler.open(paymentState.paymentInfo?.payment_provider_payment_type);
            }}
            availableProducts={paymentState.availableProducts}
            onSelectProduct={(product: PaymentProduct) => {
              activateProduct(product);
            }}
          />
        )}

        {paymentMethodDialogHandler.isOpen && (
          <UpdatePaymentMethodDialog
            handler={paymentMethodDialogHandler}
            onCancel={() => {
              setProductToActivateAfterPaymentMethodAdd(null);
            }}
            onSuccess={() => {
              if (productToActivateAfterPaymentMethodAdd) {
                productConfirmationDialogHandler.open(productToActivateAfterPaymentMethodAdd);
                setProductToActivateAfterPaymentMethodAdd(null);
              }
            }}
          />
        )}

        <DeletePaymentMethodDialog
          handler={deletePaymentMethodDialogHandler}
          onDeletePaymentMethod={deletePaymentMethod}
        />

        <PurchaseConfirmationDrawer handler={productConfirmationDialogHandler} onSuccess={confirmProductActivation} />

        <ProductCancellationDialog handler={productCancellationDialogHandler} />
      </Box>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
    paymentState: state.paymentState,
    authenticationState: state.authenticationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(CompanyActions as any, dispatch),
    productActions: bindActionCreators(CompanyProductActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CompanyPaymentPage);
